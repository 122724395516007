/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import Dialog from "../../UI/Dialog";
import { MypageTop } from "../../UI/Header";
import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { UInputForm, UFormWrapper, CheckBox, Textarea, Radio } from "../../UI/Form";

import Menu from "./Mypage.Menu";

export default (props) => {
	const { location, history, match } = props;

	const [keyword, setKeyword] = useState("");
	const [list, setList] = useState([]);
	const [paging, setPaging] = useState(null);

	const loadList = (data) => {
		_API.get({ path: `/bbs/my`, data: { ...data, bbsCode: "QNA" } }).then((res) => {
			const { list, paging } = res.data;
			console.log(list);
			setList(list);
			setPaging(paging);
		});
	};

	const movePage = (page) => {
		ToPage(location, history, { keyword, page });
		window.scrollTo(0, 0);
	};

	const onSearch = (e) => {
		ToPage(location, history, { keyword, page: 1 });
	};

	const onRefresh = () => {
		loadList({ keyword, page: paging.pageNo });
	};

	useEffect(() => {
		const params = GetParams(location);
		if (params) {
			setKeyword(params.keyword);
			loadList(params);
		} else {
			setKeyword("");
			loadList({ page: 1 });
		}
	}, [location]);

	const onClickWrite = () => {
		Dialog({
			title: `1:1문의`,
			body: <Form {...{ location, history, match }} />,
		})
			.then((data) => {
				setKeyword("");
				loadList({ page: 1 });
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};
	if (!_CK.isLogin()) {
		history.goBack();
		return null;
	}
	let virtualNo = paging ? paging.virtualRecordNo : -1;

	return (
		<Layout {...props} hideTop={true}>
			<Container>
				<MypageTop {...props}>{isMobile ? `1:1문의` : `마이페이지`}</MypageTop>
				<Wrapper>
					<Menu {...props} />
					<ContentWrapper>
						<BWrapper>
							<div className="top">
								<h2>1:1문의</h2>
								<button onClick={onClickWrite}>문의하기</button>
							</div>

							<div className="list">
								<table>
									<thead>
										<tr>
											<th width="5%">번호</th>
											<th>제목</th>
											<th>작성자</th>
											<th>등록일</th>
										</tr>
									</thead>
									<tbody>
										{!list ||
											(list.length === 0 && (
												<tr>
													<td colSpan="4" className="text-center">
														1:1 문의내역이 없습니다.
													</td>
												</tr>
											))}
										{list?.map((item, i) => (
											<ListItem key={i} {...item} virtualNo={virtualNo--} history={history} />
										))}
									</tbody>
								</table>
								{paging && (
									<div className="paging">
										<Paging paging={paging} pageCallback={movePage} />
									</div>
								)}
							</div>
						</BWrapper>
					</ContentWrapper>
				</Wrapper>
			</Container>
		</Layout>
	);
};

const Wrapper = styled.div`
	display: flex;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-bottom: 40px;
	}
`;

const ContentWrapper = styled.div`
	margin-left: 30px;
	padding-top: 10px;
	flex: 1;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-left: 0;
		padding: 0 21px;
		width: calc(100% - 21px);
	}
`;

const BWrapper = styled.div`
	div.top {
		position: relative;
		display: flex;

		/* @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-bottom: 1px solid #3d3d3d;
			margin-top: 24px;
			padding-bottom: 16px;
			display: none;
		} */
	}

	div.top > h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: none;
		}
	}

	> div.top > button {
		position: absolute;
		right: 0;
		top: 0;
		background: #00788d;
		border: none;
		outline: none;
		color: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		padding: 4px 18px;
		cursor: pointer;
	}

	div.list {
		margin-top: 16px;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			margin-top: 30px;
		}
	}

	div.paging {
		margin-top: 20px;
	}

	table {
		border-top: 1px solid #3d3d3d;
		width: 100%;
		border-spacing: 0 0;
		margin-top: 8px;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		}
		thead {
			background: #f5f5f5;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			tbody > tr.question {
				background-image: url("/icons/ic_acc_down.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_down.png", "/icons/ic_acc_down@2x.png", "/icons/ic_acc_down@3x.png"])});
				background-repeat: no-repeat;
				background-size: 10px 6px;
				background-position: right center;
			}
			tbody > tr.is-more {
				background-image: url("/icons/ic_acc_up.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_up.png", "/icons/ic_acc_up@2x.png", "/icons/ic_acc_up@3x.png"])});
			}
		}

		thead > tr > th {
			text-align: center;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 12px;
			line-height: 17px;
			padding: 9px 0;
			color: #777777;
			border-bottom: 1px solid #dfdfdf;
		}

		tbody > tr > td {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			padding: 14px 0;
			color: #3d3d3d;
			border-bottom: 1px solid #dfdfdf;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				div.date {
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 12px;
					line-height: 12px;
					color: #c1c1c1;
				}
			}

			button {
				border: 1px solid #cfcfcf;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 10px;
				line-height: 15px;
				color: #9a9a9a;
				background: #ffffff;
				padding: 5px 13px;
				margin-right: 8px;
				min-width: 54px;
			}

			button.complete {
				color: #00788d;
				background: #ffffff;
				border: 1px solid #00788d;
			}
			button.category {
				color: #3d3d3d;
				background: #ffffff;
				border: 1px solid #3d3d3d;
			}
			button.seller {
				color: #ffffff;
				background: #00788d;
				border: 1px solid #00788d;
			}

			div.product-info {
				display: none;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: flex;

					img {
						flex-basis: 50px;
						width: 50px;
						height: 50px;
						object-fit: cover;
					}

					p {
						flex: 1;
						padding-left: 8px;
						display: flex;
						align-items: center;
					}
				}
			}

			div.content {
				img {
					max-width: 100%;
				}
			}
		}

		tbody > tr > td.no,
		tbody > tr > td.date,
		tbody > tr > td.product-info {
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
		}

		tbody > tr > td.author {
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				padding-right: 21px;
			}
		}

		tbody > tr {
			cursor: pointer;
		}

		tbody > tr > td.text-center {
			text-align: center;
		}

		tbody > tr > td.title > span {
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: block;
				margin-top: 5px;
			}
		}

		tbody > tr > td.product-info {
			text-align: center;

			img {
				width: 50px;
				height: 50px;
				object-fit: cover;
			}

			p {
				margin: 0;
				padding: 0;
				font-size: 13px;
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #3d3d3d;
				width: 150px;
				white-space: nowrap;
				text-overflow: ellipsis;

				overflow: hidden;
			}
		}

		tbody > tr.answer > td:first-child {
			text-align: right;
			vertical-align: top;
			padding-right: 16px;
		}
		tbody > tr.answer > td {
			background: #f9f9f9;
			position: relative;

			div.content {
				display: flex;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: inline-flex;
					width: calc(100% - 26px);
				}
			}

			div.depth {
				width: 10px;
				height: 10px;
				background-color: #c7c7c7;
				display: inline-block;
				position: relative;

				:before {
					content: "";
					position: absolute;
					width: 9px;
					height: 9px;
					top: -1px;
					right: -1px;
					background: #f9f9f9;
				}

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: inline-block;
					vertical-align: top;
					margin: 0 8px;
				}
			}

			div.content > .button {
				flex-basis: 70px;
			}

			div.content > div.detail {
				flex: 1;
				span {
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 13px;
					line-height: 18px;
					color: #3d3d3d;
				}

				p {
					margin: 2px 0 0 0;
					padding: 0;
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 13px;
					line-height: 18px;
					color: #9a9a9a;
				}
			}
		}
	}
`;

const ListItem = (props) => {
	const { history } = props;
	const [show, setShow] = useState(false);
	console.log(props);

	return (
		<>
			<tr onClick={() => setShow(!show)} className={cx({ pointer: true, question: true, "is-more": show })}>
				<td className="text-center no">{props.virtualNo}</td>
				<td className="title">
					<button className="category">{_DEF.getDefinedLabel(_DEF.FAQ_CATEGORY, props.categoryCode)}</button>
					{props.answer ? <button className="complete">답변완료</button> : <button>미답변</button>}
					<span>{props.title}</span>
				</td>
				<td className="text-center author">
					{props.clientNickName}
					{isMobile && <div className="date">{moment(props.regDate).format("YYYY-MM-DD")}</div>}
				</td>
				<td className="text-center date">{moment(props.regDate).format("YYYY-MM-DD")}</td>
			</tr>
			{show && (
				<tr>
					<td className="no"></td>
					<td colSpan={4}>
						<div className="content">
							<div className="detail">
								<div dangerouslySetInnerHTML={{ __html: props.content }} />

								{props.fileList?.map((file, fIndex) => (
									<div key={fIndex}>
										<img src={file.signedUrl} />
									</div>
								))}
							</div>
						</div>
					</td>
				</tr>
			)}
			{props.answer && show && (
				<tr className="answer">
					<td className="no">
						<div className="depth"></div>
					</td>
					<td colSpan={4}>
						<div className="content">
							<div className="button">
								<button className="seller">판매자</button>
							</div>

							<div className="detail" dangerouslySetInnerHTML={{ __html: props.answer }} />
						</div>
					</td>
				</tr>
			)}
		</>
	);
};

const Form = (props) => {
	const { resolve, reject } = props;
	const [awsKey, setAwsKey] = useState(null);
	const [data, setData] = useState(null);
	const fileRef = useRef(null);
	const [images, setImages] = useState([]);

	const onSave = () => {
		if (!data?.categoryCode) {
			swal({ title: "알림", text: "문의 유형을 선택하세요." });
			return;
		}
		if (!data?.title) {
			swal({ title: "알림", text: "제목을 입력하세요." });
			return;
		}
		if (!data?.content) {
			swal({ title: "알림", text: "문의 내용을 입력하세요." });
			return;
		}

		_API.post({ path: "/bbs/save", data: { bbsCode: "QNA", ...data, fileList: images } }).then((res) => {
			swal({ title: "알림", text: "1:1 문의가 완료 되었습니다." });
			resolve();
		});
	};

	const loadKeys = () => {
		_API
			.loadUploadKeys()
			.then((res) => res.data)
			.then((data) => {
				setAwsKey(data);
			});
	};

	useEffect(() => {
		loadKeys();
	}, []);

	const handleFileChange = (e) => {
		const file = e.target.files[0];

		_API
			.upload({
				aws: awsKey,
				file,
				path: `QNA/${moment().format("YYYY/MM/DD")}`,
			})
			.then((res) => res.data)
			.then((result) => {
				// setData({ ...data, fileIdx: result.id, fileSignedUrl: result.signedUrl, fileName: result.name });
				setImages([{ fileIdx: result.id, signedUrl: result.signedUrl, name: result.name }]);
			});
	};

	const onFileRemove = () => {
		setImages([]);
	};

	return (
		<FWrapper>
			<UFormWrapper title={"문의유형"} labelStyles={"padding:14px 0;"} formStyles={"padding:14px 0;"}>
				{_DEF.FAQ_CATEGORY.map((item, index) => (
					<Radio
						inline={true}
						onClick={(v) => setData({ ...data, categoryCode: v })}
						value={item.value}
						title={item.label}
						checked={data?.categoryCode === item.value}
						key={index}
						style={{ marginRight: isMobile ? "10px" : "16px" }}
					/>
				))}
			</UFormWrapper>
			<UInputForm title="제목" value={data?.title} onChange={(e) => setData({ ...data, title: e.target.value })} />
			<UFormWrapper styles={"margin-bottom:0px;"} title={"내용"} labelStyles={"padding:14px 0;"} formStyles={"padding:14px 0;"}>
				<Textarea
					value={data?.content}
					onChange={(e) => setData({ ...data, content: e.target.value })}
					placeholder="내용을 상세히 적어주시면 보다 정확한 답을 받으실 수 있습니다."
				/>
			</UFormWrapper>
			<UFormWrapper styles={"margin-bottom:0px;"} title={"이미지 첨부"} labelStyles={"padding:14px 0;"} formStyles={"padding:14px 0;"}>
				<button onClick={() => fileRef.current.click()} className="btn-attach">
					사진 첨부
				</button>
				<input ref={fileRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
				{images[0]?.signedUrl && (
					<span className="file-attach">
						첨부파일 :
						<a href={images[0]?.signedUrl} target="_blank">
							{images[0]?.name}
						</a>
						<img
							src={"/images/icon/ic_remove.png"}
							srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
							onClick={() => onFileRemove()}
						/>
					</span>
				)}
			</UFormWrapper>

			<div className="buttons">
				<button onClick={() => reject()}>닫기</button>
				<button className="action" onClick={onSave}>
					저장
				</button>
			</div>
		</FWrapper>
	);
};

const FWrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		> div {
			flex-direction: column;
		}
		> div > .uif-label {
			padding: 14px 0 0px !important;
		}
		> div > .uif-form {
			padding-top: 8px;

			textarea {
				width: calc(100% - 22px);
			}
		}
	}

	button.btn-attach {
		background: #ffffff;
		border: 1px solid #00788d;
		padding: 4px 15px;
		color: #00788d;
		font-family: ${(props) => props.theme.fontFamily.regular};
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		z-index: 2;
	}

	span.file-attach {
		margin-left: 30px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			margin-left: 0px;
			display: block;
			margin-top: 8px;
		}
		img {
			vertical-align: middle;
			margin-left: 8px;
			width: 10px;
			height: 10px;
			cursor: pointer;
		}

		a {
			color: #e32222;
		}
	}

	> div.buttons {
		margin-top: 30px;
		text-align: center;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		button {
			border: 1px solid #00788d;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #00788d;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 15px 0;
			width: 220px;

			:last-child {
				margin-left: 12px;
			}

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				width: 50%;
				:last-child {
					margin-left: 0px;
				}
			}
		}

		button.action {
			border: 1px solid #00788d;
			background: #00788d;
			color: #ffffff;
		}
	}
`;
