/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import { _DEF } from "../../Modules/Config";
import Layout, { Container } from "../Layout";
import { AppContext } from "../../Components/AppProvider";

import Menu from "../User/Mypage.Menu";
import ServiceMenu from "../Service/Service.Menu";
import { Input, Textarea, CheckBox, SelectBox, Date } from "../../UI/Form";
import { MypageTop } from "../../UI/Header";
import Dialog from "../../UI/Dialog";

export default (props) => {
    const { iam, saveAuth, saveIam } = useContext(AppContext);
    const { location, history, match } = props;
    const { orderIdx, productIdx, returnIdx } = match.params;
    const [data, setData] = useState({});
    const [fileList, setFileList] = useState([]);
    const [awsKey, setAwsKey] = useState(null);
    const fileRef = useRef(null);
    const serviceYn = location.pathname?.indexOf("service/return") > -1 ? "Y" : "N";

    // if (!_CK.isLogin()) {
    //     goList();
    //     return null;
    // }

    if (!orderIdx || !productIdx) {
        swal({ title: "알림", text: "주문정보가 없습니다." });
        goList();
    }

    const loadKeys = () => {
        _API.loadUploadKeys()
            .then((res) => res.data)
            .then((data) => {
                setAwsKey(data);
            });
    };

    const loadData = () => {
        if (!orderIdx || !productIdx) {
            swal({ title: "알림", text: "주문정보가 없습니다." });
            return;
        }
        _API.get({ path: `/bbs/request/return`, data: { returnIdx, orderIdx, productIdx } })
            .then((res) => {
                const data = res.data;
                setData(data);
                if (data.fileList && data.fileList.length > 0) setFileList(data.fileList);
            })
            .catch((e) => {
                goList();
            });
    };

    const onAddrOpen = () => {
        new daum.Postcode({
            oncomplete: (result) => {
                const address = {
                    recipientZipcode: result.zonecode,
                    recipientAddr: result.address,
                    recipientAddrEng: result.addressEnglish,
                    recipientAddrDtl: "",
                };
                setData({ ...data, ...address });
            },
        }).open();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        _API.upload({
            aws: awsKey,
            file,
            path: `REQUEST/RETURN/${moment().format("YYYY/MM/DD")}`,
        })
            .then((res) => res.data)
            .then((result) => {
                console.log("fileUpload", result);
                setFileList((o) => [...o, { signedUrl: result.signedUrl, fileIdx: result.id, name: result.name }]);
            });
    };

    const goList = () => {
        if (history) {
            history.goBack();
        } else {
            window.location.href = "/";
        }
    };

    const onRequest = () => {
        if (!saveAvail({ ...data, fileList })) {
            return;
        }
        Dialog({
            title: `반품요청`,
            body: <Notice data={{ ...data, fileList }} />,
        })
            .then((data) => {
                goList();
            })
            .catch((e) => {
                console.log("e", e);
            });
    };

    useEffect(() => {
        loadKeys();
        loadData();
    }, []);

    return (
        <Layout {...props} hideTop={true}>
            <Container>
                <MypageTop {...props}>{isMobile ? `반품신청` : serviceYn === "Y" ? `서비스센터` : `마이페이지`}</MypageTop>
                <Wrapper>
                    {serviceYn === "Y" ? <ServiceMenu {...props} /> : <Menu {...props} />}
                    <ContentWrapper>
                        <DWrapper>
                            <div className="top">
                                <h2>반품신청</h2>
                            </div>
                            <div className="box">
                                <div className="form">
                                    <h3>반품신청 내용을 작성해주시기 바랍니다.</h3>
                                    <table>
                                        <tr>
                                            <th>주문번호</th>
                                            <td>{data?.orderNo}</td>
                                            <th>상품명</th>
                                            <td>{data?.productName}</td>
                                        </tr>
                                        <tr>
                                            <th>구매자명</th>
                                            <td>
                                                <Input value={data?.clientName} onChange={(e) => setData({ ...data, clientName: e.target.value })} />
                                            </td>
                                            <th>연락처</th>
                                            <td>
                                                <Input value={data?.clientMobile} onChange={(e) => setData({ ...data, clientMobile: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>사유</th>
                                            <td>
                                                <SelectBox
                                                    value={data?.reasonCode}
                                                    onChange={(e) => setData({ ...data, reasonCode: e.target.value })}
                                                    items={_DEF.RETURN_REASON_CODE}
                                                    placeholder="반품사유 선택"
                                                />
                                            </td>
                                            <th></th>
                                            <td></td>
                                        </tr>
                                        {data?.reasonCode === "ETC" && (
                                            <tr>
                                                <th>
                                                    반품사유
                                                    <br />
                                                    직접입력
                                                </th>
                                                <td colSpan={3}>
                                                    <Textarea onChange={(e) => setData({ ...data, returnDesc: e.target.value })} value={data?.asDesc} rows={5} />
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <th>배송방법</th>
                                            <td>
                                                <SelectBox
                                                    value={data?.shippingType}
                                                    onChange={(e) => setData({ ...data, shippingType: e.target.value })}
                                                    items={_DEF.RETURN_SHIPPING_TYPE}
                                                    placeholder="배송방법 선택"
                                                />
                                            </td>
                                            <th>{data?.shippingType === "REQUEST" && "수거요청일"}</th>
                                            <td>{data?.shippingType === "REQUEST" && <Date value={data?.callDate} onChange={(e) => setData({ ...data, callDate: e })} />}</td>
                                        </tr>
                                        <tr>
                                            <th>주소</th>
                                            <td colSpan={3}>
                                                <div>
                                                    <button onClick={onAddrOpen} className="search-addr-btn">
                                                        주소찾기
                                                    </button>
                                                    <Input
                                                        placeholder="우편번호"
                                                        type="text"
                                                        value={data?.recipientZipcode}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        name="recipientZipcode"
                                                        className="form-control form-control-lg"
                                                        styles={{ width: "100px", color: "#3d3d3d" }}
                                                        readOnly
                                                        onClick={onAddrOpen}
                                                    />
                                                </div>
                                                <div>
                                                    <Input
                                                        placeholder="주소를 입력하세요."
                                                        type="text"
                                                        value={data?.recipientAddr}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        name="recipientAddr"
                                                        className="form-control form-control-lg"
                                                        styles={{ width: "50%", color: "#3d3d3d" }}
                                                    />
                                                </div>
                                                <div>
                                                    <Input
                                                        placeholder="상세 주소를 입력하세요."
                                                        type="text"
                                                        value={data?.recipientAddrDtl}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        name="recipientAddrDtl"
                                                        className="form-control form-control-lg"
                                                        styles={{ width: "50%", color: "#3d3d3d" }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>비용</th>
                                            <td>
                                                {data?.productShippingType === "OVERSEAS" ? (
                                                    <>{data?.shippingType === "REQUEST" ? "30,000" : data?.shippingType === "DIRECT" ? "25,000" : ""}</>
                                                ) : data?.productShippingType ? (
                                                    <>{data?.shippingType === "REQUEST" ? "10,000" : data?.shippingType === "DIRECT" ? "5,000" : ""}</>
                                                ) : (
                                                    ""
                                                )}
                                            </td>
                                            <th>모아 주소</th>
                                            <td>{"서울 강남구 논현로 645, 8층 모아"}</td>
                                        </tr>
                                        <tr>
                                            <th>사진첨부</th>
                                            <td colSpan={3}>
                                                <button onClick={() => fileRef.current.click()} className="search-addr-btn">
                                                    사진첨부
                                                </button>
                                                <input ref={fileRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
                                                <div>
                                                    {fileList?.map((item, i) => (
                                                        <img src={item.signedUrl} style={{ maxWidth: "200px", maxHeight: "200px" }} key={i} />
                                                    ))}
                                                </div>
                                            </td>
                                        </tr>
                                    </table>

                                    <div className="buttons">
                                        <button onClick={() => goList()}>취소</button>
                                        <button className="action" onClick={onRequest}>
                                            신청하기
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </DWrapper>
                    </ContentWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding-bottom: 40px;
    }
`;

const ContentWrapper = styled.div`
    margin-left: 30px;
    padding-top: 10px;
    flex: 1;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-left: 0;
        padding: 0 21px;
    }
`;

const DWrapper = styled.div`
    div.top {
        position: relative;
        display: flex;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: 1px solid #3d3d3d;
            margin-top: 24px;
            padding-bottom: 16px;
            display: none;
        }
    }

    div.top > h2 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
    }

    div.box {
        margin-top: 16px;
    }

    div.box > div.desc-box {
        border: 1px solid #efefef;
        background: #fcfcfc;
        padding: 11px 20px;
        color: #9a9a9a;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 10px;

        p {
            font-weight: normal;
            margin: 0 0 10px;
            color: #3d3d3d;
        }
    }

    div.box > div.text-right {
        text-align: right;
    }

    table {
        border-spacing: 0;
        width: 100%;
        margin-top: 16px;
    }

    table > tr {
        color: #777777;
    }

    table > tr:first-child > th,
    table > tr:first-child > td {
        border-top: 1px solid #dfdfdf;
    }

    table > tr > th {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 12px;
        line-height: 17px;
        padding: 9px 0 9px 16px;
        border-bottom: 1px solid #dfdfdf;
        text-align: left;
        background: #f5f5f5;
        width: 15%;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 20%;
        }
    }

    table > tr > td {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        padding: 14px 16px;
        color: #3d3d3d;
        border-bottom: 1px solid #dfdfdf;

        P {
            padding: 0;
            margin: 0;
        }

        textarea {
            color: #3d3d3d;
        }
    }

    div.buttons {
        margin-top: 30px;
        text-align: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        button {
            border: 1px solid #e32222;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #e32222;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                width: 50%;
                :last-child {
                    margin-left: 0px;
                }
            }
        }

        button.action {
            border: 1px solid #e32222;
            background: #e32222;
            color: #ffffff;
        }
    }

    /* div.button {
		text-align: center;
		margin-top: 20px;
	}

	div.button > button {
		font-family: ${(props) => props.theme.fontFamily.regular};
		border: 1px solid #e32222;
		background: #e32222;
		color: #ffffff;
		font-size: 14px;
		line-height: 20px;

		padding: 15px 0;
		width: 220px;
	} */

    h3 {
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #3d3d3d;
        font-size: 14px;
        line-height: 20px;
    }
`;

const Notice = (props) => {
    const { resolve, reject, data } = props;
    const [agree, setAgree] = useState(false);

    const onSave = () => {
        if (!agree) {
            swal({ title: "알림", text: "유의사항을 확인해 주세요." });
            return;
        }

        if (!saveAvail(data)) {
            reject();
        }

        _API.post({ path: "/bbs/request/return", data: { ...data } }).then((res) => {
            swal({ title: "알림", text: "반품 요청이 접수 되었습니다." }).then((data) => {
                resolve();
            });
        });
    };

    return (
        <FWrapper>
            <div>
                <p>
                    <b>비용 : </b>
                </p>
                <p>
                    반품은 오배송 또는 하자 상품의 경우에는 모든 비용을 모아가 부담하며,
                    <br />
                    반품 비용은 모아 지정 택배사를 이용한 착불 왕복 요금 기준이 되며, 타 택배사 이용시 선불로 보내주시고 5,000원(국내배송), 25,000원(해외배송)의 요금을 동봉 또는 입금하여 주시면
                    됩니다.
                    <br />그 이외의 구매자 변심과 같은 사유는 택을 제거하지 않은 상태로 상품과 구성품에 문제가 없을 시에 10,000원(국내배송), 30,000원(해외배송)의 비용이 발생합니다.
                </p>

                <p>
                    <b>신청 방법 : </b>
                </p>
                <p>
                    상품 배송 완료 후 7일 이내에 마이페이지에서 주문하신 상품에 반품 요청을 해주신 후 반품 비용을 동봉 또는 모아 계좌로 입금하여 주시면,
                    <br />
                    지정하신 날짜에 안내 문자가 발송되며 기사님이 연락 후 수거해가십니다.
                    <br />
                    <br />
                    [반품 비용 입금 계좌 : (신한은행) 주식회사 모아 140-013-635750]
                    <br />
                    (※입금시 입금자명은 꼭! 구매자명으로 해주셔야 빠른 처리가 가능합니다.)
                </p>
                <p>
                    반품 처리는 배송 방법에 따라 정해진 기간 이내에 상품이 당사 도착 후 자체 검수를 거쳐 신속하게 진행해드리고 있습니다.
                    <br />
                    조금 지연되더라도 저희가 특별히 연락을 드리지 않는 한 정상적으로 순차 진행되고 있으니 양해 부탁드립니다
                </p>
            </div>

            <CheckBox title="확인했습니다." value="Y" checked={agree} onClick={() => setAgree(!agree)} inline />

            <div className="buttons">
                <button onClick={() => reject()}>취소</button>
                <button className="action" onClick={onSave}>
                    신청하기
                </button>
            </div>
        </FWrapper>
    );
};

const FWrapper = styled.div`
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        > div {
            flex-direction: column;
        }
        > div > .uif-label {
            padding: 14px 0 0px !important;
        }
        > div > .uif-form {
            padding-top: 8px;

            textarea {
                width: calc(100% - 22px);
            }
        }
    }

    button.btn-attach {
        background: #ffffff;
        border: 1px solid #e32222;
        padding: 4px 15px;
        color: #e32222;
        font-family: ${(props) => props.theme.fontFamily.regular};
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        z-index: 2;
    }

    span.file-attach {
        margin-left: 30px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-left: 0px;
            display: block;
            margin-top: 8px;
        }
        img {
            vertical-align: middle;
            margin-left: 8px;
            width: 10px;
            height: 10px;
            cursor: pointer;
        }

        a {
            color: #e32222;
        }
    }

    > div.buttons {
        margin-top: 30px;
        text-align: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        button {
            border: 1px solid #e32222;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #e32222;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                width: 50%;
                :last-child {
                    margin-left: 0px;
                }
            }
        }

        button.action {
            border: 1px solid #e32222;
            background: #e32222;
            color: #ffffff;
        }
    }
`;

const saveAvail = (data) => {
    if (!data) return false;

    if (!data?.orderIdx) {
        swal({ title: "알림", text: "주문정보가 없습니다." });
        return false;
    }
    if (!data.productIdx) {
        swal({ title: "알림", text: "상품정보가 없습니다." });
        return false;
    }
    if (!data.clientName) {
        swal({ title: "알림", text: "구매자명을 입력해 주세요." });
        return false;
    }
    if (!data.clientMobile) {
        swal({ title: "알림", text: "구매자명 연락처를 입력해 주세요." });
        return false;
    }
    if (!data.recipientZipcode || !data.recipientAddr || !data.recipientAddrDtl) {
        swal({ title: "알림", text: "수신자 주소를 입력해 주세요." });
        return false;
    }
    if (!data.reasonCode) {
        swal({ title: "알림", text: "반품사유를 선택해 주세요." });
        return false;
    }
    if (data.reasonCode === "ETC" && !data.returnDesc) {
        swal({ title: "알림", text: "반품사유를 입력해 주세요." });
        return false;
    }
    if (!data.shippingType) {
        swal({ title: "알림", text: "배송방법을 입력해 주세요." });
        return false;
    }
    if (data.shippingType === "REQUEST" && !data.callDate) {
        swal({ title: "알림", text: "수거요청일을 입력해 주세요." });
        return false;
    }
    if (!data.fileList || data.fileList.length === 0) {
        swal({ title: "알림", text: "첨부사진을 등록해 주세요." });
        return false;
    }
    return true;
};
