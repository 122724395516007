/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { v4 as UUIDV4 } from "uuid";
import moment from "moment";
import { isMobile } from "react-device-detect";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { _CFG, _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { OrderTop } from "../../UI/Header";
import { CheckBox, Radio } from "../../UI/Product";
import { SelectBox, Input } from "../../UI/Form";
import Dialog from "../../UI/Dialog";

import Layout from "../Layout";
import Delivery from "./Order.Delivery";
import DeliveryNoMember from "./Order.Delivery.nomember";
import OrderCouponList from "./Order.Coupon.List";

export default (props) => {
    const { history, match } = props;
    const { iam, saveIam } = useContext(AppContext);

    const { orderIdx } = match.params;
    const buyBtnRef = useRef(null);
    const [data, setData] = useState(null);
    const [isCert, setIsCert] = useState("");
    const [payment, setPayment] = useState({
        payMethod: "creditcard",
    });
    const [orderNo, setOrderNo] = useState("");
    const [discountInfo, setDiscountInfo] = useState({
        discountPoint: 0,
        discountCouponPoint: 0,
        useCouponList: [],
    });

    const loadData = () => {
        // 회원 주문
        let path = "/user/payment/product/order";

        if (!iam?.idx) {
            // 비회원 주문
            path = "/payment/product/order";
        }

        _API.get({ path, data: { orderIdx } })
            .then((res) => {
                setData(res.data);
            })
            .catch((e) => {
                history.goBack();
            });
    };

    useEffect(() => {
        if (orderIdx) {
            loadData();
        }
    }, [orderIdx]);

    const onInfoChange = (key, value) => {
        setData({ ...data, info: { ...data.info, [key]: value } });
    };

    const onDeliverySelect = (info) => {
        setData({
            ...data,
            info: {
                ...data.info,
                recipientName: info?.receiver || "",
                recipientMobile: info?.mobile || "",
                recipientAddr: info?.addr || "",
                recipientAddrDtl: info?.addrDtl || "",
                recipientZipcode: info?.zipCode || "",
                reqDesc: info?.reqDesc || "",
                memberShippingIdx: info?.idx || "",
            },
        });
    };

    const savePayment = () => {
        const orderNo = `${moment().format("YYYYMMDDHHmmss")}${data?.info.orderIdx}${data?.info.memberIdx || data?.nomemberCode}`;
        setOrderNo(orderNo);
        let path = "/user/payment/product/order/change";
        //쿠폰 및 리워드 적용
        path = "/user/payment/product/order/change/discount";

        // 비회원 구매
        if (data?.nomemberCode) {
            path = "/payment/product/order/change";
        }
        // console.log(discountInfo)
        _API.post({
            path,
            data: {
                orderNo,
                payMethod: payment.payMethod,
                ...data?.info,
                ...data?.totalSummary,
                nomemberCode: data?.nomemberCode,
                usePointAmount: discountInfo.discountPoint,
                couponDiscountAmount: discountInfo.discountCouponPoint,
                useCouponList: discountInfo.useCouponList,
            },
        })
            .then((res) => {
                document.getElementById("ordr_idxx").value = orderNo;

                if (isMobile) {
                    window.open("", "PAYMENT");
                    document.getElementById("orderForm").action = "https://payment.flexme.com/paymentMobileRequest";
                } else {
                    window.open("", "PAYMENT", "width=771,height=578");
                }
                document.getElementById("orderForm").target = "PAYMENT";
                document.getElementById("orderForm").submit();
            })
            .catch((e) => console.log(e));
    };

    const onRequireCheck = () => {
        if (data?.overseasList.length > 0 && !data?.info.personalCustomsCode) {
            swal({ title: "알림", text: "해외 배송 상품건이 있습니다. 개인통관고유부호를 입력해 주세요." });
            return false;
        }

        /*
        if (!data?.info.recipientName) {
            swal({ title: "알림", text: "받는사람 이름을 입력하세요." });
            return false;
        }
		*/
        if (!data?.info.recipientMobile) {
            swal({ title: "알림", text: "받는사람 연락처를 입력하세요." });
            return false;
        }
        /*
        if (!data?.info.recipientZipcode) {
            swal({ title: "알림", text: "받는사람 우편번호를 입력하세요." });
            return false;
        }
        if (!data?.info.recipientAddr) {
            swal({ title: "알림", text: "받는사람 주소를 입력하세요." });
            return false;
        }
        if (!data?.info.recipientAddrDtl) {
            swal({ title: "알림", text: "받는사람 상세주소를 입력하세요." });
            return false;
        }
		*/
        if (!iam?.idx) {
            if (!data?.info.nomemberName) {
                swal({ title: "알림", text: "주문자 이름을 입력하세요." });
                return false;
            }
            if (!data?.info.nomemberMobile) {
                swal({ title: "알림", text: "주문자 연락처를 입력하세요." });
                return false;
            }
            /*
            if (!data?.info.nomemberEmail) {
                swal({ title: "알림", text: "주문자 이메일을 입력하세요." });
                return false;
            }
			*/
        }

        return true;
    };

    const onClickCert = (nomemberCode) => {
        _API.post({
            path: "/user/cert",
            data: { userId: iam?.id || nomemberCode || data?.nomemberCode || "", cParams: JSON.stringify({ memberIdx: iam?.id || nomemberCode || data?.nomemberCode || "" }) },
        }).then((res) => {
            const { online_url: pc_url, mobile_url } = res.data;

            if (isMobile) {
                window.open(mobile_url, "CERT");
            } else {
                window.open(pc_url, "CERT", "width=400,height=600");
            }
        });
    };

    const onComplete = () => {
        history.push(`/checkout/finish/${orderIdx}`);
    };

    const onCancel = () => {
        swal({ title: "알림", text: "결제가 취소 되었습니다." });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "COMPLETE":
                    onComplete();
                    break;
                case "CANCEL":
                    onCancel();
                    break;
                case "CERT_COMPLETE":
                    setIsCert("Y");
                    saveIam({ ...iam, name: data.data?.name, mobile: data.data?.mobile, birthday: data.data?.birthday, gender: data.data?.gender, isCertMobile: "Y" });
                    swal({ title: "알림", text: "본인인증이 완료되었습니다. 다시 한번 결제하기 버튼을 클릭하여 주세요." });

                    break;
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("message", onReceivedMessage);

        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        if (iam) {
            setIsCert(iam.isCertMobile);
        }
    }, [iam]);

    const saveNoMember = (callback) => {
        _API.post({
            path: "/payment/nomember/save",
            data: {
                orderIdx: data?.info?.orderIdx,
                name: data?.info?.nomemberName,
                mobile: data?.info?.nomemberMobile,
                email: data?.info?.nomemberEmail,
                personalCustomsCode: data?.info?.personalCustomsCode,
                agrTerm: "Y",
                recipientName: data?.info?.recipientName,
                recipientMobile: data?.info?.recipientMobile,
                recipientAddr: data?.info?.recipientAddr,
                recipientAddrDtl: data?.info?.recipientAddrDtl,
                recipientZipcode: data?.info?.recipientZipcode,
                reqDesc: data?.info?.reqDesc,
            },
        }).then((res) => {
            setData({ ...data, nomemberCode: res.data?.nomemberCode });
            callback(res.data?.nomemberCode);
        });
    };

    const onClickPayment = () => {
        if (!onRequireCheck()) {
            return;
        }

        // 비회원 구매
        if (!iam?.idx && !data?.nomemberCode) {
            saveNoMember((code) => {
                onClickCert(code);
            });
            return;
        }

        if (isCert === "Y") {
            savePayment();
        } else {
            onClickCert();
        }
    };

    const checkIsSame = (tempData) => {
        setData({ ...data, info: { ...data.info, ...tempData } });
    };

    const changeDiscount = (e) => {
        if (e && data && data.info) {
            setDiscountInfo({ discountPoint: e.discountPoint, discountCouponPoint: e.discountCouponPoint, useCouponList: e.useCouponList });
            setData({ ...data, info: { ...data.info, payAmount: data?.totalSummary?.totalAmount - e.discountPoint - e.discountCouponPoint } });
        }
    };

    return (
        <Layout {...props} hideTop={true}>
            <Wrapper>
                <OrderTop active="2" {...props}>
                    주문/결제
                </OrderTop>
                {iam?.idx ? (
                    <Delivery {...props} isOverSeas={data?.overseasList.length > 0} data={data?.info} onChange={onInfoChange} onDeliverySelect={onDeliverySelect} />
                ) : (
                    <DeliveryNoMember {...props} isOverSeas={data?.overseasList.length > 0} data={data?.info} onChange={onInfoChange} checkIsSame={checkIsSame} onDeliverySelect={onDeliverySelect} />
                )}
                {/* <Delivery {...props} isOverSeas={data?.overseasList.length > 0} data={data?.info} onChange={onInfoChange} onDeliverySelect={onDeliverySelect} /> */}

                <ProductList {...data} />
                <div className="split" />
                <Payment {...props} payment={payment} totalSummary={data?.totalSummary} changeDiscount={changeDiscount} onChange={(key, value) => setPayment({ ...payment, [key]: value })} />
                <div className="split" />

                <div className="total-summary">
                    <h3>결제금액</h3>
                    <dl>
                        <dt>총 상품 금액</dt>
                        <dd>{_U.numberFormat(data?.totalSummary?.productAmount)} 원</dd>
                    </dl>
                    <dl>
                        <dt>배송비</dt>
                        <dd>{_U.numberFormat(data?.totalSummary?.shippingAmount)} 원</dd>
                    </dl>
                    <dl>
                        <dt>상품 할인</dt>
                        <dd>
                            <b>{_U.numberFormat(data?.totalSummary?.discountAmount)} 원</b>
                        </dd>
                    </dl>
                    {iam && iam.idx && (
                        <Fragment>
                            <dl>
                                <dt>쿠폰 사용</dt>
                                <dd>
                                    <b>
                                        <span>{_U.addComma(discountInfo?.discountCouponPoint)}</span> 원
                                    </b>
                                </dd>
                            </dl>
                            <dl>
                                <dt>적립금 사용</dt>
                                <dd>
                                    <b>
                                        <span>{_U.addComma(discountInfo?.discountPoint)}</span> 원
                                    </b>
                                </dd>
                            </dl>
                        </Fragment>
                    )}
                </div>
                <div className="sum">
                    총 결제 예상 금액
                    <b>{_U.numberFormat(data?.totalSummary?.totalAmount - discountInfo?.discountPoint - discountInfo?.discountCouponPoint)}원</b>
                </div>
                <div className="buttons">
                    <button onClick={() => history.goBack()}>취소</button>
                    <button className="action" ref={buyBtnRef} onClick={onClickPayment}>
                        결제하기
                    </button>
                </div>
                {/* KCP 결제를 위한 FORM */}
                <form id="orderForm" method="post" action="https://payment.flexme.com/paymentRequest">
                    <input type="hidden" name="pay_method" value={payment?.payMethod || ""} />
                    <input type="hidden" name="ordr_idxx" id="ordr_idxx" value="" />
                    <input type="hidden" name="good_name" value={data?.info.orderTitle || ""} />
                    <input type="hidden" name="good_mny" value={data?.info.payAmount || ""} />
                    <input type="hidden" name="buyr_name" value={data?.info.buyerName || ""} />
                    <input type="hidden" name="buyr_tel1" value={data?.info.buyerMobile || ""} />
                    <input type="hidden" name="buyr_mail" value={data?.info.buyerEmail || ""} />
                    <input type="hidden" name="serverStage" value={process.env.STAGE || ""} />
                    <input type="hidden" name="user_id" value={iam?.id || ""} />
                    <input type="hidden" name="kakaopay_direct" value={payment?.payMethod === "kakaopay" ? "Y" : "N"} />
                    <input type="hidden" name="naverpay_direct" value={payment?.payMethod === "naverpay" ? "Y" : "N"} />
                    <input type="hidden" name="naverpay_point_direct" value={payment?.payMethod === "naverpay" ? "Y" : "N"} />
                </form>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
    width: ${(props) => props.theme.layout.productWidth}px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 80px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }

    div.split {
        display: none;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            background: #f8f8f8;
            width: 100%;
            height: 10px;
            display: block;
            margin-top: 16px;
        }
    }

    div.total-summary {
        margin-top: 40px;
        border-bottom: 1px solid #efefef;
        padding-bottom: 24px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 21px;
            border-bottom: 0;
        }

        > h3 {
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 18px;
            line-height: 26px;
            font-weight: normal;
            margin: 0px;
        }

        dl {
            display: flex;
            justify-content: space-between;
            margin: 16px 0 0 0;

            :first-child {
                margin: 0;
            }

            :last-child {
                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    padding-bottom: 24px;
                    border-bottom: 1px solid #efefef;
                }
            }
        }

        dl > dt {
            flex: 1;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #b8b8b8;
            font-size: 14px;
            line-height: 20px;
        }

        dl > dd {
            flex: 1;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 17px;
            line-height: 20px;
            text-align: right;

            span {
                font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            }

            b {
                color: #e32222;
                font-weight: normal;
            }
        }
    }

    > div.sum {
        margin-top: 16px;
        text-align: right;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #777777;
        font-size: 13px;
        line-height: 18px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 21px 24px;
        }

        b {
            margin-left: 16px;
            color: #e32222;
            font-weight: normal;
            font-size: 24px;
            line-height: 35px;
            span {
                font-family: ${(props) => props.theme.fontFamily.nanumEB};
            }
        }
    }

    > div.buttons {
        margin-top: 30px;
        text-align: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            display: flex;
            margin-top: 0px;
        }
        button {
            border: 1px solid #005a60;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #005a60;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                flex: 1;
                width: 100%;
                margin-left: 0px !important;
            }
        }

        button.action {
            border: 1px solid #005a60;
            background: #005a60;
            color: #ffffff;
        }
    }
`;

const ProductList = (props) => {
    const { localList, overseasList } = props;

    return (
        <PLWrapper>
            {localList?.length > 0 && (
                <div className="box">
                    <h3>한국 배송 상품</h3>
                    {localList?.map((item, index) => (
                        <ProductItem key={index} {...item} />
                    ))}
                </div>
            )}

            {overseasList?.length > 0 && (
                <div className="box">
                    <h3>해외 배송 상품</h3>
                    {overseasList?.map((item, index) => (
                        <ProductItem key={index} {...item} />
                    ))}
                </div>
            )}
        </PLWrapper>
    );
};

const PLWrapper = styled.div`
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding: 0 21px;
    }

    div.box {
        margin-top: 40px;
    }

    div.box > h3 {
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
        margin: 0px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: 1px solid #3d3d3d;
            padding-bottom: 16px;
        }
    }
`;

const ProductItem = (props) => {
    return (
        <PIWrapper>
            <img src={props.productFileSignedUrl} onClick={() => window.open(`/#/product/${props.productCode}`)} />
            <div className="info">
                <div>
                    <div className="brand">{props.productBrandName}</div>
                    <h4 className="title">{props.productName}</h4>
                    <div className="option">
                        {props.productOptItemList?.map((item, index) => (
                            <div key={index}>
                                {item.productOptionName} : {item.productOptionItemName} / 수량 {item.quantity}개
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="price">
                상품 {_U.numberFormat(props.totalAmountSale)}&nbsp;+&nbsp;
                {props.shippingMethod === "FREE" ? (
                    "배송비 무료"
                ) : (
                    <>
                        배송비 <span>{_U.addComma(props.totalShippingAmount)}</span> 원
                    </>
                )}
                <b>
                    <small>합계 &nbsp;</small>
                    <span>{_U.numberFormat(props.totalAmountPayment)}</span> 원
                </b>
            </div>
        </PIWrapper>
    );
};
const PIWrapper = styled.div`
    border-bottom: 1px solid #efefef;
    display: flex;
    padding: 24px 0;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        flex-wrap: wrap;

        :last-child {
            border-bottom: 0;
        }
    }

    > img {
        flex-basis: 90px;
        width: 90px;
        height: 90px;
        object-fit: cover;
        cursor: pointer;
    }

    > div.info {
        flex: 1;
        padding: 0 16px;
        display: flex;
        align-items: center;

        div.brand {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #3d3d3d;
            font-size: 13px;
            line-height: 19px;
        }

        h4.title {
            margin: 0px;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
        }

        div.option {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #b8b8b8;
            font-size: 13px;
            line-height: 19px;
        }
    }

    > div.price {
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex-basis: 100%;
            border-top: 1px solid #efefef;
            padding-top: 20px;
            margin-top: 24px;
            flex-wrap: wrap;
        }

        span {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            padding-top: 2px;
        }

        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #777777;
        font-size: 13px;
        line-height: 18px;

        > b {
            color: #e32222;
            position: relative;
            margin-left: 24px;
            padding-left: 24px;
            font-weight: normal;

            :before {
                content: "";
                position: absolute;
                left: 0;
                top: 8px;
                width: 1px;
                height: 17px;
                background: #efefef;
            }

            font-size: 18px;
            font-family: ${(props) => props.theme.fontFamily.medium};
            line-height: 26px;

            small {
                font-size: 14px;
            }

            span {
                font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            }
        }
    }
`;

const Payment = (props) => {
    const { iam } = useContext(AppContext);
    const { location, history, match } = props;
    const { payment, onChange, totalSummary } = props;
    const [useCouponList, setUseCouponList] = useState([]);
    const [availCouponCnt, setAvailCouponCnt] = useState(0);
    const [remainPoint, setRemainPoint] = useState(0);
    const [usePoint, setUsePoint] = useState(0);
    const [discountPoint, setDiscountPoint] = useState(0);
    const [discountCouponPoint, setDiscountCouponPoint] = useState(0);

    const handleCouponList = () => {
        Dialog({ title: "쿠폰 목록", body: <OrderCouponList {...{ location, history, match, payAmount: totalSummary?.productAmount, useCouponList, validationCoupon }} /> })
            .then((data) => {
                if (data) {
                    const check = validationCoupon(data);
                    if (check.result) {
                        setUseCouponList((o) => [...o, data]);
                        setDiscountCouponPoint(check.couponDiscountSum);
                        setDiscountPoint(check.discountPoint);
                    } else {
                        swal({ title: "알림", text: check.message });
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const loadIssueAvailSummary = () => {
        if (!iam || !iam.idx) {
            return;
        }
        // 쿠폰 및 잔여 포인트 조회
        let path = "/user/issue/avail/summary";
        const alreadyUseCouponIdxList = JSON.stringify(useCouponList.map((item) => item.idx));
        // console.log("totalSummary", totalSummary)
        _API.get({ path, data: { payAmount: totalSummary?.productAmount, alreadyUseCouponIdxList } })
            .then((res) => {
                const { availCouponCnt: cnt, remainPoint: point } = res.data;
                setAvailCouponCnt(cnt || 0);
                setRemainPoint(point || 0);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const changeUsePoint = () => {
        const check = validationReward();
        if (!check.result) {
            swal({ title: "알림", text: check.message });
            setUsePoint(0);
            setDiscountPoint(0);
        } else {
            setDiscountPoint(check.discountPoint);
            setDiscountCouponPoint(check.couponDiscountSum);
        }
    };

    const validationReward = () => {
        /**
         * 사용포인트 체크 하여 할인가능 여부 체크
         */
        let payAmount = parseInt(totalSummary.saleAmount);
        //정액제 쿠폰 할인액 합
        let flatCouponDiscountSum = 0;
        //정률제 쿠폰 할인율 합
        let rateCouponDiscountSum = 0;

        // console.log("결제정보 할인전 : ", payAmount, usePoint, discountPoint, remainPoint);

        if (usePoint > payAmount) {
            return { result: false, message: "사용 적립금이 결제금액보다 많습니다." };
        }
        if (usePoint > remainPoint) {
            return { result: false, message: "사용가능한 적립금까지만 사용가능 합니다." };
        }

        //기존 쿠폰 할인 액 적용
        for (let i in useCouponList) {
            const useCoupon = useCouponList[i];
            if (useCoupon.discountType === "FLAT") {
                flatCouponDiscountSum += parseInt(useCoupon.discountValue);
            } else {
                rateCouponDiscountSum += parseInt(useCoupon.discountValue);
            }
        }
        //할인 적용후 결제예정금액
        let discountAfterPayAmount = payAmount - usePoint - flatCouponDiscountSum - Math.floor((payAmount - usePoint - flatCouponDiscountSum) * (rateCouponDiscountSum / 100));

        // console.log("결제정보 할인후: ", payAmount, usePoint, discountPoint, remainPoint, flatCouponDiscountSum, rateCouponDiscountSum, discountAfterPayAmount);

        const couponDiscountSum = payAmount - (discountAfterPayAmount + usePoint);
        if (payAmount <= usePoint + couponDiscountSum) {
            //할인 총액 보다 결제금액이 작으면
            return { result: false, message: "할인 총액보다 상품금액이 작습니다." };
        }
        return { result: true, payAmount, discountPoint: usePoint, flatCouponDiscountSum, rateCouponDiscountSum, discountAfterPayAmount, couponDiscountSum };
    };

    const validationCoupon = (coupon) => {
        /**
         * 사용쿠폰을 체크 하여 할인가능 여부 체크
         */

        //기본 결제 예정금액
        let payAmount = parseInt(totalSummary.saleAmount);
        //정액제 쿠폰 할인액 합
        let flatCouponDiscountSum = 0;
        //정률제 쿠폰 할인율 합
        let rateCouponDiscountSum = 0;

        // console.log("결제정보 : ", payAmount, discountPoint);

        //할인 적용후 결제예정금액
        let discountAfterPayAmount = 0;

        //기존 쿠폰 할인값 합산
        for (let i in useCouponList) {
            const useCoupon = useCouponList[i];
            if (useCoupon.discountType === "FLAT") {
                flatCouponDiscountSum += useCoupon.discountValue;
            } else {
                rateCouponDiscountSum += useCoupon.discountValue;
            }
        }

        discountAfterPayAmount = payAmount - discountPoint - flatCouponDiscountSum - Math.floor((payAmount - discountPoint - flatCouponDiscountSum) * (rateCouponDiscountSum / 100));

        // console.log("기존 할인정보::", payAmount, discountPoint, flatCouponDiscountSum, rateCouponDiscountSum, discountAfterPayAmount);

        //추가 쿠폰 할인 적용
        if (coupon) {
            const discountType = coupon.discountType;
            const payAmountMin = parseInt(coupon.payAmountMin);
            const discountMax = parseInt(coupon.discountMax);
            const discountValue = parseInt(coupon.discountValue);

            // console.log("쿠폰정보 : ", coupon.idx, discountType, payAmountMin, discountMax, discountValue);

            //쿠폰 사용가능 최소결제금액보다 결제금액이 적을때
            if (payAmount < payAmountMin) {
                return { result: false, message: "결제금액이 쿠폰 사용가능 최소금액보다 적습니다." };
            }

            const dupUseCnt = useCouponList?.filter((item) => item.dupUseYn === "Y").length || 0;
            // console.log("coupon check 중복사용쿠폰::", dupUseCnt, coupon.dupUseYn);
            if (dupUseCnt > 0 && coupon.dupUseYn !== "Y") {
                //중복사용 불가 쿠폰 1개 초과 방지
                return { result: false, message: "중복사용 불가쿠폰입니다." };
            }

            if (discountType === "FLAT") {
                if (discountAfterPayAmount < discountValue) {
                    //이전 할인 적용 후 결제예정금액이 선택 쿠폰의 할인액보다 작을때
                    //결제예정금액 만큼 만 할인
                    flatCouponDiscountSum += discountAfterPayAmount;
                    discountAfterPayAmount -= discountAfterPayAmount; //discountAfterPayAmount=0 과 같다
                } else {
                    flatCouponDiscountSum += discountValue;
                    discountAfterPayAmount -= discountValue;
                }
            } else {
                if ((payAmount - discountPoint - flatCouponDiscountSum) * ((rateCouponDiscountSum + discountValue) / 100) > discountMax) {
                    //선택쿠폰 할인 적용시 최종 할인금액이 선택 쿠폰의 최대할인금액보다 클 경우
                    // 최대할인 금액으로 할인 적용
                    flatCouponDiscountSum += discountMax;
                    discountAfterPayAmount -= discountMax;
                } else {
                    //선택쿠폰 할인 적용 시 최종할인 금액이 선택 쿠폰의 최대 할인금액 보다 작을 경우
                    //선택 쿠폰 할인율을 기 사용 쿠폰의 할인율과 합산하여 계산
                    rateCouponDiscountSum += discountValue;
                    discountAfterPayAmount = payAmount - discountPoint - flatCouponDiscountSum - Math.floor((payAmount - discountPoint - flatCouponDiscountSum) * (rateCouponDiscountSum / 100));
                }
            }
        }

        // console.log("선택 쿠폰 적용 후 할인정보::", payAmount, discountPoint, flatCouponDiscountSum, rateCouponDiscountSum, discountAfterPayAmount);

        const couponDiscountSum = payAmount - (discountAfterPayAmount + discountPoint);
        if (payAmount <= couponDiscountSum + discountPoint) {
            //할인 총액 보다 결제금액이 작으면
            return { result: false, message: "할인 총액보다 상품금액이 작습니다." };
        }

        // console.log("최종 할인정보::", payAmount, discountPoint, flatCouponDiscountSum, rateCouponDiscountSum, couponDiscountSum, discountAfterPayAmount);

        return { result: true, payAmount, discountPoint, flatCouponDiscountSum, rateCouponDiscountSum, couponDiscountSum, discountAfterPayAmount };
    };

    const cancelCoupon = (idx) => {
        setUseCouponList(useCouponList.filter((item) => item.idx !== idx));
    };

    useEffect(() => {
        if (totalSummary) {
            loadIssueAvailSummary();
            const check = validationCoupon();
            if (check) {
                setDiscountCouponPoint(check.couponDiscountSum);
                setDiscountPoint(check.discountPoint);
            }
        }
    }, [useCouponList, totalSummary]);

    useEffect(() => {
        props.changeDiscount && props.changeDiscount({ discountPoint, discountCouponPoint, useCouponList: useCouponList.map((item) => item.idx) });
    }, [discountPoint, discountCouponPoint]);

    return (
        <PAWrapper>
            <h3>결제수단</h3>
            <ul>
                {_DEF.PAYMENT_METHOD_KCP.map((pm, index) => (
                    <li key={index}>
                        <Radio title={pm.label} onClick={() => onChange("payMethod", pm.value)} checked={payment.payMethod === pm.value} />
                    </li>
                ))}
            </ul>

            {payment.payMethod === "DEPOSIT" && <PaymentDeposit />}
            {payment.payMethod === "CARD" && <PaymentCard />}
            {iam && iam.idx && (
                <div className="coupon">
                    <h3>쿠폰/적립금</h3>

                    <div className="items">
                        <div>
                            <h4>쿠폰 적용</h4>
                            <div className="box">
                                {useCouponList &&
                                    useCouponList.length > 0 &&
                                    useCouponList.map((item, i) => (
                                        <div key={i} className="useItem">
                                            <div className="useTitle">
                                                {item.title}
                                                <Close onClick={(e) => cancelCoupon(item.idx)}>
                                                    <img
                                                        src="/icons/ic_close_gray_10.png"
                                                        srcSet={getSrcSet(["/icons/ic_close_gray_10.png", "/icons/ic_close_gray_10@2x.png", "/icons/ic_close_gray_10@3x.png"])}
                                                        alt="닫기"
                                                        width="10"
                                                        height="10"
                                                    />
                                                </Close>
                                            </div>
                                        </div>
                                    ))}
                                {(!useCouponList || useCouponList.length == 0) && <p>쿠폰을 사용해 보세요.</p>}
                                <div className="availCoupon" onClick={handleCouponList}>
                                    <b>사용 가능한 쿠폰 {availCouponCnt}장</b>
                                    <Arrow>
                                        <img
                                            src="/icons/ic_right_arrow_59.png"
                                            srcSet={getSrcSet(["/icons/ic_right_arrow_59.png", "/icons/ic_right_arrow_59@2x.png", "/icons/ic_right_arrow_59@3x.png"])}
                                            alt="닫기"
                                            width="5"
                                            height="9"
                                        />
                                    </Arrow>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4>적립금 사용</h4>
                            <div className="box horizon">
                                <Input
                                    placeholder="숫자만 입력해 주세요."
                                    styles={{ width: "calc(100% - 20px)" }}
                                    value={usePoint}
                                    onChange={(e) => {
                                        if (!e.target.value) setUsePoint(0);
                                        else setUsePoint(parseInt(e.target.value.replace(/[^0-9]/gi, "")));
                                    }}
                                />
                                <button className="action" onClick={changeUsePoint}>
                                    적용
                                </button>
                                <small>
                                    사용 사능한 적립금 <b>{_U.addComma(remainPoint - discountPoint)}</b>원
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </PAWrapper>
    );
};

const PaymentDeposit = (props) => {
    return (
        <>
            <div className="bg-box">
                <dl>
                    <dt>입금은행</dt>
                    <dd>
                        <SelectBox
                            items={[
                                { label: "농협", value: "BANK1" },
                                { label: "국민은행", value: "BANK2" },
                            ]}
                            value={""}
                            onChange={() => {}}
                        />
                    </dd>
                </dl>
                <hr />
                <dl>
                    <dt>입금기한</dt>
                    <dd>2021년 11월 13일 14시 12분까지</dd>
                </dl>
                <small>*현금으로 결제한 모든 금액은 우리은행과 채무지급보증계약을 체결하여 고객님의 안전건래를 보장하고 있습니다.</small>
            </div>
            <p>
                <b>무통장입금 시 유의사항</b>
                <br />
                - 입금완료 후 상품품절로 인해 자동취소된 상품은 환불 처리해 드립니다.
                <br />
                - 무통장입금 결제 시 부분취소가 불가하며 전체취소 후 다시 주문하시기 바랍니다.
                <br />- 은행 이체 수수료가 발생될 수 있습니다. 입금시 수수료를 확인해주세요.
            </p>
            <h3>현금영수증</h3>
            <div className="solid-box">
                <div className="checkbox">
                    <CheckBox inline={true} onClick={() => {}} title="현금영수증 신청" checked={true} />
                </div>
                <hr />
                <div className="radio">
                    <Radio inline={true} onClick={() => {}} title="소득공제" checked={true} />
                    <Radio inline={true} onClick={() => {}} title="지출증빙" />
                </div>
                <div className="select">
                    <SelectBox
                        items={[
                            { label: "휴대폰번호", value: "MOBILE" },
                            { label: "현금영수증카드", value: "CARD" },
                        ]}
                        value={""}
                        onChange={() => {}}
                    />
                    <Input placeholder="숫자만 입력해 주세요." styles={{ width: "230px" }} />
                </div>
                <small>*해외구매대행 / 직구 구매금액은 현금영수증 발행이 제외됩니다. </small>
                <p>
                    <CheckBox inline={true} onClick={() => {}} title="현금영수증 발급을 위하여 휴대폰번호 또는 현금영수증카드번호를 수집하며, 5년간 처리에 동의합니다." />
                </p>
            </div>
        </>
    );
};

const PaymentCard = (props) => {
    return (
        <>
            <div className="bg-box">
                <dl>
                    <dt>카드선택</dt>
                    <dd>
                        <SelectBox
                            items={[
                                { label: "국민카드", value: "CARD1" },
                                { label: "현대카드", value: "CARD2" },
                            ]}
                            value={""}
                            onChange={() => {}}
                        />
                    </dd>
                </dl>
                <hr />
                <dl>
                    <dt>할부기간</dt>
                    <dd>
                        <SelectBox
                            items={[
                                { label: "일시불", value: "" },
                                { label: "2개월", value: "2" },
                                { label: "3개월", value: "3" },
                            ]}
                            value={""}
                            onChange={() => {}}
                        />
                    </dd>
                </dl>
                <small>* 할부는 50,000원 이상 가능합니다.</small>
            </div>
        </>
    );
};

const PAWrapper = styled.div`
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #efefef;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding: 0 21px;
        border-bottom: 0px;
    }

    h3 {
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
        margin: 0px;
    }

    ul {
        list-style: none;
        margin: 24px 0 0 0;
        padding: 0;
        display: table;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: block;
        }
    }

    ul > li {
        display: table-cell;
        padding-right: 40px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: block;
            padding-bottom: 16px;
        }

        > div {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #777777;
            font-size: 14px;
            line-height: 20px;
        }
    }

    hr {
        border: 0;
        border-top: 1px solid #dedede;
        margin: 20px 0;
        display: block;
        height: 1px;
    }

    div.bg-box {
        margin-top: 12px;
        border: 1px solid #dfdfdf;
        background: #f9f9f9;
        padding: 20px;

        dl {
            display: table;
            margin: 0;

            :first-child {
                margin: 0;
            }
        }

        dl > dt {
            display: table-cell;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 13px;
            line-height: 17px;
        }

        dl > dd {
            display: table-cell;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #3d3d3d;
            font-size: 13px;
            line-height: 18px;
            padding: 0 18px;

            select {
                height: 26px;
                color: #3d3d3d;
                font-size: 12px;
                font-family: ${(props) => props.theme.fontFamily.light};
                line-height: 16px;
                padding: 5px 10px;
                min-width: 110px;
            }
        }

        small {
            color: #b8b8b8;
            font-size: 13px;
            font-family: ${(props) => props.theme.fontFamily.regular};
            line-height: 18px;
            margin-top: 7px;
            display: block;
        }
    }

    p {
        margin: 8px 0 40px 0;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #b8b8b8;
        font-size: 13px;
        line-height: 18px;

        b {
            font-weight: normal;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            margin-bottom: 6px;
        }
    }

    div.solid-box {
        border: 1px solid #dfdfdf;
        padding: 20px;
        margin-top: 12px;

        div.checkbox > div {
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 13px;
            line-height: 18px;
        }

        div.radio > div {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #777777;
            font-size: 14px;
            line-height: 20px;
            margin-right: 40px;
        }

        div.select {
            display: block;
            margin-top: 12px;

            > div > select {
                font-family: ${(props) => props.theme.fontFamily.regular};
                color: #777777;
                font-size: 13px;
                line-height: 19px;
            }

            > div {
                display: inline-block;

                :last-child {
                    margin-left: 12px;
                }
            }
        }
        p {
            margin: 0;
            color: #777777;
            font-size: 13px;
            font-family: ${(props) => props.theme.fontFamily.regular};
            line-height: 18px;
            margin-top: 17px;
            padding: 0;
        }

        small {
            color: #b8b8b8;
            font-size: 13px;
            font-family: ${(props) => props.theme.fontFamily.regular};
            line-height: 18px;
            margin-top: 7px;
            display: block;
        }
    }

    div.coupon {
        margin-top: 40px;
    }

    div.coupon > div.items {
        display: flex;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex-direction: column;
        }
    }

    div.coupon > div.items > div {
        flex: 1;
        margin-right: 10px;

        :last-child .box:before {
            content: "";
            width: 1px;
            height: 100%;
            background: #efefef;
            left: -11px;
            top: 0;
            position: absolute;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-right: 0px;
            :last-child .box:before {
                content: unset;
            }
        }

        /* :first-child .box {
			background-image: url("/icons/ic_arrow_right.png");
			background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_right.png", "/icons/ic_arrow_right@2x.png", "/icons/ic_arrow_right@3x.png"])});
			background-repeat: no-repeat;
			background-size: 6px 10px;
			background-position: right 15px center;
		} */

        h4 {
            font-weight: normal;
            color: #b8b8b8;
            font-size: 14px;
            font-family: ${(props) => props.theme.fontFamily.regular};
            line-height: 20px;
        }

        .box {
            border: 1px solid #dfdfdf;
            background: #f9f9f9;
            min-height: 48px;
            color: #3d3d3d;
            font-size: 13px;
            font-family: ${(props) => props.theme.fontFamily.regular};
            line-height: 18px;
            padding: 20px;
            display: grid;
            align-items: center;
            position: relative;

            p {
                margin: 0;
                color: #3d3d3d;
            }

            b {
                color: #e32222;
            }
            .useItem {
                background-color: #fcfcfc;
                height: 26px;
                color: #3d3d3d;
                border: solid 1px #dfdfdf;
                margin-bottom: 4px;

                .useTitle {
                    font-size: 13px;
                    padding: 4px 10px 3px 10px;
                }
            }
            .availCoupon {
                cursor: pointer;
            }
        }

        .box.horizon {
            grid-template-columns: auto 70px;
            gap: 0 13px;
            height: 48px;

            > div {
                width: 100%;
            }

            button.action {
                background: #e32222;
                color: #ffffff;
                padding: 7px 0;
                border: #e32222 1px solid;
            }

            small {
                font-size: 12px;
                color: #777777;
            }
        }

        :last-child {
            margin-left: 10px;
            margin-right: 0;
            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                margin-left: 0px;
            }
        }
    }
`;

const Close = styled.button`
    /* position: absolute; */
    margin: 0;
    padding: 0;
    /* top: 28px; */
    /* right: 30px; */
    width: 10px;
    height: 10px;
    border: none;
    background: transparent;
    float: right;
    padding-top: 2px;
    /* @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		top: 21px;
		right: 21px;
		z-index: 2;
	} */
`;

const Arrow = styled.button`
    margin: 0 0 0 8px;
    padding: 0;
    width: 5px;
    height: 9px;
    border: none;
    background: transparent;
    /* float:right;
	padding-top:2px; */
    /* @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		top: 21px;
		right: 21px;
		z-index: 2;
	} */
`;
