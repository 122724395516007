/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Layout, { Container } from "../Layout";
import Dialog from "../../UI/Dialog";

import Sender from "./Live.Sender";
import Cams from "./Live.Cams";
import Chatting from "./Live.Chatting";
import Product from "./Live.Product";
import { getSrcSet } from "../../UI/Assets";

export default (props) => {
    const { location, history, match } = props;
    const { auth, iam } = useContext(AppContext);
    const [modifyTitle, setModifyTitle] = useState(false);
    const [title, setTitle] = useState("");

    if (!auth || iam?.isStreamer !== "Y") {
        history.goBack();
        return null;
    }

    const { idx } = match.params;
    if (!idx) {
        history.goBack();
        return null;
    }

    const [information, setInformation] = useState(null);

    const loadInformation = () => {
        _API.get({
            path: "/stream/information",
            data: { idx },
        })
            .then((res) => res.data)
            .then((data) => {
                const { title, memberIdx, status } = data;
                if (memberIdx !== iam.idx) {
                    history.goBack();
                    return;
                }

                switch (status) {
                    case "N":
                    case "D":
                        history.goBack();
                        return;
                }

                setInformation(data);
            });
    };

    useEffect(() => {
        loadInformation();
    }, [idx]);

    const onSaveTitle = () => {
        _API.post({
            path: "/stream/title",
            data: {
                idx,
                title,
            },
        }).then(() => {
            setModifyTitle(false);
            loadInformation();
        });
    };

    useEffect(() => {
        setTitle(information?.title);
    }, [information?.title]);

    return (
        <Layout {...props} hideMenu isStreamPage={true}>
            <LiveContainer>
                <img className="logo" src="/images/logo.png" srcSet={getSrcSet(["/images/logo.png", "/images/logo@2x.png", "/images/logo@3x.png"])} alt="MOAA" />
                <Title>
                    {modifyTitle ? (
                        <>
                            <input type="text" name="title" value={title || ""} onChange={(e) => setTitle(e.target.value)} />
                            <button className="btn-modify" onClick={() => onSaveTitle()}>
                                확인
                            </button>
                            <button className="btn-cancel" onClick={() => setModifyTitle(false)}>
                                취소
                            </button>
                        </>
                    ) : (
                        <>
                            {information?.title} <i className="fa fa-pencil" onClick={() => setModifyTitle(true)}></i>
                        </>
                    )}
                </Title>
                <Wrapper>
                    <Sender roomIdx={idx} roomData={information} />
                    <Cams roomIdx={idx} roomData={information} />
                    <Chatting roomIdx={idx} roomData={information} />
                    <Product roomIdx={idx} {...props} />
                </Wrapper>
            </LiveContainer>
        </Layout>
    );
};

const Title = styled.div`
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 22px;
    color: #3d3d3d;
    line-height: 32px;
    padding-left: 16px;

    i {
        color: #00788d;
        margin-left: 10px;
        cursor: pointer;
    }

    input {
        border: 1px solid #efefef;
        padding: 8px 10px;
        min-width: 500px;
    }

    .btn-modify {
        border: 1px solid #00788d;
        background: #00788d;
        color: #ffffff;
        padding: 7px 16px;
        vertical-align: middle;
    }

    .btn-cancel {
        border: 1px solid #777777;
        background: #ffffff;
        color: #777777;
        padding: 7px 16px;
        vertical-align: middle;
    }
`;

const Wrapper = styled.div`
    display: flex;
    margin-top: 8px;
`;

const LiveContainer = styled.div`
    width: 100%;
    margin: 50px auto;
    position: relative;

    img.logo {
        position: fixed;
        left: 16px;
        top: 15px;
        height: 25px;
        margin-right: 10px;
    }

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        margin: 0;
    }
`;

const ProductWrapper = styled.div``;
