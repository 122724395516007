/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import Dialog from "../../UI/Dialog";
import { MypageTop } from "../../UI/Header";
import { getBackgroundSet } from "../../UI/Assets";

import Menu from "./Mypage.Menu";

export default (props) => {
	const { location, history, match } = props;

	const [keyword, setKeyword] = useState("");
	const [list, setList] = useState([]);
	const [paging, setPaging] = useState(null);

	const loadList = (data) => {
		_API.get({ path: `/bbs/my`, data: { ...data, bbsCode: "PRODUCTQNA" } }).then((res) => {
			const { list, paging } = res.data;
			console.log(list);
			setList(list);
			setPaging(paging);
		});
	};

	const movePage = (page) => {
		ToPage(location, history, { keyword, page });
		window.scrollTo(0, 0);
	};

	const onSearch = (e) => {
		ToPage(location, history, { keyword, page: 1 });
	};

	const onRefresh = () => {
		loadList({ keyword, page: paging.pageNo });
	};

	useEffect(() => {
		const params = GetParams(location);
		if (params) {
			setKeyword(params.keyword);
			loadList(params);
		} else {
			setKeyword("");
			loadList({ page: 1 });
		}
	}, [location]);
	let virtualNo = paging ? paging.virtualRecordNo : -1;

	if (!_CK.isLogin()) {
		history.goBack();
		return null;
	}

	return (
		<Layout {...props} hideTop={true}>
			<Container>
				<MypageTop {...props}>{isMobile ? `상품 문의내역` : `마이페이지`}</MypageTop>
				<Wrapper>
					<Menu {...props} />
					<ContentWrapper>
						<BWrapper>
							<div className="top">
								<h2>상품 문의내역</h2>
							</div>

							<div className="list">
								<table>
									<thead>
										<tr>
											<th>번호</th>
											<th>제목</th>
											<th>상품</th>
											<th>작성자</th>
											<th>등록일</th>
										</tr>
									</thead>
									<tbody>
										{!list ||
											(list.length === 0 && (
												<tr>
													<td colSpan="5" className="text-center">
														상품 문의내역이 없습니다.
													</td>
												</tr>
											))}
										{list?.map((item, i) => (
											<ListItem key={i} {...item} virtualNo={virtualNo--} history={history} />
										))}
									</tbody>
								</table>
								{paging && (
									<div className="paging">
										<Paging paging={paging} pageCallback={movePage} />
									</div>
								)}
							</div>
						</BWrapper>
					</ContentWrapper>
				</Wrapper>
			</Container>
		</Layout>
	);
};

const Wrapper = styled.div`
	display: flex;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-bottom: 40px;
	}
`;

const ContentWrapper = styled.div`
	margin-left: 30px;
	padding-top: 10px;
	flex: 1;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-left: 0;
		padding: 0 21px;
		width: calc(100% - 21px);
	}
`;

const BWrapper = styled.div`
	div.top {
		position: relative;
		display: flex;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-bottom: 1px solid #3d3d3d;
			margin-top: 24px;
			padding-bottom: 16px;
			display: none;
		}
	}

	div.top > h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
	}

	div.list {
		margin-top: 16px;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		}
	}

	div.paging {
		margin-top: 20px;
	}

	table {
		border-top: 1px solid #3d3d3d;
		width: 100%;
		border-spacing: 0 0;
		margin-top: 8px;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		}
		thead {
			background: #f5f5f5;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			tbody > tr.question {
				background-image: url("/icons/ic_acc_down.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_down.png", "/icons/ic_acc_down@2x.png", "/icons/ic_acc_down@3x.png"])});
				background-repeat: no-repeat;
				background-size: 10px 6px;
				background-position: right center;
			}
			tbody > tr.is-more {
				background-image: url("/icons/ic_acc_up.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_up.png", "/icons/ic_acc_up@2x.png", "/icons/ic_acc_up@3x.png"])});
			}
		}

		thead > tr > th {
			text-align: center;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 12px;
			line-height: 17px;
			padding: 9px 0;
			color: #777777;
			border-bottom: 1px solid #dfdfdf;
		}

		tbody > tr > td {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			padding: 14px 0;
			color: #3d3d3d;
			border-bottom: 1px solid #dfdfdf;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				div.date {
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 12px;
					line-height: 12px;
					color: #c1c1c1;
				}
			}

			button {
				border: 1px solid #cfcfcf;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 10px;
				line-height: 15px;
				color: #9a9a9a;
				background: #ffffff;
				padding: 5px 13px;
				margin-right: 8px;
				min-width: 54px;
			}

			button.complete {
				color: #e32222;
				background: #ffffff;
				border: 1px solid #e32222;
			}

			button.seller {
				color: #ffffff;
				background: #e32222;
				border: 1px solid #e32222;
			}

			div.product-info {
				display: none;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: flex;

					img {
						flex-basis: 50px;
						width: 50px;
						height: 50px;
						object-fit: cover;
					}

					p {
						flex: 1;
						padding-left: 8px;
						display: flex;
						align-items: center;
					}
				}
			}
		}

		tbody > tr > td.no,
		tbody > tr > td.date,
		tbody > tr > td.product-info {
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
		}

		tbody > tr > td.author {
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				padding-right: 21px;
			}
		}

		tbody > tr {
			cursor: pointer;
		}

		tbody > tr > td.text-center {
			text-align: center;
		}

		tbody > tr > td.product-info {
			text-align: center;

			img {
				width: 50px;
				height: 50px;
				object-fit: cover;
			}

			p {
				margin: 0;
				padding: 0;
				font-size: 13px;
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #3d3d3d;
				width: 150px;
				white-space: nowrap;
				text-overflow: ellipsis;

				overflow: hidden;
			}
		}

		tbody > tr.answer > td:first-child {
			text-align: right;
			vertical-align: top;
			padding-right: 16px;
		}
		tbody > tr.answer > td {
			background: #f9f9f9;
			position: relative;

			div.content {
				display: flex;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: inline-flex;
					width: calc(100% - 26px);
				}
			}

			div.depth {
				width: 10px;
				height: 10px;
				background-color: #c7c7c7;
				display: inline-block;
				position: relative;

				:before {
					content: "";
					position: absolute;
					width: 9px;
					height: 9px;
					top: -1px;
					right: -1px;
					background: #f9f9f9;
				}

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: inline-block;
					vertical-align: top;
					margin: 0 8px;
				}
			}

			div.content > .button {
				flex-basis: 70px;
			}

			div.content > div.detail {
				flex: 1;
				span {
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 13px;
					line-height: 18px;
					color: #3d3d3d;
				}

				p {
					margin: 2px 0 0 0;
					padding: 0;
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 13px;
					line-height: 18px;
					color: #9a9a9a;
				}
			}
		}
	}
`;

const ListItem = (props) => {
	const { history } = props;
	const [show, setShow] = useState(false);

	return (
		<>
			<tr onClick={() => setShow(!show)} className={cx({ pointer: true, question: true, "is-more": show })}>
				<td className="text-center no">{props.virtualNo}</td>
				<td>
					{props.answer ? <button className="complete">답변완료</button> : <button>미답변</button>}
					{props.title}
				</td>
				<td width={150} className="product-info" onClick={() => history.push(`/product/${props.productCode}`)}>
					<img src={props.productFileSignedUrl} />
					<p>{props.productName}</p>
				</td>
				<td className="text-center author">
					{props.clientNickName}
					{isMobile && <div className="date">{moment(props.regDate).format("YYYY-MM-DD")}</div>}
				</td>
				<td className="text-center date">{moment(props.regDate).format("YYYY-MM-DD")}</td>
			</tr>
			{show && (
				<tr>
					<td className="no"></td>
					<td colSpan={4}>
						<div className="product-info">
							<img src={props.productFileSignedUrl} />
							<p>{props.productName}</p>
						</div>
						<div className="content">
							<div className="detail">
								<p>{props.content}</p>
							</div>
						</div>
					</td>
				</tr>
			)}
			{props.answer && show && (
				<tr className="answer">
					<td className="no">
						<div className="depth"></div>
					</td>
					<td colSpan={4}>
						<div className="content">
							<div className="button">
								<button className="seller">판매자</button>
							</div>

							<div className="detail">
								{/* <span>CHANNEL</span> */}
								<p>{props.answer}</p>
							</div>
						</div>
					</td>
				</tr>
			)}
		</>
	);
};
