/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import Dialog from "../../UI/Dialog";
import { MypageTop } from "../../UI/Header";
import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { UInputForm, UFormWrapper, CheckBox, Textarea, Radio } from "../../UI/Form";

import Menu from "./Service.Menu";

export default (props) => {
    const { location, history, match } = props;

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [topList, setTopList] = useState([]);

    const loadList = (data) => {
        _API.get({ path: `/bbs`, data: { ...data, bbsCode: "NOTICE", isNotice: "N" } }).then((res) => {
            const { list, paging, topNoticeList } = res.data;
            setList(list);
            setPaging(paging);
            setTopList(topNoticeList);
        });
    };

    const movePage = (page) => {
        ToPage(location, history, { keyword, page });
        window.scrollTo(0, 0);
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword, page: 1 });
    };

    const onRefresh = () => {
        loadList({ keyword, page: paging.pageNo });
    };

    useEffect(() => {
        const params = GetParams(location);
        if (params) {
            setKeyword(params.keyword);
            loadList(params);
        } else {
            setKeyword("");
            loadList({ page: 1 });
        }
    }, [location]);

    let virtualNo = paging ? paging.virtualRecordNo : -1;

    return (
        <Layout {...props} hideTop={true}>
            <Container>
                <MypageTop {...props}>{isMobile ? `공지사항` : `서비스센터`}</MypageTop>
                <Wrapper>
                    <Menu {...props} />
                    <ContentWrapper>
                        <BWrapper>
                            <div className="top">
                                <h2>공지사항</h2>
                            </div>

                            <div className="list">
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="5%">번호</th>
                                            <th>제목</th>
                                            <th width="10%">작성자</th>
                                            <th width="10%">등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topList?.map((item, i) => (
                                            <ListItem key={i} {...item} history={history} />
                                        ))}
                                        {!list ||
                                            (list.length === 0 && (
                                                <tr>
                                                    <td colSpan="4" className="text-center">
                                                        공지사항이 없습니다.
                                                    </td>
                                                </tr>
                                            ))}
                                        {list?.map((item, i) => (
                                            <ListItem key={i} {...item} virtualNo={virtualNo--} history={history} />
                                        ))}
                                    </tbody>
                                </table>
                                {paging && (
                                    <div className="paging">
                                        <Paging paging={paging} pageCallback={movePage} />
                                    </div>
                                )}
                            </div>
                        </BWrapper>
                    </ContentWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding-bottom: 40px;
    }
`;

const ContentWrapper = styled.div`
    margin-left: 30px;
    padding-top: 10px;
    flex: 1;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-left: 0;
        padding: 0 21px;
        width: calc(100% - 21px);
    }
`;

const BWrapper = styled.div`
    div.top {
        position: relative;
        display: flex;

        /* @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-bottom: 1px solid #3d3d3d;
			margin-top: 24px;
			padding-bottom: 16px;
			display: none;
		} */
    }

    div.top > h2 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }

    > div.top > button {
        position: absolute;
        right: 0;
        top: 0;
        background: #e32222;
        border: none;
        outline: none;
        color: #ffffff;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        padding: 4px 18px;
        cursor: pointer;
    }

    div.list {
        margin-top: 16px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-top: 30px;
        }
    }

    div.paging {
        margin-top: 20px;
    }

    table {
        border-top: 1px solid #3d3d3d;
        width: 100%;
        border-spacing: 0 0;
        margin-top: 8px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        }
        thead {
            background: #f5f5f5;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                display: none;
            }
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            tbody > tr.question {
                background-image: url("/icons/ic_acc_down.png");
                background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_down.png", "/icons/ic_acc_down@2x.png", "/icons/ic_acc_down@3x.png"])});
                background-repeat: no-repeat;
                background-size: 10px 6px;
                background-position: right center;
            }
            tbody > tr.is-more {
                background-image: url("/icons/ic_acc_up.png");
                background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_up.png", "/icons/ic_acc_up@2x.png", "/icons/ic_acc_up@3x.png"])});
            }
        }

        thead > tr > th {
            text-align: center;
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 12px;
            line-height: 17px;
            padding: 9px 0;
            color: #777777;
            border-bottom: 1px solid #dfdfdf;
        }

        tbody > tr > td {
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 13px;
            line-height: 18px;
            padding: 14px 0;
            color: #3d3d3d;
            border-bottom: 1px solid #dfdfdf;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                div.date {
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    font-size: 12px;
                    line-height: 12px;
                    color: #c1c1c1;
                }
            }

            button {
                border: 1px solid #cfcfcf;
                font-family: ${(props) => props.theme.fontFamily.regular};
                font-size: 10px;
                line-height: 15px;
                color: #9a9a9a;
                background: #ffffff;
                padding: 5px 13px;
                min-width: 54px;
            }

            button.complete {
                color: #e32222;
                background: #ffffff;
                border: 1px solid #e32222;
            }
            button.category {
                color: #3d3d3d;
                background: #ffffff;
                border: 1px solid #3d3d3d;
            }
            button.seller {
                color: #ffffff;
                background: #e32222;
                border: 1px solid #e32222;
            }

            div.product-info {
                display: none;

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    display: flex;

                    img {
                        flex-basis: 50px;
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                    }

                    p {
                        flex: 1;
                        padding-left: 8px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        tbody > tr > td.content {
            padding: 0 20px;
            background: #f9f9f9;

            img {
                max-width: 100%;
            }
        }

        tbody > tr > td.no,
        tbody > tr > td.date,
        tbody > tr > td.product-info {
            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                display: none;
            }
        }

        tbody > tr > td.author {
            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                padding-right: 21px;
            }
        }

        tbody > tr {
            cursor: pointer;
        }

        tbody > tr > td.text-center {
            text-align: center;
        }

        tbody > tr > td.title {
            padding-left: 20px;
        }

        tbody > tr > td.title > span {
            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                display: block;
                margin-top: 5px;
            }
        }
    }
`;

const ListItem = (props) => {
    const { history } = props;
    const [show, setShow] = useState(false);

    return (
        <>
            <tr onClick={() => setShow(!show)} className={cx({ pointer: true, question: true, "is-more": show })}>
                <td className="text-center no">{props.isNotice === "Y" ? <button className="complete">공지</button> : props.virtualNo}</td>
                <td className="title">
                    <span>{props.title}</span>
                </td>
                <td className="text-center author">
                    모아
                    {isMobile && <div className="date">{moment(props.regDate).format("YYYY-MM-DD")}</div>}
                </td>
                <td className="text-center date">{moment(props.regDate).format("YYYY-MM-DD")}</td>
            </tr>
            {show && (
                <tr>
                    <td colSpan={4} className="content">
                        <div>
                            <div className="detail">
                                <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                                {props.fileList?.map((file, fIndex) => (
                                    <div key={fIndex}>
                                        <img src={file.signedUrl} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};
