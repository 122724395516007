/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";
import moment from "moment";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { AppContext } from "../../Components/AppProvider";
import { _API, _U } from "../../Modules/Utils";
import { _DEF } from "../../Modules/Config";

import { getSrcSet } from "../../UI/Assets";
import { CheckBox } from "../../UI/Product";
import { SelectBox } from "../../UI/Form";
import swal from "sweetalert";

export default (props) => {
	console.log(props);
	const { resolve, reject } = props;
	const [data, setData] = useState(null);

	const loadData = (data) => {
		_API
			.get({
				path: "/user/mypage/shipping",
				data: {
					invoiceNumber: props?.lastOrderShipping?.invoiceNumber,
					orderShippingCompany: props?.lastOrderShipping?.shippingCompany,
					orderShippingIdx: props?.lastOrderShipping?.idx,
					orderIdx: props.orderIdx,
				},
			})
			.then((res) => {
				setData(res.data);
			})
			.catch((e) => {
				reject();
			});
	};

	useEffect(() => {
		loadData();
	}, [props?.lastOrderShipping]);

	return (
		<Wrapper>
			<dl>
				<dt>운송장번호</dt>
				<dd>{data?.invoiceNo}</dd>
			</dl>
			<dl>
				<dt>택배사</dt>
				<dd>{_DEF.getDefinedLabel(_DEF.SHIPPING_COMPANY, props.lastOrderShipping?.shippingCompany)}</dd>
			</dl>
			<table>
				<thead>
					<tr>
						<th>시간</th>
						<th>현재 위치</th>
						<th>배송 상태</th>
					</tr>
				</thead>
				<tbody>
					{data?.trackingDetails?.map((item, index) => (
						<tr key={index} className={cx({ active: index === data?.trackingDetails.length - 1 })}>
							<td>{moment(item.time).format("YYYY-MM-DD HH:mm:ss")}</td>
							<td>{item.where}</td>
							<td>{item.kind}</td>
						</tr>
					))}
				</tbody>
			</table>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	dl {
		width: 100%;
		padding: 0;
		margin: 0 0 24px 0;
		display: flex;
	}

	dl > dt {
		flex-basis: 100px;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 14px;
		line-height: 20px;
		color: #b8b8b8;
	}

	dl > dd {
		flex: 1;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 14px;
		line-height: 20px;
		color: #3d3d3d;
	}

	table {
		width: 100%;
		border-spacing: 0;
		margin-top: 36px;
	}

	table > thead > tr > th {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 13px;
		line-height: 17px;
		color: #777777;
		text-align: left;
		font-weight: normal;
		border-bottom: 1px solid #b8b8b8;
		padding: 8px 0;
		width: 33.3%;
	}

	table > tbody > tr > td {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 19px;
		color: #9c9c9c;
		text-align: left;
		font-weight: normal;
		border-bottom: 1px solid #efefef;
		padding: 16px 0;
	}

	table > tbody > tr.active > td {
		color: #3d3d3d !important;
	}
`;
