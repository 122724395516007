/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";

import Layout from "./Layout";
import { AppContext } from "../Components/AppProvider";
import { _U, _API, _CK } from "../Modules/Utils";
import { getSrcSet } from "../UI/Assets";

export default (props) => {
    const { history } = props;
    const { iam, auth } = useContext(AppContext);

    const [keyword, setKeyword] = useState("");
    const [keywordList, setKeywordList] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_CK.get("searchKeyword") && _CK.get("searchKeyword").length > 0) {
            setKeywordList(_CK.get("searchKeyword"));
        }
    }, []);

    const onSearch = () => {
        if (!keyword) {
            swal({ title: "알림", text: "검색어를 입력하세요." });
            return;
        }

        onSetCookie(keyword);

        history.push(`/search/${encodeURIComponent(keyword)}`);
    };

    const onSetCookie = (keyword) => {
        const searchKeyword = _CK.get("searchKeyword") || [];
        searchKeyword.unshift(keyword);

        const tmp = searchKeyword.filter((element, index) => {
            return searchKeyword.indexOf(element) === index;
        });

        _CK.set("searchKeyword", tmp, 1);
    };

    const onSearchItem = (keyword) => {
        onSetCookie(keyword);

        history.push(`/search/${encodeURIComponent(keyword)}`);
    };

    return (
        <Layout {...props} showMenu={true}>
            <Wrapper>
                <div className="top">
                    <div className="search-keyword">
                        <input
                            type="text"
                            name="keyword"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    onSearch();
                                }
                            }}
                            placeholder="모아의 상품을 검색해보세요."
                        />
                        <button onClick={onSearch}>
                            <img
                                src="/icons/ic_bottom_search_off.png"
                                srcSet={getSrcSet(["/icons/ic_bottom_search_off.png", "/icons/ic_bottom_search_off@2x.png", "/icons/ic_bottom_search_off@3x.png"])}
                            />
                        </button>
                    </div>
                </div>
                <div className="top">
                    <h3>최근 검색어</h3>
                </div>
                <div className="list-box">
                    {keywordList?.map((item, index) => (
                        <div className="item" key={index} onClick={() => onSearchItem(item)}>
                            {item}
                        </div>
                    ))}
                </div>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
    width: ${(props) => props.theme.layout.productWidth}px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 80px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }

    div.top {
        position: relative;
        display: flex;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex-direction: column;
            padding: 0 21px;
        }
    }

    div.top > h3 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        margin-top: 32px;
    }

    div.list-box {
        margin-top: 12px;
        display: block;
        padding: 0 21px;
    }

    div.list-box > .item {
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #777777;
        font-size: 13px;
        line-height: 18px;
        cursor: pointer;
        border: 1px solid #dedede;
        padding: 9px 16px;
        text-align: center;
        display: inline-block;
        border-radius: 24px;
        margin: 0 8px 8px 0;
    }

    div.search-keyword {
        flex: 1;
        flex-grow: 1;
        text-align: right;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 100%;
            display: flex;
        }

        input {
            background: #ebebeb;
            border: 1px solid #ebebeb;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            background: #ffffff;
            border-right: 0;
            height: 46px;
            vertical-align: middle;
            padding: 0 5px 0 15px;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                flex: 1;
            }
        }

        button {
            width: 48px;
            height: 48px;
            border: solid 1px #ebebeb;
            background-color: #ffffff;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            border-left: 0;
            cursor: pointer;
            vertical-align: middle;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                flex-basis: 40px;
            }

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
`;
