/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import Layout from "./Layout";
import { AppContext } from "../Components/AppProvider";
import { _U, _API } from "../Modules/Utils";
import { getSrcSet } from "../UI/Assets";

export default (props) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout {...props} showMenu={true}>
			{props.isHoverMenu ? <BrandView {...props} /> : <BrandPageView {...props} />}
		</Layout>
	);
};

export const BrandPageView = (props) => {
	const { history, onClickClose } = props;
	const { iam, auth } = useContext(AppContext);

	const [list, setList] = useState([]);
	const [best, setBest] = useState([]);
	const [alpha, setAlpha] = useState("");
	const [keyword, setKeyword] = useState("");

	const loadData = () => {
		_API
			.get({ path: "/product/brand", data: { alpha, keyword } })
			.then((res) => res.data)
			.then((data) => {
				const { list, best } = data;
				setList(list);
				setBest(best);
			});
	};

	useEffect(() => {
		loadData();
	}, [alpha]);

	const onProductSearch = (brand) => {
		const url = `/search?q=${_U.encode({ sort: "", brandList: [brand], costMin: "", costMax: "", discount: "", shipping: "", page: 1 })}`;
		history.push(url);
		onClickClose();
	};

	const getAlphaList = () => {
		const result = [];

		for (let i = 0; i < 26; i++) {
			const value = (i + 10).toString(36);

			result.push(
				<div onClick={() => setAlpha(value)} className={cx({ item: true, active: value === alpha })}>
					{value.toUpperCase()}
				</div>
			);
		}

		result.push(
			<div onClick={() => setAlpha("ETC")} className={cx({ item: true, etc: true, active: alpha === "ETC" })}>
				ETC
			</div>
		);

		return result;
	};

	const getList = () => {
		const result = [];
		for (let i = 0; i < 26; i++) {
			const alp = (i + 10).toString(36);

			list
				.filter((f) => f.nameEng.startsWith(alp.toUpperCase()))
				.map((item, j) => {
					if (j === 0) {
						result.push(<h3 key={i}>{alp.toUpperCase()}</h3>);
					}
					result.push(
						<div className="item" key={`${i}-${j}`} onClick={() => onProductSearch(item.name)}>
							{item.nameEng}
							<p>{item.name}</p>
						</div>
					);
				});
		}

		list
			.filter((f) => f.nameEng.match(/^[0-9]+/))
			.map((item, j) => {
				if (j === 0) {
					result.push(<h3>ETC</h3>);
				}
				result.push(
					<div className="item" key={`ETC-${j}`} onClick={() => onProductSearch(item.name)}>
						{item.nameEng}
						<p>{item.name}</p>
					</div>
				);
			});

		return result;
	};

	return (
		<PWrapper>
			<h2>브랜드</h2>

			{best?.length > 0 && (
				<>
					<h3>Best</h3>
					<div className="best-box">
						{best?.map((item, i) => (
							<div className="best-item" key={i} onClick={() => onProductSearch(item.name)}>
								<img src={item.logoSignedUrl} />
							</div>
						))}
					</div>
				</>
			)}

			<div className="top">
				<div className="search-keyword">
					<input
						type="text"
						name="keyword"
						value={keyword}
						onChange={(e) => setKeyword(e.target.value)}
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								loadData();
							}
						}}
						placeholder="브랜드명 입력"
					/>
					<button onClick={loadData}>
						<img
							src="/icons/ic_bottom_search_gray.png"
							srcSet={getSrcSet(["/icons/ic_bottom_search_gray.png", "/icons/ic_bottom_search_gray@2x.png", "/icons/ic_bottom_search_gray@3x.png"])}
						/>
					</button>
				</div>
			</div>
			<div className="search-alpha">
				{!isMobile && (
					<div className="alpha-all">
						<div onClick={() => setAlpha("")} className={cx({ item: true, active: !alpha, all: true })}>
							ALL
						</div>
					</div>
				)}
				<div className="alpha-items">
					{isMobile && (
						<div onClick={() => setAlpha("")} className={cx({ item: true, active: !alpha, all: true })}>
							ALL
						</div>
					)}
					{getAlphaList()}
				</div>
			</div>

			<div className="list-box">{getList()}</div>
		</PWrapper>
	);
};

const PWrapper = styled.div`
	width: ${(props) => props.theme.layout.productWidth}px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 80px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: calc(100% - 42px);
		padding: 0 21px;
	}

	h2 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 24px;
		line-height: 35px;
		border-bottom: 2px solid #454545;
		margin: 0;
		padding: 0 0 30px 0;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			font-size: 22px;
			line-height: 32px;
			border-bottom: 0;
		}
	}

	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 20px;
		line-height: 29px;
		margin: 44px 0 24px;
		padding: 0;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			font-size: 18px;
			line-height: 26px;
			margin: 0px 0 24px;
		}
	}

	div.best-box {
		display: flex;
	}

	div.best-box > div.best-item {
		flex: 1;
		height: 229px;
		cursor: pointer;
		border: 1px solid #b8b8b8;
		border-left: 0;
		text-align: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			height: 97px;
		}

		:first-child {
			border-left: 1px solid #b8b8b8;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				width: calc(100% - 10px);
				height: calc(100% - 10px);
			}
		}
	}

	div.top {
		margin-top: 44px;
		position: relative;
		display: flex;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-direction: column;
		}
	}

	div.top > h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 24px;
		line-height: 35px;
		font-weight: normal;
	}
	div.top > h3 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 16px;
		line-height: 24px;
		font-weight: normal;
	}
	div.top > h4 {
		flex: 1;
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 13px;
		line-height: 17px;
		font-weight: normal;
		display: flex;
		align-items: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 24px;
		}
	}

	div.list-box {
		margin-top: 60px;
		/* display: grid;
		grid-template-columns: 33.3% 33.3% 33.4%;
		row-gap: 14px; */
		display: flex;
		flex-wrap: wrap;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			justify-content: space-between;
		}
	}
	div.list-box > h3 {
		flex-basis: 100%;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 40px;
		line-height: 58px;
		margin: 0 0 44px 0;
		padding: 0;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			margin: 0 0 30px 0;
		}
	}

	div.list-box > .item {
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		cursor: pointer;
		flex-basis: 25%;
		font-family: ${(props) => props.theme.fontFamily.medium};
		margin-bottom: 40px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-basis: 45%;
			margin-bottom: 24px;
			word-break: break-all;
		}

		p {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #777777;
			font-size: 14px;
			line-height: 20px;
			margin: 4px 0 0;
			padding: 0;
		}
	}

	div.search-alpha {
		display: flex;
		margin: 18px 0 24px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			width: 100%;
		}
	}

	div.search-alpha > .alpha-all {
		flex-basis: 75px;

		> .item {
			height: 33px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;

			border: 1px solid #dedede;
		}

		> .item.active {
			background: #00788d;
			color: #ffffff;
			border: 1px solid #00788d;
		}
	}

	div.search-alpha > .alpha-items {
		margin-left: 17px;
		flex: 1;
		display: grid;
		gap: 16px 16px;
		grid-template-columns: repeat(auto-fit, 31px);
		justify-content: space-between;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			margin-left: 0;
			gap: 11px 11px;
		}
	}

	div.search-alpha > .alpha-items > .item {
		/* flex-basis: 31px; */
		width: 31px;
		height: 31px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		/* margin: 0 0 16px 16px; */

		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 14px;
		line-height: 20px;

		border: 1px solid #dedede;

		button {
			width: 40px;
			height: 40px;
			border: solid 1px #ebebeb;
			background-color: #ffffff;
			border-radius: 50%;

			cursor: pointer;
			vertical-align: middle;
		}
	}

	div.search-alpha > .alpha-items > .item.all {
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			grid-column-start: 1;
			grid-column-end: 3;
			width: 100%;
		}
	}

	div.search-alpha > .alpha-items > .item.etc {
		padding: 0 5px;
	}
	div.search-alpha > .alpha-items > .item.empty {
		border: 0;
		flex-basis: 33px;
		height: 33px;
	}

	div.search-alpha > .alpha-items > .item.active {
		background: #00788d;
		color: #ffffff;
		border: 1px solid #00788d;
	}

	div.search-keyword {
		flex: 1;
		flex-grow: 1;
		text-align: right;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			width: 100%;
			display: flex;
		}

		input {
			background: #ebebeb;
			border: 1px solid #ebebeb;
			background: #ffffff;
			border-right: 0;
			height: 32px;
			vertical-align: middle;
			padding: 0 5px 0 15px;

			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 14px;
			line-height: 20px;
			color: #3d3d3d;

			::placeholder {
				color: #b8b8b8;
			}

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				height: 36px;
			}
		}

		button {
			width: 34px;
			height: 34px;
			border: solid 1px #ebebeb;
			background-color: #ffffff;
			border-left: 0;
			cursor: pointer;
			vertical-align: middle;
			img {
				width: 20px;
				height: 20px;
			}
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex-basis: 40px;
				width: 38px;
				height: 38px;
			}
		}
	}
`;

export const BrandView = (props) => {
	const { history, onClickClose } = props;
	const { iam, auth } = useContext(AppContext);

	const [list, setList] = useState([]);
	const [alpha, setAlpha] = useState("");
	const [keyword, setKeyword] = useState("");

	const loadData = () => {
		_API
			.get({ path: "/product/brand", data: { alpha, keyword } })
			.then((res) => res.data)
			.then((data) => {
				const { list } = data;
				setList(list);
			});
	};

	useEffect(() => {
		loadData();
	}, [alpha]);

	const onProductSearch = (brand) => {
		const url = `/search?q=${_U.encode({ sort: "", brandList: [brand], costMin: "", costMax: "", discount: "", shipping: "", page: 1 })}`;
		history.push(url);
		onClickClose();
	};

	const getAlphaList = () => {
		const result = [];

		result.push(
			<div onClick={() => setAlpha("")} className={cx({ item: true, active: !alpha, all: true })}>
				ALL
			</div>
		);

		for (let i = 0; i < 26; i++) {
			const value = (i + 10).toString(36);

			result.push(
				<div onClick={() => setAlpha(value)} className={cx({ item: true, active: value === alpha })}>
					{value.toUpperCase()}
				</div>
			);
		}

		result.push(
			<div onClick={() => setAlpha("ETC")} className={cx({ item: true, active: alpha === "ETC" })}>
				ETC
			</div>
		);

		return result;
	};

	return (
		<Wrapper>
			<div className="top">
				<h4>검색</h4>
				<div className="search-keyword">
					<input
						type="text"
						name="keyword"
						value={keyword}
						onChange={(e) => setKeyword(e.target.value)}
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								loadData();
							}
						}}
					/>
					<button onClick={loadData}>
						<img
							src="/icons/ic_bottom_search_off.png"
							srcSet={getSrcSet(["/icons/ic_bottom_search_off.png", "/icons/ic_bottom_search_off@2x.png", "/icons/ic_bottom_search_off@3x.png"])}
						/>
					</button>
				</div>
			</div>
			<div className="search-alpha">{getAlphaList()}</div>
			<div className="top">
				<h3>브랜드</h3>
			</div>
			<div className="list-box">
				{list?.map((item, index) => (
					<div className="item" key={index} onClick={() => onProductSearch(item.name)}>
						{item.nameEng} {item.name}
					</div>
				))}
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: ${(props) => props.theme.layout.productWidth}px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 80px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
	}

	div.top {
		position: relative;
		display: flex;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-direction: column;
			padding: 0 21px;
		}
	}

	div.top > h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 24px;
		line-height: 35px;
		font-weight: normal;
	}
	div.top > h3 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 16px;
		line-height: 24px;
		font-weight: normal;
	}
	div.top > h4 {
		flex: 1;
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 13px;
		line-height: 17px;
		font-weight: normal;
		display: flex;
		align-items: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 24px;
		}
	}

	div.list-box {
		margin-top: 12px;
		display: grid;
		grid-template-columns: 33.3% 33.3% 33.4%;
		row-gap: 14px;
		max-height: 300px;
		overflow-y: auto;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 0 21px;
			grid-template-columns: 100%;
			max-height: unset;
			overflow-y: unset;
		}
	}

	div.list-box > .item {
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #777777;
		font-size: 13px;
		line-height: 18px;
		cursor: pointer;
	}

	div.search-alpha {
		display: flex;
		margin: 18px 0 24px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			width: calc(100% - 21px);
			overflow-x: auto;
			flex-wrap: nowrap;
			padding: 0 0 0 21px;
			-ms-overflow-style: none;
			scrollbar-width: none;
			::-webkit-scrollbar {
				display: none;
			}
		}
	}

	div.search-alpha > .item {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;

		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 13px;
		line-height: 17px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border: 1px solid #dedede;
			padding: 9px 16px;
			border-radius: 18px;
			margin-right: 8px;
		}

		button {
			width: 40px;
			height: 40px;
			border: solid 1px #ebebeb;
			background-color: #ffffff;
			/* border-top-right-radius: 16px;
            border-bottom-right-radius: 16px; */
			border-radius: 50%;

			cursor: pointer;
			vertical-align: middle;
		}
	}

	div.search-alpha > .item.active {
		color: #00788d;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border: 1px solid #00788d;
		}
	}

	div.search-keyword {
		flex: 1;
		flex-grow: 1;
		text-align: right;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			width: 100%;
			display: flex;
		}

		input {
			background: #ebebeb;
			border: 1px solid #ebebeb;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;
			background: #ffffff;
			border-right: 0;
			height: 38px;
			vertical-align: middle;
			padding: 0 5px 0 15px;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				height: 46px;
			}
		}

		button {
			width: 40px;
			height: 40px;
			border: solid 1px #ebebeb;
			background-color: #ffffff;
			border-top-right-radius: 16px;
			border-bottom-right-radius: 16px;
			border-left: 0;
			cursor: pointer;
			vertical-align: middle;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex-basis: 40px;
				width: 48px;
				height: 48px;

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
`;
