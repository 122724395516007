/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import swal from "sweetalert";

import { AppContext } from "../../Components/AppProvider";
import { _DEF } from "../../Modules/Config";
import { _API, _U, _CK } from "../../Modules/Utils";
import { getSrcSet } from "../../UI/Assets";

import Layout, { Container } from "../Layout";

import { Wrapper, Title, LoginForm } from "./UI";
import { UInputForm } from "../../UI/Form";

export default (props) => {
    const { location, history, match } = props;
    if (_CK.isLogin()) {
        history.goBack();
        return null;
    }

    const { saveAuth, saveIam } = useContext(AppContext);

    const [email, setEmail] = useState("");
    const [passwd, setPasswd] = useState("");

    const emailRef = useRef(null);
    const passwdRef = useRef(null);

    const handleLoginClick = () => {
        try {
            if (!email) {
                throw { message: "이메일을 입력해 주세요.", ref: emailRef };
            }
            if (!_U.isEmail(email)) {
                throw { message: "올바른 양식의 이메일을 입력해 주세요.", ref: emailRef };
            }
            if (!passwd) {
                throw { message: "비밀번호를 입력해 주세요.", ref: passwdRef };
            }
            const params = { id: email, passwd };
            _API.post({ path: "/user/login", data: params })
                .then((res) => res.data)
                .then((data) => {
                    const { accessKey, data: iam } = data;
                    _CK.setAuth(accessKey);
                    saveAuth(accessKey);
                    saveIam(iam);
                    history.push("/");
                });
        } catch (e) {
            swal({ title: "알림", text: e.message, button: "확인" }).then(() => {
                if (e.ref) {
                    e.ref.current.focus();
                }
            });
        }
    };

    return (
        <Layout {...props}>
            <Container>
                <Wrapper>
                    <Title>모아 회원 로그인</Title>
                    <LoginForm>
                        <div className="sns form">
                            <h3 className="title">SNS 계정으로 로그인</h3>
                            <button type="button">
                                <img
                                    src="/icons/ic_sns_naver.png"
                                    srcSet={getSrcSet(["/icons/ic_sns_naver.png", "/icons/ic_sns_naver@2x.png", "/icons/ic_sns_naver@3x.png"])}
                                    width="28"
                                    height="28"
                                    alt="네이버"
                                />
                                네이버 계정으로 로그인
                            </button>
                            <button type="button">
                                <img
                                    src="/icons/ic_sns_kakao.png"
                                    srcSet={getSrcSet(["/icons/ic_sns_kakao.png", "/icons/ic_sns_kakao@2x.png", "/icons/ic_sns_kakao@3x.png"])}
                                    width="28"
                                    height="28"
                                    alt="네이버"
                                />
                                카카오 계정으로 로그인
                            </button>
                            <button type="button">
                                <img
                                    src="/icons/ic_sns_facebook.png"
                                    srcSet={getSrcSet(["/icons/ic_sns_facebook.png", "/icons/ic_sns_facebook@2x.png", "/icons/ic_sns_facebook@3x.png"])}
                                    width="28"
                                    height="28"
                                    alt="네이버"
                                />
                                페이스북 계정으로 로그인
                            </button>
                            <button type="button">
                                <img
                                    src="/icons/ic_sns_google.png"
                                    srcSet={getSrcSet(["/icons/ic_sns_google.png", "/icons/ic_sns_google@2x.png", "/icons/ic_sns_google@3x.png"])}
                                    width="28"
                                    height="28"
                                    alt="네이버"
                                />
                                구글 계정으로 로그인
                            </button>
                            <button type="button">
                                <img
                                    src="/icons/ic_sns_apple.png"
                                    srcSet={getSrcSet(["/icons/ic_sns_apple.png", "/icons/ic_sns_apple@2x.png", "/icons/ic_sns_apple@3x.png"])}
                                    width="28"
                                    height="28"
                                    alt="네이버"
                                />
                                애플 계정으로 로그인
                            </button>
                        </div>
                        <div className="split" />
                        <div className="flexme form">
                            <h3 className="title">모아 계정으로 로그인</h3>
                            <UInputForm ref={emailRef} title="이메일" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="이메일을 입력해 주세요." />
                            <UInputForm
                                ref={passwdRef}
                                title="비밀번호"
                                name="passwd"
                                type="password"
                                value={passwd}
                                onChange={(e) => setPasswd(e.target.value)}
                                placeholder="비밀번호를 입력해 주세요."
                            />
                            <button type="button" onClick={handleLoginClick}>
                                로그인
                            </button>
                            <div className="links">
                                <Link to="/user/signup">회원가입</Link> | <Link to="/user/findIdPw">아이디/비밀번호 찾기</Link>
                            </div>
                        </div>
                    </LoginForm>
                </Wrapper>
            </Container>
        </Layout>
    );
};
