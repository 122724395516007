/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";
import { v4 as UUIDV4 } from "uuid";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import { getSrcSet } from "../../UI/Assets";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";

const STREAM_ID = UUIDV4();

export default (props) => {
    const { canvas, audio } = props;
    const { iam } = useContext(AppContext);

    const [isLive, setLive] = useState(false);
    const [awsKey, setAwsKey] = useState(null);
    const [adapter, setAdapter] = useState(null);

    const loadStream = () => {
        getStream(canvas, audio)
            .then((s) => startStreaming(s))
            .catch((e) => swal({ title: "오류", text: e.message }));
    };

    const startStreaming = (stream) => {
        startWebRTCStreaming(stream, STREAM_ID).then((adapter) => {
            setAdapter(adapter);
        });
    };

    const stopStreaming = () => {
        if (adapter) {
            stopWebRTCStreaming(adapter, STREAM_ID);
        }
    };

    const uploadPrepare = () => {
        _API.loadUploadKeys()
            .then((res) => res.data)
            .then((key) => setAwsKey(key));
    };

    const capture = () => {
        if (awsKey) {
            const cvs = document.getElementById(canvas);
            const img = cvs.toDataURL("image/png");
            _API.post({
                path: "/cast/alive",
                data: {
                    streamId: STREAM_ID,
                    image: img,
                },
            });
        }
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "STREAMING-STARTED":
                    setLive(true);

                    break;
                case "STREAMING-ENDED":
                    setLive(false);
                    break;
                case "STREAMING-ERROR":
                    setLive(false);
                    if (e.data) {
                        const err = JSON.parse(e.data);
                        switch (err.data) {
                            case "streamIdInUse":
                                swal({ title: "오류", text: "이미 다른 기기에서 송출 중입니다." });
                                break;
                            default:
                                swal({ title: "오류", text: e.message || e.name || e.data });
                                break;
                        }
                    } else {
                        swal({ title: "오류", text: e.message || e.name || e.data });
                    }
                    break;
            }
        });
    };

    useEffect(() => {
        uploadPrepare();
    }, []);

    useEffect(() => {
        if (audio) {
            loadStream();
        }
        window.addEventListener("message", onReceivedMessage);
        return () => {
            stopStreaming();
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [audio]);

    useEffect(() => {
        if (isLive) {
            capture();
        }
        const timer = setInterval(() => {
            if (!isLive && audio) {
                loadStream();
            }
            if (isLive) {
                capture();
            }
        }, [10000]);
        return () => {
            clearInterval(timer);
        };
    }, [isLive]);

    return (
        <Container>
            <Wrapper isLive={isLive} />
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    background: #ffffff;
    border-radius: 13px;
    z-index: 10;
`;
const Wrapper = styled.div`
    margin: 3px;
    background-color: ${(props) => (props.isLive ? "#005a60" : "#e32222")};
    border: black solid 2px;
    width: 16px;
    height: 16px;
    border-radius: 9px;
`;

/**
 * Functions
 */
const getStream = (canvas, audio) => {
    return new Promise(async (resolve, reject) => {
        if (!canvas) {
            return reject({ message: "Canvas ID is undefined" });
        }
        try {
            const cvs = document.getElementById(canvas);
            const cvsStream = cvs.captureStream(30);

            const audioStream = await getAudioStream(audio);
            const audioTrack = audioStream.getAudioTracks();

            cvsStream.addTrack(audioTrack[0]);
            resolve(cvsStream);
        } catch (e) {
            reject(e);
        }
    });
};

const getAudioStream = (audioId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const constraints = {
                audio: {
                    deviceId: audioId,
                },
                video: false,
            };
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => resolve(stream))
                .catch((e) => reject(e));
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (stream, streamId, token) => {
    return new Promise((resolve, reject) => {
        try {
            const adapter = new WebRTCAdaptor({
                websocket_url: _CFG.getStreamUrl(),
                mediaConstraints: { video: true, audio: false },
                peerconnection_config: null,
                sdp_constraints: {
                    OfferToReceiveAudio: false,
                    OfferToReceiveVideo: false,
                },
                localVideoId: "SENDER",
                localStream: stream,
                debug: false,
                callback: (info, obj) => {
                    const checkAndRepublishIfRequired = () => {
                        const iceState = adapter.iceConnectionState(streamId);
                        if (iceState === null || iceState === "failed" || iceState === "disconnected") {
                            adapter.stop(streamId);
                            adapter.closePeerConnection(streamId);
                            adapter.closeWebSocket();
                        }
                    };

                    switch (info) {
                        case "initialized":
                            adapter.publish(streamId, token);
                            break;
                        case "publish_started":
                            _U.postMessage("STREAMING-STARTED");
                            break;
                        case "publish_finished":
                            _U.postMessage("STREAMING-ENDED");
                            break;
                        case "browser_screen_share_supported":
                            break;
                        case "screen_share_stopped":
                            break;
                        case "closed":
                            if (typeof obj != "undefined") {
                                console.log("Connecton closed: " + JSON.stringify(obj));
                            }
                            break;
                        case "pong":
                            break;
                        case "refreshConnection":
                            checkAndRepublishIfRequired();
                            break;
                        case "ice_connection_state_changed":
                            break;
                        case "updated_stats":
                            console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec" + " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec");
                            break;
                    }
                },
                callbackError: (err) => {
                    _U.postMessage("STREAMING-ERROR", err);
                },
            });
            resolve(adapter);
        } catch (e) {
            reject(e);
        }
    });
};

const stopWebRTCStreaming = (adapter, streamId) => {
    adapter.stop(streamId);
};
