/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Loading from "../../UI/Loading";
import Permission from "./Permission";

import Controller from "./Controller";
import Video from "./Video";
import Cameras from "./Cameras";
import Product from "./Product";
import Chatting from "./Chatting";
import Player from "./Player";
import Socket from "../../Components/Socket";

import Mobile from "./Mobile";

const _RESOLUTIONS = isMobile
	? [
			{ label: "480x640 (640p)", value: "640x480" },
			{ label: "1080x1920 (1080p - FHD)", value: "1920x1080" },
	  ]
	: [
			{ label: "640x480 (640p)", value: "640x480" },
			{ label: "1920x1080 (1080p - FHD)", value: "1920x1080" },
	  ];

export default (props) => {
	const { history } = props;
	const { auth, iam } = useContext(AppContext);
	const { device } = useContext(AppContext);

	if (!auth || iam?.isStreamer !== "Y") {
		history.goBack();
		return null;
	}

	const [permission, setPermission] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const [audio, setAudio] = useState([]);
	const [video, setVideo] = useState([]);

	const [audioId, setAudioId] = useState("");
	const [videoId, setVideoId] = useState("");
	const [resolutionId, setResolutionId] = useState("");

	const [viewer, setViewer] = useState(null);

	const [isStart, setIsStart] = useState(false);
	const [socketConnect, setSocketConnect] = useState(false);
	const [castData, setCastData] = useState({ title: `${iam?.name}님의 방송` });

	const loadDevices = () => {
		getDevices()
			.then((devices) => {
				if (!devices.hasAudio) {
					throw { message: "등록된 오디오 장비가 없습니다." };
				}
				if (!devices.hasVideo) {
					throw { message: "등록된 비디오 장비가 없습니다." };
				}
				const { audio, video } = devices;
				setAudio(audio);
				setVideo(video);
				setIsLoaded(true);

				setAudioId(audio[0].deviceId);
				setVideoId(video[0].deviceId);
				setResolutionId(_RESOLUTIONS[0].value);
			})
			.catch((e) => {
				swal({ title: "오류", text: e.message || e.name || e.data });
			});
	};

	const handleDeviceChange = (type, e) => {
		switch (type) {
			case "VIDEO":
				setVideoId(e.target.value);
				break;
			case "AUDIO":
				setAudioId(e.target.value);
				break;
			case "RESOLUTION":
				setResolutionId(e.target.value);
				break;
		}
	};

	const handleLiveView = (streamId) => {
		setViewer(streamId);
	};

	const loadCast = () => {
		_API
			.get({
				path: "/cast/info",
			})
			.then((res) => {
				const { data } = res;
				if (data) {
					setCastData(data);

					if (data?.status === "Y") {
						setIsStart(true);
					}
				} else {
					setIsStart(false);
				}
			});
	};

	useEffect(() => {
		loadCast();
		const timer = setInterval(() => {
			loadCast();
		}, 10000);

		Permission()
			.then((is) => {
				if (!is) {
					swal({ title: "요청", text: "카메라와 마이크의 권한을 허용해 주세요." });
				} else {
					setPermission(true);
					loadDevices();
				}
			})
			.catch((e) => {
				swal({ title: "오류", text: e.message || e.name || e.data }).then(() => {
					history.goBack();
				});
			});

		return () => {
			clearInterval(timer);
		};
	}, []);

	const onStop = () => {
		setIsStart(false);
	};

	if (!permission || !isLoaded) {
		return (
			<Container>
				<Loading color="#00788d" background="#000000" />
			</Container>
		);
	}

	if (isMobile) {
		const p = {
			audio,
			video,
			resolution: _RESOLUTIONS,
			videoId,
			audioId,
			resolutionId,
			onDeviceChange: handleDeviceChange,
		};
		return <Mobile {...p} />;
	}

	return (
		<Container>
			<Wrapper>
				<Cameras onClick={handleLiveView} />
				<Video video={videoId} audio={audioId} resolution={resolutionId} />
				<Product />
				<Chatting socketConnect={socketConnect} isStart={isStart} castData={castData} />
			</Wrapper>
			<Controller
				audio={audio}
				video={video}
				resolution={_RESOLUTIONS}
				onDeviceChange={handleDeviceChange}
				videoId={videoId}
				audioId={audioId}
				resolutionId={resolutionId}
				onSetStart={loadCast}
				onSetStop={onStop}
				isStart={isStart}
				castData={castData}
			/>
			{viewer && <Viewer id={viewer} onClick={handleLiveView} />}
			{isStart && <Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} />}
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background-color: #000000;
	color: #ffffff;
`;
const Wrapper = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 160px;
	overflow: hidden;
	display: flex;
`;

const Viewer = (props) => {
	const { id, onClick } = props;
	return (
		<VContainer onClick={onClick.bind(this, null)}>
			<div className="wrapper">
				<Player id={id} />
			</div>
		</VContainer>
	);
};

const VContainer = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	.wrapper {
		width: 440px;
		height: 782px;
	}
`;

/**
 * Functions
 */
const getDevices = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const audio = [],
				video = [];
			const devices = await navigator.mediaDevices.enumerateDevices();
			for (let i in devices) {
				const d = devices[i];
				switch (d.kind) {
					case "videoinput":
						video.push(d);
						break;
					case "audioinput":
						audio.push(d);
						break;
				}
			}
			const hasAudio = audio.length > 0;
			const hasVideo = video.length > 0;
			resolve({ audio, video, hasAudio, hasVideo });
		} catch (e) {
			reject(e);
		}
	});
	/*
	
	const multiDevice = [];
	// alert(JSON.stringify(devices));
	
	// alert(JSON.stringify(devices));
	ret.multiDevice = multiDevice;
	return ret;
    */
};
