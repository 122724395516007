/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { v4 as UUIDV4 } from "uuid";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";
import { tryToPlay } from "../../Components/AntMedia/fetch.stream.js";

const _SIZE = { width: 720, height: 1280 };

const hlsExtension = "m3u8";

export default (props) => {
	const { id, poster } = props;
	const VIDEO_ID = `VIDEO-VIEWER-${id}`;
	let _IS_PLAY = false;
	let _ADAPTER = null;

	if (!id) {
		return null;
	}

	const loadChannelStatus = () => {
		_API
			.get({
				path: "/stream/channel/status",
				data: { id },
			})
			.then((res) => res.data)
			.then((info) => {
				if (info) {
					if (!_IS_PLAY) {
						startWebRTCStreaming(id, VIDEO_ID).then((adapter) => {
							_ADAPTER = adapter;
							_IS_PLAY = true;
						});
					}
				} else {
					if (_IS_PLAY) {
						stopWebRTCStreaming(_ADAPTER, id);
						_IS_PLAY = false;
					}
				}
			});
	};

	useEffect(() => {
		const timer = setInterval(() => {
			loadChannelStatus();
		}, 10000);
		return () => {
			clearInterval(timer);
			if (_ADAPTER) {
				stopWebRTCStreaming(_ADAPTER, id);
				_IS_PLAY = false;
			}
		};
	}, []);

	return (
		<Container>
			<video id={VIDEO_ID} autoPlay width={720} height={1280} muted poster={poster} playsInline />
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	/* padding-top: 177.7777%; */
	padding-top: 57%;
	width: 100%;
	border-radius: 10px;
	overflow: hidden;

	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000000;
	}

	video[poster] {
		object-fit: cover;
	}
`;

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (streamId, videoId, token) => {
	return new Promise((resolve, reject) => {
		try {
			const adapter = new WebRTCAdaptor({
				websocket_url: _CFG.getStreamUrl(),
				mediaConstraints: { video: false, audio: false },
				peerconnection_config: null,
				sdp_constraints: {
					OfferToReceiveAudio: false,
					OfferToReceiveVideo: false,
				},
				remoteVideoId: videoId,
				isPlayMode: true,
				debug: true,
				callback: (info, description) => {
					switch (info) {
						case "initialized":
							adapter.getStreamInfo(streamId);
							break;
						case "streamInformation":
							adapter.play(streamId);
							break;
						case "play_started":
							_U.postMessage("PLAY-STARTED");
							break;
						case "play_finished":
							_U.postMessage("PLAY-ENDED");
							break;
						case "closed":
							if (typeof description != "undefined") {
								console.log("Connecton closed: " + JSON.stringify(description));
							}
							break;
						case "bitrateMeasurement":
							break;
					}
				},
				callbackError: (err) => {
					switch (err) {
						case "no_stream_exist":
							break;
						case "notSetRemoteDescription":
							tryToPlay(streamId, token, hlsExtension);
							break;
						case "already_playing":
							break;
						default:
							_U.postMessage("PLAY-ERROR", err);
							break;
					}
				},
			});
			resolve(adapter);
		} catch (e) {
			reject(e);
		}
	});
};

const stopWebRTCStreaming = (adapter, streamId) => {
	adapter.stop(streamId);
};
