/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { isMobile, isIOS } from "react-device-detect";
import { Base64 } from "js-base64";
import cx from "classnames";

import { AppContext } from "../Components/AppProvider";
import { _CFG } from "../Modules/Config";
import { _CK, _API, _U } from "../Modules/Utils";

import { LoginForm } from "../Views/User/UI";
import { getSrcSet } from "../UI/Assets";
import { UInputForm } from "../UI/Form";
import { CheckBox } from "../UI/Product";

import SNSLogin, { TYPES as SNSLoginTypes } from "../Components/SNSLogin";

export default (props) => {
    const { history, resolve, reject } = props;
    const { device, version, appversion, lang, orderData } = props;

    const [email, setEmail] = useState(_CK.get("saveId") || "");
    const [passwd, setPasswd] = useState("");
    const [saveId, setSaveId] = useState(_CK.get("saveId") ? true : false);
    const [isNomemberSearch, setIsNomemberSearch] = useState(false);
    const [orderNo, setOrderNo] = useState("");

    const [snsLogin, setSNSLogin] = useState(null);

    const emailRef = useRef(null);
    const passwdRef = useRef(null);
    const nomemberSearchRef = useRef(null);

    const handleLoginClick = () => {
        try {
            if (!email) {
                throw { message: "이메일을 입력해 주세요.", ref: emailRef };
            }
            if (!_U.isEmail(email)) {
                throw { message: "올바른 양식의 이메일을 입력해 주세요.", ref: emailRef };
            }
            if (!passwd) {
                throw { message: "비밀번호를 입력해 주세요.", ref: passwdRef };
            }
            const params = { id: email, passwd };
            _API.post({ path: "/user/login", data: params })
                .then((res) => res.data)
                .then((data) => {
                    if (saveId) {
                        _CK.set("saveId", email);
                    } else {
                        _CK.set("saveId", "");
                    }
                    resolve(data);
                });
        } catch (e) {
            swal({ title: "알림", text: e.message, button: "확인" }).then(() => {
                if (e.ref) {
                    e.ref.current.focus();
                }
            });
        }
    };

    const handleLocateClick = (url) => {
        reject({ url });
    };

    const handleKakaoLoginClick = () => {
        if (device && device !== "null") {
            document.location.href = `${_CFG.appSchema}login?type=kakao`;
        } else {
            snsLogin
                ?.request(SNSLoginTypes.KAKAO)
                .then((res) => {
                    resolve({ ...res, snsType: SNSLoginTypes.KAKAO });
                })
                .catch((e) => console.log(e));
        }
    };

    const handleNaverLoginClick = (e) => {
        e.stopPropagation();
        document.location.href = `${_CFG.appSchema}login?type=naver`;
        return;
    };

    const handleGoogleLoginClick = (e) => {
        e.stopPropagation();
        document.location.href = `${_CFG.appSchema}login?type=google`;
        return;
    };

    const handleAppleLoginClick = (e) => {
        e.stopPropagation();
        document.location.href = `${_CFG.appSchema}login?type=apple`;
        return;
    };

    const onGoogleSignIn = (res) => {
        resolve({ ...res, snsType: SNSLoginTypes.GOOGLE });
    };

    const onGoogleSignError = (e) => {
        console.log(e);
    };

    const onReceivedMessage = (e) => {
        if (!e.data) {
            return;
        }
        console.log("e", e.data);

        let data = null;
        try {
            data = typeof e.data === "string" && e.data.startsWith("{") ? JSON.parse(e.data) : e.data;
        } catch (e) {
            console.log("parseError", e);
        }
        try {
            if (data && data.code) {
                switch (data.code) {
                    case "LOGINED":
                        if (isIOS && (data.type === "NAVER" || data.type === "GOOGLE" || data.type === "APPLE")) {
                            data = JSON.parse(Base64.decode(Base64.btoa(JSON.stringify(data))));
                        }
                        resolve({ ...data, snsType: data?.type, profile: data?.data });
                        // _LOGIN_CHECK(data, onLoginSuccess, onLoginFailure);
                        break;
                }
            }
        } catch (e) {
            try {
                if (data && data.code) {
                    switch (data.code) {
                        case "LOGINED":
                            // if (isIOS && (data.type === "NAVER" || data.type === "GOOGLE" || data.type === "APPLE")) {
                            // 	data = JSON.parse(Base64.decode(Base64.btoa(JSON.stringify(data))));
                            // }

                            _U.postMessage("LOGINED-MESSAGE", { ...data, snsType: data?.type, profile: data?.data });

                            break;
                    }
                }
            } catch (e) {
                swal({ title: "알림", text: e.message });
            }
        }
    };

    const onClickNoMemberOrder = () => {
        _API.post({
            path: "/payment/save/nomember/order",
            data: {
                ...orderData,
            },
        }).then((res) => {
            const { orderIdx } = res.data;
            resolve({ ...{ orderIdx, isNoMemberOrder: true } });
        });
    };

    useEffect(() => {
        setSNSLogin(
            new SNSLogin({
                kakao: true,
                google: device && device !== "null" ? false : true,
                naver: true,
                facebook: false,
                apple: false,
                dev: true,
                googleSignIn: onGoogleSignIn,
                googleSignError: onGoogleSignError,
                device,
                version,
                appversion,
                lang,
            })
        );
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    const handleNomemberOrderSearch = () => {
        try {
            if (!orderNo) {
                throw { message: "주문번호를 입력해 주세요.", ref: nomemberSearchRef };
            }

            _API.get({ path: "/payment/product/order/check", data: { orderIdx: orderNo } })
                .then((res) => res.data)
                .then((data) => {
                    history.push(`/order/detail/${orderNo}`);
                    resolve();
                });
        } catch (e) {
            swal({ title: "알림", text: e.message, button: "확인" }).then(() => {
                if (e.ref) {
                    e.ref.current.focus();
                }
            });
        }
    };

    return (
        <LoginForm>
            <h2>모아 로그인</h2>
            {!orderData?.productCode && (
                <div className="tab">
                    <button onClick={(e) => setIsNomemberSearch(false)} className={cx({ active: !isNomemberSearch })}>
                        회원로그인
                    </button>
                    <button onClick={(e) => setIsNomemberSearch(true)} className={cx({ active: isNomemberSearch })}>
                        비회원 주문조회
                    </button>
                </div>
            )}

            {isNomemberSearch ? (
                <>
                    <div className="flexme form">
                        <UInputForm
                            ref={nomemberSearchRef}
                            title=""
                            name="nomemberSearch"
                            type="text"
                            value={orderNo}
                            onChange={(e) => setOrderNo(e.target.value)}
                            placeholder="주문번호"
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleNomemberOrderSearch();
                                }
                            }}
                        />
                        <button type="button" onClick={handleNomemberOrderSearch}>
                            주문조회
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="flexme form">
                        <UInputForm
                            ref={emailRef}
                            title=""
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="이메일"
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleLoginClick();
                                }
                            }}
                        />
                        <UInputForm
                            ref={passwdRef}
                            title=""
                            name="passwd"
                            type="password"
                            value={passwd}
                            onChange={(e) => setPasswd(e.target.value)}
                            placeholder="비밀번호"
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleLoginClick();
                                }
                            }}
                        />
                        <button type="button" onClick={handleLoginClick}>
                            로그인
                        </button>
                        <div className="links-button">
                            <div className="save-id">
                                <CheckBox checked={saveId} onClick={() => setSaveId(!saveId)} inline={true} />
                                아이디 저장
                            </div>
                            <div className="links">
                                <a href={void 0} onClick={handleLocateClick.bind(this, "/user/findIdPw")}>
                                    아이디/비밀번호 찾기
                                </a>
                                &nbsp;|&nbsp;
                                <a href={void 0} onClick={handleLocateClick.bind(this, "/user/signup")}>
                                    회원가입
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="split"></div>
                    <div className="sns form">
                        <h3 className="title">간편 로그인</h3>
                        <div className="sns-buttons">
                            {device && device !== "null" ? (
                                <button type="button" onClick={handleNaverLoginClick.bind(this)}>
                                    <img
                                        src="/icons/ic_sns_naver.png"
                                        srcSet={getSrcSet(["/icons/ic_sns_naver.png", "/icons/ic_sns_naver@2x.png", "/icons/ic_sns_naver@3x.png"])}
                                        width="40"
                                        height="40"
                                        alt="네이버"
                                    />
                                </button>
                            ) : (
                                <button id="naverIdLogin_loginButton" type="button">
                                    <img
                                        src="/icons/ic_sns_naver.png"
                                        srcSet={getSrcSet(["/icons/ic_sns_naver.png", "/icons/ic_sns_naver@2x.png", "/icons/ic_sns_naver@3x.png"])}
                                        width="40"
                                        height="40"
                                        alt="네이버"
                                    />
                                </button>
                            )}

                            <button type="button" onClick={handleKakaoLoginClick.bind(this)}>
                                <img
                                    src="/icons/ic_sns_kakao.png"
                                    srcSet={getSrcSet(["/icons/ic_sns_kakao.png", "/icons/ic_sns_kakao@2x.png", "/icons/ic_sns_kakao@3x.png"])}
                                    width="40"
                                    height="40"
                                    alt="카카오"
                                />
                            </button>
                            {/* <button type="button">
							<img
								src="/icons/ic_sns_facebook.png"
								srcSet={getSrcSet(["/icons/ic_sns_facebook.png", "/icons/ic_sns_facebook@2x.png", "/icons/ic_sns_facebook@3x.png"])}
								width="28"
								height="28"
								alt="네이버"
							/>
							페이스북 계정으로 로그인
						</button> */}

                            {device && device !== "null" ? (
                                <button type="button" onClick={handleGoogleLoginClick.bind(this)}>
                                    <img
                                        src="/icons/ic_sns_google.png"
                                        srcSet={getSrcSet(["/icons/ic_sns_google.png", "/icons/ic_sns_google@2x.png", "/icons/ic_sns_google@3x.png"])}
                                        width="40"
                                        height="40"
                                        alt="구글"
                                    />
                                </button>
                            ) : (
                                <button id="btn-google-login" type="button">
                                    <img
                                        src="/icons/ic_sns_google.png"
                                        srcSet={getSrcSet(["/icons/ic_sns_google.png", "/icons/ic_sns_google@2x.png", "/icons/ic_sns_google@3x.png"])}
                                        width="40"
                                        height="40"
                                        alt="구글"
                                    />
                                </button>
                            )}

                            {isIOS && device && device !== "null" && (
                                <button type="button" onClick={handleAppleLoginClick.bind(this)}>
                                    <img
                                        src="/icons/ic_sns_apple.png"
                                        srcSet={getSrcSet(["/icons/ic_sns_apple.png", "/icons/ic_sns_apple@2x.png", "/icons/ic_sns_apple@3x.png"])}
                                        width="40"
                                        height="40"
                                        alt="애플"
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}

            {orderData?.productCode && (
                <div className="no-member form">
                    <p>비회원 구매 시 쿠폰할인, 적립금 등의 혜택을 받을 수 없으며, 판매자와의 주문대화가 원활하지 않을 수 있습니다.</p>
                    <button onClick={onClickNoMemberOrder}>비회원으로 구매하기</button>
                </div>
            )}
        </LoginForm>
    );
};
const NoMemberOrder = styled.div`
    display: flex;
    justify-content: center;
    text-decoration: underline;
    color: #acacac;
`;
