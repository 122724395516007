/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../Components/AppProvider";
import { _CFG } from "../../Modules/Config";
import { _CK, _API, _U } from "../../Modules/Utils";

import { UInputForm, DateTime } from "../../UI/Form";
import { Footer } from "../../UI/Dialog";

import DateTimePicker from "react-datetime-picker";

export default (props) => {
	const { resolve, reject } = props;

	const [data, setData] = useState({});

	const onConfirm = () => {
		try {
			if (!data.title) {
				throw { message: "방송 제목을 입력해 주세요." };
			}
			if (!data.preSdate) {
				throw { message: "방송 시작 예정일을 입력해 주세요." };
			}
			if (!data.preEdate) {
				throw { message: "방송 종료 예정일을 입력해 주세요." };
			}
			const sdate = moment(data.preSdate, "YYYY-MM-DD HH:mm:ss");
			const edate = moment(data.preEdate, "YYYY-MM-DD HH:mm:ss");
			if (edate.isBefore(sdate)) {
				throw { message: "방송 예정일을 확인해 주세요." };
			}
			resolve(data);
		} catch (e) {
			swal({ title: "알림", text: e.message, button: "확인" });
		}
	};

	return (
		<Fragment>
			<Container>
				<UInputForm
					title="방송 제목"
					name="title"
					value={data.title}
					onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
					placeholder="방송 제목을 입력해 주세요."
				/>
				<DateTime title="시작 예정일" value={data.preSdate} onChange={(date) => setData({ ...data, preSdate: date })} />
				<DateTime title="종료 예정일" value={data.preEdate} onChange={(date) => setData({ ...data, preEdate: date })} />
			</Container>
			<Footer>
				<div className="choose">
					<button type="button" onClick={reject}>
						취소
					</button>
					<button type="button" onClick={onConfirm}>
						생성
					</button>
				</div>
			</Footer>
		</Fragment>
	);
};

const Container = styled.div``;
