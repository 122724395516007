/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import ScrollContainer from "react-indiana-drag-scroll";
import { isMobile } from "react-device-detect";

import { _API } from "../Modules/Utils";

import Layout from "./Layout";
import {
	MBContainer,
	HIContainer,
	OAContainer,
	OAIContainer,
	VODContainer,
	VODIContainer,
	LNContainer,
	LNIContainer,
	TDContainer,
	TDPContainer,
	TDPIContainer,
	NAContainer,
	NAIContainer,
	RContainer,
	RIContainer,
} from "./Home.Styled";

import { getSrcSet } from "../UI/Assets";

import Product, { ListWrapper } from "../UI/Product";

export default (props) => {
	const { location, history, match } = props;
	const cp = { history, location, match };

	const { code1, code2, code3 } = match.params;
	const [data, setData] = useState({});
	const [products, setProducts] = useState([]);

	const loadData = () => {
		_API
			.get({
				path: "/cms",
				data: { code1, code2, code3 },
			})
			.then((res) => res.data)
			.then((data) => setData(data))
			.catch(() => {
				history.goBack();
			});
	};

	const loadProduct = () => {
		_API
			.get({
				path: "/cms/product",
				data: { idx: data.idx },
			})
			.then((res) => res.data)
			.then((data) => setProducts(data));
	};

	useEffect(() => {
		loadData();
	}, [code1, code2, code3]);

	useEffect(() => {
		if (data.idx) {
			loadProduct();
		}
	}, [data]);

	return (
		<Layout {...props} showMenu={true}>
			<Container>
				<h3 className="title">{data.title}</h3>
				<div className="wrapper" dangerouslySetInnerHTML={{ __html: isMobile ? (data?.mobile || data?.content || "") : (data?.content || "") }} />
				{products.length > 0 && (
					<ListWrapper>
						{products.map((item, i) => (
							<Product key={i} {...cp} data={item} />
						))}
					</ListWrapper>
				)}
			</Container>
		</Layout>
	);
};

const Container = styled.div`
	margin: 0 auto 50px;
	width: ${(props) => props.theme.layout.width}px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
	}

	.title {
		margin: 0;
		padding: 0;
		font-size: 22px;
		font-weight: 500;
		color: #3d3d3d;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 0 21px;
		}
	}

	.wrapper {
		position: relative;
		margin: 16px 0 30px;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 0 21px;
		}
	}

	.wrapper img {
		max-width: 100%;
	}
`;
