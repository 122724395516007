/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { AppContext } from "../Components/AppProvider";

import { _API, _U } from "../Modules/Utils";

import { getSrcSet } from "../UI/Assets";

export default (props) => {
    const { iam } = useContext(AppContext);
    const [list, setList] = useState([]);

    /*
    const loadList = (page) => {
        _API.get({
            path: "/stream/all",
            data: { page, status: "Y" },
        })
            .then((res) => res.data)
            .then((data) => {
                console.log(data);
                const { list } = data;
                setList(list);
            });
    };

    useEffect(() => {
        if (iam) {
            loadList();
        }
    }, [iam]);
*/
    return (
        <Container>
            <Wrapper>
                <div className="info">
                    {/* {isMobile && process.env.STAGE === "dev" && (
						<select onChange={(e) => props.history.push(`/live/${e.target.value}`)} style={{ marginTop: "10px", padding: "10px" }}>
							<option value="">Live 선택</option>
							{list?.map((item, index) => (
								<option value={item.idx} key={index}>
									{item.title}
								</option>
							))}
						</select>
					)} */}
                    <div className="links">
                        <a href="#/cms/company">회사소개</a> | <a href="#/cms/user/terms">이용약관</a> | <a href="#/cms/user/privacy">개인정보처리방침</a> |{" "}
                        <a href={isMobile ? `#/service/menu` : `#/service/faq`}>서비스센터</a>
                    </div>
                    <div className="text">
                        {isMobile ? (
                            <>
                                <img src="/images/logo_white.png" srcSet={getSrcSet(["/images/logo_white.png", "/images/logo_white@2x.png", "/images/logo_white@3x.png"])} alt="MOAA" />
                                <br />
                                <b>(주) 보라비</b>
                                <br />
                                대표이사 : <b>이상대</b>
                                <br />
                                사업자번호 : <b>420-88-01346</b>
                                <br />
                                통신판매번호 : <b>2022-서울서초-2997</b>
                                <br />
                                호스팅 제공자 : <b>MOAA</b>
                                <br />
                                주소 : <b>서울특별시 서초구 바우뫼로7길 8, 801호 22611 (우면동)</b>
                                <br />
                                <a href="mailto:zz0zz79@naver.com">zz0zz79@naver.com</a>
                            </>
                        ) : (
                            <>
                                (주) 보라비 대표이사 : 이상대 | 사업자번호 : 420-88-01346{" "}
                                <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=4208801346" target="_blank">
                                    사업자 정보 확인
                                </a>{" "}
                                | 통신판매번호 2022-서울서초-2997 | 호스팅 제공자 : MOAA
                                <br />
                                주소 : 서울특별시 서초구 바우뫼로7길 8, 801호 22611 (우면동) | <a href="mailto:zz0zz79@naver.com">zz0zz79@naver.com</a>
                            </>
                        )}
                        <div className="copy">ⓒ MOAA.</div>
                    </div>
                </div>
                <div className="cs">
                    <div className="title1">고객센터</div>
                    <div className="phone">070-5143-7789</div>
                    <div className="title2">상담 운영시간(전화&채팅)</div>
                    <div className="day">평일</div>
                    <div className="time">10:00 ~ 17:00</div>
                    <div className="holiday">주말 및 공휴일 휴무</div>
                </div>
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    background: #313131;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding: 24px 21px;
    }
`;

const Wrapper = styled.div`
    margin: 0 auto;
    padding: 20px 0;
    width: ${(props) => props.theme.layout.width}px;

    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .info {
        flex-basis: 764px;
        border-right: #545454 solid 1px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex: 1;
            border-top: 1px solid #545454;
            border-right: 0;
            padding-top: 24px;

            display: flex;
            flex-direction: column-reverse;
        }
    }

    .info .links {
        color: #777777;
        font-size: 13px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex: 1;
            margin-top: 24px;
        }
    }

    .info .links a {
        color: #777777;
        text-decoration: none;
    }
    .info .links a:hover {
        color: #e32222;
    }

    .info .text {
        margin-top: 30px;
        color: #a7a7a7;
        font-size: 9px;
        font-family: ${(props) => props.theme.fontFamily.light};

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            font-size: 13px;
            flex: 1;
            margin-top: 0;

            img {
                width: 105px;
                height: 22px;
                margin-bottom: 16px;
            }

            b {
                color: #dedede;
                font-weight: normal;
                font-family: ${(props) => props.theme.fontFamily.regular};
            }

            a {
                color: #2663f1;
            }
        }
    }

    .info .text a {
        color: #a7a7a7;
        text-decoration: none;
    }
    .info .text a:hover {
        color: #e32222;
        text-decoration: underline;
    }

    .info .copy {
        margin-top: 16px;
        color: #cccccc;
        font-size: 9px;
    }

    .cs {
        padding-left: 165px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex: 1;
            padding-left: 0;
            padding-bottom: 24px;
        }
    }
    .cs .title1 {
        font-size: 14px;
        font-family: ${(props) => props.theme.fontFamily.light};
        color: #a7a7a7;
    }
    .cs .phone {
        font-size: 22px;
        color: #ffffff;
    }
    .cs .title2 {
        margin-top: 6px;
        font-size: 13px;
        font-family: ${(props) => props.theme.fontFamily.light};
        color: #a7a7a7;
    }
    .cs .day {
        margin-top: 4px;
        font-size: 10px;
        font-family: ${(props) => props.theme.fontFamily.light};
        color: #a7a7a7;
    }
    .cs .time {
        font-size: 13px;
        color: #a7a7a7;
    }
    .cs .holiday {
        font-size: 10px;
        font-family: ${(props) => props.theme.fontFamily.light};
        color: #a7a7a7;
    }
`;
