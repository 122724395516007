/* eslint-disable */
import styled from "styled-components";

export const Wrapper = styled.div`
	position: relative;
	margin: 80px auto 200px;
	width: 787px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
		margin: 40px auto 100px;
	}
`;

export const Title = styled.h2`
	margin: 0;
	padding: 0;
	font-family: ${(props) => props.theme.fontFamily.light};
	font-size: 26px;
	text-align: center;
	line-height: 37px;
	font-weight: normal;
	color: #777777;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}
`;

export const LoginForm = styled.div`
	margin: 0;
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		display: block;
		padding-bottom: 30px;
	}

	h2 {
		padding: 0;
		margin: 0;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 26px;
		line-height: 37px;
		color: #3d3d3d;
		text-align: center;
	}

	.form {
		flex: 1;
	}

	.form > .title {
		margin: 0 0 20px;
		padding: 0;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		font-weight: normal;
		color: #9c9c9c;
		line-height: 19px;
		text-align: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			margin: 0 0 9px;
		}
	}

	div.split {
		margin: 50px 0px;
		width: 100%;
		height: 1px;
		border-top: #efefef solid 1px;
		clear: both;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-left: none;
			width: 100%;
		}
	}
	div.sns {
	}

	div.sns > div.sns-buttons {
		display: flex;
		justify-content: center;
	}

	div.sns > div.sns-buttons > button {
		position: relative;
		margin: 0 24px 0 0;
		border: none;
		background: none;
		display: block;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		color: #777777;
		padding: 0;
		width: 40px;
		height: 40px;
	}

	div.sns > div.sns-buttons > button:last-child {
		margin-right: 0px;
	}

	div.sns > div.sns-buttons > button > img {
		width: 100%;
		height: 100%;
	}

	div.tab {
		width: 100%;
		display: flex;
		margin-top: 48px;
	}

	div.tab > button {
		flex: 1;
		border: none;
		background: transparent;
		border-bottom: 1px solid #efefef;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 14px;
		color: #9c9c9c;
		padding: 12px 0;
		text-align: center;
	}

	div.tab > button.active {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;

		border-bottom: 2px solid #3d3d3d;
	}

	div.no-member {
		margin-top: 40px;
		p {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #9c9c9c;
			font-size: 13px;
			line-height: 19px;
			text-align: center;
			padding: 0;
			margin: 0 auto;
			width: 320px;
		}

		button {
			width: 100%;
			padding: 14px 0;
			color: #3d3d3d;
			font-family: ${(props) => props.theme.fontFamily.medium};
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			border: 1px solid #dedede;
			background: #ffffff;
			margin-top: 12px;
		}
	}

	div.flexme {
		margin-top: 40px;
		button {
			margin: 24px 0 8px;
			width: 100%;
			height: 50px;
			border: #005a60 solid 1px;
			background: #005a60;
			color: #ffffff;
			display: block;
			font-family: ${(props) => props.theme.fontFamily.medium};
			font-size: 14px;
		}

		.links-button {
			display: block;
		}

		.save-id {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 19px;
			color: #777777;
			display: inline-block;
		}

		.links {
			text-align: right;
			font-size: 13px;
			line-height: 1.5;
			color: #acacac;
			display: inline-block;
			float: right;
		}

		.links > a {
			margin: 0 10px;
			color: #777777;
			text-decoration: none;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 19px;

			:last-child {
				margin-right: 0;
			}
		}
		.links > a:hover {
			color: #005a60;
			text-decoration: underline;
		}
	}
`;

export const SignUpForm = styled.div`
	position: relative;
	margin: 50px auto 0;
	width: 353px;

	button.signUp {
		margin: 8px 0;
		width: 100%;
		height: 50px;
		border: #e32222 solid 1px;
		background: #e32222;
		color: #ffffff;
		display: block;
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 14px;
	}

	div.split {
		background: #efefef;
		margin: 30px 0;
		height: 1px;
		width: 100%;
	}

	div.agreement {
		position: relative;
		margin: 5px 0;
		width: 100%;
		label {
			color: #3d3d3d;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 14px;
		}

		label.bold {
			font-family: ${(props) => props.theme.fontFamily.medium};
		}

		small {
			position: absolute;
			right: 0;
			top: 3px;
			color: #b8b8b8;
			font-size: 10px;
			text-decoration: underline;
			cursor: pointer;
		}

		div.desc {
			width: calc(100% - 32px);
			padding: 16px;
			background: #f9f9f9;
			margin-top: 15px;

			p {
				color: #b8b8b8;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 10px;
				line-height: 18px;
			}
		}
	}
`;
