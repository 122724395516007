/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Player from "./Player";
import Product from "./Product";
import Chatting from "./Chatting";

import Socket from "../../Components/Socket";

import Info from "../Product/View.Info";
import Content from "../Product/View.Content";
import Cams from "./Cams";

export default (props) => {
	const { location, history, match } = props;
	const cp = { history, location, match };
	const { idx } = match.params;

	const [info, setInfo] = useState(null);
	const [channels, setChannels] = useState([]);
	const [streamId, setStreamId] = useState(null);
	const [isShowCams, setIsShowCams] = useState(false);
	const [viewer, setViewer] = useState(0);
	const [reload, setReload] = useState(true);

	const [socketConnect, setSocketConnect] = useState(false);
	const [selectedProductCode, setSelectedProductCode] = useState(null);

	const loadCast = () => {
		_API
			.get({
				path: "/cast/user/info",
				data: { idx },
			})
			.then((res) => res.data)
			.then((data) => {
				if (!data || data.status !== "Y") {
					throw { message: "이미 종료된 방송입니다." };
				}
				setInfo(data);
			})
			.catch((e) => {
				swal({ title: "알림", text: e.message }).then(() => {
					history.goBack();
				});
			});
	};

	const loadCams = () => {
		if (!info) {
			return;
		}

		_API
			.get({
				path: "/cast/user/channels",
				data: { idx: info.memberIdx },
			})
			.then((res) => res.data)
			.then((data) => setChannels(data))
			.catch((e) => {
				swal({ title: "알림", text: e.message }).then(() => {
					history.goBack();
				});
			});
	};

	const handleToggleCams = () => {
		setIsShowCams(!isShowCams);
	};

	const handleStreamChange = (id, e) => {
		setStreamId(id);
		setIsShowCams(false);
	};

	const handleOnProductClick = (code) => {
		if (isMobile) {
			setSelectedProductCode(code);
		} else {
			window.open(`/#/product/${code}`);
		}
	};

	useEffect(() => {
		loadCast();
	}, [idx]);

	const sendAlive = () => {
		setReload(true);
		if (idx) {
			_API
				.post({
					path: "/cast/view/log",
					data: {
						castIdx: idx,
					},
				})
				.then((res) => {
					const { viewerCnt } = res.data;
					setViewer(viewerCnt);
					setReload(false);
				});
		}
	};

	useEffect(() => {
		loadCams();
		sendAlive();
		const timer = setInterval(() => {
			loadCams();
			sendAlive();
		}, 15000);
		return () => {
			clearInterval(timer);
		};
	}, [info]);

	useEffect(() => {
		if (!streamId) {
			if (channels && channels.length > 0) {
				setStreamId(channels[0]?.streamId);
			}
		}
	}, [channels, streamId]);

	useEffect(() => {
		if (isMobile) {
			if (document.getElementById("ch-plugin")) {
				document.getElementById("ch-plugin").style.display = "none";
			}
		}
	}, []);

	return (
		<Container>
			<Wrapper>
				{streamId && (
					<>
						<Player id={streamId} history={history} />
						<Product
							roomIdx={info?.idx}
							casterMemberIdx={info?.memberIdx}
							{...props}
							onClick={handleOnProductClick}
							setSocketConnect={setSocketConnect}
							socketConnect={socketConnect}
						/>
						<Chatting roomIdx={info?.idx} viewer={viewer} reload={reload} roomData={info} setSocketConnect={setSocketConnect} socketConnect={socketConnect} />
						{selectedProductCode && <ProductView {...cp} code={selectedProductCode} onClose={() => setSelectedProductCode(null)} />}
					</>
				)}
				{isShowCams && <Cams channels={channels} current={streamId} onChange={handleStreamChange} onClose={() => setIsShowCams(false)} />}
				<Actions>
					<button type="button" onClick={handleToggleCams}>
						<i className={cx({ fa: true, "fa-film": true })}></i>
					</button>
					<Link to="/cart" className="cart">
						<i className="fa fa-shopping-cart"></i>
					</Link>
				</Actions>
			</Wrapper>
			<Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} />
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #242424;

	display: flex;
	justify-content: center;
`;

const Wrapper = styled.div`
	position: relative;
	width: calc(100vh * 9 / 16);
	color: #ffffff;
	background: #000000;

	@media only screen and (max-width: 768px) {
		width: 100%;
		height: 100%;
	}
`;

const TopActions = styled.div`
	top: 0px;
	position: fixed;
	right: 0px;
	z-index: 2;

	button {
		width: 40px;
		height: 40px;
		background: transparent;
		border: none;
		color: #ffffff;
		font-size: 18px;
		text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
		cursor: pointer;
	}
`;

const ProductView = (props) => {
	const { onClose, code } = props;

	const [categories, setCategories] = useState([]);
	const [detail, setDetail] = useState(null);
	const [options, setOptions] = useState([]);
	const [brand, setBrand] = useState([]);
	const [media, setMedia] = useState([]);
	const [content, setContent] = useState([]);

	const handleBackClick = () => {
		onClose && onClose();
	};

	const loadData = () => {
		_API
			.get({ path: "/product/search/item", data: { code } })
			.then((res) => res.data)
			.then((data) => {
				const { categories, detail, brand, media, content, options } = data;
				setCategories(categories);
				setDetail(detail);
				setOptions(options);
				setBrand(brand);
				setMedia(media);
				setContent(content);
			});
	};

	useEffect(() => {
		loadData();
	}, [code]);

	return (
		<PVContainer>
			<PVWrapper>
				<Info media={media} brand={brand} detail={detail} options={options} {...props} />
				<Content content={content} detail={detail} isLive />
			</PVWrapper>
			<TopActions>
				<button type="button" onClick={handleBackClick}>
					<i className="fa fa-times"></i>
				</button>
			</TopActions>
		</PVContainer>
	);
};

const PVContainer = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 100;
	background: rgba(0, 0, 0, 0.8);
`;

const PVWrapper = styled.div`
	position: fixed;
	top: 40px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 101;
	background: #ffffff;
	overflow-x: hidden;
	overflow-y: auto;
`;

const Actions = styled.div`
	position: absolute;
	right: 15px;
	bottom: 88px;
	z-index: 20;

	button,
	a {
		margin-top: 8px;
		width: 40px;
		height: 40px;
		display: block;
		border: none;
		border-radius: 20px;
		background: rgba(0, 0, 0, 0.5);
		color: #ffffff;
		font-size: 18px;
		cursor: pointer;
	}

	a {
		text-align: center;
		i {
			margin-top: 12px;
		}
	}

	.cart {
		background-color: #ffffff;
		color: #e32222;
	}
`;
