/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

export default (props) => {
	const { roomIdx, roomData, setSocketConnect, socketConnect, isPc, viewer, reload } = props;
	const { auth, iam } = useContext(AppContext);
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState("");
	const [isSendProc, setIsSendProc] = useState(false);
	const endRef = useRef(null);
	const intoRef = useRef(null);

	const sendRoom = () => {
		_U.postMessage("SOCKET-SEND", {
			action: "ROOM",
			data: {
				roomIdx,
			},
		});
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e)
			.then((data) => {
				switch (data.code) {
					case "SOCKET-RECEIVED":
						return data.data || null;
				}
			})
			.then((data) => {
				if (data?.mode === "CHAT") {
					setMessages((o) => [...o.slice(-99), data]);
				}
			});
	};

	const onKeyUp = (e) => {
		if (e.key === "Enter") {
			onSend();
		}
	};

	const onSend = () => {
		if (!iam || !iam.idx) {
			swal({ title: "알림", text: "로그인 후 이용가능합니다." });
			return;
		}
		if (!socketConnect) {
			swal({ title: "알림", text: "채팅서버에 연결중입니다.\n잠시후 다시연결해 주세요." });
			return;
		}
		if (!message) {
			return;
		}
		if (isSendProc) {
			return;
		}
		setIsSendProc(true);

		_U.postMessage("SOCKET-SEND", {
			action: "CHAT",
			data: {
				mode: "CHAT",
				id: iam?.code + "_" + iam?.idx,
				code: iam?.code,
				idx: iam?.idx,
				name: iam?.name || "익명",
				nickName: iam?.nickName || "",
				roomIdx,
				message,
			},
		});
		setMessage("");
		setIsSendProc(false);
	};

	useEffect(() => {
		if (socketConnect) {
			sendRoom();
		}
	}, [socketConnect]);

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	const scrollToBottom = () => {
		intoRef.current.scrollTop = intoRef.current.scrollHeight;
	};

	useEffect(scrollToBottom, [messages]);

	return (
		<>
			<Container isPc={isPc}>
				<Wrapper>
					<MessageWrapper>
						{viewer > 0 && <ViewerCount reload={reload}>{viewer}명이 이 방송을 시청중</ViewerCount>}
						<div className="message-list" ref={intoRef}>
							{messages.map((item, i) => (
								<Message key={i} isMe={item.idx === iam?.idx} isNotice={item.isNotice === "Y"}>
									{item.isNotice !== "Y" && <span className={cx({ name: true, me: item.idx === iam?.idx })}>{item.nickName}</span>}
									{item.message}
								</Message>
							))}
						</div>
					</MessageWrapper>
				</Wrapper>
			</Container>
			<Input>
				<div className="form">
					<input type="text" placeholder="내용을 입력해 주세요" value={message || ""} onChange={(e) => setMessage(e.target.value)} onKeyUp={onKeyUp} />
					<button type="button" onClick={onSend}>
						보내기
					</button>
				</div>
			</Input>
		</>
	);
};

const Container = styled.div`
	position: absolute;

	width: 375px;
	left: 15px;

	bottom: 200px;
	z-index: 20;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	flex-direction: column;
`;

const ViewerCount = styled.div`
	background: rgba(0, 0, 0, 0.5);
	border-radius: 16px;
	color: #fe9334;
	font-size: 14px;
	font-family: ${(props) => props.theme.fontFamily.medium};
	padding: 4px 16px;

	position: absolute;
	bottom: 234px;
	left: 0;
`;

const MessageWrapper = styled.div`
	margin: 0;
	flex: 1;

	.message-list {
		max-height: 218px;
		overflow-y: auto;

		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		::-webkit-scrollbar {
			display: none; /* Chrome, Safari, Opera*/
		}
	}
`;

const Message = styled.div`
	position: relative;
	margin: 2px 0;
	margin-right: ${(props) => (props.isMe ? "15px" : "0")};
	font-size: 14px;
	line-height: 20px;
	color: #dedede;
	text-align: left;
	text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
	font-family: ${(props) => props.theme.fontFamily.regular};

	${(props) =>
		props.isNotice
			? `
			color: #2dd0f2;
			font-family: ${props.theme.fontFamily.regular};
			font-size: 14px;
			line-height: 20px;	
			margin-right:0;
			text-align:left;
	`
			: ``}

	.name {
		margin-right: 12px;
		font-size: 14px;
		color: #c7c7c7;
	}

	.me {
		color: #fbc531;
	}
`;

const Input = styled.div`
	bottom: 0;
	left: 0;
	width: calc(100% - 32px);
	height: 43px;
	position: absolute;

	padding: 15px 16px;
	background: #efefef;
	z-index: 20;

	.name {
		font-size: 14px;
		margin: 0px;
		padding: 0px;
	}
	.form {
		display: flex;
		input {
			flex: 1;
			font-family: ${(props) => props.theme.fontFamily.regular};
			padding: 5px 10px;
			border: #e2e2e2 solid 1px;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
			border-right: none;
		}
		button {
			width: 80px;
			height: 43px;
			font-size: 14px;
			color: #3d3d3d;
			border: #e2e2e2 solid 1px;
			border-left: none;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.medium};
		}
	}
`;
