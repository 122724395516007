import _CK from "../Utils/cookie";
import _U from "../Utils/utilities";

const config = {
    appSchema: "flexme://",
    kakaoKey: "c9a42f98daa9e1d0f37578acb827fbc9",
    naver: {
        login: {
            clientId: "ljVA324Rw_Dw3VO8sfn6",
        },
    },
    google: {
        maps: {
            apiKey: "AIzaSyByU4xgpSzrDltOxZQPCRCrml94ZtiXyow",
        },
    },
};

config.isLocal = () => {
    return process.env.STAGE === "local";
};

config.getAPIKey = () => {
    /*
    return "5dc889c578d75634d7b0d68db16ab3bf";
    /**/
    //*
    switch (process.env.STAGE) {
        case "prod":
            return "5dc889c578d75634d7b0d68db16ab3bf";
        default:
            return "f159181ef9f8ffee78c712ac12c962d6";
    }
    /**/
};

config.getAPIUrl = () => {
    /*
    return "https://shop-api.paprika3.net";
    /**/
    //*
    switch (process.env.STAGE) {
        case "prod":
            return "https://shop-api.paprika3.net";
        default:
            return "http://localhost:5100";
    }
    /**/
};

config.getCallbackUrlForNaverLogin = () => {
    switch (process.env.STAGE) {
        case "prod":
            return "https://shop.paprika3.net/naver.html";
        default:
            return "http://localhost:3000/naver.html";
    }
};
/*
config.getStreamUrl = () => {
    // return "wss://live-dev.flexme.com:5443/LiveApp/websocket";
    switch (process.env.STAGE) {
        case "prod":
            return "wss://live.flexme.com/LiveApp/websocket";
        default:
            return "wss://localhost/LiveApp/websocket";
    }
};

config.wsHost = () => {
    const authors = {
        apikey: config.getAPIKey(),
    };
    if (_CK.isLogin()) {
        authors.authorization = _CK.getAuth();
    }

    switch (process.env.STAGE) {
        case "prod":
            return `wss://ws.flexme.com?auth=${_U.encode(authors)}`;
        default:
            // return `wss://ws-dev.flexme.com?auth=${_U.encode(authors)}`;
            return `ws://localhost:3300?auth=${_U.encode(authors)}`;
    }
};
*/
export default config;
