/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { AppContext } from "../../Components/AppProvider";
import { _API, _U } from "../../Modules/Utils";

import { getSrcSet } from "../../UI/Assets";
import { CheckBox } from "../../UI/Product";
import { SelectBox } from "../../UI/Form";
import swal from "sweetalert";

export default (props) => {
	const { auth } = useContext(AppContext);
	const { list, onRefresh, summary, history } = props;

	const onClickRemove = (item) => {
		const params = {};
		params.productCode = item.productCode;
		params.productOptionItemList = item.productLikeItemList;

		_API
			.post({ path: "/user/payment/product/like/save", data: params })
			.then((res) => res.data)
			.then((data) => {
				const { code, message } = data;
				swal({ title: "알림", text: message });
				onRefresh();
			});
	};

	return <PWrapper>{isMobile ? <MobileList {...props} onClickRemove={onClickRemove} /> : <PCList {...props} onClickRemove={onClickRemove} />}</PWrapper>;
};

const PWrapper = styled.div`
	margin-top: 11px;
	border-top: 1px solid #3d3d3d;

	table {
		width: 100%;
		border-spacing: 0;
	}

	table > thead {
		background: #f5f5f5;
		height: 40px;
	}

	table > thead > tr > th {
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #777777;
		font-size: 12px;
		line-height: 17px;
		vertical-align: middle;
		position: relative;
		padding: 0;

		.text {
			position: absolute;
			top: 12px;
			right: -30px;
		}
	}

	table > tbody > tr > td {
		text-align: center;
		padding: 24px 0;
		border-bottom: 1px solid #efefef;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #777777;
		font-size: 13px;
		line-height: 18px;
		position: relative;

		> img {
			cursor: pointer;
			vertical-align: middle;
		}

		> img.add-cart {
			width: 30px;
			height: 30px;
			margin-right: 8px;
		}

		> b {
			color: #e32222;
			font-weight: normal;
		}

		> u {
			text-decoration: line-through;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #dedede;
			font-size: 14px;
			line-height: 20px;
		}
		> p {
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #777777;
			font-size: 16px;
			line-height: 24px;
			margin: 0;
		}
	}

	table > tbody > tr > td.text-left {
		text-align: left;
	}

	table > tbody > tr.soldout > td,
	div.m-list > .list-item.soldout {
		div.item > div.info {
			h4.title {
				color: #acacac;
			}

			div.option-price {
				div.price {
					color: #dedede;
					text-decoration: line-through;
				}
			}
		}

		div.soldout {
			color: #777777;
			font-size: 16px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			line-height: 24px;

			background-image: url("/icons/ic_soldout.png");
			background-image: -webkit-image-set(url("/icons/ic_soldout.png") 1x, url("/icons/ic_soldout@2x.png") 2x, url("/icons/ic_soldout@3x.png") 3x);
			background-repeat: no-repeat;
			background-size: 12px;
			background-position: top center;
			padding-top: 13px;
		}
	}

	table > tbody > tr.summary > td {
		padding: 16px 0;
		text-align: right;

		> b {
			position: relative;
			margin-left: 24px;
			padding-left: 24px;
			:before {
				content: "";
				position: absolute;
				left: 0;
				top: 8px;
				width: 1px;
				height: 17px;
				background: #efefef;
			}

			font-size: 18px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			line-height: 26px;

			small {
				font-size: 14px;
			}

			span {
				font-family: ${(props) => props.theme.fontFamily.nanumEB};
			}
		}
	}

	div.item {
		display: flex;
		padding-left: 16px;
		position: relative;
	}

	div.item > img {
		flex-basis: 90px;
		width: 90px;
		height: 90px;
		object-fit: cover;
		cursor: pointer;
	}

	div.item > div.info {
		flex: 1;
		padding: 0 16px;

		div.brand {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 13px;
			line-height: 19px;
		}

		h4.title {
			margin: 0px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
			padding-bottom: 9px;
			cursor: pointer;
		}

		div.option-price {
			display: flex;
			justify-content: space-between;
			margin-top: 5px;

			div.option {
				/* select {
						height: 20px;
						color: #3d3d3d;
						font-size: 12px;
						font-family: ${(props) => props.theme.fontFamily.regular};
						line-height: 12px;
						padding: 0 12px;
						min-width: 100px;
						max-width: 80%;
					} */
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #777777;
				font-size: 13px;
				line-height: 24px;
			}
			div.price {
				flex: 1;
				text-align: right;
				color: #3d3d3d;
				font-size: 14px;
				font-family: ${(props) => props.theme.fontFamily.medium};
				line-height: 24px;
			}

			div.count-box {
				button {
					width: 26px;
					height: 26px;
					border: 1px solid #dedede;
					background: #f5f5f5;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					vertical-align: middle;
					cursor: pointer;
				}

				input {
					width: 40px;
					height: 24px;
					border: 1px solid #dedede;
					font-family: ${(props) => props.theme.fontFamily.medium};
					font-size: 13px;
					line-height: 13px;
					color: #3d3d3d;
					border-left: 0;
					border-right: 0;
					padding: 0;
					vertical-align: middle;
					text-align: center;

					::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}
			}
		}
	}

	div.add-cart-box {
		position: absolute;
		border: 1px solid #cfcfcf;
		background: #ffffff;
		bottom: -50px;
		left: -100px;
		width: 260px;
		height: 95px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			top: 35px;
			bottom: unset;
			right: 0;
			left: unset;
		}

		:after {
			content: "";
			position: absolute;
			border-style: solid;
			border-width: 0 9px 8px;
			border-color: #ffffff transparent;
			display: block;
			width: 0;
			z-index: 1;
			top: -8px;
			left: 121px;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				right: 19px;
				left: unset;
			}
		}

		:before {
			content: "";
			position: absolute;
			border-style: solid;
			border-width: 0 9px 8px;
			border-color: #cfcfcf transparent;
			display: block;
			width: 0;
			z-index: 0;
			top: -9px;
			left: 121px;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				right: 19px;
				left: unset;
			}
		}

		img.close {
			position: absolute;
			right: 12px;
			top: 12px;
			width: 8px;
			height: 8px;
			cursor: pointer;
		}

		p {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			color: #3d3d3d;
			margin: 0px;
		}

		button.go-cart {
			margin-top: 8px;
			border: 1px solid #e32222;
			color: #e32222;
			width: auto;
			padding: 7px 12px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 12px;
			line-height: 17px;
			background: #ffffff;
			cursor: pointer;

			img {
				width: 6px;
				height: 10px;
				margin-left: 5px;
			}
		}
	}

	div.add-cart-box.hidden {
		display: none;
	}

	div.m-list {
		width: 100%;
	}

	div.m-list > .list-item > .header {
		margin-top: 24px;
		display: flex;
		justify-content: space-between;

		> div {
			flex: 1;
			display: flex;
			align-items: center;
			position: relative;

			:last-child {
				justify-content: flex-end;
			}
		}
	}

	div.m-list > .list-item > .item {
		margin-top: 12px;
		padding-bottom: 24px;
		border-bottom: 1px solid #efefef;
	}

	div.m-list > .list-item .point {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		color: #777777;

		> b {
			color: #e32222;
			font-weight: normal;
		}
	}
	div.m-list > .list-item > .item div.soldout {
		color: #777777;
		font-size: 16px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 24px;

		background-image: url("/icons/ic_soldout.png");
		background-image: -webkit-image-set(url("/icons/ic_soldout.png") 1x, url("/icons/ic_soldout@2x.png") 2x, url("/icons/ic_soldout@3x.png") 3x);
		background-repeat: no-repeat;
		background-size: 12px;
		background-position: center left;
		margin-top: 13px;
		padding: 0 0 0 15px;
	}

	div.m-list > .list-item > .item > .info > div.price {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #777777;
		font-size: 16px;
		line-height: 24px;
		margin: 16px 0 0 0;

		> u {
			text-decoration: line-through;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #dedede;
			font-size: 14px;
			line-height: 20px;
			margin-left: 12px;
		}
	}
`;

const PCList = (props) => {
	const { list, history, onClickRemove } = props;

	const goProduct = (productCode) => {
		history.push(`/product/${productCode}`);
	};

	return (
		<table>
			<thead>
				<tr>
					<th>상품정보</th>
					<th style={{ minWidth: "170px" }}>적립금</th>
					<th style={{ minWidth: "170px" }}>상품가격</th>
					<th style={{ minWidth: "100px" }}>배송비</th>
					<th width={80}></th>
				</tr>
			</thead>
			<tbody>
				{list?.map((item, index) => (
					<tr key={index} className={cx({ soldout: item.soldOut === "Y" })}>
						<td className="text-left">
							<div className="item">
								<img src={item.productFileSignedUrl} onClick={goProduct.bind(this, item.productCode)} />
								<div className="info">
									<div className="brand">{item.productBrandName}</div>
									<h4 className="title" onClick={goProduct.bind(this, item.productCode)}>
										{item.productName}
									</h4>

									{item?.productLikeItemList?.map((cItem, cIndex) => (
										<div className="option-price" key={cIndex}>
											<div className="option">
												수량 {cItem.quantity}개 /{cItem.productOptName} : {cItem.productOptItemName}
											</div>
											<div className="price">{cItem.productOptItemAmount > 0 && <>+{_U.numberFormat(cItem.productOptItemAmount * cItem.quantity)}원</>}</div>
										</div>
									))}
								</div>
							</div>
						</td>
						<td>
							{item.priceRewardPoint > 0 && (
								<>
									적립 : <b>{_U.numberFormat(item.priceRewardPoint)}원</b>
								</>
							)}
						</td>
						<td>
							{item.soldOut === "Y" ? (
								<div className="soldout">품절</div>
							) : (
								<>
									{(item.totalAmountSale || item.priceSale) < (item.totalAmountNormal || item.priceNormal) && (
										<>
											<u>{_U.numberFormat(item.totalAmountNormal || item.priceNormal)} 원</u>
											<br />
										</>
									)}
									<p>{_U.numberFormat(item.totalAmountSale || item.priceSale)} 원</p>
								</>
							)}
						</td>
						<td>{item.shippingAmount > 0 && item.shippingMethod !== "FREE" ? <>{_U.numberFormat(item.shippingAmount)} 원</> : "무료배송"}</td>
						<td>
							<AddCart item={item} {...props} />

							<img
								src={"/images/icon/ic_remove.png"}
								srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
								onClick={() => onClickRemove(item)}
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

const MobileList = (props) => {
	const { list, history, onClickRemove } = props;

	const goProduct = (productCode) => {
		history.push(`/product/${productCode}`);
	};

	return (
		<>
			<div className="m-list">
				{list?.map((item, index) => (
					<div className={cx({ "list-item": true, soldout: item.soldOut === "Y" })} key={index}>
						<div className="header">
							<div>
								<AddCart item={item} {...props} />
								<img
									src={"/images/icon/ic_remove.png"}
									srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
									onClick={() => onClickRemove(item)}
								/>
							</div>
						</div>
						<div className="item">
							<img src={item.productFileSignedUrl} onClick={goProduct.bind(this, item.productCode)} />
							<div className="info">
								<div className="brand">{item.productBrandName}</div>
								<h4 className="title" onClick={goProduct.bind(this, item.productCode)}>
									{item.productName}
								</h4>
								{item.priceRewardPoint > 0 && (
									<div className="point">
										적립 : <b>{_U.numberFormat(item.priceRewardPoint)}원</b>
									</div>
								)}
								{item?.productLikeItemList?.map((cItem, cIndex) => (
									<div className="option-price" key={cIndex}>
										<div className="option">
											수량 {cItem.quantity}개 /{cItem.productOptName} : {cItem.productOptItemName}
										</div>
										<div className="price">{cItem.productOptItemAmount > 0 && <>+{_U.numberFormat(cItem.productOptItemAmount * cItem.quantity)}원</>}</div>
									</div>
								))}
								{item.soldOut === "Y" ? (
									<div className="soldout">품절</div>
								) : (
									<div className="price">
										{_U.numberFormat(item.totalAmountSale || item.priceSale)} 원
										{(item.totalAmountSale || item.priceSale) < (item.totalAmountNormal || item.priceNormal) && (
											<u>{_U.numberFormat(item.totalAmountNormal || item.priceNormal)} 원</u>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

const AddCart = (props) => {
	const { item, history } = props;
	const [isAddCart, setIsAddCart] = useState(false);

	const handleToCartClick = () => {
		if (item.soldOut === "Y") {
			swal({ title: "알림", text: "품절된 상품 입니다.", button: "확인" });
			return;
		}

		const options = [];
		if (item.productLikeItemList && item.productLikeItemList.length > 0) {
			for (let i = 0; i < item.productLikeItemList.length; i++) {
				const option = item.productLikeItemList[i];
				options.push({
					idx: option.productOptItemIdx,
					size: option.quantity,
				});
			}
		}

		if (options.length === 0) {
			swal({ title: "알림", text: "상품 옵션이 선택되지 않았습니다. 상품 상세 페이지로 이동합니다.", button: "확인" }).then(() => {
				history.push(`/product/${item.productCode}`);
			});

			return;
		}

		let ORIGIN_URL = `/user/payment/product/cart/save`;
		/** @임시코드
		 *  @desc: 재고 상황으로 인한 추가 구매 Block
		 *  */
		// START
		ORIGIN_URL = `/user/payment/product/cart-imsi/save`;
		// END --

		const data = { productCode: item.productCode, productOptionItemList: options, checkYn: "Y" };
		_API.post({ path: ORIGIN_URL, data }).then((res) => {
			const { code, message } = res.data;

			setIsAddCart(true);
			setTimeout(() => {
				setIsAddCart(false);
			}, 3000);
		});
	};

	return (
		<>
			<div className={cx({ "add-cart-box": true, hidden: !isAddCart })}>
				<img
					src={"/icons/ic_remove.png"}
					srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
					className="close"
					onClick={() => setIsAddCart(false)}
				/>
				<div>
					<p>상품이 장바구니에 담겼습니다.</p>
					<button className="go-cart" onClick={() => history.push("/cart")}>
						장바구니 바로가기
						<img
							src={"/icons/ic_arrow_right_red.png"}
							srcSet={getSrcSet(["/icons/ic_arrow_right_red.png", "/icons/ic_arrow_right_red@2x.png", "/icons/ic_arrow_right_red@3x.png"])}
						/>
					</button>
				</div>
			</div>
			<img
				src={"/images/icon/ic_cart_red.png"}
				srcSet={getSrcSet(["/icons/ic_cart_red.png", "/icons/ic_cart_red@2x.png", "/icons/ic_cart_red@3x.png"])}
				onClick={() => handleToCartClick()}
				className="add-cart"
			/>
		</>
	);
};
