/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Layout, { Container } from "../Layout";
import Dialog from "../../UI/Dialog";
import Paging from "../../UI/Paging";

import Form from "./Form";

export default (props) => {
	const { location, history, match } = props;
	const { auth, iam } = useContext(AppContext);
	const { device, version, appversion, lang } = useContext(AppContext);

	if (!auth || iam?.isStreamer !== "Y") {
		history.goBack();
		return null;
	}

	const [list, setList] = useState([]);
	const [paging, setPaging] = useState(null);

	const loadList = (page) => {
		_API
			.get({
				path: "/stream",
				data: { page },
			})
			.then((res) => res.data)
			.then((data) => {
				const { paging, list } = data;
				setPaging(paging);
				setList(list);
			});
	};

	const movePage = (page) => {
		loadList(page);
	};

	const onShowForm = () => {
		Dialog({
			title: "라이브 송출 등록",
			body: <Form />,
		}).then((data) => {
			_API
				.post({
					path: "/stream",
					data,
				})
				.then((res) => {
					loadList(1);
				});
		});
	};

	const handleDeleteClick = (idx) => {
		swal({
			title: "알림",
			text: "삭제 하시겠습니까?",
			buttons: ["취소", "삭제"],
		}).then((willDelete) => {
			if (willDelete) {
				_API
					.delete({
						path: "/stream",
						data: { idx },
					})
					.then((res) => {
						loadList(paging.pageNo);
					});
			}
		});
	};

	const goStremDetail = (item) => {
		if ((device && device !== "null") || isMobile) {
			// window.open(`/#/streamer/cameras/${item.idx}`);
			document.location.href = `/#/streamer/cameras/${item.idx}`;
		} else {
			history.push(`/streamer/${item.idx}`);
		}
	};

	useEffect(() => {
		if (!auth || iam?.isStreamer !== "Y") {
			history.push("/");
		}

		loadList(1);
	}, [auth]);

	let virtualNo = paging ? paging.virtualRecordNo : -1;

	return (
		<Layout {...props}>
			<Container>
				<TitleWrapper>
					<Title>라이브 송출 목록</Title>
					<div className="options">
						<button type="button" onClick={onShowForm}>
							신규 등록
						</button>
					</div>
				</TitleWrapper>
				<Wrapper>
					<table>
						<thead>
							<tr>
								{!isMobile && (
									<th rowSpan={2} width="80">
										번호
									</th>
								)}
								<th rowSpan={2}>제목</th>
								<th colSpan={isMobile ? 0 : 2}>방송 예정일</th>
								<th rowSpan={2} width={isMobile ? `auto` : `150`}>
									상태
								</th>
								<th rowSpan={2} width="50">
									삭제
								</th>
							</tr>
							{!isMobile && (
								<tr>
									<th width="150">시작</th>
									<th width="150">종료</th>
								</tr>
							)}
						</thead>
						<tbody>
							{list.map((item, i) => (
								<tr key={i}>
									{!isMobile && <td className="text-center">{virtualNo--}</td>}
									<td>
										<a onClick={(e) => goStremDetail(item)} style={{ color: "-webkit-link", textDecoration: "underline" }}>
											{item.title}
										</a>
										{/* <Link to={`/streamer/${item.idx}`}>{item.title}</Link> */}
									</td>
									{isMobile ? (
										<td className="text-left">
											{moment(item.preSdate).format("YYYY-MM-DD HH:mm:ss")} <div>~ {moment(item.preEdate).format("YYYY-MM-DD HH:mm:ss")}</div>
										</td>
									) : (
										<>
											<td className="text-center">{moment(item.preSdate).format("YYYY-MM-DD HH:mm:ss")}</td>
											<td className="text-center">{moment(item.preEdate).format("YYYY-MM-DD HH:mm:ss")}</td>
										</>
									)}
									<td className="text-center">{_DEF.getDefinedLabel(_DEF.STREAM_STATUS, item.status)}</td>
									<td className="text-center">
										<button type="button" className="delete" onClick={handleDeleteClick.bind(this, item.idx)}>
											<i className="fa fa-trash"></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{paging && (
						<div className="paging">
							<Paging paging={paging} pageCallback={movePage} />
						</div>
					)}
				</Wrapper>
			</Container>
		</Layout>
	);
};

const Title = styled.div`
	margin: 0px;
	padding: 0px;

	font-size: 24px;
	font-weight: 500;
	color: #3d3d3d;
	font-family: ${(props) => props.theme.fontFamily.medium};
`;

const TitleWrapper = styled.div`
	position: relative;
	margin: 10px 0 40px;
	padding: 0 0 20px;

	border-bottom: #3d3d3d solid 2px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px 20px;
		border-bottom: none;
		margin: 10px 0 20px;
	}
	.options {
		position: absolute;
		right: 0;
		top: 0;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			top: 10px;
			right: 21px;
		}

		button {
			width: 110px;
			height: 32px;
			font-size: 13px;
			background-color: #ffffff;
			color: #777;
			border: #cfcfcf solid 1px;
			cursor: pointer;

			:hover {
				background-color: #00788d;
				color: #ffffff;
			}
		}
	}
`;

const Wrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px 21px;
	}
	table {
		margin: 8px 0 20px;
		border-top: 1px solid #3d3d3d;
		width: 100%;
		border-spacing: 0 0;

		thead {
			background: #f5f5f5;
		}
		thead > tr > th {
			text-align: center;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 12px;
			line-height: 17px;
			padding: 9px 0;
			color: #777777;
			border-bottom: 1px solid #dfdfdf;
		}

		tbody > tr > td {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			padding: 14px 0;
			color: #3d3d3d;
			border-bottom: 1px solid #dfdfdf;

			button {
				border: 1px solid #cfcfcf;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 10px;
				line-height: 15px;
				color: #9a9a9a;
				background: #ffffff;
				padding: 5px 10px;
			}

			button.delete {
				color: #00788d;
				background: #ffffff;
				border: 1px solid #00788d;
			}
		}

		tbody > tr > td.text-center {
			text-align: center;
		}
	}
`;

export { default as StreamerLiveView } from "./Live";
export { default as StreamerLiveController } from "./Live.Controller";
