/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { AppContext } from "../../Components/AppProvider";
import { _API, _U } from "../../Modules/Utils";
import Dialog from "../../UI/Dialog";

import { getSrcSet, getBackgroundSet } from "../../UI/Assets";
import { CheckBox } from "../../UI/Product";
import { SelectBox } from "../../UI/Form";
import swal from "sweetalert";

import { Options } from "../Product/View.Info";

export default (props) => {
	const { auth } = useContext(AppContext);
	const { list, onRefresh, summary, history, location, match } = props;

	const [checked, setChecked] = useState([]);

	const handleSizeChange = (cItem, mode, soldOut) => {
		if (soldOut === "Y") {
			return;
		}

		let quantity = cItem.quantity;
		switch (mode) {
			case "PLUS":
				/** @임시코드
				 *  @desc: 재고 상황으로 인한 추가 구매 Block
				 *  */
				// START
				swal({ title: "알림", text: "이미 장바구니에 추가된 상품입니다.", button: "확인" });
				return;
				// END --

				quantity += 1;
				break;
			case "MINUS":
				if (quantity > 1) {
					quantity -= 1;
				}
				break;
			default:
				if (mode > 0) {
					quantity = parseInt(mode);
				}
				break;
		}

		if (quantity > 0) {
			const data = { cartItemIdx: cItem.cartItemIdx, productOptItemIdx: cItem.productOptItemIdx, quantity };
			_API.post({ path: "/user/payment/product/cart/item/save", data }).then((res) => {
				const { code, message } = res.data;
				if (code !== 1) {
					swal({ title: "알림", text: message, button: "확인" });
					return;
				}

				onRefresh();
			});
		}
	};

	const isAllChecked = () => {
		const tmp = list.filter((item) => item.checkYn === "Y");

		if (tmp.length === list.length) {
			return true;
		}

		return false;
	};

	const onSetChecked = (item) => {
		const data = { cartIdxs: [item.cartIdx] };
		_API.post({ path: "/user/payment/product/cart/check", data }).then((res) => {
			onRefresh();
		});
	};

	const onSetAllChecked = () => {
		const cartIdxs = list.map(function (item) {
			return item.cartIdx;
		});

		const data = { status: isAllChecked() ? "N" : "Y", cartIdxs };
		_API.post({ path: "/user/payment/product/cart/check", data }).then((res) => {
			onRefresh();
		});
	};

	const goProduct = (productCode) => {
		history.push(`/product/${productCode}`);
	};

	useEffect(() => {
		const tmp = list.filter((item) => item.checkYn === "Y");
		const cartIdxs = tmp.map(function (item) {
			return item.cartIdx;
		});

		setChecked(cartIdxs);
	}, [list]);

	const onCheckedRemove = () => {
		if (checked.length === 0) {
			swal({ title: "알림", text: "삭제할 상품을 선택하세요.", button: "확인" });
			return;
		}

		const data = { cartIdxList: checked };
		_API.post({ path: "/user/payment/product/cart/cancel", data }).then((res) => {
			onRefresh();
		});
	};

	const onSoldoutRemove = () => {
		const tmp = list.filter((item) => item.soldOut === "Y");
		const cartIdxs = tmp.map(function (item) {
			return item.cartIdx;
		});

		if (cartIdxs.length === 0) {
			swal({ title: "알림", text: "삭제할 상품이 없습니다.", button: "확인" });
			return;
		}

		const data = { cartIdxList: cartIdxs };
		_API.post({ path: "/user/payment/product/cart/cancel", data }).then((res) => {
			onRefresh();
		});
	};

	const onClickRemove = (cartIdx) => {
		const data = { cartIdxList: [cartIdx] };
		_API.post({ path: "/user/payment/product/cart/cancel", data }).then((res) => {
			onRefresh();
		});
	};

	const onClickOptionChange = (item) => {
		console.log(item);
		Dialog({ title: "옵션 변경", body: <OptionView {...{ location, history, match, code: item.productCode, auth, selectOptions: item?.cartItemList }} />, width: 500 })
			.then((data) => {
				onRefresh();
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	return (
		<PWrapper>
			{isMobile ? (
				<MobileList
					onSetAllChecked={onSetAllChecked}
					isAllChecked={isAllChecked}
					onSetChecked={onSetChecked}
					goProduct={goProduct}
					handleSizeChange={handleSizeChange}
					onClickRemove={onClickRemove}
					onCheckedRemove={onCheckedRemove}
					onSoldoutRemove={onSoldoutRemove}
					checked={checked}
					onClickOptionChange={onClickOptionChange}
					{...props}
				/>
			) : (
				<PCList
					onSetAllChecked={onSetAllChecked}
					isAllChecked={isAllChecked}
					onSetChecked={onSetChecked}
					goProduct={goProduct}
					handleSizeChange={handleSizeChange}
					onClickRemove={onClickRemove}
					onCheckedRemove={onCheckedRemove}
					onSoldoutRemove={onSoldoutRemove}
					checked={checked}
					onClickOptionChange={onClickOptionChange}
					{...props}
				/>
			)}
		</PWrapper>
	);
};

const PCList = (props) => {
	const { list, onSetAllChecked, isAllChecked, onSetChecked, goProduct, handleSizeChange, onClickRemove, summary, onCheckedRemove, onSoldoutRemove, checked, onClickOptionChange } =
		props;

	console.log(list);

	return (
		<>
			<table>
				<thead>
					<tr>
						<th width={52}>
							<CheckBox inline={true} onClick={() => onSetAllChecked()} margin={"margin:0;"} checked={isAllChecked()} />
							<div className="text">전체선택</div>
						</th>
						<th>상품정보</th>
						<th style={{ minWidth: "170px" }}>적립금</th>
						<th style={{ minWidth: "170px" }}>상품가격</th>
						<th style={{ minWidth: "100px" }}>배송비</th>
						<th width={50}></th>
					</tr>
				</thead>
				<tbody>
					{list?.map((item, index) => (
						<tr key={index} className={cx({ soldout: item.soldOut === "Y" })}>
							<td>
								<CheckBox inline={true} onClick={() => onSetChecked(item)} checked={item.checkYn === "Y"} margin={"margin:0;"} />
							</td>
							<td className="text-left">
								<div className="item">
									<img src={item.productFileSignedUrl} onClick={goProduct.bind(this, item.productCode)} />
									<div className="info">
										<div className="brand">{item.productBrandName}</div>
										<h4 className="title" onClick={goProduct.bind(this, item.productCode)}>
											{item.productName}
										</h4>

										{item?.cartItemList?.map((cItem, cIndex) => (
											<div className="option-price" key={cIndex}>
												<div className="count-box">
													<button onClick={handleSizeChange.bind(this, cItem, "MINUS", item.soldOut)}>
														<img src="/icons/ic_minus.png" srcSet={getSrcSet(["/icons/ic_minus.png", "/icons/ic_minus@2x.png", "/icons/ic_minus@3x.png"])} />
													</button>
													<input
														type="number"
														value={cItem.quantity || ""}
														onChange={(e) => handleSizeChange(cItem, e.target.value, item.soldOut)}
														disabled={item.soldOut === "Y"}
													/>
													<button onClick={handleSizeChange.bind(this, cItem, "PLUS", item.soldOut)}>
														<img src="/icons/ic_plus.png" srcSet={getSrcSet(["/icons/ic_plus.png", "/icons/ic_plus@2x.png", "/icons/ic_plus@3x.png"])} />
													</button>
												</div>
												<div className="option">
													{cItem.productOptName} : {cItem.productOptItemName}
												</div>
												<div className={cx({ price: true, soldout: cItem.productOptItemQuantity <= 0 })}>
													{cItem.productOptItemQuantity > 0 ? (
														<>{cItem.productOptItemAmount > 0 && <>+{_U.numberFormat(cItem.productOptItemAmount * cItem.quantity)}원</>}</>
													) : (
														<>품절</>
													)}
												</div>
											</div>
										))}
										{item?.cartItemList?.length > 0 && (
											<button className="btn-option-change" onClick={onClickOptionChange.bind(this, item)}>
												옵션 변경
											</button>
										)}
									</div>
								</div>
							</td>
							<td>
								{item.priceRewardPoint > 0 && (
									<>
										적립 : <b>{_U.numberFormat(item.priceRewardPoint)}원</b>
									</>
								)}
							</td>
							<td>
								{item.soldOut === "Y" ? (
									<div className="soldout">품절</div>
								) : (
									<>
										{item.totalAmountSale < item.totalAmountNormal && (
											<>
												<u>{_U.numberFormat(item.totalAmountNormal)} 원</u>
												<br />
											</>
										)}
										<p>{_U.numberFormat(item.totalAmountSale)} 원</p>
									</>
								)}
							</td>
							<td>{item.shippingMethod === "FREE" ? "무료배송" : <>{_U.numberFormat(item.totalShippingAmount)} 원</>}</td>
							<td>
								<img
									src={"/images/icon/ic_remove.png"}
									srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
									onClick={() => onClickRemove(item.cartIdx)}
								/>
							</td>
						</tr>
					))}
					<tr className="summary">
						<td colSpan={6}>
							상품 {_U.numberFormat(summary?.saleAmount)} + 배송비 {summary?.shippingAmount > 0 ? _U.numberFormat(summary?.shippingAmount) : "무료"}
							<b>
								<small>합계 &nbsp;</small>
								<span>{_U.numberFormat(summary?.totalAmount)}</span> 원
							</b>
						</td>
					</tr>
				</tbody>
			</table>
			<div className="check-option">
				<CheckBox inline={true} onClick={() => onSetAllChecked()} checked={isAllChecked()} title={`전체선택(${checked.length}/${list.length})`} />

				<div className="buttons">
					<button onClick={() => onCheckedRemove()}>선택삭제</button>
					<button onClick={() => onSoldoutRemove()}>품절/판매종료상품 전체삭제</button>
				</div>
			</div>
		</>
	);
};

const MobileList = (props) => {
	const { list, onSetAllChecked, isAllChecked, onSetChecked, goProduct, handleSizeChange, onClickRemove, summary, onCheckedRemove, onSoldoutRemove, checked, onClickOptionChange } =
		props;

	return (
		<>
			<div className="check-option">
				<CheckBox inline={true} onClick={() => onSetAllChecked()} checked={isAllChecked()} title={`전체선택(${checked.length}/${list.length})`} />

				<div className="buttons">
					<button onClick={() => onCheckedRemove()}>선택삭제</button>
				</div>
			</div>
			<div className="m-list">
				{list?.map((item, index) => (
					<div className={cx({ "list-item": true, soldout: item.soldOut === "Y" })} key={index}>
						<div className="header">
							<CheckBox inline={true} onClick={() => onSetChecked(item)} checked={item.checkYn === "Y"} margin={"margin:0;"} />
							<div>
								<img
									src={"/images/icon/ic_remove.png"}
									srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
									onClick={() => onClickRemove(item.cartIdx)}
								/>
							</div>
						</div>
						<div className="item">
							<img src={item.productFileSignedUrl} onClick={goProduct.bind(this, item.productCode)} />
							<div className="info">
								<div className="brand">{item.productBrandName}</div>
								<h4 className="title" onClick={goProduct.bind(this, item.productCode)}>
									{item.productName}
								</h4>
								{item.priceRewardPoint > 0 && (
									<div className="point">
										적립 : <b>{_U.numberFormat(item.priceRewardPoint)}원</b>
									</div>
								)}
								{item?.cartItemList?.map((cItem, cIndex) => (
									<div className="option-price" key={cIndex}>
										<div className="count-box">
											<button onClick={handleSizeChange.bind(this, cItem, "MINUS", item.soldOut)}>
												<img src="/icons/ic_minus.png" srcSet={getSrcSet(["/icons/ic_minus.png", "/icons/ic_minus@2x.png", "/icons/ic_minus@3x.png"])} />
											</button>
											<input type="number" value={cItem.quantity || ""} onChange={(e) => handleSizeChange(cItem, e.target.value, item.soldOut)} disabled={item.soldOut === "Y"} />
											<button onClick={handleSizeChange.bind(this, cItem, "PLUS", item.soldOut)}>
												<img src="/icons/ic_plus.png" srcSet={getSrcSet(["/icons/ic_plus.png", "/icons/ic_plus@2x.png", "/icons/ic_plus@3x.png"])} />
											</button>
										</div>
										<div className="option">
											{cItem.productOptName} : {cItem.productOptItemName}
										</div>
										{cItem.productOptItemAmount > 0 && <div className="price"> +{_U.numberFormat(cItem.productOptItemAmount * cItem.quantity)}원</div>}
									</div>
								))}
								{item.soldOut === "Y" ? (
									<div className="soldout">품절</div>
								) : (
									<div className="price">
										{_U.numberFormat(item.totalAmountSale)} 원{item.totalAmountSale < item.totalAmountNormal && <u>{_U.numberFormat(item.totalAmountNormal)} 원</u>}
									</div>
								)}
								{item?.cartItemList?.length > 0 && (
									<button className="btn-option-change" onClick={onClickOptionChange.bind(this, item)}>
										옵션 변경
									</button>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

const PWrapper = styled.div`
	margin-top: 11px;
	border-top: 1px solid #3d3d3d;

	/* @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		display: flex;
		flex-direction: column-reverse;
	} */

	table {
		width: 100%;
		border-spacing: 0;
	}

	table > thead {
		background: #f5f5f5;
		height: 40px;
	}

	table > thead > tr > th {
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #777777;
		font-size: 12px;
		line-height: 17px;
		vertical-align: middle;
		position: relative;
		padding: 0;

		.text {
			position: absolute;
			top: 12px;
			right: -30px;
		}
	}

	table > tbody > tr > td {
		text-align: center;
		padding: 24px 0;
		border-bottom: 1px solid #efefef;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #777777;
		font-size: 13px;
		line-height: 18px;

		> span {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}

		> img {
			cursor: pointer;
		}

		> b {
			color: #e32222;
			font-weight: normal;

			span {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}

		> u {
			text-decoration: line-through;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #dedede;
			font-size: 14px;
			line-height: 20px;

			span {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}
		> p {
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #777777;
			font-size: 16px;
			line-height: 24px;
			margin: 0;

			span {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}
	}

	table > tbody > tr > td.text-left {
		text-align: left;
	}

	table > tbody > tr.soldout > td,
	div.m-list > .list-item.soldout {
		div.item > div.info {
			h4.title {
				color: #acacac;
			}

			div.option-price {
				div.price {
					color: #dedede;
					text-decoration: line-through;
					font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
				}
			}
		}

		div.soldout {
			color: #777777;
			font-size: 16px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			line-height: 24px;

			background-image: url("/icons/ic_soldout.png");
			background-image: -webkit-image-set(url("/icons/ic_soldout.png") 1x, url("/icons/ic_soldout@2x.png") 2x, url("/icons/ic_soldout@3x.png") 3x);
			background-repeat: no-repeat;
			background-size: 12px;
			background-position: top center;
			padding-top: 13px;
		}
	}

	table > tbody > tr.summary > td {
		padding: 16px 0;
		text-align: right;

		> b {
			position: relative;
			margin-left: 24px;
			padding-left: 24px;
			:before {
				content: "";
				position: absolute;
				left: 0;
				top: 8px;
				width: 1px;
				height: 17px;
				background: #efefef;
			}

			font-size: 18px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			line-height: 26px;

			small {
				font-size: 14px;
			}

			span {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}
	}

	div.item {
		display: flex;
	}

	div.item > div.info > button.btn-option-change {
		border: 1px solid #cfcfcf;
		background: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.light};
		font-size: 13px;
		line-height: 18px;
		color: #777777;
		margin-top: 8px;

		float: right;
	}

	div.item > img {
		flex-basis: 90px;
		width: 90px;
		height: 90px;
		object-fit: cover;
		cursor: pointer;
	}

	div.item > div.info {
		flex: 1;
		padding: 0 16px;

		div.brand {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 13px;
			line-height: 19px;
		}

		h4.title {
			margin: 0px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
			padding-bottom: 9px;
			cursor: pointer;
		}

		div.option-price {
			display: flex;
			justify-content: space-between;
			margin-top: 5px;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex-wrap: wrap;
			}

			div.option {
				/* select {
						height: 20px;
						color: #3d3d3d;
						font-size: 12px;
						font-family: ${(props) => props.theme.fontFamily.regular};
						line-height: 12px;
						padding: 0 12px;
						min-width: 100px;
						max-width: 80%;
					} */
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #777777;
				font-size: 13px;
				line-height: 24px;
				padding-left: 8px;
			}
			div.price {
				flex: 1;
				text-align: right;
				color: #3d3d3d;
				font-size: 14px;
				font-family: ${(props) => props.theme.fontFamily.medium};
				line-height: 24px;
			}

			div.price.soldout {
				color: #dedede;
			}

			div.count-box {
				button {
					width: 26px;
					height: 26px;
					border: 1px solid #dedede;
					background: #f5f5f5;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					vertical-align: middle;
					cursor: pointer;
				}

				input {
					width: 40px;
					height: 24px;
					border: 1px solid #dedede;
					font-family: ${(props) => props.theme.fontFamily.medium};
					font-size: 13px;
					line-height: 13px;
					color: #3d3d3d;
					border-left: 0;
					border-right: 0;
					padding: 0;
					vertical-align: middle;
					text-align: center;

					::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}
			}
		}
	}

	div.check-option {
		margin-top: 15px;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #efefef;
			margin-top: 0;
			padding: 24px 0;
		}

		> div {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #777777;
			font-size: 12px;
			line-height: 17px;
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				display: flex;
				align-items: center;
			}
		}

		> div.buttons {
			display: inline-block;
			margin-left: 4px;
			button {
				font-family: ${(props) => props.theme.fontFamily.light};
				font-size: 12px;
				line-height: 16px;
				background: #ffffff;
				border: 1px solid #cfcfcf;
				padding: 4px 8px;
				text-align: center;
				margin-left: 8px;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					padding: 8px 10px;
				}
			}

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				text-align: right;
			}
		}
	}

	div.m-list {
		width: 100%;
	}

	div.m-list > .list-item > .header {
		margin-top: 24px;
		display: flex;
		justify-content: space-between;

		> div {
			flex: 1;
			display: flex;
			align-items: center;

			:last-child {
				justify-content: flex-end;
			}
		}
	}

	div.m-list > .list-item > .item {
		margin-top: 12px;
		padding-bottom: 24px;
		border-bottom: 1px solid #efefef;
	}

	div.m-list > .list-item .point {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		color: #777777;

		> b {
			color: #e32222;
			font-weight: normal;
			> span {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}
	}
	div.m-list > .list-item > .item div.soldout {
		color: #777777;
		font-size: 16px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 24px;

		background-image: url("/icons/ic_soldout.png");
		background-image: -webkit-image-set(url("/icons/ic_soldout.png") 1x, url("/icons/ic_soldout@2x.png") 2x, url("/icons/ic_soldout@3x.png") 3x);
		background-repeat: no-repeat;
		background-size: 12px;
		background-position: center left;
		margin-top: 13px;
		padding: 0 0 0 15px;
	}

	div.m-list > .list-item > .item > .info > div.price {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #777777;
		font-size: 16px;
		line-height: 24px;
		margin: 16px 0 0 0;

		> span {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}

		> u {
			text-decoration: line-through;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #dedede;
			font-size: 14px;
			line-height: 20px;
			margin-left: 12px;
		}
	}
`;

const OptionView = (props) => {
	const { code, auth, selectOptions, reject, resolve } = props;

	const [detail, setDetail] = useState(null);
	const [options, setOptions] = useState([]);
	const [brand, setBrand] = useState([]);
	const [media, setMedia] = useState([]);
	const [tempOptions, setTempOptions] = useState(null);

	const changeOptions = (items) => {
		setTempOptions(items);
	};

	const loadData = () => {
		_API
			.get({ path: "/product/search/item", data: { code } })
			.then((res) => res.data)
			.then((data) => {
				const { detail, brand, media, options } = data;
				setDetail(detail);
				setOptions(options);
				setBrand(brand);
				setMedia(media);
			});
	};

	useEffect(() => {
		loadData();
	}, [code]);

	useEffect(() => {
		if (options && options.length > 0 && selectOptions && selectOptions.length > 0) {
			const tmpOptions = [];

			for (let i = 0; i < selectOptions.length; i++) {
				const option = selectOptions[i];
				const o = {
					amount: option.productOptItemAmount,
					code: detail.code,
					idx: option.productOptItemIdx,
					name: option.productOptItemName,
					title: `${detail.name} - ${option.productOptItemName}`,
					itemAmount: detail.priceSale + option.productOptItemAmount,
					quantity: option.productOptItemQuantity,
					optionIdx: option.productOptIdx,
					size: option.quantity,
				};
				if (option.productOptType !== "COMBINATION") {
					o.title = option.productOptItemName;
					o.itemAmount = option.productOptItemAmount;
				}

				tmpOptions.push(o);
			}
			setTempOptions(tmpOptions);
		}
	}, [selectOptions, options]);

	const onSave = () => {
		if (tempOptions.length === 0) {
			swal({ title: "알림", text: "상품 옵션을 하나 이상 선택해 주세요.", button: "확인" });
			return;
		}

		const data = { productCode: detail.code, productOptionItemList: tempOptions, checkYn: "Y", isOptionChange: "Y" };
		let ORIGIN_URL = `/user/payment/product/cart/save`;

		/** @임시코드
		 *  @desc: 재고 상황으로 인한 추가 구매 Block
		 *  */
		// START
		ORIGIN_URL = `/user/payment/product/cart-imsi/save`;
		// END --
		_API.post({ path: ORIGIN_URL, data }).then((res) => {
			resolve();
		});
	};

	return (
		<OVWrapper>
			<div className="brand">
				{brand?.map((item, i) => (
					<span key={i}>{item.nameEng} </span>
				))}
			</div>
			<h2 className="title">{detail?.name}</h2>
			<div className="cart-option-box">
				<Options detail={detail} data={options} changeOptions={changeOptions} auth={auth} isCart={true} options={tempOptions} {...props} />
			</div>
			<div className="buttons">
				<button onClick={() => reject()}>취소</button>
				<button className="action" onClick={onSave}>
					확인
				</button>
			</div>
		</OVWrapper>
	);
};

const OVWrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-bottom: 60px;
	}
	div.cart-option-box {
		max-height: 620px;
		overflow-y: auto;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			max-height: unset;
			overflow-y: unset;
		}
	}

	div.brand {
	}

	div.brand span {
		color: #777777;
		font-size: 14px;
		font-family: ${(props) => props.theme.fontFamily.regular};
		line-height: 20px;
		padding-right: 12px;

		/* background-image: url("/icons/ic_arrow_right.png");
        background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_right.png", "/icons/ic_arrow_right@2x.png", "/icons/ic_arrow_right@3x.png"])});
        background-repeat: no-repeat;
        background-size: 5px 9px;
        background-position: right center; */
	}

	h2.title {
		color: #3d3d3d;
		font-size: 18px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 26px;
		padding: 0px;
		margin: 4px 0 0 0px;
		font-weight: normal;
		width: 75%;
	}

	section.option-info {
		margin-top: 21px;
		h4 {
			font-weight: normal;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 14px;
			color: #3d3d3d;
			margin: 0px;
			line-height: 18px;
			margin-bottom: 8px;
		}

		select {
			margin-bottom: 8px;
		}

		.select-option {
			border: 1px solid #cfcfcf;
			padding: 16px;
			margin-top: 8px;
			position: relative;
		}

		.select-option > button.option-remove {
			position: absolute;
			right: 16px;
			top: 16px;
			width: 20px;
			height: 20px;
			border: none;
			background: none;
			padding: 0;
			cursor: pointer;

			img {
				width: 20px;
				height: 20px;
			}
		}

		.select-option > p {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			color: #3d3d3d;
			margin: 0px;
			line-height: 19px;
			width: 95%;
		}

		.select-option > .option-detail {
			display: flex;
			margin-top: 30px;
		}

		.select-option > .option-detail > div {
			flex: 1;
		}

		.select-option > .option-detail > div.count-box {
			button {
				width: 26px;
				height: 26px;
				border: 1px solid #dedede;
				background: #f5f5f5;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				vertical-align: middle;
				cursor: pointer;
			}

			input {
				width: 40px;
				height: 24px;
				border: 1px solid #dedede;
				font-family: ${(props) => props.theme.fontFamily.medium};
				font-size: 13px;
				line-height: 13px;
				color: #3d3d3d;
				border-left: 0;
				border-right: 0;
				padding: 0;
				vertical-align: middle;
				text-align: center;

				::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}

		.select-option > .option-detail > div.price {
			text-align: right;
			color: #3d3d3d;
			font-size: 18px;
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			line-height: 24px;

			span {
				font-family: ${(props) => props.theme.fontFamily.medium};
			}
		}
	}

	section.final {
		margin-top: 24px;

		div.total-price {
			display: flex;
		}

		div.total-price > small {
			flex: 1;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			color: #777777;
			display: flex;
			align-items: flex-end;
			margin-bottom: 3px;
		}

		div.total-price > b {
			flex: 1;
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			font-size: 22px;
			line-height: 32px;
			color: #e32222;
			text-align: right;
			font-weight: normal;

			span {
				font-family: ${(props) => props.theme.fontFamily.medium};
			}
		}
	}

	div.buttons {
		margin-top: 12px;
		display: flex;
		justify-content: space-between;
		position: relative;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: fixed;
			bottom: 0;
			width: 100%;
			display: flex;
			margin-top: 0px;
			left: 0;
		}

		button {
			flex-basis: calc(50% - 10px);
			width: 100%;
			border: 1px solid #777777;
			text-align: center;
			padding: 15px 0;
			background: none;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 14px;
			line-height: 20px;
			color: #777777;
			background: #ffffff;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				width: 100%;
				margin-left: 0px !important;
			}
		}

		button.action {
			background: #005a60;
			color: #ffffff;
			border: 1px solid #005a60;
		}
	}
`;
