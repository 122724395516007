/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";
import { v4 as UUIDV4 } from "uuid";
import cx from "classnames";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import { getSrcSet } from "../../UI/Assets";

import Sender from "./Video.Sender";

const SEND_CVS_ID = UUIDV4();
let _RENDERER = null;
let _VIDEO = null;
let _CCVS = null,
    _CCTX = null;
let _GCVS = null,
    _GCTX = null;
let _SCVS = null,
    _SCTX = null;
let _DCVS = null,
    _DCTX = null;

let _G_STROKE_WIDTH = isMobile ? 20 : 10;
let _G_STROKE_COLOR = "#CCCCCC";
let _GSIZE = null;
let _G_X_POS = 0;
let _G_M_POS = null;

export default (props) => {
    const { video, audio, resolution } = props;

    const videoRef = useRef(null);
    const copyRef = useRef(null);
    const guideRef = useRef(null);
    const scaleRef = useRef(null);
    const camRef = useRef(null);

    const [gsize, setGsize] = useState({ width: 608, height: 1080 });

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isCameraLoading, setIsCameraLoading] = useState(false);
    const [isCameraLoadingError, setIsCameraLoadingError] = useState(false);

    const hasError = () => {
        const ret = {
            error: !video || !audio || !resolution || isCameraLoading || isCameraLoadingError,
        };
        if (!video) {
            ret.message = "카메라를 선택해 주세요.";
        } else if (!audio) {
            ret.message = "마이크를 선택해 주세요.";
        } else if (!resolution) {
            ret.message = "해상도를 선택해 주세요.";
        } else if (isCameraLoading) {
            ret.message = "카메라 장치를 로드 중입니다.";
        } else if (isCameraLoadingError) {
            ret.message = "카메라 장치에서 응답이 없습니다.";
        }
        return ret;
    };
    const check = hasError();

    const loadCamera = () => {
        setIsCameraLoading(true);
        setIsCameraLoadingError(false);
        if (_RENDERER) {
            window.cancelAnimationFrame(_RENDERER);
        }

        getStream({ deviceId: video, resolution })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                setIsCameraLoading(false);
                setTimeout(() => {
                    const w = videoRef.current.videoWidth;
                    if (w === 0) {
                        setWidth(0);
                        setHeight(0);
                        setIsCameraLoadingError(true);
                    }
                }, 500);

                _RENDERER = window.requestAnimationFrame(onDraw);
            })
            .catch((e) => {
                setIsCameraLoading(false);
                const msg = e.message || e.name;
                switch (msg) {
                    case "Could not start video source":
                        swal({ title: "오류", text: "사용할 수 없는 카메라 입력입니다." });
                        break;
                    case "OverconstrainedError":
                        swal({
                            title: "오류",
                            text: `${resolution} 해상도를 지원하는 카메라를 사용해 주세요.\n\n* 지원하는 카메라라면 브라우저 버그등으로 늦게 뜰수 있습니다. \n* 여러차례 시도해 주세요.`,
                        });
                        break;
                    default:
                        swal({ title: "오류", text: msg });
                        break;
                }
            });
    };

    const onDraw = () => {
        try {
            if (isMobile) {
                _DCTX.clearRect(0, 0, _DCVS.width, _DCVS.height);
                _DCTX.drawImage(_VIDEO, 0, 0, _DCVS.width, _DCVS.height);
            } else {
                _CCTX.clearRect(0, 0, _CCVS.width, _CCVS.height);
                _CCTX.drawImage(_VIDEO, 0, 0, _CCVS.width, _CCVS.height);
                _GCTX.clearRect(0, 0, _GCVS.width, _GCVS.height);
                _GCTX.strokeRect(_G_X_POS < _G_STROKE_WIDTH / 2 ? _G_STROKE_WIDTH / 2 : _G_X_POS, _G_STROKE_WIDTH / 2, _GSIZE.width, _GSIZE.height - _G_STROKE_WIDTH);

                const frame = _CCTX.getImageData(_G_X_POS, 0, _GSIZE.width, _GSIZE.height);
                _SCTX.putImageData(frame, 0, 0);

                _DCTX.clearRect(0, 0, _DCVS.width, _DCVS.height);
                _DCTX.drawImage(_SCVS, 0, 0, _DCVS.width, _DCVS.height);
            }
        } catch (e) {
            console.log(e);
        }
        _RENDERER = window.requestAnimationFrame(onDraw);
    };
    const handleGMouseDown = (e) => {
        if (isMobile) {
            if (e.touches.length !== 1) {
                return;
            }
        } else {
            if (e.button !== 0) {
                return;
            }
        }

        _G_M_POS = getPosition(e, _GCVS);
    };
    const handleGMouseMove = (e) => {
        if (!_G_M_POS) {
            return;
        }
        const pos = getPosition(e, _GCVS);
        const loc = getLocation(pos, _GCVS);
        let x = loc.x - _GSIZE.width / 2;
        if (x < 0) {
            x = 0;
        }
        if (x + _GSIZE.width > _GCVS.width) {
            x = _GCVS.width - _GSIZE.width;
        }
        _G_X_POS = x;
    };
    const handleGMouseUp = (e) => {
        _G_M_POS = null;
    };

    const loadMetadata = () => {
        setWidth(videoRef.current.videoWidth);
        setHeight(videoRef.current.videoHeight);
    };

    useEffect(() => {
        videoRef.current.addEventListener("loadedmetadata", loadMetadata);

        if (!check.error || isCameraLoadingError) {
            loadCamera();
        }

        _VIDEO = videoRef.current;
        _CCVS = copyRef.current;
        _CCTX = _CCVS.getContext("2d");
        _GCVS = guideRef.current;
        _GCTX = _GCVS.getContext("2d");
        _SCVS = scaleRef.current;
        _SCTX = _SCVS.getContext("2d");
        _DCVS = camRef.current;
        _DCTX = _DCVS.getContext("2d");

        _GCTX.lineWidth = _G_STROKE_WIDTH;
        _GCTX.strokeStyle = _G_STROKE_COLOR;

        _GSIZE = getGuideSize(_GCVS);
        _G_X_POS = _GCVS.width / 2 - _GSIZE.width / 2;

        setGsize(_GSIZE);

        return () => {
            videoRef.current.removeEventListener("loadedmetadata", loadMetadata);

            if (_RENDERER) {
                window.cancelAnimationFrame(_RENDERER);
            }
            _VIDEO = null;
            _CCVS = null;
            _CCTX = null;
            _GCVS = null;
            _GCTX = null;
            _SCVS = null;
            _SCTX = null;
            _DCVS = null;
            _DCTX = null;
        };
    }, [video, audio, resolution]);

    return (
        <Container>
            <Wrapper>
                <SenderWrapper>
                    {check.error && (
                        <div className="message">
                            <img src="/images/cast_cam_info.png" srcSet={getSrcSet(["/images/cast_cam_info.png", "/images/cast_cam_info@2x.png", "/images/cast_cam_info@3x.png"])} />
                            <h4 className="text">{check.message}</h4>
                        </div>
                    )}
                    <canvas id={SEND_CVS_ID} ref={camRef} width="1080" height="1920" />
                    <div className="information">[ 방송에 송출되는 화면입니다 ]</div>
                    <Sender canvas={SEND_CVS_ID} audio={audio} />
                </SenderWrapper>
                <ComponentWrapper>
                    <div className="wrapper">
                        <h3 className="title">카메라</h3>
                        <video ref={videoRef} autoPlay muted playsInline />
                        <div className="resolution">
                            {width}x{height}
                        </div>
                    </div>
                    <div className={cx({ wrapper: true, hidden: isMobile })}>
                        <h3 className="title">앵글</h3>
                        <div className="canvas-wrapper">
                            <canvas ref={copyRef} width="1920" height="1080" />
                            <canvas
                                ref={guideRef}
                                width="1920"
                                height="1080"
                                onMouseDown={handleGMouseDown}
                                onMouseMove={handleGMouseMove}
                                onMouseUp={handleGMouseUp}
                                onTouchStart={handleGMouseDown}
                                onTouchMove={handleGMouseMove}
                                onTouchEnd={handleGMouseUp}
                                style={{ zIndex: 3 }}
                            />
                            <canvas ref={scaleRef} width={gsize?.width} height={gsize?.height} style={{ zIndex: 1, display: "none" }} />
                        </div>
                        <div className="info">* 좌우로 움직여 앵글을 조절하세요</div>
                    </div>
                </ComponentWrapper>
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    width: 680px;
    flex-basis: 680px;

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        flex-basis: none;
    }
`;
const Wrapper = styled.div`
    display: flex;

    @media only screen and (max-width: 768px) {
        display: block;
        width: 100%;
        height: 100%;
    }
`;
const SenderWrapper = styled.div`
    position: relative;
    width: 440px;
    height: 782px;
    flex-basis: 440px;

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        flex-basis: none;
    }

    canvas {
        width: 440px;
        height: 782px;

        @media only screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
        }
    }

    .message {
        position: absolute;
        left: 0;
        right: 0;
        top: 208px;
        z-index: 10;
        text-align: center;

        .text {
            font-size: 14px;
            color: #dedede;
            font-weight: normal;
        }
    }

    .information {
        position: absolute;
        top: 16px;
        left: 16px;
        right: 16px;
        text-align: center;
        font-size: 14px;
        text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
    }
`;
const ComponentWrapper = styled.div`
    flex: 1;
    background: #333333;
    border-left: #3b3b3b solid 1px;

    @media only screen and (max-width: 768px) {
        position: absolute;
        right: 0px;
        bottom: 71px;
        width: 150px;
        flex: none;
        background: rgba(36, 36, 36, 0.7);
        border-top-left-radius: 5px;
        overflow: hidden;
    }

    .hidden {
        display: none;
    }

    .wrapper {
        margin: 4px 10px 0;
        padding: 0 0 4px;
        border-bottom: #4a4a4a solid 1px;

        @media only screen and (max-width: 768px) {
            border-bottom: none;
        }

        :last-child {
            border-bottom: none;
        }

        .title {
            margin: 0 0 4px;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            color: #dedede;
        }

        video {
            background: #2d2d2d;
            width: 100%;
        }

        .canvas-wrapper {
            position: relative;
            background: #2d2d2d;
            width: 100%;
            padding-top: 56.25%;

            canvas {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        .resolution {
            text-align: right;
        }

        .info {
            margin-top: 5px;
            font-size: 12px;
        }
    }
`;

/**
 * Functions
 */

const getStream = ({ deviceId, resolution }) => {
    return new Promise((resolve, reject) => {
        try {
            const r = resolution.split("x");
            const constraints = {
                audio: false,
                video: {
                    deviceId,
                    width: { ideal: parseInt(r[0]) },
                    height: { ideal: parseInt(r[1]) },
                },
            };
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => resolve(stream))
                .catch((e) => reject(e));
        } catch (e) {
            reject(e);
        }
    });
};

const getGuideSize = (canvas) => {
    const { width, height } = canvas;
    return { width: Math.round((height * 9) / 16), height };
};

const getPosition = (e, cvs) => {
    if (!cvs) {
        return null;
    }
    const rect = cvs.getBoundingClientRect();
    if (isMobile) {
        const t = e.touches[0];
        return {
            x: (t.clientX - rect.left) / rect.width,
            y: (t.clientY - rect.top) / rect.height,
        };
    }
    return {
        x: (e.clientX - rect.left) / rect.width,
        y: (e.clientY - rect.top) / rect.height,
    };
};

const getLocation = (pos, cvs) => {
    return {
        x: pos.x * cvs.width,
        y: pos.y * cvs.height,
    };
};
