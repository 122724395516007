/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import Dialog from "../../UI/Dialog";

import { getBackgroundSet } from "../../UI/Assets";
import { _DEF } from "../../Modules/Config";
import { MypageTop } from "../../UI/Header";

import Menu from "./Mypage.Menu";

export default (props) => {
	const { location, history, match } = props;

	const [keyword, setKeyword] = useState("");
	const [list, setList] = useState([]);
	const [paging, setPaging] = useState(null);

	const loadList = (data) => {
		_API.get({ path: `/bbs`, data: { ...data, bbsCode: "FAQ" } }).then((res) => {
			const { list, paging } = res.data;
			setList(list);
			setPaging(paging);
		});
	};

	const movePage = (page) => {
		ToPage(location, history, { keyword, page });
		window.scrollTo(0, 0);
	};

	const onSearch = (e) => {
		ToPage(location, history, { keyword, page: 1 });
	};

	const onRefresh = () => {
		loadList({ keyword, page: paging.pageNo });
	};

	useEffect(() => {
		const params = GetParams(location);
		if (params) {
			setKeyword(params.keyword);
			loadList(params);
		} else {
			setKeyword("");
			loadList({ page: 1 });
		}
	}, [location]);

	if (!_CK.isLogin()) {
		history.goBack();
		return null;
	}
	return (
		<Layout {...props} hideTop={true}>
			<Container>
				<MypageTop {...props}>{isMobile ? `자주 하는 질문` : `마이페이지`}</MypageTop>
				<Wrapper>
					<Menu {...props} />
					<ContentWrapper>
						<BWrapper>
							<div className="top">
								<h2>자주 하는 질문</h2>
							</div>
							<List list={list} />
							{paging && (
								<div className="paging">
									<Paging paging={paging} pageCallback={movePage} />
								</div>
							)}
						</BWrapper>
					</ContentWrapper>
				</Wrapper>
			</Container>
		</Layout>
	);
};

const Wrapper = styled.div`
	display: flex;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-bottom: 40px;
	}
`;

const ContentWrapper = styled.div`
	margin-left: 30px;
	padding-top: 10px;
	flex: 1;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-left: 0;
		padding: 0 21px;
		width: calc(100% - 21px);
	}
`;

const BWrapper = styled.div`
	div.top {
		position: relative;
		display: flex;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-bottom: 1px solid #3d3d3d;
			margin-top: 24px;
			padding-bottom: 16px;
			display: none;
		}
	}

	div.top > h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
	}

	div.paging {
		margin-top: 20px;
	}
`;

const List = (props) => {
	const { list } = props;

	return (
		<LWrapper>
			{list?.map((item, index) => (
				<ListItem {...item} key={index} />
			))}
		</LWrapper>
	);
};

const LWrapper = styled.div`
	margin-top: 16px;
`;

const ListItem = (props) => {
	const [show, setShow] = useState(false);

	return (
		<LIWrapper>
			<dl className={cx({ show: show })} onClick={() => setShow(!show)}>
				<dt>
					[{_DEF.getDefinedLabel(_DEF.FAQ_CATEGORY, props.categoryCode)}] {props.title}
				</dt>
				<dd dangerouslySetInnerHTML={{ __html: props.content }}></dd>
			</dl>
		</LIWrapper>
	);
};

const LIWrapper = styled.div`
	dl {
		border: 1px solid #efefef;
		background: #fcfcfc;
		padding: 11px 0px;
		color: #9a9a9a;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		cursor: pointer;
		margin: 8px 0 0px;
	}

	dl > dt {
		background-image: url("/icons/ic_arrow_down_gray.png");
		background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_down_gray.png", "/icons/ic_arrow_down_gray@2x.png", "/icons/ic_arrow_down_gray@3x.png"])});
		background-repeat: no-repeat;
		background-size: 10px 6px;
		background-position: right 15px top 7px;
		padding: 0 20px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			background-position: right 10px top 7px;
		}
	}

	dl > dd {
		display: none;
	}

	dl.show {
		background: #ffffff;
		color: #3d3d3d;
	}

	dl.show > dt {
		background-image: url("/icons/ic_arrow_up_gray.png");
		background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_up_gray.png", "/icons/ic_arrow_up_gray@2x.png", "/icons/ic_arrow_up_gray@3x.png"])});
		border-bottom: 1px solid #dedede;
		padding-bottom: 12px;
		font-family: ${(props) => props.theme.fontFamily.medium};
	}
	dl.show > dd {
		display: block;
		margin: 16px 0 0;
		line-height: 17px;
		font-weight: normal;
		padding: 0 20px;

		* {
			max-width: 100% !important;
			width: auto !important;
		}
		b {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
		}

		p {
			font-family: ${(props) => props.theme.fontFamily.light};
			color: #777777;
			margin: 0;
			padding: 0;
		}
	}
`;
