/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

export default (props) => {
    const { channels, onChange, onClose, current } = props;
    const handleOutClicked = () => {
        onClose && onClose();
    };
    return (
        <Container onClick={handleOutClicked}>
            <Wrapper>
                <Title>
                    <h3 className="title">라이브 캠</h3>
                </Title>
                <CWrapper>
                    {channels.map((item, i) => (
                        <CItem key={i} onClick={onChange?.bind(this, item.streamId)} current={current === item.streamId}>
                            <img src={item.url} alt="" />
                            {current === item.streamId && <i className="fa fa-circle"></i>} CAM #{i + 1}
                        </CItem>
                    ))}
                </CWrapper>
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 30;

    overflow-x: hidden;
    overflow-y: auto;

    cursor: pointer;
`;
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    flex-direction: column;
`;
const Title = styled.div`
    .title {
        margin: 0;
        padding: 0;
        color: #ffffff;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 14px;
    }
`;

const CWrapper = styled.div`
    margin: 20px 0;
    padding: 0 0 16px;
    width: 100%;

    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(auto-fit, 290px); */
`;
const CItem = styled.div`
    width: 100%;
    display: block;
    padding: 0;
    color: ${(props) => (props.current ? `#e32222` : `#ffffff`)};
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 18px;
    text-align: center;
    cursor: pointer;

    img {
        margin-bottom: 8px;
        width: 100%;
        border-radius: 5px;
        display: block;
    }

    i.fa {
        margin-left: 10px;
    }
`;
