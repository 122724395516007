/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import { AppContext } from "../../Components/AppProvider";

import Menu from "./Mypage.Menu";
import { Input, Textarea, CheckBox } from "../../UI/Form";
import { MypageTop } from "../../UI/Header";
import Dialog from "../../UI/Dialog";
import { getSrcSet, getBackgroundSet } from "../../UI/Assets";

export default (props) => {
    const { iam, saveAuth, saveIam } = useContext(AppContext);
    const { location, history, match } = props;
    const { orderIdx, productIdx, asIdx } = match.params;
    const [data, setData] = useState({});
    const [fileList, setFileList] = useState([]);
    const [awsKey, setAwsKey] = useState(null);
    const fileRef = useRef(null);

    if (!_CK.isLogin()) {
        history.goBack();
        return null;
    }

    if (!orderIdx || !productIdx) {
        swal({ title: "알림", text: "주문정보가 없습니다." });
        goList();
    }

    const loadKeys = () => {
        _API.loadUploadKeys()
            .then((res) => res.data)
            .then((data) => {
                setAwsKey(data);
            });
    };

    const loadData = () => {
        if (!orderIdx || !productIdx) {
            swal({ title: "알림", text: "주문정보가 없습니다." });
            return;
        }
        _API.get({ path: `/user/mypage/as/info`, data: { asIdx, orderIdx, productIdx } })
            .then((res) => {
                const data = res.data;
                setData(data);
                if (data.fileList && data.fileList.length > 0) setFileList(data.fileList);
            })
            .catch((e) => {
                goList();
            });
    };

    const onAddrOpen = () => {
        new daum.Postcode({
            oncomplete: (result) => {
                const address = {
                    recipientZipcode: result.zonecode,
                    recipientAddr: result.address,
                    recipientAddrEng: result.addressEnglish,
                    recipientAddrDtl: "",
                };
                setData({ ...data, ...address });
            },
        }).open();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        _API.upload({
            aws: awsKey,
            file,
            path: `REQUEST/AS/${moment().format("YYYY/MM/DD")}`,
        })
            .then((res) => res.data)
            .then((result) => {
                console.log("file", result);
                setFileList((o) => [...o, { signedUrl: result.signedUrl, fileIdx: result.id, name: result.name }]);
            });
    };

    const goList = () => {
        if (history) {
            history.push("/user/my/request/as");
        } else {
            window.location.href = "/";
        }
    };

    const onRequest = () => {
        if (!saveAvail({ ...data, fileList })) {
            return;
        }
        Dialog({
            title: ``,
            body: <Notice data={{ ...data, fileList }} />,
            width: 582,
            padding: "0",
            backgroundColor: "#e7e7e7",
            closeBtn: "WHITE",
        })
            .then((data) => {
                goList();
            })
            .catch((e) => {
                console.log("e", e);
            });
    };

    useEffect(() => {
        loadKeys();
        loadData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout {...props} hideTop={true}>
            <Container>
                <MypageTop {...props}>{isMobile ? `A/S신청` : `마이페이지`}</MypageTop>
                <Wrapper>
                    <Menu {...props} />
                    <ContentWrapper>
                        <DWrapper>
                            <div className="top">
                                <h2>A/S 신청</h2>
                            </div>
                            <div className="box">
                                <div className="form">
                                    <h3>모아 A/S신청</h3>
                                    <table>
                                        <tr>
                                            <th>주문번호</th>
                                            <td>{data?.orderNo}</td>
                                        </tr>
                                        <tr>
                                            <th>상품명</th>
                                            <td>{data?.productName}</td>
                                        </tr>
                                        <tr>
                                            <th>구매자명</th>
                                            <td>
                                                <Input
                                                    value={data?.clientName}
                                                    onChange={(e) => setData({ ...data, clientName: e.target.value })}
                                                    styles={{ color: "#3d3d3d" }}
                                                    styles={{ width: isMobile ? "calc(100% - 20px)" : "100px", color: "#3d3d3d" }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>연락처</th>
                                            <td>
                                                <Input
                                                    value={data?.clientMobile}
                                                    onChange={(e) => setData({ ...data, clientMobile: e.target.value })}
                                                    styles={{ width: isMobile ? "calc(100% - 20px)" : "200px", color: "#3d3d3d" }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>A/S신청사항</th>
                                            <td>
                                                <Textarea onChange={(e) => setData({ ...data, asDesc: e.target.value })} value={data?.asDesc} rows={10} styles={{ color: "#3d3d3d" }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>A/S 완료 후 받아보실 주소</th>
                                            <td>
                                                <div className="zipcode-box">
                                                    <button onClick={onAddrOpen} className="search-addr-btn">
                                                        주소찾기
                                                    </button>
                                                    <Input
                                                        placeholder="우편번호"
                                                        type="text"
                                                        value={data?.recipientZipcode}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        name="recipientZipcode"
                                                        className="form-control form-control-lg"
                                                        styles={{ width: "100px", color: "#3d3d3d" }}
                                                        readOnly
                                                        onClick={onAddrOpen}
                                                    />
                                                </div>
                                                <div>
                                                    <Input
                                                        placeholder="주소를 입력하세요."
                                                        type="text"
                                                        value={data?.recipientAddr}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        name="recipientAddr"
                                                        className="form-control form-control-lg"
                                                        styles={{ width: "calc(100% - 20px)", color: "#3d3d3d" }}
                                                    />
                                                </div>
                                                <div>
                                                    <Input
                                                        placeholder="상세 주소를 입력하세요."
                                                        type="text"
                                                        value={data?.recipientAddrDtl}
                                                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                                                        name="recipientAddrDtl"
                                                        className="form-control form-control-lg"
                                                        styles={{ width: "calc(100% - 20px)", color: "#3d3d3d" }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비용</th>
                                            <td>{data?.costView}</td>
                                        </tr>
                                        <tr>
                                            <th>보낼실 주소</th>
                                            <td>{"서울 강남구 논현로 645, 8층 모아"}</td>
                                        </tr>
                                        <tr>
                                            <th colspan={isMobile ? 2 : 1}>사진첨부</th>
                                            {!isMobile && (
                                                <td>
                                                    <input ref={fileRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
                                                    <div className="img-list-box">
                                                        {fileList?.map((item, i) => (
                                                            <div>
                                                                <img src={item.signedUrl} key={i} />
                                                            </div>
                                                        ))}
                                                        <div className="btn-add" onClick={() => fileRef.current.click()}>
                                                            <img src="/images/as_attach.png" srcSet={getSrcSet(["/images/as_attach.png", "/images/as_attach@2x.png", "/images/as_attach@3x.png"])} />
                                                            <p>사진추가</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                        {isMobile && (
                                            <tr>
                                                <td colspan="2" className="mobile-img-td">
                                                    <input ref={fileRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
                                                    <div className="img-list-box">
                                                        {fileList?.map((item, i) => (
                                                            <div>
                                                                <img src={item.signedUrl} key={i} />
                                                            </div>
                                                        ))}
                                                        <div className="btn-add" onClick={() => fileRef.current.click()}>
                                                            <img src="/images/as_attach.png" srcSet={getSrcSet(["/images/as_attach.png", "/images/as_attach@2x.png", "/images/as_attach@3x.png"])} />
                                                            <p>사진추가</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </table>

                                    <div className="buttons">
                                        <button onClick={() => goList()}>취소</button>
                                        <button className="action" onClick={onRequest}>
                                            신청하기
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </DWrapper>
                    </ContentWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding-bottom: 40px;
    }
`;

const ContentWrapper = styled.div`
    margin-left: 30px;
    padding-top: 10px;
    flex: 1;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-left: 0;
        padding: 0 21px;
    }
`;

const DWrapper = styled.div`
    div.top {
        position: relative;
        display: flex;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: 1px solid #3d3d3d;
            margin-top: 24px;
            padding-bottom: 16px;
            display: none;
        }
    }

    div.top > h2 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
    }

    div.box {
        margin-top: 16px;

        h3 {
            padding: 20px 0;
            margin: 0;
            background: #00788d;
            color: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.bold};
            font-size: 38px;
            line-height: 81.7px;
            text-align: center;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                padding: 0;
                font-size: 30px;
            }
        }
    }

    div.box > div.desc-box {
        border: 1px solid #efefef;
        background: #fcfcfc;
        padding: 11px 20px;
        color: #9a9a9a;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 10px;

        p {
            font-weight: normal;
            margin: 0 0 10px;
            color: #3d3d3d;
        }
    }

    div.box > div.text-right {
        text-align: right;
    }

    table {
        border-spacing: 0;
        width: 100%;
        border: 1px solid #efefef;
        border-top: 0;
        padding: 30px 0;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border: 0;
        }
    }

    table > tr {
        color: #777777;
    }

    table > tr:first-child > th,
    table > tr:first-child > td {
    }

    table > tr > th {
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 15px;
        line-height: 20px;
        /* padding: 9px 0 9px 16px; */
        text-align: left;
        width: 20%;
        position: relative;
        color: #3d3d3d;
        padding: 0 2% 0 8%;

        :before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background: #00788d;
            left: 50px;
            margin-top: 4px;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 30%;
            padding: 0 0 0 20px;

            :before {
                content: "";
                left: 0px;
            }
        }
    }

    table > tr > td.mobile-img-td {
        padding: 15px 0 0 0;
    }

    table > tr > td {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        padding: 6px 20px 6px 20px;
        color: #3d3d3d;
        height: 39px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding-left: 10px;
        }
        P {
            padding: 0;
            margin: 0;
        }

        textarea {
            color: #3d3d3d;
        }

        > div {
            padding: 6px 0 0;
        }

        > div.zipcode-box {
            display: flex;

            > button {
                flex-basis: 100px;
                border: 1px solid #777777;
                color: #777777;
                font-family: ${(props) => props.theme.fontFamily.regular};
                background: #ffffff;
                margin-right: 6px;
            }

            > div {
                flex: 1;
            }
        }

        > div.img-list-box {
            display: inline-flex;
            flex-wrap: wrap;
            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                width: 100%;
            }
            > div {
                width: 150px;
                height: 134px;
                background: #e3e3e3;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 14px 14px 0;

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    width: auto;
                    height: 98px;
                    flex-basis: 33%;
                    margin: 0 0.3% 14px 0;
                }
            }

            > div.btn-add {
                color: #aeaeae;
                font-family: ${(props) => props.theme.fontFamily.medium};
                font-size: 18px;
                line-height: 30px;
                margin-right: 0;
                cursor: pointer;
                img {
                    width: 36px;
                    height: 36px;
                }

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    font-size: 14px;
                }
            }

            > div > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    div.buttons {
        margin-top: 30px;
        text-align: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        button {
            border: 1px solid #00788d;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #00788d;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                width: 50%;
                :last-child {
                    margin-left: 0px;
                }
            }
        }

        button.action {
            border: 1px solid #00788d;
            background: #00788d;
            color: #ffffff;
        }
    }

    /* div.button {
		text-align: center;
		margin-top: 20px;
	}

	div.button > button {
		font-family: ${(props) => props.theme.fontFamily.regular};
		border: 1px solid #e32222;
		background: #e32222;
		color: #ffffff;
		font-size: 14px;
		line-height: 20px;

		padding: 15px 0;
		width: 220px;
	} */

    h3 {
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #3d3d3d;
        font-size: 14px;
        line-height: 20px;
    }
`;

const Notice = (props) => {
    const { resolve, reject, data } = props;
    const [agree, setAgree] = useState(false);

    const onSave = () => {
        if (!agree) {
            swal({ title: "알림", text: "유의사항을 확인해 주세요." });
            return;
        }

        if (!saveAvail(data)) {
            reject();
        }

        _API.post({ path: "/user/mypage/request/as", data: { ...data } }).then((res) => {
            swal({ title: "알림", text: "A/S 요청이 접수 되었습니다." }).then((data) => {
                resolve();
            });
        });
    };

    return (
        <FWrapper>
            <h2>
                <div>
                    <img src="/images/as_warning.png" srcSet={getSrcSet(["/images/as_warning.png", "/images/as_warning@2x.png", "/images/as_warning@3x.png"])} />
                </div>
                A/S 신청 시 유의사항
            </h2>
            <div className="contents">
                <p className="item-1">
                    모든 A/S 신청 상품은 선불로 <b>직접 모아로 발송</b> 해주셔야 합니다.
                </p>
                <p className="item-2">
                    A/S 비용은 무상 기간내의 경우는 수선 완료 후 발송 비용만 부담하시면 되며, 유상 기간의 경우는 수선 비용이 확정되면 수선 비용과 발송 비용을 더하여 안내드립니다.
                    <br />
                    (※ 명백한 소비자 과실에 의한 A/S는 무상 A/S 기간에도 유상처리 됩니다. 또한 무상 A/S 기간에도 손상 정도에 따라 유상으로 진행 될 수 있으며, 극심한 손상의 경우 구매 일자에 상관없이
                    A/S불가로 판명 될 수 있습니다. )
                </p>
                <p className="item-3">제품의 손상으로 인한 A/S 신청이 아닌, 제품의 본질을 훼손하는 개인의 취향에 따른 미용 목적의 신청은 유·무상 A/S 모두 불가합니다.</p>
                <CheckBox title="확인했습니다." value="Y" checked={agree} onClick={() => setAgree(!agree)} inline />
            </div>

            <div className="buttons">
                <button onClick={() => reject()}>취소</button>
                <button className="action" onClick={onSave}>
                    신청하기
                </button>
            </div>
        </FWrapper>
    );
};

const FWrapper = styled.div`
    padding-bottom: 90px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        > div {
            flex-direction: column;
        }
        > div > .uif-label {
            padding: 14px 0 0px !important;
        }
        > div > .uif-form {
            padding-top: 8px;

            textarea {
                width: calc(100% - 22px);
            }
        }
    }

    h2 {
        background: #00788d;
        color: #ffffff;
        font-family: ${(props) => props.theme.fontFamily.bold};
        font-size: 38px;
        line-height: 66.5px;
        text-align: center;
        padding: 30px 0 20px 0;
        margin: 0;

        > div {
            height: 45px;
            line-height: 0;
            margin-bottom: 8px;
        }

        > div > img {
            height: 45px;
            width: 47px;
        }
    }

    button.btn-attach {
        background: #ffffff;
        border: 1px solid #e32222;
        padding: 4px 15px;
        color: #e32222;
        font-family: ${(props) => props.theme.fontFamily.regular};
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        z-index: 2;
    }

    div.contents {
        padding: 30px 30px;

        /* p > img {
			vertical-align: middle;
			margin-right: 10px;
		} */

        p {
            color: #404040;
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 15px;
            line-height: 30px;
            padding: 0 0 16px 32px;
            margin: 0;

            > b {
                font-family: ${(props) => props.theme.fontFamily.bold};
            }
        }

        p.item-1 {
            background-image: url("/images/as_num1.png");
            background-image: -webkit-image-set(${getBackgroundSet(["/images/as_num1.png", "/images/as_num1@2x.png", "/images/as_num1@3x.png"])});
            background-repeat: no-repeat;
            background-size: 22px 22px;
            background-position: left top 5px;
        }

        p.item-2 {
            background-image: url("/images/as_num2.png");
            background-image: -webkit-image-set(${getBackgroundSet(["/images/as_num2.png", "/images/as_num2@2x.png", "/images/as_num2@3x.png"])});
            background-repeat: no-repeat;
            background-size: 22px 22px;
            background-position: left top 5px;
        }

        p.item-3 {
            background-image: url("/images/as_num3.png");
            background-image: -webkit-image-set(${getBackgroundSet(["/images/as_num3.png", "/images/as_num3@2x.png", "/images/as_num3@3x.png"])});
            background-repeat: no-repeat;
            background-size: 22px 22px;
            background-position: left top 5px;
        }

        > div {
            margin-top: 24px;
            border-top: 1px solid #616161;
            width: 100%;
            text-align: right;
            padding-top: 12px;
            color: #404040;
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 14px;
        }
    }

    span.file-attach {
        margin-left: 30px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-left: 0px;
            display: block;
            margin-top: 8px;
        }
        img {
            vertical-align: middle;
            margin-left: 8px;
            width: 10px;
            height: 10px;
            cursor: pointer;
        }

        a {
            color: #e32222;
        }
    }

    > div.buttons {
        margin-top: 30px;
        text-align: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        button {
            border: 1px solid #00788d;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #00788d;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                width: 50%;
                :last-child {
                    margin-left: 0px;
                }
            }
        }

        button.action {
            border: 1px solid #00788d;
            background: #00788d;
            color: #ffffff;
        }
    }
`;

const saveAvail = (data) => {
    if (!data) return false;
    console.log("data", data);
    if (!data?.orderIdx) {
        swal({ title: "알림", text: "주문정보가 없습니다." });
        return false;
    }
    if (!data.productIdx) {
        swal({ title: "알림", text: "상품정보가 없습니다." });
        return false;
    }
    if (!data.clientName) {
        swal({ title: "알림", text: "구매자명을 입력해 주세요." });
        return false;
    }
    if (!data.clientMobile) {
        swal({ title: "알림", text: "구매자명 연락처를 입력해 주세요." });
        return false;
    }
    if (!data.recipientZipcode || !data.recipientAddr || !data.recipientAddrDtl) {
        swal({ title: "알림", text: "수신자 주소를 입력해 주세요." });
        return false;
    }
    if (!data.asDesc) {
        swal({ title: "알림", text: "AS요청사항을 입력해 주세요." });
        return false;
    }
    if (!data.fileList || data.fileList.length === 0) {
        swal({ title: "알림", text: "첨부사진을 등록해 주세요." });
        return false;
    }
    return true;
};
