/* eslint-disable */
import { useState, useEffect, useCallback } from "react";
import { _API, _U } from "../../Modules/Utils";

function useFetch(query, page, keywordList) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [list, setList] = useState([]);

	const sendQuery = useCallback(async () => {
		try {
			await setLoading(true);
			await setError(false);

			_API
				.get({ path: "/product/search/item/review", data: { sort: "photo", numPerPage: 11, page, keywordList } })
				.then((res) => res.data)
				.then(async (data) => {
					const { paging, list } = data;

					if (page === 1) {
						await setList(list);
					} else {
						if (page <= paging.endPageNo) {
							await setList((prev) => [...prev, ...list]);
						}
					}

					setLoading(false);
				});
		} catch (err) {
			setError(err);
		}
	}, [query, page, keywordList]);

	useEffect(() => {
		sendQuery(query);
	}, [query, sendQuery, page]);

	return { loading, error, list };
}

export default useFetch;
