/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import { v4 as UUIDV4 } from "uuid";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";
import { tryToPlay } from "../../Components/AntMedia/fetch.stream.js";

const _VIDEO_ID = UUIDV4();

export default (props) => {
    const { id } = props;

    const videoRef = useRef(null);
    const [adapter, setAdapter] = useState(null);

    const play = async () => {
        try {
            if (adapter) {
                await stopWebRTCStreaming(adapter, id);
            }
            startWebRTCStreaming(id).then((adapter) => {
                setStreamId(id);
                setAdapter(adapter);
            });
        } catch (e) {
            swal({ title: "오류", text: e.message || e.name || e.data });
        }
    };

    useEffect(() => {
        play();
        return () => {
            if (adapter) {
                stopWebRTCStreaming(adapter, streamId);
            }
        };
    }, []);

    return <Video ref={videoRef} id={_VIDEO_ID} autoPlay muted controls playsInline />;
};

const Video = styled.video`
    width: 100%;
    height: 100%;
    background: #000000;
`;

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (streamId, token) => {
    console.log(`startWebRTCStreaming`, streamId, token);
    return new Promise((resolve, reject) => {
        try {
            const adapter = new WebRTCAdaptor({
                websocket_url: _CFG.getStreamUrl(),
                mediaConstraints: { video: false, audio: false },
                peerconnection_config: null,
                sdp_constraints: {
                    OfferToReceiveAudio: false,
                    OfferToReceiveVideo: false,
                },
                remoteVideoId: _VIDEO_ID,
                isPlayMode: true,
                debug: true,
                callback: (info, description) => {
                    switch (info) {
                        case "initialized":
                            adapter.getStreamInfo(streamId);
                            break;
                        case "streamInformation":
                            adapter.play(streamId);
                            break;
                        case "play_started":
                            _U.postMessage("PLAY-STARTED");
                            break;
                        case "play_finished":
                            _U.postMessage("PLAY-ENDED");
                            break;
                        case "closed":
                            if (typeof description != "undefined") {
                                console.log("Connecton closed: " + JSON.stringify(description));
                            }
                            break;
                        case "bitrateMeasurement":
                            break;
                    }
                },
                callbackError: (err) => {
                    _U.postMessage("STREAMING-ERROR", err);
                    switch (err) {
                        case "no_stream_exist":
                            break;
                        case "notSetRemoteDescription":
                            tryToPlay(streamId, token, hlsExtension);
                            break;
                        case "already_playing":
                            break;
                        default:
                            _U.postMessage("PLAY-ERROR", err);
                            break;
                    }
                },
            });
            resolve(adapter);
        } catch (e) {
            reject(e);
        }
    });
};

const stopWebRTCStreaming = (adapter, streamId) => {
    adapter.stop(streamId);
};
