export const getLabel = (list, value) => {
    if (!list || list.length === 0 || !value) return "";

    const f = list.filter((l) => l.value === value);
    if (f.length === 0) {
        return "";
    }
    return f[0].label;
};

export const STATUS = [
    { label: "사용", value: "Y" },
    { label: "사용안함", value: "N" },
];

export const PRODUCT_COST_MIN = [
    { label: "최저가", value: "" },
    { label: "10 만원", value: "10" },
    { label: "20 만원", value: "20" },
    { label: "30 만원", value: "30" },
    { label: "50 만원", value: "50" },
    { label: "70 만원", value: "70" },
    { label: "100 만원", value: "100" },
    { label: "150 만원", value: "150" },
    { label: "200 만원", value: "200" },
    { label: "300 만원", value: "300" },
    { label: "400 만원", value: "400" },
    { label: "500 만원", value: "500" },
];

export const PRODUCT_COST_MAX = [
    { label: "최고가", value: "" },
    { label: "10 만원", value: "10" },
    { label: "20 만원", value: "20" },
    { label: "30 만원", value: "30" },
    { label: "50 만원", value: "50" },
    { label: "70 만원", value: "70" },
    { label: "100 만원", value: "100" },
    { label: "150 만원", value: "150" },
    { label: "200 만원", value: "200" },
    { label: "300 만원", value: "300" },
    { label: "400 만원", value: "400" },
    { label: "500 만원", value: "500" },
    { label: "1,000 만원", value: "1000" },
    { label: "2,000 만원", value: "2000" },
    { label: "3,000 만원", value: "3000" },
];

/*
export const PRODUCT_DISCOUNT = [
    { label: "30% 이하 세일", value: "UNDER30" },
    { label: "30%~50%세일", value: "30TO50" },
    { label: "50%~70%세일", value: "50TO70" },
    { label: "70% 이상 세일", value: "OVER70" },
];
*/

export const PRODUCT_DISCOUNT = [
    { label: "3,000원 이하", value: "UNDER3000" },
    { label: "3,000 ~ 5,000", value: "3000TO5000" },
    { label: "5,000 ~ 10,000", value: "5000TO10000" },
    { label: "10,000 ~ 30,000", value: "10000TO30000" },
    { label: "30,000원 이상", value: "OVER30000" },
];

export const PRODUCT_SHIPPING = [
    { label: "익일배송", value: "TOMORROW" },
    { label: "순차배송", value: "TURN" },
    { label: "5일배송", value: "5DAYS" },
    { label: "해외배송", value: "OVERSEAS" },
];

export const PAYMENT_METHOD = [
    { value: "creditcard", label: "신용카드" },
    { value: "banktransfer", label: "인터넷뱅킹(금융결제원)" },
    { value: "virtualaccount", label: "가상계좌" },
    { value: "mobile", label: "휴대폰" },
    { value: "kakaopay", label: "카카오페이" },
    { value: "naverpay", label: "네이버페이" },
];

export const PAYMENT_METHOD_KCP = [
    { value: "creditcard", label: "신용카드" },
    { value: "banktransfer", label: "인터넷뱅킹(금융결제원)" },
    { value: "virtualaccount", label: "가상계좌" },
    { value: "mobile", label: "휴대폰" },
    { value: "kakaopay", label: "카카오페이" },
    { value: "naverpay", label: "네이버페이" },
];

export const SHIPPING_STATUS = [
    { value: "W", label: "상품 준비중" },
    { value: "I", label: "배송중" },
    { value: "Y", label: "배송완료" },
    { value: "C", label: "배송취소" },
];

export const SHIPPING_METHOD = [
    { label: "무료배송", value: "FREE" },
    { label: "유료배송", value: "PAID" },
];

export const FAQ_CATEGORY = [
    { label: "배송문의", value: "SHIPPING" },
    { label: "반품/교환/환불", value: "REFUND" },
    { label: "주문/결제", value: "PAYMENT" },
    { label: "회원서비스", value: "USER" },
    { value: "ETC", label: "기타" },
];

export const PRODUCTQNA_CATEGORY = [
    { label: "재고", value: "STOCK" },
    { label: "사이즈", value: "SIZE" },
    { label: "색상", value: "COLOR" },
    { label: "배송", value: "SHIPPING" },
    { label: "기타", value: "ETC" },
];

export const DELIVERY_REQUEST = [
    { label: "배송시 요청사항 선택하기", value: "DR" },
    { label: "직접 수령합니다", value: "D" },
    { label: "문 앞에 놓아주세요", value: "DF" },
    { label: "경비실에 맡겨주세요", value: "S" },
    { label: "배송 전 휴대폰으로 연락주세요", value: "P" },
    { label: "파손위험이 있는 상품이니 조심히 다뤄주세요", value: "C" },
    { label: "직접입력", value: "" },
];

export const ORDER_STATUS = [
    { value: "PAY_WAIT", label: "입금대기" },
    { value: "PAY_STOP", label: "결제중단" },
    { value: "PAY_FAIL", label: "결제실패" },
    { value: "PAY_COMPLETE", label: "결제완료" },
    { value: "PRODUCT_WAIT", label: "상품준비중" },
    { value: "SHIPPING_ING_OUT", label: "배송중(해외)" },
    { value: "SHIPPING_ING", label: "배송중(국내)" },
    { value: "SHIPPING_COMPLETE", label: "배송완료" },
    { value: "EXCHANGE_REQUEST", label: "교환접수" },
    { value: "EXCHANGE_WAIT", label: "교환상품준비중" },
    { value: "EXCHANGE_PENDING", label: "교환보류" },
    { value: "EXCHANGE_COMPLETE", label: "교환완료" },
    { value: "RETURN_REQUEST", label: "반품접수" },
    { value: "RETURN_PENDING", label: "반품보류" },
    { value: "RETURN_COMPLETE", label: "반품완료" },
    { value: "REFUND_REQUEST", label: "환불진행중" },
    { value: "REFUND_COMPLETE", label: "환불완료" },
    { value: "ORDER_CANCEL", label: "주문취소완료" },
    { value: "PAY_CANCEL", label: "결제취소" },
    { value: "ORDER_PART", label: "주문부분완료" },
    { value: "ORDER_PART_CANCEL", label: "주문부분취소" },
    { value: "ORDER_COMPLETE", label: "주문확정" },
];

export const BANK_LIST = [
    { value: "국민", label: "국민" },
    { value: "기업", label: "기업" },
    { value: "농협", label: "농협" },
    { value: "농협중앙", label: "농협중앙" },
    { value: "대구", label: "대구" },
    { value: "카카오뱅크", label: "카카오뱅크" },
    { value: "부산", label: "부산" },
    { value: "산림조합", label: "산림조합" },
    { value: "산업", label: "산업" },
    { value: "상호저축", label: "상호저축" },
    { value: "새마을", label: "새마을" },
    { value: "수협", label: "수협" },
    { value: "경남", label: "경남" },
    { value: "광주", label: "광주" },
    { value: "케이뱅크", label: "케이뱅크" },
    { value: "신안상호저축은행", label: "신안상호저축은행" },
    { value: "신한", label: "신한" },
    { value: "신협", label: "신협" },
    { value: "씨티", label: "씨티" },
    { value: "우리", label: "우리" },
    { value: "우체국", label: "우체국" },
    { value: "전북", label: "전북" },
    { value: "제주", label: "제주" },
    { value: "HSBC", label: "HSBC" },
    { value: "JP모간", label: "JP모간" },
    { value: "도이치", label: "도이치" },
    { value: "KEB 하나", label: "KEB 하나" },
    { value: "한국스탠다드차타드", label: "한국스탠다드차타드" },
];

export const SHIPPING_COMPANY = [
    { value: "04", label: "CJ 대한통운" },
    { value: "06", label: "로젠택배" },
    { value: "01", label: "우체국" },
];

export const AS_STATUS = [
    { value: "W", label: "신청완료" },
    { value: "P", label: "모아 검수중" },
    { value: "I", label: "AS 중" },
    { value: "S", label: "AS 완료 후 배송중" },
    { value: "Y", label: "AS후 배송완료" },
];

export const RETURN_STATUS = [
    { value: "W", label: "신청완료" },
    { value: "S", label: "택배사 수거완료" },
    { value: "P", label: "모아 검수중" },
    { value: "Y", label: "반품완료" },
];

export const RETURN_REASON_CODE = [
    { value: "SIZE", label: "사이즈 미스" },
    { value: "POOR", label: "상품불량" },
    { value: "OTHER", label: "생각한상품아님" },
    { value: "ETC", label: "기타(직접입력)" },
];

export const RETURN_SHIPPING_TYPE = [
    { value: "REQUEST", label: "수거요청" },
    { value: "DIRECT", label: "직접배송" },
];

export const STREAM_STATUS = [
    { value: "W", label: "대기중" },
    { value: "R", label: "승인 요청" },
    { value: "N", label: "승인 반려" },
    { value: "Y", label: "승인" },
];

export const REVIEW_REPORT_CATEGORY = [
    { value: "RELATION", label: "상품 품질과 관련 없는 이미지/내용" },
    { value: "ABUSE", label: "음란,욕설,비방 내용" },
    { value: "COPYRIGHT", label: "저작권 불법 도용(사진, 타인 작성 글 등)" },
    { value: "PR", label: "상업적/개인적 광고, 홍보성 내용" },
    { value: "PRIVACY", label: "개인 정보 유출 위험" },
    { value: "PAPERING", label: "게시글 도배" },
    { value: "ETC", label: "기타" },
];

export const DUP_USE_YN = [
    { label: "사용가능", value: "Y" },
    { label: "사용불가", value: "N" },
];

export const getDefinedLabel = (arr, value) => {
    if (arr && value) {
        for (let i in arr) {
            if (value === arr[i].value) {
                return arr[i].label;
            }
        }
    }
    return "";
};

export const getDefinedValue = (arr, label) => {
    if (arr && label) {
        for (let i in arr) {
            if (label === arr[i].label) {
                return arr[i].value;
            }
        }
    }
    return "";
};
