/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import cx from "classnames";

import { _API, _U } from "../Modules/Utils";
import { getSrcSet } from "../UI/Assets";

import { BrandView } from "./Brand";

let menuTimer = null;

export default (props) => {
    const { history, location, match } = props;

    const [menu, setMenu] = useState([]);
    const [isShowHover, setIsShowHover] = useState(false);
    const [hoverType, setHoverType] = useState(null);
    const [hoverCategory, setHoverCategory] = useState(null);
    const [hoverCategoryTitle, setHoverCategoryTitle] = useState(null);
    const cp = { history, location, match };

    const load = () => {
        _API.get({ path: "/menu" })
            .then((res) => res.data)
            .then((list) => setMenu(list));
    };

    const handleMouseOver = (item, e) => {
        let isShow = false;
        switch (item.type) {
            case "CATEGORY":
                if (item.categoryIdx) {
                    isShow = true;
                    setHoverType(item.type);
                    setHoverCategory(item.categoryIdx);
                    setHoverCategoryTitle(item.categoryTitle);
                } else {
                    isShow = false;
                    setHoverType(null);
                    setHoverCategory(null);
                    setHoverCategoryTitle(null);
                }
                break;
            case "CATEGORY_VIEW":
                if (item.categoryViewIdx) {
                    isShow = true;
                    setHoverType(item.type);
                    setHoverCategory(item.categoryViewIdx);
                    setHoverCategoryTitle(item.categoryViewTitle);
                } else {
                    isShow = false;
                    setHoverType(null);
                    setHoverCategory(null);
                    setHoverCategoryTitle(null);
                }
                break;
            case "BRAND":
                isShow = true;
                setHoverType(item.type);
                setHoverCategory(null);
                setHoverCategoryTitle(null);
                break;
        }

        setIsShowHover(isShow);
    };

    const handleMouseOut = () => {
        menuTimer = setTimeout(() => {
            setIsShowHover(false);
        }, 250);
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "HOVER-MENU-HIDE":
                    setIsShowHover(false);
                    break;
            }
        });
    };

    const hideHover = () => {
        clearTimeout(menuTimer);
        menuTimer = null;
        setIsShowHover(false);
    };

    const handleScroll = () => {
        hideHover();
    };

    useEffect(() => {
        load();
        window.addEventListener("message", onReceivedMessage);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("message", onReceivedMessage);
            window.removeEventListener("scroll", handleScroll);

            if (menuTimer) {
                clearTimeout(menuTimer);
                menuTimer = null;
            }
        };
    }, []);

    return (
        <Container showMenu={props.showMenu}>
            <Wrapper>
                <MenuWrapper>
                    <Menu>
                        {menu.map((item, i) => (
                            <MenuItem key={i} data={item} nowUrl={match.url} onMouseOver={handleMouseOver.bind(this, item)} />
                        ))}
                    </Menu>
                </MenuWrapper>
                {isShowHover && <HoverMenu type={hoverType} category={hoverCategory} title={hoverCategoryTitle} onMouseOut={handleMouseOut} onHide={hideHover} onClickClose={hideHover} {...cp} />}
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    position: -webkit-sticky;
    position: sticky;
    /* top: 0px; */
    top: 48px;
    background: #ffffff;
    z-index: 10;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        ${(props) => (!props.showMenu ? `display: none;` : ``)}
    }
`;
const Wrapper = styled.div`
    margin-bottom: 30px;
    border-bottom: #efefef solid 1px;
`;
const MenuWrapper = styled.div`
    margin: 0 auto;
    width: ${(props) => props.theme.layout.width + 36}px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Menu = styled.ul`
    margin: 4px 0 20px;
    padding: 20px 20px 0 0px;
    list-style: none;
    margin-bottom: 0;

    li {
        padding: 0 20px 20px;
        display: inline-block;
    }

    li.active {
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: #00788d solid 2px;
            padding-bottom: 20px;
        }
    }

    @media only screen and (min-width: ${(props) => props.theme.layout.mobileWith}px) {
        li:hover {
            padding-bottom: 18px;
            border-bottom: #00788d solid 2px;
        }

        li.split:hover {
            border-bottom: 0;
        }
    }
    li:first-child {
        padding-left: 20px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding-left: 20px;
        }
    }
    li.split {
        padding: 0 10px;
        font-size: 13px;
        color: #777777;
    }
`;

const A = styled.a`
    position: relative;
    display: block;
    color: ${(props) => (props.color ? props.color : "#3d3d3d")};
    font-size: 14px;
    text-decoration: none;
    font-family: ${(props) => props.theme.fontFamily.medium};
    :hover,
    :visited,
    :active {
        text-decoration: none;
    }
    sup {
        position: absolute;
        top: -5px;
        right: -10px;
        color: ${(props) => (props.dot ? props.dot : "#3d3d3d")};
        font-size: 22px;
        line-height: 14px;
    }
`;

const MenuItem = (props) => {
    const { data, onMouseOver, nowUrl } = props;

    if (data.type === "SPLIT") {
        return (
            <li className="split">
                <span>|</span>
            </li>
        );
    }

    const getHref = () => {
        switch (data.type) {
            case "CATEGORY":
                if (!data.categoryIdx) {
                    return `#/`;
                }
                return `#/category/${data.categoryIdx}`;
            case "CATEGORY_VIEW":
                if (!data.categoryViewIdx) {
                    return `#/`;
                }
                return `#/categoryview/${data.categoryViewIdx}`;
            case "CMS": {
                if (!data.cmsIdx) {
                    return `#/`;
                }
                let path = `#/cms/${data.cmsCode1}`;
                if (data.cmsCode2) {
                    path += `/${data.cmsCode2}`;
                }
                if (data.cmsCode2 && data.cmsCode3) {
                    path += `/${data.cmsCode3}`;
                }
                return path;
            }
            case "BBS":
                if (!data.bbsCode) {
                    return `#/`;
                }
                return `#/bbs/${data.bbsCode}`;
            case "BRAND":
                return "#/brand";

            case "REVIEW":
                return "#/reviews";
        }
    };

    const isActive = () => {
        if (`#${nowUrl}` === data.url || `#${nowUrl}` === getHref()) {
            return true;
        }

        return false;
    };

    return (
        <li onMouseOver={onMouseOver} className={cx({ active: isActive() })}>
            {data.type === "LINK" ? (
                <A href={data.url} color={data.color} dot={data.colorDot} target="_blank">
                    {data.title}
                    {data.isDot === "Y" && <sup>·</sup>}
                </A>
            ) : (
                <A href={getHref()} color={data.color} dot={data.colorDot}>
                    {data.title}
                    {data.isDot === "Y" && <sup>·</sup>}
                </A>
            )}
        </li>
    );
};

/**
 * Hover Menu
 */

const HoverMenu = (props) => {
    const { type, category, title, onMouseOut, onHide, onClickClose } = props;

    const onMouseOver = () => {
        if (menuTimer) {
            clearTimeout(menuTimer);
            menuTimer = null;
        }
    };

    return (
        <HMContainer onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            {type === "CATEGORY" && <Category title={title} idx={category} onHide={onHide} onClickClose={onClickClose} />}
            {type === "CATEGORY_VIEW" && <CategoryView title={title} idx={category} onHide={onHide} onClickClose={onClickClose} />}
            {type === "BRAND" && <BrandView {...props} onClickClose={onClickClose} isHoverMenu={true} />}
        </HMContainer>
    );
};

const HMContainer = styled.div`
    position: absolute;
    padding: 30px 15px;
    top: 60px;
    left: 0px;
    right: 0px;
    background: #ffffff;
    border-top: #efefef solid 1px;
    border-bottom: #efefef solid 1px;
    z-index: 10;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        display: none;
    }
`;

/**
 * Category
 */

const Category = (props) => {
    const { idx, title, onHide, onClickClose } = props;

    const [categories, setCategories] = useState([]);

    let isLoad = true;

    const onLoad = () => {
        _API.get({ path: "/menu/category", data: { idx } })
            .then((res) => res.data)
            .then((list) => {
                if (isLoad) {
                    setCategories(list);
                }
            });
    };

    useEffect(() => {
        isLoad = true;
        onLoad();
        return () => {
            isLoad = false;
        };
    }, [idx]);

    return (
        <CContainer>
            <h3 className="title">
                <Link to={`/category/${idx}`} onClick={onHide}>
                    {title} 전체
                </Link>
                <button className="btn-close" onClick={onClickClose}>
                    <img src={"/icons/ic_remove.png"} srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])} />
                </button>
            </h3>

            <div className="categories">
                {categories.map((item, i) => (
                    <Fragment key={i}>
                        <CategorySubs category={item} />
                        {i === 8 && <div className="dash" />}
                    </Fragment>
                ))}
            </div>
        </CContainer>
    );
};

const CategoryView = (props) => {
    const { idx, title, onHide, onClickClose } = props;

    const [categories, setCategories] = useState([]);

    let isLoad = true;

    const onLoad = () => {
        _API.get({ path: "/menu/category/view", data: { idx } })
            .then((res) => res.data)
            .then((list) => {
                if (isLoad) {
                    setCategories(list);
                }
            });
    };

    useEffect(() => {
        isLoad = true;
        onLoad();
        return () => {
            isLoad = false;
        };
    }, [idx]);

    return (
        <CContainer>
            <h3 className="title">
                <Link to={`/categoryview/${idx}`} onClick={onHide}>
                    {title} 전체
                </Link>
                <button className="btn-close" onClick={onClickClose}>
                    <img src={"/icons/ic_remove.png"} srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])} />
                </button>
            </h3>

            <div className="categories">
                {categories.map((item, i) => (
                    <>
                        <CategoryViewSubs key={i} category={item} />
                        {i === 8 && <div className="dash" />}
                    </>
                ))}
            </div>
        </CContainer>
    );
};

const CContainer = styled.div`
    margin: 0 auto;
    width: ${(props) => props.theme.layout.width}px;

    .title {
        margin: 0;
        font-size: 13px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-weight: normal;
        line-height: 17px;
        position: relative;
    }
    .title > a {
        color: #3d3d3d;
        text-decoration: none;
    }
    .title > a:hover {
        color: #e32222;
    }

    .title > button.btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        background: 0;
        padding: 10px;
        img {
            width: 14px;
            height: 14px;
        }
    }

    .categories {
        margin: 18px 0 0;
        /* display: grid;
		grid-gap: 15px;
		grid-template-columns: repeat(auto-fit, 155px); */
        display: flex;
        flex-wrap: wrap;

        > div.dash {
            width: 100%;
            margin: 24px 0;
            height: 1px;
            background: #efefef;
            flex-basis: 100%;
        }
    }
`;

const CategorySubs = (props) => {
    const { category } = props;
    const [categories, setCategories] = useState([]);

    let isLoad = true;

    const onLoad = () => {
        _API.get({ path: "/menu/category", data: { idx: category.idx } })
            .then((res) => res.data)
            .then((list) => {
                if (isLoad) {
                    setCategories(list);
                }
            });
    };

    useEffect(() => {
        isLoad = true;
        onLoad();
        return () => {
            isLoad = false;
        };
    }, [category]);

    const hideHover = () => {
        _U.postMessage("HOVER-MENU-HIDE");
    };

    return (
        <CSContainer>
            <h4 className="title">{category.name}</h4>
            <ul className="menu">
                {/* <li>
                    <Link to={`/category/${category.idx}`} onClick={() => hideHover()}>
                        {category.name} 전체
                    </Link>
                </li> */}
                {categories.map((item, i) => (
                    <li key={i}>
                        <Link to={`/category/${item.idx}`} onClick={() => hideHover()}>
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </CSContainer>
    );
};

const CategoryViewSubs = (props) => {
    const { category } = props;
    const [categories, setCategories] = useState([]);

    let isLoad = true;

    const onLoad = () => {
        _API.get({ path: "/menu/category/view", data: { idx: category.idx } })
            .then((res) => res.data)
            .then((list) => {
                if (isLoad) {
                    setCategories(list);
                }
            });
    };

    useEffect(() => {
        isLoad = true;
        onLoad();
        return () => {
            isLoad = false;
        };
    }, [category]);

    const hideHover = () => {
        _U.postMessage("HOVER-MENU-HIDE");
    };

    return (
        <CSContainer>
            <h4 className="title">{category.name}</h4>
            <ul className="menu">
                <li>
                    <Link to={`/categoryview/${category.idx}`} onClick={() => hideHover()}>
                        {category.name} 전체
                    </Link>
                </li>
                {categories.map((item, i) => (
                    <li key={i}>
                        <Link to={`/categoryview/${item.idx}`} onClick={() => hideHover()}>
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </CSContainer>
    );
};

const CSContainer = styled.div`
    flex-basis: 145px;
    .title {
        margin: 0;
        font-size: 13px;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
        line-height: 18px;
        font-weight: normal;
    }

    .menu {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .menu > li {
        margin-top: 12px;
    }

    .menu > li > a {
        font-size: 13px;
        color: #777;
        text-decoration: none;
        font-family: ${(props) => props.theme.fontFamily.regular};
        line-height: 18px;
    }
    .menu > li > a:hover {
        color: #e32222;
    }
`;
