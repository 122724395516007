/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import VideoJs from "video.js";
import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { SelectBox } from "../../UI/Form";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

export default (props) => {
    const { content, media, detail } = props;

    const [more, setMore] = useState(false);

    return (
        <Wrapper>
            <RequireInfo {...props} />
            <div className={cx({ "content-detail": true, more: more })}>
                {media?.map((item, index) => (
                    <Fragment key={index}>
                        {item.fileType === "PHOTO" && (
                            <div className="product-image">
                                <img src={item.signedUrl} />
                            </div>
                        )}
                    </Fragment>
                ))}
                {content.map((item, i) => (
                    <Content key={i} {...item} />
                ))}
            </div>
            <button className="more" onClick={() => setMore(!more)}>
                {more ? (
                    <Fragment>
                        상품정보 접기 <img src="/icons/ic_arrow_down.png" srcSet={getSrcSet(["/icons/ic_arrow_up.png", "/icons/ic_arrow_up@2x.png", "/icons/ic_arrow_up@3x.png"])} />
                    </Fragment>
                ) : (
                    <Fragment>
                        상품정보 더보기 <img src="/icons/ic_arrow_down.png" srcSet={getSrcSet(["/icons/ic_arrow_down.png", "/icons/ic_arrow_down@2x.png", "/icons/ic_arrow_down@3x.png"])} />
                    </Fragment>
                )}
            </button>
            {/* <div className="product-mark">
				<img src={`/images/product_mark.jpg`} />
			</div>
			<div className="product-mark">
				<img src={`/images/product_warning.jpg`} />
			</div> */}
            <DGuide shippingType={detail?.shippingType} />
        </Wrapper>
    );
};

const Content = (props) => {
    const { type, content, signedUrl, signedThumbnailUrl, signedEncodedUrl, encoded } = props;

    const videoNode = useRef(null);
    const player = useRef(null);

    useEffect(() => {
        if (type === "VIDEO" && encoded === "Y") {
            player.current = VideoJs(videoNode.current);
            player.current.src({ type: "application/x-mpegURL", src: signedEncodedUrl });
        }
    }, []);

    switch (type) {
        case "PHOTO":
            return (
                <CWrapper>
                    <img src={signedUrl} alt="" />
                </CWrapper>
            );
        case "VIDEO":
            if (encoded === "Y") {
                return (
                    <CWrapper>
                        <video-js>
                            <video ref={videoNode} className="video-js vjs-big-play-centered" poster={signedThumbnailUrl} controls preload="auto" playsInline data-setup='{"fluid":true}' />
                        </video-js>
                    </CWrapper>
                );
            } else {
                return (
                    <CWrapper>
                        <video controls preload="auto">
                            <source src={signedUrl} type="video/mp4" />
                        </video>
                    </CWrapper>
                );
            }
        case "YOUTUBE":
            return (
                <CWrapper>
                    <div className="player-wrapper">
                        <ReactPlayer className="react-player" url={content} width="100%" height="100%" />
                    </div>
                </CWrapper>
            );
        default:
            return (
                <CWrapper>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </CWrapper>
            );
    }
};

const DGuide = (props) => {
    const { shippingType } = props;
    const [show, setShow] = useState([]);

    const onSetShow = (num) => {
        const tmp = JSON.parse(JSON.stringify(show));

        if (!tmp.includes(num)) {
            tmp.push(num);
        } else {
            tmp.splice(tmp.indexOf(num), 1);
        }

        setShow(tmp);
    };

    return (
        <div className="guide">
            <h3>구매 전 안내사항</h3>
            {shippingType === "OVERSEAS" && (
                <dl className={cx({ show: show.includes("1") })} onClick={() => onSetShow("1")}>
                    <dt>관부가세/배송비</dt>
                    <dd>
                        <b>세관 관/부가세</b>
                        <p>해당 상품은 유럽 현지 파트너사에서 출고되는 상품으로 관/부가세 및 수수료를 포함한 가격이며, 추가로 지불하셔야 할 금액은 발생하지 않습니다.</p>
                        <br />
                        <b>배송비</b>
                        <p>무료</p>
                    </dd>
                </dl>
            )}
            {shippingType === "OVERSEAS" && (
                <dl className={cx({ show: show.includes("2") })} onClick={() => onSetShow("2")}>
                    <dt>상품 구매 과정</dt>
                    <dd>
                        <b>[유럽 현지 파트너사 구매 상품]</b>
                        <p>해당 상품은 유럽 현지의 모아 파트너사를 통해 발송되는 상품입니다.</p>
                        <br />
                        <b>1. 현지 발송</b>
                        <p>
                            주문을 확인 후 유럽에 위치한 현지 파트너사에게 발송 요청을 합니다.
                            <br />
                            주문완료 상태에서 현지 파트너사의 사정에 의해 변동 사항이 발생할 수 있으며, 고객님께 별도 안내드립니다.
                        </p>
                        <br />
                        <b>2. 검수 및 출고</b>
                        <p>
                            현지 파트너사에서 1차로 검수가 이루어집니다.
                            <br />
                            해외 전문 인력이 검수 및 포장을 확실하게 진행하며, 현지에서 보유한 구성품 또한 모두 함께 포장됩니다.
                            <br />
                            한국으로 출고된 상품은 1~2영업일 이내 인천공항에 도착하며, 공휴일이나 연말 등에는 일정이 지연될 수 있습니다.
                            <br />
                            대부분의 파트너사에서 각 브랜드의 모든 구성품을 완벽하게 제공하고 있지는 않습니다. 구성품에 대한 자세한 확인이 필요하신 경우 반드시 모아 고객센터로 문의해 주시기 바랍니다.
                        </p>
                        <br />
                        <b>3. 세관 통관</b>
                        <p>
                            인천공항에 도착한 상품은 정식 세관 통관 과정을 거치게 됩니다.
                            <br />
                            통관 과정은 2~3영업일 정도 소요되며, 세관 검열 강화에 의해 일정은 변동될 수 있습니다.
                        </p>
                        <br />
                        <b>4. 국내 배송</b>
                        <p>
                            세관 통관이 완료된 상품은 바로 모아에 전달되어, 당일 2차 검수와 재포장이 이루어집니다.
                            <br />
                            포장 완료 후 바로 고객님께 발송되며 평균 1∼2영업일 이내 배송되지만 택배사 사정에 의해 배송 예상일이 변동될 수 있습니다.
                        </p>
                    </dd>
                </dl>
            )}

            <dl className={cx({ show: show.includes("3") })} onClick={() => onSetShow("3")}>
                <dt>상품 하자 기준</dt>
                <dd>
                    <p>
                        아래와 같은 사유는 상품의 하자로 판단하지 않으며, 이로 인한 교환/환불은 처리가 불가하오니 구매 전 충분한 주의 부탁드립니다.
                        <br />
                        (※ 상품 카테고리에 따라 이외 추가적인 기준이 필요한 경우 상품의 상세페이지를 통해 고지)
                    </p>
                    <br />

                    <b>[모아 하자 판단 불가 기준]</b>
                    <p>
                        1. 제작 공정 중 발생할 수 있는 미세한 스크래치
                        <br />
                        2. 미세한 박음질 미흡(작은 실밥)
                        <br />
                        3. 천연가죽 특성상 발생하는 가죽 결의 차이 또는 눌림 자국
                        <br />
                        4. 미세한 색상의 차이 또는 일부 디테일의 차이
                        <br />
                        5. 상품에 묻어 있는 미세한 본드 자국
                        <br />
                        6. 상품 배송 중에 발생하는 미세한 구겨짐 등
                    </p>
                    <br />
                    <p>
                        검수 과정 중 하자가 발견될 경우 먼저 고객님께 별도 연락을 드리고 있습니다만, 모아 또는 국내외 검수팀에서 미처 발견하지 못한 부분이 있을 수 있습니다.
                        <br />
                        이와 같은 사항을 발견하신 경우 즉시 고객센터 02-2039-0090로 연락해 주시면 자세히 안내해 드리겠습니다.
                    </p>
                </dd>
            </dl>
        </div>
    );
};

/**
 * Styled
 */

const Wrapper = styled.div`
    margin-top: 50px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding: 0 21px;
        margin-top: 24px;
    }

    div.product-image {
        margin: 20px 0;
        text-align: center;
        img {
            max-width: 100%;
        }
    }

    div.product-mark {
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    h3 {
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 14px;
        color: #3d3d3d;
        font-weight: normal;
        line-height: 20px;
        margin: 0px;
    }

    table {
        width: 100%;
        border-top: 1px solid #dedede;
        border-spacing: 0 0;
        margin-top: 9px;

        th,
        td {
            border-bottom: 1px solid #dedede;
            text-align: left;
            padding: 11px 0 11px 16px;
        }

        th {
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 13px;
            color: #777777;
            line-height: 18px;
            background: #f5f5f5;
        }

        td {
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 13px;
            color: #3d3d3d;
            line-height: 18px;
        }
    }

    div.content-detail {
        max-height: 900px;
        overflow: hidden;
    }

    div.content-detail.more {
        max-height: unset;
        overflow: unset;
    }

    div.contents {
        width: 100%;
        margin-top: 30px;
        max-height: 1200px;
        overflow: hidden;

        /* 아래 css 실 데이터 적용시 삭제 */
        background: #efefef;
        min-height: 1200px;
        font-size: 30px;
        color: #777777;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.contents.more {
        max-height: unset;
        overflow: auto;

        /* 아래 css 실 데이터 적용시 삭제 */
        min-height: 2000px;
    }

    button.more {
        width: 420px;
        height: 60px;
        text-align: center;
        background: none;
        border: 1px solid #3d3d3d;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 14px;
        line-height: 20px;
        margin: 30px auto;
        display: block;
        cursor: pointer;

        img {
            margin-left: 8px;
            width: 14px;
            height: 8px;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: calc(59% + 42px);
            height: 40px;
        }
    }

    .guide {
        border: 1px solid #dedede;
        padding: 20px 30px;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 14px;
        line-height: 20px;

        h3 {
            font-weight: normal;
            margin: 0;
        }

        dl {
            border: 1px solid #efefef;
            background: #fcfcfc;
            padding: 11px 20px;
            color: #9a9a9a;
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 13px;
            line-height: 18px;
            cursor: pointer;
            margin: 8px 0 0px;
        }

        dl > dt {
            background-image: url("/icons/ic_arrow_down_gray.png");
            background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_down_gray.png", "/icons/ic_arrow_down_gray@2x.png", "/icons/ic_arrow_down_gray@3x.png"])});
            background-repeat: no-repeat;
            background-size: 10px 6px;
            background-position: right 2px top 7px;
        }

        dl > dd {
            display: none;
        }

        dl.show {
            background: #ffffff;
            color: #3d3d3d;
        }

        dl.show > dt {
            background-image: url("/icons/ic_arrow_up_gray.png");
            background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_up_gray.png", "/icons/ic_arrow_up_gray@2x.png", "/icons/ic_arrow_up_gray@3x.png"])});
            border-bottom: 1px solid #dedede;
            padding-bottom: 12px;
            font-family: ${(props) => props.theme.fontFamily.medium};
        }
        dl.show > dd {
            display: block;
            margin: 16px 0 0;
            line-height: 17px;
            font-weight: normal;

            b {
                font-family: ${(props) => props.theme.fontFamily.regular};
                color: #3d3d3d;
            }

            p {
                font-family: ${(props) => props.theme.fontFamily.light};
                color: #777777;
                margin: 0;
                padding: 0;
            }
        }
    }
`;

const CWrapper = styled.div`
    position: relative;
    text-align: center;

    * {
        max-width: 100%;
    }
    video {
        width: 100%;
    }

    .player-wrapper {
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const RequireInfo = (props) => {
    const { detail, brand } = props;

    if (detail.isCoupon === "Y") {
        const b = brand && brand.length > 0 ? brand[0].name : "-";
        return (
            <RIWrapper>
                <h4>필수 표기정보</h4>
                <table>
                    <tbody>
                        {isMobile ? (
                            <>
                                <tr>
                                    <th>상품번호</th>
                                    <td>{detail?.model}</td>
                                </tr>
                                <tr>
                                    <th>원산지</th>
                                    <td>{detail?.origin}</td>
                                </tr>

                                <tr>
                                    <th>브랜드</th>
                                    <td>{b}</td>
                                </tr>
                                <tr>
                                    <th>발행자</th>
                                    <td>{detail?.publisher || b}</td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr>
                                    <th>상품번호</th>
                                    <td>{detail?.model}</td>
                                    <th>원산지</th>
                                    <td>{detail?.origin}</td>
                                </tr>
                                <tr>
                                    <th>브랜드</th>
                                    <td>{b}</td>
                                    <th>발행자</th>
                                    <td>{detail?.publisher || b}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </RIWrapper>
        );
    }

    return (
        <RIWrapper>
            <h4>필수 표기정보</h4>
            <table>
                <tbody>
                    {isMobile ? (
                        <>
                            <tr>
                                <th>모델명</th>
                                <td>{detail?.model}</td>
                            </tr>
                            <tr>
                                <th>원산지</th>
                                <td>{detail?.origin}</td>
                            </tr>

                            <tr>
                                <th>제조사</th>
                                <td>{detail?.maker}</td>
                            </tr>
                            <tr>
                                <th>소재</th>
                                <td>{detail?.material}</td>
                            </tr>
                        </>
                    ) : (
                        <>
                            <tr>
                                <th>모델명</th>
                                <td>{detail?.model}</td>
                                <th>원산지</th>
                                <td>{detail?.origin}</td>
                            </tr>
                            <tr>
                                <th>제조사</th>
                                <td>{detail?.maker}</td>
                                <th>소재</th>
                                <td>{detail?.material}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </RIWrapper>
    );
};

const RIWrapper = styled.div`
    h4 {
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        margin: 0;
    }

    table {
        margin-bottom: 30px;
    }

    table th {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        font-weight: normal;
        width: 15%;
    }

    table td {
        width: 35%;
    }
`;
