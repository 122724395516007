/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Info from "../Product/View.Info";
import Content from "../Product/View.Content";
import Socket from "../../Components/Socket";
import Paging from "../../UI/Paging";

let numPerPage = 6;
let pageBlock = 5;

export default (props) => {
	const { location, history, match } = props;
	const cp = { history, location, match };
	const { roomIdx } = props;
	const [socketConnect, setSocketConnect] = useState(false);
	const [list, setList] = useState([]);
	const [code, setCode] = useState(null);
	const [page, setPage] = useState(1);
	// const productRef = useRef(null);

	const loadList = () => {
		_API
			.get({
				path: "/live/product",
				data: { idx: roomIdx },
			})
			.then((res) => res.data)
			.then((list) => {
				setList(list);
			});
	};

	const handleOnPicked = (c) => {
		setCode(c === code ? null : c);
		setTimeout(() => {
			// productRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
			// console.log(productRef?.current?.offsetTop);
			// window.scrollTo(0, productRef?.current?.offsetTop);
		}, 1000);
	};

	const onReceivedMessage = (e) => {
		console.log(`onReceivedMessage: `, e);
		_U.parseMessage(e)
			.then((data) => {
				switch (data.code) {
					case "SOCKET-RECEIVED":
						return data.data || null;
				}
			})
			.then((data) => {
				if (data?.mode === "PRODUCT_INIT") {
					loadList();
				}
			});
	};

	useEffect(() => {
		loadList();
	}, []);

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	const getList = () => {
		const start = page === 1 ? 0 : (page - 1) * numPerPage;

		if (list.length > 0) {
			const tmpList = [];

			for (let i = start; i < page * numPerPage; i++) {
				if (list[i] && list[i]?.code) {
					tmpList.push(list[i]);
				}
			}

			return tmpList;
		}
	};

	const getPaging = () => {
		let startPageNo = (Math.ceil(page / pageBlock) - 1) * pageBlock + 1; //현재 세트내 출력될 시작 페이지

		let endPage = startPageNo + pageBlock - 1;

		if (endPage > totalPage()) {
			endPage = totalPage();
		}

		const isNowFirst = page == 1 ? true : false;
		const isNowFinal = page == totalPage() ? true : false;

		let prevPageNo = 1;
		if (isNowFirst) {
			prevPageNo = 1;
		} else {
			prevPageNo = page - 1 < 1 ? 1 : page - 1;
		}

		let nextPageNo = 1;
		if (isNowFinal) {
			nextPageNo = totalPage();
		} else {
			nextPageNo = page + 1 > totalPage() ? totalPage() : page + 1;
		}

		const blockpage = ((page - 1) / pageBlock) * pageBlock + 1;
		let prevBlockNo = 1;
		if (blockpage == 1) {
			prevBlockNo = 1;
		} else {
			prevBlockNo = blockpage - pageBlock;
		}

		let nextBlockNo = 1;
		if (endPage + pageBlock > totalPage()) {
			nextBlockNo = totalPage();
		} else {
			nextBlockNo = blockpage + pageBlock;
		}
		const start = (page - 1) * numPerPage;

		const paging = {
			start: start,
			startPageNo: startPageNo,
			endPageNo: endPage,
			prevBlockNo: prevBlockNo,
			nextBlockNo: nextBlockNo,
			prevPageNo: prevPageNo,
			nextPageNo: nextPageNo,
			pageNo: page,
			finalPageNo: totalPage(),
		};

		return paging;
	};

	const totalPage = () => {
		if (list.length === 0) {
			return;
		}

		const totalPage = Math.floor((list.length + (numPerPage - 1)) / numPerPage);

		return totalPage;
	};

	const movePage = (page) => {
		setPage(page);
	};

	return (
		<>
			<Container>
				<TitleWrapper>
					<h3 className="title">라이브 상품</h3>
				</TitleWrapper>

				<ListWrapper>
					{getList()?.map((item, i) => (
						<Product key={i} data={item} isPicker onPicked={handleOnPicked} />
					))}
					{getPaging() && (
						<div className="paging">
							<Paging paging={getPaging()} pageCallback={movePage} />
						</div>
					)}
				</ListWrapper>

				{/* <Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} /> */}
			</Container>
			{code && (
				<>
					<ProductItem {...cp} code={code} />
				</>
			)}
		</>
	);
};

const ListWrapper = styled.div`
	margin: 26px 0 0;
	padding: 0px 5px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
`;

const Product = (props) => {
	const { history, data, isPicker, onPicked } = props;

	let device = null;
	if (!isPicker) {
		const { device: d } = useContext(AppContext);
		device = d;
	}

	const handleOnClick = () => {
		if (isPicker) {
			onPicked && onPicked(data.code);
		} else {
			openPage({ device, history, to: `/product/${data.code}` });
		}
	};

	const getDiscount = () => {
		try {
			return Math.round(data.priceDiscountPercent);
		} catch (e) {
			return data.priceDiscountPercent;
		}
	};

	return (
		<PContainer onClick={handleOnClick}>
			<div className="thumbnail">
				<img src={data.signedThumbnailUrl || data.signedUrl} alt="" />
			</div>
			<div className="content">
				{data.brand && <h4 className="brand">{data.brand}</h4>}

				<h3 className="title">{data.name}</h3>
				<h5 className="cost">
					<b>{_U.numberFormat(data.priceSale)}</b>원{data?.priceDiscountPercent > 0 && <span className="discount">{getDiscount()}%</span>}
				</h5>
				{data?.priceDiscountPercent > 0 && (
					<h5 className="old-cost">
						<b>{_U.numberFormat(data.priceNormal)}</b>원
					</h5>
				)}
				{/* <div className="tags">
                    <span className="tag red">익일배송</span>
                    <span className="tag">최저가스캐너</span>
                </div> */}
			</div>
		</PContainer>
	);
};

const PContainer = styled.div`
	flex: 1;
	margin-bottom: 16px;
	cursor: pointer;

	:hover {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	}

	.thumbnail {
		/* margin: 10px auto 6px; */
		width: 168px;
		height: 168px;
	}

	.thumbnail img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.content {
		margin: 8px 0 0;
	}

	.content > .brand {
		margin: 0;
		font-size: 10px;
		font-weight: normal;
		color: #3d3d3d;
		line-height: 15px;
		font-family: ${(props) => props.theme.fontFamily.regular};
	}
	.content > .title {
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		text-align: left;
		color: #777777;
		font-family: ${(props) => props.theme.fontFamily.regular};
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.content > .cost {
		margin: 4px 0 0;
		font-size: 16px;
		font-weight: normal;
		text-align: left;
		color: #3d3d3d;
		line-height: 24px;
		font-family: ${(props) => props.theme.fontFamily.medium};

		b {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}
	}
	.content > .cost > span.discount {
		margin-left: 8px;
		font-size: 13px;
		line-height: 15px;
		font-weight: normal;
		color: #e32222;
		font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
	}
	.content > .old-cost {
		margin: 0;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		text-align: left;
		text-decoration: line-through;
		color: #dedede;
		font-family: ${(props) => props.theme.fontFamily.medium};

		b {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};
			font-weight: normal;
		}
	}
	.content > .tags {
		margin-top: 18px;
	}

	.content > .tags > .tag {
		margin-right: 5px;
		padding: 3px 9px;
		font-size: 12px;
		color: #777;
		border: solid 1px #cfcfcf;
		display: inline-block;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 2px 8px;
			margin-right: 1px;
		}
	}
	.content > .tags > .tag:last-child {
		margin-right: 0;
	}
	.content > .tags > .red {
		color: #e32222;
	}
`;

const ProductItem = (props) => {
	const { code } = props;

	const [categories, setCategories] = useState([]);
	const [detail, setDetail] = useState(null);
	const [options, setOptions] = useState([]);
	const [brand, setBrand] = useState([]);
	const [media, setMedia] = useState([]);
	const [content, setContent] = useState([]);
	const productRef = useRef(null);

	const loadData = () => {
		_API
			.get({ path: "/product/search/item", data: { code } })
			.then((res) => res.data)
			.then((data) => {
				const { categories, detail, brand, media, content, options } = data;
				setCategories(categories);
				setDetail(detail);
				setOptions(options);
				setBrand(brand);
				setMedia(media);
				setContent(content);
			});
	};

	useEffect(() => {
		loadData();
		productRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	}, [code]);

	return (
		<PIContainer ref={productRef}>
			<Info media={media} brand={brand} detail={detail} options={options} categories={categories} {...props} />
			<Content content={content} detail={detail} isLive />
		</PIContainer>
	);
};

const Container = styled.div`
	flex: 1;
	flex-wrap: wrap;
	padding: 0 16px;
`;

const TitleWrapper = styled.div`
	position: relative;

	.title {
		margin: 0;
		padding: 0;
		font-size: 24px;
		line-height: 35px;
		color: #3d3d3d;
		font-family: ${(props) => props.theme.fontFamily.medium};
	}
`;

const PIContainer = styled.div`
	border-top: #efefef solid 1px;
	flex-basis: 100%;
`;
