/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

export default (props) => {
	const { roomIdx, roomData, setSocketConnect, socketConnect, isPc } = props;
	const { auth, iam } = useContext(AppContext);
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState("");
	const [isSendProc, setIsSendProc] = useState(false);
	const endRef = useRef(null);
	const intoRef = useRef(null);

	const sendRoom = () => {
		_U.postMessage("SOCKET-SEND", {
			action: "ROOM",
			data: {
				roomIdx,
			},
		});
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e)
			.then((data) => {
				switch (data.code) {
					case "SOCKET-RECEIVED":
						return data.data || null;
				}
			})
			.then((data) => {
				if (data?.mode === "CHAT") {
					setMessages((o) => [...o.slice(-9), data]);
				}
			});
	};

	const onKeyUp = (e) => {
		if (e.key === "Enter") {
			onSend();
		}
	};

	const onSend = () => {
		if (!iam || !iam.idx) {
			swal({ title: "알림", text: "로그인 후 이용가능합니다." });
			return;
		}
		if (!socketConnect) {
			swal({ title: "알림", text: "채팅서버에 연결중입니다.\n잠시후 다시연결해 주세요." });
			return;
		}
		if (!message) {
			return;
		}
		if (isSendProc) {
			return;
		}
		setIsSendProc(true);

		_U.postMessage("SOCKET-SEND", {
			action: "CHAT",
			data: {
				mode: "CHAT",
				id: iam?.code + "_" + iam?.idx,
				code: iam?.code,
				idx: iam?.idx,
				name: iam?.name || "익명",
				nickName: iam?.nickName || "",
				roomIdx,
				message,
			},
		});
		setMessage("");
		setIsSendProc(false);
	};

	useEffect(() => {
		if (socketConnect) {
			sendRoom();
		}
	}, [socketConnect]);

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	return (
		<Container isPc={isPc}>
			<Wrapper>
				<MessageWrapper>
					<div className="message-list" ref={intoRef}>
						{messages.map((item, i) => (
							<Message key={i} isMe={item.idx === iam?.idx}>
								<span className={cx({ name: true, me: item.idx === iam?.idx })}>{item.nickName}</span>
								{item.message}
							</Message>
						))}
					</div>
				</MessageWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	${(props) => (props.isPc ? `position: absolute;` : `position: fixed;`)}

	width: 375px;
	left: 15px;

	bottom: 94px;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	flex-direction: column;
`;

const MessageWrapper = styled.div`
	margin: 0;
	flex: 1;

	.message-list {
	}
`;

const Message = styled.div`
	position: relative;
	margin: 2px 0;
	margin-right: ${(props) => (props.isMe ? "15px" : "0")};
	font-size: 13px;
	color: #ffffff;
	text-align: left;
	text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);

	.name {
		margin-right: 12px;
		font-size: 13px;
		color: #c3c3c3;
	}

	.me {
		color: #fbc531;
	}
`;
