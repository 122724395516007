/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { v4 as UUIDV4 } from "uuid";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";
import { tryToPlay } from "../../Components/AntMedia/fetch.stream.js";

const _SIZE = { width: 720, height: 1280 };
const hlsExtension = "m3u8";

export default (props) => {
	const { index, title, type, streamId: id, signedUrl, current, onChange } = props;
	let _IS_PLAY = false;
	let _ADAPTER = null;
	const _VIDEO_ID = `VIDEO-VIEWER-${UUIDV4()}`;

	const loadChannelStatus = () => {
		_API
			.get({
				path: "/live/channel/status",
				data: { id },
			})
			.then((res) => res.data)
			.then((info) => {
				if (info) {
					if (!_IS_PLAY) {
						startWebRTCStreaming(id, _VIDEO_ID).then((adapter) => {
							_ADAPTER = adapter;
							_IS_PLAY = true;
						});
					}
				} else {
					if (_IS_PLAY) {
						stopWebRTCStreaming(_ADAPTER, id);
						_IS_PLAY = false;
					}
				}
			});
	};

	const getTitle = () => {
		if (title) {
			return title;
		}

		if (type === "MAIN") {
			return "메인";
		}

		return `CAM #${index}`;
	};

	const handleClicked = () => {
		onChange && onChange(id);
	};

	useEffect(() => {
		const timer = setInterval(() => {
			loadChannelStatus();
		}, 10000);
		return () => {
			clearInterval(timer);
			if (_ADAPTER) {
				stopWebRTCStreaming(_ADAPTER, id);
				_IS_PLAY = false;
			}
		};
	}, []);

	return (
		<Container onClick={handleClicked}>
			<h3>{type === "MAIN" ? "메인 캠" : `CAM #${index}`}</h3>
			<VideoWrapper isCurrent={current === id}>
				<Title isCurrent={current === id}>{getTitle()}</Title>
				<video id={_VIDEO_ID} autoPlay width={720} height={1280} muted poster={signedUrl} playsInline />
			</VideoWrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	margin: 0 0 16px;
	overflow: hidden;
	cursor: pointer;

	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 14px;
		color: #dedede;
		line-height: 20px;
		padding: 0;
		margin: 0;
		margin-bottom: 6px;
	}

	:last-child {
		margin-bottom: 0px;
	}
`;

const VideoWrapper = styled.div`
	position: relative;
	padding-top: 57%;
	width: calc(100% - 4px);
	${(props) => (props.isCurrent ? `border: #e32222 solid 2px;` : `border: #1d1d1d solid 2px;`)};
	border-radius: 10px;
	overflow: hidden;

	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000000;
	}

	video[poster] {
		object-fit: cover;
	}
`;

const Title = styled.div`
	position: absolute;
	padding: 5px 0;
	left: 0px;
	right: 0px;
	bottom: 0px;

	font-size: 14px;
	text-align: center;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.7);

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	z-index: 2;
`;

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (streamId, videoId, token) => {
	return new Promise((resolve, reject) => {
		try {
			const adapter = new WebRTCAdaptor({
				websocket_url: _CFG.getStreamUrl(),
				mediaConstraints: { video: false, audio: false },
				peerconnection_config: null,
				sdp_constraints: {
					OfferToReceiveAudio: false,
					OfferToReceiveVideo: false,
				},
				remoteVideoId: videoId,
				isPlayMode: true,
				debug: true,
				callback: (info, description) => {
					switch (info) {
						case "initialized":
							adapter.getStreamInfo(streamId);
							break;
						case "streamInformation":
							adapter.play(streamId);
							break;
						case "play_started":
							_U.postMessage("PLAY-STARTED");
							break;
						case "play_finished":
							_U.postMessage("PLAY-ENDED");
							break;
						case "closed":
							if (typeof description != "undefined") {
								console.log("Connecton closed: " + JSON.stringify(description));
							}
							break;
						case "bitrateMeasurement":
							break;
					}
				},
				callbackError: (err) => {
					switch (err) {
						case "no_stream_exist":
							break;
						case "notSetRemoteDescription":
							tryToPlay(streamId, token, hlsExtension);
							break;
						case "already_playing":
							break;
						default:
							_U.postMessage("PLAY-ERROR", err);
							break;
					}
				},
			});
			resolve(adapter);
		} catch (e) {
			reject(e);
		}
	});
};

const stopWebRTCStreaming = (adapter, streamId) => {
	adapter.stop(streamId);
};
