import React from "react";
import cx from "classnames";
import qs from "query-string";
import styled from "styled-components";

import { _U } from "../Modules/Utils";
import { getSrcSet } from "./Assets";

export default class Paging extends React.Component {
	handlePageClicked(page) {
		this.props.pageCallback && this.props.pageCallback(page < 1 ? 1 : page);
	}

	render() {
		const { paging } = this.props;
		const pagingItems = [];
		if (paging) {
			for (let i = paging.startPageNo; i <= paging.endPageNo; i++) {
				pagingItems.push(
					<li className={cx({ "page-item": true, active: paging.pageNo === i })} key={i}>
						<a onClick={this.handlePageClicked.bind(this, i)} className="page-link">
							{i}
						</a>
					</li>
				);
			}
		}

		return (
			<PagingWrapper>
				{paging && (
					<ul className="pagination justify-content-center">
						<li className="page-item pagination-first " onClick={this.handlePageClicked.bind(this, 1)}>
							<a className="page-link">
								<img src={"/icons/ic_page_prev.png"} srcSet={getSrcSet(["/icons/ic_page_prev.png", "/icons/ic_page_prev@2x.png", "/icons/ic_page_prev@3x.png"])} />
								<img src={"/icons/ic_page_prev.png"} srcSet={getSrcSet(["/icons/ic_page_prev.png", "/icons/ic_page_prev@2x.png", "/icons/ic_page_prev@3x.png"])} />
							</a>
						</li>
						<li className="page-item pagination-prev " onClick={this.handlePageClicked.bind(this, paging.prevBlockNo)}>
							<a className="page-link">
								<img src={"/icons/ic_page_prev.png"} srcSet={getSrcSet(["/icons/ic_page_prev.png", "/icons/ic_page_prev@2x.png", "/icons/ic_page_prev@3x.png"])} />
							</a>
						</li>

						{pagingItems}

						<li className="page-item pagination-next" onClick={this.handlePageClicked.bind(this, paging.nextBlockNo)}>
							<a className="page-link">
								<img src={"/icons/ic_page_prev.png"} srcSet={getSrcSet(["/icons/ic_page_next.png", "/icons/ic_page_next@2x.png", "/icons/ic_page_next@3x.png"])} />
							</a>
						</li>
						<li className="page-item pagination-first" onClick={this.handlePageClicked.bind(this, paging.finalPageNo)}>
							<a className="page-link">
								<img src={"/icons/ic_page_prev.png"} srcSet={getSrcSet(["/icons/ic_page_next.png", "/icons/ic_page_next@2x.png", "/icons/ic_page_next@3x.png"])} />
								<img src={"/icons/ic_page_prev.png"} srcSet={getSrcSet(["/icons/ic_page_next.png", "/icons/ic_page_next@2x.png", "/icons/ic_page_next@3x.png"])} />
							</a>
						</li>
					</ul>
				)}
			</PagingWrapper>
		);
	}
}

export const ToPage = (location, history, params) => {
	const url = `${location.pathname}?q=${_U.encode(params)}`;
	history.push(url);
};

export const GetParams = (location) => {
	const search = location?.search;
	if (!search) return null;

	const p = qs.parse(search);
	if (!p.q) {
		return null;
	}

	return _U.decode(p.q);
};

const PagingWrapper = styled.div`
	ul.pagination {
		list-style: none;
		padding: 0;
		margin: 0 auto;

		display: table;
	}

	ul.pagination > li.page-item {
		display: table-cell;

		color: #3d3d3d;
		font-size: 13px;
		line-height: 13px;
		cursor: pointer;
		padding: 0 4px 0 0;

		a {
			width: 26px;
			height: 26px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 6px;
				height: 11px;
				text-align: center;
				vertical-align: middle;
			}

			:hover {
				background: #f5f5f5;
			}
		}
	}

	ul.pagination > li.page-item.active {
		color: #00788d;
	}

	ul.pagination > li.pagination-first,
	ul.pagination > li.pagination-prev,
	ul.pagination > li.pagination-next {
		a {
			border: 1px solid #e7e7e7;
			background: #ffffff;
			width: 24px;
			height: 24px;

			:hover {
				background: #ffffff;
			}
		}
	}
`;
