/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";

import Layout from "./Layout";
import { AppContext } from "../Components/AppProvider";
import { _U, _API, _CK } from "../Modules/Utils";
import { getSrcSet } from "../UI/Assets";

export default (props) => {
	const { history } = props;
	const { iam, auth } = useContext(AppContext);
	const [menu, setMenu] = useState([]);

	const load = () => {
		_API
			.get({ path: "/menu" })
			.then((res) => res.data)
			.then((list) => setMenu(list));
	};

	useEffect(() => {
		load();
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout {...props} showMenu={false}>
			<Wrapper>
				<DCWrapper>
					<ul className="list-box">
						{menu?.map((item, index) => (
							<MenuItem key={index} data={item} {...props} />
						))}
					</ul>
				</DCWrapper>
			</Wrapper>
		</Layout>
	);
};

const Wrapper = styled.div`
	width: ${(props) => props.theme.layout.productWidth}px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 80px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
	}
`;

const DCWrapper = styled.div`
	padding: 0 32px;

	.list-box {
		list-style: none;
		padding: 0;
		margin: 16px 0 0 0;
	}

	.list-box > li {
		color: #3d3d3d;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 29px;
		font-size: 24px;
		padding: 12px 0;
		position: relative;
		a {
			color: #3d3d3d;
		}

		img {
			vertical-align: top;
			width: 14px;
			height: 8px;
			position: absolute;
			right: 0;
			top: 10px;
		}
		.depth-menu {
			margin-top: 2px;
		}

		.depth-menu > a {
			margin-top: 14px;
			padding-left: 12px;
			color: #3d3d3d;
			font-family: ${(props) => props.theme.fontFamily.medium};
			line-height: 24px;
			font-size: 18px;
			text-decoration: none;
			display: block;
			:hover,
			:visited,
			:active {
				color: #3d3d3d;
			}
		}
	}

	.list-box > li.split {
		border-bottom: 1px solid #efefef;
		padding: 8px 0 0;
		margin-bottom: 8px;
	}
`;

const MenuItem = (props) => {
	const { data, onMouseOver, resolve } = props;
	const [categories, setCategories] = useState([]);
	const [depth, setDepth] = useState(false);
	let isLoad = true;

	const getHref = () => {
		switch (data.type) {
			case "CATEGORY":
				if (!data.categoryIdx) {
					return `#/`;
				}
				return `#/category/${data.categoryIdx}`;
			case "CMS": {
				if (!data.cmsIdx) {
					return `#/`;
				}
				let path = `#/cms/${data.cmsCode1}`;
				if (data.cmsCode2) {
					path += `/${data.cmsCode2}`;
				}
				if (data.cmsCode2 && data.cmsCode3) {
					path += `/${data.cmsCode3}`;
				}
				return path;
			}
			case "BBS":
				if (!data.bbsCode) {
					return `#/`;
				}
				return `#/bbs/${data.bbsCode}`;
			case "BRAND":
				return "#/brand";
			case "REVIEW":
				return "#/reviews";
		}
	};

	const onLoadCategory = () => {
		_API
			.get({ path: "/menu/category", data: { idx: data?.categoryIdx } })
			.then((res) => res.data)
			.then((list) => {
				if (isLoad) {
					setCategories(list);
				}
			});
	};

	useEffect(() => {
		if (data?.categoryIdx) {
			isLoad = true;
			onLoadCategory();
		}

		return () => {
			isLoad = false;
		};
	}, [data?.categoryIdx]);

	const showDepth = () => {
		setDepth(!depth);
	};

	return (
		<li onMouseOver={onMouseOver ? onMouseOver : undefined} className={cx({ active: true })}>
			{data.type === "LINK" ? (
				<A href={data.url} color={data.color} dot={data.colorDot} target="_blank">
					{data.title}
				</A>
			) : (
				<>
					<A href={categories.length === 0 && getHref()} color={data.color} dot={data.colorDot} onClick={() => (categories.length > 0 ? showDepth() : resolve())} active={depth}>
						{data.title}
						{categories.length > 0 && (
							<img
								src={depth ? "/icons/ic_arrow_up_gray.png" : "/icons/ic_arrow_down.png"}
								srcSet={getSrcSet(
									depth
										? ["/icons/ic_arrow_up_gray.png", "/icons/ic_arrow_up_gray@2x.png", "/icons/ic_arrow_up_gray@3x.png"]
										: ["/icons/ic_arrow_down.png", "/icons/ic_arrow_down@2x.png", "/icons/ic_arrow_down@3x.png"]
								)}
							/>
						)}
					</A>
					{depth && categories.length > 0 && (
						<div className="depth-menu">
							{categories?.map((cItem, cIndex) => (
								<a className="depth-item" key={cIndex} href={`#/category/${cItem.idx}`}>
									{cItem.name}
								</a>
							))}
						</div>
					)}
				</>
			)}
		</li>
	);
};

const A = styled.a`
	position: relative;
	display: block;
	text-decoration: none;
	:hover,
	:visited,
	:active {
		text-decoration: none;
	}
	sup {
		position: absolute;
		top: -5px;
		right: -10px;
	}

	${(props) =>
		props.active
			? `
		color:#acacac !important;
	`
			: ``}
`;
