/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import { AppContext } from "../../Components/AppProvider";
import { Input, UFormWrapper, CheckBox } from "../../UI/Form";
import { MypageTop } from "../../UI/Header";
import { isMobile } from "react-device-detect";

import Menu from "./Mypage.Menu";

export default (props) => {
	const { location, history, match } = props;
	const { auth, iam } = useContext(AppContext);
	const [isPasswdCert, setIsPasswdCert] = useState(_CK.get(`passwordCert${iam?.idx}`) || "N");
	const [isSnsMember, setIsSnsMember] = useState(false);

	const onCertPassword = () => {
		_CK.set(`passwordCert${iam?.idx}`, "Y");
		setIsPasswdCert("Y");
	};

	useEffect(() => {
		if (iam?.snsKakao || iam?.snsNaver || iam?.snsApple || iam?.snsFacebook || iam?.snsGoogle) {
			setIsPasswdCert("Y");
			setIsSnsMember(true);
		}
	}, []);

	if (!_CK.isLogin()) {
		history.goBack();
		return null;
	}

	return (
		<Layout {...props} hideTop={true}>
			<Container>
				<MypageTop {...props}>{isMobile ? <>{isPasswdCert === "Y" ? `정보수정` : `비밀번호 재 확인`}</> : `마이페이지`}</MypageTop>
				<Wrapper>
					<Menu {...props} />
					<ContentWrapper>{isPasswdCert === "Y" ? <Form {...props} isSnsMember={isSnsMember} /> : <PasswordCert onCertPassword={onCertPassword} {...props} />}</ContentWrapper>
				</Wrapper>
			</Container>
		</Layout>
	);
};

const Wrapper = styled.div`
	display: flex;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-bottom: 40px;
	}
`;

const ContentWrapper = styled.div`
	margin-left: 30px;
	padding-top: 10px;
	flex: 1;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-left: 0;
		padding: 0 21px;
	}
`;

const PasswordCert = (props) => {
	const { iam } = useContext(AppContext);

	const [passwd, setPasswd] = useState("");

	const onPasswordCert = () => {
		if (!passwd) {
			swal({ title: "알림", text: "비밀번호를 입력해 주세요." });
			return;
		}

		const params = {
			path: "/user/mypage/password/cert",
			data: {
				passwd,
			},
		};
		_API
			.get(params)
			.then((res) => {
				if (res.data) {
					props.onCertPassword && props.onCertPassword();
				} else {
					swal({ title: "알림", text: "비밀번호가 일치하지 않습니다." });
				}
			})
			.catch((e) => {});
	};

	return (
		<CWrapper>
			<div className="top">
				<h2>비밀번호 재 확인</h2>
			</div>
			<table>
				<tbody>
					<tr>
						<th>이메일</th>
						<td>{iam?.id}</td>
					</tr>
					<tr>
						<th>비밀번호</th>
						<td>
							<Input type="password" value={passwd} onChange={(e) => setPasswd(e.target.value)} styles={{ width: "200px", padding: "8px 10px" }} />
						</td>
					</tr>
				</tbody>
			</table>

			<div className="button">
				<button onClick={onPasswordCert}>확인</button>
			</div>
		</CWrapper>
	);
};

const CWrapper = styled.div`
	div.top {
		position: relative;
		display: flex;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-bottom: 1px solid #3d3d3d;
			margin-top: 24px;
			padding-bottom: 16px;
			display: none;
		}
	}

	div.top > h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
	}

	table {
		border-spacing: 0;
		width: 100%;
		margin-top: 16px;
	}

	table > tbody > tr {
		color: #777777;
	}

	table > tbody > tr:first-child > th,
	table > tbody > tr:first-child > td {
		border-top: 1px solid #dfdfdf;
	}

	table > tbody > tr > th {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 12px;
		line-height: 17px;
		padding: 9px 0 9px 16px;
		border-bottom: 1px solid #dfdfdf;
		text-align: left;
		background: #f5f5f5;
		width: 15%;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			width: 20%;
		}
	}

	table > tbody > tr > td {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		padding: 14px 16px;
		color: #3d3d3d;
		border-bottom: 1px solid #dfdfdf;

		P {
			padding: 0;
			margin: 0;
			color: #b8b8b8;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 10px;
			line-height: 18px;
		}

		input {
			color: #3d3d3d;
		}
	}

	div.button {
		text-align: center;
		margin-top: 20px;
	}

	div.button > button {
		font-family: ${(props) => props.theme.fontFamily.regular};
		border: 1px solid #00788d;
		background: #00788d;
		color: #ffffff;
		font-size: 14px;
		line-height: 20px;

		padding: 15px 0;
		width: 220px;
	}
`;

const Form = (props) => {
	const { isSnsMember } = props;
	const { auth, iam, saveAuth, saveIam } = useContext(AppContext);
	console.log(iam);
	const [data, setData] = useState(iam);

	const onSave = () => {
		_API.post({ path: "/user/mypage/info", data }).then((res) => {
			const { accessKey, data } = res.data;
			_CK.setAuth(accessKey);
			saveAuth(accessKey);
			saveIam(data);
			swal({ title: "알림", text: "정보가 수정 되었습니다." });
		});
	};

	const getId = () => {
		if (iam?.snsApple) {
			return "애플 아이디로 가입한 계정입니다.";
		} else if (iam?.snsFacebook) {
			return "페이스북 아이디로 가입한 계정입니다.";
		} else if (iam?.snsGoogle) {
			return "구글 아이디로 가입한 계정입니다.";
		} else if (iam?.snsKakao) {
			return "카카오톡 아이디로 가입한 계정입니다.";
		} else if (iam?.snsNaver) {
			return "네이버 아이디로 가입한 계정입니다.";
		} else {
			return iam?.id;
		}
	};

	const getGender = () => {
		switch (iam?.gender) {
			case "M":
				return "남";
			case "F":
				return "여";
			default:
				return "";
		}
	};

	const getBirthday = () => {
		let year = "";
		let day = "";

		if (iam?.birthday) {
			const birthday = moment(iam?.birthday, "YYYYMMDD");
			year = birthday.format("YYYY");
			day = birthday.format("MM-DD");

			if (parseInt(year) > 0) {
				return `${year}-${day}`;
			} else {
				return day;
			}
		}
	};
	return (
		<CWrapper>
			<div className="top">
				<h2>정보수정</h2>
			</div>
			<table>
				<tbody>
					<tr>
						<th>이메일</th>
						<td>{getId()}</td>
					</tr>
					<tr>
						<th>이름</th>
						<td>{iam?.name}</td>
					</tr>
					{/* <tr>
						<th>닉네임</th>
						<td>
							<Input
								type="text"
								value={data?.nickName}
								onChange={(e) => setData({ ...data, nickName: e.target.value })}
								styles={{ width: "200px", padding: "8px 10px" }}
								placeholder="닉네임"
							/>
						</td>
					</tr> */}
					<tr>
						<th>연락처</th>
						<td>{iam?.mobile}</td>
					</tr>
					{getGender() && (
						<tr>
							<th>성별</th>
							<td>{getGender()}</td>
						</tr>
					)}

					{getBirthday() && (
						<tr>
							<th>생년월일</th>
							<td>{getBirthday()}</td>
						</tr>
					)}
					{!isSnsMember && (
						<tr>
							<th>비밀번호 변경</th>
							<td>
								<Input
									type="password"
									value={data?.passwd}
									onChange={(e) => setData({ ...data, passwd: e.target.value })}
									styles={{ width: "200px", padding: "8px 10px" }}
									placeholder="현재 비밀번호"
								/>
								<Input
									type="password"
									value={data?.newPasswd}
									onChange={(e) => setData({ ...data, newPasswd: e.target.value })}
									styles={{ width: "200px", padding: "8px 10px", marginTop: "8px" }}
									placeholder="새 비밀번호"
								/>
								<Input
									type="password"
									value={data?.rePasswd}
									onChange={(e) => setData({ ...data, rePasswd: e.target.value })}
									styles={{ width: "200px", padding: "8px 10px", marginTop: "8px" }}
									placeholder="비밀번호 다시입력"
								/>
							</td>
						</tr>
					)}

					<tr>
						<th>상품/이벤트 및 프로모션 알림 동의</th>
						<td>
							<CheckBox
								title="이메일"
								value="Y"
								checked={data?.agrEmail === "Y"}
								onClick={() => setData({ ...data, agrEmail: data?.agrEmail === "Y" ? "N" : "Y" })}
								inline
								style={{ marginRight: 23 }}
							/>
							<CheckBox title="SMS" value="Y" checked={data?.agrSms === "Y"} onClick={() => setData({ ...data, agrSms: data?.agrSms === "Y" ? "N" : "Y" })} inline />

							<p>
								상품/이벤트 및 프로모션 수신동의를 하시면 상품 및 이벤트 정보를 받으실 수 있습니다.
								<br />
								서비스의 중요 안내사항 및 주문/배송에 대한 정보는 위 수신 여부와 상관없이 발송됩니다.
							</p>
						</td>
					</tr>
				</tbody>
			</table>

			<div className="button">
				<button onClick={onSave}>수정</button>
			</div>
		</CWrapper>
	);
};
