/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { UInputForm, UFormWrapper, CheckBox, Textarea, Radio } from "../../UI/Form";
import Paging from "../../UI/Paging";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import Dialog from "../../UI/Dialog";
import swal from "sweetalert";

export default (props) => {
	const { productInfo } = props;
	const [list, setList] = useState([]);
	const [paging, setPaging] = useState(null);

	const loadData = (page, sort) => {
		if (productInfo) {
			const params = { productIdx: productInfo?.idx };
			if (page) {
				params.page = page;
				params.numPerPage = 5;
			}
			if (sort) params.sort = sort;
			params.bbsCode = "PRODUCTQNA";

			_API
				.get({ path: "/bbs", data: { ...params } })
				.then((res) => res.data)
				.then((data) => {
					const { paging, list } = data;
					setPaging(paging);
					setList(list);

					// if (paging && params.page !== 1) {
					//     setList([...list, ...productQnaList]);
					// } else {
					//     setList(list);
					// }
				});
		}
	};

	const nextPage = (page, sort) => {
		loadData(paging.nextPage, sort);
	};

	useEffect(() => {
		loadData(1);
	}, [productInfo]);

	const movePage = (page) => {
		props.changeSort && props.changeSort(page);
	};

	const onClickWrite = () => {
		Dialog({
			title: `상품문의`,
			body: <Form {...{ productIdx: productInfo?.idx }} />,
		})
			.then((data) => {
				loadData(1);
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	let virtualNo = paging ? paging.virtualRecordNo : -1;

	return (
		<Wrapper>
			<h2>상품 문의</h2>
			<div className="top">
				<button onClick={onClickWrite}>문의하기</button>
			</div>
			<div className="list">
				<table>
					<thead>
						<tr>
							<th>번호</th>
							<th>제목</th>
							<th>작성자</th>
							<th>등록일</th>
						</tr>
					</thead>
					<tbody>
						{!list ||
							(list.length === 0 && (
								<tr>
									<td colSpan="4" className="text-center">
										상품 문의내역이 없습니다.
									</td>
								</tr>
							))}
						{list?.map((item, i) => (
							<ListItem key={i} {...item} virtualNo={virtualNo--} history={history} />
						))}
					</tbody>
				</table>
			</div>
			{paging && (
				<div className="paging">
					<Paging paging={paging} pageCallback={movePage} />
				</div>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	margin-top: 60px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
		margin-top: 40px;
		position: relative;
	}
	h2 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 18px;
		color: #3d3d3d;
		font-weight: normal;
		line-height: 26px;
		margin: 0px;
	}

	> div.top {
		position: relative;
		height: 26px;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: absolute;
			right: 21px;
			top: 0;
			width: 100px;
		}
	}

	> div.top > button {
		position: absolute;
		right: 0;
		top: 0;
		background: #00788d;
		border: none;
		outline: none;
		color: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		padding: 4px 18px;
		cursor: pointer;
	}

	> div.paging {
		/* temp */
		padding: 10px 0;
		text-align: center;
	}

	table {
		border-top: 1px solid #3d3d3d;
		width: 100%;
		border-spacing: 0 0;
		margin-top: 8px;

		thead {
			background: #f5f5f5;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
		}
		thead > tr > th {
			text-align: center;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 12px;
			line-height: 17px;
			padding: 9px 0;
			color: #777777;
			border-bottom: 1px solid #dfdfdf;
		}

		tbody > tr {
			cursor: pointer;
		}

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			tbody > tr.question {
				background-image: url("/icons/ic_acc_down.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_down.png", "/icons/ic_acc_down@2x.png", "/icons/ic_acc_down@3x.png"])});
				background-repeat: no-repeat;
				background-size: 10px 6px;
				background-position: right center;
			}
			tbody > tr.is-more {
				background-image: url("/icons/ic_acc_up.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_up.png", "/icons/ic_acc_up@2x.png", "/icons/ic_acc_up@3x.png"])});
			}
		}

		tbody > tr > td {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			padding: 14px 0;
			color: #3d3d3d;
			border-bottom: 1px solid #dfdfdf;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				div.date {
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 12px;
					line-height: 12px;
					color: #c1c1c1;
				}
			}

			button {
				border: 1px solid #cfcfcf;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 10px;
				line-height: 15px;
				color: #9a9a9a;
				background: #ffffff;
				padding: 5px 13px;
				margin-right: 8px;
				min-width: 54px;
			}

			button.complete {
				color: #e32222;
				background: #ffffff;
				border: 1px solid #e32222;
			}

			button.seller {
				color: #ffffff;
				background: #e32222;
				border: 1px solid #e32222;
			}
		}

		tbody > tr > td.no,
		tbody > tr > td.date {
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
		}

		tbody > tr > td.author {
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				padding-right: 21px;
			}
		}

		tbody > tr > td.text-center {
			text-align: center;
		}

		tbody > tr.answer > td:first-child {
			text-align: right;
			vertical-align: top;
			padding-right: 16px;
		}
		tbody > tr.answer > td {
			background: #f9f9f9;
			position: relative;

			div.content {
				display: flex;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: inline-flex;
					width: calc(100% - 26px);
				}
			}

			div.depth {
				width: 10px;
				height: 10px;
				background-color: #c7c7c7;
				display: inline-block;
				position: relative;

				:before {
					content: "";
					position: absolute;
					width: 9px;
					height: 9px;
					top: -1px;
					right: -1px;
					background: #f9f9f9;
				}

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: inline-block;
					vertical-align: top;
					margin: 0 8px;
				}
			}

			div.content > .button {
				flex-basis: 70px;
			}

			div.content > div.detail {
				flex: 1;
				span {
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 13px;
					line-height: 18px;
					color: #3d3d3d;
				}

				p {
					margin: 2px 0 0 0;
					padding: 0;
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 13px;
					line-height: 18px;
					color: #9a9a9a;
				}
			}
		}
	}
`;

const ListItem = (props) => {
	const [show, setShow] = useState(false);
	return (
		<>
			<tr
				onClick={() => {
					setShow(!show);
				}}
				className={cx({ pointer: true, question: true, "is-more": show })}
			>
				<td className="text-center no">{props.virtualNo}</td>
				<td>
					{props.answer ? <button className="complete">답변완료</button> : <button>미답변</button>}
					{props.title}
				</td>
				<td className="text-center author">
					{props.clientId}
					{isMobile && <div className="date">{moment(props.regDate).format("YYYY-MM-DD")}</div>}
				</td>
				<td className="text-center date">{moment(props.regDate).format("YYYY-MM-DD")}</td>
			</tr>
			{show && (
				<tr>
					<td className="no"></td>
					<td colSpan={4}>
						<div className="content">
							<div className="detail">
								<p>{props.content}</p>
							</div>
						</div>
					</td>
				</tr>
			)}
			{props.answer && show && (
				<tr className="answer">
					<td className="no">
						<div className="depth"></div>
					</td>
					<td colSpan={3}>
						{isMobile && <div className="depth"></div>}
						<div className="content">
							<div className="button">
								<button className="seller">판매자</button>
							</div>

							<div className="detail">
								{/* <span>CHANNEL</span> */}
								<p>{props.answer}</p>
							</div>
						</div>
					</td>
				</tr>
			)}
		</>
	);
};

const Form = (props) => {
	const { resolve, reject, productIdx } = props;
	const [data, setData] = useState(null);

	const onSave = () => {
		if (!data?.categoryCode) {
			swal({ title: "알림", text: "문의 유형을 선택하세요." });
			return;
		}
		if (!data?.title) {
			swal({ title: "알림", text: "제목을 입력하세요." });
			return;
		}
		if (!data?.content) {
			swal({ title: "알림", text: "문의 내용을 입력하세요." });
			return;
		}

		_API.post({ path: "/bbs/save", data: { bbsCode: "PRODUCTQNA", productIdx, ...data } }).then((res) => {
			swal({ title: "알림", text: "상품 문의가 완료 되었습니다." });
			resolve();
		});
	};

	return (
		<FWrapper>
			<UFormWrapper title={"문의유형"} labelStyles={"padding:14px 0;"} formStyles={"padding:14px 0;"}>
				{_DEF.PRODUCTQNA_CATEGORY.map((item, index) => (
					<Radio
						inline={true}
						onClick={(v) => setData({ ...data, categoryCode: v })}
						value={item.value}
						title={item.label}
						checked={data?.categoryCode === item.value}
						key={index}
						style={{ marginRight: isMobile ? "10px" : "16px" }}
					/>
				))}
			</UFormWrapper>
			<UInputForm title="제목" value={data?.title} onChange={(e) => setData({ ...data, title: e.target.value })} />
			<UFormWrapper styles={"margin-bottom:0px;"} title={"내용"} labelStyles={"padding:14px 0;"} formStyles={"padding:14px 0;"}>
				<Textarea
					value={data?.content}
					onChange={(e) => setData({ ...data, content: e.target.value })}
					placeholder="상품문의는 작성 후 수정/삭제가 불가하므로 신중하게 작성해 주세요."
				/>
			</UFormWrapper>
			<UFormWrapper labelStyles={"padding:0;"} formStyles={"padding:0;"}>
				<CheckBox title="비밀글" value="Y" checked={data?.isSecret === "Y"} onClick={() => setData({ ...data, isSecret: data?.isSecret === "Y" ? "N" : "Y" })} inline />
			</UFormWrapper>

			<div className="buttons">
				<button onClick={() => reject()}>닫기</button>
				<button className="action" onClick={onSave}>
					저장
				</button>
			</div>
		</FWrapper>
	);
};

const FWrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		> div {
			flex-direction: column;
		}
		> div > .uif-label {
			padding: 14px 0 0px !important;
		}
		> div > .uif-form {
			padding-top: 8px;

			textarea {
				width: calc(100% - 22px);
			}
		}
	}

	> div.buttons {
		margin-top: 30px;
		text-align: center;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		button {
			border: 1px solid #00788d;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #00788d;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 15px 0;
			width: 220px;

			:last-child {
				margin-left: 12px;
			}

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				width: 50%;
				:last-child {
					margin-left: 0px;
				}
			}
		}

		button.action {
			border: 1px solid #00788d;
			background: #00788d;
			color: #ffffff;
		}
	}
`;
