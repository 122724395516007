/* eslint-disable */
import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import cx from "classnames";

import { _API, _U, _CK } from "../Modules/Utils";
import { getSrcSet } from "../UI/Assets";
import { AppContext } from "../Components/AppProvider";
let menuTimer = null;

export default (props) => {
	const { history, location, match } = props;
	const { auth } = useContext(AppContext);

	const [show, setShow] = useState(false);
	const [list, setList] = useState([]);
	const [page, setPage] = useState(1);

	const loadData = () => {
		_API.get({ path: "/product/recent", data: { productList: JSON.stringify(_CK.get("RECENT-PRODUCT")) } }).then((res) => {
			setList(res.data);
		});
	};

	useEffect(() => {
		if (!auth && _CK.get("RECENT-PRODUCT") && _CK.get("RECENT-PRODUCT").length > 0) {
			loadData();
		}
	}, [auth]);

	const getList = () => {
		if (list.length > 0) {
			const tmpList = [];
			const start = page === 1 ? 0 : (page - 1) * 4;

			for (let i = start; i < page * 4; i++) {
				if (list[i] && list[i]?.idx) {
					tmpList.push(list[i]);
				}
			}

			return tmpList;
		}
	};

	const totalPage = () => {
		if (list.length === 0) {
			return;
		}

		const totalPage = list.length / 4;

		return Math.ceil(totalPage);
	};

	const handleMouseOut = () => {
		setShow(false);
	};

	const onClickRecentRemove = () => {
		setShow(true);
		swal({
			title: "알림",
			text: "최근 본 상품을 모두 삭제 하시겠습니까?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				_CK.set("RECENT-PRODUCT", "");

				const req = { path: "/product/recent/remove" };
				_API.post(req).then((res) => {
					setList([]);
					setPage(1);
				});
			}
		});
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e).then((data) => {
			switch (data.code) {
				case "HIDE-SIDEMENU":
					setShow(false);
					break;
				case "READ-RECENT-PRODUCT":
					loadData();
					break;
			}
		});
	};

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	return (
		<SMWrapper>
			<div className="recent" onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)}>
				<button>
					<img
						src={show ? "/icons/ic_recent_on.png" : "/icons/ic_recent_off.png"}
						srcSet={getSrcSet(
							show
								? ["/icons/ic_recent_on.png", "/icons/ic_recent_on@2x.png", "/icons/ic_recent_on@3x.png"]
								: ["/icons/ic_recent_off.png", "/icons/ic_recent_off@2x.png", "/icons/ic_recent_off@3x.png"]
						)}
					/>
				</button>
				<div className={cx({ "recent-list-box": true, show: show })} onMouseOver={() => setShow(true)}>
					<div className="recent-list">
						<h5>최근 본 상품</h5>
						{list?.length > 0 ? (
							<>
								{getList()?.map((item, i) => (
									<div className="recent-item" key={i}>
										<Link to={`/product/${item.code}`} onClick={() => setShow(false)}>
											<img src={item?.signedUrl} />
										</Link>
									</div>
								))}
								<div className="paging">
									<div>
										<img
											className={cx({ reverse: page > 1 })}
											src={page > 1 ? "/icons/ic_arrow_dot_on.png" : "/icons/ic_arrow_dot_off.png"}
											srcSet={getSrcSet(
												page > 1
													? ["/icons/ic_arrow_dot_on.png", "/icons/ic_arrow_dot_on@2x.png", "/icons/ic_arrow_dot_on@3x.png"]
													: ["/icons/ic_arrow_dot_off.png", "/icons/ic_arrow_dot_off@2x.png", "/icons/ic_arrow_dot_off@3x.png"]
											)}
											onClick={() => {
												if (page > 1) {
													setPage(page - 1);
												}
											}}
										/>
										<img
											className={cx({ reverse: page >= totalPage() })}
											src={page < totalPage() ? "/icons/ic_arrow_dot_on.png" : "/icons/ic_arrow_dot_off.png"}
											srcSet={getSrcSet(
												page < totalPage()
													? ["/icons/ic_arrow_dot_on.png", "/icons/ic_arrow_dot_on@2x.png", "/icons/ic_arrow_dot_on@3x.png"]
													: ["/icons/ic_arrow_dot_off.png", "/icons/ic_arrow_dot_off@2x.png", "/icons/ic_arrow_dot_off@3x.png"]
											)}
											onClick={() => {
												if (page < totalPage()) {
													setPage(page + 1);
												}
											}}
										/>
									</div>
									<p>
										{page}/<span>{totalPage()}</span>
									</p>
									<button onClick={() => onClickRecentRemove()}>전체 삭제</button>
								</div>
							</>
						) : (
							<div className="empty">최근 본 상품이 없습니다.</div>
						)}
					</div>
				</div>
			</div>
			<div className="top">
				<button onClick={() => window.scrollTo(0, 0)}>
					<img src={"/icons/ic_site_top.png"} srcSet={getSrcSet(["/icons/ic_site_top.png", "/icons/ic_site_top@2x.png", "/icons/ic_site_top@3x.png"])} />
				</button>
			</div>
		</SMWrapper>
	);
};

const SMWrapper = styled.div`
	width: 60px;
	background: #3d3d3d;
	position: fixed;
	right: 0;
	top: 500px;
	min-height: 100px;
	z-index: 11;

	div {
		width: 100%;
	}

	button {
		background: transparent;
		border: 0;
	}

	div.recent {
		padding: 20px 0;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		button > img {
			width: 32px;
			height: 32px;
		}
	}

	div.top {
		height: 70px;
		border-top: 1px solid #fcfcfc;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 29px;
			height: 32px;
		}
	}

	div.recent-list-box {
		display: none;
		background: #ffffff;
		bottom: 0;
		right: 60px;
		position: absolute;
		width: 105px;
		padding-right: 12px;
	}

	div.recent-list-box.show {
		display: block;
	}
	div.recent-list {
		width: 79px;
		height: 477px;
		border: 1px solid #3d3d3d;
		padding: 12px;

		:after,
		:before {
			left: 89%;
			bottom: 100px;
			border: solid transparent;
			content: "";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		:after {
			border-color: rgba(255, 255, 255, 0);
			border-left-color: #ffffff;
			border-width: 7px;
			margin-top: -7px;
		}
		:before {
			border-color: rgba(61, 61, 61, 0);
			border-left-color: #3d3d3d;
			border-width: 8px;
			margin-bottom: -1px;
		}

		h5 {
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			padding: 0;
			margin: 0;
			text-align: center;
			margin-bottom: 24px;
		}

		div.empty {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 13px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 433px;
		}

		div.recent-item {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 16px;

			img {
				width: 70px;
				height: 70px;
				object-fit: cover;
			}
		}

		div.paging {
			text-align: center;
			position: absolute;
			bottom: 12px;
			left: 0;
			img {
				cursor: pointer;
				:last-child {
					margin-left: 14px;
				}
			}
			img.reverse {
				transform: rotate(180deg);
			}

			p {
				padding: 0;
				margin: 12px 0 0 0;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 12px;
				color: #3d3d3d;
				span {
					color: #ababab;
				}
			}

			button {
				margin-top: 6px;
				font-family: ${(props) => props.theme.fontFamily.medium};
				font-size: 12px;
				color: #3d3d3d;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		display: none;
	}
`;
