/* eslint-disable */
import React, { Fragment, useEffect, useState, useRef } from "react";
import WebSocket from "react-websocket";

import { _CFG } from "../Modules/Config";
import { _U } from "../Modules/Utils";

export default (props) => {
	const { debug, onConnected, onDisconnected, onMessage, params } = props;
	const socket = useRef(null);
	const [isConnected, setIsConnected] = useState(false);

	const onConnect = () => {
		setIsConnected(true);
		onConnected && onConnected();
	};
	const onDisconnect = () => {
		setIsConnected(false);
		onDisconnected && onDisconnected();
	};
	const onMessageReceived = (data) => {
		if (data === "OK") {
			return;
		}

		onMessage && onMessage(data);
		try {
			const d = JSON.parse(data);
			const isLocal = _CFG.isLocal();
			if (isLocal) {
				if ("PONG" === d.data.code) {
					return;
				}
			} else {
				if ("PONG" === d.code) {
					return;
				}
			}
			_U.postMessage("SOCKET-RECEIVED", isLocal ? d.data : d);
		} catch (e) {}
	};

	const onSendMessage = (message) => {
		try {
			socket?.current?.sendMessage(message);
		} catch (e) {}
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e).then((data) => {
			switch (data.code) {
				case "SOCKET-SEND":
					onSendMessage(JSON.stringify(data.data));
					break;
			}
		});
	};

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		const timer = setInterval(() => {
			onSendMessage(JSON.stringify({ action: "PING" }));
		}, 30000);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
			clearInterval(timer);
		};
	}, []);

	return <WebSocket ref={socket} url={`${_CFG.wsHost()}&params=${_U.encode(params)}`} onOpen={onConnect} onClose={onDisconnect} onMessage={onMessageReceived} debug={debug} />;
};
