import React, { createContext, useState } from "react";

const AppContext = createContext();
const AppProvider = ({
    auth: authInProps,
    iam: iamInProps,
    snsProfile: snsProfileInProps,
    device: deviceInProps,
    version: versionInProps,
    appversion: appversionInProps,
    lang: langInProps,
    children,
}) => {
    const [auth, setAuth] = useState(authInProps);
    const [iam, setIam] = useState(iamInProps);
    const [snsProfile, setSnsProfile] = useState(snsProfileInProps);
    const [device] = useState(deviceInProps);
    const [version] = useState(versionInProps);
    const [appversion] = useState(appversionInProps);
    const [lang] = useState(langInProps);

    const saveAuth = (auth) => {
        setAuth(auth);
    };
    const saveIam = (iam) => {
        setIam(iam);
    };
    const saveSnsProfile = (profile) => {
        setSnsProfile(profile);
    };

    return <AppContext.Provider value={{ auth, saveAuth, iam, saveIam, snsProfile, saveSnsProfile, device, version, appversion, lang }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
