/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";
import CountUp from "react-countup";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import { getSrcSet } from "../../UI/Assets";

import Dialog from "../../UI/Dialog";
import Selector from "./Product.Selector";

export default (props) => {
	const [summary, setSummary] = useState(null);
	const [list, setList] = useState([]);

	const getDiscount = (item) => {
		try {
			return Math.round(item.priceDiscountPercent);
		} catch (e) {
			return item.priceDiscountPercent;
		}
	};

	const loadSummary = () => {
		_API
			.get({
				path: "/cast/channels/summary",
			})
			.then((res) => {
				const { data } = res;
				if (data) {
					setSummary(data);
				}
			});
	};

	const loadProduct = () => {
		_API
			.get({
				path: "/cast/channels/product",
			})
			.then((res) => {
				const { data } = res;
				if (data && data.length > 0) {
					setList(data);
				}
			});
	};

	useEffect(() => {
		loadSummary();
		loadProduct();
		const timer = setInterval(() => {
			loadSummary();
			loadProduct();
		}, 10000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const handleNewProduct = () => {
		Dialog({
			title: "상품 선택",
			body: <Selector />,
		}).then((idx) => {
			if (idx) {
				_API
					.post({
						path: "/cast/channels/product/save",
						data: { productIdx: idx },
					})
					.then((res) => {
						loadProduct();
					});
			}
		});
	};

	const handleOnRemove = (item) => {
		swal({
			title: "알림",
			text: "라이브 상품을 삭제 하시겠습니까??",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((result) => {
			if (result) {
				_API
					.post({
						path: "/cast/channels/product/save",
						data: { productIdx: item.idx, status: "D" },
					})
					.then((res) => {
						loadProduct();
					});
			}
		});
	};

	return (
		<Container>
			<Wrapper>
				<h3 className="title">실시간 집계정보</h3>
				<div className="informations">
					<table>
						<thead>
							<tr>
								<th>시청자</th>
								<th>구매자</th>
								<th>구매수</th>
								<th>매출</th>
								<th>장바구니</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<CountUp start={0} end={summary?.viewerCnt} duration={1} separator={","} />명
								</td>
								<td>
									<CountUp start={0} end={summary?.buyerCnt} duration={1} separator={","} />명
								</td>
								<td>
									<CountUp start={0} end={summary?.saleCnt} duration={1} separator={","} />건
								</td>
								<td>
									<CountUp start={0} end={summary?.saleAmount} duration={1} separator={","} />원
								</td>
								<td>
									<CountUp start={0} end={summary?.cartCnt} duration={1} separator={","} />건
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h3 className="title">
					라이브 상품
					<button type="button" className="add" onClick={handleNewProduct}>
						<i className="fa fa-plus"></i>
					</button>
				</h3>
				<div className="list-wrapper">
					<table>
						<thead>
							<tr>
								<th width="60">사진</th>
								<th width="10%">브랜드</th>
								<th>제품명</th>
								<th width="20%">가격</th>
								<th width="15%">장바구니/판매</th>
								<th width="15%">매출액</th>
								<th width="5%">삭제</th>
							</tr>
						</thead>
						<tbody>
							{list?.map((item, index) => (
								<tr key={index}>
									<td className="center">
										<img src={item.signedThumbnailUrl || item.signedUrl} alt="" />
									</td>
									<td className="center brand">{item.brand}</td>
									<td className="title">{item.name}</td>
									<td className="left">
										<b>{_U.addComma(item?.priceSale)}</b>원 {item?.priceDiscountPercent > 0 && <span className="discount">{getDiscount(item)}%</span>}
										{item?.priceDiscountPercent > 0 && (
											<div className="old-cost">
												<b>{_U.addComma(item.priceNormal)}</b>원
											</div>
										)}
									</td>
									<td className="center">
										<b>{_U.addComma(item.cartCnt)}</b> 건 / <b>{_U.addComma(item.saleCnt)}</b> 개
									</td>
									<td className="center">
										<b>{_U.addComma(item.saleAmount)}</b> 원
									</td>
									<td className="center">
										<button type="button" className="btn-delete" onClick={() => handleOnRemove(item)}>
											<i className="fa fa-trash"></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	flex: 1;
	background: #242424;
	border-left: #3b3b3b solid 1px;
`;
const Wrapper = styled.div`
	width: 100%;
	height: 100%;

	> .title {
		margin: 16px 20px 12px;
		padding: 0 0 0 8px;
		border-left: #acacac solid 2px;
		font-size: 16px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 24px;
		color: #fcfcfc;

		button {
			float: right;
			clear: right;

			width: 24px;
			height: 24px;
			color: #ffffff;

			background: #00788d;
			border: none;
			cursor: pointer;
		}
	}

	.informations {
		margin: 0 20px 30px;
		.sub-title {
			margin: 0 0 10px;
			padding: 0;
			font-size: 13px;
		}
		table {
			width: 100%;
			border-collapse: collapse;
			thead {
				tr {
					th {
						padding: 5px 5px;
						font-size: 18px;
						border-bottom: #4a4a4a solid 1px;
						font-size: 13px;
						line-height: 19px;
						color: #efefef;

						width: 20%;
					}
				}
			}
			tbody {
				tr {
					td {
						font-size: 18px;
						padding: 5px 15px;
						text-align: center;
						border-bottom: #4a4a4a solid 1px;
						color: #ffffff;
					}
				}
			}
		}
	}

	.list-wrapper {
		position: relative;
		margin: 0;
		padding: 0;
		width: 100%;
		height: calc(100% - 173px);

		overflow-x: hidden;
		overflow-y: auto;

		::-webkit-scrollbar {
			width: 3px;
		}
		::-webkit-scrollbar-track {
			background-color: #636e72;
			/* border-radius: 3px; */
		}
		::-webkit-scrollbar-thumb {
			background: #b2bec3;
			/* border-radius: 3px; */
		}

		table {
			margin: 0 auto;
			width: calc(100% - 40px);

			border-collapse: collapse;
			thead {
				tr {
					th {
						position: sticky;
						top: 0;
						padding: 5px 0;
						border-bottom: #4a4a4a solid 1px;
						font-family: ${(props) => props.theme.fontFamily.regular};
						font-size: 13px;
						line-height: 19px;
						color: #efefef;
					}
				}
			}

			tbody {
				tr {
					td {
						padding: 5px 5px;
						border-bottom: #4a4a4a solid 1px;
						color: #ffffff;
						font-size: 14px;

						.btn-delete {
							background: #e32222;
							color: #ffffff;
							border: none;
							border-radius: 3px;
							width: 24px;
							height: 24px;
						}

						b {
							font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
						}
						span.discount {
							font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
							color: #e32222;
							font-size: 13px;
							line-height: 15px;
						}
						div.old-cost {
							margin: 0;
							font-size: 14px;
							font-weight: normal;
							font-stretch: normal;
							text-align: left;
							text-decoration: line-through;
							color: #dedede;
							font-family: ${(props) => props.theme.fontFamily.medium};

							b {
								font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};
								font-weight: normal;
							}
						}

						img {
							width: 60px;
							height: 60px;
							object-fit: cover;
						}
					}
					td.center {
						text-align: center;
					}
					td.right {
						text-align: right;
					}
					td.brand {
						font-size: 13px;
						font-family: ${(props) => props.theme.fontFamily.regular};
						color: #b8b8b8;
					}

					td.title {
						font-size: 14px;
						font-family: ${(props) => props.theme.fontFamily.medium};
						color: #fcfcfc;
					}
				}
			}
		}
	}
`;
