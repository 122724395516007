/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { AppContext } from "../../Components/AppProvider";
import Paging, { ToPage, GetParams } from "../../UI/Paging";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { Radio } from "../../UI/Product";
import { SelectBox, Input, UInputForm, CheckBox } from "../../UI/Form";
import { SaveButton, CancelButton } from "../../UI/Button";

import Layout from "../Layout";

export default (props) => {
	const { resolve, reject, location, history, match } = props;
	// const { auth, iam } = useContext(AppContext);

	const [shippingList, setShippingList] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [modItem, setModItem] = useState({});
	const [selectedItem, setSelectedItem] = useState({});
	const [paging, setPaging] = useState(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		loadShippingList();
	}, [page]);

	const loadShippingList = () => {
		_API
			.get({
				path: "/user/shipping/list",
				data: { page, numPerPage: 5 },
			})
			.then((res) => res.data)
			.then((data) => {
				const { paging, list } = data;
				setShippingList(list);
				setPaging(paging);
			});
	};

	const movePage = (page) => {
		setPage(page);
	};

	const handleRegAddr = () => {
		setIsEdit(true);
		setModItem({});
	};

	const handleDelete = (idx) => {
		_API
			.post({
				path: "/user/shipping/delete",
				data: { idx },
			})
			.then((res) => res.data)
			.then((data) => {
				swal({ title: "알림", text: data.message, button: "확인" }).then(() => {
					loadShippingList();
				});
			});
	};

	const handelModify = (item) => {
		setIsEdit(true);
		setModItem(item);
	};

	const handleSelect = (item) => {
		// setSelectedItem(item);

		resolve(item);
	};

	return (
		<Wrapper>
			<button className="btn-add" onClick={handleRegAddr}>
				배송지 추가
			</button>
			<AddrWrapper>
				{!isEdit ? (
					<AddrListWrapper>
						<table className="table table-striped table-hover">
							<thead>
								<tr>
									<th className="text-center">배송지</th>
									<th className="text-center">주소</th>
									<th className="text-center">연락처</th>
									<th className="text-center">메인여부</th>
									<th className={cx({ "text-center": true, hidden: isEdit })}>관리</th>
									<th className={cx({ "text-center": true, hidden: isEdit })}>선택</th>
								</tr>
							</thead>
							{shippingList?.length > 0 ? (
								<tbody>
									{shippingList?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{item.name}</td>
											<td className="text-center addr">
												{item.addr} {item.addrDtl}
											</td>
											<td className="text-center">{item.mobile}</td>
											<td className="text-center">{item.isMain}</td>
											<td className={cx({ "text-center": true, btn: true, hidden: isEdit })}>
												<button onClick={(e) => handelModify(item)}>수정</button>
												<button onClick={(e) => handleDelete(item.idx)}>삭제</button>
											</td>
											<td className={cx({ "text-center": true, btn: true, hidden: isEdit })}>
												<button onClick={(e) => handleSelect(item)} className="action">
													선택
												</button>
											</td>
										</tr>
									))}
								</tbody>
							) : (
								<tbody>
									<tr>
										<td className="text-center" colSpan={6}>
											등록된 배송지가 없습니다.
										</td>
									</tr>
								</tbody>
							)}
						</table>
						{paging && (
							<div className="paging" style={{ marginTop: "10px" }}>
								<Paging paging={paging} pageCallback={movePage} />
							</div>
						)}
					</AddrListWrapper>
				) : (
					<AddrEditWrapper>
						<div>
							<MemberShippingForm setIsEdit={setIsEdit} reload={loadShippingList} item={modItem} setModItem={setModItem} />
						</div>
					</AddrEditWrapper>
				)}
			</AddrWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	> .btn-add {
		color: #005a60;
		font-size: 14px;
		font-family: ${(props) => props.theme.fontFamily.regular};
		padding: 8px 20px;
		background: transparent;
		border: 1px solid #005a60;
		position: absolute;
		top: -67px;
		right: 0;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: relative;
			float: right;
			top: 0;
		}
	}
`;

const MemberShippingForm = (props) => {
	const { setIsEdit, reload, item, setModItem } = props;
	// const { iam, auth } = useContext(AppContext);

	const [idx, setIdx] = useState(item?.idx);
	const [addrName, setAddrName] = useState(item?.name || "");
	const [receiver, setReceiver] = useState(item?.receiver || "");
	const [zipCode, setZipCode] = useState(item?.zipCode || "");
	const [addr, setAddr] = useState(item?.addr || "");
	const [addrDtl, setAddrDtl] = useState(item?.addrDtl || "");
	const [mobile, setMobile] = useState(item?.mobile || "");
	const [isMain, setIsMain] = useState(item?.isMain === "Y" ? true : false);

	const [reqCode, setReqCode] = useState(item?.reqCode || "");
	const [reqDesc, setReqDesc] = useState(item?.reqDesc || "");

	useEffect(() => {
		setIdx(item?.idx);
		setAddrName(item?.name || "");
		setReceiver(item?.receiver || "");
		setZipCode(item?.zipCode || "");
		setAddr(item?.addr || "");
		setAddrDtl(item?.addrDtl || "");
		setMobile(item?.mobile || "");
		setIsMain(item?.isMain === "Y" ? true : false);
		setReqDesc(item?.reqDesc || "");
		setReqCode(item?.reqCode || "");
	}, [item]);

	const cancel = () => {
		setIsEdit(false);
		setModItem({});
		initValue();
	};

	const initValue = () => {
		setIdx(null);
		setAddrName("");
		setReceiver("");
		setZipCode("");
		setAddr("");
		setAddrDtl("");
		setMobile("");
		setReqDesc("");
		setReqCode("");
		setIsMain(false);
	};

	const saveShippring = () => {
		let tmpReqDesc = "";
		if (reqCode) {
			tmpReqDesc = _DEF.getDefinedLabel(_DEF.DELIVERY_REQUEST, reqCode);
		}

		if (!addrName) {
			swal({ title: "알림", text: "배송지명을 입력하세요." });
			return;
		}
		if (!receiver) {
			swal({ title: "알림", text: "받으시는분을 입력하세요." });
			return;
		}
		if (!zipCode || !addr) {
			swal({ title: "알림", text: "주소 찾기를 통해 주소를 입력하세요." });
			return;
		}
		if (!addrDtl) {
			swal({ title: "알림", text: "상세 주소를 입력하세요." });
			return;
		}
		if (!mobile) {
			swal({ title: "알림", text: "연락처를 입력하세요." });
			return;
		}

		if (!reqCode && !reqDesc) {
			swal({ title: "알림", text: "요청사항을 입력하세요." });
			return;
		}

		_API
			.post({
				path: "/user/shipping/save",
				data: {
					idx,
					name: addrName,
					receiver,
					zipCode,
					addr,
					addrDtl,
					mobile,
					isMain: isMain ? "Y" : "N",
					reqCode,
					reqDesc: tmpReqDesc || reqDesc,
				},
			})
			.then((res) => res.data)
			.then((data) => {
				swal({ title: "알림", text: data.message, button: "확인" }).then(() => {
					setIsEdit(false);
					initValue();
					reload();
				});
			});
	};

	const onAddrOpen = () => {
		new daum.Postcode({
			oncomplete: (data) => {
				const address = {
					zipCode: data.zonecode,
					addr: data.address,
					addrEng: data.addressEnglish,
				};
				setAddr(address?.addr);
				setZipCode(address?.zipCode);
			},
		}).open();
	};

	const phoneNumberHandler = (inputValue) => {
		setMobile(inputValue);
	};

	// useEffect(() => {
	// 	if (mobile.length == 10) {
	// 		setMobile(mobile.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
	// 	}
	// 	if (mobile.length === 13) {
	// 		setMobile(mobile.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
	// 	}
	// }, [mobile]);

	useEffect(() => {
		if (reqCode) {
			setReqDesc("");
		}
	}, [reqCode]);

	const sameOrdererInfo = () => {};

	return (
		<FormWrapper>
			<Row>
				<div className="d-title">배송 정보</div>
				{/* <div className="d-sub-title" onClick={() => sameOrdererInfo()}>
					<i className="fa fa-check-circle-o"></i>
					주문자 정보와 동일
				</div> */}
				<CheckBox title="메인 주소지" checked={isMain} onClick={(e) => setIsMain(!isMain)} />
			</Row>
			<UInputForm className="bg-none" placeholder="배송지명" type="text" value={addrName} onChange={(e) => setAddrName(e.target.value)} />
			<UInputForm className="bg-none" placeholder="받으시는분" type="text" value={receiver} onChange={(e) => setReceiver(e.target.value)} />
			<Row className="buttons">
				<button onClick={onAddrOpen} className="search-addr-btn">
					주소찾기
				</button>
				<UInputForm className="zip-code" placeholder="우편번호" type="text" value={zipCode} readOnly onClick={onAddrOpen} style={{ margin: "unset", background: "black" }} />
			</Row>
			<UInputForm placeholder="주소를 입력하세요." type="text" value={addr} readOnly onClick={onAddrOpen} />
			<UInputForm className="bg-none" placeholder="상세주소를 입력하세요." type="text" value={addrDtl} onChange={(e) => setAddrDtl(e.target.value)} />
			<UInputForm className="bg-none" placeholder="연락처" maxLength={13} value={mobile} onChange={(e) => phoneNumberHandler(e.target.value)} />
			<Row>
				<SelectBox items={_DEF.DELIVERY_REQUEST} value={reqCode} onChange={(e) => setReqCode(e.target.value)} />
			</Row>
			{(reqDesc || !reqCode) && (
				<UInputForm className="bg-none" placeholder="요청사항을 입력하세요." type="text" value={reqDesc || ""} onChange={(e) => setReqDesc(e.target.value)} />
			)}

			{/* <div className="text-right">
				
			</div>
			<UInputForm type="text" value={addrName} onChange={(e) => setAddrName(e.target.value)} />
			<UInputForm title="받으시는분" type="text" value={receiver} onChange={(e) => setReceiver(e.target.value)} />
			<UInputForm title="우편번호" type="text" value={zipCode} readOnly onClick={onAddrOpen} />
			<UInputForm title="주소" type="text" value={addr} readOnly onClick={onAddrOpen} />
			<UInputForm title="상세 주소" type="text" value={addrDtl} onChange={(e) => setAddrDtl(e.target.value)} />
			<UInputForm title="연락처" type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} />
			<UInputForm title="배송 요청사항" type="text" value={reqDesc} onChange={(e) => setReqDesc(e.target.value)} /> */}

			<div className="buttons" style={{ marginTop: "20px" }}>
				<button onClick={cancel}>취소</button>
				<button onClick={saveShippring} className="action">
					저장
				</button>
			</div>
		</FormWrapper>
	);
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 13px;
	gap: 15px;
	align-items: center;
	margin: 0 0 12px;

	i {
		font-size: 20px;
		margin-right: 5px;
	}

	.search-addr-btn {
		height: 43px;
	}

	.zip-code {
		input {
			cursor: pointer;
		}
	}

	.d-title {
		font-size: 25px;
	}

	.d-sub-title {
		font-size: 14px;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #777777;
	}
`;

const AddrWrapper = styled.div`
	clear: both;
	display: flex;
	width: 100%;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		flex-direction: column-reverse;
	}
`;

const AddrListWrapper = styled.div`
	flex-grow: 1;

	table {
		border-top: 1px solid #3d3d3d;
		width: 100%;
		border-spacing: 0 0;
		margin-top: 8px;
	}

	thead {
		background: #f5f5f5;
	}
	thead > tr > th {
		text-align: center;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 12px;
		line-height: 17px;
		padding: 9px 0;
		color: #777777;
		border-bottom: 1px solid #dfdfdf;
	}

	th.hidden,
	td.hidden {
		display: none;
	}

	tbody > tr > td {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		padding: 14px 0;
		color: #3d3d3d;
		border-bottom: 1px solid #dfdfdf;

		button {
			color: #777777;
			font-size: 13px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			padding: 8px 11px;
			background: transparent;
			border: 1px solid #777777;
			margin: 0 2px;
		}

		button.action {
			border: 1px solid #005a60;
			color: #005a60;
		}
	}

	tbody > tr > td.text-center {
		text-align: center;
	}

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		table {
			border-top: none;
		}
		table thead {
			display: none;
		}
		table tbody > tr {
			display: block;
			margin-bottom: 10px;
			border-top: none;
		}

		table > tbody > tr {
			border-top: 1px solid #3d3d3d;
		}

		table > tbody > tr > td {
			display: block;
			position: relative;
			padding: 14px 0 14px 36%;
			border-width: 0 0 1px 0;
			text-align: left !important;

			button {
				padding: 3px 11px;
			}
		}

		table > tbody > tr > td.btn {
			padding: 10px 0 10px 36%;
		}

		table > tbody > tr > td.addr {
			padding: 34px 0 34px 36%;
		}

		table > tbody > tr > td:before {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 30%;
			padding: 14px 0 14px 10px;
			background: #f5f5f5;
		}

		table > tbody > tr > td.addr:before {
			padding: 34px 0 34px 10px;
		}

		table > tbody > tr > td:nth-child(1):before {
			content: "배송지";
		}
		table > tbody > tr > td:nth-child(2):before {
			content: "주소";
		}
		table > tbody > tr > td:nth-child(3):before {
			content: "연락처";
		}
		table > tbody > tr > td:nth-child(4):before {
			content: "메인여부";
		}
		table > tbody > tr > td:nth-child(5):before {
			content: "관리";
		}
		table > tbody > tr > td:nth-child(6):before {
			content: "선택";
		}
	}
`;
const AddrEditWrapper = styled.div`
	flex-grow: 1;
	padding-left: 30px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-left: 0;
	}
`;
const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		border-top: 1px solid #3d3d3d;
		padding-top: 24px;
		margin: 16px 0;
	}

	.bg-none {
		input,
		.uif-input {
			background: none !important;
		}
	}

	> div.text-right {
		text-align: right;
		padding: 0 0 20px;
	}

	div.buttons {
		text-align: center;
		width: 100%;

		button {
			color: #777777;
			font-size: 14px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			padding: 8px 20px;
			background: transparent;
			border: 1px solid #777777;
			margin-right: 5px;
		}

		button.action {
			border: 1px solid #005a60;
			color: #005a60;
		}
	}
`;
