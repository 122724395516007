/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../Components/AppProvider";
import { isMobile } from "react-device-detect";
import moment from "moment";

import styled from "styled-components";
import { Link } from "react-router-dom";

import { getSrcSet, getBackgroundSet } from "../UI/Assets";

import { _CK, _API, _U } from "../Modules/Utils";
import swal from "sweetalert";

export default (props) => {
    const { location, history, match, isDark, hideTop } = props;
    const { auth, iam, saveAuth, saveIam } = useContext(AppContext);

    const [keyword, setKeyword] = useState("");

    const handleLocateWithLogin = (path, e) => {
        e.preventDefault();
        if (!auth) {
            _U.postMessage("LOGIN");
        } else {
            if (isMobile) {
                if (path === "/user/my") {
                    history.push(`/mypage`);
                } else {
                    history.push(path);
                }
            } else {
                history.push(path);
            }
        }
    };

    const onSearch = () => {
        if (!keyword) {
            swal({ title: "알림", text: "검색어를 입력하세요." });
            return;
        }

        onSetCookie(keyword);

        history.push(`/search/${encodeURIComponent(keyword)}`);
    };

    const onSetCookie = (keyword) => {
        const searchKeyword = _CK.get("searchKeyword") || [];
        searchKeyword.unshift(keyword);

        const tmp = searchKeyword.filter((element, index) => {
            return searchKeyword.indexOf(element) === index;
        });

        _CK.set("searchKeyword", tmp, 1);
    };

    /*
	const handleStreamStart = () => {
		const data = {
			title: `${iam?.name} 생성 방송 - ${moment().format("YYYY.MM.DD HH:mm")}`,
			preSdate: moment().add(1, "m").format("YYYY-MM-DD HH:mm:ss"),
			preEdate: moment().add(5, "h").format("YYYY-MM-DD HH:mm:ss"),
		};

		_API
			.post({
				path: "/stream",
				data,
			})
			.then((res) => {
				console.log(res);
				if (res.error === 0) {
					const { streamIdx, streamId } = res.data;
					if (isMobile) {
						history.push(`/streamer/controller/${streamIdx}/${streamId}`);
					} else {
						history.push(`/streamer/${streamIdx}`);
					}
				}
			});
	};
	*/

    useEffect(() => {
        const { keyword } = match.params;
        setKeyword(keyword);
    }, []);

    return (
        <Container isDark={isDark} hideTop={hideTop}>
            {isMobile && isDark && <div className="top-bg"></div>}
            <Wrapper>
                <div className="logo">
                    <Link to="/">
                        {isMobile && isDark ? (
                            <img src="/images/logo_white.png" srcSet={getSrcSet(["/images/logo_white.png", "/images/logo_white@2x.png", "/images/logo_white@3x.png"])} alt="MOAA" />
                        ) : (
                            <img src="/images/logo.png" srcSet={getSrcSet(["/images/logo.png", "/images/logo@2x.png", "/images/logo@3x.png"])} alt="MOAA" />
                        )}
                    </Link>
                </div>
                <div className="search">
                    <input
                        type="text"
                        value={keyword || ""}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                onSearch();
                            }
                        }}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder=""
                    />
                    <button type="button" onClick={onSearch}>
                        <img
                            src="/icons/ic_top_search.png"
                            width="20"
                            height="20"
                            srcSet={getSrcSet(["/icons/ic_top_search.png", "/icons/ic_top_search@2x.png", "/icons/ic_top_search@3x.png"])}
                            alt="검색"
                        />
                    </button>
                </div>
                <div className="actions">
                    {iam?.isStreamer === "Y" && (
                        <>
                            <a href="/#/cast" target="_blank">
                                {isMobile && isDark ? (
                                    <img
                                        src="/icons/ic_live_white.png"
                                        width="22"
                                        height="22"
                                        srcSet={getSrcSet(["/icons/ic_live_white.png", "/icons/ic_live_white@2x.png", "/icons/ic_live_white@3x.png"])}
                                        alt="방송"
                                    />
                                ) : (
                                    <img src="/icons/ic_live.png" width="32" height="32" srcSet={getSrcSet(["/icons/ic_live.png", "/icons/ic_live@2x.png", "/icons/ic_live@3x.png"])} alt="방송" />
                                )}
                            </a>
                        </>
                    )}
                    {!isMobile && (
                        <a href={void 0} onClick={handleLocateWithLogin.bind(this, "/cart")}>
                            {/* {isMobile && isDark ? (
							<img
								src="/icons/ic_top_bag_white.png"
								width="30"
								height="27"
								srcSet={getSrcSet(["/icons/ic_top_bag_white.png", "/icons/ic_top_bag_white@2x.png", "/icons/ic_top_bag_white@3x.png"])}
								alt="장바구니"
							/>
						) : ( */}
                            <img
                                src="/icons/ic_top_bag.png"
                                width="32"
                                height="32"
                                srcSet={getSrcSet(["/icons/ic_top_bag.png", "/icons/ic_top_bag@2x.png", "/icons/ic_top_bag@3x.png"])}
                                alt="장바구니"
                            />
                            {/* )} */}
                        </a>
                    )}

                    <a href={void 0} onClick={handleLocateWithLogin.bind(this, "/like")}>
                        {isMobile && isDark ? (
                            <img
                                src="/icons/ic_top_heart_white.png"
                                width="32"
                                height="28"
                                srcSet={getSrcSet(["/icons/ic_top_heart_white.png", "/icons/ic_top_heart_white@2x.png", "/icons/ic_top_heart_white@3x.png"])}
                                alt="찜한상품"
                            />
                        ) : (
                            <img
                                src="/icons/ic_top_heart.png"
                                width="32"
                                height="32"
                                srcSet={getSrcSet(["/icons/ic_top_heart.png", "/icons/ic_top_heart@2x.png", "/icons/ic_top_heart@3x.png"])}
                                alt="찜한상품"
                            />
                        )}
                    </a>
                    <a href={void 0} onClick={handleLocateWithLogin.bind(this, "/user/my")}>
                        {isMobile && isDark ? (
                            <img
                                src="/icons/ic_top_man_white.png"
                                width="27"
                                height="28"
                                srcSet={getSrcSet(["/icons/ic_top_man_white.png", "/icons/ic_top_man_white@2x.png", "/icons/ic_top_man_white@3x.png"])}
                                alt="회원"
                            />
                        ) : (
                            <img src="/icons/ic_top_man.png" width="32" height="32" srcSet={getSrcSet(["/icons/ic_top_man.png", "/icons/ic_top_man@2x.png", "/icons/ic_top_man@3x.png"])} alt="회원" />
                        )}
                    </a>
                </div>
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    position: sticky;
    top: 0;
    z-index: 10;
    ${(props) => (isMobile ? `` : `background:#ffffff;`)}

    .top-bg {
        background-image: url("/images/mobile_top_bg.png");
        background-image: -webkit-image-set(${getBackgroundSet(["/images/mobile_top_bg.png", "/images/mobile_top_bg@2x.png", "/images/mobile_top_bg@3x.png"])});
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 172px;
    }

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        position: sticky;
        top: 0;
        z-index: 3;

        ${(props) => (!props.isDark ? `background:#ffffff;` : ``)}
        ${(props) => (props.hideTop ? `display:none;` : ``)}
    }
`;
const Wrapper = styled.div`
    margin: 24px auto 0;
    width: ${(props) => props.theme.layout.width}px;
    display: flex;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        padding: 16px 0;
        margin: 0;
        position: relative;
        z-index: 3;
    }

    .logo {
        padding: 2px 0;
        flex: 1;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 0 0 16px;
        }
    }
    .logo img {
        width: 190px;
        height: 40px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 106px;
            height: 23px;
        }
    }
    .search {
        position: relative;
        flex-basis: 582px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }
    .search input {
        padding: 0 50px 0 23px;
        width: 505px;
        height: 41px;
        border: #ebebeb solid 2px;
        border-radius: 23px;
    }
    .search button {
        position: absolute;
        width: 40px;
        height: 40px;
        right: 12px;
        top: 3px;
        background: transparent;
        border: none;
        cursor: pointer;
    }
    .actions {
        padding-top: 4.5px;
        text-align: right;
        flex: 1;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 16px 0 0;
        }
    }

    .actions a {
        margin-right: 40px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-right: 12px;
            img {
                width: 22px !important;
                height: 22px !important;
            }
        }
    }
    .actions a:last-child {
        margin-right: 0px;
    }
`;
