/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import { AppContext } from "../Components/AppProvider";
import { _CK, _API } from "../Modules/Utils";

import HomeView from "./Home";
import { ProductListView, ProductItemView, CategoryViewProductListView } from "./Product";
import ReviewsView from "./Reviews";
import CMSView from "./CMS";
import BrandView from "./Brand";
import MobileSearchView from "./MobileSearch";
import MobileCategoryView from "./MobileCategory";
import RakingView from "./Ranking";

import {
	UserSignUpView,
	UserFindIdPwView,
	UserMyPageOrderView,
	UserMyPageReviewView,
	UserMyPageInfoView,
	UserMyPageWithdrawalView,
	UserMyFaqView,
	UserMyQnaView,
	UserMyProductQnaView,
	MobileMypageView,
} from "./User";

import { MyRequestAsListView, MyRequestAsFormView } from "./User";

import { CartView, CheckoutView, CheckoutFinishView, OrderDetailView, LikeView, CheckoutKCPView } from "./Order";
import { CallbackNaverLoginView } from "./Callback";

import StreamerView, { StreamerLiveView, StreamerLiveController } from "./Streamer";
import StreamerCameraLiveView from "./Streamer/camList";
import { LiveView } from "./Live";
import Live2View from "./Live2";

import CastView from "./Cast";

import { PaymentView, PaymentCompleteView, PaymentCancelView, CertCompleteView, CertCancelView } from "./Payletter";
import { ServiceFaqView, ServiceNoticeView, ServiceMenuView, ServiceAsView } from "./Service";
import { ServiceReturnListView, ServiceReturnFormView } from "./Service";

export default (props) => {
	return (
		<Router>
			<Switch>
				<Route path="/" exact component={HomeView} />
				<Route path="/category/:idx" component={ProductListView} />
				<Route path="/categoryview/:idx" component={CategoryViewProductListView} />
				<Route path="/search/:keyword" component={ProductListView} />
				<Route path="/search" component={ProductListView} />
				<Route path="/find" component={MobileSearchView} />
				<Route path="/mypage" component={MobileMypageView} />
				<Route path="/ranking" component={RakingView} />
				<Route path="/categorys" component={MobileCategoryView} />

				<Route path="/product/:code" component={ProductItemView} />
				<Route path="/reviews" exact component={ReviewsView} />
				<Route path="/cart" exact component={CartView} />
				{/* <Route path="/checkout" exact component={CheckoutView} /> */}
				<Route path="/checkout" exact component={CheckoutKCPView} />
				<Route path="/checkout/finish/:orderIdx" exact component={CheckoutFinishView} />
				{/* <Route path="/checkout/:orderIdx" exact component={CheckoutView} /> */}
				<Route path="/checkout/:orderIdx" exact component={CheckoutKCPView} />
				<Route path="/order/detail/:orderIdx" exact component={OrderDetailView} />
				<Route path="/like" exact component={LikeView} />
				<Route path="/brand" exact component={BrandView} />
				<Route path="/user">
					<Route path="/user/my">
						<Route path="/user/my" exact component={UserMyPageOrderView} />
						<Route path="/user/my/order" exact component={UserMyPageOrderView} />
						<Route path="/user/my/review" exact component={UserMyPageReviewView} />
						<Route path="/user/my/info" exact component={UserMyPageInfoView} />
						<Route path="/user/my/withdrawal" exact component={UserMyPageWithdrawalView} />
						<Route path="/user/my/bbs/faq" exact component={UserMyFaqView} />
						<Route path="/user/my/bbs/qna" exact component={UserMyQnaView} />
						<Route path="/user/my/bbs/productqna" exact component={UserMyProductQnaView} />
						<Route path="/user/my/request/as" exact component={MyRequestAsListView} />
						<Route path="/user/my/request/as/:asIdx/:orderIdx/:productIdx" exact component={MyRequestAsFormView} />
						<Route path="/user/my/request/return" exact component={ServiceReturnListView} />
						<Route path="/user/my/request/return/:returnIdx/:orderIdx/:productIdx" exact component={ServiceReturnFormView} />
					</Route>

					<Route path="/user/signup" exact component={UserSignUpView} />
					<Route path="/user/findIdPw" exact component={UserFindIdPwView} />
				</Route>
				<Route path="/service">
					<Route path="/service/faq" component={ServiceFaqView} />
					<Route path="/service/notice" component={ServiceNoticeView} />
					<Route path="/service/menu" component={ServiceMenuView} />
					<Route path="/service/as" component={ServiceAsView} />
					<Route path="/service/return/:returnIdx/:orderIdx/:productIdx" exact component={ServiceReturnFormView} />
				</Route>
				<Route path={["/cms/:code1", "/cms/:code1/:code2", "/cms/:code1/:code2/:code3"]} exact component={CMSView} />
				<Route path="/streamer">
					<Route path="/streamer" exact component={StreamerView} />
					<Route path="/streamer/cameras/:idx" exact component={StreamerCameraLiveView} />
					<Route path="/streamer/controller/:idx/:streamIdx" exact component={StreamerLiveController} />
					<Route path="/streamer/:idx" exact component={StreamerLiveView} />
				</Route>
				<Route path="/cast" exact component={CastView} />
				<Route path="/live">
					<Route path="/live/:idx" component={LiveView} />
				</Route>
				<Route path="/live2">
					<Route path="/live2/:idx" component={Live2View} />
				</Route>

				<Route path="/callback">
					<Route path="/callback/login/naver" component={CallbackNaverLoginView} />
				</Route>

				<Route path="/payletter">
					<Route path="/payletter/payment" exact component={PaymentView} />
					<Route path="/payletter/payment/close" exact component={PaymentCompleteView} />
					<Route path="/payletter/payment/cancel" exact component={PaymentCancelView} />
					<Route path="/payletter/cert/close" exact component={CertCompleteView} />
					<Route path="/payletter/cert/cancel" exact component={CertCancelView} />
				</Route>

				<Redirect path="*" to="/" />
			</Switch>
		</Router>
	);
};
