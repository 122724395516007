/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
// import Slider from "react-slick";
import Slider from "react-slick-ios-fix";

import moment from "moment";
import nl2br from "nl2br";
import { isMobile } from "react-device-detect";

import { _API, _U } from "../../Modules/Utils";

import { _DEF } from "../../Modules/Config";
import { getSrcSet } from "../../UI/Assets";
import { Radio, Textarea } from "../../UI/Form";
import swal from "sweetalert";

export default (props) => {
	const { item, history, bbsIdx, resolve, reject } = props;

	const [category, setCategory] = useState("");
	const [content, setContent] = useState("");

	const onSave = () => {
		if (!category) {
			swal({ title: "알림", text: "신고 유형을 선택해 주세요." });
			return;
		}

		if (!content) {
			swal({ title: "알림", text: "신고 사유를 입력해 주세요." });
			return;
		}

		_API.post({ path: "/bbs/report/save", data: { bbsIdx, category, content } }).then((res) => {
			swal({ title: "알림", text: "상품 문의가 완료 되었습니다." });
			resolve("Y");
		});
	};

	return (
		<RWrapper>
			<h3>신고유형</h3>
			<div className="box">
				{_DEF.REVIEW_REPORT_CATEGORY.map((item, index) => (
					<Radio onClick={(v) => setCategory(v)} value={item.value} title={item.label} checked={category === item.value} key={index} />
				))}
			</div>
			<h3>신고 사유</h3>
			<Textarea
				value={content}
				onChange={(e) => setContent(e.target.value)}
				placeholder="위 신고 유형에 해당하지 않거나 추가로 신고하고자 하는 내용을 적어주세요."
				rows={10}
				styles={{ borderColor: "#dfdfdf", color: "#3d3d3d" }}
			/>
			<p>• 신고해 주신 내용은 내부 정책에 의거하여 검토 후 신고가 적절하다고 판단되는 경우에는 리뷰 내에서 즉시 삭제처리됩니다.</p>
			<div className="buttons">
				<button onClick={() => reject()}>닫기</button>
				<button className="action" onClick={onSave}>
					신고 하기
				</button>
			</div>
		</RWrapper>
	);
};

const RWrapper = styled.div`
	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 13px;
		line-height: 20px;
		color: #3d3d3d;
		margin: 0 0 8px 0;
		padding: 0;
	}

	> div.box {
		border: 1px solid #dfdfdf;
		padding: 16px;
		margin-bottom: 16px;
		> div {
			margin-bottom: 8px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			line-height: 14px;
			color: #777777;
			:last-child {
				margin-bottom: 0;
			}
		}
	}

	p {
		padding: 0;
		margin: 0;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 14px;
		color: #b8b8b8;
		line-height: 20px;
	}

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		overflow-y: auto;
		padding-bottom: 60px;
		textarea {
			width: 100%;
			width: calc(100% - 22px);
		}
	}

	> div.buttons {
		margin-top: 30px;
		text-align: center;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		button {
			border: 1px solid #00788d;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #00788d;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 15px 0;
			width: 220px;

			:last-child {
				margin-left: 12px;
			}

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				width: 50%;
				:last-child {
					margin-left: 0px;
				}
			}
		}

		button.action {
			border: 1px solid #00788d;
			background: #00788d;
			color: #ffffff;
		}
	}
`;
