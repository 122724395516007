/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import Moment from "react-moment";
import cx from "classnames";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

import Camera from "./Live.Controller.Sender.Camera";
import { getSrcSet, getBackgroundSet } from "../../UI/Assets";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";

const _CANVAS_ID = "LIVE-STREAM-CANVAS";

export default (props) => {
	const { roomIdx, roomData, streamIdx: streamId, history, match } = props;
	const videoRef = useRef(null);

	const [devices, setDevices] = useState(null);
	const [videoId, setVideoId] = useState(null);
	const [audioId, setAudioId] = useState(null);
	const [stream, setStream] = useState(null);

	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	const [adapter, setAdapter] = useState(null);
	const [streamTime, setStreamTime] = useState(null);
	const [isStream, setIsStream] = useState(false);

	const [cameraMore, setCameraMore] = useState(false);
	const [audioMore, setAudioMore] = useState(false);

	const isMainChannel = () => {
		if (roomData) {
			const channel = roomData?.channels?.filter((v) => {
				return v.streamId == streamId;
			});
			if (channel[0].type === "MAIN") {
				return true;
			}
			return false;
		}
	};

	const handleToggleStream = () => {
		if (isStream) {
			stopStreaming();
		} else {
			startStreaming();
		}
	};

	const startStreaming = () => {
		startWebRTCStreaming(stream, streamId, null, roomIdx, isMainChannel()).then((adapter) => {
			setAdapter(adapter);
		});
	};

	const stopStreaming = () => {
		stopWebRTCStreaming(adapter, streamId);
	};

	const setStreamStatus = (idx, status) => {
		_API
			.post({
				path: "/stream/status",
				data: { idx, status },
			})
			.then((res) => res.data)
			.then((data) => {
				console.log(data);
			});
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e).then((data) => {
			switch (data.code) {
				case "STREAMING-STARTED":
					setStreamTime(moment(new Date()));
					setIsStream(true);

					if (data.data.isMain) {
						setStreamStatus(data.data.roomIdx, "Y");
					}
					break;
				case "STREAMING-ENDED":
					setStreamTime(null);
					setIsStream(false);

					if (data.data.isMain) {
						setStreamStatus(data.data.roomIdx, "R");
					}
					break;
				case "STREAMING-ERROR":
					if (e.data) {
						const err = JSON.parse(e.data);
						switch (err.data) {
							case "streamIdInUse":
								swal({ title: "오류", text: "이미 다른 기기에서 송출 중입니다." });
								break;
							default:
								swal({ title: "오류", text: e.message || e.name || e.data });
								break;
						}
					} else {
						swal({ title: "오류", text: e.message || e.name || e.data });
					}
					break;
			}
		});
	};

	const handleBackButtonClicked = () => {
		if (adapter && streamId) stopStreaming();
		history.goBack(); /* `${document.location.origin}/#/streamer/cameras/${roomIdx}`; */
	};

	useEffect(() => {
		getDevices()
			.then((devices) => setDevices(devices))
			.catch((e) => {
				swal({
					title: "알림",
					text: e.message || e.name || e.data,
				});
			});
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	useEffect(() => {
		if (devices !== null) {
			const { video, audio } = devices;
			if (video.length > 0) {
				setVideoId(video[0].deviceId);
			}
			if (audio.length > 0) {
				setAudioId(audio[0].deviceId);
			}
		}
	}, [devices]);

	useEffect(() => {
		const playing = () => {
			setWidth(videoRef.current.videoWidth);
			setHeight(videoRef.current.videoHeight);
		};
		videoRef.current.addEventListener("playing", playing);

		if (videoId && audioId) {
			getStream(videoId, audioId)
				.then((stream) => {
					setStream(stream);
					videoRef.current.srcObject = stream;
				})
				.catch((e) => console.log(e));
		}
		return () => {
			videoRef.current.removeEventListener("playing", playing);
		};
	}, [videoId, audioId]);

	const getDeviceName = () => {
		const result = devices.video.findIndex((item) => {
			return item.deviceId === videoId;
		});

		if (result < 0) {
			return `CAMERA #1`;
		} else {
			return `CAMERA #${result + 1}`;
		}
	};

	return (
		<Container>
			<Video ref={videoRef} autoPlay playsInline muted />
			{devices && (
				<Wrapper>
					<Device>
						<button type="button" className={cx({ start: isStream })} onClick={handleBackButtonClicked}>
							<img src={"/icons/ic_camera_exit.png"} srcSet={getSrcSet(["/icons/ic_camera_exit.png", "/icons/ic_camera_exit@2x.png", "/icons/ic_camera_exit@3x.png"])} />
						</button>
						{!isStream && (
							<>
								{/* <select value={videoId || ""} onChange={(e) => setVideoId(e.target.value)}>
									{devices.video.map((item, i) => (
										<option key={i} value={item.deviceId}>
											{`Camera #${i + 1}`}
										</option>
									))}
								</select> */}
								{/*<select value={audioId || ""} onChange={(e) => setAudioId(e.target.value)} className="audio">
									{devices.audio.map((item, i) => (
										<option key={i} value={item.deviceId}>
											{item.label}
										</option>
									))}
								</select> */}

								<div className={cx({ select: true, active: cameraMore })} onClick={() => setCameraMore(!cameraMore)}>
									&nbsp;{getDeviceName()}
									<img
										src={"/icons/ic_arrow_down_white.png"}
										srcSet={getSrcSet(["/icons/ic_arrow_down_white.png", "/icons/ic_arrow_down_white@2x.png", "/icons/ic_arrow_down_white@3x.png"])}
									/>
									{cameraMore && devices.video.length > 0 && (
										<ul>
											{devices.video.map((item, i) => (
												<li key={i} onClick={(e) => setVideoId(item.deviceId)}>{`CAMERA #${i + 1}`}</li>
											))}
										</ul>
									)}
								</div>

								<div className="info">
									{width}x{height}
								</div>
								{/* <div className={cx({ select: true, active: audioMore })} onClick={() => setAudioMore(!audioMore)}>
									AUDIO 1
									<img
										src={"/icons/ic_arrow_down_white.png"}
										srcSet={getSrcSet(["/icons/ic_arrow_down_white.png", "/icons/ic_arrow_down_white@2x.png", "/icons/ic_arrow_down_white@3x.png"])}
									/>
								</div> */}
							</>
						)}
					</Device>
					{/* {!isStream && (
						<Info>
							{width}x{height}
						</Info>
					)} */}
					{stream && (
						<Recording>
							<button type="button" className={cx({ send: isStream })} onClick={handleToggleStream}>
								{isStream ? "방송중지" : "방송시작"}
								{streamTime && <Moment date={streamTime} format="  (HH:mm:ss)" interval={1000} durationFromNow />}
							</button>
						</Recording>
					)}
				</Wrapper>
			)}

			{/* <CloseWrapper>
                <button type="button" className={cx({ start: isStream })} onClick={handleBackButtonClicked} style={{ width: "100px" }}>
                    <img src={"/icons/ic_camera_exit.png"} srcSet={getSrcSet(["/icons/ic_camera_exit.png", "/icons/ic_camera_exit@2x.png", "/icons/ic_camera_exit@3x.png"])} />
                </button>
            </CloseWrapper> */}
		</Container>
	);
};

/**
 * Styled
 */

const Container = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 10;
`;

const CloseWrapper = styled.div``;

const Video = styled.video`
	width: 100%;
	height: 100%;
	background: #000000;
`;

const Device = styled.div`
	position: fixed;
	top: 5px;
	left: 15px;
	right: 15px;
	display: flex;

	select {
		padding: 5px 10px;
		opacity: 0.7;
		flex: 1;
		margin-right: 15px;
	}

	select.audio {
		display: none;
	}

	button {
		margin: 0;
		padding: 0;
		width: 50px;
		background: transparent;
		border: none;
		position: fixed;
		right: 0;
		top: 15px;
	}

	div.select {
		border-bottom: 1px solid #ffffff;
		height: 29px;
		/* padding-bottom: 6px; */
		flex-basis: 60%;
		/* margin-right: 10px; */
		color: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 20px;
		line-height: 29px;
		position: relative;
		background: #000000;
		padding: 5px 0 6px 0px;

		/* :last-child {
			margin-left: 10px;
			margin-right: 0;
		} */

		img {
			position: absolute;
			right: 10px;
			top: 15px;
			width: 14px;
			height: 8px;
		}
		> ul {
			list-style: none;
			padding: 10px 0 0;
			margin: 7px 0 0;
			background: #000000;
		}
		> ul > li {
			padding: 5px 0 5px 10px;
		}
	}

	div.select.active {
		img {
			transform: rotate(180deg);
		}
	}

	div.info {
		flex: 1;
		display: flex;
		align-items: center;
		color: #ffffff;
		text-shadow: 1px 1px 2px black;
		text-align: right;
		padding-left: 20px;
	}
`;

const Info = styled.div`
	position: fixed;
	top: 15px;
	left: 15px;
	color: #ffffff;
	text-shadow: 1px 1px 2px black;
	text-align: right;
`;

const Recording = styled.div`
	position: fixed;
	padding: 15px;
	bottom: 25px;
	left: 0;
	right: 0;
	text-align: center;

	button {
		padding: 10px 25px;
		font-size: 14px;
		line-height: 20px;
		border: solid 1px #e32222;
		color: #e32222;
		background-color: #fff;
		border-radius: 20px;
		font-family: ${(props) => props.theme.fontFamily.regular};
	}

	button.send {
		color: #fff;
		background-color: #e32222;
	}
`;

/**
 * Functions
 */
const getDevices = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			if (!navigator.mediaDevices) {
				throw { message: "장치 정보를 읽어올 수 없습니다." };
			}
			const devices = await navigator.mediaDevices.enumerateDevices();
			const video = [];
			const audio = [];
			for (let i in devices) {
				const d = devices[i];
				switch (d.kind) {
					case "videoinput":
						video.push(d);
						break;
					case "audioinput":
						audio.push(d);
						break;
				}
			}

			resolve({ video, audio });
		} catch (e) {
			reject(e);
		}
	});
};

const getStream = (videoId, audioId) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (!videoId || !audioId) {
				const devices = await getDevices();
				if (!videoId) {
					if (devices.video.length === 0) {
						throw { message: "인식된 영상 입력이 없습니다." };
					}
					videoId = devices.video[0].groupId;
				}
				if (!audioId) {
					if (devices.audio.length === 0) {
						throw { message: "인식된 음성 입력이 없습니다." };
					}
					audioId = devices.audio[0].groupId;
				}
			}

			const constraints = {
				audio: { deviceId: audioId },
				video: {
					deviceId: videoId,
				},
			};

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => resolve(stream))
				.catch((e) => reject(e));
		} catch (e) {
			reject(e);
		}
	});
};

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (stream, streamId, token, roomIdx, isMain) => {
	return new Promise((resolve, reject) => {
		try {
			const adapter = new WebRTCAdaptor({
				websocket_url: _CFG.getStreamUrl(),
				mediaConstraints: { video: true, audio: false },
				peerconnection_config: null,
				sdp_constraints: {
					OfferToReceiveAudio: false,
					OfferToReceiveVideo: false,
				},
				localVideoId: "SENDER",
				localStream: stream,
				debug: false,
				callback: (info, obj) => {
					const checkAndRepublishIfRequired = () => {
						const iceState = adapter.iceConnectionState(streamId);
						if (iceState === null || iceState === "failed" || iceState === "disconnected") {
							adapter.stop(streamId);
							adapter.closePeerConnection(streamId);
							adapter.closeWebSocket();
						}
					};

					switch (info) {
						case "initialized":
							adapter.publish(streamId, token);
							break;
						case "publish_started":
							_U.postMessage("STREAMING-STARTED", { roomIdx, isMain });
							break;
						case "publish_finished":
							_U.postMessage("STREAMING-ENDED", { roomIdx, isMain });
							break;
						case "browser_screen_share_supported":
							break;
						case "screen_share_stopped":
							break;
						case "closed":
							if (typeof obj != "undefined") {
								console.log("Connecton closed: " + JSON.stringify(obj));
							}
							break;
						case "pong":
							break;
						case "refreshConnection":
							checkAndRepublishIfRequired();
							break;
						case "ice_connection_state_changed":
							break;
						case "updated_stats":
							console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec" + " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec");
							break;
					}
				},
				callbackError: (err) => {
					_U.postMessage("STREAMING-ERROR", err);
				},
			});
			resolve(adapter);
		} catch (e) {
			reject(e);
		}
	});
};

const stopWebRTCStreaming = (adapter, streamId) => {
	adapter.stop(streamId);
};
