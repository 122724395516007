/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import swal from "sweetalert";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { CheckBox, Radio } from "../../UI/Product";
import { SelectBox, Input } from "../../UI/Form";
import Dialog from "../../UI/Dialog";

import Layout from "../Layout";
import { _API, _U } from "../../Modules/Utils";
import { _DEF } from "../../Modules/Config";
import { OrderTop } from "../../UI/Header";
import Review from "../User/Review.Component";
import Shipping from "./Shipping.Component";

import { AppContext } from "../../Components/AppProvider";

export default (props) => {
	const { history, match } = props;
	const { orderIdx } = match.params;

	const { iam } = useContext(AppContext);

	const [data, setData] = useState(null);

	const loadData = () => {
		// 회원 주문
		let path = "/user/payment/product/order";

		if (!iam?.idx) {
			// 비회원 주문
			path = "/payment/product/order/detail";
		}

		_API
			.get({ path, data: { orderIdx, isFinish: "Y" } })
			.then((res) => {
				setData(res.data);
			})
			.catch((e) => {
				history.goBack();
			});
	};

	useEffect(() => {
		if (orderIdx) {
			loadData();
		}
	}, [orderIdx]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onDeliveryCheck = (item) => {
		swal({ title: "알림", text: "준비중 입니다." });
	};

	const onRefundClick = (item) => {
		//주문 취소,반품 내용 정리 후 다시 작업 필요함.
		swal({
			title: "알림",
			text: "상품을 반품 하시겠습니까?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				const req = { path: "/user/mypage/order/cancel", data: { orderIdx: item.orderIdx } };
				_API.post(req).then((res) => {
					loadData();
				});
			}
		});
	};

	const onCancelClick = (item) => {
		let path = "/user/mypage/order/cancel/kcp";

		if (!iam?.idx) {
			path = "/payment/order/cancel/kcp";
		}

		//주문 취소,반품 내용 정리 후 다시 작업 필요함.
		swal({
			title: "알림",
			text: "주문을 취소 하시겠습니까?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				const req = { path, data: { orderIdx: item.orderIdx } };
				_API.post(req).then((res) => {
					loadData();
				});
			}
		});
	};

	return (
		<Layout {...props} hideTop={true}>
			<Wrapper>
				<OrderTop {...props}>주문상세</OrderTop>
				<div className="order-date-no">
					<div className="date">{moment(data?.info?.regDate).format("YYYY.MM.DD")} 주문</div>
					<div className="no">주문번호 {data?.info?.paymentOrderNo}</div>
					{/* <div className="status">{_DEF.getDefinedLabel(_DEF.ORDER_STATUS, data?.info?.orderStatus)}</div> */}

					{/* <div className="buttons">
						{["I", "Y"].includes(data?.info?.shippingStatus) && (
							<button className="on" onClick={() => onDeliveryCheck(data?.info)}>
								배송조회
							</button>
						)}
					</div>  */}
				</div>

				<ProductInfo {...props} {...data} onRefresh={loadData} />
				<DeliveryInfo {...props} data={data?.info} />
				<Summary {...data} />
				{/* <Receipt /> */}

				<div className="buttons">
					<button className="action" onClick={() => history.push(`/`)}>
						쇼핑 계속하기
					</button>
					{/* {data?.info?.shippingStatus === "Y" && <button onClick={() => onRefundClick(data?.info)}>반품신청</button>} */}
					{(data?.info?.orderStatus === "PAY_WAIT" || data?.info?.orderStatus === "PAY_COMPLETE" || data?.info?.orderStatus === "PRODUCT_WAIT") && (
						<button onClick={() => onCancelClick(data?.info)}>주문취소</button>
					)}
				</div>
			</Wrapper>
		</Layout>
	);
};

const Wrapper = styled.div`
	width: ${(props) => props.theme.layout.productWidth}px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 80px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
	}

	div.order-date-no {
		display: block;
		margin-top: 37px;
		position: relative;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 0 21px;
			display: flex;
			flex-wrap: wrap;
		}
		> div {
			display: inline-block;
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				flex-basis: auto;
			}
		}

		> div.date {
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 16px;
			line-height: 24px;
		}

		> div.no {
			font-family: ${(props) => props.theme.fontFamily.light};
			color: #acacac;
			font-size: 16px;
			line-height: 24px;
			margin-left: 22px;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				font-size: 14px;
				margin-left: 0;
				text-align: end;
			}
		}

		> div.status {
			font-family: ${(props) => props.theme.fontFamily.bold};
			color: #3d3d3d;
			font-size: 16px;
			line-height: 24px;
			margin-left: 22px;
			cursor: pointer;
		}

		> .buttons {
			position: absolute;
			right: 0px;
			top: -10px;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				top: unset;
				position: relative;
				text-align: right;
				display: block;
				margin-top: 15px;
				flex-basis: 100%;
			}

			> button {
				border: 1px solid #777777;
				background: #ffffff;
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #777777;
				font-size: 14px;
				line-height: 20px;
				text-align: center;
				padding: 8px 15px;
				margin-left: 8px;
				:first-child {
					margin-left: 0px;
				}
			}

			button.on {
				border: 1px solid #00788d;
				color: #ffffff;
				background: #00788d;
			}
		}
	}

	> div.buttons {
		margin-top: 30px;
		text-align: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: fixed;
			bottom: 0;
			width: 100%;
			display: flex;
			margin-top: 0px;
		}

		button {
			border: 1px solid #00788d;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #00788d;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 15px 0;
			width: 220px;

			:last-child {
				margin-left: 12px;
			}
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				width: 100%;
				margin-left: 0px !important;
			}
		}

		button.action {
			border: 1px solid #00788d;
			background: #00788d;
			color: #ffffff;
		}
	}
`;

const ProductInfo = (props) => {
	const { localList, overseasList, info, location, history, match, onRefresh } = props;

	const onClickReview = (item) => {
		Dialog({ title: "리뷰 작성", body: <Review {...{ location, history, match, orderIdx: item.orderIdx, productIdx: item.productIdx }} /> })
			.then((data) => {
				onRefresh();
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	return (
		<PWrapper>
			{localList?.length > 0 && (
				<div className="box">
					<h4>한국 배송 상품</h4>
					{localList?.map((item, index) => (
						<ProductItem key={index} {...item} orderStatus={info?.orderStatus} onClickReview={onClickReview} />
					))}
				</div>
			)}
			{overseasList?.length > 0 && (
				<div className="box">
					<h4>해외 배송 상품</h4>

					{overseasList?.map((item, index) => (
						<ProductItem key={index} {...item} orderStatus={info?.orderStatus} onClickReview={onClickReview} />
					))}
				</div>
			)}
		</PWrapper>
	);
};

const PWrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}
	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		margin: 0px;
	}

	div.box {
		border: 1px solid #dfdfdf;
		padding: 29px 24px;
		margin-top: 24px;

		h4 {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
			margin: 0px;

			:last-child {
				padding-top: 24px;
			}
		}
	}
`;

const ProductItem = (props) => {
	const onShippingShow = () => {
		Dialog({ title: "택배 배송조회", body: <Shipping {...{ ...props }} />, width: 582 })
			.then((data) => {
				props.refresh && props.refresh();
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	const goOrderDetail = () => {

	}

	return (
		<PIWrapper>
			<img src={props.productFileSignedUrl} />
			<div className="info">
				<div>
					<div className="brand">{props.productBrandName}</div>
					<h4 className="title">{props.productName}</h4>
					<div className="option">
						{props.productOptItemList?.map((item, index) => (
							<div key={index}>
								{item.productOptionName} : {item.productOptionItemName} / 수량 {item.quantity}개
							</div>
						))}
					</div>
					<div className="price">{_U.numberFormat(props.totalAmountPayment)} 원</div>
				</div>
			</div>
			<div className="buttons">
				{props.lastOrderShipping?.orderStatus ? (
					<button onClick={() => goOrderDetail()}>{_DEF.getDefinedLabel(_DEF.ORDER_STATUS, props.lastOrderShipping?.orderStatus)}</button>
				) : (
					// <button onClick={() => goOrderDetail()}>{props.orderStatus === "PAY_WAIT" ? "입금대기" : "결제완료"}</button>
					<button onClick={() => goOrderDetail()}>{_DEF.getDefinedLabel(_DEF.ORDER_STATUS, props.orderStatus)}</button>
				)}

				{["SHIPPING_ING_OUT", "SHIPPING_ING", "SHIPPING_COMPLETE"].includes(props.lastOrderShipping?.orderStatus) && (
					<button className="on" onClick={() => onShippingShow()}>
						배송조회
					</button>
				)}
				{["ORDER_COMPLETE", "SHIPPING_COMPLETE"].includes(props.lastOrderShipping?.orderStatus) && (
					<>
						{props.reviewIdx ? (
							<button onClick={() => history.push(`/user/my/review`)}>리뷰 작성완료</button>
						) : (
							<button className="active" onClick={() => props.onClickReview(props)}>
								리뷰 작성
							</button>
						)}
					</>
				)}
			</div>
		</PIWrapper>
	);
};

const PIWrapper = styled.div`
	border-bottom: 1px solid #efefef;
	display: flex;
	padding: 16px 0 35px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		flex-wrap: wrap;
	}
	:last-child {
		border-bottom: 0px;
		padding-bottom: 0px;
	}

	> img {
		flex-basis: 90px;
		width: 90px;
		height: 90px;
		object-fit: cover;
	}

	> div.info {
		flex: 1;
		padding: 0 16px;
		display: flex;
		align-items: center;

		div.brand {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 13px;
			line-height: 19px;
		}

		h4.title {
			margin: 0px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
		}

		div.option {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 13px;
			line-height: 19px;
		}

		div.price {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			margin-top: 8px;

			span {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}
	}

	> div.buttons {
		flex-basis: 176px;
		padding: 0 44px;
		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-direction: row;
			padding: 0;
		}

		:before {
			position: absolute;
			width: 1px;
			height: 70%;
			background: #efefef;
			left: 0;
			top: 15%;
			content: "";
		}
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-basis: 100%;
			margin-top: 24px;
			:before {
				content: unset;
			}
		}

		button {
			border: 1px solid #777777;
			background: #ffffff;
			text-align: center;
			padding: 10px 0;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #777777;
			font-size: 14px;
			line-height: 20px;
			width: 100%;
			margin-bottom: 8px;
			:last-child {
				margin-bottom: 8px;
			}

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				margin-right: 5px;
			}
		}

		button.on {
			border: 1px solid #00788d;
			color: #00788d;
		}

		button.active {
			border: 1px solid #00788d;
			background-color: #00788d;
			color: #ffffff;
		}
	}
`;

const DeliveryInfo = (props) => {
	const { data } = props;
	return (
		<DWrapper>
			<h3>받는사람 정보</h3>
			<div className="box">
				<dl>
					<dt>이름</dt>
					<dd>{data?.recipientName}</dd>
				</dl>
				<dl>
					<dt>연락처</dt>
					<dd>{data?.recipientMobile}</dd>
				</dl>
				<dl>
					<dt>배송주소</dt>
					<dd>
						{data?.recipientZipcode && `(${data?.recipientZipcode})`}
						{data?.recipientAddr} {data?.recipientAddrDtl}
						{/* <a>변경하기</a> */}
					</dd>
				</dl>
				<dl>
					<dt>배송 요청사항</dt>
					<dd>
						{data?.reqDesc}

						{/* <a>변경하기</a> */}
					</dd>
				</dl>
			</div>
		</DWrapper>
	);
};

const DWrapper = styled.div`
	margin-top: 40px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}
	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		margin: 0px;
	}

	> div.box {
		padding: 24px 0 40px;
		border-bottom: 1px solid #efefef;

		> h3 {
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 18px;
			line-height: 26px;
			font-weight: normal;
			margin: 0px;

			button {
				background: #ffffff;
				border: 1px solid #efefef;
				text-align: center;
				padding: 8px 17px;
				font-weight: normal;
				font-family: ${(props) => props.theme.fontFamily.medium};
				color: #777777;
				font-size: 13px;
				line-height: 19px;
				margin-left: 8px;

				:first-child {
					margin-left: 24px;
				}
			}
		}

		dl {
			display: flex;
			justify-content: space-between;

			:first-child {
				margin: 0;
			}
		}

		dl > dt {
			flex-basis: 100px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 14px;
			line-height: 20px;
		}

		dl > dd {
			flex: 1;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			position: relative;
			a {
				position: absolute;
				font-weight: normal;
				font-family: ${(props) => props.theme.fontFamily.medium};
				color: #777777;
				font-size: 13px;
				line-height: 19px;
				right: 0;
				top: 0;
				padding-right: 12px;

				background-image: url("/icons/ic_arrow_right.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_right.png", "/icons/ic_arrow_right@2x.png", "/icons/ic_arrow_right@3x.png"])});
				background-repeat: no-repeat;
				background-size: 6px 10px;
				background-position: right center;
			}
		}
	}
`;

const Summary = (props) => {
	const { totalSummary, info } = props;
	const { iam } = useContext(AppContext);

	return (
		<SWrapper>
			<h3>결제정보</h3>
			<div className="box">
				<dl>
					<dt>결제수단</dt>
					<dd>{_DEF.getDefinedLabel(_DEF.PAYMENT_METHOD, info?.paymentPayMethod)}</dd>
				</dl>

				<dl>
					<dt>총 상품 가격</dt>
					<dd>{_U.numberFormat(totalSummary?.productAmount)} 원</dd>
				</dl>
				<dl>
					<dt>할인 금액</dt>
					<dd>
						<b><span>-{_U.addComma(totalSummary?.discountAmount)}</span> 원</b>
					</dd>
				</dl>
				{iam && iam.idx && (
					<Fragment>
						<dl>
							<dt>사용 포인트</dt>
							<dd>
								<b><span>-{_U.addComma(totalSummary?.usePoint)}</span> 원</b>
							</dd>
						</dl>
						<dl>
							<dt>쿠폰 할인</dt>
							<dd>
								<b><span>-{_U.addComma(totalSummary?.discountCoupon)}</span> 원</b>
							</dd>
						</dl>
					</Fragment>
				)}
				<dl>
					<dt>배송비</dt>
					<dd>{_U.numberFormat(totalSummary?.shippingAmount)} 원</dd>
				</dl>
				<dl className="total">
					<dt>총 결제금액</dt>
					<dd>
						<b>{_U.numberFormat(totalSummary?.totalAmount)} 원</b>
					</dd>
				</dl>
			</div>
		</SWrapper>
	);
};

const SWrapper = styled.div`
	margin-top: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid #efefef;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}
	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		margin: 0px;
	}

	div.box {
		border: 1px solid #dfdfdf;
		background: #f9f9f9;
		padding: 24px;
		margin-top: 24px;
	}

	dl {
		display: flex;
		justify-content: space-between;
		margin: 16px 0 0 0;

		:first-child {
			margin: 0;
		}
	}

	dl > dt {
		flex: 1;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #b8b8b8;
		font-size: 14px;
		line-height: 20px;
	}

	dl > dd {
		flex: 1;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 14px;
		line-height: 20px;
		text-align: right;

		b {
			color: #e32222;
			font-weight: normal;
		}

		span {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}
	}

	dl.total {
		margin: 33px 0 0 0;
	}
	dl.total > dt {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 14px;
		line-height: 20px;
	}
	dl.total > dd {
		color: #e32222;
		font-size: 20px;
		line-height: 29px;
		span {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}

		b {
			color: #e32222;
			font-weight: normal;
		}
	}
`;

const Receipt = (props) => {
	return (
		<RWrapper>
			<h3>결제영수증 정보</h3>
			<div className="box">
				<div>해당 주문건에 대해 구매 카드영수증 확인이 가능합니다.</div>
				<button>카드영수증</button>
			</div>
		</RWrapper>
	);
};

const RWrapper = styled.div`
	margin-top: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid #efefef;

	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		margin: 0px;
	}

	div.box {
		display: flex;
		justify-content: space-between;
		margin-top: 25px;

		> div {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 14px;
			line-height: 20px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		> button {
			border: 1px solid #efefef;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #777777;
			font-size: 13px;
			line-height: 19px;
			padding: 8px 30px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
`;
