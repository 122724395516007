/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import useFetch from "./useFetch";
import { isMobile } from "react-device-detect";
import StackGrid, { transitions } from "react-stack-grid";
const { scaleDown } = transitions;

import Layout from "../Layout";
import styled from "styled-components";
import Modal from "react-modal";

import { getSrcSet } from "../../UI/Assets";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import ReviewModal from "./ReviewModal";
import Review from "../User/Review.Component";
import Dialog from "../../UI/Dialog";

export default (props) => {
	const { history, location, match } = props;
	const { iam, auth } = useContext(AppContext);

	const [query, setQuery] = useState("");
	const [page, setPage] = useState(1);
	const [keyword, setKeyword] = useState("");
	const [keywordList, setKeywordList] = useState([]);

	const { loading, error, list } = useFetch(query, page, keywordList);
	const loader = useRef(null);
	const likeEl = useRef([]);

	const [selectItem, setSelectItem] = useState(null);

	const handleObserver = useCallback(
		(entries) => {
			const target = entries[0];
			if (target.isIntersecting) {
				setPage((prev) => prev + 1);
			}
		},
		[keywordList]
	);

	const openModal = (item) => {
		setSelectItem(item);
	};

	const closeModal = () => {
		setSelectItem(null);
	};

	const onSearch = () => {
		let tmp = JSON.parse(JSON.stringify(keywordList));
		tmp.push(keyword);
		setKeyword("");
		setPage(1);

		// tmp = [...tmp].reverse();
		setKeywordList(tmp);
	};

	const removeKeyword = (index) => {
		const tmp = JSON.parse(JSON.stringify(keywordList));
		tmp.splice(index, 1);
		setPage(1);
		setKeywordList(tmp);
	};

	// useEffect(() => {
	// 	const { loading, error, list } = useFetch(query, page, keywordList);

	// }, [keywordList])

	useEffect(() => {
		const option = {
			root: null,
			rootMargin: "20px",
			threshold: 0,
		};
		const observer = new IntersectionObserver(handleObserver, option);
		if (loader.current) observer.observe(loader.current);
	}, [handleObserver]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onClickLike = (item, e) => {
		e.stopPropagation();
		let likeCnt = item.likeCnt;

		_API
			.post({ path: "/bbs/like", data: { bbsIdx: item.bbsIdx } })
			.then((res) => res.data)
			.then((data) => {
				if (data.status === "Y") {
					likeEl.current[item.bbsIdx].innerHTML = likeCnt + 1;
					e.target.src = "/icons/ic_heart_on.png";
					e.target.srcset = getSrcSet(["/icons/ic_heart_on.png", "/icons/ic_heart_on@2x.png", "/icons/ic_heart_on@3x.png"]);
				} else {
					likeEl.current[item.bbsIdx].innerHTML = likeCnt > 0 ? likeCnt - 1 : 0;
					e.target.src = "/icons/ic_heart.png";
					e.target.srcset = getSrcSet(["/icons/ic_heart.png", "/icons/ic_heart@2x.png", "/icons/ic_heart@3x.png"]);
				}
			});
	};

	const getKeywordList = () => {
		return [...keywordList].reverse();
	};
	const onClickWrite = () => {
		Dialog({
			title: `리뷰 작성`,
			body: <Review {...{ location, history, match, isReviewer: iam?.isReviewer, reviewPage: "Y" }} />,
		})
			.then((data) => {
				setKeyword("");
				setPage(1);
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	const onRemoveClick = (item, e) => {
		e.stopPropagation();
		swal({
			title: "알림",
			text: "리뷰를 삭제 하시겠습니까?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				const req = { path: "/bbs/status", data: { bbsIdx: item.bbsIdx, status: "D" } };
				_API.post(req).then((res) => {
					setKeyword("");
					setPage(1);
				});
			}
		});
	};

	return (
		<Layout {...props} showMenu={true}>
			<ReviewWrapper>
				<ReviewSection>
					소셜
					{auth && (
						<div className="buttons">
							<button onClick={onClickWrite}>리뷰작성</button>
						</div>
					)}
				</ReviewSection>
				<KeywordLayout>
					<KeywordWrapper>
						{getKeywordList()?.map((keyword, index) => (
							<KeywordBox key={index}>
								<KeywordText>{keyword}</KeywordText>
								<KeyaordClose
									onClick={() => removeKeyword(index)}
									src="/icons/ic_close.png"
									srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])}
								/>
							</KeywordBox>
						))}
					</KeywordWrapper>
					<SearchBox>
						<input
							type="text"
							name="keyword"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									onSearch();
								}
							}}
						/>
						<button onClick={onSearch.bind(this)}>
							<img src="/icons/ic_search.png" srcSet={getSrcSet(["/icons/ic_search.png", "/icons/ic_search@2x.png", "/icons/ic_search@3x.png"])} />
						</button>
					</SearchBox>
				</KeywordLayout>

				<StackWrapper>
					<StackGrid
						columnWidth={isMobile ? `50%` : `25%`}
						appear={scaleDown.appear}
						appeared={scaleDown.appeared}
						enter={scaleDown.enter}
						entered={scaleDown.entered}
						monitorImagesLoaded={true}
						leaved={scaleDown.leaved}
					>
						{list.map((item, i) => (
							<StackBox key={i} onClick={() => openModal(item)}>
								<StackImage>
									<img src={item.imageList?.[0].fileSignedUrl} />
								</StackImage>
								<ReviewTitle>{item.productName}</ReviewTitle>
								<ReviewContent>
									{item.content}
									{/* {item["hash"].map((hash, key) => (
										<ReviewHash key={`hash${i}_${key}`}>#{hash}</ReviewHash>
									))} */}
								</ReviewContent>
								{iam?.isReviewer === "Y" && iam?.code === item.regCode && (
									<div className="manage">
										<button className="btn-remove" onClick={onRemoveClick.bind(this, item)}>
											삭제
										</button>
									</div>
								)}
								<ReviewItem>
									<ReviewBrand>
										{item.brandFileSignedUrl && <BrandMark src={item.brandFileSignedUrl} />}

										<BrandName>{item.brandName}</BrandName>
									</ReviewBrand>
									<LikeWrap>
										<img
											src={item.myLike > 0 ? `/icons/ic_heart_on.png` : `/icons/ic_heart.png`}
											srcSet={
												item.myLike > 0
													? getSrcSet(["/icons/ic_heart_on.png", "/icons/ic_heart_on@2x.png", "/icons/ic_heart_on@3x.png"])
													: getSrcSet(["/icons/ic_heart.png", "/icons/ic_heart@2x.png", "/icons/ic_heart@3x.png"])
											}
											onClick={onClickLike.bind(this, item)}
										/>
										<LickCount ref={(el) => (likeEl.current[item.bbsIdx] = el)}>{item.likeCnt || 0}</LickCount>
									</LikeWrap>
								</ReviewItem>
								<div style={{ height: "20px" }}></div>
							</StackBox>
						))}
					</StackGrid>
				</StackWrapper>

				{loading && <p></p>}
				{error && <p></p>}
				<div ref={loader} />
			</ReviewWrapper>
			{selectItem != null && <ReviewModal history={history} onClose={closeModal} item={selectItem} />}
		</Layout>
	);
};

const Wrapper = styled.div`
	height: 2000px;
`;

const ReviewWrapper = styled.div`
	width: ${(props) => props.theme.layout.reviewWidth}px;
	margin: 0 auto;
	padding-bottom: 80px;
	min-height: 1000px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
	}

	div.top {
		display: flex;
	}

	div.top {
		display: flex;
	}
`;

const ReviewSection = styled.div`
	font-family: ${(props) => props.theme.fontFamily.medium};
	font-size: 20px;
	font-weight: normal;
	line-height: 29px;
	text-align: left;
	color: #3d3d3d;
	position: relative;

	> div.buttons {
		position: absolute;
		right: 0;
		top: -5px;
		text-align: right;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			right: 21px;
		}
		small {
			display: block;
			color: #777777;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
		}

		button {
			background: #00788d;
			border: none;
			outline: none;
			color: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			padding: 8px 18px;
			cursor: pointer;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}
`;

const KeywordLayout = styled.div`
	margin-top: 12px;
	vertical-align: bottom;
	justify-content: space-between;
	display: flex;
	align-items: end;
	margin-bottom: 12px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		flex-direction: column;
		align-items: start;
	}
`;

const KeywordWrapper = styled.div`
	display: flex;
	gap: 5px;
	flex: 1;
	flex-grow: 3;
	flex-wrap: wrap;
	width: 100%;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: calc(100% - 21px);
		flex-wrap: nowrap;
		padding: 0 0 0 21px;
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		::-webkit-scrollbar {
			display: none;
		}
	}
`;

const KeywordBox = styled.div`
	padding: 6px 16px;
	border-radius: 16px;
	background-color: #e32222;
	display: flex;
	align-items: baseline;
	gap: 12px;
`;

const KeywordText = styled.div`
	font-family: NotoSansCJKkr;
	font-size: 13px;
	line-height: 18px;
	text-align: left;
	color: #ffffff;
`;

const KeyaordClose = styled.img`
	cursor: pointer;
	width: 7px;
	height: 7px;
`;

const SearchBox = styled.div`
	flex: 1;
	flex-grow: 1;
	text-align: right;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-top: 10px;
		width: calc(100% - 42px);
		padding: 0 21px;
	}

	input {
		background: #ebebeb;
		border: 1px solid #ebebeb;
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
		background: #ffffff;
		border-right: 0;
		height: 38px;
		vertical-align: middle;
		padding: 0 5px 0 15px;
	}

	button {
		width: 40px;
		height: 40px;
		border: solid 1px #ebebeb;
		background-color: #ffffff;
		border-top-right-radius: 16px;
		border-bottom-right-radius: 16px;
		border-left: 0;
		cursor: pointer;
		vertical-align: middle;
	}
`;

const StackWrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: calc(100% - 42px);
		padding: 0 21px;
	}
`;

const StackBox = styled.span`
	width: 237px;
	margin-bottom: 20px;
	cursor: pointer;
	position: sticky;

	.manage {
		width: 100%;
		text-align: right;

		.btn-remove {
			border: 1px solid #efefef;
			background: none;
			color: #777777;
			font-size: 12px;
		}
	}
`;

const StackImage = styled.div`
	object-fit: contain;
	border-radius: 10px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const ReviewTitle = styled.div`
	padding-right: 8px;
	padding-left: 8px;
	height: 19px;
	font-family: NotoSansCJKkr;
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
	text-align: left;
	color: #3d3d3d;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
`;

const ReviewContent = styled.div`
	padding-right: 8px;
	padding-left: 8px;
	display: flow-root;
	font-family: NotoSansCJKkr;
	font-size: 13px;
	line-height: 18px;
	text-align: left;
	color: #777777;
`;

const ReviewHash = styled.span`
	height: 17px;
	font-family: NotoSansCJKkr;
	font-size: 13px;
	font-weight: 500;
	line-height: 17px;
	text-align: left;
	color: #3d3d3d;
`;

const ReviewItem = styled.div`
	padding-right: 8px;
	padding-left: 8px;
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
`;

const ReviewBrand = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4.6px;
`;

const BrandMark = styled.img`
	width: 30px;
	height: 30px;
	object-fit: contain;
`;

const BrandName = styled.div`
	font-family: NotoSansCJKkr;
	font-size: 13px;
	font-weight: 300;
	line-height: 17px;
	text-align: left;
	color: #3d3d3d;
`;

const LikeWrap = styled.div`
	display: flex;
	gap: 4px;
	flex-direction: row;
	align-items: center;
`;

const LickCount = styled.div.attrs((props) => {
	return {
		ref: props.ref,
	};
})`
	font-family: NanumSquareB;
	font-size: 13px;
	line-height: 13px;
	text-align: left;
	color: #acacac;
`;

const ModalTest = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: flex;
	.wrapper {
		width: 990px;
		height: 640px;
		background-color: #ff0000;
	}
`;
