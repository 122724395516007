/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import { isMobile } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Layout, { Container } from "../Layout";

import { Wrapper, Title } from "./UI";
import swal from "sweetalert";
import { UInputForm, UFormWrapper, CheckBox } from "../../UI/Form";

export default (props) => {
    const { auth } = useContext(AppContext);
    const { location, history, match } = props;
    const [tab, setTab] = useState("ID");

    if (auth) {
        history.push("/");
    }

    return (
        <Layout {...props}>
            <Container>
                <Wrapper>
                    <Title>모아 회원 아이디/비밀번호 찾기</Title>
                    <FWrapper>
                        <ul className="tab">
                            <li className={cx({ active: tab === "ID" })} onClick={() => setTab("ID")}>
                                아이디 찾기
                            </li>
                            <li className={cx({ active: tab === "PASSWORD" })} onClick={() => setTab("PASSWORD")}>
                                비밀번호 찾기
                            </li>
                        </ul>
                        {tab === "ID" && <FindId />}
                        {tab === "PASSWORD" && <FindPassword {...props} />}
                    </FWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const FWrapper = styled.div`
    margin-top: 40px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding: 0 21px;
    }
    ul.tab {
        display: flex;

        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    ul.tab > li {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #b8b8b8;
        color: #efefef;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 14px;
        line-height: 20px;
        padding: 16px 0;
        cursor: pointer;
    }

    ul.tab > li.active {
        background: #005a60;
        color: #ffffff;
    }
`;

const FindId = (props) => {
    const [id, setId] = useState("");

    const onClickCert = () => {
        _API.post({ path: "/user/cert", data: { userId: UUIDV4(), cParams: "FIND_ID" } }).then((res) => {
            const { online_url: pc_url, mobile_url } = res.data;

            if (isMobile) {
                window.open(mobile_url, "CERT");
            } else {
                window.open(pc_url, "PAYMENT", "width=400,height=600");
            }
        });
    };

    const onCertComplete = (data) => {
        console.log(data);
        if (!data.id) {
            swal({ title: "알림", text: "존재하지 않는 회원 정보 입니다." });
        } else {
            setId(data.id);
        }
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "CERT_COMPLETE":
                    if (data.data.type === "FIND_ID") {
                        onCertComplete(data.data);
                    }
                    break;
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <FFWrapper>
            {id ? (
                <>
                    <p>회원님의 아이디는 {id} 입니다.</p>
                    <button type="button" onClick={() => setId("")}>
                        뒤로
                    </button>
                </>
            ) : (
                <>
                    <p>본인 인증 후 아이디를 찾을 수 있습니다.</p>
                    <button type="button" onClick={() => onClickCert()}>
                        본인인증
                    </button>
                </>
            )}
        </FFWrapper>
    );
};

const FindPassword = (props) => {
    const { history } = props;
    const [isChange, setIsChange] = useState(false);
    const [id, setId] = useState("");
    const [passwd, setPasswd] = useState("");
    const [passwdRe, setPasswdRe] = useState("");

    const onClickCert = () => {
        _API.post({ path: "/user/cert", data: { userId: UUIDV4(), cParams: "FIND_PASSWORD" } }).then((res) => {
            const { online_url: pc_url, mobile_url } = res.data;

            if (isMobile) {
                window.open(mobile_url, "CERT");
            } else {
                window.open(pc_url, "PAYMENT", "width=400,height=600");
            }
        });
    };

    const onCertComplete = (data) => {
        if (data.passwdChange === "N") {
            swal({ title: "알림", text: "존재하지 않는 회원 정보 입니다." });
            setIsChange(false);
        } else {
            setIsChange(true);
            setId(data.id);
        }
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            console.log(data);
            switch (data.code) {
                case "CERT_COMPLETE":
                    if (data.data.type === "FIND_PASSWORD") {
                        onCertComplete(data.data);
                    }
                    break;
            }
        });
    };

    const handleChangePasswd = () => {
        if (!passwd) {
            swal({ title: "알림", text: "새 비밀번호를 입력해주세요." });
            return;
        }

        if (!passwdRe) {
            swal({ title: "알림", text: "새 비밀번호 확인을 입력해주세요." });
            return;
        }

        _API.post({ path: "/user/passwd", data: { id, passwd, passwdRe } }).then((res) => {
            console.log(res);
            swal({ title: "알림", text: "비밀번호 변경이 완료되었습니다." }).then(() => {
                history.push(`/`);
                setTimeout(() => {
                    _U.postMessage("LOGIN");
                }, 500);
            });
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <FFWrapper>
            {isChange ? (
                <div className="form">
                    <UInputForm title="새 비밀번호" name="passwd" type="password" value={passwd} onChange={(e) => setPasswd(e.target.value)} placeholder="비밀번호를 입력해 주세요." />
                    <UInputForm title="비밀번호 확인" name="passwdRe" type="password" value={passwdRe} onChange={(e) => setPasswdRe(e.target.value)} placeholder="비밀번호를 한번 더 입력해 주세요." />
                    <button type="button" className="on" onClick={handleChangePasswd}>
                        비밀번호 변경
                    </button>
                    <button type="button" onClick={() => setIsChange(false)}>
                        취소
                    </button>
                </div>
            ) : (
                <>
                    <p>본인 인증 후 비밀번호를 찾을 수 있습니다.</p>
                    <button type="button" onClick={() => onClickCert()}>
                        본인인증
                    </button>
                </>
            )}
        </FFWrapper>
    );
};

const FFWrapper = styled.div`
    border: 1px solid #efefef;
    width: calc(100% - 44px);
    padding: 21px;
    border-top: 0;
    text-align: center;

    p {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 14px;
        text-align: center;
        color: #3d3d3d;
    }

    button {
        padding: 8px 16px 7px;
        border: solid 1px #efefef;
        background-color: #fff;
        font-size: 13px;
    }

    button.on {
        border: #005a60 solid 1px;
        background: #005a60;
        color: #ffffff;
    }

    div.form {
        width: 70%;
        margin: 0 auto;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 100%;
        }

        .uif-label {
            text-align: left;
        }
    }
`;
