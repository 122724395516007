/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
// import Slider from "react-slick";
import Slider from "react-slick-ios-fix";
import ScrollContainer from "react-indiana-drag-scroll";
import moment from "moment";
import { isMobile } from "react-device-detect";
import cx from "classnames";

import Layout from "./Layout";
import {
    MBContainer,
    HIContainer,
    OAContainer,
    OAIContainer,
    VODContainer,
    VODIContainer,
    LNContainer,
    LNIContainer,
    TDContainer,
    TDPContainer,
    TDPIContainer,
    NAContainer,
    NAIContainer,
    RContainer,
    RIContainer,
} from "./Home.Styled";

import { getSrcSet } from "../UI/Assets";
import { _API, _U } from "../Modules/Utils";

export default (props) => {
    const { location, history, match } = props;
    const cp = { history, location, match };

    const [visualList, setVisualList] = useState([]);
    const [visualContentList, setVisualContentList] = useState([]);
    const [mainContentShowInfo, setMainContentShowInfo] = useState({});

    const loadVisual = () => {
        _API.get({
            path: "/visual/front/main/all",
            data: {},
        })
            .then((res) => res.data)
            .then((data) => {
                setVisualContentList(data.contentList);
                setVisualList(data.list);
                const timeDeal = data.contentList?.filter((f) => f.visualCategory === "TIMEDEAL")?.length > 0 ? true : false;
                const newArrivals = data.contentList?.filter((f) => f.visualCategory === "NEWARRIVALS")?.length > 0 ? true : false;
                const ranking = data.contentList?.filter((f) => f.visualCategory === "RANKING")?.length > 0 ? true : false;
                setMainContentShowInfo({ timeDeal, newArrivals, ranking });
            });
    };

    useEffect(() => {
        loadVisual();
    }, []);

    return (
        <Layout {...props} isDark={true}>
            <MainBanners visualContentList={visualContentList} {...cp} />
            {/* <OnAIR /> */}
            {/* <VOD /> */}
            {/* <LiveNotice /> */}
            {mainContentShowInfo?.timeDeal && <TimeDeal visualContentList={visualContentList} visualList={visualList} {...cp} />}
            {mainContentShowInfo?.newArrivals && <NewArrivals visualContentList={visualContentList} {...cp} />}
            {mainContentShowInfo?.ranking && <Ranking visualContentList={visualContentList} {...cp} />}
        </Layout>
    );
};

/**
 * MainBanner
 */

const MainBanners = (props) => {
    const { location, history, match } = props;
    const bannerEnd = useRef(null);
    const cp = { history, location, match };
    const [keyword, setKeyword] = useState("");
    const [searchFix, setSearchFix] = useState(false);

    const [list, setList] = useState([]);
    const getCmsPath = (item) => {
        let result = "";
        if (item && item.cmsCode1) {
            result += "#/cms/" + item.cmsCode1;
        }
        if (item && item.cmsCode1 && item.cmsCode2) {
            result += "/" + item.cmsCode2;
        }
        if (item && item.cmsCode1 && item.cmsCode2 && item.cmsCode3) {
            result += "/" + item.cmsCode3;
        }
        return result;
    };

    useEffect(() => {
        setList(props.visualContentList);
    }, [props.visualContentList]);

    const handleScroll = () => {
        if (bannerEnd?.current.offsetTop <= window.pageYOffset) {
            _U.postMessage("DARK-DISABLED");
            //setSearchFix(true);
        } else {
            _U.postMessage("DARK-ENABLED");
            //setSearchFix(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const onSearch = () => {
        history.push(`/search/${encodeURIComponent(keyword)}`);
    };

    return (
        <MBContainer>
            <div className="main">
                <Slider
                    infinite
                    speed={500}
                    dots={isMobile ? false : true}
                    autoplay={true}
                    autoplaySpeed={5000}
                    slidesToShow={1}
                    slidesToScroll={1}
                    arrows={false}
                    pauseOnFocus={true}
                    pauseOnHover={true}
                >
                    {list
                        ?.filter((f) => f.visualCategory === "MAINBANNER")
                        ?.map((item, i) => (
                            <Fragment key={i}>
                                {item.visualContentType === "CATEGORY" && (
                                    <a href={`#/category/${item.categoryIdx}`}>
                                        <img src={!isMobile ? item.thumbnailFileSignedUrl : item.thumbnailMobileFileSignedUrl || item.thumbnailFileSignedUrl} alt="" />
                                    </a>
                                )}
                                {item.visualContentType === "CATEGORY_VIEW" && (
                                    <a href={`#/categoryview/${item.categoryViewIdx}`}>
                                        <img src={!isMobile ? item.thumbnailFileSignedUrl : item.thumbnailMobileFileSignedUrl || item.thumbnailFileSignedUrl} alt="" />
                                    </a>
                                )}
                                {item.visualContentType === "CMS" && (
                                    <a href={getCmsPath(item)}>
                                        <img src={!isMobile ? item.thumbnailFileSignedUrl : item.thumbnailMobileFileSignedUrl || item.thumbnailFileSignedUrl} alt="" />
                                    </a>
                                )}
                                {item.visualContentType === "LINK" && (
                                    <a href={item.url} target="_blank">
                                        <img src={!isMobile ? item.thumbnailFileSignedUrl : item.thumbnailMobileFileSignedUrl || item.thumbnailFileSignedUrl} alt="" />
                                    </a>
                                )}
                            </Fragment>
                        ))}
                    {/* <a href="#">
                        <img src="/sample/816x480.png" width="816" height="480" alt="" />
                    </a> */}
                </Slider>
            </div>
            <div ref={bannerEnd} />
            <div className="search">
                <div className={cx({ fix: searchFix })}>
                    <input
                        type="text"
                        defaultValue={keyword || ""}
                        // onKeyPress={(e) => {
                        // 	if (e.key === "Enter") {
                        // 		onSearch();
                        // 	}
                        // }}
                        // onChange={(e) => setKeyword(e.target.value)}
                        onClick={() => history.push("/find")}
                        placeholder="모아의 상품을 검색해보세요."
                    />
                    <button type="button" onClick={onSearch}>
                        <img
                            src="/icons/ic_top_search.png"
                            width="20"
                            height="20"
                            srcSet={getSrcSet(["/icons/ic_top_search.png", "/icons/ic_top_search@2x.png", "/icons/ic_top_search@3x.png"])}
                            alt="검색"
                        />
                    </button>
                </div>
            </div>
            <div className="sub" style={{ width: "378px" }}>
                <HotItem visualContentList={list} {...cp} />
                {/* <div className="others">
                    <Slider infinite speed={2000} autoplay={true} autoplaySpeed={7000} slidesToShow={1} slidesToScroll={1} arrows={false} pauseOnFocus={true} pauseOnHover={true}>
                        {list
                            ?.filter((f) => f.visualCategory === "SUBBANNER")
                            ?.map((item, i) => (
                                <Fragment key={i}>
                                    {item.visualContentType === "CATEGORY" && (
                                        <a href={`#/category/${item.categoryIdx}`}>
                                            <img src={item.thumbnailFileSignedUrl || item.productFileSignedUrl} width="378" height="170" alt="" />
                                        </a>
                                    )}
                                    {item.visualContentType === "CATEGORY_VIEW" && (
                                        <a href={`#/categoryview/${item.categoryViewIdx}`}>
                                            <img src={item.thumbnailFileSignedUrl || item.productFileSignedUrl} width="378" height="170" alt="" />
                                        </a>
                                    )}
                                    {item.visualContentType === "CMS" && (
                                        <a href={getCmsPath(item)}>
                                            <img src={item.thumbnailFileSignedUrl || item.productFileSignedUrl} width="378" height="170" alt="" />
                                        </a>
                                    )}
                                    {item.visualContentType === "LINK" && (
                                        <a href={item.url} target="_blank">
                                            <img src={item.thumbnailFileSignedUrl || item.productFileSignedUrl} width="378" height="170" alt="" />
                                        </a>
                                    )}
                                </Fragment>
                            ))}
                    </Slider>
                </div> */}
            </div>
        </MBContainer>
    );
};

const HotItem = (props) => {
    const { location, history, match } = props;

    const [hotItemList, setHotItemList] = useState([]);
    const [viewInfo, setViewInfo] = useState({ i: 0, t: 0 });

    const move = (type, e) => {
        if (e) e.preventDefault();
        if (type === "prev") {
            const t = hotItemList?.length || 0;
            const i = viewInfo.i - 1 < 0 ? t : viewInfo.i - 1;
            setViewInfo({ t, i, ...hotItemList[i] });
        } else {
            const t = hotItemList?.length || 0;
            const i = viewInfo.i + 1 >= t ? 0 : viewInfo.i + 1;
            setViewInfo({ t, i, ...hotItemList[i] });
        }
    };

    const goProduct = (item) => {
        if (item?.productCode) {
            history.push(`/product/${item.productCode}`);
        }
    };

    useEffect(() => {
        const list = props.visualContentList?.filter((f) => f.visualCategory === "HOTITEM");
        setHotItemList(list);
        if (list && list.length > 0) {
            setViewInfo({ t: list?.length, i: 0, ...list[0] });
        }
    }, [props.visualContentList]);
    return (
        <HIContainer>
            <h3 className="title">
                HOT ITEM{" "}
                <small>
                    <strong>{viewInfo?.i + 1 || 0}</strong>/{viewInfo?.t || 0}
                </small>{" "}
            </h3>
            <div className="item">
                <button type="button" className="nav prev" onClick={move.bind(this, "prev")}>
                    <i className="fa fa-angle-left"></i>
                </button>
                <button type="button" className="nav next" onClick={move.bind(this, "next")}>
                    <i className="fa fa-angle-right"></i>
                </button>
                <img src={viewInfo?.thumbnailFileSignedUrl || viewInfo?.productFileSignedUrl} width="160" height="160" alt="" style={{ cursor: "pointer" }} onClick={(e) => goProduct(viewInfo)} />
                {/* <img src="/sample/180x160.png" width="180" height="160" alt="" /> */}
            </div>
            <div className="info">
                <h4 className="brand">{viewInfo?.productBrandName}</h4>
                <h3 className="product">{viewInfo?.productName}</h3>
                <h5 className="cost">
                    <b>{_U.numberFormat(viewInfo?.priceSale)}</b>원
                </h5>
            </div>
        </HIContainer>
    );
};

/**
 * ON AIR
 */

const OnAIR = (props) => {
    return (
        <OAContainer>
            <h3 className="title">
                ON AIR <span className="dot"></span>
            </h3>

            <ScrollContainer className="wrapper scroll-container" vertical={false}>
                <OnAIRItem />
                <OnAIRItem />
                <OnAIRItem />
                <OnAIRItem />
                <OnAIRItem />
                <OnAIRItem />
                <OnAIRItem />
            </ScrollContainer>

            <hr className="split" />
        </OAContainer>
    );
};

const OnAIRItem = (props) => {
    const videoRef = useRef(null);

    return (
        <OAIContainer>
            <video ref={videoRef} poster="/sample/378x542.png" />
            <div className="subTitle">
                <h4 className="number">1</h4>
                <div className="separator"></div>
                <div className="time">
                    <img src="/icons/ic_clock_small.png" width="16" height="16" srcSet={getSrcSet(["/icons/ic_clock_small.png", "/icons/ic_clock_small@2x.png", "/icons/ic_clock_small@3x.png"])} />
                    00:00 ~ 00:00
                </div>
            </div>
            <div className="item">
                <div className="thumbnail">
                    <img src="/sample/92x92.png" width="92" height="92" alt="" />
                </div>
                <div className="content">
                    <h4 className="title">Title</h4>
                    <div className="status">
                        <span className="percent">00%</span>
                        0,000,000원
                    </div>
                </div>
            </div>
            <div className="info">
                <h3 className="title">Title</h3>
                <div className="text">
                    <img src="/icons/ic_play_small.png" width="10" height="12" srcSet={getSrcSet(["/icons/ic_play_small.png", "/icons/ic_play_small@2x.png", "/icons/ic_play_small@3x.png"])} />
                    0.0만
                    <img src="/icons/ic_heart_small.png" width="10" height="11" srcSet={getSrcSet(["/icons/ic_heart_small.png", "/icons/ic_heart_small@2x.png", "/icons/ic_heart_small@3x.png"])} />
                    0.0만
                </div>
            </div>
            <div className="seller">
                <img src="/sample/28x28.png" width="28" height="28" alt="SHOP NAME" />
                Seller Title
            </div>
        </OAIContainer>
    );
};

/**
 * VOD
 */
const VOD = (props) => {
    return (
        <VODContainer>
            <h3 className="title">VOD</h3>

            <ScrollContainer className="wrapper scroll-container" vertical={false}>
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
                <VODItem />
            </ScrollContainer>

            <hr className="split" />
        </VODContainer>
    );
};

const VODItem = (props) => {
    return (
        <VODIContainer>
            <div className="thumbnail">
                <img src="/sample/186x267.png" width="186" height="267" alt="" />
            </div>
            <div className="info">
                <h3 className="title">Title</h3>
                <div className="text">
                    <img src="/icons/ic_play_small.png" width="10" height="12" srcSet={getSrcSet(["/icons/ic_play_small.png", "/icons/ic_play_small@2x.png", "/icons/ic_play_small@3x.png"])} />
                    0.0만
                    <img src="/icons/ic_heart_small.png" width="10" height="11" srcSet={getSrcSet(["/icons/ic_heart_small.png", "/icons/ic_heart_small@2x.png", "/icons/ic_heart_small@3x.png"])} />
                    0.0만
                </div>
                <div className="uploader">Uploader Name</div>
            </div>
        </VODIContainer>
    );
};

/**
 * Live Notice
 */

const LiveNotice = (props) => {
    return (
        <LNContainer>
            <h3 className="title">라이브 예고</h3>
            <ScrollContainer className="wrapper scroll-container" vertical={false}>
                <LiveNoticeItem />
                <LiveNoticeItem />
                <LiveNoticeItem />
                <LiveNoticeItem />
            </ScrollContainer>

            <hr className="split" />
        </LNContainer>
    );
};

const LiveNoticeItem = (props) => {
    return (
        <LNIContainer>
            <div className="layout">
                <div className="thumbnails">
                    <img src="/sample/276x395.png" alt="" />
                    <div className="black-cover"></div>
                    <div className="timer">
                        <img src="/icons/ic_clock.png" width="50" height="50" srcSet={getSrcSet(["/icons/ic_clock.png", "/icons/ic_clock@2x.png", "/icons/ic_clock@3x.png"])} alt="" />
                        <h6 className="time">00:00:00</h6>
                    </div>
                </div>
                <div className="content">
                    <h3 className="title">Title</h3>
                    <h4 className="subTitle">SubTitle</h4>
                    <div className="cost">
                        <span className="discount">
                            <i className="fa fa-arrow-down"></i>
                            00%
                        </span>
                        0,000,000원
                    </div>
                    <div className="seller">
                        <img src="/sample/36x36.png" width="36" height="36" alt="" />
                        Seller Name
                    </div>
                    <hr className="split" />
                    <div className="run-day">
                        00월 00일 <span className="time">00:00</span>
                    </div>
                    <button type="button" className="btn-notify">
                        <img
                            src="/icons/ic_clock2_small.png"
                            width="14"
                            height="14"
                            srcSet={getSrcSet(["/icons/ic_clock2_small.png", "/icons/ic_clock2_small@2x.png", "/icons/ic_clock2_small@3x.png"])}
                            alt=""
                        />
                        알림받기
                    </button>
                </div>
            </div>
        </LNIContainer>
    );
};

/**
 * 타임딜
 */

const TimeDeal = (props) => {
    const { location, history, match } = props;
    const cp = { history, location, match };

    const [list, setList] = useState([]);
    const [timer, setTimer] = useState("");
    const [visual, setVisual] = useState();
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        setList(props.visualContentList?.filter((f) => f.visualCategory === "TIMEDEAL"));
        setVisual(props.visualList?.filter((f) => f.visualCategory === "TIMEDEAL")[0]);
    }, [props.visualContentList, props.visualList]);

    useEffect(() => {
        const timeTick = () => {
            return setInterval(() => {
                const d = moment.duration(moment(visual.endDate).diff(moment()));
                return setTimer(_U.lpad(d.get("hours"), "0", 2) + " : " + _U.lpad(d.get("minutes"), "0", 2) + " : " + _U.lpad(d.get("seconds"), "0", 2));
            }, 1000);
        };
        if (visual) {
            timeTick();
        }
        return clearTimeout(timeTick);
    }, [visual]);

    useEffect(() => {
        const items = [];

        if (isMobile) {
            for (let i = 0; i < list?.length; i++) {
                items.push(
                    <TDPContainer>
                        <TimeDealItem item={list[i]} {...cp} />
                    </TDPContainer>
                );
            }
        } else {
            for (let i = 0; i < list?.length; i++) {
                if (i + 1 >= list.length) {
                    items.push(
                        <TDPContainer>
                            <TimeDealItem item={list[i]} {...cp} />
                        </TDPContainer>
                    );
                } else {
                    items.push(
                        <TDPContainer>
                            <TimeDealItem item={list[i]} {...cp} />
                            <TimeDealItem item={list[++i]} {...cp} />
                        </TDPContainer>
                    );
                }
            }
        }

        setItemList(items);
    }, [list]);

    return (
        <TDContainer>
            <h3 className="title">오늘의 타임딜</h3>
            <h2 className="time">{timer}</h2>
            <div className="wrapper">
                <Slider infinite speed={500} autoplay={true} autoplaySpeed={5000} slidesToShow={1} slidesToScroll={1} arrows={false} pauseOnFocus={true} pauseOnHover={true}>
                    {itemList?.map((item, i) => (
                        <Fragment key={i}>{item}</Fragment>
                    ))}
                </Slider>
            </div>
            <hr className="split" />
        </TDContainer>
    );
};

const TimeDealPage = (props) => {
    return (
        <TDPContainer>
            <TimeDealItem />
            <TimeDealItem />
        </TDPContainer>
    );
};

const TimeDealItem = (props) => {
    const { location, history, match } = props;

    const goProduct = (item) => {
        if (item?.productCode) {
            history.push(`/product/${item.productCode}`);
        }
    };
    return (
        <TDPIContainer onClick={(e) => goProduct(props.item)}>
            <div className="wrapper">
                <div className="thumbnail">
                    {/* <img src="/sample/174x180.png" width="174" height="180" alt="" /> */}
                    <img src={props.item?.thumbnailFileSignedUrl || props.item?.productFileSignedUrl} alt="" />
                </div>
                <div className="content">
                    <h4 className="brand">{props.item?.productBrandName}</h4>
                    <h3 className="title">{props.item?.productName}</h3>
                    <h5 className="cost">
                        <b>{_U.addComma(props.item?.priceSale)}</b>원{props.item?.priceDiscountPercent > 0 && <span>{Math.round(props.item?.priceDiscountPercent) || 0}%</span>}
                    </h5>
                    {props.item?.priceDiscountPercent > 0 && (
                        <h5 className="old-cost">
                            <b>{_U.numberFormat(props.item?.priceNormal)}</b>원
                        </h5>
                    )}
                    {/* <div className="tags">
                        <span className="tag red">익일배송</span>
                        <span className="tag">최저가스캐너</span>
                    </div> */}
                </div>
            </div>
        </TDPIContainer>
    );
};

/**
 * 추천선물
 */
const NewArrivals = (props) => {
    const { location, history, match } = props;
    const cp = { history, location, match };

    const setEmpty = (number) => {
        const list = props.visualContentList?.filter((f) => f.visualCategory === "NEWARRIVALS");

        let remainer = list.length % number;
        const rtn = [];

        remainer = remainer > 0 ? number - remainer : remainer;

        if (remainer <= 0) {
            return "";
        }

        for (let i = 0; i < remainer; i++) {
            rtn.push(<NewArrivalItem key={i} />);
        }

        return rtn;
    };

    return (
        <NAContainer>
            <h3 className="title">추천선물</h3>

            <div className="list">
                {props.visualContentList
                    ?.filter((f) => f.visualCategory === "NEWARRIVALS")
                    ?.map((item, i) => (
                        <NewArrivalItem key={i} item={item} {...cp} />
                    ))}
                {setEmpty(isMobile ? 2 : 5)}
            </div>
            <hr className="split" />
        </NAContainer>
    );
};

const NewArrivalItem = (props) => {
    const { location, history, match, item } = props;
    const goProduct = (item) => {
        if (item?.productCode) {
            history.push(`/product/${item.productCode}`);
        }
    };

    return (
        <NAIContainer onClick={(e) => goProduct(props.item)} noHover={!props.item}>
            {item && (
                <>
                    <div className="thumbnail">
                        {/* <img src="/sample/166x210.png" width="166" height="210" alt="" /> */}
                        <img src={item?.thumbnailFileSignedUrl || item?.productFileSignedUrl} width="166" height="210" alt="" />
                    </div>
                    <div className="content">
                        <h4 className="brand">{item?.productBrandName}</h4>
                        <h3 className="title">{item?.productName}</h3>
                        <h5 className="cost">
                            <b>{_U.numberFormat(item?.priceSale)}</b>원{item?.priceDiscountPercent > 0 && <span>{Math.round(item?.priceDiscountPercent) || 0}%</span>}
                        </h5>
                        {item?.priceDiscountPercent > 0 && (
                            <h5 className="old-cost">
                                <b>{_U.numberFormat(item?.priceNormal)}</b>원
                            </h5>
                        )}
                    </div>
                </>
            )}
        </NAIContainer>
    );
};

/**
 * 인기상품
 */

const Ranking = (props) => {
    const { location, history, match } = props;
    const cp = { history, location, match };

    const [list, setList] = useState([]);

    useEffect(() => {
        const list = props.visualContentList?.filter((f) => f.visualCategory === "RANKING");
        setList(list);
    }, [props.visualContentList]);

    const setEmpty = (number) => {
        const list = props.visualContentList?.filter((f) => f.visualCategory === "RANKING");
        let remainer = list.length % number;
        const rtn = [];

        remainer = remainer > 0 ? number - remainer : remainer;

        if (remainer <= 0) {
            return "";
        }

        for (let i = 0; i < remainer; i++) {
            rtn.push(<RankingItem key={i} />);
        }

        return rtn;
    };

    return (
        <RContainer>
            <h3 className="title">인기상품</h3>
            <div className="list">
                {list?.map((item, i) => (
                    <RankingItem number={i + 1} key={i} content={item} {...cp} />
                ))}
                {setEmpty(isMobile ? 2 : 5)}
            </div>
            <div className="more">
                <button type="button" onClick={() => history.push(`/ranking`)}>
                    상품 랭킹 모두 보기
                </button>
            </div>
        </RContainer>
    );
};

const RankingItem = (props) => {
    const { location, history, match } = props;
    const { number, content } = props;

    const goProduct = (item) => {
        if (item?.productCode) {
            history.push(`/product/${item.productCode}`);
        }
    };
    return (
        <RIContainer onClick={(e) => goProduct(content)} noHover={!content}>
            {content && (
                <>
                    <div className="number">{number}</div>
                    <div className="thumbnail">
                        {/* <img src="/sample/166x210.png" width="166" height="210" alt="" /> */}
                        <img src={content?.thumbnailFileSignedUrl || content?.productFileSignedUrl} alt="" />
                    </div>
                    <div className="content">
                        <h4 className="brand">{content?.productBrandName}</h4>
                        <h3 className="title">{content?.productName}</h3>
                        <h5 className="cost">
                            <b>{_U.addComma(content?.priceSale)}</b>원{content?.priceDiscountPercent > 0 && <span>{Math.round(content?.priceDiscountPercent) || 0}%</span>}
                        </h5>
                        {content?.priceDiscountPercent > 0 && (
                            <h5 className="old-cost">
                                <b>{_U.numberFormat(content?.priceNormal)}</b>원
                            </h5>
                        )}
                        {/* <div className="tags">
							<span className="tag red">익일배송</span>
							<span className="tag">최저가스캐너</span>
						</div> */}
                    </div>
                </>
            )}
        </RIContainer>
    );
};
