/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { AppContext } from "../../Components/AppProvider";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { OrderTop } from "../../UI/Header";
import { CheckBox } from "../../UI/Product";
import { SelectBox } from "../../UI/Form";
import { _API, _U } from "../../Modules/Utils";

import Layout from "../Layout";

import ProductList from "./Cart.Product";

export default (props) => {
    const { auth } = useContext(AppContext);
    const { history } = props;

    if (!auth) {
        history.goBack();
        return null;
    }

    const [localList, setLocalList] = useState([]);
    const [overseasList, setOverseasList] = useState([]);
    const [localSummary, setLocalSummary] = useState(null);
    const [overseasSummary, setOverseasSummary] = useState(null);
    const [totalSummary, setTotalSummary] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);

    const isLogin = auth;

    const loadData = () => {
        _API.get({ path: "/user/payment/product/cart" })
            .then((res) => res.data)
            .then((data) => {
                const { overseasList, localList, localSummary, overseasSummary, totalSummary } = data;
                setLocalList(localList);
                setOverseasList(overseasList);
                setLocalSummary(localSummary);
                setOverseasSummary(overseasSummary);
                setTotalSummary(totalSummary);
            });
    };

    const onRefresh = () => {
        loadData();
    };

    useEffect(() => {
        if (auth) {
            loadData();
        } else {
            setLocalList([]);
            setOverseasList([]);
            setLocalSummary(null);
            setOverseasSummary(null);
            setTotalSummary(null);
        }
    }, [auth]);

    useEffect(() => {
        setIsEmpty(localList.length === 0 && overseasList.length === 0);
    }, [localList, overseasList]);

    const handleToBuyClick = () => {
        _API.post({ path: "/user/payment/product/cart/order/save" }).then((res) => {
            const { orderIdx } = res.data;
            history.push(`/checkout/${orderIdx}`);
        });
    };

    return (
        <Layout {...props} hideTop={true}>
            <Wrapper>
                <OrderTop active="1" {...props}>
                    장바구니
                </OrderTop>
                {/* 장바구니 비었을때 */}
                {isEmpty && <EmptyCart />}

                {/* 장바구니 있는데 비로그인 상태일때 */}
                {!isLogin && !isEmpty && <NoLogin />}

                {!isEmpty && (
                    <>
                        {localList.length > 0 && (
                            <div className="list-box">
                                <h3>한국 배송 상품</h3>
                                <ProductList {...props} list={localList} summary={localSummary} onRefresh={onRefresh} />
                            </div>
                        )}
                        {overseasList.length > 0 && (
                            <div className="list-box">
                                <h3>해외 배송 상품</h3>
                                <ProductList {...props} list={overseasList} summary={overseasSummary} onRefresh={onRefresh} />
                            </div>
                        )}
                        <div className="total-summary">
                            <div className="detail">
                                <dl>
                                    <dt>상품수</dt>
                                    <dd>
                                        <span>{totalSummary?.productCnt}</span> 개
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>상품 금액</dt>
                                    <dd>{_U.numberFormat(totalSummary?.productAmount)} 원</dd>
                                </dl>
                                <dl>
                                    <dt>할인 금액</dt>
                                    <dd>
                                        <b>{_U.numberFormat(totalSummary?.discountAmount)} 원</b>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>총 배송비</dt>
                                    <dd>{_U.numberFormat(totalSummary?.shippingAmount)} 원</dd>
                                </dl>
                            </div>
                            <div className="sum">
                                <small>총 결제 예상 금액</small>
                                <b>
                                    <span>{_U.numberFormat(totalSummary?.totalAmount)}</span> 원
                                </b>
                            </div>
                        </div>
                        <div className="buttons">
                            <button onClick={() => history.push(`/`)}>계속 쇼핑하기</button>
                            <button className="action" onClick={() => handleToBuyClick()}>
                                구매하기
                            </button>
                        </div>
                    </>
                )}
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
    width: ${(props) => props.theme.layout.productWidth}px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 80px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }

    div.list-box {
        margin-top: 60px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 21px;
            margin-top: 24px;
        }
    }

    div.list-box > h3 {
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
        margin: 0px;
    }

    div.total-summary {
        margin-top: 60px;
        border-top: 2px solid #005a60;
        border-bottom: 1px solid #efefef;

        display: flex;
        min-height: 170px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex-direction: column;
            border-top: 10px solid #f8f8f8;
            border-bottom: 0;
        }

        > div.detail {
            flex-basis: 420px;
            background: #f5f5f5;
            padding: 24px 40px;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                flex-basis: unset;
                flex: 1;
                background: transparent;
                border-bottom: 1px solid #efefef;
                padding: 40px 21px 25px;
            }

            dl {
                display: flex;
                justify-content: space-between;
                margin: 16px 0 0 0;

                :first-child {
                    margin: 0;
                }
            }

            dl > dt {
                flex: 1;
                font-family: ${(props) => props.theme.fontFamily.regular};
                color: #b8b8b8;
                font-size: 13px;
                line-height: 19px;
            }

            dl > dd {
                flex: 1;
                font-family: ${(props) => props.theme.fontFamily.medium};
                color: #3d3d3d;
                font-size: 14px;
                line-height: 20px;
                text-align: right;

                > span {
                    font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
                }

                b {
                    color: #e32222;
                    font-weight: normal;

                    > span {
                        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
                    }
                }
            }
        }

        > div.sum {
            flex: 1;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #e32222;
            font-size: 24px;
            line-height: 35px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                > b,
                > small {
                    flex: 1;
                }
                padding: 25px 21px 20px;
            }

            b {
                color: #e32222;
                font-weight: normal;
                margin-left: 16px;

                span {
                    font-family: ${(props) => props.theme.fontFamily.nanumEB};
                }

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    text-align: right;
                }
            }

            small {
                font-family: ${(props) => props.theme.fontFamily.regular};
                color: #777777;
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    > div.buttons {
        margin-top: 30px;
        text-align: center;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            display: flex;
            margin-top: 0px;
        }

        button {
            border: 1px solid #005a60;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #005a60;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                flex: 1;
                width: 100%;
                margin-left: 0px !important;
            }
        }

        button.action {
            border: 1px solid #005a60;
            background: #005a60;
            color: #ffffff;
        }
    }
`;

const EmptyCart = () => {
    return (
        <EWrapper>
            <h3>장바구니가 비어있습니다.</h3>
            <p>모아에서 상품을 확인해보세요.</p>
            <button>모아 추천 보러가기</button>
        </EWrapper>
    );
};

const EWrapper = styled.div`
    padding: 40px 0;
    text-align: center;
    h3 {
        margin: 0px;
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #777777;
        font-size: 18px;
        line-height: 26px;
    }

    p {
        margin: 12px 0 0 0px;
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #b8b8b8;
        font-size: 14px;
        line-height: 20px;
    }

    button {
        margin-top: 38px;
        background: #ffffff;
        border: 1px solid #efefef;
        text-align: center;
        padding: 14px 44px;
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #777777;
        font-size: 14px;
        line-height: 20px;
    }
`;

const NoLogin = () => {
    return (
        <NWrapper>
            <p>
                <a href="#">로그인</a> 하시고 장바구니에 담긴 <br />
                상품을 저장하세요
            </p>
        </NWrapper>
    );
};

const NWrapper = styled.div`
    padding: 80px 0;
    text-align: center;
    border-bottom: 1px solid #efefef;

    p {
        margin: 12px 0 0 0px;
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #b8b8b8;
        font-size: 14px;
        line-height: 20px;

        a {
            color: #e32222;
        }
    }
`;
