/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import swal from "sweetalert";

import { AppContext } from "../../Components/AppProvider";
import Paging, { ToPage, GetParams } from "../../UI/Paging";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

export default (props) => {
    const { resolve, reject, location, history, match, useCouponList, payAmount, validationCoupon } = props;
    // const { auth, iam } = useContext(AppContext);

    const [list, setList] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [paging, setPaging] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        loadList();
    }, [page]);

    const loadList = () => {
        const alreadyUseCouponIdxList = useCouponList && useCouponList.length > 0 ? JSON.stringify(useCouponList.map((item) => item.idx)) : null;
        console.log(":useCouponList", useCouponList)
        _API
            .get({
                path: "/user/issue/coupon/list",
                data: {
                    page,
                    numPerPage: 5,
                    payAmount,
                    alreadyUseCouponIdxList,
                },
            })
            .then((res) => res.data)
            .then((data) => {
                const { paging, list } = data;
                setList(list);
                setPaging(paging);
            });
    };

    const movePage = (page) => {
        setPage(page);
    };

    const handleSelect = (item) => {
        const check = validationCoupon(item);
        if (!check.result) {
            swal({ title: "알림", text: check.message });
            return;
        }
        resolve(item);
    };

    return (
        <Wrapper>
            <AddrWrapper>
                <AddrListWrapper>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center">쿠폰명</th>
                                <th className="text-center">할인율</th>
                                <th className="text-center">최대할인</th>
                                <th className="text-center">중복사용</th>
                                <th className="text-center">선택</th>
                            </tr>
                        </thead>
                        {list?.length > 0 ? (
                            <tbody>
                                {list?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{item.title}</td>
                                        <td className="text-center">{_U.addComma(item.discountValue)}{item.discountType === "RATE" ? "%" : "원"}</td>
                                        <td className="text-center">{_U.addComma(item.disCountMax)}원</td>
                                        <td className="text-center">{_DEF.getDefinedLabel(_DEF.DUP_USE_YN, item.dupUseYn)}</td>
                                        <td className={cx({ "text-center": true, btn: true })}>
                                            <button onClick={(e) => handleSelect(item)} className="action">
                                                선택
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td className="text-center" colSpan={5}>
                                        사용가능한 쿠폰이 없습니다.
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                    {paging && (
                        <div className="paging" style={{ marginTop: "10px" }}>
                            <Paging paging={paging} pageCallback={movePage} />
                        </div>
                    )}
                </AddrListWrapper>
            </AddrWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
	> .btn-add {
		color: #005a60;
		font-size: 14px;
		font-family: ${(props) => props.theme.fontFamily.regular};
		padding: 8px 20px;
		background: transparent;
		border: 1px solid #005a60;
		position: absolute;
		top: -67px;
		right: 0;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: relative;
			float: right;
			top: 0;
		}
	}
`;

const AddrWrapper = styled.div`
	clear: both;
	display: flex;
	width: 100%;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		flex-direction: column-reverse;
	}
`;

const AddrListWrapper = styled.div`
	flex-grow: 1;

	table {
		border-top: 1px solid #3d3d3d;
		width: 100%;
		border-spacing: 0 0;
		margin-top: 8px;
	}

	thead {
		background: #f5f5f5;
	}
	thead > tr > th {
		text-align: center;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 12px;
		line-height: 17px;
		padding: 9px 0;
		color: #777777;
		border-bottom: 1px solid #dfdfdf;
	}

	th.hidden,
	td.hidden {
		display: none;
	}

	tbody > tr > td {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		padding: 14px 0;
		color: #3d3d3d;
		border-bottom: 1px solid #dfdfdf;

		button {
			color: #777777;
			font-size: 13px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			padding: 8px 11px;
			background: transparent;
			border: 1px solid #777777;
			margin: 0 2px;
		}

		button.action {
			border: 1px solid #005a60;
			color: #005a60;
		}
	}

	tbody > tr > td.text-center {
		text-align: center;
	}

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		table {
			border-top: none;
		}
		table thead {
			display: none;
		}
		table tbody > tr {
			display: block;
			margin-bottom: 10px;
			border-top: none;
		}

		table > tbody > tr {
			border-top: 1px solid #3d3d3d;
		}

		table > tbody > tr > td {
			display: block;
			position: relative;
			padding: 14px 0 14px 36%;
			border-width: 0 0 1px 0;
			text-align: left !important;

			button {
				padding: 3px 11px;
			}
		}

		table > tbody > tr > td.btn {
			padding: 10px 0 10px 36%;
		}

		table > tbody > tr > td.addr {
			padding: 34px 0 34px 36%;
		}

		table > tbody > tr > td:before {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 30%;
			padding: 14px 0 14px 10px;
			background: #f5f5f5;
		}

		table > tbody > tr > td.addr:before {
			padding: 34px 0 34px 10px;
		}

		table > tbody > tr > td:nth-child(1):before {
			content: "쿠폰명";
		}
		table > tbody > tr > td:nth-child(2):before {
			content: "할인율";
		}
		table > tbody > tr > td:nth-child(3):before {
			content: "최대할인";
		}
		table > tbody > tr > td:nth-child(4):before {
			content: "중복사용";
		}
		table > tbody > tr > td:nth-child(5):before {
			content: "선택";
		}
	}
`;