/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

import Layout from "../Layout";
import { AppContext } from "../../Components/AppProvider";
import { _U, _API, _CK } from "../../Modules/Utils";
import { _CFG } from "../../Modules/Config";
import { getSrcSet } from "../../UI/Assets";

export default (props) => {
	const { history } = props;
	const { iam, saveAuth, saveIam } = useContext(AppContext);
	const { device, version, appversion, lang } = useContext(AppContext);
	const [depth, setDepth] = useState([]);

	const handleClose = () => {
		history.goBack();
	};

	const showDepth = (menu) => {
		const tmp = JSON.parse(JSON.stringify(depth));

		if (tmp.includes(menu)) {
			tmp.splice(tmp.indexOf(menu), 1);
		} else {
			tmp.push(menu);
		}

		setDepth(tmp);
	};

	const isOnMenu = (menu) => {
		if (depth.includes(menu)) {
			return true;
		}

		return false;
	};

	return (
		<Layout {...props} hideTop={true}>
			<Container>
				<Wrapper>
					<Close onClick={handleClose}>
						{isMobile ? (
							<img
								src="/icons/ic_remove.png"
								srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
								alt="닫기"
								width="22"
								height="22"
							/>
						) : (
							<img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} alt="닫기" width="22" height="22" />
						)}
					</Close>
					<MMWrapper>
						<h2>서비스센터</h2>
						<ul className="list-box">
							<li>
								<a href="#/service/faq">자주 하는 질문</a>
							</li>
							<li>
								<a href="#/service/notice">공지사항</a>
							</li>
							<li>
								<a href="#/cms/user/terms">서비스 이용약관</a>
							</li>
							<li>
								<a href="#/cms/user/privacy">개인정보 처리방침</a>
							</li>
						</ul>
					</MMWrapper>
				</Wrapper>
			</Container>
		</Layout>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 9998;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const Wrapper = styled.div`
	position: relative;
	padding: 30px;
	background: #ffffff;
	width: 786px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
		height: calc(100% - 60px);
	}
`;

const Close = styled.button`
	position: absolute;
	margin: 0;
	padding: 0;
	top: -42px;
	right: 0;
	width: 22px;
	height: 22px;
	border: none;
	background: transparent;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		top: 21px;
		right: 21px;
		z-index: 2;
	}
`;

const MMWrapper = styled.div`
	h2 {
		font-weight: normal;
		color: #3d3d3d;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 29px;
		font-size: 20px;
		margin: 30px 0 0 0;
	}

	.list-box {
		list-style: none;
		padding: 0;
		margin: 24px 0 0 0;
	}

	.list-box > li {
		color: #3d3d3d;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 29px;
		font-size: 20px;
		padding: 8px 0;
		a {
			color: #3d3d3d;
			text-decoration: none;
		}

		a.active {
			color: #acacac;
		}

		img {
			margin-left: 12px;
			vertical-align: top;
			margin-top: 12px;
			width: 14px;
			height: 8px;
		}
		.depth-menu {
			margin-top: 2px;
		}

		.depth-menu > a {
			margin-top: 10px;
			padding-left: 12px;
			color: #3d3d3d;
			font-family: ${(props) => props.theme.fontFamily.medium};
			line-height: 24px;
			font-size: 16px;
			text-decoration: none;
			display: block;
			:hover,
			:visited,
			:active {
				color: #3d3d3d;
			}
		}
	}

	.list-box > li.split {
		border-bottom: 1px solid #efefef;
		padding: 8px 0 0;
		margin-bottom: 8px;
	}
`;
