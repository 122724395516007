/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import cx from "classnames";

import { AppContext } from "../Components/AppProvider";

import { openPage } from "../Components/Linker";

import { _U } from "../Modules/Utils";

import { getSrcSet, getBackgroundSet } from "./Assets";

export const OrderTop = ({ children, active, history }) => {
	return (
		<OTWrapper>
			<button className="btn-back" onClick={() => history.goBack()}>
				<img src={"/icons/ic_back.png"} srcSet={getSrcSet(["/icons/ic_back.png", "/icons/ic_back@2x.png", "/icons/ic_back@3x.png"])} />
			</button>
			<h2>{children}</h2>
			{active && (
				<div>
					<Step>
						<li className={cx({ active: active === "1" })}>
							<span>01</span> 장바구니
						</li>
						<li className={cx({ active: active === "2" })}>
							<span>02</span> 주문/결제
						</li>
						<li className={cx({ active: active === "3" })}>
							<span>03</span> 주문완료
						</li>
					</Step>
				</div>
			)}
		</OTWrapper>
	);
};

const OTWrapper = styled.div`
	position: relative;
	display: flex;
	padding-bottom: 30px;
	border-bottom: 2px solid #3d3d3d;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		border-bottom: 0;
		padding-bottom: 0;
		height: 54px;
		align-items: center;
		justify-content: center;
	}

	> button.btn-back {
		display: none;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: block;
			position: absolute;
			left: 21px;
			top: 14px;
			width: 26px;
			height: 26px;
			border: none;
			background: none;
			padding: 0;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	> h2,
	> div {
		flex: 1;
	}

	> h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 24px;
		line-height: 35px;
		font-weight: normal;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			text-align: center;
		}
	}

	> div {
		display: flex;
		justify-content: flex-end;
		align-items: end;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: none;
		}
	}
`;

export const Step = styled.ul`
	list-style: none;
	padding: 0px;
	margin: 0px;

	display: table;

	> li {
		display: table-cell;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #dedede;
		font-size: 20px;
		line-height: 29px;
		position: relative;

		span {
			font-family: ${(props) => props.theme.fontFamily.nanumEB};
		}

		:before {
			content: " ";
			background-image: url("/icons/ic_breadcrumb.png");
			background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_breadcrumb.png", "/icons/ic_breadcrumb@2x.png", "/icons/ic_breadcrumb@3x.png"])});
			background-repeat: no-repeat;
			background-size: 6px 12px;
			background-position: center;
			padding: 0 12px;
		}
		:first-child:before {
			content: unset;
		}
	}

	> li.active {
		color: #3d3d3d;
	}
`;

export const MypageTop = ({ children, active, history }) => {
	return (
		<MTWrapper>
			<button className="btn-back" onClick={() => history.goBack()}>
				<img src={"/icons/ic_back.png"} srcSet={getSrcSet(["/icons/ic_back.png", "/icons/ic_back@2x.png", "/icons/ic_back@3x.png"])} />
			</button>
			<h2>{children}</h2>
		</MTWrapper>
	);
};

const MTWrapper = styled.div`
	position: relative;
	padding: 0 0 20px;
	border-bottom: 2px solid #3d3d3d;
	margin: 10px 0 20px;
	display: flex;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		border-bottom: 0;
		padding-bottom: 0;
		height: 54px;
		align-items: center;
		justify-content: center;
		margin: 0px;
	}

	> button.btn-back {
		display: none;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: block;
			position: absolute;
			left: 21px;
			top: 14px;
			width: 26px;
			height: 26px;
			border: none;
			background: none;
			padding: 0;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	> h2,
	> div {
		flex: 1;
	}

	> h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 24px;
		line-height: 35px;
		font-weight: normal;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			text-align: center;
		}
	}

	> div {
		display: flex;
		justify-content: flex-end;
		align-items: end;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: none;
		}
	}
`;
