/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import cx from "classnames";

import { AppContext } from "../Components/AppProvider";

import { openPage } from "../Components/Linker";

import { _U } from "../Modules/Utils";

import { getSrcSet } from "./Assets";
import { _DEF } from "../Modules/Config";

export default (props) => {
	const { history, data, isPicker, onPicked, isCastPicker } = props;

	let device = null;
	if (!isPicker && !isCastPicker) {
		const { device: d } = useContext(AppContext);
		device = d;
	}

	const handleOnClick = () => {
		if (isPicker) {
			onPicked && onPicked(data.code);
		} else if (isCastPicker) {
			onPicked && onPicked(data.idx);
		} else {
			openPage({ device, history, to: `/product/${data.code}` });
		}
	};

	const getDiscount = () => {
		try {
			return Math.round(data.priceDiscountPercent);
		} catch (e) {
			return data.priceDiscountPercent;
		}
	};

	return (
		<Container onClick={handleOnClick}>
			<div className="thumbnail">
				<img src={data.signedThumbnailUrl || data.signedUrl} alt="" />
			</div>
			<div className="content">
				{data.brand && <h4 className="brand">{data.brand}</h4>}

				<h3 className="title">{data.name}</h3>
				<h5 className="cost">
					<b>{_U.numberFormat(data.priceSale)}</b>원{data?.priceDiscountPercent > 0 && <span className="discount">{getDiscount()}%</span>}
				</h5>
				{data?.priceDiscountPercent > 0 && (
					<h5 className="old-cost">
						<b>{_U.numberFormat(data.priceNormal)}</b>원
					</h5>
				)}
				{/* <div className="tags">
                    <span className="tag red">익일배송</span>
                    <span className="tag">최저가스캐너</span>
                </div> */}
			</div>
		</Container>
	);
};

const Container = styled.div`
	height: 357px;
	cursor: pointer;

	:hover {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			box-shadow: unset;
		}
	}

	.thumbnail {
		margin: 10px auto 6px;
		width: 201px;
		height: 201px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			width: 100%;
			height: 201px;
			margin: 0 auto 6px;
		}
	}

	.thumbnail img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.content {
		margin: 0 10px 0;
	}

	.content > .brand {
		margin: 0;
		font-size: 13px;
		font-weight: normal;
		color: #777;
		font-family: ${(props) => props.theme.fontFamily.regular};
	}
	.content > .title {
		margin: 0;
		font-size: 16px;
		text-align: left;
		color: #3d3d3d;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-family: ${(props) => props.theme.fontFamily.regular};
	}
	.content > .cost {
		margin: 8px 0 0;
		font-size: 20px;
		font-weight: normal;
		text-align: left;
		color: #3d3d3d;
		line-height: 20px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			font-size: 18px;
		}
		b {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}
	}
	.content > .cost > span.discount {
		margin-left: 8px;
		font-size: 14px;
		font-weight: normal;
		color: #e32222;
		font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
	}
	.content > .old-cost {
		margin: 0;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		text-align: left;
		text-decoration: line-through;
		color: #dedede;
		font-family: ${(props) => props.theme.fontFamily.medium};

		b {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};
			font-weight: normal;
		}
	}
	.content > .tags {
		margin-top: 18px;
	}

	.content > .tags > .tag {
		margin-right: 5px;
		padding: 3px 9px;
		font-size: 12px;
		color: #777;
		border: solid 1px #cfcfcf;
		display: inline-block;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 2px 8px;
			margin-right: 1px;
		}
	}
	.content > .tags > .tag:last-child {
		margin-right: 0;
	}
	.content > .tags > .red {
		color: #e32222;
	}
`;

export const ListWrapper = styled.div`
	padding: 5px 4.5px;
	display: grid;
	grid-column-gap: 15px;
	grid-row-gap: 30px;
	grid-template-columns: repeat(auto-fit, 221px);

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		grid-template-columns: calc(50% - 6px) calc(50% - 6px);
		grid-column-gap: 6px;
		grid-row-gap: 16px;
		padding: 0 20px;
		justify-content: space-between;
	}
`;

/**
 * Breadcrumb
 */
export const Breadcrumb = (props) => {
	const { breadcrumb, children, keyword, brand, brandList, searchItem, onReSearch, searchPage } = props;

	const onRemoveSearchItem = (key, value) => {
		const tmp = searchItem;
		console.log(tmp);
		switch (key) {
			case "cost":
				tmp.costMin = "";
				tmp.costMax = "";
				break;
			case "brand":
				tmp.brandList?.splice(tmp.brandList?.indexOf(value), 1);

				break;
			default:
				tmp[key] = "";
				break;
		}

		onReSearch(tmp);
	};

	const onResetClick = () => {
		onReSearch({ page: 1, keyword });
	};

	const getItemPath = (url) => {
		if (brandList?.length > 0 || keyword) {
			return `${url}?q=${_U.encode({ brandList, keyword })}`;
		} else {
			return url;
		}
	};

	return (
		<TBContainer>
			{searchPage ? (
				<ul className="breadcrumb">
					<li className="item">
						{brandList?.length > 0 && !keyword ? (
							<>브랜드 검색결과</>
						) : (
							<>
								{keyword ? (
									<>
										<b>'{keyword}'</b> 에 대한 검색결과
									</>
								) : (
									"전체"
								)}
							</>
						)}
					</li>
				</ul>
			) : (
				<>
					{breadcrumb && breadcrumb.length > 0 && (
						<>
							<ul className="breadcrumb">
								{breadcrumb.map((item, i) => {
									if (breadcrumb.length - 1 === i) {
										return (
											<li key={i} className="item">
												{item.label}
											</li>
										);
									} else {
										return (
											<li key={i} className="link">
												<Link to={getItemPath(item.path)}>{item.label}</Link>
											</li>
										);
									}
								})}
							</ul>
						</>
					)}
				</>
			)}

			{children}
			{searchItem && <SearchItem {...searchItem} onResetClick={onResetClick} removeSearchItem={onRemoveSearchItem} hideKeyword={keyword && searchPage} />}
		</TBContainer>
	);
};

const SearchItem = (props) => {
	const { removeSearchItem, hideKeyword, onResetClick } = props;

	return (
		<SIWrapper>
			{!props.hideKeyword && props.keyword && (
				<div className="item" onClick={() => removeSearchItem("keyword")}>
					{props.keyword}
					<img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} />
				</div>
			)}
			{props.brandList?.length > 0 && (
				<>
					{props.brandList?.map((item, index) => (
						<div className="item" onClick={() => removeSearchItem("brand", item)} key={index}>
							{item}
							<img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} />
						</div>
					))}
				</>
			)}
			{(props.costMin || props.costMax) && (
				<div className="item" onClick={() => removeSearchItem("cost")}>
					{props.costMin && <>{_U.addComma(props.costMin)}만원</>} ~ {props.costMax && <>{_U.addComma(props.costMax)}만원</>}
					<img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} />
				</div>
			)}

			{props.discount && (
				<div className="item" onClick={() => removeSearchItem("discount")}>
					{_DEF.getDefinedLabel(_DEF.PRODUCT_DISCOUNT, props.discount)}{" "}
					<img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} />
				</div>
			)}

			{props.shipping?.length > 0 && (
				<div className="item" onClick={() => removeSearchItem("shipping")}>
					{props.shipping?.map((sItem, sIndex) => (
						<React.Fragment key={sIndex}>
							{sIndex > 0 && ", "}
							{_DEF.getDefinedLabel(_DEF.PRODUCT_SHIPPING, sItem)}
						</React.Fragment>
					))}
					<img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} />
				</div>
			)}

			{(props.brandList?.length > 0 || props.costMin || props.costMax || props.discount || props.shipping?.length > 0) && (
				<button className="btn-reset" onClick={onResetClick}>
					<img src={"/icons/ic_remove.png"} srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])} />
					초기화
				</button>
			)}
		</SIWrapper>
	);
};

const SIWrapper = styled.div`
	margin-top: 12px;
	position: relative;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
		white-space: nowrap;
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		::-webkit-scrollbar {
			display: none;
		}
	}

	.btn-reset {
		border: 1px solid #cfcfcf;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		padding: 6px 16px;
		border-radius: 16px;
		background: #ffffff;
		color: #777777;

		img {
			width: 8px;
			height: 8px;
			margin-right: 5px;
		}
	}

	.item {
		padding: 6px 16px;
		border-radius: 16px;
		background-color: #00788d;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		text-align: left;
		color: #ffffff;
		display: inline-block;
		cursor: pointer;
		margin-right: 10px;

		img {
			width: 7px;
			height: 7px;
			margin-left: 12px;
		}
	}
`;

const TBContainer = styled.div`
	.breadcrumb {
		margin: 0;
		padding: 0;
		list-style: none;
		display: block;
	}

	.breadcrumb > .link,
	.breadcrumb > .item {
		display: inline-block;
		font-size: 22px;
		font-weight: normal;
		color: #3d3d3d;
		font-family: ${(props) => props.theme.fontFamily.medium};
	}

	.breadcrumb > .item > b {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-weight: normal;
	}

	> div.keyword {
		display: inline-block;
		font-size: 22px;
		font-weight: normal;
		color: #3d3d3d;
		font-family: ${(props) => props.theme.fontFamily.medium};

		b {
			font-family: ${(props) => props.theme.fontFamily.medium};
			font-weight: normal;
		}
	}

	.breadcrumb > .link::after {
		margin: 0px 12px 3px;
		background-image: url("/icons/ic_breadcrumb.png");
		background-image: -webkit-image-set(url("/icons/ic_breadcrumb.png") 1x, url("/icons/ic_breadcrumb@2x.png") 2x, url("/icons/ic_breadcrumb@3x.png") 3x);
		content: "";
		display: inline-block;
		width: 6px;
		height: 12px;
	}

	.breadcrumb > .link > a {
		font-size: 22px;
		font-weight: 500;
		color: #3d3d3d;
		text-decoration: none;
	}
	.breadcrumb > .link > a:hover {
		text-decoration: underline;
	}
`;

/**
 * Accordion
 */

export const Accordion = (props) => {
	const { title, children, collapsed, showSearchOption, categoryLength, location } = props;

	const [isCollapsed, setIsCollapsed] = useState(typeof collapsed === "undefined" ? true : collapsed);
	const [accordionContentHeight, setAccordionContentHeight] = useState(0);

	const accordionRef = useRef(null);

	const toggleAccordion = () => {
		if (isCollapsed) {
			accordionRef.current.style.height = "0px";
		} else {
			accordionRef.current.style.height = `${accordionContentHeight + 10}px`;
		}

		setIsCollapsed(!isCollapsed);
	};

	useEffect(() => {
		setAccordionContentHeight(accordionRef.current.clientHeight);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setAccordionContentHeight(accordionRef.current.children[0].clientHeight);
		}, 500);
	}, [location]);

	useEffect(() => {
		if (accordionContentHeight !== 0) {
			toggleAccordion();
		}
	}, [accordionContentHeight]);

	return (
		<AContainer>
			<div className={cx({ "accordion-wrapper": true, "accordion-expanded": isCollapsed, "accordion-collapsed": !isCollapsed })}>
				<div className="accordion-header" onClick={toggleAccordion}>
					{title}
				</div>
				<div className="accordion-content" ref={accordionRef}>
					{children}
				</div>
			</div>
		</AContainer>
	);
};

const AContainer = styled.div`
	border-bottom: #efefef solid 1px;

	.accordion-wrapper {
	}

	.accordion-header {
		padding: 16px 0;
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 13px;
		font-weight: 500;
		color: #777;
		cursor: pointer;
	}

	.accordion-content {
		overflow: hidden;
		transition: all 0.1s ease-in-out;
	}

	.accordion-expanded > .accordion-header {
		background-image: url("/icons/ic_acc_up.png");
		background-image: -webkit-image-set(url("/icons/ic_acc_up.png") 1x, url("/icons/ic_acc_up@2x.png") 2x, url("/icons/ic_acc_up@3x.png") 3x);
		background-repeat: no-repeat;
		background-position: right center;
	}

	.accordion-collapsed > .accordion-header {
		background-image: url("/icons/ic_acc_down.png");
		background-image: -webkit-image-set(url("/icons/ic_acc_down.png") 1x, url("/icons/ic_acc_down@2x.png") 2x, url("/icons/ic_acc_down@3x.png") 3x);
		background-repeat: no-repeat;
		background-position: right center;
	}
`;

/**
 * CheckBox
 */
export const CheckBox = (props) => {
	const { title, checked, inline, onClick, value, margin } = props;

	return (
		<CBContainer inline={inline} checked={checked} onClick={onClick?.bind(this, value)} margin={margin}>
			<img
				src={`/icons/ic_check_${checked ? "on" : "off"}.png`}
				srcSet={getSrcSet(
					checked
						? ["/icons/ic_check_on.png", "/icons/ic_check_on@2x.png", "/icons/ic_check_on@3x.png"]
						: ["/icons/ic_check_off.png", "/icons/ic_check_off@2x.png", "/icons/ic_check_off@3x.png"]
				)}
				width="20"
				height="20"
				alt=""
			/>
			{title}
		</CBContainer>
	);
};

const CBContainer = styled.div`
	display: ${(props) => (props.inline ? "inline-block" : "block")};

	font-size: 13px;
	color: ${(props) => (props.checked ? "#00788d" : "#777777")};

	cursor: pointer;

	img {
		${(props) => (props.margin ? props.margin : `margin-right: 8px;`)}
		vertical-align: middle;
	}
`;

/**
 * Radio
 */
export const Radio = (props) => {
	const { title, checked, inline, onClick, value } = props;

	return (
		<CBContainer inline={inline} checked={checked} onClick={onClick?.bind(this, value)}>
			<img
				src={`/icons/ic_radio_${checked ? "on" : "off"}.png`}
				srcSet={getSrcSet(
					checked
						? ["/icons/ic_radio_on.png", "/icons/ic_radio_on@2x.png", "/icons/ic_radio_on@3x.png"]
						: ["/icons/ic_radio_off.png", "/icons/ic_radio_off@2x.png", "/icons/ic_radio_off@3x.png"]
				)}
				width="20"
				height="20"
				alt=""
			/>
			{title}
		</CBContainer>
	);
};

/**
 * Color
 */
export const ColorItem = (props) => {
	const { value, label, color, checked, onClick } = props;

	return (
		<CIContainer color={color} checked={checked}>
			<div className="color">{checked && <i className="fa fa-check"></i>}</div>
			<div className="title">{label}</div>
		</CIContainer>
	);
};

const CIContainer = styled.div`
	.color {
		margin: 0 auto;
		width: 34px;
		height: 34px;
		background-color: ${(props) => props.color};
		border: ${(props) => (props.color === "#ffffff" ? "#777777" : props.color)} solid 1px;
		border-radius: 17px;
		color: ${(props) => (props.color === "#ffffff" ? "#777777" : "#ffffff")};
		text-align: center;
	}
	.color i {
		margin-top: 10px;
	}

	.title {
		font-size: 13px;
		text-align: center;
		color: ${(props) => (props.checked ? "#e32222" : "#777777")};
	}
`;
