/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { CheckBox, Radio } from "../../UI/Product";
import { SelectBox, Input } from "../../UI/Form";
import Dialog from "../../UI/Dialog";

import Layout from "../Layout";
import DeliveryInfo from "./Order.Delivery.Info";

export default (props) => {
    const { data, location, history, match, onChange, onDeliverySelect, isOverSeas, checkIsSame } = props;
    const [shippingList, setShippingList] = useState([]);
    const [isSame, setIsSame] = useState(false);
    const { iam } = useContext(AppContext);

    const onSetDeliveryInfo = (item) => {
        onDeliverySelect(item);
    };

    useEffect(() => {
        const tempData = {};
        if (tempData && data?.orderIdx) {
            if (isSame) {
                tempData.recipientName = data?.nomemberName || "";
                tempData.recipientMobile = data?.nomemberMobile || "";
            } else {
                tempData.recipientName = "";
                tempData.recipientMobile = "";
            }
            checkIsSame(tempData);
        }
    }, [isSame]);

    useEffect(() => {}, [data]);

    const isUseShipping = (item) => {
        if (data?.recipientName === item.receiver && data?.recipientMobile === item.mobile && data?.recipientZipcode === item.zipCode) {
            return true;
        }

        return false;
    };

    const onAddrOpen = () => {
        new daum.Postcode({
            oncomplete: (result) => {
                const address = {
                    ...data,
                    receiver: data?.recipientName,
                    mobile: data?.recipientMobile,
                    reqDesc: data?.reqDesc,
                    zipCode: result.zonecode,
                    addr: result.address,
                    addrEng: result.addressEnglish,
                    addrDtl: "",
                };
                onDeliverySelect(address);
            },
        }).open();
    };

    return (
        <DWrapper>
            <div className="box">
                <h3>주문자 정보</h3>
                <dl>
                    <dt>이름</dt>
                    <dd>
                        <Input
                            placeholder="이름을 입력하세요."
                            type="text"
                            value={data?.nomemberName || ""}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            name="nomemberName"
                            className="form-control form-control-lg"
                            styles={{ color: "#3d3d3d", width: "200px" }}
                        />
                    </dd>
                </dl>
                <dl>
                    <dt>연락처</dt>
                    <dd>
                        <Input
                            placeholder="연락처를 입력하세요."
                            type="number"
                            value={data?.nomemberMobile || ""}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            name="nomemberMobile"
                            className="form-control form-control-lg"
                            styles={{ color: "#3d3d3d", width: "200px" }}
                        />
                    </dd>
                </dl>
                <dl>
                    <dt>이메일</dt>
                    <dd>
                        <Input
                            placeholder="이메일 입력하세요."
                            type="text"
                            value={data?.nomemberEmail || ""}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            name="nomemberEmail"
                            className="form-control form-control-lg"
                            styles={{ color: "#3d3d3d", width: "200px" }}
                        />
                    </dd>
                </dl>
                {isOverSeas && (
                    <dl>
                        <dt className="input">통관고유부호</dt>
                        <dd className="center">
                            <Input
                                placeholder="개인통관고유부호를 입력하세요."
                                type="text"
                                value={data?.personalCustomsCode || ""}
                                name="personalCustomsCode"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                className="form-control form-control-lg"
                                styles={{ color: "#3d3d3d", width: "200px" }}
                            />
                        </dd>
                    </dl>
                )}
            </div>
            <div className="box">
                <ReceiverWrapper>
                    <h3>
                        받는사람 정보
                        {/* <div className="shipping-list">
                            {shippingList?.map((item, index) => (
                                <div className={cx({ sItem: true, active: isUseShipping(item) })} key={index} onClick={() => onSetDeliveryInfo(item)}>
                                    {item.name} ({item.addrDtl})
                                </div>
                            ))}
                        </div> */}
                    </h3>

                    {/* <div>
                        <input type="checkbox" value={isSame} onChange={(e) => setIsSame(!isSame)} />
                        주문자와 동일
                    </div> */}
                </ReceiverWrapper>
                {/* <dl>
                    <dt className="input">이름</dt>
                    <dd className="center">
                        {shippingList.length > 0 && data?.recipientName ? (
                            <>{data?.recipientName}</>
                        ) : (
                            <Input
                                placeholder="이름을 입력하세요."
                                type="text"
                                value={data?.recipientName}
                                name="recipientName"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                className="form-control form-control-lg"
                                styles={{ color: "#3d3d3d" }}
                            />
                        )}
                    </dd>
                </dl> */}
                <dl>
                    <dt className="input">연락처</dt>
                    <dd className="center">
                        {shippingList.length > 0 && data?.recipientMobile ? (
                            <>{data?.recipientMobile}</>
                        ) : (
                            <Input
                                placeholder="연락처를 입력하세요."
                                type="text"
                                value={data?.recipientMobile}
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                name="recipientMobile"
                                className="form-control form-control-lg"
                                styles={{ color: "#3d3d3d" }}
                                desc="* 쿠폰상품 구매시는 위 번호로 쿠폰이 발행 됩니다."
                            />
                        )}
                    </dd>
                </dl>
                {/* <dl>
                    <dt className="input">배송주소</dt>
                    <dd className="center addr">
                        {shippingList.length > 0 && data?.recipientMobile ? (
                            <>
                                {data?.recipientZipcode ? `(${data?.recipientZipcode})` : ""}
                                {data?.recipientAddr}
                            </>
                        ) : (
                            <>
                                <div>
                                    <button onClick={onAddrOpen} className="search-addr-btn">
                                        주소찾기
                                    </button>
                                    <Input
                                        placeholder="우편번호"
                                        type="text"
                                        value={data?.recipientZipcode}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        name="recipientZipcode"
                                        className="form-control form-control-lg"
                                        styles={{ width: "100px", color: "#3d3d3d" }}
                                        readOnly
                                        onClick={onAddrOpen}
                                    />
                                </div>
                                <div>
                                    <Input
                                        placeholder="주소를 입력하세요."
                                        type="text"
                                        value={data?.recipientAddr}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        name="recipientAddr"
                                        className="form-control form-control-lg"
                                        styles={{ width: "50%", color: "#3d3d3d" }}
                                    />
                                </div>
                            </>
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="input">배송주소상세</dt>
                    <dd className="center">
                        {shippingList.length > 0 && data?.recipientAddrDtl ? (
                            <>{data?.recipientAddrDtl}</>
                        ) : (
                            <Input
                                placeholder="상세 주소를 입력하세요."
                                type="text"
                                value={data?.recipientAddrDtl}
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                name="recipientAddrDtl"
                                className="form-control form-control-lg"
                                styles={{ width: "50%", color: "#3d3d3d" }}
                            />
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="input">배송 요청사항</dt>
                    <dd>
                        <Input
                            placeholder="배송 요청사항을 입력하세요."
                            type="text"
                            value={data?.reqDesc}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            name="reqDesc"
                            className="form-control form-control-lg"
                            styles={{ width: "50%", color: "#3d3d3d" }}
                        />
                    </dd>
                </dl> */}
            </div>
        </DWrapper>
    );
};

const DWrapper = styled.div`
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: calc(100% - 42px);
        padding: 0 21px;
    }

    > div.box {
        padding: 40px 0;
        border-bottom: 1px solid #efefef;

        > h3 {
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 18px;
            line-height: 26px;
            font-weight: normal;
            margin: 0px;

            button {
                background: #ffffff;
                border: 1px solid #efefef;
                text-align: center;
                padding: 8px 17px;
                font-weight: normal;
                font-family: ${(props) => props.theme.fontFamily.medium};
                color: #777777;
                font-size: 13px;
                line-height: 19px;
                margin-left: 8px;

                :first-child {
                    margin-left: 24px;
                }
            }
        }

        .shipping-list {
            display: block;
            flex-wrap: wrap;
            margin-top: 8px;

            > .sItem {
                display: inline-block;
                padding: 4px 16px;
                border: 1px solid #dedede;
                font-family: ${(props) => props.theme.fontFamily.regular};
                color: #777777;
                font-size: 13px;
                border-radius: 24px;
                margin-right: 6px;
                cursor: pointer;
            }

            > .sItem.active {
                border: 1px solid #e32222;
                color: #e32222;
            }
        }

        dl {
            display: flex;
            justify-content: space-between;

            :first-child {
                margin: 0;
            }
        }

        dl > dt {
            flex-basis: 100px;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #b8b8b8;
            font-size: 14px;
            line-height: 20px;
        }

        dl > dt.input {
            line-height: 39px;
        }

        dl > dd {
            flex: 1;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 14px;
            line-height: 20px;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                margin-left: 0;
                input {
                    width: calc(100% - 20px) !important;
                }
            }

            button {
                background: #ffffff;
                border: 1px solid #efefef;
                text-align: center;
                padding: 5px 14px;
                font-weight: normal;
                font-family: ${(props) => props.theme.fontFamily.medium};
                color: #777777;
                font-size: 12px;
                line-height: 17px;
                margin-left: 16px;
            }
        }

        dd.center {
            display: flex;
            align-items: center;

            > div {
                flex: 1;
            }
        }

        dd.center.addr {
            flex-wrap: wrap;

            > div:first-child {
                display: flex;
                margin-bottom: 5px;
                > button {
                    margin-left: 0;
                    color: #777777;
                    font-size: 13px;
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    padding: 8px 20px;
                    background: transparent;
                    border: 1px solid #777777;
                    margin-right: 5px;
                }

                > span {
                    margin: 8px 0 0 10px;
                }

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    position: relative;
                    flex-wrap: wrap;
                    > button,
                    > div {
                        flex: 1;
                    }

                    > button {
                        padding: 8px 0px;
                    }

                    span {
                        flex-basis: 100%;
                        margin: 8px 0;
                    }
                }
            }

            > div:last-child {
                flex-basis: 100%;
            }
        }
    }
`;

const ReceiverWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        font-family: Noto Sans KR;
        font-weight: 500 !important;
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
        margin: 0px;
    }
`;
