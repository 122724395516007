/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";
import nl2br from "nl2br";
import ImgsViewer from "react-images-viewer";
import { isMobile } from "react-device-detect";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { SelectBox } from "../../UI/Form";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import { AppContext } from "../../Components/AppProvider";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import swal from "sweetalert";
import Dialog from "../../UI/Dialog";

import ReportForm from "../Reviews/ReportModal";

export default (props) => {
	const { productInfo } = props;
	const [review, setReview] = useState([]);
	const [totalReview, setTotalReview] = useState([{ totalCnt: 0, totalPoint: 0 }]);
	const [paging, setPaging] = useState(null);

	const loadData = (page, sort) => {
		if (productInfo) {
			const params = { productIdx: productInfo?.idx };
			if (page) params.page = page;
			if (sort) params.sort = sort;

			_API
				.get({ path: "/product/search/item/review", data: { ...params } })
				.then((res) => res.data)
				.then((data) => {
					const { paging, list, summary } = data;
					setPaging(paging);
					setTotalReview(summary);
					setReview(list);
					// if (paging && params.page !== 1) {
					// 	setReview([...review, ...list]);
					// } else {
					// 	setReview(list);
					// }
				});
		}
	};

	const changeSort = (page, sort) => {
		loadData(page, sort);
	};

	useEffect(() => {
		loadData(1);
	}, [productInfo]);

	return (
		<Fragment>
			<Wrapper>
				<div className="top">
					상품리뷰 {totalReview?.totalCnt || 0}
					<div>
						<PDReviewStar sum={totalReview?.totalPoint || 0} count={totalReview?.totalCnt || 0} />
					</div>
				</div>

				{/* {productReview?.length > 0 && <PDReviewTopGrapth />} */}

				{review?.length > 0 ? (
					<PDReviewList review={review} changeSort={changeSort} paging={paging} />
				) : (
					<PDReviewEmpty>
						<div>구매하신 분의 후기를 기다리고 있습니다.</div>
					</PDReviewEmpty>
				)}
			</Wrapper>

			{/* <Wrapper>
                <div className="top">
                    판매자 전체 상품리뷰 {totalReview?.length}
                    <div>
                        <PDReviewStar sum={10} count={5} />
                    </div>
                </div>

                {totalReview?.length > 0 && <PDReviewTopGrapth />}

                {totalReview?.length > 0 ? (
                    <PDReviewList />
                ) : (
                    <PDReviewEmpty>
                        <div>구매하신 분의 후기를 기다리고 있습니다.</div>
                    </PDReviewEmpty>
                )}
            </Wrapper> */}
		</Fragment>
	);
};

const Wrapper = styled.div`
	margin-top: 60px;
	border-bottom: 1px solid #efefef;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-top: 40px;
		border-bottom: 0;
	}
	> div.top {
		width: 100%;
		height: 80px;
		font-size: 16px;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #3d3d3d;
		line-height: 18px;
		text-align: center;
		background: #f5f5f5;
		display: flex;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		}

		> div {
			position: relative;
			margin-left: 16px;
			padding-left: 16px;
		}

		> div:before {
			content: "";
			width: 1px;
			position: absolute;
			left: 0;
			top: 10px;
			height: 10px;
			background: #dfdfdf;
		}

		> div.star > img {
			width: 28px;
			height: 27px;
		}
	}

	> div.top-percent {
		background: #f5f5f5;
		position: relative;
		padding: 30px 103px;

		:before {
			content: "";
			height: 1px;
			width: calc(100% - 60px);
			position: absolute;
			left: 30px;
			right: 30px;
			top: 0px;
			background: #efefef;
		}

		ul {
			margin: 0px;
			padding: 0px;
			list-style: none;
			display: flex;
		}

		ul > li {
			flex: 1;
			padding-right: 100px;
			:last-child {
				padding-right: 0;
			}

			h4 {
				text-align: center;
				font-size: 13px;
				font-family: ${(props) => props.theme.fontFamily.medium};
				color: #3d3d3d;
				line-height: 17px;
			}
		}

		ul > li > dl {
			display: flex;
		}

		ul > li > dl > dt {
			flex: 1;
			font-size: 12px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			line-height: 17px;
			flex-grow: 3;
			margin: 0;
			padding: 0;
		}

		ul > li > dl > dd {
			flex: 1;
			font-size: 12px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			line-height: 17px;
			flex-grow: 7;
			padding: 0;
			display: flex;
			align-items: center;

			:last-child {
				flex-grow: 1;
				margin-left: 8px;
			}

			margin: 0;
		}
	}
`;

const PDReviewEmpty = styled.div`
	background-image: url("/icons/ic_message.png");
	background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_message.png", "/icons/ic_message@2x.png", "/icons/ic_message@3x.png"])});
	background-repeat: no-repeat;
	background-size: 239px 184px;
	background-position: center;
	font-family: ${(props) => props.theme.fontFamily.regular};
	font-size: 14px;
	line-height: 20px;
	color: #b8b8b8;
	text-align: center;
	width: 100%;
	height: 346px;
	position: relative;
	div {
		position: absolute;
		bottom: 50px;
		left: 0;
		width: 100%;
		text-align: center;
	}
`;

const BarGraph = styled.div`
	background: #dfdfdf;
	width: 100%;
	height: 2px;
	position: relative;
	margin-left: 10px;

	${(props) =>
		props.width > 0
			? `
       :after{
           content:" ";
           background:#e32222;
           position:absolute;
           width:${props.width}%;
           height:2px;
       } 
    `
			: ``}
`;

const PDReviewTopGrapth = (props) => {
	return (
		<div className="top-percent">
			<ul>
				<li>
					<h4>배송</h4>
					<dl>
						<dt>느려요</dt>
						<dd>
							<BarGraph width={0} />
						</dd>
						<dd>0%</dd>
					</dl>
					<dl>
						<dt>적당해요</dt>
						<dd>
							<BarGraph width={22} />
						</dd>
						<dd>22%</dd>
					</dl>
					<dl>
						<dt>빨라요</dt>
						<dd>
							<BarGraph width={77} />
						</dd>
						<dd>77%</dd>
					</dl>
				</li>
				<li>
					<h4>포장</h4>
					<dl>
						<dt>별로예요</dt>
						<dd>
							<BarGraph width={0} />
						</dd>
						<dd>0%</dd>
					</dl>
					<dl>
						<dt>보통이예요</dt>
						<dd>
							<BarGraph width={22} />
						</dd>
						<dd>22%</dd>
					</dl>
					<dl>
						<dt>좋아요</dt>
						<dd>
							<BarGraph width={77} />
						</dd>
						<dd>77%</dd>
					</dl>
				</li>
				<li>
					<h4>가격</h4>
					<dl>
						<dt>싸요</dt>
						<dd>
							<BarGraph width={0} />
						</dd>
						<dd>0%</dd>
					</dl>
					<dl>
						<dt>적당해요</dt>
						<dd>
							<BarGraph width={22} />
						</dd>
						<dd>22%</dd>
					</dl>
					<dl>
						<dt>비싸요</dt>
						<dd>
							<BarGraph width={77} />
						</dd>
						<dd>77%</dd>
					</dl>
				</li>
			</ul>
		</div>
	);
};

const PDReviewStar = (props) => {
	const { sum, count } = props;

	const getReviewStar = () => {
		const star = [];
		if (!sum || !count) {
			for (let i = 1; i <= 5; i++) {
				star.push(
					<img key={`type-1-${i}`} src="/icons/ic_star_off.png" srcSet={getSrcSet(["/icons/ic_star_off.png", "/icons/ic_star_off@2x.png", "/icons/ic_star_off@3x.png"])} />
				);
			}
		} else {
			let avg = sum / count;
			avg = Math.round(parseFloat(avg.toFixed(1)));
			if (avg > 5) {
				avg = 5;
			}
			const left = 5 - avg;

			for (let i = 1; i <= avg; i++) {
				star.push(<img key={`type-2-${i}`} src="/icons/ic_star_on.png" srcSet={getSrcSet(["/icons/ic_star_on.png", "/icons/ic_star_on@2x.png", "/icons/ic_star_on@3x.png"])} />);
			}
			if (left < 5) {
				for (let i = 1; i <= left; i++) {
					star.push(
						<img key={`type-3-${i}`} src="/icons/ic_star_off.png" srcSet={getSrcSet(["/icons/ic_star_off.png", "/icons/ic_star_off@2x.png", "/icons/ic_star_off@3x.png"])} />
					);
				}
			}
		}

		return star;
	};

	return <div className="star">{getReviewStar()}</div>;
};

const PDReviewList = (props) => {
	const { review, paging } = props;
	const [sort, setSort] = useState("last");

	const changeSort = (c) => {
		setSort(c);
		props.changeSort && props.changeSort(1, c);
	};

	const nextPage = () => {
		props.changeSort && props.changeSort(paging.nextPage, sort);
	};

	const movePage = (page) => {
		props.changeSort && props.changeSort(page, sort);
	};

	return (
		<PDReviewListWrapper>
			<div className="top">
				<ul>
					<li className={cx({ active: sort === "last" })} style={{ cursor: "pointer" }} onClick={(e) => changeSort("last")}>
						· 최신 등록순
					</li>
					<li className={cx({ active: sort === "point" })} style={{ cursor: "pointer" }} onClick={(e) => changeSort("point")}>
						· 최고 평점순
					</li>
					{/* <li className={cx({ active: sort === "like" })} style={{ cursor: "pointer" }} onClick={(e)=>changeSort("like")}>· 추천 많은순</li> */}
					<li className={cx({ active: sort === "photo" })} style={{ cursor: "pointer" }} onClick={(e) => changeSort("photo")}>
						· 포토후기 모아보기
					</li>
				</ul>

				{/* <button>작성하기</button> */}
			</div>
			<div className="list">
				{/* <PDReviewItem /> */}
				{review?.map((item, i) => (
					<PDReviewItem key={i} review={item} />
				))}
			</div>
			{/* {paging && paging.pageNo < paging.endPageNo && <div className="paging" style={{ cursor: "pointer" }} onClick={(e) => nextPage()}>더보기</div>} */}
			{paging && (
				<div className="paging">
					<Paging paging={paging} pageCallback={movePage} />
				</div>
			)}
		</PDReviewListWrapper>
	);
};

const PDReviewItem = (props) => {
	const { review } = props;
	const { auth } = useContext(AppContext);
	const [more, setMore] = useState(false);
	const [images, setImages] = useState([]);
	const [imageView, setImageView] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);

	useEffect(() => {
		const tmp = [];

		for (let i in review?.imageList) {
			const item = review.imageList[i];
			tmp.push({
				src: item.fileSignedUrl,
				thumbnail: item.fileSignedUrl,
			});
		}

		setImages(tmp);
	}, [review]);

	const onReportClick = () => {
		if (!auth) {
			_U.postMessage("LOGIN");
			return;
		}

		Dialog({
			title: `리뷰 신고`,
			body: <ReportForm {...{ bbsIdx: review?.bbsIdx }} />,
		})
			.then((data) => {
				if (data === "Y") {
					swal({ title: "알림", text: "리뷰 신고가 정상 접수 되었습니다." });
				}
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	return (
		<ul
			className={cx({ item: true, "is-more": more })}
			onClick={() => {
				if (isMobile) {
					setMore(!more);
				}
			}}
		>
			<li className="summary">
				<div className="name">{review?.clientId}</div>
				<PDReviewStar sum={review?.point || 0} count={1} />
				{/* <div className="desc">정사이즈에요</div> */}
				<div className="size">{review?.orderItem}</div>
			</li>
			<li className="content">
				<div className="option">
					{review?.brandName}
					<span className={cx({ "hide-bar": !review?.brandName })}>{review?.productName}</span>
				</div>

				{review?.imageList?.length > 0 && (
					<div
						className={cx({ image: true, multi: review?.imageList?.length > 1 })}
						onClick={(e) => {
							e.stopPropagation();
							setImageView(true);
						}}
					>
						{review?.imageList && review?.imageList.length > 0 && <img src={review?.imageList[0].fileSignedUrl} />}
					</div>
				)}

				<div className="title">
					<div className="size">{review?.orderItem}</div>
					<div className={cx({ text: true, "is-more": more })}>
						<div dangerouslySetInnerHTML={{ __html: nl2br(review?.content || "") }} />
						{more && (
							<small className="more" onClick={() => setMore(false)}>
								접기
							</small>
						)}
					</div>
					{!more && (
						<small className="more" onClick={() => setMore(true)}>
							더보기
						</small>
					)}

					<ImgsViewer
						imgs={images}
						isOpen={imageView}
						currImg={imageIndex}
						onClickPrev={() => setImageIndex(imageIndex > 0 ? imageIndex - 1 : 0)}
						onClickNext={() => setImageIndex(imageIndex === images.length ? images.length : imageIndex + 1)}
						onClose={() => setImageView(false)}
						showThumbnails={true}
						onClickThumbnail={(i) => setImageIndex(i)}
					/>
				</div>
				<div className="buttons">
					{/* <button className="up">
						<img src="/icons/ic_thumb_up_off.png" srcSet={getSrcSet(["/icons/ic_thumb_up_off.png", "/icons/ic_thumb_up_off@2x.png", "/icons/ic_thumb_up_off@3x.png"])} /> 1,255
					</button>
					<button className="down">
						<img src="/icons/ic_thumb_down_off.png" srcSet={getSrcSet(["/icons/ic_thumb_down_off.png", "/icons/ic_thumb_down_off@2x.png", "/icons/ic_thumb_down_off@3x.png"])} /> 75
					</button> */}

					<span className="date">{moment(review?.regDate).format("YYYY-MM-DD HH:mm")}</span>
					<span className="report" onClick={onReportClick}>
						신고
					</span>
					{/* {review?.commentCnt > 0 && (
						<span className="comment">
							<img src="/icons/ic_comment.png" srcSet={getSrcSet(["/icons/ic_comment.png", "/icons/ic_comment@2x.png", "/icons/ic_comment@3x.png"])} />
							판매자가 댓글을 남겼습니다.
						</span>
					)} */}
				</div>
			</li>
			<li className={cx({ image: true, multi: review?.imageList?.length > 1 })} onClick={() => setImageView(true)}>
				<div>{review?.imageList && review?.imageList.length > 0 && <img src={review?.imageList[0].fileSignedUrl} />}</div>
			</li>
		</ul>
	);
};

const PDReviewListWrapper = styled.div`
	margin-top: 32px;
	padding-bottom: 60px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}
	> div.top {
		position: relative;
		height: 26px;
	}

	> div.top > button {
		position: absolute;
		right: 0;
		top: 0;
		background: #00788d;
		border: none;
		outline: none;
		color: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		line-height: 18px;
		padding: 4px 18px;
		cursor: pointer;
	}

	> div.top > ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: table;
	}

	> div.top > ul > li {
		display: table-cell;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #3d3d3d;
		font-size: 12px;
		line-height: 17px;
		position: relative;
		padding-left: 24px;
		vertical-align: bottom;

		:before {
			content: "";
			position: absolute;
			left: 12px;
			bottom: 4px;
			width: 1px;
			height: 8px;
			background: #dfdfdf;
		}

		:first-child {
			padding-left: 0;
			:before {
				content: unset;
			}
		}
	}

	> div.top > ul > li.active {
		color: #00788d;
	}

	> div.list {
		width: 100%;
		border-top: 1px solid #3d3d3d;
		margin-top: 8px;
	}

	> div.list > ul {
		list-style: none;
		padding: 16px;
		margin: 0;
		display: flex;
		border-bottom: 1px solid #dfdfdf;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 16px 0;
			flex-direction: column;
			position: relative;

			background-image: url("/icons/ic_acc_down.png");
			background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_down.png", "/icons/ic_acc_down@2x.png", "/icons/ic_acc_down@3x.png"])});
			background-repeat: no-repeat;
			background-size: 10px 6px;
			background-position: right center;
		}
	}

	> div.list > ul.is-more {
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			background-image: url("/icons/ic_acc_up.png");
			background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_acc_up.png", "/icons/ic_acc_up@2x.png", "/icons/ic_acc_up@3x.png"])});
		}
	}

	> div.list > ul > li.content {
		flex: 1;
		padding-right: 30px;

		> div.title > div.size {
			display: none;
		}

		> div.image {
			display: none;
		}

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 13px 0 0 0;

			> div.title > div.size {
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #9a9a9a;
				font-size: 13px;
				line-height: 17px;
				display: block;
			}

			> div.image {
				display: inline-block;
				vertical-align: top;
				margin-right: 16px;
				img {
					width: 60px;
					height: 60px;
					object-fit: cover;
				}
			}

			> div.image.multi {
				position: relative;
				:before {
					content: "";
					position: absolute;
					top: -4px;
					left: 2px;
					right: 2px;
					height: 4px;
					background-color: #969696;
				}

				:after {
					content: "";
					position: absolute;
					top: -8px;
					left: 4px;
					right: 4px;
					height: 4px;
					background-color: #c8c8c8;
				}
			}
		}

		div.option {
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 13px;
			line-height: 17px;
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
			span {
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #9a9a9a;
				font-size: 13px;
				line-height: 17px;
				padding-left: 12px;
				margin-left: 12px;
				position: relative;

				:before {
					content: "";
					position: absolute;
					left: 0px;
					bottom: 4px;
					width: 1px;
					height: 8px;
					background: #dfdfdf;
				}
			}

			span.hide-bar {
				padding-left: 0px !important;
				margin-left: 0px !important;
				:before {
					content: unset;
				}
			}
		}

		div.title {
			margin-top: 11px;
			position: relative;
			display: flex;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex-direction: column;
				display: inline-block;
				margin-top: 0px;
				width: calc(100% - 106px);
				padding-right: 30px;
			}

			div.text {
				font-family: ${(props) => props.theme.fontFamily.medium};
				color: #3d3d3d;
				font-size: 13px;
				line-height: 17px;

				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					margin-top: 6px;
				}
			}

			div.text.is-more {
				overflow: auto;
				text-overflow: unset;
				display: block;
			}

			small.more {
				font-family: ${(props) => props.theme.fontFamily.regular};
				color: #c1c1c1;
				font-size: 10px;
				line-height: 15px;
				cursor: pointer;
				margin-left: 4px;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					display: none;
				}
			}
		}

		div.buttons {
			clear: both;
			margin-top: 13px;
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				position: absolute;
				top: 16px;
				right: 0;
				margin-top: 0;
			}
			button {
				border: 1px solid #cfcfcf;
				background: #ffffff;
				font-family: ${(props) => props.theme.fontFamily.light};
				font-size: 10px;
				line-height: 12px;
				color: #c1c1c1;
				padding: 6px 11px;
				margin-right: 4px;
				cursor: pointer;

				img {
					width: 12px;
					height: 12px;
					vertical-align: bottom;
				}
			}

			span.date {
				/* margin-left: 12px; */
				font-family: ${(props) => props.theme.fontFamily.light};
				font-size: 13px;
				line-height: 13px;
				color: #c1c1c1;
			}

			span.report {
				cursor: pointer;
				margin-left: 12px;
				font-family: ${(props) => props.theme.fontFamily.light};
				font-size: 13px;
				line-height: 13px;
				color: #c1c1c1;

				position: relative;
				padding-left: 12px;

				:before {
					content: "";
					position: absolute;
					left: 0px;
					bottom: 4px;
					width: 1px;
					height: 8px;
					background: #dfdfdf;
				}

				img {
					width: 12px;
					height: 13px;
					vertical-align: middle;
					margin-right: 4px;
				}
			}

			span.comment {
				margin-left: 12px;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 13px;
				line-height: 18px;
				color: #9a9a9a;

				position: relative;
				padding-left: 12px;

				:before {
					content: "";
					position: absolute;
					left: 0px;
					bottom: 4px;
					width: 1px;
					height: 8px;
					background: #dfdfdf;
				}

				img {
					width: 12px;
					height: 13px;
					vertical-align: middle;
					margin-right: 4px;
				}
			}
		}
	}

	> div.list > ul > li.summary {
		flex-basis: 150px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-direction: column;
			flex: 1;

			> div {
				display: inline-block;
			}
		}

		div.star {
			margin-top: 4px;
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				margin-top: 0;
				margin-left: 8px;
			}
		}

		div.star > img {
			width: 17px;
			height: 15px;
		}

		div.name {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 13px;
			line-height: 18px;
		}

		div.desc {
			margin-top: 12px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 13px;
			line-height: 17px;
		}

		div.size {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #9a9a9a;
			font-size: 13px;
			line-height: 17px;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				display: none;
			}
		}
	}

	> div.list > ul > li.image {
		flex-basis: 60px;
		display: flex;
		align-items: center;
		cursor: pointer;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: none;
		}

		img {
			width: 60px;
			height: 60px;
		}
	}

	> div.list > ul > li.image.multi > div {
		position: relative;
		:before {
			content: "";
			position: absolute;
			top: -4px;
			left: 2px;
			right: 2px;
			height: 4px;
			background-color: #969696;
		}

		:after {
			content: "";
			position: absolute;
			top: -8px;
			left: 4px;
			right: 4px;
			height: 4px;
			background-color: #c8c8c8;
		}
	}

	> div.paging {
		padding: 10px 0;
		text-align: center;
	}
`;
