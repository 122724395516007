/* eslint-disable */
import React, { Fragment, useContext, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isMobile, isIOS } from "react-device-detect";

import { AppContext } from "../Components/AppProvider";
import { _CK, _API, _U } from "../Modules/Utils";
import { _CFG } from "../Modules/Config";

import Dialog from "../UI/Dialog";
import Login from "./Layout.Login";

export default (props) => {
    const { location, history, match } = props;
    const { auth, saveAuth, saveIam, saveSnsProfile, iam } = useContext(AppContext);
    const { device, version, appversion, lang } = useContext(AppContext);

    const setRecentMuliti = () => {
        if (_CK.get("RECENT-PRODUCT") && _CK.get("RECENT-PRODUCT").length > 0) {
            _API.post({ path: "/product/recent/multi", data: { productList: _CK.get("RECENT-PRODUCT") } }).then((res) => {
                _U.postMessage("READ-RECENT-PRODUCT");
            });
        }
    };

    const handleLogin = (data) => {
        if (_CK.isLogin()) {
            return true;
        }
        Dialog({ title: "", body: <Login {...{ location, history, match, device, version, appversion, lang, orderData: data }} />, width: 354 })
            .then((data) => {
                console.log("handleLogin -> data", data);

                if (data) {
                    const { snsType } = data;
                    if (snsType) {
                        snsLoginCheck(data);
                    } else if (data?.isNoMemberOrder) {
                        // 비회원 주문
                        history.push(`/checkout/${data?.orderIdx}`);
                    } else {
                        const { accessKey, data: iam } = data;
                        _CK.setAuth(accessKey);
                        saveAuth(accessKey);
                        saveIam(iam);
                        _CK.set("device", device);
                        _CK.set("version", version);
                        _CK.set("appversion", appversion);
                        _CK.set("lang", lang);

                        if (device && device !== "null") {
                            document.location.href = `${_CFG.appSchema}logined?auth=${accessKey}&encoded=${encodeURIComponent(accessKey)}`;
                        }
                    }
                }
            })
            .catch((e) => {
                if (e && e.url) {
                    history.push(e.url);
                }
            });
    };

    const handleLoginCheckLink = (url) => {
        if (handleLogin()) {
            history.push(url);
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();
        _CK.clearAuth();
        saveAuth("");
        saveIam(null);
        _CK.set(`passwordCert${iam?.idx}`, "");

        if (device && device !== "null") {
            document.location.href = `${_CFG.appSchema}logout`;
        } else {
            history.push("/");
        }
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "LOGIN":
                    console.log("onReceivedMessage -> data", data);
                    handleLogin(data.data);
                    break;
                case "LOGINED-MESSAGE":
                    if (data.data && data.data.snsType) {
                        snsLoginCheck(data.data);
                    }

                    break;
            }
        });
    };

    const snsLoginCheck = (data) => {
        const { id, accessToken: token, snsType: type, profile } = data;

        _API.post({
            path: "/user/login/sns",
            data: {
                type,
                id,
                token,
                profile,
            },
        }).then((res) => {
            const { data: resData } = res;

            if (!resData) {
                saveSnsProfile(data);
                history.push("/user/signup");
            } else {
                const { accessKey, data: iam } = resData;

                _CK.setAuth(accessKey);
                saveAuth(accessKey);
                saveIam(iam);
                _CK.set("device", device);
                _CK.set("version", version);
                _CK.set("appversion", appversion);
                _CK.set("lang", lang);

                if (device && device !== "null") {
                    document.location.href = `${_CFG.appSchema}logined?auth=${accessKey}&encoded=${encodeURIComponent(accessKey)}`;
                } else if (isMobile && type === "KAKAO") {
                    window.location.reload();
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        if (auth) {
            setRecentMuliti();
        }
    }, [auth, location]);

    return (
        <Container>
            <Wrapper>
                <div className="title">
                    <h1>24시간 모바일쿠폰 모아</h1>
                </div>
                <div className="link">
                    {auth ? (
                        <Fragment>
                            <a href={void 0} onClick={handleLogout}>
                                로그아웃
                            </a>
                            ·<Link to="/user/my">마이페이지</Link>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <a href={void 0} onClick={handleLogin}>
                                로그인
                            </a>
                            ·<Link to="/user/signup">회원가입</Link>
                        </Fragment>
                    )}
                    ·
                    <a href={void 0} onClick={() => handleLoginCheckLink("/user/my")}>
                        주문배송조회
                    </a>
                    ·<Link to="/service/faq">서비스센터</Link>
                </div>
            </Wrapper>
        </Container>
    );
};

/**
 * Styled
 */
const Container = styled.div`
    height: 24px;
    background-color: #4e4e4e;
    color: #ffffff;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        display: none;
    }
`;

const Wrapper = styled.div`
    margin: 0 auto;
    width: ${(props) => props.theme.layout.width}px;
    display: flex;

    .title {
        flex: 1;
    }

    .title h1 {
        margin: 4px 0;
        font-size: 10px;
        font-weight: normal;
    }

    .link {
        text-align: right;
    }
    .link a {
        color: #ffffff;
        font-size: 10px;
        text-decoration: none;
    }
    .link a:hover {
        text-decoration: underline;
    }
`;
