/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import { _CFG } from "../Modules/Config";
import { _CK } from "../Modules/Utils";
import { AppContext } from "../Components/AppProvider";

import TopBand from "./Layout.TopBand";
import SearchBar from "./Layout.SearchBar";
import Navigation from "./Layout.Navigation";
import Footer from "./Layout.Footer";
import SiteMenu from "./Layout.SiteMenu";

import { _API, _U } from "../Modules/Utils";
import { getSrcSet } from "../UI/Assets";

import Dialog from "../UI/Dialog";

export default (props) => {
    const { auth } = useContext(AppContext);
    const { history, location, match, children, hideTop, showMenu, hideMenu, isStreamPage } = props;
    const [isDark, setIsDark] = useState(props.isDark);
    const cp = { history, location, match, isDark, hideTop, showMenu };

    useEffect(() => {}, [auth]);

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "DARK-DISABLED":
                    setIsDark(false);
                    break;
                case "DARK-ENABLED":
                    setIsDark(true);
                    break;
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <LayoutContainer isDark={isDark} hideTop={hideTop}>
            {!isStreamPage && <TopBand {...cp} />}
            {!isStreamPage && <SearchBar {...cp} />}
            {!hideMenu && <Navigation {...cp} />}
            {/* onMouseOver={() => _U.postMessage("HIDE-SIDEMENU")} */}
            <div className="root-contents">{children}</div>

            {(!hideTop || !isMobile) && !isStreamPage && <Footer {...cp} />}
            {!hideTop && isMobile && <MobileMenu {...cp} />}

            {!isStreamPage && <SiteMenu />}
            {isMobile && (
                <button className="btn-top" onClick={() => window.scrollTo(0, 0)}>
                    <img src={"/icons/ic_top_arrow.png"} srcSet={getSrcSet(["/icons/ic_top_arrow.png", "/icons/ic_top_arrow@2x.png", "/icons/ic_top_arrow@3x.png"])} />
                </button>
            )}
            {/* {!isStreamPage && <ChannelTalk />} */}
        </LayoutContainer>
    );
};

export const StreamerLayout = (props) => {
    const { auth, iam } = useContext(AppContext);
    const { history, location, match, children } = props;
    const cp = { history, location, match };

    if (!auth || iam?.isStreamer !== "Y") {
        history.goBack();
        return null;
    }

    useEffect(() => {
        if (!auth || iam?.isStreamer !== "Y") {
            history.push("/");
        }
    }, [auth]);

    return (
        <LayoutContainer>
            <TopBand {...cp} />
            <div>{children}</div>
            <Footer {...cp} />
        </LayoutContainer>
    );
};

/**
 * Styled Components
 */
const LayoutContainer = styled.div`
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        ${(props) => (props.hideTop ? `` : `padding-bottom: 56px;`)}
    }

    div.root-contents {
        min-height: 650px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            min-height: unset;
        }
    }

    button.btn-top {
        position: fixed;
        bottom: 100px;
        right: 30px;
        background: #ffffff;
        border: none;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        width: 50px;
        height: 50px;
        border-radius: 50%;

        img {
            width: 19px;
            height: 11px;
            vertical-align: middle;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            bottom: 130px;
            right: 5px;
        }
    }
`;

export const Container = styled.div`
    width: ${(props) => props.theme.layout.width}px;
    margin: ${(props) => props.margin || "40px auto"};
    position: relative;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        margin: 0;
    }
`;

const MobileMenu = (props) => {
    const { location, history, match } = props;
    const { auth } = useContext(AppContext);

    const isOnMenu = (menu) => {
        switch (menu) {
            case "HOME":
                if (location.pathname === "/") return "on";
                break;
            case "FIND":
                if (location.pathname.startsWith("/find")) return "on";
                break;
            case "CART":
                if (location.pathname.startsWith("/cart")) return "on";
                break;
            case "CATEGORY":
                if (location.pathname.startsWith("/categorys")) return "on";
                break;
            case "REVIEW":
                if (location.pathname.startsWith("/reviews")) return "on";
                break;
        }

        return "off";
    };

    const goLink = (url) => {
        switch (url) {
            case "/cart":
                if (!auth) {
                    _U.postMessage("LOGIN");
                } else {
                    history.push(url);
                }
                break;
            // case "/category":
            // 	Dialog({
            // 		title: ``,
            // 		body: <Category {...{ location, history, match }} />,
            // 	})
            // 		.then((data) => {})
            // 		.catch((e) => {
            // 			if (e && e.url) {
            // 				history.push(e.url);
            // 			}
            // 		});
            // 	break;
            default:
                history.push(url);
                break;
        }
    };

    return (
        <MMWrapper>
            <ul>
                <li onClick={() => goLink("/")}>
                    <img
                        src={`/icons/ic_bottom_home_${isOnMenu("HOME")}.png`}
                        srcSet={getSrcSet([`/icons/ic_bottom_home_${isOnMenu("HOME")}.png`, `/icons/ic_bottom_home_${isOnMenu("HOME")}@2x.png`, `/icons/ic_bottom_home_${isOnMenu("HOME")}@3x.png`])}
                    />
                    <div>홈</div>
                </li>
                <li onClick={() => goLink("/categorys")}>
                    <img
                        src={`/icons/ic_bottom_menu_${isOnMenu("CATEGORY")}.png`}
                        srcSet={getSrcSet([
                            `/icons/ic_bottom_menu_${isOnMenu("CATEGORY")}.png`,
                            `/icons/ic_bottom_menu_${isOnMenu("CATEGORY")}@2x.png`,
                            `/icons/ic_bottom_menu_${isOnMenu("CATEGORY")}@3x.png`,
                        ])}
                    />
                    <div>카테고리</div>
                </li>
                <li onClick={() => goLink("/find")}>
                    <img
                        src={`/icons/ic_bottom_search_${isOnMenu("FIND")}.png`}
                        srcSet={getSrcSet([
                            `/icons/ic_bottom_search_${isOnMenu("FIND")}.png`,
                            `/icons/ic_bottom_search_${isOnMenu("FIND")}@2x.png`,
                            `/icons/ic_bottom_search_${isOnMenu("FIND")}@3x.png`,
                        ])}
                    />
                    <div>검색</div>
                </li>
                <li onClick={() => goLink("/cart")}>
                    <img
                        src={`/icons/ic_bottom_cart_${isOnMenu("CART")}.png`}
                        srcSet={getSrcSet([`/icons/ic_bottom_cart_${isOnMenu("CART")}.png`, `/icons/ic_bottom_cart_${isOnMenu("CART")}@2x.png`, `/icons/ic_bottom_cart_${isOnMenu("CART")}@3x.png`])}
                    />
                    <div>장바구니</div>
                </li>
                <li onClick={() => goLink("/reviews")}>
                    <img
                        src={`/icons/ic_bottom_social_${isOnMenu("REVIEW")}.png`}
                        srcSet={getSrcSet([
                            `/icons/ic_bottom_social_${isOnMenu("REVIEW")}.png`,
                            `/icons/ic_bottom_social_${isOnMenu("REVIEW")}@2x.png`,
                            `/icons/ic_bottom_social_${isOnMenu("REVIEW")}@3x.png`,
                        ])}
                    />
                    <div>소셜</div>
                </li>
            </ul>
        </MMWrapper>
    );
};

const MMWrapper = styled.div`
    display: none;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #ffffff;
        height: 56px;
        width: 100%;
        z-index: 3;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            height: 100%;
        }

        ul > li {
            flex: 1;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;

            img {
                width: 24px;
                height: 24px;
            }
            div {
                color: #777777;
                font-family: ${(props) => props.theme.fontFamily.regular};
                line-height: 15px;
                font-size: 10px;
            }
            padding: 10px 0 5px;
        }
    }
`;

const Category = (props) => {
    const [menu, setMenu] = useState([]);

    const load = () => {
        _API.get({ path: "/menu" })
            .then((res) => res.data)
            .then((list) => setMenu(list));
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <DCWrapper>
            <h2>카테고리</h2>
            <ul className="list-box">
                {menu?.map((item, index) => (
                    <MenuItem key={index} data={item} {...props} />
                ))}
            </ul>
        </DCWrapper>
    );
};

const DCWrapper = styled.div`
    h2 {
        font-weight: normal;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
        line-height: 29px;
        font-size: 20px;
        margin: 30px 0 0 0;
    }

    .list-box {
        list-style: none;
        padding: 0;
        margin: 24px 0 0 0;
    }

    .list-box > li {
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
        line-height: 29px;
        font-size: 20px;
        padding: 8px 0;
        a {
            color: #3d3d3d;
        }

        img {
            margin-left: 12px;
            vertical-align: top;
            margin-top: 12px;
            width: 14px;
            height: 8px;
        }
        .depth-menu {
            margin-top: 2px;
        }

        .depth-menu > a {
            margin-top: 10px;
            padding-left: 12px;
            color: #3d3d3d;
            font-family: ${(props) => props.theme.fontFamily.medium};
            line-height: 24px;
            font-size: 16px;
            text-decoration: none;
            display: block;
            :hover,
            :visited,
            :active {
                color: #3d3d3d;
            }
        }
    }

    .list-box > li.split {
        border-bottom: 1px solid #efefef;
        padding: 8px 0 0;
        margin-bottom: 8px;
    }
`;

const MenuItem = (props) => {
    const { data, onMouseOver, resolve } = props;
    const [categories, setCategories] = useState([]);
    const [depth, setDepth] = useState(false);
    let isLoad = true;

    if (data.type === "SPLIT") {
        return <li className="split"></li>;
    }

    const getHref = () => {
        switch (data.type) {
            case "CATEGORY":
                if (!data.categoryIdx) {
                    return `#/`;
                }
                return `#/category/${data.categoryIdx}`;
            case "CMS": {
                if (!data.cmsIdx) {
                    return `#/`;
                }
                let path = `#/cms/${data.cmsCode1}`;
                if (data.cmsCode2) {
                    path += `/${data.cmsCode2}`;
                }
                if (data.cmsCode2 && data.cmsCode3) {
                    path += `/${data.cmsCode3}`;
                }
                return path;
            }
            case "BBS":
                if (!data.bbsCode) {
                    return `#/`;
                }
                return `#/bbs/${data.bbsCode}`;
            case "BRAND":
                return "#/brand";
            case "REVIEW":
                return "#/reviews";
        }
    };

    const onLoadCategory = () => {
        _API.get({ path: "/menu/category", data: { idx: data?.categoryIdx } })
            .then((res) => res.data)
            .then((list) => {
                if (isLoad) {
                    setCategories(list);
                }
            });
    };

    useEffect(() => {
        if (data?.categoryIdx) {
            isLoad = true;
            onLoadCategory();
        }

        return () => {
            isLoad = false;
        };
    }, [data?.categoryIdx]);

    const showDepth = () => {
        setDepth(!depth);
    };

    return (
        <li onMouseOver={onMouseOver ? onMouseOver : undefined} className={cx({ active: true })}>
            {data.type === "LINK" ? (
                <A href={data.url} color={data.color} dot={data.colorDot} target="_blank">
                    {data.title}
                </A>
            ) : (
                <>
                    <A href={categories.length === 0 && getHref()} color={data.color} dot={data.colorDot} onClick={() => (categories.length > 0 ? showDepth() : resolve())} active={depth}>
                        {data.title}
                        {categories.length > 0 && (
                            <img
                                src={depth ? "/icons/ic_arrow_up_gray.png" : "/icons/ic_arrow_down.png"}
                                srcSet={getSrcSet(
                                    depth
                                        ? ["/icons/ic_arrow_up_gray.png", "/icons/ic_arrow_up_gray@2x.png", "/icons/ic_arrow_up_gray@3x.png"]
                                        : ["/icons/ic_arrow_down.png", "/icons/ic_arrow_down@2x.png", "/icons/ic_arrow_down@3x.png"]
                                )}
                            />
                        )}
                    </A>
                    {depth && categories.length > 0 && (
                        <div className="depth-menu">
                            {categories?.map((cItem, cIndex) => (
                                <a className="depth-item" key={cIndex} href={`#/category/${cItem.idx}`} onClick={() => resolve()}>
                                    {cItem.name}
                                </a>
                            ))}
                        </div>
                    )}
                </>
            )}
        </li>
    );
};

const A = styled.a`
    position: relative;
    display: block;
    text-decoration: none;
    :hover,
    :visited,
    :active {
        text-decoration: none;
    }
    sup {
        position: absolute;
        top: -5px;
        right: -10px;
    }

    ${(props) =>
        props.active
            ? `
		color:#acacac !important;
	`
            : ``}
`;

const ChannelTalk = (props) => {
    const { iam, auth } = useContext(AppContext);

    useEffect(() => {
        var w = window;
        if (w.ChannelIO) {
            return (window.console.error || window.console.log || function () {})("ChannelIO script included twice.");
        }
        var ch = function () {
            ch.c(arguments);
        };
        ch.q = [];
        ch.c = function (args) {
            ch.q.push(args);
        };
        w.ChannelIO = ch;
        function l() {
            if (w.ChannelIOInitialized) {
                return;
            }
            w.ChannelIOInitialized = true;
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
            s.charset = "UTF-8";
            var x = document.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
        }
        if (document.readyState === "complete") {
            l();
        } else if (window.attachEvent) {
            window.attachEvent("onload", l);
        } else {
            window.addEventListener("DOMContentLoaded", l, false);
            window.addEventListener("load", l, false);
        }

        return () => {
            window.removeEventListener("DOMContentLoaded", l, false);
            window.removeEventListener("load", l, false);
        };
    }, []);

    useEffect(() => {
        let param = {
            pluginKey: "46df3d6d-e5f5-47f5-9236-b0085eba8730", //please fill with your plugin key
        };
        if (iam?.id) {
            param.memberId = iam?.id || "";
            param.profile = {
                name: iam?.name || "",
                mobileNumber: iam?.mobile || "",
                email: iam?.email || "",
                nickName: iam?.nickName || "",
            };
        }

        ChannelIO("boot", param);
    }, [iam]);

    return "";
};
