/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import Layout, { Container } from "./Layout";
import { AppContext } from "../Components/AppProvider";
import { _U, _API } from "../Modules/Utils";
import { getSrcSet } from "../UI/Assets";

import { RContainer, RIContainer } from "./Home.Styled";
import { MypageTop } from "../UI/Header";

export default (props) => {
	const { location, history, match } = props;
	const cp = { history, location, match };
	const { iam, auth } = useContext(AppContext);
	const [list, setList] = useState([]);

	const loadList = () => {
		_API
			.get({
				path: "/product/ranking",
				data: {},
			})
			.then((res) => res.data)
			.then((data) => {
				const { list } = data;
				setList(list);
			});
	};

	useEffect(() => {
		loadList();
	}, []);

	const setEmpty = (number) => {
		let remainer = list.length % number;
		const rtn = [];

		remainer = remainer > 0 ? number - remainer : remainer;

		if (remainer <= 0) {
			return "";
		}

		for (let i = 0; i < remainer; i++) {
			rtn.push(<RankingItem key={i} />);
		}

		return rtn;
	};

	return (
		<Layout {...props} hideTop={true}>
			<Container>
				<MypageTop {...props}>상품 랭킹</MypageTop>

				<RContainer>
					<div className="list">
						{list?.map((item, i) => (
							<RankingItem number={i + 1} key={i} content={item} {...cp} />
						))}
						{setEmpty(isMobile ? 2 : 6)}
					</div>
				</RContainer>
			</Container>
		</Layout>
	);
};

const TitleWrapper = styled.div`
	position: relative;
	padding: 0 0 20px;
	border-bottom: 2px solid #3d3d3d;
	margin: 0px 0 20px;
	display: flex;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		border-bottom: 0;
		padding-bottom: 0;
		height: 54px;
		align-items: center;
		justify-content: center;
		margin: 0px;
	}

	> h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 22px;
		line-height: 35px;
		font-weight: normal;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			text-align: center;
		}
	}
`;

const RankingItem = (props) => {
	const { location, history, match } = props;
	const { number, content } = props;

	const goProduct = (item) => {
		if (item?.productCode) {
			history.push(`/product/${item.productCode}`);
		}
	};
	return (
		<RIContainer onClick={(e) => goProduct(content)} noHover={!content}>
			{content && (
				<>
					<div className="number">{number}</div>
					<div className="thumbnail">
						<img src={content?.thumbnailFileSignedUrl || content?.productFileSignedUrl} alt="" />
					</div>
					<div className="content">
						<h4 className="brand">{content?.productBrandName}</h4>
						<h3 className="title">{content?.productName}</h3>
						<h5 className="cost">
							<b>{_U.addComma(content?.priceSale)}</b>원
							{content?.priceDiscountPercent > 0 && (
								<span>
									<i className="fa fa-arrow-down"></i> {Math.round(content?.priceDiscountPercent) || 0}%
								</span>
							)}
						</h5>
						{content?.priceDiscountPercent > 0 && (
							<h5 className="old-cost">
								<b>{_U.numberFormat(content?.priceNormal)}</b>원
							</h5>
						)}
						{/* <div className="tags">
							<span className="tag red">익일배송</span>
							<span className="tag">최저가스캐너</span>
						</div> */}
					</div>
				</>
			)}
		</RIContainer>
	);
};
