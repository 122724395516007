/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import axios from "axios";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import qs from "query-string";

import { _API, _U } from "../../Modules/Utils";

export const CloseView = (props) => {
	const { location } = props;

	useEffect(() => {
		const params = qs.parse(location.search);
		_U.postMessage("CERT_COMPLETE", params, window.opener);
		self.close();
	}, [location]);
	return <div></div>;
};

export const CancelView = (props) => {
	useEffect(() => {
		swal({ title: "알림", text: "본인 인증에 실패했습니다." }).then(() => {
			_U.postMessage("CERT_CANCEL", null, window.opener);
			self.close();
		});
	}, []);
	return <div></div>;
};
