/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import axios from "axios";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";

import { _API, _U } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";

const _TYPE = [{ value: "PAYLETTER", label: "페이레터" }];
const _PAYLETTER_PC_CODE = [
	{ value: "creditcard", label: "신용카드" },
	// { value: "banktransfer", label: "인터넷뱅킹(금융결제원)" },
	// { value: "virtualaccount", label: "가상계좌" },
	{ value: "mobile", label: "휴대폰" },
	{ value: "kakaopay", label: "카카오페이" },
	{ value: "naverpay", label: "네이버페이" },
];

export default (props) => {
	const [type, setType] = useState(_TYPE[0].value);
	const [pgcode, setPgcode] = useState(_PAYLETTER_PC_CODE[0].value);

	const handlePayment = () => {
		switch (type) {
			case _TYPE[0].value:
				requestPayletter();
				break;
		}
	};

	const requestPayletter = () => {
		_API
			.post({
				path: "/payment/payletter",
				data: {
					pgcode: pgcode,
					orderNo: UUIDV4(),
				},
			})
			.then((res) => res.data)
			.then((data) => {
				const { online_url: url } = data;
				window.open(url, "PAYMENT", "width=400,height=600");
			})
			.catch((e) => console.log(e));
	};

	const onComplete = () => {
		swal({ title: "알림", text: "결제가 완료 되었습니다." });
	};

	const onCancel = () => {
		swal({ title: "알림", text: "결제가 취소 되었습니다." });
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e).then((data) => {
			switch (data.code) {
				case "COMPLETE":
					onComplete();
					break;
				case "CANCEL":
					onCancel();
					break;
			}
		});
	};

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	return (
		<Layout>
			<Container>
				<Row>
					<div className="label">결제 종류</div>
					<div className="content">
						{_TYPE.map((item, i) => (
							<button key={i} type="button" className={cx({ type: true, active: item.value === type })} onClick={(e) => setType(item.value)}>
								{item.label}
							</button>
						))}
					</div>
				</Row>
				{type === _TYPE[0].value && (
					<Row>
						<div className="label">결제 수단</div>
						<div className="content">
							{_PAYLETTER_PC_CODE.map((item, i) => (
								<button key={i} type="button" className={cx({ type: true, active: item.value === pgcode })} onClick={(e) => setPgcode(item.value)}>
									{item.label}
								</button>
							))}
						</div>
					</Row>
				)}
				<Row>
					<div className="label">결제하기</div>
					<div className="content">
						<button type="button" className="payment" onClick={handlePayment}>
							결제하기
						</button>
					</div>
				</Row>
			</Container>
		</Layout>
	);
};

const Row = styled.div`
	margin: 0 auto 15px;
	display: flex;
	.label {
		width: 200px;
	}
	.content {
		flex: 1;

		button.type {
			padding: 5px 10px;
			background: #ffffff;
			border: #efefef solid 1px;
			border-right: none;
		}
		button.type:last-child {
			border-right: #efefef solid 1px;
		}
		button.active {
			background: #e32222;
			color: #ffffff;
		}

		button.payment {
			padding: 10px 50px;
			border: #e32222 solid 1px;
			background: #e32222;
			color: #ffffff;
		}
	}
`;

export const CloseView = (props) => {
	useEffect(() => {
		_U.postMessage("COMPLETE", null, window.opener);
		self.close();
	}, []);
	return <div></div>;
};

export const CancelView = (props) => {
	useEffect(() => {
		_U.postMessage("CANCEL", null, window.opener);
		self.close();
	}, []);
	return <div></div>;
};
