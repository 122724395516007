/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Player from "./PC.Cams.Player";

export default (props) => {
	const { roomData: data, onChange, current } = props;
	if (!data) {
		return <Container />;
	}

	const [channels, setChannels] = useState([]);

	useEffect(() => {
		const { channels } = data;
		setChannels(channels);
	}, [data]);

	return (
		<Container>
			<Wrapper>
				<Title>
					<h3 className="title">라이브 캠</h3>
				</Title>
				{/* <CameraWrapper>
					{channels.map((item, i) => (
						<Player key={i} index={i} {...item} current={current} onChange={onChange} />
					))}
				</CameraWrapper> */}

				<CWrapper>
					{channels.map((item, i) => (
						<CItem key={i} onClick={() => onChange(item.streamId)} current={current === item.streamId}>
							{item.type === "MAIN" ? `메인` : item.title}
							{current === item.streamId && <i className="fa fa-circle"></i>}
						</CItem>
					))}
				</CWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 16px;
	background: rgba(0, 0, 0, 0.7);
	z-index: 10;

	overflow-x: hidden;
	overflow-y: auto;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	flex-direction: column;
`;
const Title = styled.div`
	.title {
		margin: 0;
		padding: 0;
		color: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 14px;
	}
`;

const CameraWrapper = styled.div`
	margin: 6px 0;
	padding: 0 0 16px;

	display: grid;
	grid-gap: 15px;
	grid-template-columns: repeat(auto-fit, 150px);
	grid-template-rows: repeat(auto-fit, 267px);

	/* ::-webkit-scrollbar {
        display: none;
    } */

	/*
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: #636e72;
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: #b2bec3;
        border-radius: 8px;
    }
    */
`;

const CWrapper = styled.div`
	margin: 20px 0;
	padding: 0 0 16px;
	width: 100%;
`;
const CItem = styled.div`
	width: 100%;
	display: block;
	padding: 10px 0;
	color: ${(props) => (props.current ? `#e32222` : `#ffffff`)};
	font-family: ${(props) => props.theme.fontFamily.medium};

	font-size: 18px;

	i.fa {
		margin-left: 10px;
	}
`;
