/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import { getSrcSet } from "../../UI/Assets";

export default (props) => {
	const { socketConnect, isStart, castData } = props;
	const { auth, iam } = useContext(AppContext);
	const intoRef = useRef(null);

	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState("");
	const [isSendProc, setIsSendProc] = useState(false);

	const sendRoom = () => {
		if (castData && castData?.idx) {
			_U.postMessage("SOCKET-SEND", {
				action: "ROOM",
				data: {
					roomIdx: castData?.idx,
				},
			});
		}
	};

	const onKeyUp = (e) => {
		if (e.key === "Enter" && !isSendProc) {
			onSend();
		}
	};

	const onSend = () => {
		if (!iam || !iam.idx) {
			swal({ title: "알림", text: "로그인 후 이용가능합니다." });
			return;
		}
		if (!socketConnect) {
			swal({ title: "알림", text: "채팅서버에 연결중입니다.\n잠시후 다시연결해 주세요." });
			return;
		}
		if (!message) {
			return;
		}
		if (isSendProc) {
			return;
		}
		setIsSendProc(true);

		_U.postMessage("SOCKET-SEND", {
			action: "CHAT",
			data: {
				mode: "CHAT",
				id: iam?.code + "_" + iam?.idx,
				code: iam?.code,
				idx: iam?.idx,
				name: iam?.name || "익명",
				nickName: iam?.nickName || "",
				roomIdx: castData?.idx,
				message,
			},
		});

		setMessage("");
		setIsSendProc(false);
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e)
			.then((data) => {
				switch (data.code) {
					case "SOCKET-RECEIVED":
						return data.data || null;
				}
			})
			.then((data) => {
				if (data?.mode === "CHAT") {
					console.log(data);
					setMessages((o) => [...o.slice(-99), data]);
				}
			});
	};

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	useEffect(() => {
		if (socketConnect) {
			sendRoom();
		}
	}, [socketConnect, castData]);

	const onClickNotice = () => {
		swal({
			title: "채팅 공지사항",
			content: "input",
			button: {
				text: "보내기",
			},
		}).then((msg) => {
			if (msg) {
				_U.postMessage("SOCKET-SEND", {
					action: "CHAT",
					data: {
						mode: "CHAT",
						id: iam?.code + "_" + iam?.idx,
						code: iam?.code,
						idx: iam?.idx,
						name: iam?.name || "익명",
						nickName: iam?.nickName || "",
						roomIdx: castData?.idx,
						isNotice: "Y",
						message: msg,
					},
				});
			}
		});
	};

	const scrollToBottom = () => {
		intoRef.current.scrollTop = intoRef.current.scrollHeight;
	};

	useEffect(scrollToBottom, [messages]);

	return (
		<Container>
			<Wrapper>
				<h3 className="title">
					채팅
					<small>
						<img
							src="/images/cast_horn.png"
							srcSet={getSrcSet(["/images/cast_horn.png", "/images/cast_horn@2x.png", "/images/cast_horn@3x.png"])}
							onClick={() => onClickNotice()}
						/>
					</small>
				</h3>
				<hr className="split" />
				<div className="message-wrapper" ref={intoRef}>
					{socketConnect && isStart && <div className="connect">채팅방이 개설 되었습니다.</div>}
					{messages.map((item, i) => (
						<Message key={i} isMe={item.idx === iam?.idx} isNotice={item.isNotice === "Y"}>
							{item.idx !== iam?.idx && <span className="name">{item.nickName}</span>}
							{item.message}
						</Message>
					))}
				</div>
				<div className="form">
					<div className="row">
						<input
							type="text"
							readOnly={!isStart}
							placeholder={isStart && socketConnect ? `내용을 입력해 주세요` : `방송 시작 후 채팅 가능합니다.`}
							onChange={(e) => setMessage(e.target.value)}
							value={message}
							onKeyUp={onKeyUp}
						/>
						<button type="button" onClick={onSend}>
							<i className="fa fa-send"></i>
						</button>
					</div>
				</div>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	width: 320px;
	flex-basis: 320px;
	background: #2b2c2e;
	border-left: #3b3b3b solid 1px;
`;
const Wrapper = styled.div`
	width: 100%;
	height: 100%;

	.title {
		margin: 16px 20px 12px;
		padding: 0 0 0 8px;
		border-left: #acacac solid 2px;
		font-size: 14px;
		font-weight: normal;

		small {
			float: right;
			clear: right;
			cursor: pointer;

			img {
				width: 24px;
				height: 24px;
			}
		}
	}

	.split {
		margin: 0 20px;
		border: none;
		border-top: #434343 solid 1px;
	}

	.form {
		padding: 12px 20px 16px;
		border-top: #434343 solid 1px;

		.row {
			background: #ffffff;
			border-radius: 10px;
			overflow: hidden;
			display: flex;

			input {
				padding: 12px 16px;
				background: none;
				border: none;

				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 13px;
				color: #000000;

				flex: 1;
				::placeholder {
					font-family: ${(props) => props.theme.fontFamily.regular};
					font-size: 13px;
					color: #7a7a7a;
				}
			}

			button {
				flex-basis: 50px;
				background: none;
				border: none;
				color: #00788d;
			}
		}
	}

	.message-wrapper {
		padding: 12px 20px;
		height: calc(100% - 140px);

		overflow-x: hidden;
		overflow-y: auto;

		::-webkit-scrollbar {
			width: 3px;
		}
		::-webkit-scrollbar-track {
			background-color: #636e72;
			/* border-radius: 3px; */
		}
		::-webkit-scrollbar-thumb {
			background: #b2bec3;
			/* border-radius: 3px; */
		}

		.connect {
			color: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 19px;
			background: rgba(0, 0, 0, 0.5);
			border-radius: 14px;
			padding: 5px 0 5px 20px;
			margin-bottom: 20px;
		}
	}
`;

const Message = styled.div`
	position: relative;
	margin: 2px 0;
	margin-right: ${(props) => (props.isMe ? "15px" : "0")};
	font-size: 14px;
	line-height: 20px;
	color: #dedede;
	text-align: ${(props) => (props.isMe ? "right" : "left")};
	font-family: ${(props) => props.theme.fontFamily.regular};

	${(props) =>
		props.isNotice
			? `
			color: #2dd0f2;
			font-family: ${props.theme.fontFamily.regular};
			font-size: 14px;
			line-height: 20px;	
			margin-right:0;
			text-align:left;
	`
			: ``}

	.name {
		margin-right: 12px;
		font-size: 14px;
		color: #fe9334;
	}
`;
