/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import Dialog from "../../UI/Dialog";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { _DEF } from "../../Modules/Config";
import { MypageTop } from "../../UI/Header";

import Menu from "./Service.Menu";

export default (props) => {
    const { location, history, match } = props;

    const RequestAs = (e) => {
        if (_CK.isLogin()) {
            history.push(`/user/my/request/as`);
        } else {
            // swal({ title: "알림", text: "로그인 후 이용가능합니다." });
            _U.postMessage("LOGIN");
        }
    };

    return (
        <Layout {...props} hideTop={true}>
            <Container>
                <MypageTop {...props}>{isMobile ? `A/S 신청` : `서비스센터`}</MypageTop>
                <Wrapper>
                    <Menu {...props} />
                    <ContentWrapper>
                        <BWrapper>
                            <div className="top">
                                <h2>A/S</h2>
                            </div>
                            <div className="content">
                                <div>
                                    <img src="/images/as_top.png" className="visual" srcSet={getSrcSet(["/images/as_top.png", "/images/as_top@2x.png", "/images/as_top@3x.png"])} />
                                </div>
                                <div className="content-item first">
                                    <img src="/images/as_img1.png" srcSet={getSrcSet(["/images/as_img1.png", "/images/as_img1@2x.png", "/images/as_img1@3x.png"])} />
                                    <span>
                                        <h3>
                                            모아가 보장하는 <b>평생 A/S</b>
                                        </h3>
                                        <p>
                                            모아에서 구매하신 상품의 손상으로 인한 모든 <b>A/S를 평생 동안</b> 최선을 다해 책임지겠습니다.
                                        </p>
                                    </span>
                                </div>
                                <div className="content-item">
                                    <img src="/images/as_img2.png" srcSet={getSrcSet(["/images/as_img2.png", "/images/as_img2@2x.png", "/images/as_img2@3x.png"])} />
                                    <span>
                                        <h3>
                                            백화점 A/S 이상의 <b>퀄리티</b>
                                        </h3>
                                        <p>
                                            백화점 A/S의 경우에도 대부분 <b>전문 수선업체에서 진행</b>됩니다. 모아에서는 자체적으로 확실하게 검증한 유명 전문 수선 업체들과의 제휴를 통해 상품별로 가장
                                            적합한 업체에 의뢰를 하고 있습니다.
                                        </p>
                                    </span>
                                </div>
                                <div className="content-item">
                                    <img src="/images/as_img3.png" srcSet={getSrcSet(["/images/as_img3.png", "/images/as_img3@2x.png", "/images/as_img3@3x.png"])} />
                                    <span>
                                        <h3>
                                            구매 6개월 이내 <b>무상 A/S</b>
                                        </h3>
                                        <p>
                                            모아에서는 구매일로부터 6개월이 지나지 않은 상품에 한해서 <b>무상 A/S를 진행</b>해 드리고 있습니다.
                                        </p>
                                    </span>
                                </div>
                                <div className="content-item last">
                                    <img src="/images/as_img4.png" srcSet={getSrcSet(["/images/as_img4.png", "/images/as_img4@2x.png", "/images/as_img4@3x.png"])} />
                                    <span>
                                        <h3>
                                            더욱 빠르고 <b>저렴한 A/S</b>
                                        </h3>
                                        <p>
                                            모아에서는 수선 업체들과의 긴밀한 제휴 관계를 통해, 개인이 직접 의뢰 할 때보다 <b>더욱 빠르고 저렴한 A/S를 약속</b>합니다.
                                        </p>
                                    </span>
                                </div>
                                <div>
                                    <img src="/images/as_bottom.png" className="visual" srcSet={getSrcSet(["/images/as_bottom.png", "/images/as_bottom@2x.png", "/images/as_bottom@3x.png"])} />
                                </div>
                            </div>
                            <div className="button">
                                <button onClick={RequestAs}>A/S 신청하기</button>
                            </div>
                        </BWrapper>
                    </ContentWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding-bottom: 40px;
    }
`;

const ContentWrapper = styled.div`
    margin-left: 30px;
    padding-top: 10px;
    flex: 1;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-left: 0;
        padding: 0;
        width: 100%;
    }
`;

const BWrapper = styled.div`
    div.top {
        position: relative;
        display: flex;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: 1px solid #3d3d3d;
            margin-top: 24px;
            padding-bottom: 16px;
            display: none;
        }
    }

    div.top > h2 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
    }

    div.content {
        margin-top: 24px;
        background: #f2f2f2;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding-bottom: 8px;
        }

        img.visual {
            width: 100%;
        }

        div.content-item {
            padding: 50px 60px 0;
            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                padding: 30px 21px 0;
            }
            display: flex;

            > img {
                flex-basis: 144px;
                height: 144px;

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    flex-basis: 56px;
                    height: 56px;
                }
            }
            > span {
                flex: 1;
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    padding-left: 12px;
                }

                h3 {
                    margin: 0;
                    padding: 0;
                    font-family: ${(props) => props.theme.fontFamily.bold};
                    font-size: 34px;
                    line-height: 40px;
                    color: #3d3d3d;
                    b {
                        color: #00788d;
                    }

                    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                        font-size: 20px;
                    }
                }

                p {
                    margin: 2px 0 0 0;
                    padding: 0;
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    font-size: 20px;
                    line-height: 30px;
                    color: #3d3d3d;
                    b {
                        font-family: ${(props) => props.theme.fontFamily.bold};
                    }

                    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                        font-size: 13px;
                    }
                }
            }
        }

        div.content-item.first {
            padding: 60px 60px 0px;
        }
        div.content-item.last {
            padding: 50px 60px 60px;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            div.content-item.first {
                padding: 40px 21px 0px;
            }
            div.content-item.last {
                padding: 30px 21px 40px;
            }
        }
    }

    div.button {
        text-align: center;
        margin-top: 20px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }

    div.button > button {
        font-family: ${(props) => props.theme.fontFamily.regular};
        border: 1px solid #00788d;
        background: #00788d;
        color: #ffffff;
        font-size: 14px;
        line-height: 20px;

        padding: 15px 0;
        width: 220px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 100%;
        }
    }
`;

const List = (props) => {
    const { list } = props;

    return (
        <LWrapper>
            {list?.map((item, index) => (
                <ListItem {...item} key={index} />
            ))}
        </LWrapper>
    );
};

const LWrapper = styled.div`
    margin-top: 16px;
`;

const ListItem = (props) => {
    const [show, setShow] = useState(false);

    return (
        <LIWrapper>
            <dl className={cx({ show: show })} onClick={() => setShow(!show)}>
                <dt>
                    [{_DEF.getDefinedLabel(_DEF.FAQ_CATEGORY, props.categoryCode)}] {props.title}
                </dt>
                <dd dangerouslySetInnerHTML={{ __html: props.content }}></dd>
            </dl>
        </LIWrapper>
    );
};

const LIWrapper = styled.div`
    dl {
        border: 1px solid #efefef;
        background: #fcfcfc;
        padding: 11px 0px;
        color: #9a9a9a;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        cursor: pointer;
        margin: 8px 0 0px;
    }

    dl > dt {
        background-image: url("/icons/ic_arrow_down_gray.png");
        background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_down_gray.png", "/icons/ic_arrow_down_gray@2x.png", "/icons/ic_arrow_down_gray@3x.png"])});
        background-repeat: no-repeat;
        background-size: 10px 6px;
        background-position: right 15px top 7px;
        padding: 0 20px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            background-position: right 10px top 7px;
        }
    }

    dl > dd {
        display: none;
    }

    dl.show {
        background: #ffffff;
        color: #3d3d3d;
    }

    dl.show > dt {
        background-image: url("/icons/ic_arrow_up_gray.png");
        background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_up_gray.png", "/icons/ic_arrow_up_gray@2x.png", "/icons/ic_arrow_up_gray@3x.png"])});
        border-bottom: 1px solid #dedede;
        padding-bottom: 12px;
        font-family: ${(props) => props.theme.fontFamily.medium};
    }
    dl.show > dd {
        display: block;
        margin: 16px 0 0;
        line-height: 17px;
        font-weight: normal;
        padding: 0 20px;

        * {
            max-width: 100% !important;
            width: auto !important;
        }
        b {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #3d3d3d;
        }

        p {
            font-family: ${(props) => props.theme.fontFamily.light};
            color: #777777;
            margin: 0;
            padding: 0;
        }
    }
`;
