/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";

import { _DEF } from "../../Modules/Config";
import { _API, _CK, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Layout from "../Layout";

import Info from "./View.Info";
import Slider from "./View.Slider";
import Content from "./View.Content";

export default (props) => {
    const { history, location, match } = props;
    const { code } = match.params;
    const { auth } = useContext(AppContext);

    const [categories, setCategories] = useState([]);
    const [detail, setDetail] = useState(null);
    const [options, setOptions] = useState([]);
    const [brand, setBrand] = useState([]);
    const [media, setMedia] = useState([]);
    const [content, setContent] = useState([]);
    const [productQna, setProductQna] = useState([]);
    const [productReview, setProductReview] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadData = () => {
        _API.get({ path: "/product/search/item", data: { code } })
            .then((res) => res.data)
            .then((data) => {
                const { categories, detail, brand, media, content, options } = data;
                setCategories(categories);
                setDetail(detail);
                setOptions(options);
                setBrand(brand);
                setMedia(media);
                setContent(content);
                setLoading(false);
            });
    };

    const setRecent = () => {
        let recentProduct = [];
        if (_CK.get("RECENT-PRODUCT") && _CK.get("RECENT-PRODUCT").length > 0) {
            recentProduct = _CK.get("RECENT-PRODUCT");
        }

        const tmpRecentProduct = recentProduct.filter((p) => {
            return p.productIdx !== detail?.idx;
        });

        tmpRecentProduct.unshift({ productIdx: detail.idx });
        _CK.set("RECENT-PRODUCT", tmpRecentProduct, 1);

        if (auth) {
            if (detail?.idx) {
                _API.post({ path: "/product/recent", data: { productIdx: detail.idx } }).then((res) => {
                    _U.postMessage("READ-RECENT-PRODUCT");
                });
            }
        } else {
            _U.postMessage("READ-RECENT-PRODUCT");
        }
    };

    const getCategory = () => {
        let text = "";
        categories.map((item, i) => {
            text += ` ${item.label} `;
            if (i < categories.length - 1) {
                text += " &gt; ";
            }
        });
        return text;
    };

    useEffect(() => {
        loadData();
    }, [code]);

    useEffect(() => {
        if (detail?.idx) {
            setRecent();
        }
    }, [detail, auth]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout {...props}>
            <Wrapper>
                {!loading && (
                    <>
                        <div className="top">
                            <small dangerouslySetInnerHTML={{ __html: getCategory() }} />
                            <small>상품코드 : {code}</small>
                        </div>
                        <Info media={media} brand={brand} detail={detail} options={options} {...props} categories={categories} />
                        {/* <Slider title="관련 상품" more={false} font="20" onClick={() => {}} /> */}
                        <Content media={media} brand={brand} content={content} detail={detail} />
                    </>
                )}
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
    width: ${(props) => props.theme.layout.productWidth}px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 80px;
    min-height: 300px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        padding-bottom: 60px;
        min-height: unset;
    }

    > div.top {
        display: flex;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }

    div.top > small {
        flex: 1;
        color: #acacac;
        font-size: 12px;
        font-family: ${(props) => props.theme.fontFamily.regular};
        line-height: 15px;

        :last-child {
            text-align: right;
            padding-right: 30px;
        }
    }
`;
