/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

import Player from "./Mobile.Viewer";
import Product from "./Mobile.Product";
import Chatting from "./Mobile.Chatting";
import Cams from "./Mobile.Cams";
import Socket from "../../Components/Socket";

import Info from "../Product/View.Info";
import Content from "../Product/View.Content";

export default (props) => {
	const { location, history, match } = props;
	const cp = { history, location, match };
	const { roomIdx, roomData, streamId: streamIdInProps, poster: posterInProps } = props;

	const { iam } = useContext(AppContext);

	const [streamId, setStreamId] = useState(streamIdInProps);
	const [poster, setPoster] = useState(posterInProps);
	const [favorite, setFavorite] = useState(false);
	const [isShowCams, setIsShowCams] = useState(false);
	const [selectedProductCode, setSelectedProductCode] = useState(null);
	const [socketConnect, setSocketConnect] = useState(false);

	const handleChatClick = () => {
		if (!iam || !iam.idx) {
			swal({ title: "알림", text: "로그인 후 이용가능합니다." });
			return;
		}

		swal({
			title: "메세지",
			content: "input",
			button: {
				text: "보내기",
			},
		}).then((msg) => {
			if (msg) {
				_U.postMessage("SOCKET-SEND", {
					action: "CHAT",
					data: {
						mode: "CHAT",
						id: iam?.code + "_" + iam?.idx,
						code: iam?.code,
						idx: iam?.idx,
						name: iam?.name || "익명",
						nickName: iam?.nickName || "",
						roomIdx,
						message: msg,
					},
				});
			}
		});
	};

	const handleHeartClick = () => {
		setFavorite(!favorite);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const handleToggleCams = () => {
		setIsShowCams(!isShowCams);
	};

	const handleStreamChange = (id) => {
		const { channels } = roomData;
		if (channels && channels.length > 0) {
			const f = channels.filter((o) => o.streamId === id);
			if (f && f.length > 0) {
				const c = f[0];
				setPoster(c.signedUrl);
			}
		}
		setStreamId(id);
		setIsShowCams(false);
	};

	const handleOnProductClick = (code) => {
		setSelectedProductCode(code);
	};

	useEffect(() => {
		setStreamId(streamIdInProps);
		setPoster(posterInProps);
	}, [streamIdInProps]);

	return (
		<Container>
			<Player roomIdx={roomIdx} streamId={streamId} poster={poster} />
			<Product roomIdx={roomIdx} {...props} onClick={handleOnProductClick} setSocketConnect={setSocketConnect} socketConnect={socketConnect} />
			<Chatting roomIdx={roomIdx} roomData={roomData} setSocketConnect={setSocketConnect} socketConnect={socketConnect} />
			{isShowCams && <Cams roomIdx={roomIdx} roomData={roomData} current={streamId} onChange={handleStreamChange} />}
			{selectedProductCode && <ProductView {...cp} code={selectedProductCode} onClose={() => setSelectedProductCode(null)} />}
			<Actions>
				<button type="button" onClick={handleToggleCams}>
					<i className={cx({ fa: true, "fa-film": true })}></i>
				</button>
				<button type="button" onClick={handleHeartClick}>
					<i className={cx({ fa: true, "fa-heart": favorite, "fa-heart-o": !favorite })}></i>
				</button>
				<button type="button" onClick={handleChatClick}>
					<i className="fa fa-comments-o"></i>
				</button>
				<Link to="/cart" className="cart">
					<i className="fa fa-shopping-cart"></i>
				</Link>
			</Actions>
			<TopActions>
				<button type="button" onClick={handleBackClick}>
					<i className="fa fa-times"></i>
				</button>
			</TopActions>
			<Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} />
		</Container>
	);
};

const Container = styled.div``;

const TopActions = styled.div`
	top: 0px;
	position: fixed;
	right: 0px;
	z-index: 2;

	button {
		width: 40px;
		height: 40px;
		background: transparent;
		border: none;
		color: #ffffff;
		font-size: 18px;
		text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
		cursor: pointer;
	}
`;

const Actions = styled.div`
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 2;

	button,
	a {
		margin-top: 8px;
		width: 40px;
		height: 40px;
		display: block;
		border: none;
		border-radius: 20px;
		background: rgba(0, 0, 0, 0.5);
		color: #ffffff;
		font-size: 18px;
		cursor: pointer;
	}

	a {
		text-align: center;
		i {
			margin-top: 12px;
		}
	}

	.cart {
		background-color: #ffffff;
		color: #e32222;
	}
`;

const ProductView = (props) => {
	const { onClose, code } = props;

	const [categories, setCategories] = useState([]);
	const [detail, setDetail] = useState(null);
	const [options, setOptions] = useState([]);
	const [brand, setBrand] = useState([]);
	const [media, setMedia] = useState([]);
	const [content, setContent] = useState([]);

	const handleBackClick = () => {
		onClose && onClose();
	};

	const loadData = () => {
		_API
			.get({ path: "/product/search/item", data: { code } })
			.then((res) => res.data)
			.then((data) => {
				const { categories, detail, brand, media, content, options } = data;
				setCategories(categories);
				setDetail(detail);
				setOptions(options);
				setBrand(brand);
				setMedia(media);
				setContent(content);
			});
	};

	useEffect(() => {
		loadData();
	}, [code]);

	return (
		<PVContainer>
			<PVWrapper>
				<Info media={media} brand={brand} detail={detail} options={options} {...props} />
				<Content content={content} detail={detail} isLive />
			</PVWrapper>
			<TopActions>
				<button type="button" onClick={handleBackClick}>
					<i className="fa fa-times"></i>
				</button>
			</TopActions>
		</PVContainer>
	);
};

const PVContainer = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 100;
	background: rgba(0, 0, 0, 0.8);
`;

const PVWrapper = styled.div`
	position: fixed;
	top: 40px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 101;
	background: #ffffff;
	overflow-x: hidden;
	overflow-y: auto;
`;
