/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

export default (props) => {
	const { roomIdx, roomData, socketConnect, setSocketConnect } = props;
	const { auth, iam } = useContext(AppContext);
	// const [socketConnect, setSocketConnect] = useState(false);
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState("");
	const [isSendProc, setIsSendProc] = useState(false);
	const endRef = useRef(null);
	const intoRef = useRef(null);

	const sendRoom = () => {
		_U.postMessage("SOCKET-SEND", {
			action: "ROOM",
			data: {
				roomIdx,
			},
		});
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e)
			.then((data) => {
				switch (data.code) {
					case "SOCKET-RECEIVED":
						return data.data || null;
				}
			})
			.then((data) => {
				if (data?.mode === "CHAT") {
					setMessages((o) => [...o.slice(-99), data]);
				}
			});
	};

	const scrollToBottom = () => {
		intoRef.current.scrollTop = intoRef.current.scrollHeight;
		endRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
	};

	const onKeyUp = (e) => {
		if (e.key === "Enter" && !isSendProc) {
			onSend();
		}
	};

	const onSend = () => {
		if (!iam || !iam.idx) {
			swal({ title: "알림", text: "로그인 후 이용가능합니다." });
			return;
		}
		if (!socketConnect) {
			swal({ title: "알림", text: "채팅서버에 연결중입니다.\n잠시후 다시연결해 주세요." });
			return;
		}
		if (!message) {
			return;
		}
		if (isSendProc) {
			return;
		}
		setIsSendProc(true);

		_U.postMessage("SOCKET-SEND", {
			action: "CHAT",
			data: {
				mode: "CHAT",
				id: iam?.code + "_" + iam?.idx,
				code: iam?.code,
				idx: iam?.idx,
				name: iam?.name || "익명",
				nickName: iam?.nickName || "",
				roomIdx,
				message,
			},
		});

		setMessage("");
		setIsSendProc(false);
	};

	useEffect(() => {
		if (socketConnect) {
			sendRoom();
		}
	}, [socketConnect]);

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	useEffect(scrollToBottom, [messages]);

	return (
		<Container>
			<Wrapper>
				<TitleWrapper>
					<h3 className="title">
						<i className="fa fa-ellipsis-v" />
						채팅
					</h3>
				</TitleWrapper>
				<MessageWrapper>
					<div className="message-list" ref={intoRef}>
						{messages.map((item, i) => (
							<Message key={i} isMe={item.idx === iam?.idx}>
								{item.idx !== iam?.idx && <span className="name">{item.nickName}</span>}
								{item.message}
							</Message>
						))}
					</div>
				</MessageWrapper>
				<SenderWrapper>
					<h3 className="name">{iam?.name}</h3>
					<div className="form">
						<input type="text" placeholder="내용을 입력해 주세요" value={message || ""} onChange={(e) => setMessage(e.target.value)} onKeyUp={onKeyUp} />
						<button type="button" onClick={onSend}>
							보내기
						</button>
					</div>
				</SenderWrapper>
				<div ref={endRef} />
			</Wrapper>
			{/* <Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} /> */}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	width: 375px;
	padding: 12px 16px 16px;
	border: #efefef solid 1px;
	border-left: none;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const TitleWrapper = styled.div`
	padding-bottom: 12px;
	border-bottom: #efefef solid 1px;
	.title {
		margin: 0;
		padding: 0 0 0 8px;
		font-size: 16px;
		color: #3d3d3d;

		i {
			color: #acacac;
			margin-right: 10px;
		}
	}
`;

const MessageWrapper = styled.div`
	margin: 0;
	flex: 1;
	border-bottom: #efefef solid 1px;

	.message-list {
		height: 664px;
		overflow-x: hidden;
		overflow-y: auto;
	}
`;

const Message = styled.div`
	position: relative;
	margin: 2px 0;
	margin-right: ${(props) => (props.isMe ? "15px" : "0")};
	font-size: 13px;
	color: #3d3d3d;
	text-align: ${(props) => (props.isMe ? "right" : "left")};

	.name {
		margin-right: 12px;
		font-size: 13px;
		color: #a7a7a7;
	}
`;

const SenderWrapper = styled.div`
	margin-top: 12px;
	padding: 15px 16px;
	border: #e2e2e2 solid 1px;
	border-radius: 10px;
	background: #efefef;

	.name {
		font-size: 14px;
		margin: 0px;
		padding: 0px;
	}
	.form {
		margin-top: 10px;
		display: flex;
		input {
			flex: 1;
			font-family: ${(props) => props.theme.fontFamily.regular};
			padding: 5px 10px;
			border: #e2e2e2 solid 1px;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
		button {
			width: 80px;
			height: 48px;
			font-size: 16px;
			border: #e2e2e2 solid 1px;
			border-left: none;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
`;
