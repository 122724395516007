import React, { forwardRef } from "react";
import cx from "classnames";
import styled from "styled-components";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import moment from "moment";

import { getSrcSet, getBackgroundSet } from "./Assets";

export const SelectBox = (props) => {
    const { items, value, styles, onChange, placeholder, disabled } = props;

    return (
        <SelectBoxWrapper>
            <select onChange={onChange} styles={styles} value={value} disabled={disabled}>
                {placeholder && <option value="">{placeholder}</option>}
                {items?.map((item, index) => (
                    <option value={item.value} key={index}>
                        {item.label}
                    </option>
                ))}
            </select>
        </SelectBoxWrapper>
    );
};

const SelectBoxWrapper = styled.div`
    select {
        width: 100%;
        border: 1px solid #cfcfcf;
        font-family: ${(props) => props.theme.fontFamily.light};
        color: #777777;
        font-size: 12px;
        line-height: 16px;
        padding: 9px 16px;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        background-image: url("/icons/ic_selectdown.png");
        background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_selectdown.png", "/icons/ic_selectdown@2x.png", "/icons/ic_selectdown@3x.png"])});
        background-repeat: no-repeat;
        background-size: 10px 6px;
        background-position: right 10px center;
    }
`;

export const UInputForm = forwardRef((props, ref) => {
    const { title, type, value, name, placeholder, step, readOnly, onChange, onKeyDown, onClick, style, className, maxLength, pattern, onKeyPress, require } = props;

    return (
        <UIFContainer style={style ? style : {}} className={className ? className : ""}>
            {title && (
                <div className="uif-label">
                    {title} {require ? <span>*</span> : ``}
                </div>
            )}
            <div className="uif-form">
                <input
                    ref={ref}
                    className="uif-input"
                    type={type || "text"}
                    value={value || ""}
                    placeholder={placeholder}
                    maxLength={maxLength || ""}
                    pattern={pattern}
                    onChange={onChange}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    name={name}
                    step={step}
                    readOnly={readOnly}
                    onKeyPress={onKeyPress}
                />
            </div>
        </UIFContainer>
    );
});

export const UFormWrapper = (props) => {
    const { title, children, labelStyles, formStyles, styles } = props;

    return (
        <UIFContainer styles={styles} labelStyles={labelStyles} formStyles={formStyles}>
            <div className="uif-label">{title}</div>
            <div className="uif-form">{children}</div>
        </UIFContainer>
    );
};

export const UIFContainer = styled.div`
    position: relative;
    display: flex;
    margin: 0 0 12px;
    ${(props) => (props.styles ? props.styles : ``)}

    .uif-label {
        padding: 14px 0 0;
        width: 90px;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        ${(props) => (props.labelStyles ? props.labelStyles : ``)}

        span {
            color: #e32222;
        }
    }
    .uif-form {
        flex: 1;
        ${(props) => (props.formStyles ? props.formStyles : ``)}
    }
    .uif-form > input.uif-input {
        padding: 13px 16px;
        width: calc(100% - 32px);
        height: 16px;
        border: #efefef solid 1px;
        /* background: #f9f9f9; */
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        color: #3d3d3d;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: calc(100% - 34px);
        }
    }

    .uif-form > input.uif-input::placeholder {
        color: #dedede;
    }

    .uif-form > input.uif-input::-webkit-outer-spin-button,
    .uif-form > input.uif-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .react-datetime-picker__wrapper {
        padding: 7.5px 16px;
        border: #efefef solid 1px;
        background: #f9f9f9;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        color: #3d3d3d;
    }

    .react-date-picker__wrapper {
        padding: 7.5px 16px;
        border: #efefef solid 1px;
        background: #f9f9f9;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        color: #3d3d3d;
    }
`;

export const Input = (props) => {
    const { type, value, name, styles, onChange, placeholder, readOnly, onClick, desc } = props;

    return (
        <InputWrapper>
            <input type={type || "text"} value={value || ""} name={name} onChange={onChange} placeholder={placeholder} style={styles} readOnly={readOnly} onClick={onClick} />
            {desc && <div className="desc">{desc}</div>}
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    input {
        border: 1px solid #efefef;
        background: #ffffff;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #b8b8b8;
        font-size: 13px;
        line-height: 19px;
        padding: 9px 10px;
    }
`;

/**
 * CheckBox
 */
export const CheckBox = (props) => {
    const { title, checked, inline, onClick, value, margin, style } = props;

    return (
        <CBContainer inline={inline} checked={checked} onClick={onClick?.bind(this, value)} margin={margin} style={style}>
            <img
                src={`/icons/ic_check_${checked ? "on" : "off"}.png`}
                srcSet={getSrcSet(
                    checked
                        ? ["/icons/ic_check_on.png", "/icons/ic_check_on@2x.png", "/icons/ic_check_on@3x.png"]
                        : ["/icons/ic_check_off.png", "/icons/ic_check_off@2x.png", "/icons/ic_check_off@3x.png"]
                )}
                width="20"
                height="20"
                alt=""
            />
            {title}
        </CBContainer>
    );
};

const CBContainer = styled.div`
    display: ${(props) => (props.inline ? "inline-block" : "block")};

    font-size: 13px;
    color: ${(props) => (props.checked ? "#00788d" : "#777777")};

    cursor: pointer;

    img {
        ${(props) => (props.margin ? props.margin : `margin-right: 8px;`)}
        vertical-align: middle;
    }
`;

/**
 * Radio
 */
export const Radio = (props) => {
    const { title, checked, inline, onClick, value, style } = props;

    return (
        <CBContainer inline={inline} checked={checked} onClick={onClick?.bind(this, value)} style={style}>
            <img
                src={`/icons/ic_radio_${checked ? "on" : "off"}.png`}
                srcSet={getSrcSet(
                    checked
                        ? ["/icons/ic_radio_on.png", "/icons/ic_radio_on@2x.png", "/icons/ic_radio_on@3x.png"]
                        : ["/icons/ic_radio_off.png", "/icons/ic_radio_off@2x.png", "/icons/ic_radio_off@3x.png"]
                )}
                width="20"
                height="20"
                alt=""
            />
            {title}
        </CBContainer>
    );
};

/**
 * Textarea
 */
export const Textarea = (props) => {
    const { title, value, name, styles, onChange, placeholder, rows } = props;

    return (
        <TextWrapper>
            {title && <h4>{title}</h4>}
            <textarea value={value} name={name} onChange={onChange} placeholder={placeholder} style={styles} rows={rows || 5}></textarea>
        </TextWrapper>
    );
};

const TextWrapper = styled.div`
    width: 100%;

    h4 {
        font-weight: normal;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0;
    }

    textarea {
        border: 1px solid #efefef;
        background: #ffffff;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #b8b8b8;
        font-size: 13px;
        line-height: 19px;
        padding: 10px;
        width: calc(100% - 20px);
    }
`;

/**
 * DateTime Picker
 */

export const DateTime = (props) => {
    const { title, value, onChange } = props;
    let { format } = props;
    if (!format) {
        format = "YYYY-MM-DD HH:mm:ss";
    }

    const handleOnChange = (date) => {
        onChange && onChange(date ? moment(date).format(format) : null);
    };

    return (
        <UIFContainer>
            <div className="uif-label">{title}</div>
            <div className="uif-form">
                <DateTimePicker onChange={handleOnChange} value={value ? moment(value, format).toDate() : null} clearIcon={null} />
            </div>
        </UIFContainer>
    );
};

/**
 * Date Picker
 */

export const Date = (props) => {
    const { title, value, onChange, styles, maxDate } = props;
    let { format } = props;
    if (!format) {
        format = "YYYY-MM-DD";
    }

    const handleOnChange = (date) => {
        onChange && onChange(date ? moment(date).format(format) : null);
    };

    return (
        <UIFContainer styles={styles}>
            {title && <div className="uif-label">{title}</div>}
            <div className="uif-form">
                <DatePicker onChange={handleOnChange} value={value ? moment(value, format).toDate() : null} clearIcon={null} maxDate={maxDate} />
            </div>
        </UIFContainer>
    );
};
