/* eslint-disable */
import React, { useContext } from "react";
import { Redirect, Link } from "react-router-dom";

import { AppContext } from "./AppProvider";

import { _CFG } from "../Modules/Config";

export default (props) => {
    const { device } = useContext(AppContext);
    const { to, children, title, className } = props;

    switch (device) {
        case "android":
        case "ios":
            return (
                <a href={`${_CFG.appSchema}app?url=${encodeURIComponent(to)}`} className={className} title={title}>
                    {children}
                </a>
            );
        default:
            return (
                <Link to={to} className={className} title={title}>
                    {children}
                </Link>
            );
    }
};

export const NewWindow = (props) => {
    const { device } = useContext(AppContext);
    const { to, children, title, className } = props;

    switch (device) {
        case "android":
        case "ios":
            return (
                <a href={`${_CFG.appSchema}link?url=${encodeURIComponent(to)}`} className={className} title={title}>
                    {children}
                </a>
            );
        default:
            return (
                <a href={to} className={className} target="_blank" title={title}>
                    {children}
                </a>
            );
    }
};

export const openPage = ({ device, history, to }) => {
    history.push(to);
    // switch (device) {
    //     case "android":
    //     case "ios":
    //         document.location.href = `${_CFG.appSchema}app?url=${encodeURIComponent(to)}`;
    //         break;
    //     default:
    //         history.push(to);
    //         break;
    // }
};

export const Action = (props) => {
    const { device } = useContext(AppContext);
    const { children, title, className, action } = props;

    switch (device) {
        case "android":
        case "ios":
            return (
                <a href={`${_CFG.appSchema}${action}`} className={className} title={title}>
                    {children}
                </a>
            );
        default:
            return null;
    }
};

export const Back = (props) => {
    const { device } = useContext(AppContext);
    const { history, children, title, className } = props;

    const onClick = (e) => {
        e.preventDefault();
        history?.goBack();
    };

    switch (device) {
        case "android":
        case "ios":
            return (
                <a href={`${_CFG.appSchema}finish`} className={className} title={title}>
                    {children}
                </a>
            );
        default:
            return (
                <a href="#" className={className} title={title} onClick={onClick}>
                    {children}
                </a>
            );
    }
};
