/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import Paging, { ToPage, GetParams } from "../../UI/Paging";

import Layout from "../Layout";
import Product, { Breadcrumb, Accordion, CheckBox, Radio, ColorItem, ListWrapper } from "../../UI/Product";
import { getSrcSet } from "../../UI/Assets";

const _SORT = [
    { value: "order", label: "인기순", src: "/icons/ic_order.png", srcSet: ["/icons/ic_order.png", "/icons/ic_order@2x.png", "/icons/ic_order@3x.png"] },
    { value: "like", label: "추천순", src: "/icons/ic_order.png", srcSet: ["/icons/ic_order.png", "/icons/ic_order@2x.png", "/icons/ic_order@3x.png"] },
    { value: "review", label: "리뷰순", src: "/icons/ic_order.png", srcSet: ["/icons/ic_order.png", "/icons/ic_order@2x.png", "/icons/ic_order@3x.png"] },
];

const _COLORS = [
    { value: "화이트", label: "화이트", color: "#ffffff" },
    { value: "블랙", label: "블랙", color: "#000000" },
    { value: "그레이", label: "그레이", color: "#a2a2a2" },
    { value: "레드", label: "레드", color: "#e10606" },
    { value: "브라운", label: "브라운", color: "#9b8b55" },
    { value: "민트", label: "민트", color: "#2de6c0" },
    { value: "퍼플", label: "퍼플", color: "#bd5ae6" },
    { value: "그린", label: "그린", color: "#64d842" },
    { value: "핑크", label: "핑크", color: "#fb71f1" },
    { value: "블루", label: "블루", color: "#4dc6fc" },
    { value: "옐로우", label: "옐로우", color: "#f9f237" },
    { value: "오렌지", label: "오렌지", color: "#f5ae34" },
    { value: "골드", label: "골드", color: "#e6d461" },
    { value: "실버", label: "실버", color: "#dbdbdb" },
    { value: "베이지", label: "베이지", color: "#ece4b0" },
];

export default (props) => {
    const { location, history, match } = props;
    const { idx } = match.params;

    const [breadcrumb, setBreadcrumb] = useState([]);
    const [categories, setCategories] = useState([]);

    const [brand, setBrand] = useState("");
    const [brandList, setBrandList] = useState([]);
    const [costMin, setCostMin] = useState("");
    const [costMax, setCostMax] = useState("");
    const [discount, setDiscount] = useState("");
    const [shipping, setShipping] = useState([]);
    const [keyword, setKeyword] = useState(match.params?.keyword);

    const [sort, setSort] = useState(_SORT[0].value);
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [showSearchOption, setShowSearchOption] = useState(false);
    const [searchItem, setSearchItem] = useState(null);
    const [searchPage, setSearchPage] = useState(false);

    const onMore = () => {
        const params = { sort, brand, costMin, costMax, discount, shipping, page: paging.pageNo + 1, keyword, brandList };
        loadList(params);
    };

    const loadBreadcrumb = () => {
        _API.get({ path: "/product/breadcrumb", data: { idx } })
            .then((res) => res.data)
            .then((data) => {
                const bc = data.map((item) => {
                    return {
                        ...item,
                        path: `/category/${item.value}`,
                    };
                });
                setBreadcrumb(bc);
            });
    };

    const loadSubCategories = () => {
        _API.get({ path: "/product/category", data: { idx, keyword, brandList } })
            .then((res) => res.data)
            .then((data) => {
                setCategories(data);
            });
    };

    const loadList = (data) => {
        const params = { ...data, categoryIdx: idx };
        _API.get({
            path: "/product/search/category",
            data: params,
        })
            .then((res) => res.data)
            .then((data) => {
                const { list, paging } = data;

                if (!paging || paging?.pageNo === 1) {
                    setList(list);
                } else {
                    if (params.page <= paging.finalPageNo) {
                        setList((old) => [...old, ...list]);
                    }
                }
                setPaging(paging);
            });
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSearch();
        }
    };

    const onSearch = (key, value) => {
        const brandListArr = JSON.parse(JSON.stringify(brandList));

        if (brand && !brandListArr.includes(brand)) {
            brandListArr.push(brand);
            setBrandList(brandListArr);
        }

        ToPage(location, history, { sort, brand, costMin, costMax, discount, shipping, page: 1, keyword, brandList: brandListArr, [key]: value });
        onHideSearchOption();
    };

    const onReSearch = (params) => {
        ToPage(location, history, params);
    };

    const onChangeSearch = (e) => {
        switch (e.target.name) {
            case "costMin":
                setCostMin(e.target.value);
                break;
            case "costMax":
                setCostMax(e.target.value);
                break;
        }
        onSearch(e.target.name, e.target.value);
    };

    const onDiscountClick = (v) => {
        setDiscount(v);
        onSearch("discount", v);
    };

    const onShippingClick = (v) => {
        let s = [...shipping];
        if (s.includes(v)) {
            s = s.filter((o) => o !== v);
        } else {
            s.push(v);
        }
        setShipping(s);
        onSearch("shipping", s);
    };

    useEffect(() => {
        if (match.params?.keyword) {
            setKeyword(match.params?.keyword);
        }
    }, [match.params?.keyword]);

    useEffect(() => {
        if (idx) {
            loadBreadcrumb();
        }
    }, [idx]);

    useEffect(() => {
        loadSubCategories();
    }, [idx, keyword, brandList]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const params = GetParams(location);

        if (params) {
            setSort(params.sort || _SORT[0].value);
            setBrand(params.brand || "");
            setCostMin(params.costMin || "");
            setCostMax(params.costMax || "");
            setDiscount(params.discount || "");
            setShipping(params.shipping || []);
            setKeyword(params.keyword || "");
            setBrandList(params.brandList || []);
            loadList(params);

            setSearchItem(params);
        } else {
            setSort(_SORT[0].value);
            setBrand("");
            setCostMin("");
            setCostMax("");
            setDiscount("");
            setShipping([]);
            setKeyword(match.params?.keyword);
            setBrandList([]);
            loadList({ page: 1, keyword: match.params?.keyword });

            setSearchItem(null);
        }
    }, [location]);

    useEffect(() => {
        if (location.pathname.startsWith("/search")) {
            setSearchPage(true);
        } else {
            setSearchPage(false);
        }
    }, [location, keyword, idx]);

    const onShowSearchOption = () => {
        setShowSearchOption(true);
    };

    const onHideSearchOption = () => {
        setShowSearchOption(false);
    };

    const getCategoryLink = (url) => {
        if (brandList.length > 0 || keyword) {
            return `${url}?q=${_U.encode({ brandList, keyword })}`;
        } else {
            return url;
        }
    };

    return (
        <Layout {...props} showMenu={true}>
            {showSearchOption && <Dim onClick={() => setShowSearchOption(false)} />}
            <Container>
                <TitleWrapper>
                    <Breadcrumb breadcrumb={breadcrumb} keyword={keyword} brand={brand} brandList={brandList} searchItem={searchItem} onReSearch={onReSearch} searchPage={searchPage} />
                    <div className="options">
                        <Sort value={sort} items={_SORT} onChange={(t) => onSearch("sort", t.value)} onShowSearchOption={onShowSearchOption} />
                    </div>
                </TitleWrapper>
                <Wrapper>
                    <SearchOptions mShow={showSearchOption}>
                        <div className="wrapper">
                            {categories.length > 0 && (
                                <Accordion title="카테고리" showSearchOption={showSearchOption} location={location}>
                                    <ul className="category">
                                        {categories.map((item, i) => (
                                            <li key={i}>
                                                <Link to={getCategoryLink(`/category/${item.idx}`)} onClick={() => onHideSearchOption()}>
                                                    {item.name} <small>{_U.numberFormat(item.cntItem)}</small>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Accordion>
                            )}

                            <Accordion title="브랜드" showSearchOption={showSearchOption}>
                                <div className="search-keyword">
                                    <input type="text" className="search-form" value={brand} onChange={(e) => setBrand(e.target.value)} onKeyDown={onKeyDown} placeholder="ex) 상품권" />
                                    <button type="button" className="search-button" onClick={onSearch}>
                                        <img
                                            src="/icons/ic_search.png"
                                            srcSet={getSrcSet(["/icons/ic_search.png", "/icons/ic_search@2x.png", "/icons/ic_search@3x.png"])}
                                            width="16"
                                            height="16"
                                            alt="검색"
                                        />
                                    </button>
                                </div>
                            </Accordion>
                            <Accordion title="가격" showSearchOption={showSearchOption}>
                                <div className="search-cost">
                                    <select className="search-form" name="costMin" value={costMin} onChange={onChangeSearch}>
                                        {_DEF.PRODUCT_COST_MIN.map((item, i) => (
                                            <option key={i} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                    <span>~</span>
                                    <select className="search-form" name="costMax" value={costMax} onChange={onChangeSearch}>
                                        {_DEF.PRODUCT_COST_MAX.map((item, i) => (
                                            <option key={i} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Accordion>
                            <Accordion title="할인율" showSearchOption={showSearchOption}>
                                <ul className="search-check">
                                    {_DEF.PRODUCT_DISCOUNT.map((item, i) => (
                                        <li key={i}>
                                            <Radio title={item.label} value={item.value} checked={item.value === discount} onClick={onDiscountClick} />
                                        </li>
                                    ))}
                                </ul>
                            </Accordion>
                            {/* <Accordion title="배송유형" showSearchOption={showSearchOption}>
								<ul className="search-check">
									{_DEF.PRODUCT_SHIPPING.map((item, i) => (
										<li key={i}>
											<CheckBox title={item.label} value={item.value} checked={shipping.includes(item.value)} onClick={onShippingClick} />
										</li>
									))}
								</ul>
								<div className="search-desc">
									* 이 외 일반 배송 상품은 고객님께서 주문 후 발주가되는 상품으로 2~3일 후 수령하실 수 있습니다. (영업일 기준)<br />
									* 쿠폰 상품은 즉시 발송 됩니다.
								</div>
							</Accordion> */}
                        </div>
                    </SearchOptions>
                    <ItemsWrapper>
                        <InfiniteScroll dataLength={list.length} next={onMore} hasMore={paging && paging.pageNo < paging.totalSize}>
                            <ListWrapper>
                                {list.map((item, i) => (
                                    <Product key={i} data={item} history={history} />
                                ))}
                            </ListWrapper>
                        </InfiniteScroll>
                    </ItemsWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const Container = styled.div`
    margin: 0 auto 50px;
    width: ${(props) => props.theme.layout.width}px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }
`;

const TitleWrapper = styled.div`
    position: relative;
    margin: 10px 0 40px;
    padding: 0 0 20px;
    border-bottom: #3d3d3d solid 2px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding: 0 0 0 21px;
        border-bottom: 0px;
    }
    .options {
        position: absolute;
        right: 0;
        top: 0;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            /* left: 21px;
			bottom: -100px;
			top: unset; */
            position: relative;
            display: block;
            margin-top: 24px;
            text-align: right;
            padding-right: 21px;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
`;

const Dim = styled.div`
    display: none;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 998;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
    }
`;

const SearchOptions = styled.div`
    width: 226px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        ${(props) => (props.mShow ? `` : `visibility: hidden;`)}
        width: calc(78.5% - 60px);
        position: fixed;
        right: 0;
        top: 0;
        background: #ffffff;
        z-index: 999;
        padding: 30px;
        height: 100%;
        overflow-y: auto;
    }
    div.wrapper {
        position: -webkit-sticky;
        position: sticky;
        top: 61px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            top: 0;
            position: relative;
            background: #ffffff;
        }
    }

    ul.category {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul.category > li {
        padding: 8px 0;
    }

    ul.category > li > a {
        font-size: 13px;
        font-weight: normal;
        color: #777;
        text-decoration: none;
        display: block;
    }

    ul.category > li > a:hover {
        text-decoration: underline;
    }

    ul.category > li > a > small {
        float: right;
        clear: right;
        font-size: 10px;
        color: #acacac;
    }

    .search-keyword {
        position: relative;
    }

    .search-keyword > .search-form {
        width: 274px;
        height: 16px;
        padding: 11px 40px 11px 16px;
        font-family: ${(props) => props.theme.fontFamily.regular};
        border: solid 1px #efefef;
        background-color: #f7f7f7;
        font-size: 13px;
        color: #777;
    }

    .search-keyword > .search-form::placeholder {
        color: #c1c1c1;
    }

    .search-keyword > .search-button {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 40px;
        height: 40px;
        border: none;
        background: transparent;
    }

    .search-cost {
        display: flex;
    }

    .search-cost select.search-form {
        flex: 1;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        color: #acacac;

        padding: 11px 12px 11px 16px;
        border: solid 1px #efefef;
        background-color: #f7f7f7;
        background-image: url("/icons/ic_select_down.png");
        background-image: -webkit-image-set(url("/icons/ic_select_down.png") 1x, url("/icons/ic_select_down@2x.png") 2x, url("/icons/ic_select_down@3x.png") 3x);
        background-repeat: no-repeat;
        background-position: 90% center;
    }

    .search-cost select.search-form option {
        background: #cfcfcf;
        font-size: 13px;
        color: #777777;
        border-color: #efefef;
    }

    .search-cost span {
        flex-basis: 20px;
        text-align: center;
        font-size: 13px;
        color: #acacac;
    }

    .search-check {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .search-check > li {
        margin-bottom: 16px;
        padding: 0;
    }
    .search-check > li:last-child {
        margin-bottom: 0;
    }

    .search-desc {
        margin-top: 12px;
        font-size: 10px;
        color: #acacac;
    }

    .search-color {
        display: grid;
        grid-column-gap: 24px;
        grid-row-gap: 15px;
        grid-template-columns: repeat(auto-fit, 36px);
    }
`;

const ItemsWrapper = styled.div`
    margin-left: 30px;
    flex: 1;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-left: 0;
        padding: 0;
        width: 100%;
    }
`;

/**
 * Labels
 */
const Labels = (props) => {
    const { items, onClick } = props;
    return (
        <LBContainer>
            {items?.map((item, i) => (
                <button key={i} className={cx({ on: i % 2 === 0, off: i % 2 !== 0 })} onClick={onClick?.bind(this, item)}>
                    {item.label}
                    {i % 2 === 0 && (
                        <img
                            src="/icons/ic_close_sm_w.png"
                            srcSet={getSrcSet(["/icons/ic_close_sm_w.png", "/icons/ic_close_sm_w@2x.png", "/icons/ic_close_sm_w@3x.png"])}
                            width="7"
                            height="7"
                            alt={item.label}
                        />
                    )}
                </button>
            ))}
        </LBContainer>
    );
};

const LBContainer = styled.div`
    button {
        margin-right: 10px;
        padding: 5px 16px;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        border: none;
        border-radius: 16px;
        cursor: pointer;
    }
    button:last-child {
        margin-right: 0;
    }
    button > img {
        margin: 0 0 1px 12px;
    }

    button.on {
        background: #e32222;
        color: #ffffff;
    }

    button.off {
        background: #f7f7f7;
        color: #3d3d3d;
    }
`;

/**
 * Sort
 */

const Sort = (props) => {
    const { value, items, onChange, onShowSearchOption } = props;
    const [isShowOptions, setIsShowOptions] = useState(false);

    const getCurrentItem = () => {
        const f = items.filter((o) => o.value === value);
        return f.length > 0 ? f[0] : null;
    };

    const handleItemClick = (item) => {
        setIsShowOptions(false);
        onChange && onChange(item);
    };

    const current = getCurrentItem();

    return (
        <SContainer>
            {current && (
                <button type="button" className="current" onClick={(e) => setIsShowOptions(!isShowOptions)}>
                    <img src={current.src} srcSet={getSrcSet(current.srcSet)} alt="" />
                    {current.label}
                </button>
            )}
            <button className="btn-category" onClick={() => onShowSearchOption()}>
                <img src={"/icons/ic_category.png"} srcSet={getSrcSet(["/icons/ic_category.png", "/icons/ic_category@2x.png", "/icons/ic_category@3x.png"])} />
            </button>
            {isShowOptions && (
                <div className="options-wrapper">
                    {items.map((item, i) => (
                        <button key={i} type="button" className={cx({ on: item.value === value })} onClick={handleItemClick.bind(this, item)}>
                            <img src={item.src} srcSet={getSrcSet(item.srcSet)} alt="" />
                            {item.label}
                        </button>
                    ))}
                </div>
            )}
        </SContainer>
    );
};

const SContainer = styled.div`
    position: relative;

    button {
        width: 110px;
        height: 32px;
        font-size: 13px;
        background-color: #ffffff;
        color: #777;
        border: #cfcfcf solid 1px;
        cursor: pointer;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            height: 34px;
        }
    }

    button > img {
        margin-right: 12px;
        width: 15px;
        height: 16px;
        vertical-align: middle;
    }

    button.btn-category {
        display: none;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: inline-block;
            padding: 7px 12px;
            width: auto;
            margin-left: 12px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 0;
            }
        }
    }

    .options-wrapper {
        position: absolute;
        top: 0px;
        border: #cfcfcf solid 1px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: flex;
            flex-direction: column;
            right: 58px;
            top: 32px;
        }
    }

    .options-wrapper > button {
        width: 108px;
        border: none;
        border-bottom: #cfcfcf solid 1px;
    }

    .options-wrapper > button:last-child {
        border-bottom: none;
    }

    .options-wrapper > button:hover {
        background-color: #00788d;
        color: #ffffff;
    }

    .options-wrapper > button.on {
        background-color: #00788d;
        color: #ffffff;
    }
`;
