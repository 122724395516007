/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import { v4 as UUIDV4 } from "uuid";
import cx from "classnames";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";
import { tryToPlay } from "../../Components/AntMedia/fetch.stream.js";

const _VIDEO_ID = UUIDV4();

let _RENDERER = null;
let _VIDEO;
let _CVS = null;
let _CTX = null;

export default (props) => {
	const { id: idInProps, history } = props;

	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [streamId, setStreamId] = useState(null);
	const [adapter, setAdapter] = useState(null);
	const [muted, setMuted] = useState(true);

	const play = async (id) => {
		try {
			if (adapter) {
				await stopWebRTCStreaming(adapter, streamId);
			}
			startWebRTCStreaming(id).then((adapter) => {
				setStreamId(id);
				setAdapter(adapter);
				_RENDERER = window.requestAnimationFrame(onDraw);
			});
		} catch (e) {
			swal({ title: "오류", text: e.message || e.name || e.data });
		}
	};

	const toggleMute = () => {
		videoRef.current.muted = !videoRef.current.muted;
		setMuted(videoRef.current.muted);
	};

	const onDraw = () => {
		try {
			_CTX.clearRect(0, 0, _CVS.width, _CVS.height);
			_CTX.drawImage(_VIDEO, 0, 0, _CVS.width, _CVS.height);
		} catch (e) {}
		_RENDERER = window.requestAnimationFrame(onDraw);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	useEffect(() => {
		if (idInProps) {
			if (streamId !== idInProps) {
				play(idInProps);
			}
		}

		_VIDEO = videoRef.current;
		_CVS = canvasRef.current;
		_CTX = _CVS.getContext("2d");

		return () => {
			if (adapter) {
				stopWebRTCStreaming(adapter, streamId);
			}
			if (_RENDERER) {
				window.cancelAnimationFrame(_RENDERER);
			}
			_VIDEO = null;
			_CVS = null;
			_CTX = null;
		};
	}, [idInProps]);

	return (
		<Container>
			<Video ref={videoRef} id={_VIDEO_ID} autoPlay muted playsInline />
			<Canvas ref={canvasRef} width="1080" height="1920" />
			<Top>
				<button type="button" onClick={toggleMute}>
					<i className={cx({ fa: true, "fa-volume-off": muted, "fa-volume-up": !muted })}></i>
				</button>
				<button type="button" onClick={handleBackClick}>
					<i className="fa fa-times"></i>
				</button>
			</Top>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

const Video = styled.video`
	position: absolute;
	top: 0;
	left: 0;
	background: #000000;
	width: 100%;
	height: 100%;
	z-index: 0;
`;

const Canvas = styled.canvas`
	position: absolute;
	top: 0;
	left: 0;
	background: #000000;
	width: 100%;
	height: 100%;
	z-index: 10;
`;

const Top = styled.div`
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 20;

	button {
		width: 40px;
		height: 40px;
		background: transparent;
		border: none;
		color: #ffffff;
		font-size: 18px;
		text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
		cursor: pointer;
	}
`;

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (streamId, token) => {
	console.log(`startWebRTCStreaming`, streamId, token);
	return new Promise((resolve, reject) => {
		try {
			const adapter = new WebRTCAdaptor({
				websocket_url: _CFG.getStreamUrl(),
				mediaConstraints: { video: false, audio: false },
				peerconnection_config: null,
				sdp_constraints: {
					OfferToReceiveAudio: false,
					OfferToReceiveVideo: false,
				},
				remoteVideoId: _VIDEO_ID,
				isPlayMode: true,
				debug: true,
				callback: (info, description) => {
					switch (info) {
						case "initialized":
							adapter.getStreamInfo(streamId);
							break;
						case "streamInformation":
							adapter.play(streamId);
							break;
						case "play_started":
							_U.postMessage("PLAY-STARTED");
							break;
						case "play_finished":
							_U.postMessage("PLAY-ENDED");
							break;
						case "closed":
							if (typeof description != "undefined") {
								console.log("Connecton closed: " + JSON.stringify(description));
							}
							break;
						case "bitrateMeasurement":
							break;
					}
				},
				callbackError: (err) => {
					_U.postMessage("STREAMING-ERROR", err);
					switch (err) {
						case "no_stream_exist":
							break;
						case "notSetRemoteDescription":
							tryToPlay(streamId, token, hlsExtension);
							break;
						case "already_playing":
							break;
						default:
							_U.postMessage("PLAY-ERROR", err);
							break;
					}
				},
			});
			resolve(adapter);
		} catch (e) {
			reject(e);
		}
	});
};

const stopWebRTCStreaming = (adapter, streamId) => {
	adapter.stop(streamId);
};
