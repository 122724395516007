/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import Dialog from "../../UI/Dialog";

import Menu from "./Mypage.Menu";
import Review from "./Review.Component";
import { MypageTop } from "../../UI/Header";

export default (props) => {
	const { location, history, match } = props;

	const [keyword, setKeyword] = useState("");
	const [list, setList] = useState([]);
	const [paging, setPaging] = useState(null);

	const loadList = (data) => {
		_API.get({ path: "/user/mypage/order/review", data: { ...data, numPerPage: 10 } }).then((res) => {
			const { list, paging } = res.data;
			setList(list);
			setPaging(paging);
		});
	};

	const movePage = (page) => {
		ToPage(location, history, { keyword, page });
		window.scrollTo(0, 0);
	};

	const onSearch = (e) => {
		ToPage(location, history, { keyword, page: 1 });
	};

	const onRefresh = () => {
		loadList({ keyword, page: paging.pageNo });
	};

	useEffect(() => {
		const params = GetParams(location);
		if (params) {
			setKeyword(params.keyword);
			loadList(params);
		} else {
			setKeyword("");
			loadList({ page: 1 });
		}
	}, [location]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onClickReview = (item) => {
		Dialog({
			title: `${item.bbsIdx ? `리뷰 수정` : `리뷰 작성`}`,
			body: <Review {...{ location, history, match, orderIdx: item.orderIdx, productIdx: item.productIdx, bbsIdx: item.reviewIdx }} />,
		})
			.then((data) => {
				onRefresh();
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	const onRemoveClick = (item) => {
		swal({
			title: "알림",
			text: "리뷰를 삭제 하시겠습니까?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				const req = { path: "/bbs/status", data: { bbsIdx: item.reviewIdx, status: "D" } };
				_API.post(req).then((res) => {
					onRefresh();
				});
			}
		});
	};
	if (!_CK.isLogin()) {
		history.goBack();
		return null;
	}
	return (
		<Layout {...props} hideTop={true}>
			<Container>
				<MypageTop {...props}>{isMobile ? `마이리뷰` : `마이페이지`}</MypageTop>
				<Wrapper>
					<Menu {...props} />
					<ContentWrapper>
						<OLWrapper>
							<div className="top">
								<h2>마이리뷰</h2>
							</div>
							{list.length === 0 && <div className="empty">리뷰 내역이 없습니다.</div>}
							{list?.map((item, index) => (
								<ProductItem key={index} {...item} onClickReview={onClickReview} onRemoveClick={onRemoveClick} history={history} />
							))}
							{paging && (
								<div className="paging">
									<Paging paging={paging} pageCallback={movePage} />
								</div>
							)}
						</OLWrapper>
					</ContentWrapper>
				</Wrapper>
			</Container>
		</Layout>
	);
};

const Wrapper = styled.div`
	display: flex;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-bottom: 40px;
	}
`;

const ContentWrapper = styled.div`
	margin-left: 30px;
	padding-top: 10px;
	flex: 1;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-left: 0;
		padding: 0 21px;
	}
`;

const OLWrapper = styled.div`
	div.top {
		position: relative;
		display: flex;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-bottom: 1px solid #3d3d3d;
			margin-top: 24px;
			padding-bottom: 16px;
			display: none;
		}
	}

	div.empty {
		text-align: center;
		padding: 30px 0;
		font-size: 14px;
		color: #777777;
		font-family: ${(props) => props.theme.fontFamily.regular};
	}

	div.top > h2 {
		margin: 0px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
	}

	div.paging {
		margin-top: 20px;
	}
`;

const ProductItem = (props) => {
	const { onClickReview, onRemoveClick, history } = props;

	const getOptNames = () => {
		const tmp = props.optNames?.split(",");

		const result = [];
		for (let i in tmp) {
			result.push(<div key={i}>{tmp[i]}</div>);
		}

		return result;
	};

	return (
		<PIWrapper>
			<img src={props.productFileSignedUrl} onClick={() => history.push(`/product/${props.productCode}`)} />
			<div className="info" onClick={() => history.push(`/product/${props.productCode}`)}>
				<div>
					<div className="brand">{props.productBrandName}</div>
					<h4 className="title">{props.productName}</h4>
					<div className="option">{getOptNames()}</div>
				</div>
			</div>
			<div className="buttons">
				{props.reviewIdx > 0 ? (
					<>
						<button onClick={() => onClickReview(props)}>수정</button>
						<button onClick={() => onRemoveClick(props)}>삭제</button>
					</>
				) : (
					<button className="on" onClick={() => onClickReview(props)}>
						작성
					</button>
				)}
			</div>
		</PIWrapper>
	);
};

const PIWrapper = styled.div`
	border-bottom: 1px solid #efefef;
	display: flex;
	padding: 24px 0;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		flex-wrap: wrap;
	}

	> img {
		flex-basis: 90px;
		width: 90px;
		height: 90px;
		object-fit: cover;
		cursor: pointer;
	}

	> div.info {
		flex: 1;
		padding: 0 16px;
		display: flex;
		align-items: center;
		cursor: pointer;

		div.brand {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 13px;
			line-height: 19px;
		}

		h4.title {
			margin: 0px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
		}

		div.option {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 13px;
			line-height: 19px;
		}
	}

	> div.buttons {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #777777;
		font-size: 13px;
		line-height: 18px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-basis: 100%;
		}

		> button {
			border: 1px solid #777777;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #777777;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 8px 15px;
			margin-left: 8px;
			:first-child {
				margin-left: 0px;
			}
		}

		button.on {
			border: 1px solid #00788d;
			color: #ffffff;
			background: #00788d;
		}
	}
`;
