/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";
import { AppContext } from "../../Components/AppProvider";

import Menu from "./Mypage.Menu";
import { Input, Textarea, CheckBox } from "../../UI/Form";
import { MypageTop } from "../../UI/Header";

export default (props) => {
    const { iam, saveAuth, saveIam } = useContext(AppContext);
    const { location, history, match } = props;
    const [agree, setAgree] = useState(false);
    const [reason, setReason] = useState("");

    const onSave = () => {
        if (!agree) {
            swal({ title: "알림", text: "회원탈퇴 전, 유의사항 관련 내용에 동의하여주세요." });
            return;
        }

        if (!reason) {
            swal({ title: "알림", text: "탈퇴 사유를 입력해 주세요." });
            return;
        }

        swal({
            title: "회원 탈퇴를 진행하시겠습니까?",
            text: "탈퇴 후 복구가 불가합니다.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                const params = {
                    path: "/user/mypage/withdrawal",
                    data: { reason },
                };
                _API.post(params)
                    .then((res) => {
                        swal({ title: "알림", text: "탈퇴가 완료되었습니다." }).then(() => {
                            _CK.set(`passwordCert${iam?.idx}`, "");
                            _CK.clearAuth();
                            saveAuth(null);
                            saveIam(null);
                            history.push("/");
                        });
                    })
                    .catch((e) => {});
            }
        });
    };

    const getId = () => {
        if (iam?.snsApple) {
            return "애플 아이디로 가입한 계정입니다.";
        } else if (iam?.snsFacebook) {
            return "페이스북 아이디로 가입한 계정입니다.";
        } else if (iam?.snsGoogle) {
            return "구글 아이디로 가입한 계정입니다.";
        } else if (iam?.snsKakao) {
            return "카카오톡 아이디로 가입한 계정입니다.";
        } else if (iam?.snsNaver) {
            return "네이버 아이디로 가입한 계정입니다.";
        } else {
            return iam?.id;
        }
    };

    if (!_CK.isLogin()) {
        history.goBack();
        return null;
    }
    return (
        <Layout {...props} hideTop={true}>
            <Container>
                <MypageTop {...props}>{isMobile ? `탈퇴` : `마이페이지`}</MypageTop>
                <Wrapper>
                    <Menu {...props} />
                    <ContentWrapper>
                        <DWrapper>
                            <div className="top">
                                <h2>탈퇴</h2>
                            </div>
                            <div className="box">
                                <div className="desc-box">
                                    <p>1. 회원탈퇴 전, 유의사항을 확인해 주시기 바랍니다.</p>
                                    - 회원탈퇴 시 회원전용 웹 서비스 이용이 불가합니다.
                                    <br />
                                    - 거래정보가 있는 경우, 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 계약 또는 청약철회에 관한 기록, 대금결제 및 재화 등의 공급에 관한 기록은 5년동안
                                    보존됩니다.
                                    <br />
                                    - 유효기간이 경과되지 않은 미사용 쿠폰관련 정보는 유효기간 만료일까지 보관되며, 탈퇴 후에도 유효기간 내 사용하실 수 있습니다.
                                    <br />
                                    - 유효기간 내 사용하지 못한 미사용 쿠폰은 구매금액의 70%를 모아캐시로 적립해 드리나, 탈퇴시 적립 받을 수 없습니다.
                                    <br />
                                    - 보유하셨던 모아캐시는 탈퇴와 함께 삭제되며 환불되지 않습니다.
                                    <br />
                                    - 회원탈퇴 후 모아 서비스에 입력하신 상품문의 및 후기, 댓글은 삭제되지 않으며, 회원정보 삭제로 인해 작성자 본인을 확인할 수 없어 편집 및 삭제처리가 원천적으로
                                    불가능 합니다.
                                    <br />- 상품문의 및 후기, 댓글 삭제를 원하시는 경우에는 먼저 해당 게시물을 삭제하신 후 탈퇴를 신청하시기 바랍니다. 이미 결제가 완료된 건은 탈퇴로 취소되지 않습니다.
                                </div>
                                <div className="text-right">
                                    <CheckBox title="위 사실에 동의합니다" value="Y" checked={agree} onClick={() => setAgree(!agree)} inline />
                                </div>

                                <div className="form">
                                    <h3>탈퇴 사유를 작성해주시기 바랍니다.</h3>
                                    <table>
                                        <tr>
                                            <th>이메일</th>
                                            <td>{getId()}</td>
                                        </tr>
                                        <tr>
                                            <th>탈퇴사유</th>
                                            <td>
                                                <Textarea onChange={(e) => setReason(e.target.value)} value={reason} rows={10} />
                                            </td>
                                        </tr>
                                    </table>

                                    <div className="button">
                                        <button onClick={onSave}>회원탈퇴</button>
                                    </div>
                                </div>
                            </div>
                        </DWrapper>
                    </ContentWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding-bottom: 40px;
    }
`;

const ContentWrapper = styled.div`
    margin-left: 30px;
    padding-top: 10px;
    flex: 1;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-left: 0;
        padding: 0 21px;
    }
`;

const DWrapper = styled.div`
    div.top {
        position: relative;
        display: flex;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: 1px solid #3d3d3d;
            margin-top: 24px;
            padding-bottom: 16px;
            display: none;
        }
    }

    div.top > h2 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
    }

    div.box {
        margin-top: 16px;
    }

    div.box > div.desc-box {
        border: 1px solid #efefef;
        background: #fcfcfc;
        padding: 11px 20px;
        color: #9a9a9a;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 10px;

        p {
            font-weight: normal;
            margin: 0 0 10px;
            color: #3d3d3d;
        }
    }

    div.box > div.text-right {
        text-align: right;
    }

    table {
        border-spacing: 0;
        width: 100%;
        margin-top: 16px;
    }

    table > tr {
        color: #777777;
    }

    table > tr:first-child > th,
    table > tr:first-child > td {
        border-top: 1px solid #dfdfdf;
    }

    table > tr > th {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 12px;
        line-height: 17px;
        padding: 9px 0 9px 16px;
        border-bottom: 1px solid #dfdfdf;
        text-align: left;
        background: #f5f5f5;
        width: 15%;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 20%;
        }
    }

    table > tr > td {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        padding: 14px 16px;
        color: #3d3d3d;
        border-bottom: 1px solid #dfdfdf;

        P {
            padding: 0;
            margin: 0;
        }

        textarea {
            color: #3d3d3d;
        }
    }

    div.button {
        text-align: center;
        margin-top: 20px;
    }

    div.button > button {
        font-family: ${(props) => props.theme.fontFamily.regular};
        border: 1px solid #00788d;
        background: #00788d;
        color: #ffffff;
        font-size: 14px;
        line-height: 20px;

        padding: 15px 0;
        width: 220px;
    }

    h3 {
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #3d3d3d;
        font-size: 14px;
        line-height: 20px;
    }
`;
