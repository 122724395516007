/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AppContext } from "../../Components/AppProvider";
import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { OrderTop } from "../../UI/Header";
import { CheckBox, Radio } from "../../UI/Product";
import { SelectBox, Input } from "../../UI/Form";

import Layout from "../Layout";
import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

export default (props) => {
	const { history, match } = props;
	const { orderIdx } = match.params;
	const { iam } = useContext(AppContext);

	const [data, setData] = useState(null);

	const loadData = () => {
		// 회원 주문
		let path = "/user/payment/product/order";

		if (!iam?.idx) {
			// 비회원 주문
			path = "/payment/product/order";
		}

		_API.get({ path, data: { orderIdx, isFinish: "Y" } }).then((res) => {
			console.log(res.data);
			setData(res.data);
		});
	};

	useEffect(() => {
		if (orderIdx) {
			loadData();
		}
	}, [orderIdx]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout {...props} hideTop={true}>
			<Wrapper>
				<OrderTop active="3" {...props}>
					주문완료
				</OrderTop>

				<div className="text">
					<b>주문이 완료 되었습니다.</b>
					<br />
					감사합니다.
					<p>주문번호 : {data?.info?.paymentOrderNo}</p>
				</div>
				<ProductInfo {...data} />
				<DeliveryInfo {...props} data={data?.info} />
				<div className="split" />
				<Summary {...data} />

				<div className="buttons">
					<button onClick={() => history.push(`/order/detail/${iam?.idx ? orderIdx : data?.info?.paymentOrderNo}`)}>주문 상세 보기</button>
					{/* <button onClick={() => history.push(`/order/detail/${orderIdx}`)}>주문 상세 보기</button> */}
					<button className="action" onClick={() => history.push(`/`)}>
						쇼핑 계속하기
					</button>
				</div>
			</Wrapper>
		</Layout>
	);
};

const Wrapper = styled.div`
	width: ${(props) => props.theme.layout.productWidth}px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 80px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
	}

	div.split {
		display: none;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			background: #f8f8f8;
			width: 100%;
			height: 10px;
			display: block;
			margin-top: 16px;
		}
	}

	div.text {
		padding: 50px 0;
		text-align: center;
		font-family: ${(props) => props.theme.fontFamily.light};
		color: #3d3d3d;
		font-size: 20px;
		line-height: 29px;

		b {
			font-family: ${(props) => props.theme.fontFamily.medium};
			font-weight: normal;
		}

		p {
			font-family: ${(props) => props.theme.fontFamily.regular};
			padding: 0;
		}
	}

	> div.buttons {
		margin-top: 30px;
		text-align: center;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: fixed;
			bottom: 0;
			width: 100%;
			display: flex;
			margin-top: 0px;
		}
		button {
			border: 1px solid #00788d;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #00788d;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 15px 0;
			width: 220px;

			:last-child {
				margin-left: 12px;
			}
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				width: 100%;
				margin-left: 0px !important;
			}
		}

		button.action {
			border: 1px solid #00788d;
			background: #00788d;
			color: #ffffff;
		}
	}
`;

const ProductInfo = (props) => {
	const { localList, overseasList } = props;

	return (
		<PWrapper>
			<h3>상품배송 정보</h3>

			{localList?.length > 0 && (
				<div className="box">
					<h4>한국 배송 상품</h4>
					{localList?.map((item, index) => (
						<ProductItem key={index} {...item} />
					))}
				</div>
			)}
			{overseasList?.length > 0 && (
				<div className="box">
					<h4>해외 배송 상품</h4>
					{overseasList?.map((item, index) => (
						<ProductItem key={index} {...item} />
					))}
				</div>
			)}
		</PWrapper>
	);
};

const PWrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}

	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		margin: 0px;
	}

	div.box {
		border: 1px solid #dfdfdf;
		padding: 29px 24px;
		margin-top: 24px;

		h4 {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
			margin: 0px;

			:last-child {
				padding-top: 24px;
			}
		}
	}
`;

const ProductItem = (props) => {
	return (
		<PIWrapper>
			<img src={props.productFileSignedUrl} />
			<div className="info">
				<div>
					<div className="brand">{props.productBrandName}</div>
					<h4 className="title">{props.productName}</h4>
					<div className="option">
						{props.productOptItemList?.map((item, index) => (
							<div key={index}>
								{item.productOptionName} : {item.productOptionItemName} / 수량 {item.quantity}개
							</div>
						))}
					</div>
					<div className="price">{_U.numberFormat(props.totalAmountPayment)} 원</div>
				</div>
			</div>
		</PIWrapper>
	);
};

const PIWrapper = styled.div`
	border-bottom: 1px solid #efefef;
	display: flex;
	padding: 16px 0 35px;

	:last-child {
		border-bottom: 0px;
		padding-bottom: 0px;
	}

	> img {
		flex-basis: 90px;
		width: 90px;
		height: 90px;
		object-fit: cover;
	}

	> div.info {
		flex: 1;
		padding: 0 16px;
		display: flex;
		align-items: center;

		div.brand {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 13px;
			line-height: 19px;
		}

		h4.title {
			margin: 0px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
		}

		div.option {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 13px;
			line-height: 19px;
		}

		div.price {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			margin-top: 8px;

			span {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}
	}
`;

const DeliveryInfo = (props) => {
	const { data } = props;
	return (
		<DWrapper>
			<h3>받는사람 정보</h3>
			<div className="box">
				<dl>
					<dt>이름</dt>
					<dd>{data?.recipientName}</dd>
				</dl>
				<dl>
					<dt>연락처</dt>
					<dd>{data?.recipientMobile}</dd>
				</dl>
				<dl>
					<dt>배송주소</dt>
					<dd>
						{data?.recipientZipcode && `(${data?.recipientZipcode})`}
						{data?.recipientAddr} {data?.recipientAddrDtl}
						{/* <a>변경하기</a> */}
					</dd>
				</dl>
				<dl>
					<dt>배송 요청사항</dt>
					<dd>
						{data?.reqDesc}
						{/* <a>변경하기</a> */}
					</dd>
				</dl>
			</div>
		</DWrapper>
	);
};

const DWrapper = styled.div`
	margin-top: 40px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}

	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		margin: 0px;
	}

	> div.box {
		padding: 24px 0 40px;
		border-bottom: 1px solid #efefef;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			border-bottom: 0px;
		}
		> h3 {
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 18px;
			line-height: 26px;
			font-weight: normal;
			margin: 0px;

			button {
				background: #ffffff;
				border: 1px solid #efefef;
				text-align: center;
				padding: 8px 17px;
				font-weight: normal;
				font-family: ${(props) => props.theme.fontFamily.medium};
				color: #777777;
				font-size: 13px;
				line-height: 19px;
				margin-left: 8px;

				:first-child {
					margin-left: 24px;
				}
			}
		}

		dl {
			display: flex;
			justify-content: space-between;

			:first-child {
				margin: 0;
			}
		}

		dl > dt {
			flex-basis: 100px;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 14px;
			line-height: 20px;
		}

		dl > dd {
			flex: 1;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			position: relative;
			a {
				position: absolute;
				font-weight: normal;
				font-family: ${(props) => props.theme.fontFamily.medium};
				color: #777777;
				font-size: 13px;
				line-height: 19px;
				right: 0;
				top: 0;
				padding-right: 12px;

				background-image: url("/icons/ic_arrow_right.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_right.png", "/icons/ic_arrow_right@2x.png", "/icons/ic_arrow_right@3x.png"])});
				background-repeat: no-repeat;
				background-size: 6px 10px;
				background-position: right center;
			}
		}
	}
`;

const Summary = (props) => {
	const { totalSummary } = props;
	const { iam } = useContext(AppContext);

	return (
		<SWrapper>
			<h3>결제정보</h3>
			<div className="box">
				<dl>
					<dt>주문 금액</dt>
					<dd>{_U.numberFormat(totalSummary?.productAmount)} 원</dd>
				</dl>
				<dl>
					<dt>할인 금액</dt>
					<dd>
						<b><span>-{_U.addComma(totalSummary?.discountAmount)}</span> 원</b>
					</dd>
				</dl>
				{iam && iam.idx && (
					<Fragment>
						<dl>
							<dt>사용 포인트</dt>
							<dd>
								<b><span>-{_U.addComma(totalSummary?.usePoint)}</span> 원</b>
							</dd>
						</dl>
						<dl>
							<dt>쿠폰 할인</dt>
							<dd>
								<b><span>-{_U.addComma(totalSummary?.discountCoupon)}</span> 원</b>
							</dd>
						</dl>
					</Fragment>
				)}
				<dl>
					<dt>배송비</dt>
					<dd>{_U.numberFormat(totalSummary?.shippingAmount)} 원</dd>
				</dl>
				<dl className="total">
					<dt>총 결제금액</dt>
					<dd>
						<b>{_U.numberFormat(totalSummary?.totalAmount)} 원</b>
					</dd>
				</dl>
			</div>
		</SWrapper>
	);
};

const SWrapper = styled.div`
	margin-top: 40px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
	}

	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 18px;
		line-height: 26px;
		font-weight: normal;
		margin: 0px;
	}

	div.box {
		border: 1px solid #dfdfdf;
		background: #f9f9f9;
		padding: 24px;
		margin-top: 24px;
	}

	dl {
		display: flex;
		justify-content: space-between;
		margin: 16px 0 0 0;

		:first-child {
			margin: 0;
		}
	}

	dl > dt {
		flex: 1;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #b8b8b8;
		font-size: 14px;
		line-height: 20px;
	}

	dl > dd {
		flex: 1;
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 14px;
		line-height: 20px;
		text-align: right;

		span {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}

		b {
			color: #e32222;
			font-weight: normal;
		}
	}

	dl.total {
		margin: 33px 0 0 0;
	}
	dl.total > dt {
		font-family: ${(props) => props.theme.fontFamily.medium};
		color: #3d3d3d;
		font-size: 14px;
		line-height: 20px;
	}
	dl.total > dd {
		color: #e32222;
		font-size: 20px;
		line-height: 29px;
		span {
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}

		b {
			color: #e32222;
			font-weight: normal;
		}
	}
`;
