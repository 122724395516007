/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";
import Selector from "./Live.Product.Selector";

import Product, { ListWrapper } from "../../UI/Product";
import { getSrcSet } from "../../UI/Assets";
import Paging from "../../UI/Paging";

let numPerPage = 5;
let pageBlock = 5;

export default (props) => {
	const { location, history, match } = props;
	const { roomIdx } = props;

	const [list, setList] = useState([]);
	const [page, setPage] = useState(1);

	const handleNewProduct = () => {
		Dialog({
			title: "상품 선택",
			body: <Selector />,
		}).then((code) => {
			if (code) {
				_API
					.post({
						path: "/stream/product",
						data: { idx: roomIdx, code },
					})
					.then((res) => {
						console.log({
							action: "PRODUCT",
							data: {
								mode: "PRODUCT_INIT",
								roomIdx,
							},
						});

						_U.postMessage("SOCKET-SEND", {
							action: "PRODUCT",
							data: {
								mode: "PRODUCT_INIT",
								roomIdx,
							},
						});

						loadList();
					});
			}
		});
	};

	const handleOnPicked = (code) => {
		_API
			.delete({
				path: "/stream/product",
				data: { idx: roomIdx, code },
			})
			.then((res) => loadList());
	};

	const loadList = () => {
		_API
			.get({
				path: "/stream/product",
				data: { idx: roomIdx },
			})
			.then((res) => res.data)
			.then((list) => setList(list));
	};

	useEffect(() => {
		loadList();
	}, []);

	const getList = () => {
		const start = page === 1 ? 0 : (page - 1) * numPerPage;

		if (list.length > 0) {
			const tmpList = [];

			for (let i = start; i < page * numPerPage; i++) {
				if (list[i] && list[i]?.code) {
					tmpList.push(list[i]);
				}
			}

			return tmpList;
		}
	};

	const getPaging = () => {
		let startPageNo = (Math.ceil(page / pageBlock) - 1) * pageBlock + 1; //현재 세트내 출력될 시작 페이지

		let endPage = startPageNo + pageBlock - 1;

		if (endPage > totalPage()) {
			endPage = totalPage();
		}

		const isNowFirst = page == 1 ? true : false;
		const isNowFinal = page == totalPage() ? true : false;

		let prevPageNo = 1;
		if (isNowFirst) {
			prevPageNo = 1;
		} else {
			prevPageNo = page - 1 < 1 ? 1 : page - 1;
		}

		let nextPageNo = 1;
		if (isNowFinal) {
			nextPageNo = totalPage();
		} else {
			nextPageNo = page + 1 > totalPage() ? totalPage() : page + 1;
		}

		const blockpage = ((page - 1) / pageBlock) * pageBlock + 1;
		let prevBlockNo = 1;
		if (blockpage == 1) {
			prevBlockNo = 1;
		} else {
			prevBlockNo = blockpage - pageBlock;
		}

		let nextBlockNo = 1;
		if (endPage + pageBlock > totalPage()) {
			nextBlockNo = totalPage();
		} else {
			nextBlockNo = blockpage + pageBlock;
		}
		const start = (page - 1) * numPerPage;

		const paging = {
			start: start,
			startPageNo: startPageNo,
			endPageNo: endPage,
			prevBlockNo: prevBlockNo,
			nextBlockNo: nextBlockNo,
			prevPageNo: prevPageNo,
			nextPageNo: nextPageNo,
			pageNo: page,
			finalPageNo: totalPage(),
		};

		return paging;
	};

	const totalPage = () => {
		if (list.length === 0) {
			return;
		}

		const totalPage = Math.floor((list.length + (numPerPage - 1)) / numPerPage);

		return totalPage;
	};

	const movePage = (page) => {
		setPage(page);
	};

	return (
		<Container>
			<TitleWrapper>
				<h3 className="title">라이브 상품</h3>
				<button type="button" onClick={handleNewProduct}>
					<img src="/icons/ic_plus_stream.png" srcSet={getSrcSet(["/icons/ic_plus_stream.png", "/icons/ic_plus_stream@2x.png", "/icons/ic_plus_stream@3x.png"])} /> 상품추가
				</button>
			</TitleWrapper>

			<PListWrapper>
				{getList()?.map((item, i) => (
					<ProductItem key={i} data={item} isPicker onPicked={handleOnPicked} />
				))}
				{getPaging() && (
					<div className="paging">
						<Paging paging={getPaging()} pageCallback={movePage} />
					</div>
				)}
			</PListWrapper>
		</Container>
	);
};

const Container = styled.div`
	flex: 1;
	padding: 0 24px;
`;

const TitleWrapper = styled.div`
	position: relative;

	.title {
		margin: 0;
		padding: 0;
		font-size: 24px;
		color: #3d3d3d;
		line-height: 35px;
		font-family: ${(props) => props.theme.fontFamily.medium};
	}

	button {
		position: absolute;
		right: 0px;
		top: 0px;
		padding: 6px 16px;
		border: #00788d solid 1px;
		background: #00788d;
		color: #ffffff;
		display: block;
		font-size: 14px;
		font-family: ${(props) => props.theme.fontFamily.regular};
	}
`;
const PListWrapper = styled.div`
	display: block;
	margin-top: 16px;

	div.paging {
		margin-top: 20px;
	}
`;

const ProductItem = (props) => {
	const { history, data, isPicker, onPicked } = props;

	let device = null;
	if (!isPicker) {
		const { device: d } = useContext(AppContext);
		device = d;
	}

	const handleOnClick = () => {
		if (isPicker) {
			onPicked && onPicked(data.code);
		} else {
			openPage({ device, history, to: `/product/${data.code}` });
		}
	};

	const getDiscount = () => {
		try {
			return Math.round(data.priceDiscountPercent);
		} catch (e) {
			return data.priceDiscountPercent;
		}
	};

	return (
		<PIContainer onClick={handleOnClick}>
			<div className="thumbnail">
				<img src={data.signedThumbnailUrl || data.signedUrl} alt="" />
			</div>
			<div className="content">
				{data.brand && <h4 className="brand">{data.brand}</h4>}

				<h3 className="title">{data.name}</h3>
			</div>
			<div className="price">
				<h5>
					<b>{_U.numberFormat(data.priceSale)}</b>원{data?.priceDiscountPercent > 0 && <span className="discount">{getDiscount()}%</span>}
					{data?.priceDiscountPercent > 0 && (
						<div className="old-cost">
							<b>{_U.numberFormat(data.priceNormal)}</b>원
						</div>
					)}
				</h5>
			</div>
		</PIContainer>
	);
};

const PIContainer = styled.div`
	display: flex;
	border-bottom: 1px solid #efefef;
	padding: 30px 0;
	/* 
	:hover {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			box-shadow: unset;
		}
	} */

	.thumbnail {
		flex-basis: 90px;
		height: 90px;
		margin: 0 16px;
	}

	.thumbnail img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.content {
		flex: 1;
		padding: 0 20px;
	}

	.content > .brand {
		margin: 0;
		font-size: 13px;
		font-weight: normal;
		color: #777;
		font-family: ${(props) => props.theme.fontFamily.regular};
	}
	.content > .title {
		margin: 0;
		font-size: 16px;
		text-align: left;
		color: #3d3d3d;
		white-space: wrap;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 24px;
	}

	.content > .tags {
		margin-top: 18px;
	}

	.content > .tags > .tag {
		margin-right: 5px;
		padding: 3px 9px;
		font-size: 12px;
		color: #777;
		border: solid 1px #cfcfcf;
		display: inline-block;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			padding: 2px 8px;
			margin-right: 1px;
		}
	}
	.content > .tags > .tag:last-child {
		margin-right: 0;
	}
	.content > .tags > .red {
		color: #e32222;
	}

	.price {
		flex-basis: 200px;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: center;
		h5 {
			margin: 0;
			font-size: 20px;
			font-weight: normal;
			text-align: right;
			color: #3d3d3d;
			line-height: 20px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				font-size: 18px;
			}
			b {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
			}
		}
		h5 > span.discount {
			margin-left: 8px;
			font-size: 16px;
			font-weight: normal;
			color: #e32222;
			font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		}
		div.old-cost {
			margin: 0;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			text-align: left;
			text-decoration: line-through;
			color: #dedede;
			font-family: ${(props) => props.theme.fontFamily.medium};

			b {
				font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};
				font-weight: normal;
			}
		}
	}
`;
