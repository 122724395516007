/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

import Player from "./PC.Cams.Player";

export default (props) => {
	const { roomData: data, onChange, current } = props;
	if (!data) {
		return <Container />;
	}

	const [channels, setChannels] = useState([]);

	useEffect(() => {
		const { channels } = data;
		setChannels(channels);
	}, [data]);

	return (
		<Container>
			<Wrapper>
				{/* <Title>
					<h3 className="title">라이브 캠</h3>
				</Title> */}
				<CameraWrapper>
					{channels.map((item, i) => (
						<Player key={i} index={i} {...item} current={current} onChange={onChange} />
					))}
				</CameraWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	padding: 23px;
	flex-basis: calc(306px - 46px);
	background: #1d1d1d;
	max-height: calc(919px - 46px);
	overflow: auto;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
const Title = styled.div`
	.title {
		margin: 0;
		padding: 0;
		color: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 14px;
	}
`;

const CameraWrapper = styled.div`
	margin: 6px 0;
	/* max-height: 775px; */
	flex: 1;

	overflow-x: hidden;
	overflow-y: auto;

	::-webkit-scrollbar {
		display: none;
	}

	/*
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: #636e72;
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: #b2bec3;
        border-radius: 8px;
    }
    */
`;
