/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";
import { UInputForm, UIFContainer } from "../../UI/Form";
import { getSrcSet } from "../../UI/Assets";

import Player from "./Live.Cams.Player";

export default (props) => {
	const { roomIdx: idx, streamIdx } = props;

	const [channels, setChannels] = useState([]);

	const loadChannels = () => {
		_API
			.get({
				path: "/stream/channels",
				data: { idx },
			})
			.then((res) => res.data)
			.then((list) => setChannels(list));
	};

	const onCameraSettingChanged = () => {
		loadChannels();
	};

	const handleCameraAdd = () => {
		_API
			.post({
				path: "/stream/channel",
				data: { idx },
			})
			.then(() => {
				loadChannels();
			});
	};

	useEffect(() => {
		loadChannels();
	}, [idx]);

	return (
		<Container>
			<Wrapper>
				{/* <div className="title-wrapper">
					<h3 className="title">라이브 캠</h3>
				</div> */}
				<div className="cams-wrapper">
					{channels
						.filter((c) => (streamIdx ? c.streamId == streamIdx : c))
						.map((c, i) => (
							<SubCamera key={i} data={c} cameraIndex={i} onChange={onCameraSettingChanged} />
						))}
				</div>
				<div className="action-wrapper">
					<button type="button" onClick={handleCameraAdd}>
						<img src="/icons/ic_plus_stream.png" srcSet={getSrcSet(["/icons/ic_plus_stream.png", "/icons/ic_plus_stream@2x.png", "/icons/ic_plus_stream@3x.png"])} />
						캠추가
					</button>
				</div>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	padding: 23px;
	flex-basis: calc(306px - 46px);
	background: #333333;
	max-height: calc(853px - 46px);
	overflow: auto;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.title-wrapper {
		.title {
			margin: 0;
			padding: 0;
			color: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 14px;
		}
	}
	.cams-wrapper {
		margin: 6px 0;
		/* max-height: 725px; */
		flex: 1;

		overflow-x: hidden;
		overflow-y: auto;

		::-webkit-scrollbar {
			display: none;
		}

		/*
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-track {
            background-color: #636e72;
            border-radius: 8px;
        }
        ::-webkit-scrollbar-thumb {
            background: #b2bec3;
            border-radius: 8px;
        }
        */
	}
	.action-wrapper {
		button {
			width: 100%;
			height: 50px;
			border: #00788d solid 1px;
			background: #00788d;
			color: #ffffff;
			display: block;

			img {
				width: 10px;
				height: 10px;
				margin-right: 4px;
			}
		}
	}
`;

const SubCamera = (props) => {
	const { data, onChange, cameraIndex } = props;
	const { idx, title, fileIdx, signedUrl, type, streamId } = data;

	const handleSettingClick = () => {
		Dialog({
			title: "설정",
			body: <ConfigDlgView idx={idx} title={title} fileIdx={fileIdx} fileUrl={signedUrl} />,
		}).then((res) => {
			onChange && onChange();
		});
	};

	const handleRemoveClick = () => {
		swal({
			title: "알림",
			text: "캠을 삭제 하시겠습니까?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				_API
					.delete({
						path: "/stream/channel",
						data: { idx },
					})
					.then(() => {
						onChange();
					});
			}
		});
	};

	return (
		<CContainer>
			<h3>{type === "MAIN" ? "메인 캠" : `CAM #${cameraIndex}`}</h3>
			<Player id={streamId} poster={signedUrl} />
			<div className="title">{title}</div>
			<div className="actions">
				<button type="button" onClick={handleSettingClick}>
					<img src="/icons/ic_setting_stream.png" srcSet={getSrcSet(["/icons/ic_setting_stream.png", "/icons/ic_setting_stream@2x.png", "/icons/ic_setting_stream@3x.png"])} />
				</button>
				{type !== "MAIN" && (
					<button type="button" onClick={handleRemoveClick}>
						<img src="/icons/ic_close_stream.png" srcSet={getSrcSet(["/icons/ic_close_stream.png", "/icons/ic_close_stream@2x.png", "/icons/ic_close_stream@3x.png"])} />
					</button>
				)}
			</div>
		</CContainer>
	);
};

const CContainer = styled.div`
	position: relative;
	margin-bottom: 16px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: hidden;

	h3 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 14px;
		color: #dedede;
		line-height: 20px;
		padding: 0;
		margin: 0;
		margin-bottom: 6px;
	}

	.title {
		position: absolute;
		padding: 5px 0;
		left: 0px;
		right: 0px;
		bottom: 0px;
		font-size: 14px;
		text-align: center;
		color: #ebebeb;
		font-size: 14px;
		background: rgba(0, 0, 0, 0.7);
		font-family: ${(props) => props.theme.fontFamily.medium};

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.actions {
		position: absolute;
		top: -3px;
		right: 8px;

		button {
			font-size: 22px;
			width: 15px;
			height: 15px;
			color: #ffffff;
			background-color: transparent;
			border: none;
			text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

			:last-child {
				margin-left: 8px;
			}

			img {
				width: 15px;
				height: 15px;
			}
		}
	}
`;

const ConfigDlgView = (props) => {
	const { idx, fileIdx: fileIdxInProps, title: titleInProps, fileUrl, resolve, reject } = props;

	const titleRef = useRef(null);

	const [title, setTitle] = useState("");
	const [fileIdx, setFileIdx] = useState(null);
	const [fileData, setFileData] = useState(null);
	const [aws, setAws] = useState(null);

	const loadPrepare = () => {
		_API
			.get({ path: "/upload" })
			.then((res) => res.data)
			.then((data) => setAws(data));
	};

	const onFileChange = (e) => {
		if (e.target.files.length > 0) {
			const file = e.target.files[0];
			_API
				.upload({ aws, file })
				.then((res) => res.data)
				.then((data) => {
					setFileData(data);
					setFileIdx(data.id);
				});
		}
	};

	const onSave = () => {
		_API
			.post({
				path: "/stream/channel/setting",
				data: {
					idx,
					title,
					fileIdx,
				},
			})
			.then(() => {
				resolve();
			});
	};

	useEffect(() => {
		setTitle(titleInProps);
		setFileIdx(fileIdxInProps);
		loadPrepare();
	}, []);

	return (
		<CDVContainer>
			<UInputForm ref={titleRef} title="방송 제목" name="title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="방송 제목을 입력해 주세요." />
			<UIFContainer>
				<div className="uif-label">방송 섬네일</div>
				<div className="uif-form">
					<input type="file" accept="image/*" className="uif-input" onChange={onFileChange} />
					{(fileData || fileUrl) && <img src={fileData?.url || fileUrl} className="thumb" alt="" />}
				</div>
			</UIFContainer>
			<div className="buttons">
				<button onClick={() => reject()}>닫기</button>
				<button className="action" onClick={onSave}>
					저장
				</button>
			</div>
		</CDVContainer>
	);
};

const CDVContainer = styled.div`
	div.buttons {
		margin-top: 30px;
		text-align: center;

		button {
			border: 1px solid #e32222;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #e32222;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 15px 0;
			width: 220px;

			:last-child {
				margin-left: 12px;
			}
		}

		button.action {
			border: 1px solid #e32222;
			background: #e32222;
			color: #ffffff;
		}
	}

	img.thumb {
		margin-top: 15px;
		max-width: 100%;
		max-height: 400px;
	}
`;
