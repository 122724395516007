/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Layout from "../Layout";
import Dialog from "../../UI/Dialog";
import Socket from "../../Components/Socket";

import Viewer from "./PC.Viewer";
import Cams from "./PC.Cams";
import Chatting from "./PC.Chatting";
import Products from "./PC.Product";

import MobileView from "./Mobile";

export default (props) => {
	const { location, history, match } = props;
	const { idx } = match.params;
	if (!idx) {
		history.goBack();
		return null;
	}
	const isMobile = window.innerWidth < 1194;

	const [info, setInfo] = useState(null);
	const [streamId, setStreamId] = useState(null);
	const [poster, setPoster] = useState(null);
	const [socketConnect, setSocketConnect] = useState(false);

	const loadInfo = () => {
		_API
			.get({
				path: "/live",
				data: { idx },
			})
			.then((res) => res.data)
			.then((data) => {
				setInfo(data);
			});
	};

	const handleStreamChange = (id) => {
		const { channels } = info;
		if (channels && channels.length > 0) {
			const f = channels.filter((o) => o.streamId === id);
			if (f && f.length > 0) {
				const c = f[0];
				setPoster(c.signedUrl);
			}
		}
		setStreamId(id);
	};

	useEffect(() => {
		loadInfo();
	}, [idx]);

	useEffect(() => {
		if (info) {
			const { channels } = info;
			if (channels && channels.length > 0) {
				const f = channels.filter((o) => o.type === "MAIN");
				if (f && f.length > 0) {
					const c = f[0];
					setStreamId(c.streamId);
					setPoster(c.signedUrl);
				}
			}
		}
	}, [info]);

	useEffect(() => {
		const handleResize = () => {
			const is = window.innerWidth < 1194;
			if (isMobile !== is) {
				document.location.reload();
			}
		};
		handleResize();

		if (isMobile) {
			if (document.getElementById("ch-plugin")) {
				document.getElementById("ch-plugin").style.display = "none";
			}
		}

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (isMobile) {
		return <MobileView {...props} roomIdx={idx} streamId={streamId} poster={poster} roomData={info} />;
	}

	return (
		<Layout {...props}>
			<Container>
				<Title>
					<span>LIVE</span>
					{info?.title}
				</Title>
				<Wrapper>
					<Viewer roomIdx={idx} roomData={info} streamId={streamId} poster={poster} setSocketConnect={setSocketConnect} socketConnect={socketConnect} />
					<Cams roomIdx={idx} roomData={info} current={streamId} onChange={handleStreamChange} />
					<Products roomIdx={idx} {...props} />
					{/* <Chatting roomIdx={idx} roomData={info} setSocketConnect={setSocketConnect} socketConnect={socketConnect} /> */}
				</Wrapper>
				<Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} />
			</Container>
		</Layout>
	);
};

const Container = styled.div`
	width: ${(props) => props.theme.layout.width}px;
	margin: 0 auto 26px;
	position: relative;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
		margin: 0;
	}
`;

const Title = styled.div`
	font-family: ${(props) => props.theme.fontFamily.regular};
	font-size: 22px;
	font-weight: 500;
	color: #3d3d3d;

	span {
		display: inline-block;
		padding: 2px 12px;
		background: #e32222;
		color: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 18px;
		line-height: 26px;
		border-radius: 20px;
		margin-right: 11px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	margin-top: 10px;
	flex-wrap: wrap;
`;
