/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import Moment from "react-moment";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import { isIOS } from "react-device-detect";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";
import { tryToPlay } from "../../Components/AntMedia/fetch.stream.js";

const _SIZE = { width: 720, height: 1280 };
const _VIDEO_ID = `VIDEO-VIEWER-${UUIDV4()}`;

const hlsExtension = "m3u8";

export default (props) => {
	const { roomIdx, streamId: streamIdInProps, poster } = props;

	const videoRef = useRef(null);
	const [adapter, setAdapter] = useState(null);
	const [streamId, setStreamId] = useState(null);
	const [muted, setMuted] = useState(true);

	const play = async (id) => {
		try {
			if (adapter) {
				await stopWebRTCStreaming(adapter, streamId);
			}
			startWebRTCStreaming(id).then((adapter) => {
				setStreamId(id);
				setAdapter(adapter);
			});
		} catch (e) {
			swal({ title: "오류", text: e.message || e.name || e.data });
		}
	};

	const toggleMute = () => {
		videoRef.current.muted = !videoRef.current.muted;
		setMuted(videoRef.current.muted);
	};

	useEffect(() => {
		if (streamIdInProps) {
			if (streamId !== streamIdInProps) {
				play(streamIdInProps);
			}
		}
	}, [streamIdInProps]);

	return (
		<Container>
			<div className="sound-wrapper">
				<button type="button" onClick={toggleMute}>
					<i className={cx({ fa: true, "fa-volume-off": muted, "fa-volume-up": !muted })}></i>
				</button>
			</div>
			<div className="video-wrapper">
				<video ref={videoRef} id={_VIDEO_ID} autoPlay width={_SIZE.width} height={_SIZE.height} muted={muted} poster={poster} playsInline={isIOS} />
			</div>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: #000000;

	.video-wrapper {
		position: relative;
		padding-top: 177.7777%;
		width: 100%;

		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000000;
		}

		video[poster] {
			object-fit: cover;
		}
	}

	.sound-wrapper {
		position: absolute;
		right: 40px;
		top: 0px;
		z-index: 1;

		button {
			width: 40px;
			height: 40px;
			background: transparent;
			border: none;
			color: #ffffff;
			font-size: 18px;
			text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
			cursor: pointer;
		}
	}
`;

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (streamId, token) => {
	return new Promise((resolve, reject) => {
		try {
			const adapter = new WebRTCAdaptor({
				websocket_url: _CFG.getStreamUrl(),
				mediaConstraints: { video: false, audio: false },
				peerconnection_config: null,
				sdp_constraints: {
					OfferToReceiveAudio: false,
					OfferToReceiveVideo: false,
				},
				remoteVideoId: _VIDEO_ID,
				isPlayMode: true,
				debug: true,
				callback: (info, description) => {
					switch (info) {
						case "initialized":
							adapter.getStreamInfo(streamId);
							break;
						case "streamInformation":
							adapter.play(streamId);
							break;
						case "play_started":
							_U.postMessage("PLAY-STARTED");
							break;
						case "play_finished":
							_U.postMessage("PLAY-ENDED");
							break;
						case "closed":
							if (typeof description != "undefined") {
								console.log("Connecton closed: " + JSON.stringify(description));
							}
							break;
						case "bitrateMeasurement":
							break;
					}
				},
				callbackError: (err) => {
					_U.postMessage("STREAMING-ERROR", err);
					switch (err) {
						case "no_stream_exist":
							break;
						case "notSetRemoteDescription":
							tryToPlay(streamId, token, hlsExtension);
							break;
						case "already_playing":
							break;
						default:
							_U.postMessage("PLAY-ERROR", err);
							break;
					}
				},
			});
			resolve(adapter);
		} catch (e) {
			reject(e);
		}
	});
};

const stopWebRTCStreaming = (adapter, streamId) => {
	adapter.stop(streamId);
};
