/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import { isMobile, isIOS } from "react-device-detect";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { SelectBox } from "../../UI/Form";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

import Detail from "./View.Content.Detail";
import Seller from "./View.Content.Seller";
import Review from "./View.Content.Review";
import Question from "./View.Content.Question";
import Delivery from "./View.Content.Delivery";

export default (props) => {
    const { content, detail, isLive, media, brand } = props;

    const [tab, setTab] = useState("DETAIL");

    const detailTab = useRef(null);
    const sellerTab = useRef(null);
    const reviewTab = useRef(null);
    const questionTab = useRef(null);
    const deliveryTab = useRef(null);

    const scrollToRef = (ref) => {
        window.scrollTo(0, ref.current.offsetTop);
    };

    const handleScroll = () => {
        if (detail.isCoupon === "Y") {
            if (questionTab.current.offsetTop <= window.pageYOffset) {
                setTab("QUESTION");
            } else if (reviewTab.current.offsetTop <= window.pageYOffset) {
                setTab("REVIEW");
            } else if (detailTab.current.offsetTop <= window.pageYOffset) {
                setTab("DETAIL");
            }
            return;
        }

        if (deliveryTab.current.offsetTop <= window.pageYOffset) {
            setTab("DELIVERY");
        } else if (questionTab.current.offsetTop <= window.pageYOffset) {
            setTab("QUESTION");
        } else if (reviewTab.current.offsetTop <= window.pageYOffset) {
            setTab("REVIEW");
            // } else if (sellerTab.current.offsetTop <= window.pageYOffset) {
            // 	setTab("SELLER");
        } else if (detailTab.current.offsetTop <= window.pageYOffset) {
            setTab("DETAIL");
        }
    };

    const handleTabClick = (tabId, ref) => {
        setTab(tabId);
        scrollToRef(ref);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PDWrapper>
            <PDTabs isLive={isLive} isIOS={isIOS}>
                <li className={cx({ active: tab === "DETAIL" })} onClick={handleTabClick.bind(this, "DETAIL", detailTab)}>
                    상품상세정보
                </li>
                {/* <li className={cx({ active: tab === "SELLER" })} onClick={handleTabClick.bind(this, "SELLER", sellerTab)}>
                    판매자소개
                </li> */}
                <li className={cx({ active: tab === "REVIEW" })} onClick={handleTabClick.bind(this, "REVIEW", reviewTab)}>
                    리뷰
                </li>
                <li className={cx({ active: tab === "QUESTION" })} onClick={handleTabClick.bind(this, "QUESTION", questionTab)}>
                    상품문의
                </li>
                {detail.isCoupon !== "Y" && (
                    <li className={cx({ active: tab === "DELIVERY" })} onClick={handleTabClick.bind(this, "DELIVERY", deliveryTab)}>
                        배송/반품/교환
                    </li>
                )}
            </PDTabs>
            <Detail content={content} media={media} detail={detail} brand={brand} />
            <div ref={detailTab} />
            <hr />
            {/* <div ref={sellerTab} />
			<Seller /> */}
            <div ref={reviewTab} />
            <Review productInfo={detail} />
            <div ref={questionTab} />
            <Question productInfo={detail} />
            {detail.isCoupon !== "Y" && (
                <Fragment>
                    <div ref={deliveryTab} />
                    <Delivery detail={detail} />
                </Fragment>
            )}
        </PDWrapper>
    );
};

const PDWrapper = styled.div`
    margin-top: 60px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        border-top: 10px solid #f8f8f8;
        padding-top: 40px;
        margin-top: 40px;
    }

    hr {
        border: 0;
        border-top: 1px solid #dedede;
        margin: 60px 0;
        display: block;
        height: 1px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin: 40px 0;
            border-top: 10px solid #f8f8f8;
        }
    }
`;

const PDTabs = styled.ul`
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: table;
    width: 100%;
    position: sticky;
    top: 61px;
    z-index: 2;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        top: ${(props) => (props.isLive ? "0px" : props.isIOS ? "60px" : "59px")};
        width: calc(100% - 2px);
        left: 1px;
    }
    li {
        display: table-cell;
        padding: 15px 0;
        text-align: center;
        border: 1px solid #dedede;
        background: #f8f8f8;
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 14px;
        color: #acacac;
        line-height: 20px;
        width: 20%;
        border-right: none;
        cursor: pointer;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0;
            height: 50px;
            vertical-align: middle;
        }

        :last-child {
            border-right: 1px solid #dedede;
        }
    }

    li.active {
        border: 1px solid #777777;
        background: #ffffff;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
    }
`;
