/* eslint-disable */
import React, { useState, useRef, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
// import Slider from "react-slick";
import Slider from "react-slick-ios-fix";

import moment from "moment";
import nl2br from "nl2br";
import { isMobile } from "react-device-detect";
import { AppContext } from "../../Components/AppProvider";

import { _API, _U } from "../../Modules/Utils";

import { Fade } from "react-slideshow-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-slideshow-image/dist/styles.css";

import Dialog from "../../UI/Dialog";
import { getSrcSet } from "../../UI/Assets";
import ReportForm from "./ReportModal";

export default (props) => {
	const { item, history, reject } = props;
	const [images, setImages] = useState([]);

	useEffect(() => {
		const images = [];

		if (item?.imageList.length > 0) {
			for (let i in item?.imageList) {
				const image = item?.imageList[i];
				images.push(image.fileSignedUrl);
			}
			setImages(images);
		}
	}, [item]);

	const CustomNextArrow = (props) => {
		const { className, style, onClick } = props;

		return (
			<div className="sd-paging sd-next">
				<button type="button" onClick={onClick}>
					<img src={"/icons/ic_next.png"} srcSet={getSrcSet(["/icons/ic_next.png", "/icons/ic_next@2x.png", "/icons/ic_next@3x.png"])} alt="" />
				</button>
			</div>
		);
	};

	const CustomPrevArrow = (props) => {
		const { className, style, onClick } = props;

		return (
			<div className="sd-paging sd-prev" onClick={onClick}>
				<button type="button">
					<img src={"/icons/ic_prev.png"} srcSet={getSrcSet(["/icons/ic_prev.png", "/icons/ic_prev@2x.png", "/icons/ic_prev@3x.png"])} alt="" />
				</button>
			</div>
		);
	};

	const sliderSetting = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
	};

	return (
		<Dim>
			<ModalWrapper>
				<Close onClick={() => props.onClose()}>
					{isMobile ? (
						<img src="/icons/ic_remove.png" srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])} />
					) : (
						<img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} />
					)}
				</Close>
				<Modal>
					<ImageSlider>
						<Slider {...sliderSetting}>
							{images?.map((item, index) => (
								<a key={index} style={{ width: "100%", height: "100%" }}>
									<img src={item} style={{ width: "100%", height: "100%", maxHeight: "500px", objectFit: "cover" }} />
								</a>
							))}
						</Slider>
					</ImageSlider>
					<ReviewInfo>
						<Info>
							<Brand item={item} />
							{/* <LikeComment item={item} /> */}
							<Content item={item} history={history} />
						</Info>
						{/* <CommentList>
							<Comment />
						</CommentList> */}

						{/* <InputBox>
							<div style={{ padding: "16px", display: "flex", width: "100%", alignItems: "center" }}>
								<ReviewInput placeholder="댓글 달기" />
								<WriteButton>작성하기</WriteButton>
							</div>
						</InputBox> */}
					</ReviewInfo>
				</Modal>
			</ModalWrapper>
		</Dim>
	);
};

const Dim = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	justify-content: center;
	align-items: center;
	display: flex;
`;

const ModalWrapper = styled.div`
	width: 990px;
	height: fit-content;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		width: 100%;
	}
`;

const Close = styled.div`
	display: flex;
	justify-content: end;
	> img {
		cursor: pointer;
	}

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		position: absolute;
		top: 21px;
		right: 21px;
		z-index: 2;
	}
`;

const Modal = styled.div`
	margin-top: 20px;
	height: 640px;
	background-color: #ffffff;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin-top: 0;
		height: 100vh;
	}
`;

const ImageSlider = styled.div`
	display: block;
	overflow: hidden;
	position: relative;

	/* .react-slideshow-container {
		position: relative;
		width: 100%;
		height: 100%;
		max-height: 500px;

		.react-slideshow-fade-wrapper {
			width: 100%;
			height: 100%;

			.react-slideshow-fade-images-wrap {
				height: 100%;
				> div {
					height: 100%;
				}
			}
		}
	} */

	max-height: 500px;

	div.sd-paging {
		position: absolute;
		z-index: 2;
		button {
			background: transparent;
			border: none;
		}
	}

	div.sd-paging.sd-prev {
		top: 250px;
		left: 0;
	}
	div.sd-paging.sd-next {
		top: 250px;
		right: 0;
	}
`;

const ReviewInfo = styled.div`
	background-color: #ffffff;
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
const Info = styled.div`
	background-color: transparent;
	display: flex;
	flex-direction: column;
	position: relative;

	img.btn-report {
		position: absolute;
		right: 0;
		top: 5px;
		cursor: pointer;
	}
`;
const BrandWrap = styled.div`
	display: flex;
	gap: 4.6px;
	align-items: center;

	img {
		cursor: pointer;
	}
`;

const BrandMark = styled.img`
	width: 30px;
	height: 30px;
	object-fit: contain;
`;

const BrandName = styled.div`
	font-family: NotoSansCJKkr;
	font-size: 13px;
	font-weight: 300;
	line-height: 17px;
	text-align: left;
	color: #3d3d3d;
`;

const Count = styled.div`
	font-family: NanumSquareB;
	font-size: 14px;
	line-height: 16px;
	text-align: left;
	color: #acacac;
	margin-left: 8px;
`;

const InputBox = styled.div`
	width: 100%;
	height: 80px;
	border-radius: 10px;
	border: solid 1px #e2e2e2;
	background-color: #efefef;
	display: flex;
`;

const WriteButton = styled.div`
	font-family: NotoSansCJKkr;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: #3d3d3d;
	cursor: pointer;
`;
const ReviewInput = styled.textarea`
	resize: none;
	background-color: transparent;
	border: aliceblue;
	flex: 1;
	margin-right: 20px;
	font-family: NotoSansCJKkr;
	font-size: 13px;
	line-height: 19px;
	text-align: left;
`;
const Brand = (props) => {
	const { item } = props;
	const { auth } = useContext(AppContext);
	const likeEl = useRef(null);

	const onClickLike = (e) => {
		let likeCnt = item.likeCnt;

		_API
			.post({ path: "/bbs/like", data: { bbsIdx: item.bbsIdx } })
			.then((res) => res.data)
			.then((data) => {
				console.log(data);
				if (data.status === "Y") {
					likeEl.current.innerHTML = likeCnt + 1;
					e.target.src = "/icons/ic_heart_on.png";
					e.target.srcset = getSrcSet(["/icons/ic_heart_on.png", "/icons/ic_heart_on@2x.png", "/icons/ic_heart_on@3x.png"]);
				} else {
					likeEl.current.innerHTML = likeCnt > 0 ? likeCnt - 1 : 0;
					e.target.src = "/icons/ic_heart.png";
					e.target.srcset = getSrcSet(["/icons/ic_heart.png", "/icons/ic_heart@2x.png", "/icons/ic_heart@3x.png"]);
				}
			});
	};

	const onClickReport = () => {
		if (!auth) {
			_U.postMessage("LOGIN");
			return;
		}

		Dialog({
			title: `리뷰 신고`,
			body: <ReportForm {...{ bbsIdx: item?.bbsIdx }} />,
		})
			.then((data) => {
				if (data === "Y") {
					swal({ title: "알림", text: "리뷰 신고가 정상 접수 되었습니다." });
				}
			})
			.catch((e) => {
				if (e && e.url) {
					history.push(e.url);
				}
			});
	};

	return (
		<BrandWrap>
			<BrandMark src={item.brandFileSignedUrl} />
			<BrandName>{item.brandName}</BrandName>
			<div style={{ display: "flex", flexBasis: "20px", marginLeft: "20px" }}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<img
						src={item.myLike > 0 ? `/icons/ic_heart_on.png` : `/icons/ic_heart.png`}
						srcSet={
							item.myLike > 0
								? getSrcSet(["/icons/ic_heart_on.png", "/icons/ic_heart_on@2x.png", "/icons/ic_heart_on@3x.png"])
								: getSrcSet(["/icons/ic_heart.png", "/icons/ic_heart@2x.png", "/icons/ic_heart@3x.png"])
						}
						onClick={onClickLike.bind(this)}
					/>
					<Count ref={likeEl}>{item.likeCnt || 0}</Count>
				</div>

				<div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
					<img
						className="btn-report"
						src="/icons/ic_report.png"
						srcSet={getSrcSet[("/icons/ic_report.png", "/icons/ic_report@2x.png", "/icons/ic_report@3x.png")]}
						onClick={onClickReport}
					/>
				</div>
			</div>
		</BrandWrap>
	);
};

const LikeComment = (props) => {
	const { item } = props;

	return (
		<div style={{ display: "flex", flexBasis: "20px", marginTop: "20px" }}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<img src="/icons/ic_heart_circle.png" srcSet={getSrcSet(["/icons/ic_heart_circle.png", "/icons/ic_heart_circle@2x.png", "/icons/ic_heart_circle@3x.png"])} />
				<Count>0</Count>
			</div>

			{/* <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
				<img src="../sample/ic_comment.png" />
				<Count>{item.likeCount}</Count>
			</div> */}
		</div>
	);
};

const ProductName = styled.h3`
	color: #3d3d3d;
	font-size: 16px;
	cursor: pointer;
	font-family: ${(props) => props.theme.fontFamily.regular};
	font-weight: normal;
	margin: 0 0 8px 0;
`;

const ContentText = styled.span`
	margin-bottom: 6px;
	font-family: NotoSansCJKkr;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #777;
	.hash {
		font-weight: 500;
		color: #3d3d3d;
	}
`;

const Date = styled.div`
	font-family: NotoSansCJKkr;
	font-size: 12px;
	font-weight: 300;
	line-height: 16px;
	text-align: left;
	color: #acacac;
`;

const Content = (props) => {
	const { item, history } = props;

	return (
		<div style={{ marginTop: "12px" }}>
			<ProductName onClick={() => history.push(`/product/${item.productCode}`)}>{item.productName}</ProductName>
			<ContentText>
				<div dangerouslySetInnerHTML={{ __html: nl2br(item.content || "") }} />
				{/* {item.content.split(/(#[^\s]+)/g).map((v, i) => {
					if (v.match(/#[^\s]+/)) {
						return (
							<span key={i} className="hash">
								{v}
							</span>
						);
					}
					return v;
				})} */}
			</ContentText>
			<Date>{moment(item.regDate).format("YYYY년 MM월 DD일")}</Date>
		</div>
	);
};
const CommentList = styled.div`
	flex: 1;
	flex-direction: colum;
	background-color: transparent;
	overflow-y: scroll;
	overflow-x: hidden;
`;

const MemberName = styled.span`
	font-family: NotoSansCJKkr;
	font-size: 13px;
	font-weight: 500;
	line-height: 17px;
	text-align: left;
	color: #3d3d3d;
`;
const CommentContent = styled.span`
	margin-left: 6px;
	font-family: NotoSansCJKkr;
	font-size: 13px;
	line-height: 18px;
	text-align: left;
	color: #777777;
`;
const LikeWrap = styled.div`
	margin-left: 10px;
	display: flex;
	gap: 4px;
	flex-direction: row;
	align-items: center;
`;

const LickCount = styled.div`
	font-family: NanumSquareB;
	font-size: 13px;
	line-height: 13px;
	text-align: left;
	color: #acacac;
`;

const ReComment = styled.div`
	font-family: NotoSansCJKkr;
	font-size: 12px;
	line-height: 17px;
	text-align: left;
	color: #3d3d3d;
	cursor: pointer;
`;

const Comment = (props) => {
	return (
		<div style={{ marginBottom: "12px" }}>
			<div style={{ display: "flex", marginBottom: "6px" }}>
				<div>
					<MemberName>GUCCI</MemberName>
					<CommentContent>고객님의 소중한 후기 감사드립니다! 적립금 1,000원이 지급될 예정이며, 앞으로도 많은 이용 부탁드립니다. 감사합니다:)</CommentContent>
				</div>
				<LikeWrap>
					<img src="../sample/ic_heart.png" />
					<LickCount>3</LickCount>
				</LikeWrap>
			</div>
			<div style={{ display: "flex", gap: "10px" }}>
				<Date>어제</Date>
				<ReComment>답글달기</ReComment>
			</div>
		</div>
	);
};
