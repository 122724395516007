/* eslint-disable */

export default () => {
	return new Promise(async (resolve, reject) => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
			if (stream) {
				return resolve(true);
			}
			resolve(false);
		} catch (e) {
			const msg = e.name;

			switch (msg) {
				case "NotAllowedError":
					return reject({ message: "카메라와 마이크의 권한을 허용해 주세요." });
				case "NotFoundError":
				case "SecurityError":
					return reject({ message: "연결된 카메라 장치가 없거나 카메라 정보를 가져 올 수 없습니다." });
				case "AbortError":
				case "NotReadableError":
					return reject({ message: "카메라 혹은 마이크가 다른 곳에서 사용중입니다." });
				default:
					return reject({ message: e.message });
			}
		}
	});
};
