import React, { useContext, useState, useEffect, Fragment } from "react";
import cx from "classnames";
import styled from "styled-components";

export const BlankA = styled.a`
    cursor: pointer;
`;

export const BlockButton = styled.button.attrs((props) => {
    return {
        type: props.type || "button",
        className: `btn btn-block btn-lg ${props.className}`,
    };
})``;

export const SaveButton = styled.button.attrs((props) => {
    return {
        type: props.type || "button",
        className: `btn btn-primary active  ${props.className}`,
    };
})``;

export const CancelButton = styled.button.attrs((props) => {
    return {
        type: props.type || "button",
        className: `btn btn-outline-theme active  ${props.className}`,
    };
})``;

export const ModalCloseButton = styled.div`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 9999;
    cursor: pointer;
    i {
        font-size: 3rem;
        color: #222;
        font-weight: bold;
    }
`;
