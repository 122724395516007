/* eslint-disable */
import React, { useEffect, useState } from "react";
import GlobalStyles, { ThemeProvider, Theme } from "./Components/GlobalStyles";
import qs from "query-string";

// App Provider
import { AppProvider } from "./Components/AppProvider";

// Utils
import { _CK, _API } from "./Modules/Utils";

// UI
import { Loading } from "./UI/Loading";

// Router
import Views from "./Views";

export default () => {
    const [loaded, setLoaded] = useState(false);
    const [auth, setAuth] = useState("");
    const [iam, setIam] = useState(null);
    const [device, setDevice] = useState("");
    const [version, setVersion] = useState("");
    const [appversion, setAppversion] = useState("");
    const [lang, setLang] = useState("KR");

    const preLoad = async () => {
        let parsedQs = null;
        if (window.location.search) {
            parsedQs = qs.parse(window.location.search);
        }

        try {
            if (parsedQs) {
                setDevice(parsedQs.device);
                setVersion(parsedQs.version);
                setAppversion(parsedQs.appversion);
                setLang(parsedQs.lang);
            } else if (_CK.get("device")) {
                setDevice(_CK.get("device"));
                setVersion(_CK.get("version"));
                setAppversion(_CK.get("appversion"));
                setLang(_CK.get("lang"));
            }

            if (_CK.isLogin() || parsedQs?.auth) {
                const data = await _API.iam(parsedQs?.auth);
                const { data: iam } = data;
                setAuth(_CK.getAuth() || parsedQs?.auth);
                _CK.setAuth(_CK.getAuth() || parsedQs?.auth);
                setIam(iam);
            }
            setLoaded(true);
        } catch (e) {
            //*
            _CK.clearAuth();
            if (parsedQs) {
                document.location.href = `/?device=${parsedQs.device}&version=${parsedQs.version}&appversion=${parsedQs.appversion}&lang=${parsedQs.lang}`;
            } else {
                document.location.href = "/";
            }
            /**/
            /*
			if (process.env.STAGE === "local") {
				console.log(e);
			} else {
				_CK.clearAuth();
				if (parsedQs) {
					document.location.href = `/?device=${parsedQs.device}&version=${parsedQs.version}&appversion=${parsedQs.appversion}&lang=${parsedQs.lang}`;
				} else {
					document.location.href = "/";
				}
			}
			/**/
        }
    };

    useEffect(() => {
        preLoad();
    }, []);

    if (!loaded) {
        return (
            <ThemeProvider theme={Theme}>
                <Loading dark />
                <GlobalStyles />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={Theme}>
            <AppProvider auth={auth} iam={iam} device={device} version={version} appversion={appversion} lang={lang}>
                <Views />
                <GlobalStyles />
            </AppProvider>
        </ThemeProvider>
    );
};
