/* eslint-disable */
import { ConsoleView } from "react-device-detect";
import { _CFG } from "../Modules/Config";

export const TYPES = {
	KAKAO: "KAKAO",
	NAVER: "NAVER",
	GOOGLE: "GOOGLE",
	FACEBOOK: "FACEBOOK",
	APPLE: "APPLE",
};

export default class SNSLogin {
	isDev = false;
	isUseNaver = false;
	isUseKakao = false;
	isUseFacebook = false;
	isUseGoogle = false;
	isUseApple = false;

	googleSignIn = null;
	googleSignError = null;
	device = null;
	version = null;
	appversion = null;
	lang = null;

	constructor(defs) {
		if (!defs) {
			throw new Error("SNS 사용 타입을 정의해 주세요.");
		}

		const { kakao, naver, google, apple, facebook, dev, googleSignIn, googleSignError, device, version, appversion, lang } = defs;
		this.isDev = dev === true ? true : false;
		this.isUseNaver = naver === true ? true : false;
		this.isUseKakao = kakao === true ? true : false;
		this.isUseFacebook = facebook === true ? true : false;
		this.isUseGoogle = google === true ? true : false;
		this.isUseApple = apple === true ? true : false;
		this.device = device;
		this.version = version;
		this.appversion = appversion;
		this.lang = lang;

		this.googleSignIn = googleSignIn;
		this.googleSignError = googleSignError;

		if (this.isDev) {
			console.log(":::: SNSLogin constructor ::::");
			console.log(defs);
		}
		if (this.isUseKakao) {
			this._initKakao();
		}
		if (this.isUseGoogle) {
			this._initGoogle();
		}
		if (this.isUseNaver) {
			this._initNaver();
		}
	}

	request = (type) => {
		if (this.isDev) {
			console.log(`:: Login Request - ${type} ::`);
		}
		switch (type) {
			case TYPES.KAKAO:
				return this._loginByKakao();
			case TYPES.NAVER:
				break;
			case TYPES.GOOGLE:
				break;
			case TYPES.FACEBOOK:
				break;
			case TYPES.APPLE:
				break;
		}
	};

	signOut = () => {
		if (this.isUseKakao) {
			Kakao.Auth.logout(() => {
				if (this.isDev) {
					console.log(":: Kakao SignOut ::");
				}
			});
		}
	};

	/**
	 * for Naver Start
	 */

	_initNaver = () => {
		if (this.isDev) {
			console.log(":: Naver Initialize Start ::");
		}

		this.naverLogin = getNaverLogin(window.location.hash);

		// naverLogin.reprompt();
		if (this.isDev) {
			console.log(":: Naver Initialize End ::");
		}
	};

	/**
	 * for Google Start
	 */

	_initGoogle = () => {
		if (this.isDev) {
			console.log(":: Google Initialize Start ::");
		}
		gapi.load("auth2", this._googleInitialized);
	};

	_googleInitialized = () => {
		gapi.auth2.init();
		const options = new gapi.auth2.SigninOptionsBuilder();
		options.setPrompt("select_account");
		options.setScope(
			[
				"email",
				"profile",
				"openid",
				"https://www.googleapis.com/auth/profile.agerange.read",
				"https://www.googleapis.com/auth/user.birthday.read",
				"https://www.googleapis.com/auth/user.gender.read",
				"https://www.googleapis.com/auth/userinfo.email",
				"https://www.googleapis.com/auth/userinfo.profile",
			].join(" ")
		);
		// 인스턴스의 함수 호출 - element에 로그인 기능 추가
		// GgCustomLogin은 li태그안에 있는 ID, 위에 설정한 options와 아래 성공,실패시 실행하는 함수들
		gapi.auth2.getAuthInstance().attachClickHandler("btn-google-login", options, this._googleSignIn, this._googleSignError);

		if (this.isDev) {
			console.log(":: Google Initialize End ::");
		}
	};

	_googleSignIn = (user) => {
		const accessToken = user.getAuthResponse().id_token;
		const profile = user.getBasicProfile();
		const id = profile.getId();
		const name = profile.getName();
		const imageUrl = profile.getImageUrl();
		const email = profile.getEmail();
		const data = {
			accessToken,
			id,
			profile: {
				id,
				name,
				nickName: name,
				imageUrl,
				email,
			},
		};
		this.googleSignIn && this.googleSignIn(data);
	};

	_googleSignError = (e) => {
		const { error } = e;
		this.googleSignError && this.googleSignError({ message: error });
	};

	/**
	 * for Kakao Start
	 */
	_initKakao = () => {
		if (this.isDev) {
			console.log(":: Kakao Initialize Start ::");
		}
		if (!Kakao.isInitialized()) {
			Kakao.init(_CFG.kakaoKey);
		}
		if (this.isDev) {
			console.log(`Kakao Is Initialized : ${Kakao.isInitialized()}`);
			console.log(":: Kakao Initialize End ::");
		}
	};

	_loginByKakao = () => {
		return new Promise((resolve, reject) => {
			try {
				const getGender = (g) => {
					switch (g) {
						case "male":
							return "M";
						case "female":
							return "F";
						default:
							return null;
					}
				};
				const _ERROR = (e) => {
					const { error, error_description } = e;
					reject({ code: error, message: error_description });
				};
				const _IAM = (tokens) => {
					Kakao.API.request({
						url: "/v2/user/me",
						success: (res) => {
							const { id, kakao_account: account } = res;
							const { profile } = account;

							const ret = { ...tokens, id };
							ret.profile = {
								id,
								nickName: profile?.nickname,
								ageRange: account.age_range,
								birthday: account.birthday,
								birthdayType: account.birthday_type,
								email: account.email,
								gender: getGender(account.gender),
							};
							resolve(ret);
						},
						fail: _ERROR,
					});
				};

				Kakao.Auth.login({
					scope: "account_email,gender,profile_nickname,birthday",
					success: (data) => {
						const { access_token: accessToken, refresh_token: refreshToken } = data;
						_IAM({ accessToken, refreshToken });
					},
					fail: _ERROR,
				});
			} catch (e) {
				reject(e);
			}
		});
	};

	/**
	 * for Kakao End
	 */
}

export const getNaverLogin = (url) => {
	const naverLogin = new naver.LoginWithNaverId({
		clientId: _CFG.naver.login.clientId,
		callbackUrl: _CFG.getCallbackUrlForNaverLogin() + `?url=${encodeURIComponent(url)}`,
		isPopup: false,
		callbackHandler: true,
	});
	naverLogin.init();
	return naverLogin;
};
