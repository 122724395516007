/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import Paging, { ToPage, GetParams } from "../../UI/Paging";
import Dialog from "../../UI/Dialog";

import Menu from "../User/Mypage.Menu";
import { _DEF } from "../../Modules/Config";
import { MypageTop } from "../../UI/Header";
import { UInputForm, UFormWrapper, CheckBox, Textarea, SelectBox, DateTime, Date } from "../../UI/Form";
import { getBackgroundSet } from "../../UI/Assets";

export default (props) => {
    const { location, history, match } = props;

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [startDate, setStartDate] = useState(moment().add(-1, "month"));
    const [endDate, setEndDate] = useState(moment());
    const [month, setMonth] = useState(1);

    const loadList = (data) => {
        _API.get({
            path: "/user/mypage/order",
            data: { ...data, startDate: moment(data.startDate).format("YYYY-MM-DD"), endDate: moment(data.endDate).format("YYYY-MM-DD"), numPerPage: 5, returnYn: "Y" },
        }).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const movePage = (page) => {
        ToPage(location, history, { keyword, page, startDate, endDate, month });
        window.scrollTo(0, 0);
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword, page: 1, startDate, endDate, month });
    };

    const onRefresh = () => {
        loadList({ keyword, page: paging.pageNo, startDate, endDate, month });
    };

    useEffect(() => {
        const params = GetParams(location);
        if (params) {
            setKeyword(params.keyword);
            setStartDate(moment(params.startDate));
            setEndDate(moment(params.endDate));
            setMonth(params.month);
            loadList(params);
        } else {
            setKeyword("");
            setStartDate(moment().add(-1, "month"));
            setEndDate(moment());
            setMonth(1);
            loadList({ page: 1, startDate: moment().add(-1, "month"), endDate: moment(), month: 1 });
        }
    }, [location]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const goDetail = (item) => {
        if (item?.asInfo) {
            history.push(`/user/my/request/return/${item.asInfo.idx}/${item.orderIdx}/${item.productIdx}`);
        } else if (item) {
            history.push(`/user/my/request/return/new/${item.orderIdx}/${item.productIdx}`);
        }
    };

    const onDateSearch = () => {
        ToPage(location, history, { keyword, page: 1, startDate, endDate, month });
    };

    if (!_CK.isLogin()) {
        history.goBack();
        return null;
    }

    return (
        <Layout {...props} hideTop={true}>
            <Container>
                <MypageTop {...props}>{isMobile ? `반품 신청` : `마이페이지`}</MypageTop>
                <Wrapper>
                    <Menu {...props} />
                    <ContentWrapper>
                        <OLWrapper>
                            <div className="top">
                                <h2>반품 신청</h2>
                            </div>
                            <Search onSearch={onDateSearch} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} month={month} setMonth={setMonth} />
                            {list.length === 0 && <div className="empty">신청 가능한 상품이 없습니다.</div>}
                            {list?.map((item, index) => (
                                <div key={index} className="box">
                                    <div className="order-date-no">
                                        <div className="date" onClick={() => goOrderDetail(item.orderIdx)}>
                                            {moment(item?.regDate).format("YYYY.MM.DD")} 주문
                                        </div>
                                        <div className="no">주문번호 {item?.paymentOrderNo}</div>
                                    </div>
                                    <div>
                                        {item?.localList?.length > 0 && (
                                            <div className="box sub">
                                                <h4>한국 배송 상품</h4>
                                                {item?.localList?.map((sItem, sIndex) => (
                                                    <ProductItem
                                                        key={sIndex}
                                                        goDetail={(e) => goDetail(e)}
                                                        {...sItem}
                                                        productInfo={sItem}
                                                        orderStatus={item.orderStatus}
                                                        history={history}
                                                        refresh={onRefresh}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                        {item?.overseasList?.length > 0 && (
                                            <div className="box sub">
                                                <h4>해외 배송 상품</h4>
                                                {item?.overseasList?.map((sItem, sIndex) => (
                                                    <ProductItem
                                                        key={sIndex}
                                                        goDetail={(e) => goDetail(e)}
                                                        {...sItem}
                                                        productInfo={sItem}
                                                        orderStatus={item.orderStatus}
                                                        history={history}
                                                        refresh={onRefresh}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {paging && (
                                <div className="paging">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </OLWrapper>
                    </ContentWrapper>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding-bottom: 40px;
    }
`;

const ContentWrapper = styled.div`
    margin-left: 30px;
    padding-top: 10px;
    flex: 1;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-left: 0;
        padding: 0 21px;
    }
`;

const OLWrapper = styled.div`
    div.top {
        position: relative;
        display: flex;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: 1px solid #3d3d3d;
            margin-top: 24px;
            padding-bottom: 16px;
            display: none;
        }
    }

    div.empty {
        text-align: center;
        padding: 30px 0;
        font-size: 14px;
        color: #777777;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }

    div.top > h2 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
    }

    div.paging {
        margin-top: 20px;
    }

    div.box {
        /* padding: 20px; */
        /* margin-top: 37px; */
        border-bottom: 1px solid #dfdfdf;
        padding: 37px 0;

        h4 {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #3d3d3d;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            margin: 0px;

            :last-child {
                padding-top: 24px;
            }
        }

        div.order-date-no {
            display: block;
            position: relative;

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                display: flex;
                flex-wrap: wrap;
            }

            > div {
                display: inline-block;
                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    flex: 1;
                    flex-basis: auto;
                }
            }

            > div.date {
                font-family: ${(props) => props.theme.fontFamily.medium};
                color: #3d3d3d;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
            }

            > div.no {
                font-family: ${(props) => props.theme.fontFamily.light};
                color: #acacac;
                font-size: 16px;
                line-height: 24px;
                margin-left: 22px;

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    font-size: 14px;
                    margin-left: 0;
                    text-align: end;
                }
            }

            > div.status {
                font-family: ${(props) => props.theme.fontFamily.bold};
                color: #3d3d3d;
                font-size: 16px;
                line-height: 24px;
                margin-left: 22px;
                cursor: pointer;
            }

            > .buttons {
                position: absolute;
                right: 0px;
                top: -10px;

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    top: unset;
                    position: relative;
                    text-align: right;
                    display: block;
                    margin-top: 15px;
                    flex-basis: 100%;
                }

                > button {
                    border: 1px solid #777777;
                    background: #ffffff;
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    color: #777777;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    padding: 8px 15px;
                    margin-left: 8px;
                    :first-child {
                        margin-left: 0px;
                    }
                }

                button.on {
                    border: 1px solid #e32222;
                    color: #ffffff;
                    background: #e32222;
                }
            }
        }
    }

    div.box.sub {
        margin-top: 15px;
        border: 1px solid #dfdfdf;
        padding: 20px;
    }
`;

const ProductItem = (props) => {
    const { history, goDetail } = props;

    return (
        <PIWrapper>
            <img src={props.productFileSignedUrl} onClick={() => history.push(`/product/${props.productCode}`)} />
            <div className="info" onClick={() => history.push(`/product/${props.productCode}`)}>
                <div>
                    <div className="brand">{props.productBrandName}</div>
                    <h4 className="title">{props.productName}</h4>
                    <div className="option">
                        {props.productOptItemList?.map((item, index) => (
                            <div key={index}>
                                {item.productOptionName} : {item.productOptionItemName} / 수량 {item.quantity}개
                            </div>
                        ))}
                    </div>
                    <div className="price">{_U.numberFormat(props.totalAmountPayment)} 원</div>
                </div>
            </div>
            <div className="buttons">
                {props.productInfo?.returnInfo?.returnStatus ? (
                    <button onClick={() => goDetail()}>{_DEF.getDefinedLabel(_DEF.RETURN_STATUS, props.productInfo?.returnInfo?.returnStatus)}</button>
                ) : (
                    <button onClick={() => goDetail(props.productInfo)}>반품신청</button>
                )}
            </div>
        </PIWrapper>
    );
};

const PIWrapper = styled.div`
    border-bottom: 1px solid #efefef;
    display: flex;
    padding: 0 0 35px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        flex-wrap: wrap;
    }
    :last-child {
        border-bottom: 0px;
        padding-bottom: 0px;
    }

    > img {
        flex-basis: 90px;
        width: 90px;
        height: 90px;
        object-fit: cover;
    }

    > div.info {
        flex: 1;
        padding: 0 16px;
        display: flex;
        align-items: center;

        div.brand {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #3d3d3d;
            font-size: 13px;
            line-height: 19px;
        }

        h4.title {
            margin: 0px;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
        }

        div.option {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #b8b8b8;
            font-size: 13px;
            line-height: 19px;
        }

        div.price {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #3d3d3d;
            font-size: 14px;
            line-height: 20px;
            margin-top: 8px;

            span {
                font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            }
        }
    }

    > div.buttons {
        flex-basis: 176px;
        padding: 0 44px;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex-direction: row;
            padding: 0;
        }
        :before {
            position: absolute;
            width: 1px;
            height: 70%;
            background: #efefef;
            left: 0;
            top: 15%;
            content: "";
        }
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            flex-basis: 100%;
            margin-top: 24px;
            :before {
                content: unset;
            }
        }
        button {
            border: 1px solid #777777;
            background: #ffffff;
            text-align: center;
            padding: 10px 0;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #777777;
            font-size: 14px;
            line-height: 20px;
            width: 100%;
            margin-bottom: 8px;
            :last-child {
                margin-bottom: 8px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                flex: 1;
                margin-right: 5px;
            }
        }

        button.on {
            border: 1px solid #e32222;
            color: #e32222;
        }

        button.active {
            border: 1px solid #e32222;
            background-color: #e32222;
            color: #ffffff;
        }
    }
`;

const ShippingStatus = (props) => {
    const [data, setData] = useState(null);

    const loadData = () => {
        _API.get({ path: "/user/mypage/order/shipping", data: { ...data, numPerPage: 5 } }).then((res) => {
            setData(res.data);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <SSWrapper>
            <div className="box">
                <ul>
                    <li className={cx({ active: data?.payWait > 0 })}>
                        입금대기
                        <div className="round">{data?.payWait}</div>
                    </li>
                    <li className={cx({ active: data?.productWait > 0 })}>
                        상품준비중
                        <div className="round">{data?.productWait}</div>
                    </li>
                    <li className={cx({ active: data?.shippingIng > 0 })}>
                        배송중
                        <div className="round">{data?.shippingIng}</div>
                    </li>
                    <li className={cx({ active: data?.shippingComplete > 0 })}>
                        배송완료
                        <div className="round">{data?.shippingComplete}</div>
                    </li>
                    <li className={cx({ active: data?.orderComplete > 0 })}>
                        주문확정
                        <div className="round">{data?.orderComplete}</div>
                    </li>
                </ul>
                <div className="etcs">
                    <div className="etc-item">
                        · 취소
                        <span>{data?.orderCancel} 건</span>
                    </div>
                    <div className="etc-item">
                        · 반품요청
                        <span>{data?.refundRequest} 건</span>
                    </div>
                    <div className="etc-item">
                        · 반품완료
                        <span>{data?.refundComplete} 건</span>
                    </div>
                </div>
            </div>
        </SSWrapper>
    );
};

const SSWrapper = styled.div`
    margin-top: 24px;
    div.box {
        border: 1px solid #efefef;
        padding: 24px 26px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: calc(100% - 32px);
            padding: 24px 16px;
        }

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            justify-content: center;
        }

        ul > li {
            flex: 1;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            color: #777777;
            font-family: ${(props) => props.theme.fontFamily.medium};
            font-size: 13px;
            line-height: 17px;

            /* :before {
				content: "";
				position: absolute;
				background-image: url("/icons/ic_shipping_off.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_shipping_off.png", "/icons/ic_shipping_off@2x.png", "/icons/ic_shipping_off@3x.png"])});
				background-repeat: no-repeat;
				background-size: 16px 11px;
				width: 16px;
				height: 11px;
				left: -10px;
				top: 53px;
			} */

            :first-child:before {
                content: unset;
            }

            div.round {
                background: #e7e7e7;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-top: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 20px;
                font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    width: 40px;
                    height: 40px;
                    font-size: 16px;
                }
            }
        }

        ul > li.active {
            color: #3d3d3d;

            /* :before {
				background-image: url("/icons/ic_shipping_on.png");
				background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_shipping_on.png", "/icons/ic_shipping_on@2x.png", "/icons/ic_shipping_on@3x.png"])});
			} */

            div.round {
                background: #777777;
            }
        }

        div.etcs {
            border-top: 1px solid #efefef;
            margin-top: 30px;
            padding-top: 16px;
            display: flex;

            div.etc-item {
                flex: 1;
                color: #777777;
                font-family: ${(props) => props.theme.fontFamily.regular};
                font-size: 13px;
                line-height: 18px;
                padding: 0 16px;
                position: relative;

                :after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 10px;
                    background: #b8b8b8;
                    left: 0;
                    top: 5px;
                }

                :first-child {
                    padding: 0 16px 0 0;
                    :after {
                        content: unset;
                    }
                }

                :last-child {
                    padding: 0 0 0 16px;
                }

                span {
                    float: right;
                    font-family: ${(props) => props.theme.fontFamily.medium};
                    color: #b8b8b8;
                }
            }
        }
    }
`;

const Refund = (props) => {
    const { reject, resolve } = props;
    const [data, setData] = useState(null);

    const onSave = () => {
        if (!data?.reason) {
            swal({ title: "알림", text: "환불 사유를 입력해주세요." });
            return;
        }
        if (!data?.bankName) {
            swal({ title: "알림", text: "환불은행을 선택해주세요." });
            return;
        }
        if (!data?.bankAccountNumber) {
            swal({ title: "알림", text: "환불 계좌번호를 입력해주세요." });
            return;
        }
        if (!data?.bankAccountHolder) {
            swal({ title: "알림", text: "예금주를 입력해주세요." });
            return;
        }

        swal({
            title: "알림",
            text: "해당 상품을 환불 요청 하시겠습니까?",
            buttons: ["취소", "확인"],
        }).then((willDelete) => {
            if (willDelete) {
                const req = { path: "/user/mypage/order/refund", data: { ...data, orderIdx: props.orderIdx, orderShippingIdx: props.lastOrderShipping.idx } };
                _API.post(req).then((res) => {
                    resolve();
                });
            }
        });
    };

    return (
        <RWrapper>
            <RefundItem {...props} />
            <div className="form">
                <UFormWrapper styles={"margin-bottom:0px;"} title={"사유"} labelStyles={"padding:14px 0;"} formStyles={"padding:14px 0;"}>
                    <Textarea value={data?.reason} onChange={(e) => setData({ ...data, reason: e.target.value })} placeholder="환불 사유를 작성해주세요. 모아 운영에 큰 힘이 됩니다." />
                </UFormWrapper>
                <UFormWrapper styles={"margin-bottom:0px;"} title={"환불은행"} labelStyles={"padding:24px 0;"} formStyles={"padding:14px 0;"}>
                    <SelectBox items={_DEF.BANK_LIST} placeholder={"환불받을 은행을 선택하세요."} onChange={(e) => setData({ ...data, bankName: e.target.value })} value={data?.bankName} />
                </UFormWrapper>
                <UInputForm title="환불 계좌번호" value={data?.bankAccountNumber} onChange={(e) => setData({ ...data, bankAccountNumber: e.target.value })} />
                <UInputForm title="예금주" value={data?.bankAccountHolder} onChange={(e) => setData({ ...data, bankAccountHolder: e.target.value })} />
                <div className="buttons">
                    <button onClick={() => reject()}>닫기</button>
                    <button className="action" onClick={onSave}>
                        저장
                    </button>
                </div>
            </div>
        </RWrapper>
    );
};

const RWrapper = styled.div`
    div.buttons {
        margin-top: 30px;
        text-align: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            display: flex;
            margin-top: 0px;
            left: 0;
        }
        button {
            border: 1px solid #e32222;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #e32222;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }
            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                flex: 1;
                width: 100%;
                margin-left: 0px !important;
            }
        }

        button.action {
            border: 1px solid #e32222;
            background: #e32222;
            color: #ffffff;
        }
    }
`;

const RefundItem = (props) => {
    return (
        <RIWrapper>
            <img src={props.productFileSignedUrl} />
            <div className="info">
                <div>
                    <div className="brand">{props.productBrandName}</div>
                    <h4 className="title">{props.productName}</h4>
                    <div className="option">
                        {props.productOptItemList?.map((item, index) => (
                            <div key={index}>
                                {item.productOptionName} : {item.productOptionItemName} / 수량 {item.quantity}개
                            </div>
                        ))}
                    </div>
                    <div className="price">{_U.numberFormat(props.totalAmountPayment)} 원</div>
                </div>
            </div>
        </RIWrapper>
    );
};

const RIWrapper = styled.div`
    border-bottom: 1px solid #efefef;
    display: flex;
    padding: 16px 0 35px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding-top: 32px;
    }

    :last-child {
        border-bottom: 0px;
        padding-bottom: 0px;
    }

    > img {
        flex-basis: 90px;
        width: 90px;
        height: 90px;
        object-fit: cover;
    }

    > div.info {
        flex: 1;
        padding: 0 16px;
        display: flex;
        align-items: center;

        > div {
            flex: 1;
        }

        > div.star {
            display: flex;
            justify-content: flex-end;

            img {
                cursor: pointer;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        div.brand {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #b8b8b8;
            font-size: 13px;
            line-height: 19px;
        }

        h4.title {
            margin: 0px;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #3d3d3d;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
        }

        div.option {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #b8b8b8;
            font-size: 13px;
            line-height: 19px;
        }

        div.price {
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #3d3d3d;
            font-size: 14px;
            line-height: 20px;
            margin-top: 8px;
        }
    }
`;

const Search = (props) => {
    const { onSearch, startDate, setStartDate, endDate, setEndDate, month, setMonth } = props;

    const onClickMonth = (month) => {
        setStartDate(moment().add(-month, "month").toDate());
        setMonth(month);
    };
    return (
        <SWrapper>
            <span>조회기간</span>
            <ul>
                <li className={cx({ active: month === 1 })} onClick={() => onClickMonth(1)}>
                    1개월
                </li>
                <li className={cx({ active: month === 3 })} onClick={() => onClickMonth(3)}>
                    3개월
                </li>
                <li className={cx({ active: month === 6 })} onClick={() => onClickMonth(6)}>
                    6개월
                </li>
                <li className={cx({ active: month === 12 })} onClick={() => onClickMonth(12)}>
                    12개월
                </li>
            </ul>
            <div>
                <Date value={startDate} styles={"margin:0;"} onChange={(date) => setStartDate(moment(date))} maxDate={moment().toDate()} />
                <div className="dash">~</div>
                <Date value={endDate} styles={"margin:0;"} onChange={(date) => setEndDate(moment(date))} maxDate={moment().toDate()} />
                <div className="button" onClick={() => onSearch()}>
                    <button className="btn-search">조회</button>
                </div>
            </div>
        </SWrapper>
    );
};

const SWrapper = styled.div`
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        flex-direction: column;
    }
    > span {
        flex-basis: 80px;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #777777;
        font-size: 13px;
        line-height: 18px;
        display: flex;
        align-items: center;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }

    > ul {
        flex: 1;
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        align-items: center;

        > li {
            flex: 1;
            border: 1px solid #dedede;
            color: #f8f8f8;
            text-align: center;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #acacac;
            font-size: 14px;
            line-height: 20px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 0;
            cursor: pointer;

            :last-child {
                border-right: 1px solid #dedede;
            }
        }

        > li.active {
            border: 1px solid #e32222;
            color: #e32222;
        }
    }

    > div {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 30px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-left: 0;
            margin-top: 10px;
        }

        > div {
            flex: 1;
            display: flex;
            justify-content: center;
        }

        > div.dash {
            flex-basis: 20px;
        }

        > div.button {
            flex-basis: 80px;
            height: 42px;
            margin-left: 5px;
            > button.btn-search {
                border: 1px solid #e32222;
                background: #e32222;
                color: #ffffff;
                width: 100%;
                height: 100%;
            }
        }
    }
`;
