/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import qs from "query-string";

import { AppContext } from "../../Components/AppProvider";
import { _API, _U, _CK } from "../../Modules/Utils";
import { _CFG } from "../../Modules/Config";

export default (props) => {
	const { location, history, match } = props;
	const { auth, saveAuth, saveIam, saveSnsProfile } = useContext(AppContext);
	const { device, version, appversion, lang } = useContext(AppContext);

	const parseText = (text) => {
		return JSON.parse(decodeURIComponent(text));
	};

	const snsLoginCheck = (data) => {
		const { id, accessToken: token, snsType: type, profile } = data;
		let parsedQs = null;
		if (location.search) {
			parsedQs = qs.parse(location.search);
			console.log(parsedQs);
		}

		_API
			.post({
				path: "/user/login/sns",
				data: {
					type,
					id,
					token,
					profile,
				},
			})
			.then((res) => {
				const { data: resData } = res;
				if (!resData) {
					saveSnsProfile(data);
					history.push("/user/signup");
				} else {
					const { accessKey, data: iam } = resData;
					_CK.setAuth(accessKey);
					saveAuth(accessKey);
					saveIam(iam);

					_CK.set("device", device);
					_CK.set("version", version);
					_CK.set("appversion", appversion);
					_CK.set("lang", lang);
					if (device && device !== "null") {
						document.location.href = `${_CFG.appSchema}logined?auth=${accessKey}&encoded=${encodeURIComponent(accessKey)}`;
					} else {
						if (parsedQs && parsedQs?.url) {
							history.push(parsedQs?.url.replace("#", ""));
						} else {
							history.push("/");
						}
					}
				}
			});
	};

	useEffect(() => {
		const ck = _CK.get("NAVER-LOGIN-PROFILE");
		if (!ck) {
			return history.push("/");
		}

		const profile = parseText(ck);
		profile.snsType = "NAVER";
		snsLoginCheck(profile);
		_CK.set("NAVER-LOGIN-PROFILE", "");
	}, []);

	return (
		<div>
			<div></div>
		</div>
	);
};
