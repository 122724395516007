/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ImageMagnify from "react-image-magnify";
import cx from "classnames";
import VideoJs from "video.js";
import swal from "sweetalert";
import { isMobile, isIOS } from "react-device-detect";
// import Slider from "react-slick";
import Slider from "react-slick-ios-fix";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AppContext } from "../../Components/AppProvider";

import { _DEF } from "../../Modules/Config";
import { _U, _API, _CK } from "../../Modules/Utils";
import Dialog from "../../UI/Dialog";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import Login from "../Layout.Login";

import SizeGuide from "./View.info.Guide";

export default (props) => {
    const { match, auth, saveAuth, saveIam } = useContext(AppContext);
    const { media, brand, detail, options } = props;
    const [tempOptions, setTempOptions] = useState(null);
    const [likeCnt, setLikeCnt] = useState(0);

    const changeOptions = (items) => {
        setTempOptions(items);
    };

    const likeAction = () => {
        if (!auth) {
            _U.postMessage("LOGIN");

            return;
        }

        const params = {};
        params.productCode = detail.code;
        params.productOptionItemList = tempOptions;
        if (!params.productCode) {
            swal({ title: "알림", text: "상품정보를 찾을 수 없습니다." });
            return;
        }

        _API.post({ path: "/user/payment/product/like/save", data: params })
            .then((res) => res.data)
            .then((data) => {
                const { code, message } = data;
                swal({ title: "알림", text: message });
                setLikeCnt(code);
            });
    };
    useEffect(() => {
        if (detail) {
            setLikeCnt(detail?.likeCnt || 0);
        }
    }, [detail]);

    return (
        <Wrapper>
            <Photos media={media} />
            <InfoWrapper isIOS={isIOS} isCoupon={detail.isCoupon === "Y"}>
                <button className="btn-favorite" onClick={likeAction}>
                    {likeCnt > 0 ? (
                        <img src="/icons/ic_heart_circle_on.png" srcSet={getSrcSet(["/icons/ic_heart_circle_on.png", "/icons/ic_heart_circle_on@2x.png", "/icons/ic_heart_circle_on@3x.png"])} />
                    ) : (
                        <img src="/icons/ic_heart_circle.png" srcSet={getSrcSet(["/icons/ic_heart_circle.png", "/icons/ic_heart_circle@2x.png", "/icons/ic_heart_circle@3x.png"])} />
                    )}
                </button>

                <IBrand brand={brand} />

                <h2 className="title">{detail?.name}</h2>
                <IPrice detail={detail} />
                {/* <ICoupon detail={detail} /> */}
                <hr />
                <IShipping detail={detail} />
                <hr />
                <Options detail={detail} data={options} changeOptions={changeOptions} {...props} />
            </InfoWrapper>
        </Wrapper>
    );
};

export const Options = (props) => {
    const { categories } = props;
    let auth = "";

    if (props.isCart) {
        auth = props.auth;
    } else {
        auth = useContext(AppContext).auth;
    }

    const { data, detail, location, history, match } = props;

    const [options, setOptions] = useState([]);
    const [isAddCart, setIsAddCart] = useState(false);

    /** @임시코드
     *  @desc: 재고 상황으로 인한 추가 구매 Block
     *  */
    // START
    const [sameProductCount, setSameProductCount] = useState(0);
    const loadData = () => {
        if (!auth) {
            return;
        }
        _API.get({ path: "/user/payment/product/cart" })
            .then((res) => res.data)
            .then((result) => {
                const { overseasList, localList, localSummary, overseasSummary, totalSummary } = result;
                setSameProductCount(result?.list?.filter((i) => i.productCode == detail?.code).length > 0 ? result?.list?.filter((i) => i.productCode == detail.code).length : 0);
            });
    };
    // END --

    useEffect(() => {
        /** @임시코드
         *  @desc: 재고 상황으로 인한 추가 구매 Block
         *  */
        // START
        loadData();
        // END --
        if (props.isCart && props.options && props.options.length > 0) {
            setOptions(props.options);
        }
    }, [props.isCart, props.options]);

    const handleOptionChange = (i, e) => {
        const option = data[i];
        const optionItem = option.items.filter((t) => t.idx === parseInt(e.target.value))[0];

        if (optionItem) {
            if (optionItem.quantity === 0) {
                return;
            }

            const f = options.filter((o) => o.idx === optionItem.idx);
            if (f.length > 0) {
                handleSizeChange(i, "PLUS");
                return;
            }

            const o = {
                ...optionItem,
                code: detail.code,
                optionIdx: option.idx,
                title: `${detail.name} - ${optionItem.name}`,
                itemAmount: detail.priceSale + optionItem.amount,
                size: 1,
            };
            if (option.type !== "COMBINATION") {
                o.title = optionItem.name;
                o.itemAmount = optionItem.amount;
            }

            setOptions((old) => [...old, o]);
        }
    };

    const findOptionItem = (optionIdx, itemIdx) => {
        const of = data.filter((d) => d.idx === optionIdx);
        if (of.length === 0) {
            return null;
        }

        const oif = of[0].items.filter((d) => d.idx === itemIdx);
        if (oif.length === 0) {
            return null;
        }

        return oif[0];
    };

    const handleSizeChange = (index, mode) => {
        const old = [...options];
        switch (mode) {
            case "PLUS":
                /** @임시코드
				 *  @desc: 재고 상황으로 인한 추가 구매 Block
				 * 
				// START
				swal({ title: "알림", text: "이미 장바구니에 추가된 상품입니다.", button: "확인" });
				return;
				// END --
				 */

                old[index].size += 1;
                break;
            case "MINUS":
                old[index].size -= 1;
                break;
            default:
                old[index].size = parseInt(mode);
                break;
        }

        const dest = [];
        old.map((o) => {
            const ori = findOptionItem(o.optionIdx, o.idx);
            if (o.size > ori.quantity) {
                o.size = ori.quantity;
            }
            if (o.size > 0) {
                dest.push(o);
            }
        });
        setOptions(dest);
    };

    const handleRemoveOptionClick = (index) => {
        const dest = [];
        options.map((item, i) => {
            if (i !== index) {
                dest.push(item);
            }
        });
        setOptions(dest);
    };

    const handleToCartClick = () => {
        if (!auth) {
            //로그인 팝업
            LoginDialog();
            return;
        }

        if (options.length === 0) {
            swal({ title: "알림", text: "상품 옵션을 선택해 주세요.", button: "확인" });
            return;
        }

        let ORIGIN_URL = `/user/payment/product/cart/save`;

        /** @임시코드
         *  @desc: 재고 상황으로 인한 추가 구매 Block
         *  */
        // START
        if (sameProductCount > 0) {
            swal({ title: "알림", text: "이미 장바구니에 추가된 상품입니다.", button: "확인" });
            return;
        }
        ORIGIN_URL = `/user/payment/product/cart-imsi/save`;
        // END --

        const data = { productCode: detail.code, productOptionItemList: options, checkYn: "Y" };
        _API.post({ path: ORIGIN_URL, data }).then((res) => {
            const { code, message } = res.data;
            setIsAddCart(true);
            setTimeout(() => {
                setIsAddCart(false);
            }, 3000);
        });
    };

    const handleToBuyClick = () => {
        if (options.length === 0) {
            swal({ title: "알림", text: "상품 옵션을 선택해 주세요.", button: "확인" });
            return;
        }

        let data = { productCode: detail.code, productOptionItemList: options, checkYn: "Y" };

        if (!auth) {
            //로그인 팝업
            LoginDialog(data);
            return;
        }

        let ORIGIN_URL = `/user/payment/product/cart/save`;

        /** @임시코드
         *  @desc: 재고 상황으로 인한 추가 구매 Block
         *  */
        // START
        ORIGIN_URL = `/user/payment/product/cart-imsi/save`;
        // END --

        _API.post({ path: ORIGIN_URL, data }).then((res) => {
            const { code } = res.data;
            if (code === 0 || code === 1) {
                data = { productCode: detail.code, productOptionItemList: options };
                _API.post({ path: "/user/payment/product/order/save", data }).then((res) => {
                    const { orderIdx } = res.data;
                    history.push(`/checkout/${orderIdx}`);
                });
            }
        });
    };

    let totalAmount = 0;
    options.map((item) => {
        totalAmount += item.itemAmount * item.size;
    });

    useEffect(() => {
        props.changeOptions && props.changeOptions(options);
    }, [options]);

    const LoginDialog = (param) => {
        _U.postMessage("LOGIN", param);
    };

    const onShowSizeGuide = () => {
        Dialog({
            title: ``,
            body: <SizeGuide {...{ categories }} />,
            width: 1050,
        })
            .then((data) => {
                loadData(1);
            })
            .catch((e) => {
                if (e && e.url) {
                    history.push(e.url);
                }
            });
    };

    useEffect(() => {
        if (detail.isCoupon === "Y" && data.length > 0 && options.length === 0) {
            handleOptionChange(0, { target: { value: data[0].idx } });
        }
    }, []);

    return (
        <Fragment>
            <section className="option-info">
                <h4>
                    주문선택사항
                    {categories && categories.length > 0 && <>{_U.isSizeGuide(categories) && <button onClick={() => onShowSizeGuide()}>Size Guide</button>}</>}
                </h4>
                {detail.isCoupon !== "Y" &&
                    data.map((option, i) => (
                        <DropDown key={i}>
                            <div className="label">
                                <h5>{option.name}</h5>
                            </div>
                            <div className="option">
                                <select value="" onChange={handleOptionChange.bind(this, i)}>
                                    <option value="">옵션을 선택해 주세요.</option>
                                    {option.items.map((item, j) => (
                                        <option key={`OPTION-ITEM-${i}-${j}`} value={item.idx}>
                                            {item.name} {item.amount !== 0 && `(${item.amount > 0 ? "+" : ""}${_U.addComma(item.amount)})`} -{" "}
                                            {item.quantity !== 0 ? `${_U.addComma(item.quantity)}개 남음` : "품절"}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </DropDown>
                    ))}

                {options.map((o, i) => (
                    <div key={i} className="select-option">
                        <p>{o.title}</p>

                        <div className="option-detail">
                            {/* {detail.isCoupon === "Y" ? (
                                <div className="count-box">{o.size} 개</div>
                            ) : (
                                <div className="count-box">
                                    <button onClick={handleSizeChange.bind(this, i, "MINUS")}>
                                        <img src="/icons/ic_minus.png" srcSet={getSrcSet(["/icons/ic_minus.png", "/icons/ic_minus@2x.png", "/icons/ic_minus@3x.png"])} />
                                    </button>
                                    <input type="number" value={o.size} onChange={(e) => handleSizeChange(i, e.target.value)} />
                                    <button onClick={handleSizeChange.bind(this, i, "PLUS")}>
                                        <img src="/icons/ic_plus.png" srcSet={getSrcSet(["/icons/ic_plus.png", "/icons/ic_plus@2x.png", "/icons/ic_plus@3x.png"])} />
                                    </button>
                                </div>
                            )} */}

                            <div className="count-box">
                                <button onClick={handleSizeChange.bind(this, i, "MINUS")}>
                                    <img src="/icons/ic_minus.png" srcSet={getSrcSet(["/icons/ic_minus.png", "/icons/ic_minus@2x.png", "/icons/ic_minus@3x.png"])} />
                                </button>
                                <input type="number" value={o.size} onChange={(e) => handleSizeChange(i, e.target.value)} />
                                <button onClick={handleSizeChange.bind(this, i, "PLUS")}>
                                    <img src="/icons/ic_plus.png" srcSet={getSrcSet(["/icons/ic_plus.png", "/icons/ic_plus@2x.png", "/icons/ic_plus@3x.png"])} />
                                </button>
                            </div>

                            <div className="price">
                                {_U.addComma(o.itemAmount * o.size)}
                                <span>원</span>
                            </div>
                        </div>

                        {detail.isCoupon !== "Y" && (
                            <button className="option-remove" onClick={handleRemoveOptionClick.bind(this, i)}>
                                <img src="/icons/ic_remove_circle.png" srcSet={getSrcSet(["/icons/ic_remove_circle.png", "/icons/ic_remove_circle@2x.png", "/icons/ic_remove_circle@3x.png"])} />
                            </button>
                        )}
                    </div>
                ))}
            </section>
            <section className="final">
                <div className="total-price">
                    <small>총 상품 금액</small>
                    <b>
                        {_U.addComma(totalAmount)}
                        <span>원</span>
                    </b>
                </div>
                {!props.isCart && (
                    <div className="buttons">
                        <div className={cx({ "add-cart-box": true, hidden: !isAddCart })}>
                            <img
                                src={"/icons/ic_remove.png"}
                                srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])}
                                className="close"
                                onClick={() => setIsAddCart(false)}
                            />
                            <div>
                                <p>상품이 장바구니에 담겼습니다.</p>
                                <button className="go-cart" onClick={() => history.push("/cart")}>
                                    장바구니 바로가기
                                    <img
                                        src={"/icons/ic_arrow_right_red.png"}
                                        srcSet={getSrcSet(["/icons/ic_arrow_right_red.png", "/icons/ic_arrow_right_red@2x.png", "/icons/ic_arrow_right_red@3x.png"])}
                                    />
                                </button>
                            </div>
                        </div>
                        {detail.isCoupon === "Y" ? (
                            <Fragment>
                                <button className="buy" onClick={handleToBuyClick}>
                                    선물(구매)하기
                                </button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <button className="cart" onClick={handleToCartClick}>
                                    장바구니 담기
                                </button>
                                <button className="buy" onClick={handleToBuyClick}>
                                    바로 구매하기
                                </button>
                            </Fragment>
                        )}
                    </div>
                )}
            </section>
        </Fragment>
    );
};

const IBrand = ({ brand }) => {
    return (
        <div className="brand">
            {brand?.map((item, i) => (
                <span key={i}>{item.nameEng} </span>
            ))}
        </div>
    );
};

const IPrice = ({ detail }) => {
    return (
        <section className="price-info">
            <div className="original-price">
                {detail?.priceDiscountPercent > 0 && (
                    <>
                        {_U.addComma(detail?.priceNormal)}
                        <span>원</span>
                    </>
                )}
            </div>
            {/* <div className="discount-price">
                        9,050,000<span>원</span> <small>관부과세 포함</small>
                    </div> */}
            <div className="real-price">
                {_U.addComma(detail?.priceSale)}
                <span>원</span>
                {detail?.priceDiscountPercent > 0 && <b>{Math.round(detail?.priceDiscountPercent)}%</b>}
                {/* <small>쿠폰적용가</small> */}
                {/* <button className="btn-coupon">쿠폰받기</button> */}
            </div>
        </section>
    );
};

const ICoupon = ({ detail }) => {
    return (
        <section className="coupon-info">
            <dl>
                <dt>쿠폰</dt>
                <dd>최대 30,000원 할인가능</dd>
            </dl>
            <dl>
                <dt>F 머니</dt>
                <dd>최대 181,000원</dd>
            </dl>
            <dl className="gray">
                <dt>안내사항</dt>
                <dd>
                    <p>[안내] 상품 판매 신청만 해도 1만원 지급</p>
                    <p>[안내] 2021년 9월 무이자 카드할부 안내</p>
                </dd>
            </dl>
        </section>
    );
};

const IShipping = ({ detail }) => {
    return (
        <section className="company-info">
            {detail.isCoupon === "Y" ? (
                <dl>
                    <dt>발송정보</dt>
                    <dd>
                        모바일 발송.
                        <br />
                        예약발송 불가상품, 대량발송 불가상품.
                    </dd>
                </dl>
            ) : (
                <Fragment>
                    <dl>
                        <dt>배송정보</dt>
                        <dd>
                            {detail?.shippingMethod === "FREE" ? "무료배송" : detail?.shippingAmount ? `${detail?.shippingAmount}원` : ""}
                            <span>{_DEF.getLabel(_DEF.PRODUCT_SHIPPING, detail?.shippingType)}</span>
                        </dd>
                    </dl>
                    {detail?.shippingAmountMsg && (
                        <dl className="gray">
                            <dt>안내사항</dt>
                            <dd>{detail?.shippingAmountMsg}</dd>
                        </dl>
                    )}
                </Fragment>
            )}
        </section>
    );
};

/**
 * Photos
 */
const Photos = (props) => {
    const { media } = props;
    const sliderRef = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const videoNode = useRef(null);
    const mobileVideoNode = useRef([]);
    const player = useRef(null);

    const selectedItem = media.length > 0 ? media[activeIndex] : null;

    useEffect(() => {
        if (selectedItem && selectedItem.fileType === "VIDEO") {
            if (isMobile) {
                player.current = VideoJs(mobileVideoNode.current[activeIndex]);
            } else {
                player.current = VideoJs(videoNode.current);
            }

            if (selectedItem.encoded === "Y") {
                player.current.src({ type: "application/x-mpegURL", src: selectedItem.signedEncodedUrl });
            } else {
                player.current.src({ type: "video/mp4", src: selectedItem.signedUrl });
            }

            player.current.autoplay("muted");
        }
    }, [activeIndex, selectedItem]);

    const sliderSetting = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        customPaging: (i) => <div className={cx({ "slide-paging": true, active: activeIndex === i })}></div>,
    };

    return (
        <PhotosWrapper>
            <div className="main-image">
                {isMobile ? (
                    <Slider
                        ref={sliderRef}
                        {...sliderSetting}
                        afterChange={(index) => {
                            setActiveIndex(index);
                        }}
                    >
                        {media.map((item, i) => (
                            <React.Fragment key={i}>
                                {item.fileType === "PHOTO" && <img src={item.signedUrl} alt="" />}
                                {item.fileType === "VIDEO" && (
                                    <video-js>
                                        <video
                                            ref={(el) => (mobileVideoNode.current[i] = el)}
                                            className="video-js vjs-big-play-centered"
                                            poster={item.signedThumbnailUrl}
                                            controls
                                            preload="auto"
                                            muted={true}
                                            autoPlay={true}
                                            playsInline
                                            width="100%"
                                            height="548"
                                            loop={true}
                                        >
                                            {item.encoded !== "Y" && <source src={item.signedUrl} type="video/mp4" />}
                                        </video>
                                    </video-js>
                                )}
                            </React.Fragment>
                        ))}
                    </Slider>
                ) : (
                    <>
                        {selectedItem && selectedItem.fileType === "PHOTO" && <img src={selectedItem.signedUrl} alt="" />}
                        {/* {selectedItem && selectedItem.fileType === "VIDEO" && selectedItem.encoded !== "Y" && (
							<video controls preload="auto" poster={selectedItem.signedThumbnailUrl} muted={true} autoPlay={true}>
								<source src={selectedItem.signedUrl} type="video/mp4" />
							</video>
						)} */}
                        {selectedItem && selectedItem.fileType === "VIDEO" && (
                            <video-js>
                                <video
                                    ref={videoNode}
                                    className="video-js vjs-big-play-centered"
                                    poster={selectedItem.signedThumbnailUrl}
                                    controls
                                    preload="auto"
                                    muted={true}
                                    autoPlay={true}
                                    playsInline
                                    width="510"
                                    height="510"
                                    loop={true}
                                >
                                    {selectedItem.encoded !== "Y" && <source src={selectedItem.signedUrl} type="video/mp4" />}
                                </video>
                            </video-js>
                        )}
                    </>
                )}
            </div>
            <div className="image-items">
                {media.map((item, i) => (
                    <img
                        key={i}
                        src={item.signedThumbnailPointUrl || item.signedThumbnailUrl || item.signedUrl}
                        onClick={(e) => setActiveIndex(i)}
                        className={cx({ active: activeIndex === i })}
                        alt=""
                    />
                ))}
            </div>
        </PhotosWrapper>
    );
};

/**
 * Styled
 */

const Wrapper = styled.div`
    display: flex;
    margin-top: 31px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-top: 0;
        flex-direction: column;
        position: relative;
    }

    > div {
        flex: 1;
        min-height: 510px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            min-height: 430px;
        }
    }
`;

const PhotosWrapper = styled.div`
    div.main-image {
        width: 510px;
        height: 510px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        video {
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 100%;
            height: 430px;

            img {
                width: 100vw;
                height: 430px;
            }
            video {
                width: 100vw;
                height: 430px;
            }

            .video-js {
                width: 100%;
                height: 430px;
            }

            ul.slick-dots {
                margin-top: 21px;
                bottom: -50px;
                > li {
                    margin: 0;
                }
            }

            div.slide-paging {
                height: 1px;
                border-top: 1px solid #efefef;
            }

            div.slide-paging.active {
                border-top: 1px solid #3d3d3d;
            }

            div.slick-arrow {
                display: none;
            }
        }
    }

    div.image-items {
        width: 100%;
        height: 56px;
        display: inline-block;
        margin-top: 12px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 21px;
            display: none;
        }

        img {
            width: 56px;
            height: 56px;
            border: 2px solid #efefef;
            margin-right: 8px;
            cursor: pointer;
            object-fit: cover;

            :last-child {
                margin-right: 0px;
            }

            @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                margin-right: 4px;
                margin-left: 4px;
            }
        }

        img.active {
            border: 2px solid #232323;
        }
    }
`;

export const InfoWrapper = styled.div`
    padding: 0 30px;
    position: relative;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        position: static;
    }

    .btn-favorite {
        position: absolute;
        right: 21px;
        top: 16px;
        border: none;
        background: none;
        cursor: pointer;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 34px;
            height: 34px;
            padding: 0;
            margin: 0;
        }

        img {
            width: 34px;
            height: 34px;
        }
    }

    hr {
        border: 0;
        border-top: 1px solid #efefef;
        margin: 20px 0;
        display: block;
        height: 1px;
    }

    div.brand {
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-top: 40px;
        }
    }

    div.brand span {
        color: #777777;
        font-size: 14px;
        font-family: ${(props) => props.theme.fontFamily.regular};
        line-height: 20px;
        padding-right: 12px;

        /* background-image: url("/icons/ic_arrow_right.png");
        background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_arrow_right.png", "/icons/ic_arrow_right@2x.png", "/icons/ic_arrow_right@3x.png"])});
        background-repeat: no-repeat;
        background-size: 5px 9px;
        background-position: right center; */
    }

    h2.title {
        color: #3d3d3d;
        font-size: 18px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        line-height: 26px;
        padding: 0px;
        margin: 4px 0 0 0px;
        font-weight: normal;
        width: 75%;
    }

    section.price-info {
        margin-top: 24px;
    }

    section.price-info > .original-price {
        color: #dedede;
        font-size: 18px;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
        line-height: 24px;
        text-decoration: line-through;
        span {
            font-family: ${(props) => props.theme.fontFamily.medium};
        }
    }

    section.price-info > .discount-price {
        color: #777777;
        font-size: 18px;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
        line-height: 24px;

        span {
            font-family: ${(props) => props.theme.fontFamily.medium};
        }

        small {
            font-family: ${(props) => props.theme.fontFamily.light};
            font-size: 13px;
            line-height: 17px;
            vertical-align: text-top;
            color: #acacac;
        }
    }

    section.price-info > .real-price {
        color: #3d3d3d;
        font-size: 22px;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
        line-height: 32px;

        span {
            font-family: ${(props) => props.theme.fontFamily.medium};
        }

        b {
            font-size: 18px;
            color: #e32222;
            line-height: 21px;
            margin-left: 6px;

            background-image: url("/icons/ic_discount.png");
            background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_discount.png", "/icons/ic_discount@2x.png", "/icons/ic_discount@3x.png"])});
            background-repeat: no-repeat;
            background-size: 9px;
            background-position: left center;
            padding-left: 10px;
        }

        small {
            font-family: ${(props) => props.theme.fontFamily.light};
            font-size: 13px;
            line-height: 17px;
            vertical-align: middle;
            color: #acacac;
        }

        button.btn-coupon {
            background: #efefef;
            border: 0px;
            border-radius: 27px;
            padding: 5px 45px 5px 27px;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #e32222;
            font-size: 13px;
            line-height: 19px;
            margin-left: 57px;

            background-image: url("/icons/ic_download.png");
            background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_download.png", "/icons/ic_download@2x.png", "/icons/ic_download@3x.png"])});
            background-repeat: no-repeat;
            background-size: 15px 13px;
            background-position: right 26px center;
        }
    }

    section.coupon-info {
        dl {
            display: flex;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #777777;
            font-size: 13px;
            line-height: 18px;
        }

        dl > dt {
            flex: 1;
            flex-grow: 2;
            margin: 0px;
        }

        dl > dd {
            flex: 1;
            flex-grow: 8;
            margin: 0px;

            p {
                margin: 0px;
            }
        }

        dl.gray > dt,
        dl.gray > dd {
            color: #b8b8b8;
            font-family: ${(props) => props.theme.fontFamily.light};
        }
    }

    section.company-info {
        dl {
            display: flex;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #777777;
            font-size: 13px;
            line-height: 18px;
        }

        dl > dt {
            flex: 1;
            flex-grow: 2;
            margin: 0px;
        }

        dl > dd {
            flex: 1;
            flex-grow: 8;
            margin: 0px;

            span {
                color: #f6773e;
                position: relative;
                margin-left: 8px;
                padding-left: 8px;
                z-index: -1;

                :before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 5px;
                    width: 1px;
                    height: 10px;
                    background: #efefef;
                }
            }

            span.gray {
                color: #b8b8b8;
                font-family: ${(props) => props.theme.fontFamily.light};
            }
        }

        dl.gray > dt,
        dl.gray > dd {
            color: #b8b8b8;
            font-family: ${(props) => props.theme.fontFamily.light};
        }
    }

    section.option-info {
        h4 {
            font-weight: normal;
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 14px;
            color: #3d3d3d;
            margin: 0px;
            line-height: 18px;
            margin-bottom: 8px;

            button {
                border: 1px solid #3d3d3d;
                color: #3d3d3d;
                font-size: 13px;
                line-height: 19px;
                font-family: ${(props) => props.theme.fontFamily.regular};
                padding: 2px 14px;
                background: #ffffff;
                margin-left: 13px;
            }
        }

        select {
            margin-bottom: 8px;
        }

        .select-option {
            border: 1px solid #cfcfcf;
            padding: 16px;
            margin-top: 8px;
            position: relative;
        }

        .select-option > button.option-remove {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 20px;
            height: 20px;
            border: none;
            background: none;
            padding: 0;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .select-option > p {
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 13px;
            color: #3d3d3d;
            margin: 0px;
            line-height: 19px;
            width: 95%;
        }

        .select-option > .option-detail {
            display: flex;
            margin-top: 30px;
        }

        .select-option > .option-detail > div {
            flex: 1;
        }

        .select-option > .option-detail > div.count-box {
            button {
                width: 26px;
                height: 26px;
                border: 1px solid #dedede;
                background: #f5f5f5;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                cursor: pointer;
            }

            input {
                width: 40px;
                height: 24px;
                border: 1px solid #dedede;
                font-family: ${(props) => props.theme.fontFamily.medium};
                font-size: 13px;
                line-height: 13px;
                color: #3d3d3d;
                border-left: 0;
                border-right: 0;
                padding: 0;
                vertical-align: middle;
                text-align: center;

                ::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

        .select-option > .option-detail > div.price {
            text-align: right;
            color: #3d3d3d;
            font-size: 18px;
            font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            line-height: 24px;

            span {
                font-family: ${(props) => props.theme.fontFamily.medium};
            }
        }
    }

    section.final {
        margin-top: 24px;

        div.total-price {
            display: flex;
        }

        div.total-price > small {
            flex: 1;
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 13px;
            line-height: 18px;
            color: #777777;
            display: flex;
            align-items: flex-end;
            margin-bottom: 3px;
        }

        div.total-price > b {
            flex: 1;
            font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            font-size: 22px;
            line-height: 32px;
            color: #e32222;
            text-align: right;
            font-weight: normal;

            span {
                font-family: ${(props) => props.theme.fontFamily.medium};
            }
        }

        div.buttons {
            margin-top: 12px;
            display: flex;
            /* justify-content: space-between; */
            justify-content: ${(props) => (props.isCoupon ? "flex-end" : "space-between")};
            position: relative;

            button {
                flex-basis: calc(50% - 10px);
                width: 100%;
                border: 1px solid #00788d;
                text-align: center;
                padding: 15px 0;
                background: none;
                font-family: ${(props) => props.theme.fontFamily.regular};
                font-size: 14px;
                line-height: 20px;
                color: #00788d;

                @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
                    flex-basis: calc(50% - 3px);
                }
            }

            button.buy {
                background: #00788d;
                color: #ffffff;
            }

            div.add-cart-box {
                position: absolute;
                border: 1px solid #cfcfcf;
                background: #ffffff;
                bottom: 64px;
                left: -23px;
                width: 260px;
                height: 95px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                :after {
                    content: "";
                    position: absolute;
                    border-style: solid;
                    border-width: 9px 8px 0;
                    border-color: #ffffff transparent;
                    display: block;
                    width: 0;
                    z-index: 1;
                    bottom: -9px;
                    left: 122px;
                }

                :before {
                    content: "";
                    position: absolute;
                    border-style: solid;
                    border-width: 9px 8px 0;
                    border-color: #cfcfcf transparent;
                    display: block;
                    width: 0;
                    z-index: 0;
                    bottom: -10px;
                    left: 122px;
                }

                img.close {
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    width: 8px;
                    height: 8px;
                    cursor: pointer;
                }

                p {
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    font-size: 13px;
                    line-height: 18px;
                    color: #3d3d3d;
                    margin: 0px;
                }

                button.go-cart {
                    margin-top: 8px;
                    border: 1px solid #00788d;
                    color: #00788d;
                    width: auto;
                    padding: 7px 12px;
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    font-size: 12px;
                    line-height: 17px;
                    background: #ffffff;
                    img {
                        width: 6px;
                        height: 10px;
                        margin-left: 5px;
                    }
                }
            }

            div.add-cart-box.hidden {
                display: none;
            }
        }
    }
`;

const DropDown = styled.div`
    display: flex;

    .label {
        width: 90px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
        h5 {
            margin: 9px 0;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #777777;
            font-size: 13px;
        }
    }

    .option {
        flex: 1;
    }

    select {
        width: 100%;
        border: 1px solid #cfcfcf;
        font-family: ${(props) => props.theme.fontFamily.light};
        color: #777777;
        font-size: 13px;
        line-height: 16px;
        padding: 9px 16px;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        background: transparent;
        background-image: url("/icons/ic_selectdown.png");
        background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_selectdown.png", "/icons/ic_selectdown@2x.png", "/icons/ic_selectdown@3x.png"])});
        background-repeat: no-repeat;
        background-size: 10px 6px;
        background-position: right 10px center;
    }
`;
