/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { SelectBox } from "../../UI/Form";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

import Slider from "./View.Slider";

export default (props) => {
	return (
		<Wrapper>
			<h2>판매자소개</h2>
			<div className="info-box">
				<div className="link">
					<img className="grade" src="/icons/ic_grade_1.png" srcSet={getSrcSet(["/icons/ic_grade_1.png", "/icons/ic_grade_1@2x.png", "/icons/ic_grade_1@3x.png"])} />
					<div className="name">샤넬</div>
					<button>
						<img src="/icons/ic_heart_gray.png" srcSet={getSrcSet(["/icons/ic_heart_gray.png", "/icons/ic_heart_gray@2x.png", "/icons/ic_heart_gray@3x.png"])} />
						판매자 찜
					</button>
					<button>
						<img src="/icons/ic_home_gray.png" srcSet={getSrcSet(["/icons/ic_home_gray.png", "/icons/ic_home_gray@2x.png", "/icons/ic_home_gray@3x.png"])} />
						미니샵 바로가기
					</button>
				</div>
				<div className="counts">
					<ul>
						<li>
							<small>판매된 상품</small>
							<p>
								2,726<span>개</span>
							</p>
						</li>
						<li>
							<small>구매자 만족도</small>
							<p>
								86<span>점</span>
							</p>
						</li>
						<li>
							<small>단골회원</small>
							<p>
								526<span>명</span>
							</p>
						</li>
						<li>
							<small>판매자보상각서</small>
							<p>
								<img src="/icons/ic_note.png" srcSet={getSrcSet(["/icons/ic_note.png", "/icons/ic_note@2x.png", "/icons/ic_note@3x.png"])} />
							</p>
						</li>
					</ul>
				</div>
			</div>
			<Slider title="판매자의 다른 상품" more={true} font="16" marginTop="40" onClick={() => {}} onMoreClick={() => {}} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	> h2 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 18px;
		color: #3d3d3d;
		font-weight: normal;
		line-height: 26px;
		margin: 0px;
	}

	.info-box {
		display: flex;
		height: 199px;
		margin-top: 16px;
	}

	.info-box > div.link {
		flex-basis: 129px;
		background: #3a3a3a;
		height: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 0 35px;
	}

	.info-box > div.link > img.grade {
		position: absolute;
		left: 10px;
		top: 10px;
		width: 26px;
		height: 29px;
	}

	.info-box > div.link > div.name {
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #ffffff;
		font-size: 13px;
		line-height: 18px;
	}

	.info-box > div.link > button {
		width: 100%;
		margin-top: 6px;
		border: none;
		background: #ffffff;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #3d3d3d;
		font-size: 13px;
		line-height: 18px;
		text-align: center;
		height: 32px;

		img {
			vertical-align: middle;
			margin: 0 6px 3px 0;
		}
	}

	.info-box > div.counts {
		flex: 1;
		border: 1px solid #efefef;
		border-left: none;

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			width: 100%;
			height: 100%;
		}

		ul > li {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			position: relative;
			flex-direction: column;

			:after {
				content: "";
				position: absolute;
				width: 1px;
				height: 80px;
				background: #efefef;
				top: 60px;
				right: 0;
			}

			:last-child:after {
				content: unset;
			}
		}

		ul > li > small {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 13px;
			line-height: 18px;
			display: block;
		}

		ul > li > p {
			font-family: ${(props) => props.theme.fontFamily.nanumEB};
			color: #e32222;
			font-size: 22px;
			line-height: 34px;
			display: block;
			margin: 16px 0 0;

			span {
				font-family: ${(props) => props.theme.fontFamily.medium};
				font-size: 16px;
			}
		}
	}
`;
