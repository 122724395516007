/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import moment from "moment";
// import Slider from "react-slick";
import Slider from "react-slick-ios-fix";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";

import { _API, _U, _CK } from "../../Modules/Utils";
import Layout, { Container } from "../Layout";
import { Textarea, UFormWrapper } from "../../UI/Form";
import Paging, { ToPage, GetParams } from "../../UI/Paging";

import Menu from "./Mypage.Menu";
import { _DEF } from "../../Modules/Config";
import { getSrcSet, getBackgroundSet } from "../../UI/Assets";
import { AppContext } from "../../Components/AppProvider";

export default (props) => {
	const { resolve, reject, bbsIdx, isReviewer, reviewPage } = props;
	const [data, setData] = useState(null);
	const [content, setContent] = useState("");
	const [images, setImages] = useState([]);
	const [awsKey, setAwsKey] = useState(null);
	const [star, setStar] = useState(0);
	const [productIdx, setProductIdx] = useState(null);
	const [orderIdx, setOrderIdx] = useState(null);
	const [hideButton, setHideButton] = useState(false);

	const fileRef = useRef(null);
	const sliderRef = useRef(null);

	const loadData = () => {
		if (orderIdx && productIdx) {
			_API.get({ path: "/user/mypage/order/product", data: { orderIdx, productIdx } }).then((res) => {
				const { data } = res.data;
				setData(data);
			});
		} else if (!orderIdx && productIdx) {
			_API.get({ path: "/product/item", data: { productIdx } }).then((res) => {
				setData({ ...res.data, productName: res.data.name });
			});
		}
	};

	useEffect(() => {
		loadData();
	}, [orderIdx, productIdx]);

	useEffect(() => {
		if (props.productIdx) {
			setProductIdx(props.productIdx);
		}
		if (props.orderIdx) {
			setOrderIdx(props.orderIdx);
		}
	}, [props.productIdx, props.orderIdx]);

	const loadBbsData = () => {
		_API.get({ path: "/bbs/item", data: { bbsIdx } }).then((res) => {
			const { content, point, fileList } = res.data;
			setContent(content);
			setStar(point);
			setImages(fileList);
		});
	};

	useEffect(() => {
		if (bbsIdx) {
			loadBbsData();
		}
	}, [bbsIdx]);

	const loadKeys = () => {
		_API
			.loadUploadKeys()
			.then((res) => res.data)
			.then((data) => {
				setAwsKey(data);
			});
	};

	useEffect(() => {
		loadKeys();
	}, []);

	const handleFileChange = (e) => {
		const file = e.target.files[0];

		_API
			.upload({
				aws: awsKey,
				file,
				path: `PRODUCT/REVIEW/${moment().format("YYYY/MM/DD")}`,
			})
			.then((res) => res.data)
			.then((result) => {
				setImages((o) => [...o, { fileIdx: result.id, signedUrl: result.signedUrl }]);
				sliderRef.current.slickGoTo(images.length);
			});
	};

	const CustomNextArrow = (props) => {
		const { className, style, onClick } = props;

		return (
			<div className="sd-paging sd-next">
				<button type="button" onClick={onClick}>
					<img src={"/icons/ic_page_next.png"} srcSet={getSrcSet(["/icons/ic_page_next.png", "/icons/ic_page_next@2x.png", "/icons/ic_page_next@3x.png"])} alt="" />
				</button>
			</div>
		);
	};

	const CustomPrevArrow = (props) => {
		const { className, style, onClick } = props;

		return (
			<div className="sd-paging sd-prev" onClick={onClick}>
				<button type="button">
					<img src={"/icons/ic_page_prev.png"} srcSet={getSrcSet(["/icons/ic_page_prev.png", "/icons/ic_page_prev@2x.png", "/icons/ic_page_prev@3x.png"])} alt="" />
				</button>
			</div>
		);
	};

	const onRemoveImage = (index) => {
		const tmp = JSON.parse(JSON.stringify(images));
		tmp.splice(index, 1);
		setImages(tmp);
	};

	const sliderSetting = {
		dots: isMobile,
		arrows: !isMobile,
		infinite: !isMobile,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
	};

	const onSave = () => {
		if (!productIdx) {
			swal({ title: "알림", text: "선택된 상품이 없습니다." });
			return;
		}

		if (!star) {
			swal({ title: "알림", text: "별점을 선택 해주세요." });
			return;
		}

		if (!content) {
			swal({ title: "알림", text: "후기 내용을 입력 해주세요." });
			return;
		}

		if (reviewPage === "Y" && !images.length) {
			swal({ title: "알림", text: "소셜 리뷰는 사진을 하나 이상 첨부하셔야 합니다." });
			return;
		}

		_API
			.post({ path: "/bbs/save", data: { bbsCode: "REVIEW", title: `${data.productName} 구매 후기`, content, orderIdx, productIdx, point: star, fileList: images, bbsIdx } })
			.then((res) => {
				swal({ title: "알림", text: bbsIdx ? `리뷰 수정이 완료되었습니다.` : `리뷰 작성이 완료되었습니다.` });
				resolve();
			});
	};

	const onHideButton = (isHide) => {
		console.log(isHide);
		setHideButton(isHide);
	};

	return (
		<Wrapper>
			{data && <ProductItem {...data} star={star} setStar={setStar} />}
			{reviewPage === "Y" && isReviewer === "Y" && <ProductSelect productIdx={productIdx} setProductIdx={setProductIdx} />}
			{reviewPage === "Y" && isReviewer !== "Y" && <MyOrderSelect productIdx={productIdx} setProductIdx={setProductIdx} setOrderIdx={setOrderIdx} onHideButton={onHideButton} />}

			{!hideButton && (
				<>
					<div className="image-box">
						<button onClick={() => fileRef.current.click()}>사진 첨부</button>
						<input ref={fileRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
						{images?.length > 0 && (
							<div className="image-slider">
								<Slider ref={sliderRef} {...sliderSetting}>
									{images?.map((item, index) => (
										<a key={index}>
											<img src={item.signedUrl} alt="" />
											<button className="btn-remove" onClick={() => onRemoveImage(index)}>
												<img src={"/icons/ic_remove.png"} srcSet={getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])} />
											</button>
										</a>
									))}
								</Slider>
							</div>
						)}
					</div>
					<Textarea
						title="상품 후기"
						name="content"
						value={content}
						onChange={(e) => {
							setContent(e.target.value);
						}}
						placeholder="상품 품질에 대한 고객님의 솔직한 리뷰를 남겨주세요."
					/>
				</>
			)}
			<div className="buttons">
				<button onClick={() => reject()}>닫기</button>
				{!hideButton && (
					<button className="action" onClick={onSave}>
						저장
					</button>
				)}
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-bottom: 60px;
	}
	div.image-box {
		position: relative;

		> button {
			position: absolute;
			top: 13px;
			right: 0;
			background: #ffffff;
			border: 1px solid #00788d;
			padding: 4px 15px;
			color: #00788d;
			font-family: ${(props) => props.theme.fontFamily.regular};
			text-align: center;
			font-size: 14px;
			line-height: 20px;
			z-index: 2;
		}

		.image-slider {
			position: relative;
			width: 500px;
			height: 300px;
			padding: 20px;
			margin: 0 auto;

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				width: calc(100% - 40px);
				max-width: 200px;
				padding-top: 60px;
				height: auto;

				a > img {
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}

			button.btn-remove {
				position: absolute;
				top: 0;
				right: 0;
				background: transparent;
				border: none;

				@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
					top: 5px;
					right: 5px;
				}
			}

			a {
				position: relative;
				z-index: 1;

				img {
					max-height: 300px;
					cursor: pointer;
					object-fit: contain;
					margin: 0 auto;
				}
			}

			div.sd-paging {
				position: absolute;
				z-index: 2;
				button {
					background: transparent;
					border: none;
				}
			}

			div.sd-paging.sd-prev {
				left: -100px;
				top: 150px;
			}
			div.sd-paging.sd-next {
				right: -100px;
				top: 150px;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		textarea {
			width: calc(100% - 22px);
		}
	}

	> div.buttons {
		margin-top: 30px;
		text-align: center;
		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			position: fixed;
			bottom: 0;
			width: 100%;
			display: flex;
			margin-top: 0px;
			left: 0;
		}
		button {
			border: 1px solid #00788d;
			background: #ffffff;
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #00788d;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			padding: 15px 0;
			width: 220px;

			:last-child {
				margin-left: 12px;
			}
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				flex: 1;
				width: 100%;
				margin-left: 0px !important;
			}
		}

		button.action {
			border: 1px solid #00788d;
			background: #00788d;
			color: #ffffff;
		}
	}
`;

const ProductItem = (props) => {
	const { star, setStar } = props;

	const getStar = () => {
		const stars = [];
		let remain = 5;

		remain -= star;

		for (let i = 0; i < star; i++) {
			stars.push(
				<img src={"/icons/ic_star_on.png"} srcSet={getSrcSet(["/icons/ic_star_on.png", "/icons/ic_star_on@2x.png", "/icons/ic_star_on@3x.png"])} onClick={() => setStar(i + 1)} />
			);
		}
		for (let i = 0; i < remain; i++) {
			stars.push(
				<img
					src={"/icons/ic_star_off.png"}
					srcSet={getSrcSet(["/icons/ic_star_off.png", "/icons/ic_star_off@2x.png", "/icons/ic_star_off@3x.png"])}
					onClick={() => setStar(star + i + 1)}
				/>
			);
		}

		return stars;
	};

	return (
		<PIWrapper>
			<img src={props.productFileSignedUrl} />
			<div className="info">
				<div>
					<div className="brand">{props.productBrandName}</div>
					<h4 className="title">{props.productName}</h4>
					<div className="option">
						{props.productOptItemList?.map((item, index) => (
							<div key={index}>
								{item.productOptionName} : {item.productOptionItemName} / 수량 {item.quantity}개
							</div>
						))}
					</div>
					{props.priceRewardPoint && (
						<div className="price">
							{/* {_U.numberFormat(props.totalAmountPayment)} 원 */}
							적립금 : {_U.numberFormat(props.priceRewardPoint)}원
						</div>
					)}
				</div>
				<div className="star">{getStar()}</div>
			</div>
		</PIWrapper>
	);
};

const PIWrapper = styled.div`
	border-bottom: 1px solid #efefef;
	display: flex;
	padding: 16px 0 35px;

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding-top: 32px;
	}

	:last-child {
		border-bottom: 0px;
		padding-bottom: 0px;
	}

	> img {
		flex-basis: 90px;
		width: 90px;
		height: 90px;
		object-fit: cover;
	}

	> div.info {
		flex: 1;
		padding: 0 16px;
		display: flex;
		align-items: center;

		> div {
			flex: 1;
		}

		> div.star {
			display: flex;
			justify-content: flex-end;

			img {
				cursor: pointer;
			}

			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		div.brand {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 13px;
			line-height: 19px;
		}

		h4.title {
			margin: 0px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
		}

		div.option {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #b8b8b8;
			font-size: 13px;
			line-height: 19px;
		}

		div.price {
			font-family: ${(props) => props.theme.fontFamily.regular};
			color: #3d3d3d;
			font-size: 14px;
			line-height: 20px;
			margin-top: 8px;
		}
	}
`;

const ProductSelect = (props) => {
	const { productIdx, setProductIdx } = props;
	const [keyword, setKeyword] = useState("");
	const [list, setList] = useState("");

	const onSearchProduct = () => {
		const params = { keyword };
		_API
			.get({
				path: "/product/search/category",
				data: params,
			})
			.then((res) => res.data)
			.then((data) => {
				const { list } = data;
				if (!list.length) {
					swal({ title: "알림", text: "검색 결과가 없습니다." });
				} else {
					setList(list);
				}
			});
	};

	return (
		<SWrapper>
			<UFormWrapper styles={"margin-bottom:0px;"} title={"상품 검색"} labelStyles={"padding:24px 0 14px;"} formStyles={"padding:14px 0;"}>
				<input
					type="text"
					name="keyword"
					onChange={(e) => setKeyword(e.target.value)}
					value={keyword}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							onSearchProduct();
						}
					}}
				/>
				<button className="btn-search" onClick={() => onSearchProduct()}>
					검색
				</button>
			</UFormWrapper>
			{list?.length > 0 && (
				<UFormWrapper styles={"margin-bottom:0px;"} title={"상품 선택"} labelStyles={"padding:24px 0 14px;"} formStyles={"padding:14px 0;"}>
					<select value={productIdx} onChange={(e) => setProductIdx(e.target.value)}>
						<option value="">상품을 선택해주세요.</option>
						{list?.map((item, index) => (
							<option value={item.idx} key={index}>
								[{item.brand}] {item.name}
							</option>
						))}
					</select>
				</UFormWrapper>
			)}
		</SWrapper>
	);
};

const SWrapper = styled.div`
	.uif-form {
		display: flex;

		input {
			padding: 13px 16px;
			width: calc(100% - 32px);
			height: 16px;
			border: #efefef solid 1px;
			/* background: #f9f9f9; */
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			color: #3d3d3d;
			flex: 1;
		}

		button {
			background: none;
			border: 1px solid #cfcfcf;
			color: #777777;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			flex-basis: 100px;
		}

		select {
			width: 100%;
			border: 1px solid #cfcfcf;
			font-family: ${(props) => props.theme.fontFamily.light};
			color: #777777;
			font-size: 12px;
			line-height: 16px;
			padding: 9px 16px;
			-moz-appearance: none; /* Firefox */
			-webkit-appearance: none; /* Safari and Chrome */
			appearance: none;
			background-image: url("/icons/ic_selectdown.png");
			background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_selectdown.png", "/icons/ic_selectdown@2x.png", "/icons/ic_selectdown@3x.png"])});
			background-repeat: no-repeat;
			background-size: 10px 6px;
			background-position: right 10px center;
		}
	}

	div.empty {
		text-align: center;
		color: #777777;
		font-family: ${(props) => props.theme.fontFamily.regular};
		padding: 16px 0;
	}
`;

const MyOrderSelect = (props) => {
	const { productIdx, setProductIdx, setOrderIdx } = props;
	const [list, setList] = useState([]);

	const loadList = (data) => {
		_API.get({ path: "/user/mypage/order/review", data: { ...data } }).then((res) => {
			const { list } = res.data;
			setList(list?.filter((f) => !f.reviewIdx));
		});
	};

	useEffect(() => {
		loadList();
	}, []);

	useEffect(() => {
		if (list.length > 0) {
			props.onHideButton(false);
		} else {
			props.onHideButton(true);
		}
	}, [list]);

	const onSelectOrder = (e) => {
		const orderIdx = e.target.options[e.target.selectedIndex].dataset.orderidx;
		const productIdx = e.target.value;
		setProductIdx(productIdx);
		setOrderIdx(orderIdx);
	};

	return (
		<SWrapper>
			{list?.length > 0 && (
				<UFormWrapper styles={"margin-bottom:0px;"} title={"주문 상품 선택"} labelStyles={"padding:24px 0 14px;"} formStyles={"padding:14px 0;"}>
					<select value={productIdx || ""} onChange={(e) => onSelectOrder(e)}>
						<option value="">주문 상품을 선택해주세요.</option>
						{list?.map((item, index) => (
							<option value={item.productIdx} data-orderidx={item.orderIdx} key={index}>
								[{item.productBrandName}] {item.productName} - {item.optNames}, {moment(item.orderDate).format("YYYY-MM-DD")} 주문
							</option>
						))}
					</select>
				</UFormWrapper>
			)}

			{list.length === 0 && <div className="empty">리뷰 작성 가능한 주문건이 없습니다.</div>}
		</SWrapper>
	);
};
