/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../Components/AppProvider";

import { _API, _U, _CK } from "../../Modules/Utils";
import { _CFG } from "../../Modules/Config";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";

export default (props) => {
	const { location, history, match } = props;
	const { iam, saveAuth, saveIam } = useContext(AppContext);
	const { device, version, appversion, lang } = useContext(AppContext);

	const handleLogout = () => {
		_CK.clearAuth();
		saveAuth("");
		saveIam(null);
		_CK.set(`passwordCert${iam?.idx}`, "");

		if (device && device !== "null") {
			document.location.href = `${_CFG.appSchema}logout`;
		}
		_CK.set("device", null);
		_CK.set("version", null);
		_CK.set("appversion", null);
		_CK.set("lang", null);

		history.push("/");
	};
	return (
		<MenuWrapper>
			<Accordion title="마이 플렉스" collapsed={false}>
				<ul>
					{/* {iam?.isStreamer === "Y" && (
						<li>
							<Link to="/streamer">방송하기</Link>
						</li>
					)} */}
					<li className={cx({ active: location.pathname === "/user/my/order" || location.pathname === "/user/my" })}>
						<Link to="/user/my/order">구매내역</Link>
					</li>
					<li className={cx({ active: location.pathname === "/user/my/review" })}>
						<Link to="/user/my/review">마이리뷰</Link>
					</li>
					<li className={cx({ active: location.pathname === "/user/my/bbs/productqna" })}>
						<Link to="/user/my/bbs/productqna">상품 문의내역</Link>
					</li>
					<li className={cx({ active: location.pathname === "/user/my/bbs/qna" })}>
						<Link to="/user/my/bbs/qna">1:1문의</Link>
					</li>
					<li className={cx({ active: location.pathname.indexOf("/user/my/request/as") > -1 })}>
						<Link to="/user/my/request/as">A/S신청</Link>
					</li>
					<li className={cx({ active: location.pathname.indexOf("/user/my/request/return") > -1 })}>
						<Link to="/user/my/request/return">반품신청</Link>
					</li>
				</ul>
			</Accordion>
			<Accordion title="회원정보" collapsed={false}>
				<ul>
					<li className={cx({ active: location.pathname === "/user/my/info" })}>
						<Link to="/user/my/info">정보수정</Link>
					</li>
					<li className={cx({ active: location.pathname === "/user/my/withdrawal" })}>
						<Link to="/user/my/withdrawal">탈퇴</Link>
					</li>
				</ul>
			</Accordion>
			{/* <Accordion title="고객센터" collapsed={false}>
				<ul>
					<li>
						<Link to="/cms/user/terms">서비스 이용약관</Link>
					</li>
					<li>
						<Link to="/cms/user/privacy">개인정보 처리방침</Link>
					</li>
					<li className={cx({ active: location.pathname === "/user/my/bbs/faq" })}>
						<Link to="/user/my/bbs/faq">자주 하는 질문</Link>
					</li>


					{isMobile && (
						<li>
							<a onClick={(e) => handleLogout()}>로그아웃</a>
						</li>
					)}
				</ul>
			</Accordion> */}
		</MenuWrapper>
	);
};

const MenuWrapper = styled.div`
	width: 276px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		display: none;
	}

	.accordion-header {
		color: #3d3d3d;
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 13px;
		line-height: 17px;
		padding: 20px 0;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding: 8px 0;
			font-family: ${(props) => props.theme.fontFamily.regular};
			a {
				font-size: 13px;
				font-weight: normal;
				color: #777;
				text-decoration: none;
				display: block;
				font-family: ${(props) => props.theme.fontFamily.regular};

				small {
					float: right;
					clear: right;
					font-size: 10px;
					color: #acacac;
				}
			}
		}

		li.active {
			font-family: ${(props) => props.theme.fontFamily.medium};
		}
	}
`;
