/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import cx from "classnames";
import ImageMagnify from "react-image-magnify";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { CheckBox } from "../../UI/Product";
import { SelectBox } from "../../UI/Form";

import Layout from "../Layout";
import { AppContext } from "../../Components/AppProvider";
import { _U, _API } from "../../Modules/Utils";

import ProductList from "./Like.Product";

export default (props) => {
    const { history } = props;
    const { iam, auth } = useContext(AppContext);

    if (!auth) {
        history.goBack();
        return null;
    }

    const [list, setList] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);

    const loadData = () => {
        const params = {};

        _API.get({ path: "/user/payment/product/like", data: params })
            .then((res) => res.data)
            .then((data) => {
                const { list } = data;
                setList(list);
            });
    };

    useEffect(() => {
        if (auth) {
            loadData();
        } else {
            setList([]);
        }
    }, [auth]);

    const onRefresh = () => {
        loadData();
    };

    useEffect(() => {
        setIsEmpty(list.length === 0);
    }, [list]);

    return (
        <Layout {...props} showMenu={true}>
            <Wrapper>
                <div className="top">
                    <h2>좋아요 상품</h2>
                </div>
                {isEmpty && <EmptyLike />}

                {!isEmpty && (
                    <>
                        <div className="list-box">
                            <ProductList {...props} list={list} onRefresh={onRefresh} />
                        </div>
                    </>
                )}
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
    width: ${(props) => props.theme.layout.productWidth}px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 80px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: calc(100% - 42px);
        padding: 0 21px;
    }

    div.top {
        position: relative;
        display: flex;
        padding-bottom: 30px;
        border-bottom: 2px solid #3d3d3d;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            border-bottom: 0;
            padding-bottom: 0px;
        }
    }

    div.top > h2,
    div.top > div {
        flex: 1;
    }

    div.top > h2 {
        margin: 0px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 24px;
        line-height: 35px;
        font-weight: normal;
    }

    div.top > div {
        display: flex;
        justify-content: flex-end;
        align-items: end;
    }

    div.list-box {
        margin-top: 60px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-top: 32px;
        }
    }

    div.list-box > h3 {
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #3d3d3d;
        font-size: 18px;
        line-height: 26px;
        font-weight: normal;
        margin: 0px;
    }

    div.total-summary {
        margin-top: 60px;
        border-top: 2px solid #e32222;
        border-bottom: 1px solid #efefef;

        display: flex;
        min-height: 170px;

        > div.detail {
            flex-basis: 420px;
            background: #f5f5f5;
            padding: 24px 40px;

            dl {
                display: flex;
                justify-content: space-between;
                margin: 16px 0 0 0;

                :first-child {
                    margin: 0;
                }
            }

            dl > dt {
                flex: 1;
                font-family: ${(props) => props.theme.fontFamily.regular};
                color: #b8b8b8;
                font-size: 13px;
                line-height: 19px;
            }

            dl > dd {
                flex: 1;
                font-family: ${(props) => props.theme.fontFamily.medium};
                color: #3d3d3d;
                font-size: 14px;
                line-height: 20px;
                text-align: right;

                b {
                    color: #e32222;
                    font-weight: normal;
                }
            }
        }

        > div.sum {
            flex: 1;
            font-family: ${(props) => props.theme.fontFamily.medium};
            color: #e32222;
            font-size: 24px;
            line-height: 35px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            b {
                color: #e32222;
                font-weight: normal;
                margin-left: 16px;

                span {
                    font-family: ${(props) => props.theme.fontFamily.nanumEB};
                }
            }

            small {
                font-family: ${(props) => props.theme.fontFamily.regular};
                color: #777777;
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    > div.buttons {
        margin-top: 30px;
        text-align: center;

        button {
            border: 1px solid #e32222;
            background: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #e32222;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 15px 0;
            width: 220px;

            :last-child {
                margin-left: 12px;
            }
        }

        button.action {
            border: 1px solid #e32222;
            background: #e32222;
            color: #ffffff;
        }
    }
`;

const EmptyLike = () => {
    return (
        <EWrapper>
            <h3>찜한 상품이 없습니다.</h3>
            <p>모아에서 상품을 확인해보세요.</p>
            <button>모아 추천 보러가기</button>
        </EWrapper>
    );
};

const EWrapper = styled.div`
    padding: 40px 0;
    text-align: center;
    h3 {
        margin: 0px;
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #777777;
        font-size: 18px;
        line-height: 26px;
    }

    p {
        margin: 12px 0 0 0px;
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #b8b8b8;
        font-size: 14px;
        line-height: 20px;
    }

    button {
        margin-top: 38px;
        background: #ffffff;
        border: 1px solid #efefef;
        text-align: center;
        padding: 14px 44px;
        font-weight: normal;
        font-family: ${(props) => props.theme.fontFamily.medium};
        color: #777777;
        font-size: 14px;
        line-height: 20px;
    }
`;
