import styled from "styled-components";

export const MBContainer = styled.div`
    margin: 0 auto;
    width: ${(props) => props.theme.layout.width}px;
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        display: block;
        width: 100%;
    }

    .main {
        position: relative;
        width: 816px;
        height: 310px;
        flex: 1;

        img {
            width: 816px;
            height: 310px;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 100%;
            height: 310px;
            position: absolute;
            top: 0;
            left: 0;

            img {
                width: 100%;
                height: 310px;
                border-radius: 0;
                object-fit: cover;
            }
        }
    }
    .sub {
        flex-basis: 378px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-top: 16px;
            width: 100% !important;

            .others {
                img {
                    width: 100%;
                    height: calc(100% * 0.4497);
                }
            }
        }
    }

    .search {
        display: none;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-top: 267px;
            display: block;
            position: relative;
            width: calc(100% - 42px);
            padding: 0 21px;
            z-index: 3;
        }
    }

    .search > div {
        width: 100%;
    }

    .search > div.fix {
        position: fixed;
        top: 70px;
        left: 21px;
        right: 21px;
        width: calc(100% - 42px);
        button {
            right: 0;
        }
    }

    .search input {
        ::placeholder {
            color: #3d3d3d;
        }

        padding: 0 23px;
        width: calc(100% - 46px);
        height: 41px;
        border: #777777 solid 1px;
        border-radius: 0;
    }

    .search button {
        position: absolute;
        width: 40px;
        height: 40px;
        right: 12px;
        top: 3px;
        background: transparent;
        border: none;
        cursor: pointer;
        right: 22px;
    }
`;

export const HIContainer = styled.div`
    padding: 30px 60px;
    height: 250px;
    background: #f9f9f9;
    overflow: hidden;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        height: 270px;
    }

    .title {
        margin: 0;
        padding: 0;
        font-family: ${(props) => props.theme.fontFamily.bold};
        font-size: 18px;
        font-weight: bold;
        color: #222222;
    }
    .title > small {
        float: right;
        clear: right;
        font-size: 14px;
        color: #939393;
    }
    .title > small > span {
        color: #242424;
    }

    .item {
        position: relative;
        margin-top: 15px;
        text-align: center;
    }

    .item .nav {
        position: absolute;
        top: 65px;
        width: 30px;
        height: 30px;
        border: none;
        background-color: transparent;
        font-size: 22px;
        cursor: pointer;
        color: #d1d1d1;
    }

    .item .prev {
        left: -15px;
    }
    .item .next {
        right: -15px;
    }

    .info .brand {
        margin: 0;
        padding: 0;
        color: #777777;
        font-size: 13px;
        text-align: center;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }
    .info .product {
        margin: 0;
        padding: 0;
        color: #3d3d3d;
        font-size: 14px;
        text-align: center;
        font-family: ${(props) => props.theme.fontFamily.regular};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .info .cost {
        margin: 0;
        padding: 0;
        color: #e32222;
        font-size: 16px;
        font-weight: bold;
        text-align: center;

        b {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            font-weight: normal;
        }
    }
`;

export const OAContainer = styled.div`
    margin: 50px auto 0;
    width: ${(props) => props.theme.layout.width}px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: calc(100% - 21px);
        padding-left: 21px;
    }

    .title {
        margin: 0;
        padding: 0;
        font-size: 22px;
        font-weight: 500;
        color: #3d3d3d;
    }
    .title .dot {
        margin: 0 0 4px 10px;
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #e32222;
        border-radius: 4px;
    }

    .wrapper {
        position: relative;
        margin-top: 16px;
        white-space: nowrap;
    }

    hr.split {
        margin: 50px 0;
        border: #efefef solid 1px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }
`;

export const OAIContainer = styled.div`
    position: relative;
    margin: 0 30px 0 0;
    width: 378px;
    display: inline-block;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 319px;
    }

    :last-child {
        margin-right: 0;
    }

    video {
        width: 378px;
        height: 542px;
        border-radius: 10px;
        overflow: hidden;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 319px;
            height: 457px;
            overflow: inherit;
        }
    }

    .subTitle {
        position: absolute;
        top: 16px;
        right: 16px;
        left: 16px;
        display: flex;
    }

    .subTitle > .number {
        margin: 0;
        padding: 9px 14px;
        color: #ffffff;
        background-color: #e32222;
        border-radius: 5px;
    }

    .subTitle > .separator {
        flex: 1;
    }

    .subTitle > .time > img {
        vertical-align: middle;
        margin-right: 5px;
    }
    .subTitle > .time {
        margin-top: 7px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        color: #fff;
    }

    .info {
        margin-top: 8px;
    }
    .info > .title {
        margin: 0;
        font-size: 16px;
        color: #3d3d3d;
    }
    .info > .text {
        font-size: 13px;
        color: #979797;
    }
    .info > .text > img {
        margin: 0 5px 0 16px;
        vertical-align: middle;
        margin-right: 5px;
    }
    .info > .text > img:first-child {
        margin-left: 0;
    }

    .seller {
        margin-top: 8px;
        font-size: 14px;
        color: #777;
    }
    .seller > img {
        margin-right: 6px;
        vertical-align: middle;
        border-radius: 14px;
    }

    .item {
        position: absolute;
        height: 92px;
        top: 435px;
        left: 16px;
        right: 16px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
        overflow: hidden;
        display: flex;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            top: 350px;
            left: 16px;
            right: 16px;
        }
    }
    .item > .thumbnail {
    }
    .item > .content {
        padding: 15px 0 0 16px;
        flex: 1;
    }
    .item > .content > .title {
        margin: 0;
        padding: 0;
        color: #b8b8b8;
        font-size: 20px;
    }
    .item > .content > .status {
        font-size: 22px;
        color: #ffffff;
    }
    .item > .content > .status > .percent {
        color: #e32222;
        margin-right: 10px;
    }
`;

export const VODContainer = styled.div`
    margin: 50px auto 0;
    width: ${(props) => props.theme.layout.width}px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: calc(100% - 21px);
        padding-left: 21px;
    }

    .title {
        margin: 0;
        padding: 0;
        font-size: 22px;
        font-weight: 500;
        color: #3d3d3d;
    }

    .wrapper {
        position: relative;
        margin-top: 16px;
        white-space: nowrap;
    }

    hr.split {
        margin: 50px 0;
        border: #efefef solid 1px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }
`;

export const VODIContainer = styled.div`
    position: relative;
    margin: 0 16px 0 0;
    width: 186px;
    display: inline-block;

    :last-child {
        margin-right: 0;
    }

    .thumbnail img {
        width: 186px;
        height: 267px;
        border-radius: 10px;
        overflow: hidden;
    }

    .info {
        margin-top: 6px;
    }

    .info > .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: normal;
        color: #3d3d3d;
    }
    .info > .text {
        font-size: 13px;
        color: #979797;
    }
    .info > .text > img {
        margin: 0 5px 0 16px;
        vertical-align: middle;
        margin-right: 5px;
    }
    .info > .text > img:first-child {
        margin-left: 0;
    }
    .info > .uploader {
        font-size: 13px;
        font-weight: 300;
        color: #777;
    }
`;

export const LNContainer = styled.div`
    margin: 50px auto 0;
    width: ${(props) => props.theme.layout.width}px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }

    .title {
        margin: 0;
        padding: 0;
        font-size: 22px;
        font-weight: 500;
        color: #3d3d3d;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding-left: 21px;
        }
    }

    .wrapper {
        position: relative;
        margin-top: 16px;
        white-space: nowrap;
    }

    hr.split {
        margin: 50px 0;
        border: #efefef solid 1px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }
`;

export const LNIContainer = styled.div`
    position: relative;
    margin: 0 40px 0 0;
    width: 572px;
    height: 395px;
    display: inline-block;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        height: 612px;
    }

    :last-child {
        margin-right: 0;
    }

    .layout {
        width: 100%;
        height: 100%;
        display: flex;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: block;
        }
    }

    .layout > .thumbnails {
        position: relative;
        width: 276px;
        height: 395px;
        border-radius: 10px;
        overflow: hidden;

        > img {
            width: 276px;
            height: 395px;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 100%;
            height: 612px;
            position: absolute;
            border-radius: 0;
            overflow: inherit;

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .layout > .thumbnails > .black-cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .layout > .thumbnails > .timer {
        position: absolute;
        margin-top: -50px;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin-top: 0px;
            top: 80px;
        }
    }

    .layout > .thumbnails > .timer > .time {
        margin: 20px 0 0;
        font-size: 30px;
        font-weight: normal;
        color: #fff;
    }

    .layout > .content {
        flex: 1;
        padding: 12px 0 0 16px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: absolute;
            z-index: 2;
            bottom: 40px;
            left: 0;
            width: calc(100% - 32px);
            padding: 12px 16px 0;
        }
    }

    .layout > .content > .title {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #777777;
        font-family: ${(props) => props.theme.fontFamily.medium};

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            color: #ffffff;
            padding-left: 0;
        }
    }

    .layout > .content > .subTitle {
        margin: 0;
        font-size: 14px;
        font-weight: 300;
        color: #c58c28;
        font-family: ${(props) => props.theme.fontFamily.light};

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            color: #ffffff;
        }
    }

    .layout > .content > .cost {
        margin: 10px 0 0;
        font-size: 22px;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.nanumEB};

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            color: #ffffff;
        }
    }

    .layout > .content > .cost > .discount {
        margin-right: 6px;
        font-size: 18px;
        color: #e32222;
        font-family: ${(props) => props.theme.fontFamily.nanumEB};
    }

    .layout > .content > .seller {
        margin: 10px 0 0;
        font-size: 14px;
        color: #777777;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            color: #ffffff;
        }
    }

    .layout > .content > .seller > img {
        margin-right: 8px;
        border-radius: 18px;
        vertical-align: middle;
    }

    .layout > .content > hr.split {
        margin: 24px 0;
        border: #efefef solid 1px;
    }

    .layout > .content > .run-day {
        font-size: 16px;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.regular};

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            color: #ffffff;
        }
    }

    .layout > .content > .run-day > span {
        display: block;
        font-size: 30px;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.nanumR};
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            color: #ffffff;
        }
    }

    .layout > .content > button.btn-notify {
        margin-top: 16px;
        width: 140px;
        height: 44px;
        background-color: #e32222;
        border: #e32222 solid 1px;
        border-radius: 22px;

        font-size: 16px;
        color: #fff;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: absolute;
            right: 16px;
            bottom: 0;
        }
    }

    .layout > .content > button.btn-notify > img {
        margin-right: 6px;
        vertical-align: middle;
    }
`;

export const TDContainer = styled.div`
    margin: 50px auto 0;
    width: ${(props) => props.theme.layout.width}px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }

    .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
        text-align: center;
        color: #777777;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }

    .time {
        margin: 14px 0 0;
        font-size: 38px;
        font-weight: normal;
        text-align: center;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
    }

    hr.split {
        margin: 50px 0;
        border: #efefef solid 1px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }
`;

export const TDPContainer = styled.div`
    margin-top: 30px;
    text-align: center;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        padding: 0 16px;
    }
`;

export const TDPIContainer = styled.div`
    margin: 0 15px;
    width: 480px;
    height: 180px;
    display: inline-block;
    cursor: pointer;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        margin: 0;
        height: 160px;
    }

    .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
    }

    .thumbnail {
        width: 180px;
        height: 180px;

        img {
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            width: 160px;
            height: 160px;
        }
    }

    .content {
        padding: 30px 24px;
        text-align: left;
        background-color: #f8f8f8;
        flex: 1;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 16px;
        }
    }

    .content > .brand {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        color: #777777;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }
    .content > .title {
        margin: 0;
        font-size: 16px;
        text-align: left;
        color: #3d3d3d;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }
    .content > .cost {
        margin: 8px 0 0;
        font-size: 20px;
        font-weight: normal;
        text-align: left;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            font-size: 18px;
        }
        b {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            font-weight: normal;
        }
    }
    .content > .cost > span {
        margin-left: 8px;
        font-size: 14px;
        font-weight: normal;
        color: #e32222;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
    }
    .content > .old-cost {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        text-align: left;
        text-decoration: line-through;
        color: #dedede;

        b {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};
            font-weight: normal;
        }
    }
    .content > .tags {
        margin-top: 16px;
    }

    .content > .tags > .tag {
        margin-right: 4px;
        padding: 3px 8px;
        font-size: 10px;
        color: #777;
        border: solid 1px #cfcfcf;
        display: inline-block;
    }
    .content > .tags > .tag:last-child {
        margin-right: 0;
    }
    .content > .tags > .red {
        color: #e32222;
    }
`;

export const NAContainer = styled.div`
    margin: 50px auto 0;
    width: ${(props) => props.theme.layout.width}px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }

    > .title {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 500;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 16px;
        }
    }

    .list {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            justify-content: space-around;
            padding: 0 16px;
        }
    }

    .wrapper {
        position: relative;
        margin: 0 -8px;
        padding: 16px 0;
        white-space: nowrap;
    }

    hr.split {
        margin: 50px 0;
        border: #efefef solid 1px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            display: none;
        }
    }
`;

export const NAIContainer = styled.div`
    position: relative;
    margin: 0 1px 20px;
    height: 357px;
    flex-basis: 226px;
    background-color: #fff;
    display: inline-block;
    cursor: pointer;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        height: 305px;
        flex: 1;
    }

    ${(props) =>
        !props.noHover
            ? `
			:hover {
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
				@media only screen and (max-width: ${props.theme.layout.mobileWith}px) {
					box-shadow: unset;
				}
			}	
			`
            : ``}

    .thumbnail {
        margin: 10px auto 6px;
        width: 206px;
        height: 206px;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            height: 166px;
            width: 166px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content {
        margin: 0 10px;
    }

    .content > .brand {
        margin: 0;
        font-size: 13px;
        font-weight: normal;
        color: #777;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }
    .content > .title {
        margin: 0;
        font-size: 16px;

        color: #3d3d3d;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }
    .content > .cost {
        margin: 8px 0 0;
        font-size: 20px;
        color: #3d3d3d;
        line-height: 20px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            font-size: 18px;
        }

        b {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
        }
    }
    .content > .cost > span {
        margin-left: 8px;
        font-size: 14px;
        color: #e32222;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
    }
    .content > .old-cost {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        text-align: left;
        text-decoration: line-through;
        color: #dedede;
        font-family: ${(props) => props.theme.fontFamily.medium};

        b {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};
            font-weight: normal;
        }
    }
`;

export const RContainer = styled.div`
    margin: 50px auto 0;
    width: ${(props) => props.theme.layout.width}px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
    }

    > .title {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 500;
        color: #3d3d3d;
        font-family: ${(props) => props.theme.fontFamily.medium};
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            padding: 0 16px;
        }
    }

    .wrapper {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: repeat(auto-fit, 185px);
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        justify-content: space-between;

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            justify-content: space-around;
            padding: 0 16px;
        }
    }

    .more {
        margin: 30px 0;
        text-align: center;
    }
    .more > button {
        width: 280px;
        height: 40px;
        font-size: 14px;
        border: solid 1px #dedede;
        background-color: #fff;
        color: #777;
        cursor: pointer;
    }
`;

export const RIContainer = styled.div`
    position: relative;
    width: 226px;
    height: 357px;
    cursor: pointer;
    margin: 0 1px 20px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        height: 305px;
        flex: 1;
        width: auto;
    }

    ${(props) =>
        !props.noHover
            ? `
			:hover {
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
				@media only screen and (max-width: ${props.theme.layout.mobileWith}px) {
					box-shadow: unset;
				}
			}	
			`
            : ``}

    .number {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background-color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
        line-height: 28px;
    }

    .thumbnail {
        margin: 10px auto 6px;
        width: 206px;
        height: 206px;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            height: 166px;
            width: 166px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        margin: 0 10px 0;
        text-align: left;
    }

    .content > .brand {
        margin: 0;
        font-size: 13px;
        font-weight: normal;
        color: #777;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }
    .content > .title {
        margin: 0;
        font-size: 16px;
        text-align: left;
        color: #3d3d3d;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-family: ${(props) => props.theme.fontFamily.regular};
    }
    .content > .cost {
        margin: 8px 0 0;
        font-size: 20px;
        text-align: left;
        color: #3d3d3d;
        line-height: 20px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            font-size: 18px;
        }
        b {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
            font-weight: normal;
        }
    }
    .content > .cost > span {
        margin-left: 8px;
        font-size: 14px;
        font-weight: normal;
        color: #e32222;
        font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
    }
    .content > .old-cost {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        text-align: left;
        text-decoration: line-through;
        color: #dedede;
        font-family: ${(props) => props.theme.fontFamily.medium};

        b {
            font-family: ${(props) => props.theme.fontFamily.GmarketSansMedium};
            font-weight: normal;
        }
    }
    .content > .tags {
        margin-top: 18px;
    }

    .content > .tags > .tag {
        margin-right: 4px;
        padding: 3px 8px;
        font-size: 10px;
        color: #777;
        border: solid 1px #cfcfcf;
        display: inline-block;
    }
    .content > .tags > .tag:last-child {
        margin-right: 0;
    }
    .content > .tags > .red {
        color: #e32222;
    }
`;
