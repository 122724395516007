/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";
import cx from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import { getSrcSet } from "../../UI/Assets";

export default (props) => {
	const { audio, video, resolution, onDeviceChange, onSetStart, onSetStop, isStart, castData } = props;
	const { audioId, videoId, resolutionId } = props;
	const [modifyTitle, setModifyTitle] = useState(false);
	const [title, setTitle] = useState("");

	useEffect(() => {
		setTitle(castData?.title);
	}, [castData]);

	const onSaveTitle = () => {
		if (!isStart) {
			setModifyTitle(false);
			return;
		}

		_API
			.post({
				path: "/cast/save",
				data: {
					title,
					castIdx: castData?.idx,
				},
			})
			.then(() => {
				setModifyTitle(false);
			});
	};

	const onCastStart = () => {
		if (isStart && castData?.idx) {
			swal({
				title: "알림",
				text: "방송을 종료 하시겠습니까?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then((result) => {
				if (result) {
					_API
						.post({
							path: "/cast/save",
							data: {
								...castData,
								status: "N",
								castIdx: castData?.idx,
							},
						})
						.then(() => {
							onSetStop();
						});
				}
			});
		} else {
			_API
				.get({
					path: "/cast/info",
				})
				.then((res) => {
					const { data } = res;
					if (data?.idx && data?.status === "Y") {
						swal({ title: "알림", text: "이미 시작 중인 방송입니다. " });
					} else {
						_API
							.post({
								path: "/cast/save",
								data: {
									title,
								},
							})
							.then(() => {
								onSetStart();
							});
					}
				});
		}
	};

	const onPreview = () => {
		if (!isStart) {
			swal({ title: "알림", text: "방송 시작 후 미리보기 가능합니다. " });
			return;
		}

		if (!castData?.idx) {
			swal({ title: "알림", text: "방송 정보가 없습니다." });
			return;
		}

		window.open(`/#/live2/${castData?.idx}`);
	};

	const getLiveUrl = () => {
		return `${window.location.origin}/#/live2/${castData?.idx}`;
	};

	return (
		<Container>
			<TitleWrapper>
				<>
					{modifyTitle ? (
						<>
							<input type="text" name="title" value={title || ""} onChange={(e) => setTitle(e.target.value)} />
							<button className="btn-modify" onClick={() => onSaveTitle()}>
								확인
							</button>
							<button className="btn-cancel" onClick={() => setModifyTitle(false)}>
								취소
							</button>
						</>
					) : (
						<>
							<h4 className="title">{title}</h4>

							<button type="button">
								<img
									onClick={() => setModifyTitle(true)}
									src="/images/cast_pen_edit.png"
									width="24"
									height="24"
									srcSet={getSrcSet(["/images/cast_pen_edit.png", "/images/cast_pen_edit@2x.png", "/images/cast_pen_edit@3x.png"])}
								/>
							</button>
						</>
					)}
				</>
			</TitleWrapper>

			<Wrapper>
				<Column width={250}>
					<div className="wrapper">
						<h3 className="title">카메라</h3>
						<select className="form" value={videoId} onChange={onDeviceChange.bind(this, "VIDEO")}>
							<option value="">카메라를 선택해 주세요.</option>
							{video.map((item, i) => (
								<option key={i} value={item.deviceId}>
									{item.label}
								</option>
							))}
						</select>
					</div>
				</Column>
				<Column width={250}>
					<div className="wrapper">
						<h3 className="title">마이크</h3>
						<select className="form" value={audioId} onChange={onDeviceChange.bind(this, "AUDIO")}>
							<option value="">마이크를 선택해 주세요.</option>
							{audio.map((item, i) => (
								<option key={i} value={item.deviceId}>
									{item.label}
								</option>
							))}
						</select>
					</div>
				</Column>
				<Column width={250}>
					<div className="wrapper">
						<h3 className="title">해상도</h3>
						<select className="form" value={resolutionId} onChange={onDeviceChange.bind(this, "RESOLUTION")}>
							<option value="">해상도를 선택해 주세요.</option>
							{resolution.map((item, i) => (
								<option key={i} value={item.value}>
									{item.label}
								</option>
							))}
						</select>
					</div>
				</Column>
				<Column width={250}>
					<button type="button" className={cx({ "btn-stream": true, start: isStart })} onClick={() => onCastStart()}>
						{isStart ? (
							<>
								<i className="fa fa-stop"></i> 방송종료
							</>
						) : (
							<>
								<i className="fa fa-video-camera"></i> 방송시작
							</>
						)}
					</button>
				</Column>
				<Column width={100}>
					<div className="actions">
						<button type="button" className="btn-monitor" onClick={() => onPreview()}>
							<i className="fa fa-laptop"></i> 미리보기
						</button>

						<CopyToClipboard
							text={getLiveUrl()}
							onCopy={() => {
								if (!isStart) {
									swal({ title: "알림", text: "방송 시작 후 미리보기 가능합니다. " });
									return;
								}

								if (!castData?.idx) {
									swal({ title: "알림", text: "방송 정보가 없습니다." });
									return;
								}

								swal({ title: "알림", text: `URL : ${getLiveUrl()}\n\nLIVE 주소가 복사 되었습니다.` });
							}}
						>
							<button type="button" className="btn-link">
								<i className="fa fa-link"></i> 주소복사
							</button>
						</CopyToClipboard>
					</div>
				</Column>
				<Column></Column>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	border-top: #3b3b3b solid 1px;
	background-color: #242424;
	right: 0px;
	bottom: 0px;
	left: 0px;
	height: 160px;
	z-index: 100;
`;
const Wrapper = styled.div`
	display: flex;
`;
const Column = styled.div`
	${(props) => (props.width ? `width:${props.width}px; flex-basis: ${props.width}px;` : `flex:1;`)}
	margin-top: 9px;
	padding: 0 16px;
	border-right: #4a4a4a solid 1px;
	:last-child {
		border-right: none;
	}

	.wrapper {
		padding: 16px;
		background-color: #2d2d2d;
		border-radius: 3px;

		.title {
			margin: 0px 0 6px;
			padding: 0px;
			color: #dedede;
			font-size: 13px;
			font-weight: normal;
		}

		.form {
			padding: 7px 10px;
			width: 100%;
			font-family: ${(props) => props.theme.fontFamily.regular};
		}
	}

	.btn-stream {
		width: 100%;
		height: 100%;
		background: #e32222;
		color: #ffffff;
		font-size: 22px;
		font-weight: bold;
		border: none;
	}

	.btn-stream.start {
		background: #00788d;
	}
	.actions {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		button {
			color: #ffffff;
			flex: 1;
			border: none;
			font-size: 14px;
		}
		.btn-monitor {
			background: #00788d;
		}
		.btn-link {
			background: #b8b8b8;
		}
	}
`;

const TitleWrapper = styled.div`
	padding: 9px 16px 7px;
	border-bottom: #17191f solid 1px;
	min-height: 24px;

	.title {
		margin: 0;
		padding: 0;
		font-size: 16px;
		line-height: 24px;
		font-weight: normal;
		display: inline-block;
	}

	button {
		margin: -10px 0 0 5px;
		width: 24px;
		height: 24px;

		color: #a3a3a3;
		background: transparent;
		border: none;
		cursor: pointer;
		display: inline-block;
		vertical-align: bottom;
	}

	input {
		border: 1px solid #efefef;
		padding: 8px 10px;
		min-width: 500px;
		vertical-align: bottom;
	}

	.btn-modify {
		border: 1px solid #00788d;
		background: #00788d;
		color: #ffffff;
		padding: 7px 16px;
		vertical-align: middle;
		width: auto;
		height: auto;
	}

	.btn-cancel {
		border: 1px solid #777777;
		background: #ffffff;
		color: #777777;
		padding: 7px 16px;
		vertical-align: middle;
		width: auto;
		height: auto;
	}
`;
