/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../Components/AppProvider";
import { _CFG, _DEF } from "../../Modules/Config";
import { _API, _U, _CK } from "../../Modules/Utils";

import Layout, { Container } from "../Layout";

import { Wrapper, Title, SignUpForm } from "./UI";
import { UInputForm, UFormWrapper, CheckBox, Radio } from "../../UI/Form";
import { getSrcSet } from "../../UI/Assets";

import Dialog from "../../UI/Dialog";

export default (props) => {
    const { location, history, match } = props;
    const { device } = useContext(AppContext);

    const { snsProfile } = useContext(AppContext);

    const [email, setEmail] = useState("");
    const [passwd, setPasswd] = useState("");
    const [passwdRe, setPasswdRe] = useState("");
    const [name, setName] = useState("");
    const [nickName, setNickName] = useState("");
    const [mobile, setMobile] = useState("");
    const [agrEmail, setAgrEmail] = useState(false);
    const [agrSms, setAgrSms] = useState(false);
    const [isCert, setIsCert] = useState(false);
    const [gender, setGender] = useState("");
    const [birthyear, setBirthyear] = useState("");
    const [birthday, setBirthday] = useState("");

    const [allAgree, setAllAgree] = useState(false);
    const [agree, setAgree] = useState({
        terms: false,
        privacy: false,
        minor: false,
        event: false,
    });

    const emailRef = useRef(null);
    const passwdRef = useRef(null);
    const passwdReRef = useRef(null);
    const nameRef = useRef(null);
    const nickNameRef = useRef(null);
    const mobileRef = useRef(null);

    const handleShowTerms = (type, title, e) => {
        e.preventDefault();

        const params = { code1: "user", code2: type.toLowerCase() };
        _API.get({
            path: "/cms",
            data: params,
        })
            .then((res) => res.data)
            .then((data) => {
                Dialog({
                    title,
                    html: data.content,
                });
            });
    };

    const handleSignUp = () => {
        const signUpForForm = () => {
            try {
                if (!email) {
                    throw { message: "이메일을 입력해 주세요.", ref: emailRef };
                }
                if (!_U.isEmail(email)) {
                    throw { message: "올바른 양식의 이메일을 입력해 주세요.", ref: emailRef };
                }
                if (!passwd) {
                    throw { message: "비밀번호를 입력해 주세요.", ref: passwdRef };
                }
                if (!passwdRe) {
                    throw { message: "비밀번호 확인을 입력해 주세요.", ref: passwdReRef };
                }
                if (passwd !== passwdRe) {
                    throw { message: "비밀번호와 비밀번호 확인이 동일 하지 않습니다.", ref: passwdReRef };
                }

                // if (!isCert) {
                //     throw { message: "본인 인증을 진행해 주세요.", ref: nameRef };
                // }

                if (!name) {
                    throw { message: "이름을 입력해 주세요.", ref: nameRef };
                }
                // if (!nickName) {
                //     throw { message: "별명을 입력해 주세요.", ref: nickNameRef };
                // }
                if (!mobile) {
                    throw { message: "휴대폰을 입력해 주세요.", ref: mobileRef };
                }

                if (!agree?.terms) {
                    throw { message: "이용약관에 동의해 주세요." };
                }
                if (!agree?.privacy) {
                    throw { message: "개인정보 수집에 동의해 주세요." };
                }
                if (!agree?.minor) {
                    throw { message: "만14세 이상에 동의해 주세요." };
                }

                const data = { email, passwd, name, nickName, mobile, agrEmail: agrEmail ? "Y" : "N", agrSms: agrSms ? "Y" : "N", gender, birthday };
                _API.post({ path: "/user/join", data }).then((res) => {
                    signupComplete(name, res.data.accessKey);
                });
            } catch (e) {
                swal({ title: "알림", text: e.message, button: "확인" }).then(() => {
                    if (e.ref) {
                        e.ref.current.focus();
                    }
                });
            }
        };

        const signUpForSNS = () => {
            try {
                if (!email) {
                    throw { message: "이메일을 입력해 주세요.", ref: emailRef };
                }
                if (!_U.isEmail(email)) {
                    throw { message: "올바른 양식의 이메일을 입력해 주세요.", ref: emailRef };
                }
                if (!name) {
                    throw { message: "이름을 입력해 주세요.", ref: nameRef };
                }
                // if (!nickName) {
                //     throw { message: "별명을 입력해 주세요.", ref: nickNameRef };
                // }
                if (!mobile) {
                    throw { message: "휴대폰을 입력해 주세요.", ref: mobileRef };
                }

                let tmpBirthday = "";
                if (birthday && birthyear) {
                    tmpBirthday = `${birthyear}${birthday}`;
                } else if (birthday) {
                    tmpBirthday = `0000${birthday}`;
                }

                const data = { ...snsProfile, email, passwd, name, nickName, mobile, agrEmail: agrEmail ? "Y" : "N", agrSms: agrSms ? "Y" : "N", gender, birthday: tmpBirthday };
                _API.post({ path: "/user/join/sns", data }).then((res) => {
                    signupComplete(name);
                });
            } catch (e) {
                swal({ title: "알림", text: e.message, button: "확인" }).then(() => {
                    if (e.ref) {
                        e.ref.current.focus();
                    }
                });
            }
        };
        if (snsProfile) {
            signUpForSNS();
        } else {
            signUpForForm();
        }
    };

    const signupComplete = (name, accessKey) => {
        Dialog({
            title: `가입 완료`,
            body: <Complete {...{ location, history, match, name }} />,
        })
            .then((data) => {
                history.push(`/`);
                setTimeout(() => {
                    _U.postMessage("LOGIN");
                    if (device && device !== "null") {
                        document.location.href = `${_CFG.appSchema}joined?auth=${accessKey}&encoded=${encodeURIComponent(accessKey)}`;
                    }
                }, 500);
            })
            .catch((e) => {
                if (e && e.url) {
                    history.push(e.url);
                }
            });
    };

    // useEffect(() => {
    // 	signupComplete();
    // }, []);

    const onClickCert = () => {
        if (!email) {
            swal({ title: "알림", text: "이메일을 입력해 주세요." });
            return;
        }

        _API.post({ path: "/user/cert", data: { userId: email } }).then((res) => {
            const { online_url: pc_url, mobile_url } = res.data;

            if (isMobile) {
                window.open(mobile_url, "CERT");
            } else {
                window.open(pc_url, "PAYMENT", "width=400,height=600");
            }
        });
    };

    useEffect(() => {
        setEmail(snsProfile?.profile.email);
        setName(snsProfile?.profile.name);
        setNickName(snsProfile?.profile.nickName);
        setGender(snsProfile?.profile.gender);
        if (snsProfile?.profile.mobile) {
            setMobile(snsProfile?.profile.mobile.replace(/-/g, ""));
        }

        if (snsProfile?.profile.birthyear) {
            setBirthyear(snsProfile?.profile.birthyear);
        }

        if (snsProfile?.profile.birthday) {
            setBirthday(snsProfile?.profile.birthday.replace(/-/g, ""));
        }
    }, [snsProfile]);

    const onCertComplete = (data) => {
        if (data.isExsits === "Y") {
            swal({ title: "알림", text: "이미 가입된 회원 정보 입니다." });
        } else {
            setIsCert(true);
            setName(data.name);
            setMobile(data.mobile);
            setGender(data.gender);
            setBirthday(data.birthday);
        }
    };
    const onCertCancel = () => {
        setIsCert(false);
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "CERT_COMPLETE":
                    onCertComplete(data.data);
                    break;
                case "CERT_CANCEL":
                    onCertCancel();
                    break;
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    const onClickAllAgree = () => {
        if (isAllAgree()) {
            setAgree({
                terms: false,
                privacy: false,
                minor: false,
                event: false,
            });
            setAgrEmail(false);
            setAgrSms(false);
        } else {
            setAgree({
                terms: true,
                privacy: true,
                minor: true,
                event: true,
            });
            setAgrEmail(true);
            setAgrSms(true);
        }
    };

    const isAllAgree = () => {
        if (agree.terms && agree.privacy && agree.minor && agrEmail && agrSms) {
            return true;
        }

        return false;
    };

    const onClickAgree = (key) => {
        switch (key) {
            case "event":
                setAgrEmail(!agree[key]);
                setAgrSms(!agree[key]);
                break;
        }
        setAgree({ ...agree, [key]: !agree[key] });
    };

    const isEventAgree = () => {
        if (agrEmail || agrSms) {
            return true;
        }

        return false;
    };

    if (_CK.isLogin()) {
        history.push("/");
        return null;
    }

    return (
        <Layout {...props}>
            <Container>
                <Wrapper>
                    <Title>모아 회원 회원가입</Title>
                    <SignUpForm>
                        <UInputForm
                            ref={emailRef}
                            title="이메일"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="이메일을 입력해 주세요."
                            require={true}
                        />
                        {!snsProfile && (
                            <Fragment>
                                <UInputForm
                                    ref={passwdRef}
                                    title="비밀번호"
                                    name="passwd"
                                    type="password"
                                    value={passwd}
                                    onChange={(e) => setPasswd(e.target.value)}
                                    placeholder="비밀번호를 입력해 주세요."
                                    require={true}
                                />
                                <UInputForm
                                    ref={passwdReRef}
                                    title="비밀번호 확인"
                                    name="passwdRe"
                                    type="password"
                                    value={passwdRe}
                                    onChange={(e) => setPasswdRe(e.target.value)}
                                    placeholder="비밀번호를 한번 더 입력해 주세요."
                                    require={true}
                                />
                            </Fragment>
                        )}
                        {/* {!snsProfile && (
							<MobileCertWrapper>
								<button type="button" className="reqCert" onClick={() => onClickCert()}>
									본인인증
								</button>
								<div className="text">
									· <span className="black">본인 인증 후 회원가입이 가능합니다.</span>
								</div>
							</MobileCertWrapper>
						)} */}
                        <UInputForm
                            ref={nameRef}
                            title="이름"
                            name="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="이름을 입력해 주세요."
                            readOnly={isCert}
                            require={true}
                        />
                        {/* <UInputForm ref={nickNameRef} title="별명" name="nickName" type="text" value={nickName} onChange={(e) => setNickName(e.target.value)} placeholder="별명을 입력해 주세요." /> */}
                        <UInputForm
                            ref={mobileRef}
                            title="휴대폰"
                            name="mobile"
                            type="text"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            placeholder="휴대폰 번호를 입력해 주세요."
                            readOnly={isCert}
                            require={true}
                        />

                        {!snsProfile ? (
                            <UInputForm
                                title="생년월일"
                                name="birthday"
                                type="number"
                                value={birthday}
                                onChange={(e) => {
                                    if (e.target.value.length > 8) {
                                        e.target.value = e.target.value.substr(0, 8);
                                    }
                                    setBirthday(e.target.value);
                                }}
                                placeholder="8자리 숫자 입력(19990101)"
                            />
                        ) : (
                            <>
                                <UInputForm
                                    title="출생년도"
                                    name="birthyear"
                                    type="number"
                                    value={birthyear}
                                    onChange={(e) => {
                                        if (e.target.value.length > 4) {
                                            e.target.value = e.target.value.substr(0, 4);
                                        }
                                        setBirthyear(e.target.value);
                                    }}
                                    placeholder="4자리 숫자 입력(1999)"
                                />
                                <UInputForm
                                    title="생일"
                                    name="birthday"
                                    type="number"
                                    value={birthday}
                                    onChange={(e) => {
                                        if (e.target.value.length > 4) {
                                            e.target.value = e.target.value.substr(0, 4);
                                        }
                                        setBirthday(e.target.value);
                                    }}
                                    placeholder="4자리 숫자 입력(0101)"
                                />
                            </>
                        )}

                        <UFormWrapper title="성별">
                            <Radio title="남" value="M" checked={gender === "M"} onClick={() => setGender("M")} inline style={{ marginTop: 12, marginRight: 23 }} />
                            <Radio title="여" value="F" checked={gender === "F"} onClick={() => setGender("F")} inline style={{ marginTop: 12 }} />
                        </UFormWrapper>
                        <div className="split"></div>

                        <div className="agreement" onClick={() => onClickAllAgree()}>
                            <CheckBox title="" value="Y" checked={isAllAgree()} inline style={{ marginRight: 5 }} />
                            <label className="bold">모든 약관에 동의합니다.</label>
                        </div>
                        <div className="agreement">
                            <CheckBox title="" value="Y" checked={agree?.terms} inline style={{ marginRight: 5 }} onClick={() => onClickAgree("terms")} />
                            <label onClick={() => onClickAgree("terms")}>이용약관 동의(필수)</label>
                            <small onClick={handleShowTerms.bind(this, "TERMS", "이용약관")}>내용보기</small>
                        </div>
                        <div className="agreement">
                            <CheckBox title="" value="Y" checked={agree?.privacy} inline style={{ marginRight: 5 }} onClick={() => onClickAgree("privacy")} />
                            <label onClick={() => onClickAgree("privacy")}>개인정보 수집 동의(필수)</label>
                            <small onClick={handleShowTerms.bind(this, "PRIVACY", "개인정보 처리방침")}>내용보기</small>
                        </div>
                        <div className="agreement" onClick={() => onClickAgree("minor")}>
                            <CheckBox title="" value="Y" checked={agree?.minor} inline style={{ marginRight: 5 }} />
                            <label>만 14세 이상입니다.(필수)</label>
                        </div>
                        <div className="agreement">
                            <CheckBox title="" value="Y" checked={isEventAgree()} inline style={{ marginRight: 5 }} onClick={() => onClickAgree("event")} />
                            <label onClick={() => onClickAgree("event")}>상품/이벤트 및 프로모션 알림 동의(선택)</label>
                            <small onClick={handleShowTerms.bind(this, "EVENT", "상품/이벤트 및 프로모션")}>내용보기</small>
                            <div className="desc">
                                <CheckBox title="이메일" value="Y" checked={agrEmail} onClick={() => setAgrEmail(!agrEmail)} inline style={{ marginTop: 12, marginRight: 23 }} />
                                <CheckBox title="SMS" value="Y" checked={agrSms} onClick={() => setAgrSms(!agrSms)} inline style={{ marginTop: 12 }} />
                                <p>
                                    상품/이벤트 및 프로모션 수신동의를 하시면 상품 및 이벤트 정보를 받으실 수 있습니다.
                                    <br />
                                    서비스의 중요 안내사항 및 주문/배송에 대한 정보는 위 수신 여부와 상관없이 발송됩니다.
                                </p>
                            </div>
                        </div>

                        {/* <TermWrapper>
							가입하시면 모아의{" "}
							<a href={void 0} onClick={handleShowTerms.bind(this, "TERMS", "이용약관")}>
								이용약관
							</a>
							과{" "}
							<a href={void 0} onClick={handleShowTerms.bind(this, "PRIVACY", "개인정보 처리방침")}>
								개인정보 처리방침
							</a>
							에 동의하게 됩니다.
						</TermWrapper> */}
                        <button type="button" className="signUp" onClick={handleSignUp}>
                            가입하기
                        </button>
                    </SignUpForm>
                </Wrapper>
            </Container>
        </Layout>
    );
};

const MobileCertWrapper = styled.div`
    margin: 0 0 12px 90px;
    button.reqCert {
        padding: 8px 16px 7px;
        border: solid 1px #efefef;
        background-color: #fff;
        font-size: 13px;
    }

    .text {
        margin-top: 7px;
        font-size: 10px;
        font-weight: normal;
        line-height: 1.5;
        color: #b8b8b8;

        span.black {
            color: #3d3d3d;
        }
        span.red {
            color: #e32222;
        }
    }
`;

const TermWrapper = styled.div`
    margin-top: 40px;
    font-size: 10px;
    font-weight: normal;
    color: #b8b8b8;
    text-align: center;

    a {
        color: #3d3d3d;
        text-decoration: underline;
    }
    a:hover {
        color: #e32222;
    }
`;

const Complete = (props) => {
    const { resolve, reject } = props;

    return (
        <CWrapper>
            <img src={`/images/signup_complete.png`} srcSet={getSrcSet([`/images/signup_complete.png`, `/images/signup_complete@2x.png`, `/images/signup_complete@3x.png`])} />
            <div>
                <b>가입이 완료되었습니다.</b>
                <p>
                    <b>{props.name}</b> 고객님, 추가 정보를 입력하시고 고객님만을 위한
                    <br /> 모아가 준비한 <b className="red">혜택</b>을 만나보세요.
                </p>
            </div>
            <div className="buttons">
                <button className="on" onClick={() => resolve()}>
                    로그인
                </button>
            </div>
        </CWrapper>
    );
};

const CWrapper = styled.div`
    text-align: center;
    position: relative;
    padding-bottom: 100px;

    img {
        width: 82px;
        height: 72px;
    }

    > div {
        margin-top: 24px;
    }

    > b {
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #3d3d3d;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
    }

    p {
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: #3d3d3d;
        font-size: 13px;
        line-height: 18px;
        width: 300px;
        margin: 12px auto;

        b {
            font-family: ${(props) => props.theme.fontFamily.medium};
            font-weight: normal;
        }

        b.red {
            color: #e32222;
        }
    }

    div.buttons {
        position: absolute;
        left: -30px;
        bottom: -30px;
        width: calc(100% + 60px);

        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            display: flex;
            margin-top: 0px;
            left: 0;
        }

        button.on {
            height: 60px;
            width: 100%;
            font-family: ${(props) => props.theme.fontFamily.regular};
            color: #ffffff;
            font-size: 14px;
            line-height: 20px;
            background: #e32222;
            border: 0;
        }
    }
`;
