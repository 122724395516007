/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import { getSrcSet } from "../../UI/Assets";

export default (props) => {
	const { onClick } = props;
	const [list, setList] = useState([]);

	const loadChannels = () => {
		_API
			.get({ path: "/cast/channels" })
			.then((res) => res.data)
			.then((data) => {
				console.log(data);
				setList(data);
			});
	};

	useEffect(() => {
		loadChannels();
		const timer = setInterval(() => {
			loadChannels();
		}, 5000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<Container>
			<Wrapper>
				<h3 className="title">
					<i className="fa fa-video-camera"></i> Live Cams
				</h3>
				<div className="list-wrapper">
					{list.length === 0 && <Dummy />}
					{list.map((item, i) => (
						<Camera key={i} {...item} onClick={onClick} />
					))}
				</div>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	width: 200px;
	flex-basis: 200px;
	background: #333333;
	border-right: #3b3b3b solid 1px;
`;
const Wrapper = styled.div`
	width: 100%;
	height: 100%;

	.title {
		margin: 16px 0;
		padding: 0px;
		font-size: 14px;
		text-align: center;
		font-weight: normal;
	}

	.list-wrapper {
		width: 100%;
		height: calc(100% - 52px);
		overflow-x: hidden;
		overflow-y: auto;

		::-webkit-scrollbar {
			width: 3px;
		}
		::-webkit-scrollbar-track {
			background-color: #636e72;
			/* border-radius: 3px; */
		}
		::-webkit-scrollbar-thumb {
			background: #b2bec3;
			/* border-radius: 3px; */
		}
	}
`;

const Camera = (props) => {
	const { streamId, url, onClick } = props;
	return (
		<CContainer>
			<CWrapper>
				<div className="camera" onClick={onClick.bind(this, streamId)}>
					<img src={url} />
				</div>
			</CWrapper>
		</CContainer>
	);
};

const Dummy = (props) => {
	return (
		<CContainer>
			<CWrapper>
				<div className="dummy">
					<img src="/images/cast_cam_info.png" srcSet={getSrcSet(["/images/cast_cam_info.png", "/images/cast_cam_info@2x.png", "/images/cast_cam_info@3x.png"])} />
					<h4 className="title">대기중...</h4>
				</div>
			</CWrapper>
		</CContainer>
	);
};

const CContainer = styled.div`
	margin: 16px 20px 0;
	padding: 0 0 16px;
	border-bottom: #4a4a4a solid 1px;
	:first-child {
		margin-top: 0;
	}
	:last-child {
		border-bottom: none;
	}
`;
const CWrapper = styled.div`
	width: 160px;
	height: 121px;
	border-radius: 5px;
	overflow: hidden;

	.camera {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		cursor: pointer;
	}

	.dummy {
		position: relative;
		padding: 12px;
		background: #000000;

		img {
			width: 136px;
			height: 97px;
		}

		.title {
			position: absolute;
			right: 0;
			bottom: 22px;
			left: 0;
			margin: 0;
			padding: 0;
			font-size: 12px;
			font-weight: normal;
		}
	}
`;
