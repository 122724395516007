/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ImageMagnify from "react-image-magnify";
import cx from "classnames";
import VideoJs from "video.js";
import swal from "sweetalert";
import { isMobile, isIOS } from "react-device-detect";
// import Slider from "react-slick";
import Slider from "react-slick-ios-fix";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AppContext } from "../../Components/AppProvider";

import { _DEF } from "../../Modules/Config";
import { _U, _API, _CK } from "../../Modules/Utils";
import Dialog from "../../UI/Dialog";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import Login from "../Layout.Login";

import SizeGuide from "./View.info.Guide";

export default (props) => {
	const [categories, setCategories] = useState(null);

	useEffect(() => {
		if (props.categories) {
			setCategories(props.categories);
		}
	}, [props.categories]);

	const getGuideImage = () => {
		if (_U.selectGuide(categories, { main: "여성", sub: ["의류"] })) {
			return <img src="/images/woman_clothes.png" srcSet={getSrcSet(["images/woman_clothes.png", "images/woman_clothes@2x.png", "images/woman_clothes@3x.png"])} />;
		} else if (_U.selectGuide(categories, { main: "여성", sub: ["슈즈", "신발"] })) {
			return <img src="/images/woman_shoes.png" srcSet={getSrcSet(["images/woman_shoes.png", "images/woman_shoes@2x.png", "images/woman_shoes@3x.png"])} />;
		} else if (_U.selectGuide(categories, { main: "남성", sub: ["의류"] })) {
			return <img src="/images/man_clothes.png" srcSet={getSrcSet(["images/man_clothes.png", "images/man_clothes@2x.png", "images/man_clothes@3x.png"])} />;
		} else if (_U.selectGuide(categories, { main: "남성", sub: ["슈즈", "신발"] })) {
			return <img src="/images/man_shoes.png" srcSet={getSrcSet(["images/man_shoes.png", "images/man_shoes@2x.png", "images/man_shoes@3x.png"])} />;
		} else if (_U.selectGuide(categories, { main: "", sub: ["액세서리", "악세서리"] })) {
			return <img src="/images/accessory.png" srcSet={getSrcSet(["images/accessory.png", "images/accessory@2x.png", "images/accessory@3x.png"])} />;
		}
	};

	if (!categories) {
		return "";
	}

	return (
		<Wrapper>
			<h1>SIZE GUIDE</h1>
			<ul>
				<li className={cx({ active: _U.selectGuide(categories, { main: "여성", sub: ["의류"] }) })} onClick={() => setCategories([{ label: "여성" }, { label: "의류" }])}>
					<div>여성의류</div>
				</li>
				<li className={cx({ active: _U.selectGuide(categories, { main: "여성", sub: ["슈즈", "신발"] }) })} onClick={() => setCategories([{ label: "여성" }, { label: "슈즈" }])}>
					<div>여성슈즈</div>
				</li>
				<li className={cx({ active: _U.selectGuide(categories, { main: "남성", sub: ["의류"] }) })} onClick={() => setCategories([{ label: "남성" }, { label: "의류" }])}>
					<div>남성의류</div>
				</li>
				<li className={cx({ active: _U.selectGuide(categories, { main: "남성", sub: ["슈즈", "신발"] }) })} onClick={() => setCategories([{ label: "남성" }, { label: "슈즈" }])}>
					<div>남성슈즈</div>
				</li>
				<li className={cx({ active: _U.selectGuide(categories, { main: "", sub: ["액세서리", "악세서리"] }) })} onClick={() => setCategories([{ label: "액세서리" }])}>
					<div>액세서리</div>
				</li>
				{isMobile && <li></li>}
			</ul>
			<div className="img-box">{getGuideImage()}</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 15px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0px;
	}

	h1 {
		font-family: ${(props) => props.theme.fontFamily.GmarketSansBold};
		color: #3d3d3d;
		font-size: 38px;
		line-height: 43px;
		margin: 0 auto;
		padding: 0;
		text-align: center;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			font-size: 30px;
			line-height: 35px;
		}
	}

	ul {
		list-style: none;
		padding: 0 20px;
		margin: 0;
		display: flex;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		width: calc(100% - 40px);
		height: 52px;
		border-radius: 7px;
		margin-top: 40px;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-wrap: wrap;
			min-height: 79px;
			height: auto;
			padding: 0 10px;
			width: calc(100% - 20px);
			margin-top: 30px;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
		}
	}

	ul > li {
		flex: 1;
		height: 100%;
		font-family: ${(props) => props.theme.fontFamily.regular};
		color: #acacac;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			flex-basis: 33.3%;
			height: 38px;
		}
	}

	ul > li.active {
		position: relative;
		div {
			position: relative;
			z-index: 2;
			top: -20px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #ffffff;
			height: 38px;
			border-radius: 7px;
			box-shadow: 0 2px 5px 2.5px rgba(0, 0, 0, 0.16);
			background-color: #00788d;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
				top: -6px;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			:nth-child(4),
			:nth-child(5) {
				div {
					top: unset;
					bottom: -6px;
				}
			}
		}
		/* 
		:before {
			content: "";
			position: absolute;
			z-index: 1;
			height: 45px;
			background: #ffffff;
			width: 107%;
			border-radius: 7px;
			top: -13px;
		} */
	}

	div.img-box {
		margin-top: 40px;
		text-align: center;
		width: 100%;
		img {
			max-width: 100%;
		}

		max-height: 600px;
		overflow-y: auto;

		::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}
		::-webkit-scrollbar-track {
			background-color: #efefef;
			border-radius: 8px;
		}
		::-webkit-scrollbar-thumb {
			background: #3d3d3d;
			border-radius: 8px;
		}

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			width: calc(100vw - 40px);
			max-height: calc(100vh - 245px);
			overflow-x: auto;
			img {
				max-width: unset;
			}
		}
	}
`;
