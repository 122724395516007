import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";

import "font-awesome/css/font-awesome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video.js/dist/video-js.min.css";
import "../Resources/style.css";

const theme = {
	color: {},
	layout: {
		width: 1194,
		productWidth: 1020,
		reviewWidth: 990,
		mobileWith: 918,
	},
	fontFamily: {
		regular: "Noto Sans KR;font-weight:400 !important",
		bold: "Noto Sans KR;font-weight:700 !important",
		medium: "Noto Sans KR;font-weight:500 !important",
		light: "Noto Sans KR;font-weight:300 !important",
		// regular: "NotoSans-R",
		// bold: "NotoSans-B",
		// medium: "NotoSans-M",
		// light: "NotoSans-L",
		nanumB: "NanumSquareB",
		nanumEB: "NanumSquareEB",
		nanumL: "NanumSquareL",
		nanumR: "NanumSquareR",
		SBAggroB: "SBAggroB",
		SBAggroM: "SBAggroM",
		SBAggroL: "SBAggroL",
		GmarketSansBold: "GmarketSansBold",
		GmarketSansMedium: "GmarketSansMedium",
		GmarketSansLight: "GmarketSansLight",
	},
};

const globalStyle = createGlobalStyle`
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none !important;
    }
    body {
        font-family: "Noto Sans KR";
        margin: 0;
        padding: 0;
        font-size:13px;
    }
    input, textarea {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    button, a {
        cursor:pointer;
    }

    .swal-modal {
        border-radius: 0px;
     
        .swal-title {
            margin:30px 30px 17px;
            padding:0 0 16px;
            font-family: ${theme.fontFamily.medium};
            font-size: 16px;
            font-weight: 500;
            color:#3d3d3d;
            text-align: left;
            border-bottom: #efefef solid 1px;
        }
        .swal-text {
            margin:0 30px;
            padding:0;
            display:block;
            font-size: 12px;
            font-weight: 300;
            color: #777;
        }

        .swal-footer {
            margin:17px 30px 30px;
            padding:0;

            .swal-button-container {
                margin:0;

                .swal-button {
                    border-radius: 0;
                    color: #ffffff;
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    font-size: 14px;
                }
                .swal-button:focus {
                    box-shadow: none;
                }
                .swal-button--confirm {
                    background: #00788d;
                }
                .swal-button--cancel {
                    background: #b8b8b8;
                }
            }
        }
    }

`;

export const Theme = theme;
export const ThemeProvider = Provider;
export default globalStyle;
