/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import cx from "classnames";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { SelectBox } from "../../UI/Form";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

export default (props) => {
	const { title, more, font } = props;

	const count = 6;

	const onNextClick = () => {};

	const onPrevClick = () => {};

	const CustomNextArrow = (props) => {
		return (
			<div className="sd-paging sd-next">
				<button type="button" onClick={onNextClick}>
					<img src="/icons/ic_next.png" srcSet={getSrcSet(["/icons/ic_next.png", "/icons/ic_next@2x.png", "/icons/ic_next@3x.png"])} alt="" />
				</button>
			</div>
		);
	};

	const CustomPrevArrow = (props) => {
		return (
			<div className="sd-paging sd-prev" onClick={onPrevClick}>
				<button type="button">
					<img src="/icons/ic_prev.png" srcSet={getSrcSet(["/icons/ic_prev.png", "/icons/ic_prev@2x.png", "/icons/ic_prev@3x.png"])} alt="" />
				</button>
			</div>
		);
	};

	const sliderSetting = {
		dots: false,
		infinite: count > 5 ? true : false,
		autoplay: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		nextArrow: count > 5 ? <CustomNextArrow /> : null,
		prevArrow: count > 5 ? <CustomPrevArrow /> : null,
	};

	return (
		<PSWrapper font={font}>
			<h2>
				{title}
				{more && <div className="more">전체보기</div>}
			</h2>
			<Slider {...sliderSetting}>
				<RPItem style={{ width: "187px" }}>
					<img src={"/images/temp/product_01.png"} />
					<div className="info">
						<div className="brand">마르니1</div>
						<h4 className="title">샤넬 트렌디 CC 미니 플랩 백 캐비어 금장 블랙 AS2711 B06454 94305</h4>
						<div className="price">
							<div className="real-price">
								9,020,000<span>원</span> <b>10%</b>
							</div>
							<div className="original-price">
								10,000,000<span>원</span>
							</div>
						</div>
					</div>
				</RPItem>
				<RPItem>
					<img src={"/images/temp/product_01.png"} />
					<div className="info">
						<div className="brand">마르니2</div>
						<h4 className="title">샤넬 트렌디 CC 미니 플랩 백 캐비어 금장 블랙 AS2711 B06454 94305</h4>
						<div className="price">
							<div className="real-price">
								9,020,000<span>원</span> <b>10%</b>
							</div>
							<div className="original-price">
								10,000,000<span>원</span>
							</div>
						</div>
					</div>
				</RPItem>
				<RPItem>
					<img src={"/images/temp/product_01.png"} />
					<div className="info">
						<div className="brand">마르니3</div>
						<h4 className="title">샤넬 트렌디 CC 미니 플랩 백 캐비어 금장 블랙 AS2711 B06454 94305</h4>
						<div className="price">
							<div className="real-price">
								9,020,000<span>원</span> <b>10%</b>
							</div>
							<div className="original-price">
								10,000,000<span>원</span>
							</div>
						</div>
					</div>
				</RPItem>
				<RPItem>
					<img src={"/images/temp/product_01.png"} />
					<div className="info">
						<div className="brand">마르니4</div>
						<h4 className="title">샤넬 트렌디 CC 미니 플랩 백 캐비어 금장 블랙 AS2711 B06454 94305</h4>
						<div className="price">
							<div className="real-price">
								9,020,000<span>원</span> <b>10%</b>
							</div>
							<div className="original-price">
								10,000,000<span>원</span>
							</div>
						</div>
					</div>
				</RPItem>
				<RPItem>
					<img src={"/images/temp/product_01.png"} />
					<div className="info">
						<div className="brand">마르니5</div>
						<h4 className="title">샤넬 트렌디 CC 미니 플랩 백 캐비어 금장 블랙 AS2711 B06454 94305</h4>
						<div className="price">
							<div className="real-price">
								9,020,000<span>원</span> <b>10%</b>
							</div>
							<div className="original-price">
								10,000,000<span>원</span>
							</div>
						</div>
					</div>
				</RPItem>
				<RPItem>
					<img src={"/images/temp/product_01.png"} />
					<div className="info">
						<div className="brand">마르니6</div>
						<h4 className="title">샤넬 트렌디 CC 미니 플랩 백 캐비어 금장 블랙 AS2711 B06454 94305</h4>
						<div className="price">
							<div className="real-price">
								9,020,000<span>원</span> <b>10%</b>
							</div>
							<div className="original-price">
								10,000,000<span>원</span>
							</div>
						</div>
					</div>
				</RPItem>
			</Slider>
		</PSWrapper>
	);
};

const PSWrapper = styled.div`
	margin-top: ${(props) => (props.marginTop ? props.marginTop : `60`)}px;
	.slick-track {
		margin-left: 0;
	}

	.slick-initialized .slick-slide {
		margin-right: 20px;
	}

	h2 {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: ${(props) => (props.font ? props.font : `20`)}px;
		color: #3d3d3d;
		line-height: 29px;
		font-weight: normal;
		position: relative;
		margin: 0px;
		margin-bottom: 12px;
	}

	h2 > div.more {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 13px;
		color: #777777;
		font-weight: normal;
		line-height: 18px;
		position: absolute;
		right: 0;
		top: 5px;
	}

	.slick-slider {
		position: relative;
	}
	.slick-slider .sd-paging {
		position: absolute;
		z-index: 2;
	}
	.slick-slider .sd-paging.sd-prev {
		left: 0;
		top: 117px;
	}
	.slick-slider .sd-paging.sd-next {
		right: 4px;
		top: 117px;
	}

	.slick-slider .sd-paging > button {
		border: 0px;
		background: none;
		padding: 0;
		cursor: pointer;

		img {
			width: 40px;
			height: 40px;
		}
	}
`;

const RPItem = styled.div`
	width: 187px;
	height: 273px;
	border: 1px solid #efefef;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;

	img {
		width: 187px;
		height: 187px;
		object-fit: cover;
		text-align: center;
	}

	div.info {
		padding: 15px 9px;
	}

	div.info > .brand {
		font-family: ${(props) => props.theme.fontFamily.regular};
		font-size: 10px;
		color: #777777;
		line-height: 15px;
		font-weight: normal;
	}

	div.info > h4.title {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 10px;
		color: #777777;
		line-height: 15px;
		font-weight: normal;
		margin: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
	}

	div.info > .price > .real-price {
		font-family: ${(props) => props.theme.fontFamily.medium};
		font-size: 13px;
		color: #3d3d3d;
		line-height: 17px;
		font-weight: normal;

		b {
			font-size: 9px;
			color: #e32222;
			line-height: 10px;

			background-image: url("/icons/ic_discount.png");
			background-image: -webkit-image-set(${getBackgroundSet(["/icons/ic_discount.png", "/icons/ic_discount@2x.png", "/icons/ic_discount@3x.png"])});
			background-repeat: no-repeat;
			background-size: 6px;
			background-position: left 6px;
			padding-left: 8px;
			margin-left: 5px;
		}
	}

	div.info > .price > .original-price {
		color: #dedede;
		font-size: 13px;
		font-family: ${(props) => props.theme.fontFamily.medium};
		line-height: 17px;
		text-decoration: line-through;
	}
`;
