/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import Moment from "react-moment";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import Chatting from "./Mobile.Chatting";

import Dialog from "../../UI/Dialog";

import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";
import { tryToPlay } from "../../Components/AntMedia/fetch.stream.js";

const _SIZE = { width: 720, height: 1280 };
const _VIDEO_ID = `VIDEO-VIEWER-${UUIDV4()}`;

const hlsExtension = "m3u8";

export default (props) => {
	const { roomIdx, streamId: streamIdInProps, poster, socketConnect, setSocketConnect, roomData } = props;
	const { auth, iam } = useContext(AppContext);

	const videoRef = useRef(null);
	const [adapter, setAdapter] = useState(null);
	const [streamId, setStreamId] = useState(null);
	const [message, setMessage] = useState("");
	const [isSendProc, setIsSendProc] = useState(false);

	const play = async (id) => {
		try {
			if (adapter) {
				await stopWebRTCStreaming(adapter, streamId);
			}
			startWebRTCStreaming(id).then((adapter) => {
				setStreamId(id);
				setAdapter(adapter);
			});
		} catch (e) {
			swal({ title: "오류", text: e.message || e.name || e.data });
		}
	};

	useEffect(() => {
		if (streamIdInProps) {
			if (streamId !== streamIdInProps) {
				play(streamIdInProps);
			}
		}
	}, [streamIdInProps]);

	const onKeyUp = (e) => {
		if (e.key === "Enter" && !isSendProc) {
			onSend();
		}
	};

	const onSend = () => {
		if (!iam || !iam.idx) {
			// swal({ title: "알림", text: "로그인 후 이용가능합니다." });
			_U.postMessage("LOGIN");
			return;
		}
		if (!socketConnect) {
			swal({ title: "알림", text: "채팅서버에 연결중입니다.\n잠시후 다시연결해 주세요." });
			return;
		}
		if (!message) {
			return;
		}
		if (isSendProc) {
			return;
		}
		setIsSendProc(true);

		_U.postMessage("SOCKET-SEND", {
			action: "CHAT",
			data: {
				mode: "CHAT",
				id: iam?.code + "_" + iam?.idx,
				code: iam?.code,
				idx: iam?.idx,
				name: iam?.name || "익명",
				nickName: iam?.nickName || "",
				roomIdx,
				message,
			},
		});

		setMessage("");
		setIsSendProc(false);
	};

	const onChattingClick = () => {
		if (!auth) {
			_U.postMessage("LOGIN");
		}
	};

	return (
		<Container>
			<div className="video-wrapper">
				<video ref={videoRef} id={_VIDEO_ID} autoPlay width={_SIZE.width} height={_SIZE.height} muted controls poster={poster} playsInline />
				<Chatting roomIdx={roomIdx} roomData={roomData} setSocketConnect={setSocketConnect} socketConnect={socketConnect} isPc={true} />
			</div>
			<div className="chatting-wrapper">
				<SenderWrapper>
					{/* <h3 className="name">{iam?.name}</h3> */}
					<div className="form">
						<input
							type="text"
							placeholder={!auth ? `로그인이 필요합니다.` : `내용을 입력해 주세요`}
							value={message || ""}
							onChange={(e) => setMessage(e.target.value)}
							onKeyUp={onKeyUp}
							readOnly={!auth}
							onClick={() => onChattingClick()}
						/>
						<button type="button" onClick={onSend}>
							보내기
						</button>
					</div>
				</SenderWrapper>
			</div>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	width: 480px;

	.video-wrapper {
		position: relative;
		padding-top: 177.7777%;
		width: 100%;

		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000000;
		}

		video[poster] {
			object-fit: cover;
		}
	}
`;

const SenderWrapper = styled.div`
	padding: 8px 16px;
	border: #e2e2e2 solid 1px;
	background: #efefef;
	display: flex;

	.name {
		font-size: 14px;
		margin: 0px;
		padding: 0px;
		flex-basis: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.form {
		flex: 1;
		display: flex;
		input {
			flex: 1;
			font-family: ${(props) => props.theme.fontFamily.regular};
			padding: 5px 10px;
			border: #e2e2e2 solid 1px;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
			background: #ffffff;
			border-right: 0;
		}
		button {
			width: 80px;
			height: 48px;
			font-size: 14px;
			font-family: ${(props) => props.theme.fontFamily.medium};
			color: #3d3d3d;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
			background: #ffffff;
			border: #e2e2e2 solid 1px;
			border-left: 0;
		}
	}
`;

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (streamId, token) => {
	console.log(`startWebRTCStreaming`, streamId, token);
	return new Promise((resolve, reject) => {
		try {
			const adapter = new WebRTCAdaptor({
				websocket_url: _CFG.getStreamUrl(),
				mediaConstraints: { video: false, audio: false },
				peerconnection_config: null,
				sdp_constraints: {
					OfferToReceiveAudio: false,
					OfferToReceiveVideo: false,
				},
				remoteVideoId: _VIDEO_ID,
				isPlayMode: true,
				debug: true,
				callback: (info, description) => {
					switch (info) {
						case "initialized":
							adapter.getStreamInfo(streamId);
							break;
						case "streamInformation":
							adapter.play(streamId);
							break;
						case "play_started":
							_U.postMessage("PLAY-STARTED");
							break;
						case "play_finished":
							_U.postMessage("PLAY-ENDED");
							break;
						case "closed":
							if (typeof description != "undefined") {
								console.log("Connecton closed: " + JSON.stringify(description));
							}
							break;
						case "bitrateMeasurement":
							break;
					}
				},
				callbackError: (err) => {
					_U.postMessage("STREAMING-ERROR", err);
					switch (err) {
						case "no_stream_exist":
							break;
						case "notSetRemoteDescription":
							tryToPlay(streamId, token, hlsExtension);
							break;
						case "already_playing":
							break;
						default:
							_U.postMessage("PLAY-ERROR", err);
							break;
					}
				},
			});
			resolve(adapter);
		} catch (e) {
			reject(e);
		}
	});
};

const stopWebRTCStreaming = (adapter, streamId) => {
	adapter.stop(streamId);
};
