/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import { ThemeProvider, Theme } from "../Components/GlobalStyles";
import { getSrcSet } from "./Assets";

const _CONTAINER_ID = "MOAA-DIALOG-CONTAINER";
let _CONTAINER = null;

export default (props) => {
    if (!_CONTAINER) {
        _CONTAINER = document.getElementById(_CONTAINER_ID);
    }
    if (!_CONTAINER) {
        _CONTAINER = document.createElement("div");
        _CONTAINER.id = _CONTAINER_ID;
        document.body.appendChild(_CONTAINER);
    }

    return new Promise((resolve, reject) => {
        try {
            ReactDOM.render(<Dialog {...props} resolve={resolve} reject={reject} />, _CONTAINER);
        } catch (e) {
            reject(e);
        }
    });
};

const Dialog = (props) => {
    const { resolve, reject } = props;
    const { closeOnClickOutside } = props;
    const { title, text, html, body, button, width, padding, backgroundColor, closeBtn } = props;

    const dismiss = () => {
        document.body.removeChild(_CONTAINER);
        _CONTAINER = null;
    };

    const handleClose = () => {
        dismiss();
        resolve && resolve();
    };

    const handleContainerClicked = () => {
        if (closeOnClickOutside === false) {
            return;
        }
        dismiss();
        resolve && resolve();
    };

    const handleConfirmClick = () => {
        dismiss();
        resolve && resolve(true);
    };
    const handleButtonClick = (index) => {
        dismiss();
        if (index === button.length - 1) {
            resolve && resolve(true);
        } else {
            resolve && resolve(index);
        }
    };

    const handleOnResolve = (args) => {
        dismiss();
        resolve(args);
    };

    const handleOnReject = (args) => {
        dismiss();
        reject(args);
    };

    return (
        <ThemeProvider theme={Theme}>
            <Container onClick={handleContainerClicked}>
                <Wrapper onClick={(e) => e.stopPropagation()} width={width} padding={padding} backgroundColor={backgroundColor}>
                    <Close onClick={handleClose}>
                        {isMobile ? (
                            <img
                                src={closeBtn === "WHITE" ? "/icons/ic_close.png" : "/icons/ic_remove.png"}
                                srcSet={
                                    closeBtn === "WHITE"
                                        ? getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])
                                        : getSrcSet(["/icons/ic_remove.png", "/icons/ic_remove@2x.png", "/icons/ic_remove@3x.png"])
                                }
                                alt="닫기"
                                width="22"
                                height="22"
                            />
                        ) : (
                            <img src="/icons/ic_close.png" srcSet={getSrcSet(["/icons/ic_close.png", "/icons/ic_close@2x.png", "/icons/ic_close@3x.png"])} alt="닫기" width="22" height="22" />
                        )}
                    </Close>

                    {title && <Header>{title}</Header>}
                    <CWRapper backgroundColor={backgroundColor}>
                        {text && <Text>{text}</Text>}
                        {html && <Text dangerouslySetInnerHTML={{ __html: html }} />}
                        {body && React.cloneElement(body, { resolve: handleOnResolve, reject: handleOnReject })}
                        {typeof button === "boolean" && button && (
                            <Footer>
                                <div className="confirm">
                                    <button type="button" onClick={handleConfirmClick}>
                                        확인
                                    </button>
                                </div>
                            </Footer>
                        )}
                        {typeof button === "string" && button && (
                            <Footer>
                                <div className="confirm">
                                    <button type="button" onClick={handleConfirmClick}>
                                        {button}
                                    </button>
                                </div>
                            </Footer>
                        )}
                        {typeof button === "object" && button && (
                            <Footer>
                                <div className="choose">
                                    {button.map((b, i) => (
                                        <button key={i} type="button" onClick={handleButtonClick.bind(this, i)}>
                                            {b}
                                        </button>
                                    ))}
                                </div>
                            </Footer>
                        )}
                    </CWRapper>
                </Wrapper>
            </Container>
        </ThemeProvider>
    );
};

/**
 * Styled
 */

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9998;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    position: relative;
    padding: ${(props) => (props.padding != undefined && props.padding != "" ? props.padding : "30")}px;
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : `#ffffff`)};
    width: ${(props) => (props.width ? props.width : 786)}px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        width: 100%;
        ${(props) =>
            props.padding != undefined && props.padding != ""
                ? `
		height: calc(100% - ${props.padding * 2}px);
		`
                : `
		height: calc(100% - 60px);
		`}
    }
`;

const CWRapper = styled.div`
    position: relative;
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : `#ffffff`)};
    width: 100%;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        height: calc(100% - 30px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 30px;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }
`;

const Header = styled.h3`
    margin: 0 0 17px;
    padding: 0 0 16px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 16px;
    font-weight: 500;
    color: #3d3d3d;
    border-bottom: #3d3d3d solid 1px;

    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        /* text-align: center;
		font-family: ${(props) => props.theme.fontFamily.light};
		font-size: 26px;
		line-height: 37px;
		color: #777777;
		padding-bottom: 0;
		margin-bottom: 60px; */
        /* border-bottom: 0; */
    }
`;

export const Footer = styled.div`
    .confirm {
        margin: 17px 0 0;
        text-align: right;
        button {
            min-width: 200px;
            height: 50px;
            border: #e32222 solid 1px;
            background: #e32222;
            color: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.medium};
            font-size: 14px;
        }
    }
    .choose {
        margin: 17px -30px -30px;
        display: flex;
        @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
            margin: 0;
        }
        button {
            flex: 1;
            height: 50px;
            border: #b8b8b8 solid 1px;
            background: #b8b8b8;
            color: #ffffff;
            font-family: ${(props) => props.theme.fontFamily.medium};
            font-size: 14px;
        }
        button:last-child {
            border: #e32222 solid 1px;
            background: #e32222;
        }
    }
`;

const Text = styled.div`
    max-height: 500px;
    overflow: auto;
    font-size: 12px;
    font-weight: 300;
    color: #777;
`;

const Close = styled.button`
    position: absolute;
    margin: 0;
    padding: 0;
    top: -42px;
    right: 0;
    width: 22px;
    height: 22px;
    border: none;
    background: transparent;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        top: 21px;
        right: 21px;
        z-index: 2;
    }
`;
