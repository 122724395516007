/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import { isMobile, isIOS } from "react-device-detect";

let _CAMERA_SIZE = { width: window.innerWidth, height: window.innerHeight };
let _CANVAS_SIZE = { width: window.innerWidth, height: window.innerHeight };
// alert(`${window.innerWidth}/${window.innerHeight}`);
let _RENDERER = null;
let _VIDEO = null;
let _CCVS = null,
	_CCTX = null;

export default (props) => {
	const { cvsId, facingMode, deviceId, devices: propsInDevices, setFacingMode } = props;

	const videoRef = useRef(null);
	const copyRef = useRef(null);
	const camRef = useRef(null);

	const [cameraSize, setCameraSize] = useState(_CAMERA_SIZE || { width: window.innerWidth, height: window.innerHeight });
	const [topSize, setTopSize] = useState(0);
	// alert(/* videoRef,  */JSON.stringify(propsInDevices));

	const loadCamera = async (opt) => {
		try {
			getStream({ ...opt })
				.then((stream) => {
					videoRef.current.srcObject = stream;
					_RENDERER = window.requestAnimationFrame(onDraw);
				})
				.catch((e) => {
					swal({ title: "오류", text: e.message || e.name });
				});
		} catch (e) {
			swal({ title: "오류", text: e.message || e.name });
		}
	};

	const onDraw = () => {
		try {
			// alert(`${JSON.stringify(_VIDEO)}`)
			_CCTX.drawImage(_VIDEO, 0, 0);
			setTopSize(window.innerHeight - _VIDEO.videoHeight);
		} catch (e) {
			console.log(e);
		}
		_RENDERER = window.requestAnimationFrame(onDraw);
	};

	const onDetect = () => {
		// orientation: !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape'
		setCameraSize({ width: window.innerWidth, height: window.innerHeight });
	};

	useEffect(() => {
		// videoRef.current.srcObject && videoRef.current.srcObject.getTracks().forEach(t => t.stop());

		// alert(`ratio:${(window.innerHeight / window.innerWidth) * 100}, full-height: ${window.innerHeight}, video-height: ${videoRef.current.videoHeight}, diff: ${(window.innerHeight - videoRef.current.videoHeight) / 2}`)

		loadCamera({ facingMode, deviceId, propsInDevices, setFacingMode });
		_VIDEO = videoRef.current;
		_CCVS = copyRef.current;
		_CCTX = _CCVS.getContext("2d");

		return () => {
			if (_RENDERER) {
				window.cancelAnimationFrame(_RENDERER);
			}
			_VIDEO = null;
			_CCVS = null;
			_CCTX = null;
		};
	}, [facingMode, deviceId, propsInDevices, _CAMERA_SIZE]);

	useEffect(() => {}, [propsInDevices]);

	useEffect(() => {
		window.addEventListener("resize", onDetect);
		return () => {
			window.removeEventListener("resize", onDetect);
		};
	}, []);

	return (
		<Container>
			<div className="video-wrapper" cameraSize={cameraSize} topSize={topSize}>
				<div className="video-wrapper-container">
					<video ref={videoRef} autoPlay width={cameraSize.width} /* height={cameraSize.height} */ style={{ zIndex: 1 }} muted playsInline />
					<canvas id={cvsId} ref={copyRef} width={cameraSize.width} height={cameraSize.height * 0.7} style={{ zIndex: 2 }} />
				</div>
			</div>
			{/* <div className="camera-wrapper">
                <canvas id={cvsId} ref={camRef} width={_CANVAS_SIZE.height} height={_CANVAS_SIZE.width} style={{ zIndex: 2 }} />
            </div> */}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	${isMobile &&
	isIOS &&
	`
                display: flex;
            `}
	.video-wrapper {
		/* position: absolute; */
		/* bottom: 50px; 
        width: 100%;
        z-index: 10;
        box-shadow: rgb(255 255 255) 0px 0px 8px;
        top: 0px; */

		.video-wrapper-container {
			/* position: relative;
            padding-top: ${(props) => (props.children.props.cameraSize.height / props.children.props.cameraSize.width) * 100}%; */
			width: 100%;
			overflow: hidden;
		}

		video {
			background: black;
			z-index: 1;
			object-fit: none;
			/* position: absolute; */
			position: fixed;
			/* top: ${(props) => props.children.props.topSize / 2}px; */
			width: 100vw;
			height: 40vh;
			top: 15vh;
		}

		canvas {
			/* position: absolute;
            left: 0;
            top: 0; */
			position: absolute;
			z-index: 2;
			/* top: ${(props) => props.children.props.topSize / 2}px; */
			width: ${(props) => _CAMERA_SIZE.width}px;
			/* height: ${(props) => (props.children.props.cameraSize.height / props.children.props.cameraSize.width) * 100}%; */

			background: black;
			top: ${(props) => props.children.props.cameraSize.height * 0.18}px;
			padding-top: ${(props) => props.children.props.cameraSize.height * 0.02}px;
		}
	}

	.camera-wrapper {
		position: relative;
		padding-top: 177.7777%;
		width: 100%;
		background: red;
		/* canvas {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000000;
        } */
	}
`;

/**
 * Functions
 */
const getDevices = async () => {
	const ret = {};
	const devices = await navigator.mediaDevices.enumerateDevices();
	const multiDevice = [];
	for (let i in devices) {
		const d = devices[i];
		switch (d.kind) {
			case "videoinput":
				ret.video = d;
				multiDevice.push(d);
				break;
			case "audioinput":
				ret.audio = d;
				break;
		}
	}
	ret.multiDevice = multiDevice;
	return ret;
};

const getStream = ({ facingMode, deviceId, propsInDevices, setFacingMode }) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (!navigator.mediaDevices) {
				throw { message: "장치 정보를 읽어 올 수 없습니다." };
			}

			// if (isMobile && isIOS) {
			//     throw { message: "This browser is not supported. Please use Safari browser." };
			// }

			const device = propsInDevices.multiDevice?.length > 0 ? propsInDevices : await getDevices();
			let cameraDeviceId = "";
			// alert(device.multiDevice.length +'/'+ facingMode + '/' + JSON.stringify(device.multiDevice));
			if (deviceId) {
				cameraDeviceId = deviceId;
			} else if (device.multiDevice.length > 1) {
				if (!facingMode === "user" && !device.multiDevice[0]) {
					throw { message: "카메라 장치를 확인 할 수 없습니다." };
				}
				if (!facingMode !== "user" && !device.multiDevice[1]) {
					throw { message: "카메라 장치를 확인 할 수 없습니다." };
				}
				cameraDeviceId = facingMode === "user" ? device.multiDevice[0]?.deviceId : device.multiDevice[1]?.deviceId;
			} else {
				if (!device.video) {
					throw { message: "카메라 장치를 확인 할 수 없습니다." };
				}
			}
			if (!device.audio) {
				throw { message: "오디오 장치를 확인 할 수 없습니다." };
			}
			const constraints = {
				audio: false,
				video: {
					// width: facingMode === 'user' ? undefined : { min: 1280, exact: 1920, ideal: 720 },
					// height: { min: 720, exact: 1080 },
					facingMode: facingMode === "user" ? facingMode : { exact: facingMode },
					deviceId: facingMode === "user" ? cameraDeviceId : { exact: cameraDeviceId },
				},
			};

			// alert(JSON.stringify(constraints));

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => resolve(stream))
				.catch((e) => {
					setFacingMode(facingMode === "user" ? "environment" : "user");
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});
};
