/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Layout, { Container } from "../Layout";
import Dialog from "../../UI/Dialog";
import Socket from "../../Components/Socket";

import Sender from "./Live.Controller.Sender";
import Chatting from "../Live/Mobile.Chatting";

export default (props) => {
	const { location, history, match } = props;
	const { auth, iam } = useContext(AppContext);
	const [socketConnect, setSocketConnect] = useState(false);

	if (!auth || iam?.isStreamer !== "Y") {
		history.goBack();
		return null;
	}

	const { idx, streamIdx } = match.params;
	if (!idx) {
		history.goBack();
		return null;
	}

	const [information, setInformation] = useState(null);

	const loadInformation = () => {
		_API
			.get({
				path: "/stream/information",
				data: { idx },
			})
			.then((res) => res.data)
			.then((data) => {
				const { title, memberIdx, status } = data;
				if (memberIdx !== iam.idx) {
					history.goBack();
					return;
				}

				switch (status) {
					case "N":
					case "D":
						history.goBack();
						return;
				}

				setInformation(data);
			});
	};

	useEffect(() => {
		loadInformation();

		if (document.getElementById("ch-plugin")) {
			document.getElementById("ch-plugin").style.display = "none";
		}
	}, [idx]);

	return (
		<Wrapper>
			<Sender roomIdx={idx} roomData={information} streamIdx={streamIdx} history={history} />
			<Chatting roomIdx={idx} roomData={information} setSocketConnect={setSocketConnect} socketConnect={socketConnect} />
			<Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;
