/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import { UInputForm, UFormWrapper } from "../../UI/Form";
import Product, { ListWrapper } from "../../UI/Product";

export default (props) => {
	const { resolve, reject } = props;

	const [keyword, setKeyword] = useState("");
	const [list, setList] = useState([]);

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			loadList();
		}
	};

	const loadList = () => {
		_API
			.get({
				path: "/product/search/category",
				data: { keyword },
			})
			.then((res) => res.data)
			.then((data) => {
				const { list } = data;
				if (list && list.length > 0) {
					setList(list);
				} else {
					swal({ title: "알림", text: "검색된 상품이 없습니다." });
				}
			});
	};

	const handleOnPicked = (code) => {
		resolve(code);
	};

	return (
		<Container>
			{/* <UInputForm
				title="검색 키워드"
				type="text"
				value={keyword}
				onChange={(e) => setKeyword(e.target.value)}
				placeholder="검색할 상품의 키워드를 입력해 주세요."
				onKeyDown={handleKeyDown}
			/> */}
			<UFormWrapper title="검색 키워드">
				<input
					type="text"
					className="uif-input"
					value={keyword}
					onChange={(e) => setKeyword(e.target.value)}
					placeholder="검색할 상품의 키워드를 입력해 주세요."
					onKeyDown={handleKeyDown}
				/>
				<button className="btn-search" onClick={() => loadList()}>
					검색
				</button>
			</UFormWrapper>
			<ListWrapper>
				{list.map((item, i) => (
					<Product key={i} data={item} isPicker onPicked={handleOnPicked} />
				))}
			</ListWrapper>
		</Container>
	);
};

const Container = styled.div`
	max-height: 650px;
	overflow-y: auto;

	> div {
		padding: 0 12px;
	}

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}
	::-webkit-scrollbar-track {
		background-color: #efefef;
		border-radius: 8px;
	}
	::-webkit-scrollbar-thumb {
		background: #3d3d3d;
		border-radius: 8px;
	}

	.uif-form {
		display: flex;
		> input {
			flex: 1;
		}
		> button {
			flex-basis: 100px;
			height: 44px;
			border: #00788d solid 1px;
			background: #00788d;
			color: #ffffff;
		}
	}
`;
