/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import Layout, { Container } from "../Layout";

import { isMobile, isIOS } from "react-device-detect";

export default (props) => {
	const { location, history, match } = props;
	const { auth, iam } = useContext(AppContext);
	const { device, version, appversion, lang } = useContext(AppContext);

	if (!auth || iam?.isStreamer !== "Y") {
		window.self.close();
		return null;
	}

	const { idx } = match.params;
	if (!idx) {
		window.self.close();
		return null;
	}

	const [list, setList] = useState([]);

	const loadList = (page) => {
		_API
			.get({
				path: "/stream/channels",
				data: { idx },
			})
			.then((res) => res.data)
			.then((list) => setList(list));
	};

	const handleItem = (item) => {
		console.log("handleItem", item);
		console.log("device", device, "version", version, "appversion", appversion, "lang", lang);
		console.log("auth", auth, "iam", iam);
		// if (device && device !== "null") {
		// document.location.href = `${_CFG.appSchema}stream?streamId=${item.streamId}`;

		if (isMobile && isIOS) {
			document.location.href = `flexme://stream?streamId=${item.streamId}`;
		} else {
			document.location.href = `${document.location.origin}/#/streamer/controller/${idx}/${item.streamId}`;
		}
		// }
	};

	const goBack = () => {
		if (device && device !== "null") {
			history.goBack();
		} else {
			history.goBack();
		}
	};

	useEffect(() => {
		if (!auth || iam?.isStreamer !== "Y") {
			history.push("/");
		}

		loadList(1);
	}, [auth]);
	console.log(list);
	return (
		<Container>
			<TitleWrapper>
				<button className="btn-back" onClick={() => goBack()}>
					<img src={"/icons/ic_back.png"} srcSet={getSrcSet(["/icons/ic_back.png", "/icons/ic_back@2x.png", "/icons/ic_back@3x.png"])} />
				</button>
				<Title>라이브 송출 목록</Title>
			</TitleWrapper>
			<Wrapper>
				<table>
					<thead>
						<tr>
							{!isMobile && <th width="80">번호</th>}
							<th>제목</th>
						</tr>
					</thead>
					<tbody>
						{list.map((item, i) => (
							<tr key={i}>
								{!isMobile && <td className="text-center">{i + 1}</td>}
								<td>
									<a onClick={(e) => handleItem(item)}>
										{item.title}-{item.type === "MAIN" ? "메인" : item.streamId}
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Wrapper>
		</Container>
	);
};

const Title = styled.div`
	margin: 0px;
	padding: 0px;

	font-size: 24px;
	font-weight: 500;
	color: #3d3d3d;
	font-family: ${(props) => props.theme.fontFamily.medium};

	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		margin: 0 auto;
	}
`;

const TitleWrapper = styled.div`
	position: relative;
	margin: 10px 0 20px;
	padding: 0 0 20px;
	display: flex;

	.options {
		position: absolute;
		right: 0;
		top: 0;

		button {
			width: 110px;
			height: 32px;
			font-size: 13px;
			background-color: #ffffff;
			color: #777;
			border: #cfcfcf solid 1px;
			cursor: pointer;

			:hover {
				background-color: #e32222;
				color: #ffffff;
			}
		}
	}

	button.btn-back {
		display: none;

		@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
			display: block;
			position: absolute;
			left: 21px;
			top: 4px;
			width: 26px;
			height: 26px;
			border: none;
			background: none;
			padding: 0;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
`;

const Wrapper = styled.div`
	width: 100%;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		padding: 0 21px;
		width: calc(100% - 42px);
	}
	table {
		margin: 8px 0 20px;
		border-top: 1px solid #3d3d3d;
		width: 100%;
		border-spacing: 0 0;

		thead {
			background: #f5f5f5;
		}
		thead > tr > th {
			text-align: center;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 12px;
			line-height: 17px;
			padding: 9px 0;
			color: #777777;
			border-bottom: 1px solid #dfdfdf;
		}

		tbody > tr > td {
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 13px;
			line-height: 18px;
			padding: 14px 0;
			color: #3d3d3d;
			border-bottom: 1px solid #dfdfdf;

			button {
				border: 1px solid #cfcfcf;
				font-family: ${(props) => props.theme.fontFamily.regular};
				font-size: 10px;
				line-height: 15px;
				color: #9a9a9a;
				background: #ffffff;
				padding: 5px 10px;
			}

			button.delete {
				color: #e32222;
				background: #ffffff;
				border: 1px solid #e32222;
			}

			a {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				width: 100%;
			}
		}

		tbody > tr > td.text-center {
			text-align: center;
		}
	}
`;
