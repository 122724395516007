/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import cx from "classnames";
import nl2br from "nl2br";

import { getBackgroundSet, getSrcSet } from "../../UI/Assets";
import { SelectBox } from "../../UI/Form";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";

export default (props) => {
    const { detail } = props;

    const TEMP_AS_REPAIR = `- 제품 손상에 한하여 평생 보장하며, 당사와 계약이 되어진 상품 전문 수선 업체에서 A/S가 진행됩니다.  
	- 구매 후 6개월 이내에 발생한 제품의 하자로 인정되는 손상에 대해서는 무상으로 처리해드리고 있으며, 그 이후에는 유상으로 처리됩니다.
	(※ 명백한 소비자 과실에 의한 A/S는 무상 A/S 기간에도 유상처리 됩니다. 또한 무상 A/S 기간에도 손상 정도에 따라 유상으로 진행 될 수 있으며, 극심한 손상의 경우 구매 일자에 상관없이 A/S불가로 판명 될 수 있습니다. )
	- 신청 가능 범위는 제품의 손상으로 인한 A/S만 가능하며, 제품의 본질을 훼손하는 개인의 취향에 따른 미용 목적의 신청은 유·무상 A/S 모두 불가합니다.
	- A/S와 관련된 더욱 자세한 내용은 A/S 페이지에서 확인 가능합니다.
	`;

    return (
        <Wrapper>
            <h2>배송 정보</h2>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <th width="20%">배송방법</th>
                            <td width="30%">{_DEF.getDefinedLabel(_DEF.PRODUCT_SHIPPING, detail?.shippingType)}</td>
                            <th width="20%">배송유형</th>
                            <td width="30%">
                                {detail?.shippingMethod === "PAID" ? <>배송비 {_U.numberFormat(detail?.shippingAmount)}원</> : _DEF.getDefinedLabel(_DEF.SHIPPING_METHOD, detail?.shippingMethod)}
                                {detail?.shippingAmountFar ? <p>도서 산간지역 {_U.numberFormat(detail?.shippingAmountFar)}원</p> : ``}
                            </td>
                        </tr>
                        <tr>
                            <th>배송설명</th>
                            <td colSpan={3}>
                                <div dangerouslySetInnerHTML={{ __html: nl2br(detail?.shippingAmountMsg || "") }} />
                            </td>
                        </tr>
                        <tr>
                            <th>배송기간</th>
                            <td colSpan={3}>
                                <div dangerouslySetInnerHTML={{ __html: nl2br(detail?.shippingTerm || "") }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2>반품/교환/AS 정보</h2>
            <div>
                <table>
                    <tbody>
                        {detail?.returnExplanation && (
                            <tr>
                                <th width="20%">반품비</th>
                                <td>
                                    {_U.numberFormat(detail?.returnAmount)}원
                                    <div dangerouslySetInnerHTML={{ __html: nl2br(detail?.returnExplanation || "") }} />
                                </td>
                            </tr>
                        )}
                        {detail?.returnStandard && (
                            <tr>
                                <th>교환/반품 신청 기준</th>
                                <td>
                                    <div dangerouslySetInnerHTML={{ __html: nl2br(detail?.returnStandard || "") }} />
                                </td>
                            </tr>
                        )}
                        {detail?.returnRestriction && (
                            <tr>
                                <th>교환/반품 제한</th>
                                <td>
                                    <div dangerouslySetInnerHTML={{ __html: nl2br(detail?.returnRestriction || "") }} />
                                </td>
                            </tr>
                        )}
                        {detail?.asInfo && (
                            <tr>
                                <th>A/S정보</th>
                                <td>
                                    {/* <div dangerouslySetInnerHTML={{ __html: nl2br(detail?.repair || TEMP_AS_REPAIR) }} /> */}
                                    <div dangerouslySetInnerHTML={{ __html: nl2br(detail?.asInfo || "") }} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-top: 60px;
    @media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
        margin-top: 40px;
        padding: 0 21px;
    }
    > div {
        margin-bottom: 60px;
    }

    h2 {
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 18px;
        color: #3d3d3d;
        font-weight: normal;
        line-height: 26px;
        margin: 0px;
    }

    table {
        border-spacing: 0;
        width: 100%;
        margin-top: 16px;
    }

    table > tbody > tr {
        color: #777777;
    }

    table > tbody > tr:first-child > th,
    table > tbody > tr:first-child > td {
        border-top: 1px solid #dfdfdf;
    }

    table > tbody > tr > th {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 12px;
        line-height: 17px;
        padding: 9px 0 9px 16px;
        border-bottom: 1px solid #dfdfdf;
        text-align: left;
        background: #f5f5f5;
    }

    table > tbody > tr > td {
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 13px;
        line-height: 18px;
        padding: 14px 16px;
        color: #3d3d3d;
        border-bottom: 1px solid #dfdfdf;

        P {
            padding: 0;
            margin: 0;
        }
    }
`;
