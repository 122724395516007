/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import Moment from "react-moment";
import cx from "classnames";

import { _DEF, _CFG } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

import Dialog from "../../UI/Dialog";

import Camera from "./Live.Sender.Camera";
import { WebRTCAdaptor } from "../../Components/AntMedia/webrtc_adaptor";
import Permission from "./Permission";

const _CANVAS_ID = "LIVE-STREAM-CANVAS";

export default (props) => {
	const { roomIdx, roomData } = props;

	const [adapter, setAdapter] = useState(null);
	const [stream, setStream] = useState(null);
	const [streamId, setStreamId] = useState(null);
	const [isStream, setIsStream] = useState(false);
	const [streamTime, setStreamTime] = useState(null);

	const [deviceId, setDeviceId] = useState("");
	const [videos, setVideos] = useState(null);
	const [hasPermission, setHasPermission] = useState(false);

	const loadStream = () => {
		getStream(_CANVAS_ID)
			.then((s) => setStream(s))
			.catch((e) => swal({ title: "오류", text: e.message }));
	};

	const handleToggleStream = () => {
		if (isStream) {
			stopStreaming();
		} else {
			startStreaming();
		}
	};

	const startStreaming = () => {
		startWebRTCStreaming(stream, streamId, null, roomIdx).then((adapter) => {
			setAdapter(adapter);
		});
	};

	const stopStreaming = () => {
		stopWebRTCStreaming(adapter, streamId);
	};

	const setStreamStatus = (idx, status) => {
		_API
			.post({
				path: "/stream/status",
				data: { idx, status },
			})
			.then((res) => res.data)
			.then((data) => {
				console.log(data);
			});
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e).then((data) => {
			switch (data.code) {
				case "STREAMING-STARTED":
					setStreamTime(moment(new Date()));
					setIsStream(true);

					if (data.data) {
						setStreamStatus(data.data, "Y");
					}

					break;
				case "STREAMING-ENDED":
					setStreamTime(null);
					setIsStream(false);

					if (data.data) {
						setStreamStatus(data.data, "R");
					}
					break;
				case "STREAMING-ERROR":
					if (e.data) {
						const err = JSON.parse(e.data);
						switch (err.data) {
							case "streamIdInUse":
								swal({ title: "오류", text: "이미 다른 기기에서 송출 중입니다." });
								break;
							default:
								swal({ title: "오류", text: e.message || e.name || e.data });
								break;
						}
					} else {
						swal({ title: "오류", text: e.message || e.name || e.data });
					}

					break;
			}
		});
	};

	const handleVideoListChanged = (devices) => {
		setVideos(devices);
	};

	useEffect(() => {
		Permission()
			.then((is) => {
				setHasPermission(is);
				if (!is) {
					swal({ title: "요청", text: "카메라와 마이크의 권한을 허용해 주세요." });
				} else {
					loadStream();
				}
			})
			.catch((e) => {
				swal({ title: "오류", text: e.message || e.name || e.data });
			});

		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	useEffect(() => {
		if (roomData) {
			const { channels } = roomData;
			if (channels && channels.length > 0) {
				const f = channels.filter((o) => o.type === "MAIN");
				if (f && f.length > 0) {
					const c = f[0];
					if (streamId !== c.streamId) {
						setStreamId(c.streamId);
					}
				}
			}
		}
	}, [roomData]);

	useEffect(() => {
		if (videos?.multiDevice && videos?.multiDevice?.length > 0) {
			if (videos?.multiDevice[0]?.deviceId) {
				setDeviceId(videos?.multiDevice[0]?.deviceId);
			}
		}
	}, [videos?.multiDevice]);

	return (
		<Container>
			<Camera cvsId={_CANVAS_ID} permission={hasPermission} videos={videos} setVideos={handleVideoListChanged} deviceId={deviceId} />
			<ActionWrapper>
				<div className="side left">
					<button type="button" className={cx({ start: isStream })} onClick={handleToggleStream}>
						{/* <i className={cx({ fa: true, "fa-play-circle": !isStream, "fa-stop": isStream })}></i> */}
						{isStream ? " 방송 중지 " : " 방송 시작 "}
						{streamTime && <Moment date={streamTime} format="  (HH:mm:ss)" interval={1000} durationFromNow />}
					</button>
				</div>
				{videos && (
					<div className="side right">
						<select
							onChange={(e) => {
								setDeviceId(e.target.value);
							}}
							value={deviceId || ""}
							style={{ height: "40px", marginRight: "10px", alignSelf: "center" }}
						>
							<option value="">카메라 선택</option>
							{videos?.multiDevice?.map((d, i) => (
								<option key={i} value={d.deviceId}>
									{!d.label ? `${i + 1}. Camera` : d.label}
								</option>
							))}
						</select>
					</div>
				)}
			</ActionWrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	width: 480px;
`;

const ActionWrapper = styled.div`
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	z-index: 100;
	display: flex;

	div.side {
		flex: 1;
	}

	div.left {
		text-align: left;

		button {
			padding: 8px 44px;
			border: none;
			background: #ffffff;
			color: #e32222;
			display: block;
			font-family: ${(props) => props.theme.fontFamily.regular};
			font-size: 16px;
			border-radius: 25px;
		}
		button.start {
			background: #e32222;
			color: #ffffff;
			padding: 8px 14px;
		}
	}
	div.right {
		text-align: right;

		button {
			font-size: 22px;
			width: 36px;
			height: 36px;
			color: #ffffff;
			background-color: transparent;
			border: none;
		}
	}
`;

/**
 * Functions
 */
const getStream = (cvsId) => {
	return new Promise(async (resolve, reject) => {
		if (!cvsId) {
			return reject({ message: "Canvas ID is undefined" });
		}
		try {
			const cvs = document.getElementById(cvsId);
			const cvsStream = cvs.captureStream(30);

			const audioStream = await getAudioStream();
			const audioTrack = audioStream.getAudioTracks();

			cvsStream.addTrack(audioTrack[0]);
			resolve(cvsStream);
		} catch (e) {
			reject(e);
		}
	});
};

const getAudioStream = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const audio = await getAudioDevice();
			if (!audio) {
				return reject({ message: "오디오 장치정보를 읽어 올 수 없습니다." });
			}
			const constraints = {
				audio: true,
				video: false,
			};
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => resolve(stream))
				.catch((e) => reject(e));
		} catch (e) {
			reject(e);
		}
	});
};

const getAudioDevice = async () => {
	const devices = await navigator.mediaDevices.enumerateDevices();
	for (let i in devices) {
		const d = devices[i];
		if (d.kind === "audioinput") {
			return d;
		}
	}
	return null;
};

/**
 * WebRTC Streaming
 */
const startWebRTCStreaming = (stream, streamId, token, roomIdx) => {
	return new Promise((resolve, reject) => {
		try {
			const adapter = new WebRTCAdaptor({
				websocket_url: _CFG.getStreamUrl(),
				mediaConstraints: { video: true, audio: false },
				peerconnection_config: null,
				sdp_constraints: {
					OfferToReceiveAudio: false,
					OfferToReceiveVideo: false,
				},
				localVideoId: "SENDER",
				localStream: stream,
				debug: false,
				callback: (info, obj) => {
					const checkAndRepublishIfRequired = () => {
						const iceState = adapter.iceConnectionState(streamId);
						if (iceState === null || iceState === "failed" || iceState === "disconnected") {
							adapter.stop(streamId);
							adapter.closePeerConnection(streamId);
							adapter.closeWebSocket();
						}
					};

					switch (info) {
						case "initialized":
							adapter.publish(streamId, token);
							break;
						case "publish_started":
							_U.postMessage("STREAMING-STARTED", roomIdx);
							break;
						case "publish_finished":
							_U.postMessage("STREAMING-ENDED", roomIdx);
							break;
						case "browser_screen_share_supported":
							break;
						case "screen_share_stopped":
							break;
						case "closed":
							if (typeof obj != "undefined") {
								console.log("Connecton closed: " + JSON.stringify(obj));
							}
							break;
						case "pong":
							break;
						case "refreshConnection":
							checkAndRepublishIfRequired();
							break;
						case "ice_connection_state_changed":
							break;
						case "updated_stats":
							console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec" + " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec");
							break;
					}
				},
				callbackError: (err) => {
					_U.postMessage("STREAMING-ERROR", err);
				},
			});
			resolve(adapter);
		} catch (e) {
			reject(e);
		}
	});
};

const stopWebRTCStreaming = (adapter, streamId) => {
	adapter.stop(streamId);
};
