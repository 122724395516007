/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { v4 as UUIDV4 } from "uuid";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../Components/AppProvider";

import { _API, _U, _CK } from "../../Modules/Utils";
import { _CFG } from "../../Modules/Config";
import Layout, { Container } from "../Layout";
import { Accordion } from "../../UI/Product";

export default (props) => {
	const { location, history, match } = props;

	return (
		<MenuWrapper>
			<ul>
				<li className={cx({ active: location.pathname === "/service/faq" })}>
					<Link to="/service/faq">자주 하는 질문</Link>
				</li>
				<li className={cx({ active: location.pathname.startsWith("/service/notice") })}>
					<Link to="/service/notice">공지사항</Link>
				</li>
				<li>
					<Link to="/cms/user/terms">서비스 이용약관</Link>
				</li>
				<li>
					<Link to="/cms/user/privacy">개인정보 처리방침</Link>
				</li>
				<li>
					<Link to="/service/as">A/S</Link>
				</li>
			</ul>
		</MenuWrapper>
	);
};

const MenuWrapper = styled.div`
	width: 276px;
	@media only screen and (max-width: ${(props) => props.theme.layout.mobileWith}px) {
		display: none;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding: 8px 0;
			font-family: ${(props) => props.theme.fontFamily.regular};
			a {
				font-size: 13px;
				font-weight: normal;
				color: #777;
				text-decoration: none;
				display: block;

				small {
					float: right;
					clear: right;
					font-size: 10px;
					color: #acacac;
				}
			}
		}

		li.active {
			font-family: ${(props) => props.theme.fontFamily.medium};
		}
	}
`;
