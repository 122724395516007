/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";

const _CAMERA_SIZE = { width: 1920, height: 1080 };
const _CANVAS_SIZE = { width: 1280, height: 720 };

let _RENDERER = null;
let _VIDEO = null;
let _CCVS = null,
	_CCTX = null;
let _GCVS = null,
	_GCTX = null;
let _SCVS = null,
	_SCTX = null;
let _DCVS = null,
	_DCTX = null;

let _G_STROKE_WIDTH = 10;
let _G_STROKE_COLOR = "#CCCCCC";
let _GSIZE = null;
let _G_X_POS = 0;
let _G_M_POS = null;

export default (props) => {
	const { cvsId, deviceId, permission } = props;
	const { videos, setVideos } = props;

	const videoRef = useRef(null);
	const copyRef = useRef(null);
	const guideRef = useRef(null);
	const scaleRef = useRef(null);
	const camRef = useRef(null);

	const [gsize, setGsize] = useState({ width: 608, height: 1080 });

	const loadCamera = async ({ deviceId, videos }) => {
		try {
			getStream({ deviceId, videos })
				.then((stream) => {
					videoRef.current.srcObject = stream;
					_RENDERER = window.requestAnimationFrame(onDraw);
				})
				.catch((e) => {
					const msg = e.message || e.name;
					switch (msg) {
						case "Could not start video source":
							swal({ title: "오류", text: "사용할 수 없는 카메라 입력입니다." });
							break;
						case "OverconstrainedError":
							swal({ title: "오류", text: "1920x1080 해상도를 지원하는 카메라를 사용해 주세요." });
							break;
						default:
							swal({ title: "오류", text: msg });
							break;
					}
				});
		} catch (e) {
			swal({ title: "오류", text: e.message || e.name });
		}
	};

	const onDraw = () => {
		try {
			_CCTX.drawImage(_VIDEO, 0, 0);
			_GCTX.clearRect(0, 0, _GCVS.width, _GCVS.height);
			_GCTX.strokeRect(_G_X_POS < _G_STROKE_WIDTH / 2 ? _G_STROKE_WIDTH / 2 : _G_X_POS, _G_STROKE_WIDTH / 2, _GSIZE.width, _GSIZE.height - _G_STROKE_WIDTH);

			const frame = _CCTX.getImageData(_G_X_POS, 0, _GSIZE.width, _GSIZE.height);
			_SCTX.putImageData(frame, 0, 0);

			_DCTX.drawImage(_SCVS, 0, 0, _DCVS.width, _DCVS.height);
		} catch (e) {
			console.log(e);
		}
		_RENDERER = window.requestAnimationFrame(onDraw);
	};

	const handleGMouseDown = (e) => {
		if (e.button !== 0) {
			return;
		}
		_G_M_POS = getPosition(e, _GCVS);
	};
	const handleGMouseMove = (e) => {
		if (!_G_M_POS) {
			return;
		}
		const pos = getPosition(e, _GCVS);
		const loc = getLocation(pos, _GCVS);
		let x = loc.x - _GSIZE.width / 2;
		if (x < 0) {
			x = 0;
		}
		if (x + _GSIZE.width > _GCVS.width) {
			x = _GCVS.width - _GSIZE.width;
		}
		_G_X_POS = x;
	};
	const handleGMouseUp = (e) => {
		_G_M_POS = null;
	};

	useEffect(() => {
		if (permission && deviceId) {
			loadCamera({ deviceId, videos });

			_VIDEO = videoRef.current;
			_CCVS = copyRef.current;
			_CCTX = _CCVS.getContext("2d");
			_GCVS = guideRef.current;
			_GCTX = _GCVS.getContext("2d");
			_SCVS = scaleRef.current;
			_SCTX = _SCVS.getContext("2d");
			_DCVS = camRef.current;
			_DCTX = _DCVS.getContext("2d");

			_GCTX.lineWidth = _G_STROKE_WIDTH;
			_GCTX.strokeStyle = _G_STROKE_COLOR;

			_GSIZE = getGuideSize(_GCVS);
			_G_X_POS = _GCVS.width / 2 - _GSIZE.width / 2;

			setGsize(_GSIZE);
		}

		return () => {
			if (_RENDERER) {
				window.cancelAnimationFrame(_RENDERER);
			}
			_VIDEO = null;
			_CCVS = null;
			_CCTX = null;
			_GCVS = null;
			_GCTX = null;
			_SCVS = null;
			_SCTX = null;
			_DCVS = null;
			_DCTX = null;
		};
	}, [permission, deviceId, videos]);

	useEffect(async () => {
		if (permission && navigator.mediaDevices) {
			const d = await getDevices();
			setVideos && setVideos(d);
		}
	}, [permission]);

	return (
		<Container>
			<div className="video-wrapper">
				<div className="video-wrapper-container">
					<video ref={videoRef} autoPlay width={_CAMERA_SIZE.width} height={_CAMERA_SIZE.height} style={{ zIndex: 1 }} muted playsInline />
					<canvas ref={copyRef} width={_CAMERA_SIZE.width} height={_CAMERA_SIZE.height} style={{ zIndex: 2 }} />
					<canvas
						ref={guideRef}
						width={_CAMERA_SIZE.width}
						height={_CAMERA_SIZE.height}
						onMouseDown={handleGMouseDown}
						onMouseMove={handleGMouseMove}
						onMouseUp={handleGMouseUp}
						style={{ zIndex: 3 }}
					/>
				</div>
			</div>
			<div className="camera-wrapper">
				<canvas ref={scaleRef} width={gsize?.width} height={gsize?.height} style={{ zIndex: 1 }} />
				<canvas id={cvsId} ref={camRef} width={_CANVAS_SIZE.height} height={_CANVAS_SIZE.width} style={{ zIndex: 2 }} />
			</div>
		</Container>
	);
};

const Container = styled.div`
	position: relative;

	.video-wrapper {
		position: absolute;
		right: 15px;
		bottom: 15px;
		width: 300px;
		z-index: 10;
		box-shadow: 0px 0px 8px #ffffff;

		.video-wrapper-container {
			position: relative;
			padding-top: 56.25%;
			width: 100%;
		}

		video,
		canvas {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
	.camera-wrapper {
		position: relative;
		padding-top: 177.7777%;
		width: 100%;

		canvas {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000000;
		}
	}
`;

/**
 * Functions
 */
const getDevices = async () => {
	const ret = {};
	const devices = await navigator.mediaDevices.enumerateDevices();
	const multiDevice = [];
	// alert(JSON.stringify(devices));
	for (let i in devices) {
		const d = devices[i];
		switch (d.kind) {
			case "videoinput":
				ret.video = d;
				multiDevice.push(d);
				break;
			case "audioinput":
				ret.audio = d;
				break;
		}
	}
	// alert(JSON.stringify(devices));
	ret.multiDevice = multiDevice;
	return ret;
};

const getStream = ({ deviceId, videos }) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (!navigator.mediaDevices) {
				throw { message: "장치 정보를 읽어 올 수 없습니다." };
			}
			const device = videos.multiDevice?.length > 0 ? videos : await getDevices();
			if (!device.video) {
				throw { message: "카메라 장치를 확인 할 수 없습니다." };
			}
			if (!device.audio) {
				throw { message: "오디오 장치를 확인 할 수 없습니다." };
			}

			let cameraDeviceId = device.multiDevice[0].deviceId;
			if (deviceId) {
				cameraDeviceId = deviceId;
			}

			const constraints = {
				audio: false,
				video: {
					width: { min: 1280, ideal: 1920, max: 1920 },
					height: { min: 720, ideal: 1080, max: 1080 },
				},
			};
			if (cameraDeviceId) {
				constraints.video.deviceId = { exact: cameraDeviceId };
			}

			let stream = null;
			// let checkCount = 0;
			// let checkTime = moment();
			let lastTime = moment().add(10, "second");
			do {
				try {
					stream = await navigator.mediaDevices.getUserMedia(constraints);
					// console.log(`check ${checkCount++}`);
				} catch (e) {
					// console.log(`check : `, checkTime.format("YYYYMMDDHHmmss"), lastTime.format("YYYYMMDDHHmmss"), moment().format("YYYYMMDDHHmmss"));
				}
			} while (!stream && lastTime >= moment());

			if (stream) {
				resolve(stream);
			} else {
				reject({ message: "카메라 장치가 선택되지 않았습니다. 카메라를 선택해 주세요." });
			}

			// navigator.mediaDevices
			// 	.getUserMedia(constraints)
			// 	.then((stream) => resolve(stream))
			// 	.catch((e) => {
			// 		console.log(":::::::", cameraDeviceId);
			// 		console.log(":::::::", videos);
			// 		console.log(":::::::", constraints);
			// 		console.log(":::::::", e);
			// 		reject(e);
			// 	});
		} catch (e) {
			reject(e);
		}
	});
};

const getGuideSize = (canvas) => {
	const { width, height } = canvas;
	return { width: Math.round((height * 9) / 16), height };
};

const getPosition = (e, cvs) => {
	if (!cvs) {
		return null;
	}
	const rect = cvs.getBoundingClientRect();
	return {
		x: (e.clientX - rect.left) / rect.width,
		y: (e.clientY - rect.top) / rect.height,
	};
};

const getLocation = (pos, cvs) => {
	return {
		x: pos.x * cvs.width,
		y: pos.y * cvs.height,
	};
};
