/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile, isIOS } from "react-device-detect";
import { v4 as UUIDV4 } from "uuid";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import { getSrcSet } from "../../UI/Assets";

import Video from "./Video";

export default (props) => {
    const { videoId, audioId, resolutionId } = props;

    return (
        <Container>
            <Video video={videoId} audio={audioId} resolution={resolutionId} />
            <Controller {...props} />
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #000000;
    color: #ffffff;
`;

/**
 * Controller
 */
const Controller = (props) => {
    const { audio, video, resolution, onDeviceChange } = props;
    const { audioId, videoId, resolutionId } = props;

    return (
        <CContainer>
            <CWrapper>
                <Column>
                    <div className="wrapper">
                        <h3 className="title">카메라</h3>
                        <select className="form" value={videoId} onChange={onDeviceChange.bind(this, "VIDEO")}>
                            <option value="">카메라를 선택해 주세요.</option>
                            {video.map((item, i) => (
                                <option key={i} value={item.deviceId}>
                                    {item.label ? item.label : `Camera #${i + 1}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </Column>
                <Column>
                    <div className="wrapper">
                        <h3 className="title">해상도</h3>
                        <select className="form" value={resolutionId} onChange={onDeviceChange.bind(this, "RESOLUTION")}>
                            <option value="">해상도를 선택해 주세요.</option>
                            {resolution.map((item, i) => (
                                <option key={i} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </Column>
            </CWrapper>
        </CContainer>
    );
};

const CContainer = styled.div`
    position: fixed;
    padding: 4px;
    border-top: #3b3b3b solid 1px;
    background-color: rgba(36, 36, 36, 0.7);
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100;
`;

const CWrapper = styled.div`
    display: flex;
`;
const Column = styled.div`
    ${(props) => (props.width ? `width:${props.width}px; flex-basis: ${props.width}px;` : `flex:1;`)}
    padding: 0 8px;
    border-right: #4a4a4a solid 1px;
    :last-child {
        border-right: none;
    }

    .wrapper {
        .title {
            margin: 0px 0 6px;
            padding: 0px;
            color: #dedede;
            font-size: 13px;
            font-weight: normal;
        }

        .form {
            padding: 7px 10px;
            width: 100%;
            font-family: ${(props) => props.theme.fontFamily.regular};
        }
    }
`;
