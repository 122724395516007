/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import Slider from "react-slick";

import { _DEF } from "../../Modules/Config";
import { _API, _U } from "../../Modules/Utils";
import { AppContext } from "../../Components/AppProvider";
import Socket from "../../Components/Socket";

export default (props) => {
	const { location, history, match, onClick, setSocketConnect, socketConnect } = props;
	const cp = { history, location, match };
	const { roomIdx } = props;
	const [list, setList] = useState([]);
	const [code, setCode] = useState(null);

	const loadList = () => {
		_API
			.get({
				path: "/live/product",
				data: { idx: roomIdx },
			})
			.then((res) => res.data)
			.then((list) => setList(list));
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e)
			.then((data) => {
				switch (data.code) {
					case "SOCKET-RECEIVED":
						return data.data || null;
				}
			})
			.then((data) => {
				if (data?.mode === "PRODUCT_INIT") {
					loadList();
				}
			});
	};

	useEffect(() => {
		loadList();
	}, []);

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	return (
		<Container>
			<Wrapper>
				<Slider infinite speed={2000} autoplay={true} autoplaySpeed={5000} slidesToShow={1} slidesToScroll={1} arrows={false} pauseOnFocus={true} pauseOnHover={true}>
					{list.map((item, i) => (
						<Product key={i} data={item} isPicker onClick={onClick} />
					))}
				</Slider>
			</Wrapper>
			{/* <Socket onConnected={() => setSocketConnect(true)} onDisconnected={() => setSocketConnect(false)} /> */}
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	width: 270px;
	left: 15px;
	bottom: 15px;
	z-index: 2;
`;

const Wrapper = styled.div``;

const Product = (props) => {
	const { data, onClick } = props;
	const { signedUrl } = data;

	const getDiscount = () => {
		try {
			return Math.round(data.priceDiscountPercent);
		} catch (e) {
			return data.priceDiscountPercent;
		}
	};

	return (
		<PContainer onClick={onClick?.bind(this, data.code)}>
			<div className="thumbnail">
				<img src={signedUrl} alt="" />
			</div>
			<div className="content">
				<h3 className="title">{data.name}</h3>
				<h5 className="cost">
					{_U.numberFormat(data.priceSale)}
					<span className="discount">
						<i className="fa fa-arrow-down"></i> {getDiscount()}%
					</span>
				</h5>
				<h5 className="old-cost">{_U.numberFormat(data.priceNormal)}</h5>
			</div>
		</PContainer>
	);
};

const PContainer = styled.div`
	margin: 0 10px 0 0;
	background: #ffffff;
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	cursor: pointer;

	.thumbnail {
		width: 64px;
		height: 64px;
		flex-basis: 64px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.content {
		width: 174px;
		padding: 5px 10px;
		flex: 1;
	}
	.content > .title {
		margin: 0;
		padding: 0;
		font-size: 12px;
		text-align: left;
		color: #3d3d3d;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.content > .cost {
		margin: 0;
		padding: 0;
		margin: 2px 0 0;
		font-size: 13px;
		font-weight: normal;
		text-align: left;
		color: #3d3d3d;
	}
	.content > .cost > span.discount {
		margin-left: 8px;
		font-size: 10px;
		font-weight: normal;
		color: #e32222;
	}
	.content > .old-cost {
		margin: 0;
		padding: 0;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		text-align: left;
		text-decoration: line-through;
		color: #dedede;
	}
`;
